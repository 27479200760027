import React, { Component } from 'react';
import { Card, CardActions, CardContent, Fab, Button } from '@material-ui/core';
import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Row, Col } from 'reactstrap';

class CompFilters extends Component {
    
    state = { 
        compFilters: this.props.values.compFilters,
    }
    
    continue = e => {
        this.props.nextStep();
        this.props.setFilters('compFilters', this.state.compFilters);
    }

    back = e => {
        this.props.prevStep();
    }

    render() { 

        // FILTER TAG DESCRIPTIONS
        const FilterTagTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} />
            ))(({ theme }) => ({
                [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: 'snow',
                color: 'black',
                fontWeight: 'bold',
                maxWidth: 300,
                fontSize: theme.typography.pxToRem(14),
                padding: 25,
                border: '2px solid rgb(52, 181, 122)',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                },
        }));
        
        return ( 
            <div style={{ backgroundColor: 'whitesmoke', height: '100vh', paddingTop: 50 }}>
                <Card className='customCardBackgroundColor' style={{ width: '75vw', height: '75vh', margin: 'auto', overflowY: 'scroll' }}>
                    <CardContent>
                        <h2 style={{ textAlign: 'center', marginTop: 20, marginBottom: 60 }}>More Competition Filters</h2>
                        <div style={{ font: 16, fontStyle: 'italic', fontWeight: 'bold', textAlign: 'center', marginTop: 25, marginBottom: 25 }}>Areas With...</div>
                        <Row style={{ width: 800, height: 215, marginLeft: 'auto', marginRight: 'auto', marginTop: 25 }}>
                            <Col sm={6}>
                                <Row style={{ height: 40 }}>
                                    <div className='columnHeader'>Product Prices</div>
                                </Row>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div id='lowEndBox'>
                                        <FilterTagTooltip arrow placement="left"
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas where there are mostly businesses with affordable product prices nearby.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                onClick={(e) => {
                                                    const value = 'lowEndBiz';
                                                    const checked = this.state.compFilters.includes('lowEndBiz');
                                                    let filterTag = checked ? this.state.compFilters.filter((tags) => tags !== value) : [...this.state.compFilters, value]
                                                    this.setState({ compFilters: filterTag });
                                                }}
                                                style={ this.state.compFilters.includes('lowEndBiz') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 200, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 200, fontSize: 12, outline: 'none' }}
                                            >
                                            $
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div id='highEndBox'>
                                        <FilterTagTooltip arrow placement="left"
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas where there are numerous high-end businesses nearby.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                onClick={(e) => {
                                                    const value = 'highEndBiz';
                                                    const checked = this.state.compFilters.includes('highEndBiz');
                                                    let filterTag = checked ? this.state.compFilters.filter((tags) => tags !== value) : [...this.state.compFilters, value]
                                                    this.setState({ compFilters: filterTag });
                                                }}
                                                style={ this.state.compFilters.includes('highEndBiz') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 200, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 200, fontSize: 12, outline: 'none' }}
                                            >
                                            $$$$
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={6}>
                                <Row style={{ height: 40 }}>
                                    <div className='columnHeader'>Business Density</div>
                                </Row>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div id='highDensityBox'>
                                    <FilterTagTooltip arrow placement="right"
                                        title={
                                            <>
                                                <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas with the highest density of retail businesses.</p>
                                            </>
                                        }
                                    >
                                        <Fab
                                            variant="extended"
                                            size="large"
                                            category='highBizDensity'
                                            checked={this.state.compFilters.includes('highBizDensity')}
                                            onClick={(e) => {
                                                const value = 'highBizDensity';
                                                const checked = this.state.compFilters.includes('highBizDensity');
                                                let filterTag = checked ? this.state.compFilters.filter((tags) => tags !== value) : [...this.state.compFilters, value]
                                                this.setState({ compFilters: filterTag });
                                            }}
                                            style={ this.state.compFilters.includes('highBizDensity') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 200, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 200, fontSize: 12, outline: 'none' }}
                                        >
                                        High
                                        </Fab>
                                    </FilterTagTooltip>
                                    </div>
                                </Row>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div id='lowDensityBox'>
                                        <FilterTagTooltip arrow placement="right"
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas with the lowest density of retail businesses.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                category='lowBizDensity'
                                                checked={this.state.compFilters.includes('lowBizDensity')}
                                                onClick={(e) => {
                                                    const value = 'lowBizDensity';
                                                    const checked = this.state.compFilters.includes('lowBizDensity');
                                                    let filterTag = checked ? this.state.compFilters.filter((tags) => tags !== value) : [...this.state.compFilters, value]
                                                    this.setState({ compFilters: filterTag });
                                                }}
                                                style={ this.state.compFilters.includes('lowBizDensity') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 200, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 200, fontSize: 12, outline: 'none' }}
                                            >
                                            Low
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                        <CardActions>
                            <Button style={{ marginTop: 50, marginRight: 20, marginLeft: 'auto' }} variant="contained" color="default" size="medium" onClick={() => this.back()}>Back</Button>
                            <Button style={{ marginTop: 50, marginRight: 'auto', marginLeft: 30, backgroundColor: '#007FFF', color: 'white' }} variant="contained" size="medium" onClick={() => this.continue()}>Continue</Button>
                        </CardActions>
                    </CardContent>
                </Card>
            </div>
        );
    }
}
 
export default CompFilters;

