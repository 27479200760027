import React, { Component } from 'react';
import { Table } from '@mui/material';
import PriceGauge from '../../../Assets/priceGauge.png';
import '../../listingDetailsLayouts/demographics/demographics.css';
import '../compareListings.css';

class ResPricesComparison extends Component {
    
    
    render() {

        const { listings } = this.props;

        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.scores.fourcastScore - a.scores.fourcastScore});
        
        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }

        let getThisListingsResPricesGauge = (thisListing) => {
            
            const resPriceIndex = thisListing && thisListing.scores && thisListing.scores.residentialPricesIndex && thisListing.scores.residentialPricesIndex;
        
            let resPriceNeedle; 
            
            if (resPriceIndex >= 95) {
                resPriceNeedle = 85;
            } else if (resPriceIndex >= 90) {
                resPriceNeedle = 75;
            } else if (resPriceIndex >= 85) {
                resPriceNeedle = 65;
            } else if (resPriceIndex >= 80) {
                resPriceNeedle = 45;
            } else if (resPriceIndex >= 75) {
                resPriceNeedle = 30;
            } else if (resPriceIndex >= 70) {
                resPriceNeedle = 20;
            } else if (resPriceIndex >= 65) {
                resPriceNeedle = 10;
            } else if (resPriceIndex >= 60) {
                resPriceNeedle = -10;
            } else if (resPriceIndex >= 55) {
                resPriceNeedle = -20;
            } else if (resPriceIndex >= 50) {
                resPriceNeedle = -30;
            } else if (resPriceIndex >= 45) {
                resPriceNeedle = -45;
            } else if (resPriceIndex >= 40) {
                resPriceNeedle = -65;
            } else if (resPriceIndex >= 35) {
                resPriceNeedle = -75;
            } else if (resPriceIndex >= 0) {
                resPriceNeedle = -85;
            } else {
                resPriceNeedle = 0;
            }

            const geoIdV4 = thisListing && thisListing.attomData && thisListing.attomData.geoIdV4 && thisListing.attomData.geoIdV4;

            // RES REAL ESTATE DATA
            let thisListingsPrimaryNeighbRent = {};
            let thisListingsSecondaryNeighbRent = {};
            let thisListingsPrimaryNeighbSalePrice = {};
            let thisListingsSecondaryNeighbSalePrice = {};

            if (geoIdV4 && geoIdV4.length) {
    
                for (let g = 0; g < geoIdV4.length; g++) {

                    const thisAreaType = geoIdV4 && geoIdV4[g] && geoIdV4[g].type;
                    const thisListingsRent = (geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.housing_Median_Rent));
                    const thisListingsSalePrice = (geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.housing_Owner_Households_Median_Value));

                    if (geoIdV4 && (geoIdV4.length > 1)) {
                        if (thisAreaType === 'N4') {    
                            thisListingsPrimaryNeighbRent = {medRent: thisListingsRent, areaType: thisAreaType}
                        } else {
                            thisListingsSecondaryNeighbRent = {medRent: thisListingsRent, areaType: thisAreaType}
                        }
                    } else {
                        thisListingsPrimaryNeighbRent = {medRent: thisListingsRent, areaType: thisAreaType}
                    }

                    if (geoIdV4 && (geoIdV4.length > 1)) {
                        if (thisAreaType === 'N4') {    
                            thisListingsPrimaryNeighbSalePrice = {medSalePrice: thisListingsSalePrice, areaType: thisAreaType}
                        } else {
                            thisListingsSecondaryNeighbSalePrice = {medSalePrice: thisListingsSalePrice, areaType: thisAreaType}
                        }
                    } else {
                        thisListingsPrimaryNeighbSalePrice = {medSalePrice: thisListingsSalePrice, areaType: thisAreaType}
                    }
    
                }
                
            }

            // find avg rent by weighing each N-type (if there are multiple) => .6 for primary N4 && .4 for secondary N2 or N3
            const primaryRent = thisListingsPrimaryNeighbRent.medRent;
            const secondaryRent = thisListingsSecondaryNeighbRent && thisListingsSecondaryNeighbRent.medRent ? thisListingsSecondaryNeighbRent.medRent : null;
            const secondaryRentAreaType = thisListingsSecondaryNeighbRent && thisListingsSecondaryNeighbRent.areaType ? thisListingsSecondaryNeighbRent.areaType : null;

            let medRent = 'N/A';

            if ((secondaryRent !== null) && (secondaryRentAreaType !== null)) {
                let primaryVal = (primaryRent * .6);
                let secondaryVal = (secondaryRent * .4);
                medRent = Math.ceil(primaryVal + secondaryVal);
            } else {
                medRent = Math.ceil(primaryRent);
            }

            // find median sale price by weighing each N-type (if there are multiple) => .6 for primary N4 && .4 for secondary N2 or N3
            const primarySalePrice = thisListingsPrimaryNeighbSalePrice.medSalePrice;
            const secondarySalePrice = thisListingsSecondaryNeighbSalePrice && thisListingsSecondaryNeighbSalePrice.medSalePrice ? thisListingsSecondaryNeighbSalePrice.medSalePrice : null;
            const secondarySalePriceAreaType = thisListingsSecondaryNeighbSalePrice && thisListingsSecondaryNeighbSalePrice.areaType ? thisListingsSecondaryNeighbSalePrice.areaType : null;

            let medSalePrice = 'N/A';

            if ((secondarySalePrice !== null) && (secondarySalePriceAreaType !== null)) {
                let primaryVal = (primarySalePrice * .6);
                let secondaryVal = (secondarySalePrice * .4);
                medSalePrice = Math.ceil(primaryVal + secondaryVal);
            } else {
                medSalePrice = Math.ceil(primarySalePrice);
            }

            const medianSalePrice = medSalePrice;
            const medianRentPrice = medRent;

            
            const resPricesObj = {address: thisListing.listingAddress, resPriceNeedle: resPriceNeedle, medianSale: medianSalePrice, medianRent: medianRentPrice};
            return resPricesObj;
        }


        // SORT BY RES PRICES
        const filteredListingsResPriceGauges = sortedByFourcastScore && sortedByFourcastScore.map(listing => getThisListingsResPricesGauge(listing));
        
        let listing1 = (filteredListingsResPriceGauges && filteredListingsResPriceGauges.length && (filteredListingsResPriceGauges[0] !== undefined)) ? filteredListingsResPriceGauges[0] : null;
        let listing2 = (filteredListingsResPriceGauges && filteredListingsResPriceGauges.length && (filteredListingsResPriceGauges[1] !== undefined)) ? filteredListingsResPriceGauges[1] : null;
        let listing3 = (filteredListingsResPriceGauges && filteredListingsResPriceGauges.length && (filteredListingsResPriceGauges[2] !== undefined)) ? filteredListingsResPriceGauges[2] : null;
        let listing4 = (filteredListingsResPriceGauges && filteredListingsResPriceGauges.length && (filteredListingsResPriceGauges[3] !== undefined)) ? filteredListingsResPriceGauges[3] : null;

        const setResPricesNeedle = async (gauge, el) => {
            setTimeout(async () => {
                const resPricesGauge = await document.querySelector(el);
                let resPricesRotation = gauge;                
                resPricesGauge && await resPricesGauge.style.setProperty('--rotation', resPricesRotation);
            }, 100, { once: true });
        }

        (listing1 !== null) && listing1.resPriceNeedle && setResPricesNeedle(listing1.resPriceNeedle, '.resPriceComparisonNeedle1');
        (listing2 !== null) && listing2.resPriceNeedle && setResPricesNeedle(listing2.resPriceNeedle, '.resPriceComparisonNeedle2');
        (listing3 !== null) && listing3.resPriceNeedle && setResPricesNeedle(listing3.resPriceNeedle, '.resPriceComparisonNeedle3');
        (listing4 !== null) && listing4.resPriceNeedle && setResPricesNeedle(listing4.resPriceNeedle, '.resPriceComparisonNeedle4');
        
        const commaNumber = (inc) => {
            let newNum = Number(inc);
            let numString = newNum.toLocaleString("en-US");
            return numString;
        }

        let displayMedianRent = (listing) => {
            return (
                <>
                    {(listing && listing.medianRent) ? ( 
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'start' }}>
                                <div style={{ fontWeight: 800, marginLeft: 5 }}>{`$${listing && commaNumber(listing.medianRent)}`}</div>
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }
        let displayMedianSale = (listing) => {
            return (
                <>
                    {(listing && listing.medianSale) ? ( 
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'start' }}>
                                <div style={{ fontWeight: 800, marginLeft: 5 }}>{`$${listing && commaNumber(listing.medianSale)}`}</div>
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }

        let filteredListingsMedianRent = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsRent = getThisListingsResPricesGauge(listing);
            return (
                <td style={{ fontSize: 14, padding: 10 }}>
                    {thisListingsRent && displayMedianRent(thisListingsRent)}
                </td>
            )
        });
        let filteredListingsMedianSale = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsSale = getThisListingsResPricesGauge(listing);
            return (
                <td style={{ fontSize: 14, padding: 10 }}>
                    {thisListingsSale && displayMedianSale(thisListingsSale)}
                </td>
            )
        });

        return (
            <div style={{ position: 'relative', width: '98%' }}>
                <div style={{ display: 'flex', justifyContent: 'left', paddingBottom: 20 }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Local Residential Real Estate Market</div>
                </div>
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', justifyContent: 'space-around', marginTop: 25 }}>
                    {listing1 !== null ? (<div style={{ position: 'relative' }}>
                        <div className='resPriceGaugeComparison'>
                            <img src={PriceGauge} alt='' />
                            <div className='resPriceComparisonNeedle1'></div>
                        </div>
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing1.address)}</div>
                    </div>) : null}
                    {listing2 !== null ? (<div style={{ position: 'relative' }}>
                        <div className='resPriceGaugeComparison'>
                            <img src={PriceGauge} alt='' />
                            <div className='resPriceComparisonNeedle2'></div>
                        </div>
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing2.address)}</div>
                    </div>) : null}
                    {listing3 !== null ? (<div style={{ position: 'relative' }}>
                        <div className='resPriceGaugeComparison'>
                            <img src={PriceGauge} alt='' />
                            <div className='resPriceComparisonNeedle3'></div>
                        </div>
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing3.address)}</div>
                    </div>) : null}
                    {listing4 !== null ? (<div style={{ position: 'relative' }}>
                        <div className='resPriceGaugeComparison'>
                            <img src={PriceGauge} alt='' />
                            <div className='resPriceComparisonNeedle4'></div>
                        </div>
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing4.address)}</div>
                    </div>) : null}
                </div>
                <div style={{ marginTop: 50 }}>
                    <div>
                        <Table style={{ tableLayout: 'fixed' }}>
                            <thead>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '10%' }}></th>
                                    {sortedByFourcastScore && sortedByFourcastScore.map(listing => {return (<th style={{ padding: 20, color: 'darkgreen' }}>{addressStyle(listing.listingAddress)}</th>)})}
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '10%' }}>
                                       Median Rent
                                    </th>
                                    {filteredListingsMedianRent}
                                </tr>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '10%' }}>
                                        Median Sale Price
                                    </th>
                                    {filteredListingsMedianSale}
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResPricesComparison;