import React, { Component } from 'react';
import { Table } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-datalabels';
import '../../listingDetailsLayouts/demographics/demographics.css';
import '../compareListings.css';

Chart.register(ChartDataLabels);


class CommonBizComparison extends Component {
    
    
    render() {

        const { listings } = this.props;

        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.scores.fourcastScore - a.scores.fourcastScore});
        
        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }


        let getThisListingsCommonBizTypes = (thisListing) => {
            
            const yelpBusinesses = thisListing && thisListing.yelpData && thisListing.yelpData.businesses && thisListing.yelpData.businesses;
            
            //gets the category Titles of the businesses in the area of this each listing
            const yelpBusinessCategories = yelpBusinesses && yelpBusinesses.map((business) => (business.categories).map((category) => category.title));
            const allCategories = yelpBusinessCategories && yelpBusinessCategories.map((category) => category[0]);
            
            //creates an array of key/values that contains a count of each business type in the area
            const count = allCategories =>
                allCategories.reduce((a, b) => ({ ...a,
                    [b]: (a[b] || 0) + 1
                }), {})

            const sortable = Object.fromEntries(
                Object.entries(count(allCategories)).sort(([,a],[,b]) => b-a)
            );
            
            const mostCommonBusinesses = Object.entries(sortable);
            let commonChartData = {};

            
            commonChartData = {
                labels: 
                [
                    `${mostCommonBusinesses[0][0]}`, 
                    `${mostCommonBusinesses[1][0]}`, 
                    `${mostCommonBusinesses[2][0]}`, 
                    `${mostCommonBusinesses[3][0]}`,
                    `${mostCommonBusinesses[4][0]}`,
                    `${mostCommonBusinesses[5][0]}`
                ],
                datasets: [
                    {
                        label: 'Number of Businesses',
                        indexAxis: 'y',
                        data: [
                            `${mostCommonBusinesses[0][1]}`,
                            `${mostCommonBusinesses[1][1]}`,
                            `${mostCommonBusinesses[2][1]}`,
                            `${mostCommonBusinesses[3][1]}`,
                            `${mostCommonBusinesses[4][1]}`,
                            `${mostCommonBusinesses[5][1]}`
                        ],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.8)',
                            'rgba(54, 162, 235, 0.8)',
                            'rgba(255, 206, 86, 0.8)',
                            'rgba(75, 192, 192, 0.8)',
                            'rgba(150, 142, 62, 0.8)',
                            'rgba(105, 172, 100, 0.8)'
                        ]
                    }
                ]
            }
            
            const commonBizObj = {address: thisListing.listingAddress, commonChartData: commonChartData};
            return commonBizObj;
        }


        // SORT BY LISTING
        const filteredListingsCommonBizTypes = sortedByFourcastScore && sortedByFourcastScore.map(listing => getThisListingsCommonBizTypes(listing));
        
        let listing1 = (filteredListingsCommonBizTypes && filteredListingsCommonBizTypes.length && (filteredListingsCommonBizTypes[0] !== undefined)) ? filteredListingsCommonBizTypes[0] : null;
        let listing2 = (filteredListingsCommonBizTypes && filteredListingsCommonBizTypes.length && (filteredListingsCommonBizTypes[1] !== undefined)) ? filteredListingsCommonBizTypes[1] : null;
        let listing3 = (filteredListingsCommonBizTypes && filteredListingsCommonBizTypes.length && (filteredListingsCommonBizTypes[2] !== undefined)) ? filteredListingsCommonBizTypes[2] : null;
        let listing4 = (filteredListingsCommonBizTypes && filteredListingsCommonBizTypes.length && (filteredListingsCommonBizTypes[3] !== undefined)) ? filteredListingsCommonBizTypes[3] : null;
        

        return (
            <div style={{ position: 'relative', width: '98%' }}>
                <div style={{ display: 'flex', justifyContent: 'left', paddingBottom: 20 }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Most Common Business Types Nearby</div>
                </div>
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', marginTop: 50, paddingBottom: 40, justifyContent: 'space-around' }}>
                    {listing1 !== null ? (<div style={{ position: 'relative', maxWidth: 350 }}>
                        <Bar
                            data={listing1.commonChartData}
                            height={200}
                            options={{
                                padding: 10,
                                title: {
                                    display: true,
                                    text: 'Common Business Types Nearby',
                                    fontSize: 25,
                                    color: 'black'
                                },
                                plugins: {
                                    datalabels: {
                                    display: true,
                                    color: 'black'
                                    }
                                },
                                scales: {
                                    x: {
                                        type: 'linear',
                                        ticks: {
                                            beginAtZero: true
                                        },
                                        grid: {
                                            display: false
                                        }
                                    },
                                    y: {
                                        type: 'category',
                                        stacked: true,
                                        ticks: {
                                            fontSize: 14,
                                        },
                                        grid: {
                                            display: false
                                        },
                                    },
                                },
                            }} 
                        />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing1.address)}</div>
                        <hr />
                    </div>) : null}
                    {listing2 !== null ? (<div style={{ position: 'relative', maxWidth: 350 }}>
                        <Bar
                            data={listing2.commonChartData}
                            height={200}
                            options={{
                                padding: 10,
                                title: {
                                    display: true,
                                    text: 'Common Business Types Nearby',
                                    fontSize: 25,
                                    color: 'black'
                                },
                                plugins: {
                                    datalabels: {
                                    display: true,
                                    color: 'black'
                                    }
                                },
                                scales: {
                                    x: {
                                        type: 'linear',
                                        ticks: {
                                            beginAtZero: true
                                        },
                                        grid: {
                                            display: false
                                        }
                                    },
                                    y: {
                                        type: 'category',
                                        stacked: true,
                                        ticks: {
                                            fontSize: 14,
                                        },
                                        grid: {
                                            display: false
                                        },
                                    },
                                },
                            }} 
                        />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing2.address)}</div>
                        <hr />
                    </div>) : null}
                    {listing3 !== null ? (<div style={{ position: 'relative', maxWidth: 350, marginTop: 50 }}>
                        <Bar
                            data={listing3.commonChartData}
                            height={200}
                            options={{
                                padding: 10,
                                title: {
                                    display: true,
                                    text: 'Common Business Types Nearby',
                                    fontSize: 25,
                                    color: 'black'
                                },
                                plugins: {
                                    datalabels: {
                                    display: true,
                                    color: 'black'
                                    }
                                },
                                scales: {
                                    x: {
                                        type: 'linear',
                                        ticks: {
                                            beginAtZero: true
                                        },
                                        grid: {
                                            display: false
                                        }
                                    },
                                    y: {
                                        type: 'category',
                                        stacked: true,
                                        ticks: {
                                            fontSize: 14,
                                        },
                                        grid: {
                                            display: false
                                        },
                                    },
                                },
                            }} 
                        />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing3.address)}</div>
                        <hr />
                    </div>) : null}
                    {listing4 !== null ? (<div style={{ position: 'relative', maxWidth: 350, marginTop: 50 }}>
                        <Bar
                            data={listing4.commonChartData}
                            height={200}
                            options={{
                                padding: 10,
                                title: {
                                    display: true,
                                    text: 'Common Business Types Nearby',
                                    fontSize: 25,
                                    color: 'black'
                                },
                                plugins: {
                                    datalabels: {
                                    display: true,
                                    color: 'black'
                                    }
                                },
                                scales: {
                                    x: {
                                        type: 'linear',
                                        ticks: {
                                            beginAtZero: true
                                        },
                                        grid: {
                                            display: false
                                        }
                                    },
                                    y: {
                                        type: 'category',
                                        stacked: true,
                                        ticks: {
                                            fontSize: 14,
                                        },
                                        grid: {
                                            display: false
                                        },
                                    },
                                },
                            }} 
                        />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing4.address)}</div>
                        <hr />
                    </div>) : null}
                </div>
            </div>
        );
    }
}

export default CommonBizComparison;