import React, { Component } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Card, CardContent, Fab } from '@material-ui/core';
import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import yelplogo from '../../../Assets/yelplogo.png';
import TotalReviewCount from './totalReviewCount';
import WalkScoreData from './walkscoreData';
import BusyHours from './popularTimes';
import BusyTimesModal from './busyTimesModal';
import BusyRoadTraffic from './busyRoadTraffic';
import poweredByGoogle from '../../../Assets/powered_by_google_on_white.png';
import './traffic.css';
const starAttraction = <img alt='major attraction star desc' className='star' src="https://img.icons8.com/fluent/48/000000/star.png"/>;
const starAttraction1 = <img alt='major attraction star value' className='star1' src="https://img.icons8.com/fluent/48/000000/star.png"/>;


class Traffic extends Component {
    
    
    render() { 
        const { walkScoreData, yelpData, thisListing, scores, filterables } = this.props;
        const geoIdV4 = thisListing && thisListing.attomData && thisListing.attomData.geoIdV4 && thisListing.attomData.geoIdV4;
        const primaryNeighb = geoIdV4 && geoIdV4.length && geoIdV4.filter(neighb => (neighb.type === 'N2' || neighb.type === 'N3'));
        const neName = primaryNeighb && primaryNeighb.length ? primaryNeighb[0].name : 'N/A';
        const { scores: { POI: { poi1: { name: poi1Name } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi2: { name: poi2Name } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi3: { name: poi3Name } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi1: { icon: poi1Icon } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi2: { icon: poi2Icon } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi3: { icon: poi3Icon } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi1: { latitude: poi1Lat } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi2: { latitude: poi2Lat } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi3: { latitude: poi3Lat } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi1: { longitude: poi1Lon } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi2: { longitude: poi2Lon } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi3: { longitude: poi3Lon } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi1: { majorAttraction: poi1Major } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi2: { majorAttraction: poi2Major } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi3: { majorAttraction: poi3Major } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi1: { distance: { elements: distance1Elem } = {}} = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi2: { distance: { elements: distance2Elem } = {}} = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi3: { distance: { elements: distance3Elem } = {}} = {}} = {}} = {}} = this.props;

        let poi1Distance = '';
        let poi2Distance = '';
        let poi3Distance = '';

        if (distance1Elem) {
            for (let i = 0; i < distance1Elem.length; i++ ) {
                poi1Distance = distance1Elem[i].duration.text;
            }
        }

        if (distance2Elem) {
            for (let i = 0; i < distance2Elem.length; i++ ) {
                poi2Distance = distance2Elem[i].duration.text;
            }
        }

        if (distance3Elem) {
            for (let i = 0; i < distance3Elem.length; i++ ) {
                poi3Distance = distance3Elem[i].duration.text;
            }
        }

        const { filterables: { busyHours: { openingTimes: theOpeningTimes } = {}} = {}} = this.props;
        const { filterables: { busyHours: { closingTimes: theClosingTimes } = {}} = {}} = this.props;


        const trafficFilterTags = [];

        if (thisListing.filterTags) {
            for (let i = 0; i < thisListing.filterTags.length; i ++) {
                
                if (thisListing.filterTags[i].includes('nearAttraction')) {
                    trafficFilterTags.push({tagName: 'Near Major Attraction', tagDescription: 'This space is within a 250 meter radius (~5 min walk) of a major attraction, which can generate traffic and tourists in the area.'});
                } else if (thisListing.filterTags[i].includes('busyDayTime')) {
                    trafficFilterTags.push({tagName: 'Busy Daytime', tagDescription: 'Traffic is high during the day, and drops at night.'});
                } else if (thisListing.filterTags[i].includes('busyMonThruFri')) {
                    trafficFilterTags.push({tagName: 'Busy Weekdays', tagDescription: 'Most traffic occurs during the weekdays, Monday-Friday.'});
                } else if (thisListing.filterTags[i].includes('busyWeekend')) {
                    trafficFilterTags.push({tagName: 'Busy Weekends', tagDescription: 'Traffic is high during the weekends, Friday-Sunday.'});
                } else if (thisListing.filterTags[i].includes('busyNightLife')) {
                    trafficFilterTags.push({tagName: 'Busy Nightlife', tagDescription: 'There are numerous nightlife spots nearby that are open late.'});
                } else if (thisListing.filterTags[i].includes('seasonal')) {
                    trafficFilterTags.push({tagName: 'Seasonal', tagDescription: 'Traffic here fluctuates depending on the season or time of year.'});
                } else if (thisListing.filterTags[i].includes('volatileTraffic')) {
                    trafficFilterTags.push({tagName: 'Volatile Traffic', tagDescription: 'Traffic increases and decreases dramatically depending on the time and day.'});
                }
            }
        }

        const FilterTagTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} />
            ))(({ theme }) => ({
                [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: 'snow',
                color: 'black',
                fontWeight: 'bold',
                maxWidth: 300,
                fontSize: theme.typography.pxToRem(14),
                padding: 25,
                border: '2px solid rgb(52, 181, 122)',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                },
        }));


        return (
            <Container style={{ margin: 'auto' }}>
                <div className='trafficTagsArea'>
                    <div style={{ height: '100%', alignItems: 'center', display: 'grid', gridTemplateColumns: '2fr 1fr', gap: 5 }}>
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <h5>Traffic Score:</h5>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <h3 style={{ textShadow: '.5px .5px 1px rgb(21, 134, 84)', border: '2px solid green', borderRadius: '50%', width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>{scores.trafficScore}</h3>
                        </div>
                    </div>
                    <div style={{ height: '100%', display: 'grid', gridTemplateColumns: '1fr 5fr', gap: 5 }}>
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <h5>Tags:</h5>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                            {trafficFilterTags.length ? trafficFilterTags.map(tag => (
                                <span>
                                    <FilterTagTooltip arrow
                                        title={
                                            <>
                                                <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>{tag.tagDescription}</p>
                                            </>
                                        }
                                    >
                                        <Fab
                                            variant="extended"
                                            size="small"
                                            style={{ backgroundColor: 'rgb(52, 181, 122)', fontSize: 12, color: 'whitesmoke', marginLeft: 10, outline: 'none', padding: 15, lineHeight: 1.25 }}
                                            >
                                            {tag.tagName}
                                        </Fab>
                                    </FilterTagTooltip>
                                </span>
                            )) : (<p className='summaryDescription'>None</p>)}
                        </div>
                    </div>
                </div>
                <hr />
                <div style={{ width: 1150, margin: 'auto', paddingBottom: 100, display: 'grid', gridTemplateColumns: '3fr 1fr' }} id='trafficContent'>
                    <div style={{ position: 'relative' }} id='mostVisitedPlacesData'>
                        <Row style={{ height: 180 }}>
                            <Card style={{ width: 725, height: 180, margin: 'auto 0', position: 'relative' }}>
                                <CardContent>
                                    <div className='poiContent'>
                                        <span><div>{starAttraction1}</div></span><span><div className='starDescription'>- Major Attraction</div></span>
                                        <div className='topPlacesTitle'>Most Visited Places Nearby:</div>
                                        <Row style={{ width: '100%', height: 110, marginTop: 50 }}>
                                            <Col sm={4} className="poi1Column">
                                                <Row className="poi1Icon">
                                                    <div className='markerNumber'>1</div>
                                                    <span className="poiName">{poi1Name}</span><span>{poi1Major === true ? starAttraction : ''}</span>
                                                </Row>
                                                <Row className="poiDistance">
                                                    <span>{`${poi1Distance} walk`}<img className='poiIcon' src={poi1Icon} alt='' /></span>
                                                </Row>
                                            </Col>
                                            <Col sm={4} className="poi2Column">
                                                <Row className="poi2Icon">
                                                    <div className='markerNumber'>2</div>
                                                    <span className="poiName">{poi2Name}</span><span>{poi2Major === true ? starAttraction : ''}</span>
                                                </Row>
                                                <Row className="poiDistance">
                                                    <span>{`${poi2Distance} walk`}<img className='poiIcon' src={poi2Icon} alt='' /></span>
                                                </Row>
                                            </Col>
                                            <Col sm={4} className="poi3Column">
                                                <Row className="poi3Icon">
                                                    <div className='markerNumber'>3</div>
                                                    <span className="poiName">{poi3Name}</span><span>{poi3Major === true ? starAttraction : ''}</span>
                                                </Row>
                                                <Row className="poiDistance">
                                                    <span>{`${poi3Distance} walk`}<img className='poiIcon' src={poi3Icon} alt='' /></span>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <img className='poweredByGoogle' src={poweredByGoogle} alt='POI Powered By Google' />
                                    </div>
                                </CardContent>
                            </Card>
                        </Row>
                        <Row style={{ position: 'relative' }}>
                            <div className="staticMap">
                                <img alt='static google map on traffic page' src={`https://maps.googleapis.com/maps/api/staticmap?center=${thisListing.latitude},${thisListing.longitude}&zoom=16&scale=1&size=800x500&maptype=terrain&markers=color:eb2741cb%7C${thisListing.latitude},${thisListing.longitude}&markers=color:purple%7Clabel:1%7C${poi1Lat},${poi1Lon}&markers=color:purple%7Clabel:2%7C${poi2Lat},${poi2Lon}&markers=color:purple%7Clabel:3%7C${poi3Lat},${poi3Lon}&style=feature:poi.business|visibility:off&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`} />
                            </div>
                            <Card style={{ position: 'absolute', left: "0%", top: "5%", backgroundColor: 'white', padding: 15 }}>
                                <div style={{ fontSize: 12, textAlign: 'center' }}>Neighborhood:</div>
                                <div style={{ fontSize: 17, textAlign: 'center', fontWeight: 'bold', marginTop: 5, color: 'darkgreen' }}>{neName}</div>
                            </Card>
                        </Row>    
                    </div>
                    <div style={{ position: 'relative' }}>
                        <Row>
                            <Card className="popularTimes" id="busyTimesOfDay">
                                <CardContent>
                                    <BusyHours thisListing={thisListing} filterables={filterables} />
                                    <div className="busyTimesModal" id='busyTimesModalBtn'>
                                        {theOpeningTimes && theClosingTimes ? <BusyTimesModal openingTimes={theOpeningTimes} closingTimes={theClosingTimes} /> : null}
                                    </div>
                                </CardContent>
                            </Card>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Card className="walkscoreData" id="accessibilityData">
                                <CardContent>
                                    <img id='walkScoreLogoApi' className='walkscoreLogoTraffic' src={walkScoreData.logo_url} alt='' />
                                    <WalkScoreData walkScoreData={walkScoreData} />
                                </CardContent>
                            </Card>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Card className='engagement' id='engagementData'>
                                <CardContent>
                                    <img className='yelpLogoTraffic' src={yelplogo} alt='' />
                                    <TotalReviewCount thisListing={thisListing} yelpData={yelpData} scores={scores} />
                                </CardContent>
                            </Card>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Card className='busyRoadTraffic'>
                                <CardContent>
                                    <p className="roadTrafficTitle">Road Traffic</p>
                                    <div id='trafficBusyRoadSection'>
                                        <BusyRoadTraffic thisListing={thisListing} />
                                    </div>
                                </CardContent>
                            </Card>
                        </Row>
                    </div>
                </div>
            </Container>
        );
    }
}

export default Traffic;

