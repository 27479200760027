import React, { Component } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { FaMapMarker, FaWalking, FaIndustry } from 'react-icons/fa';
import { IoStorefront } from 'react-icons/io5';
import { BsPeopleFill } from 'react-icons/bs';
import { MdArrowForwardIos } from 'react-icons/md';
import { Row, Col } from 'reactstrap';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import fourcastMatchLogo from '../../Assets/fourcastMatchLogo.png';
import fourcastAnalysisArea from '../../Assets/analysis-area.png';
import fourcastAnalysisArea2 from '../../Assets/analysis-area2.png';
import '../welcome/description.css';
import '../listingMap.css';



class StartJoyRide extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            stepIndex: 0,
            start: false,
        }
    }

    componentDidMount() {
        const { run } = this.props;
        const { timeout } = this.props;

        if (run === true) {
            const timer = setTimeout(() => {
                this.setState({ start: true })
            }, timeout);
            return () => clearTimeout(timer);
        }
    }

    handleJoyrideCallback = data => {
        const { action, index, status, type } = data;
    
        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
          // Update state to advance the tour
          this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
        }
        else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
          // Need to set our running state to false, so we can restart if we click start again.
          this.setState({ run: false });
          this.props.setJoyRide(false);
        }

        if (action ===  'close') {
            this.setState({ run: false });
          this.props.setJoyRide(false);
        }
    
        console.groupCollapsed(type);
        console.log(data); //eslint-disable-line no-console
        console.groupEnd();
    };


    render() { 

        const { stepIndex, start } = this.state;
        const { page } = this.props;

        const customMapSteps = [
            {
                target: '.customMapShowUndisclosedBtn',
                content: "Click here to show properties that have an undisclosed price. An undisclosed price weakens the strength of the Fourcast Match score given.",
                placement: 'auto',
                disableBeacon: true
            },
            {
                target: '.customMapShowAllBtn',
                content: 'Switch between the top 10 matching properties and all available properties.',
                placement: 'auto',
                disableBeacon: true
            },
            {
                target: '.customMapMatchScoreStrength',
                content: 'Match Score Strength indicates the level of confidence in the Fourcast Match score given - typically based off the amount/quality of data available for that particular space.',
                placement: 'auto',
                disableBeacon: true
            }
        ];

        const mapIntroSteps = [
            {
                target: '.mapIntroListing',
                content: (
                    <div style={{ position: 'relative' }}>
                        <h4 style={{ textAlign: 'center', marginTop: 20, fontFamily: 'sans-serif', width: '100%', wordSpacing: 1, textShadow: '1px 1px 2px rgb(21, 134, 84)' }}>Fourcast provides analysis on available retail spaces and their immediate surrounding area:</h4>
                        <div style={{ marginTop: '2rem', marginLeft: 'auto', marginRight: 'auto', width: '70%', textAlign: 'left', fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif' }}>
                            <Row style={{ height: 50, paddingLeft: 25 }}>
                                <FaWalking style={{ width: 30, height: 30, color: 'rgb(52, 181, 122)' }} />
                                <span style={{ fontWeight: 'bold', marginLeft: 10, paddingTop: 5 }}>: Traffic</span><span style={{ paddingTop: 5, paddingLeft: 5 }}>Data</span>
                            </Row>
                            <Row style={{ height: 50, paddingLeft: 25 }}>
                                <BsPeopleFill style={{ width: 30, height: 30, color: 'rgb(52, 181, 122)' }} />
                                <span style={{ fontWeight: 'bold', marginLeft: 10, paddingTop: 5 }}>: Demographics</span><span style={{ paddingTop: 5, paddingLeft: 5 }}>Data</span>
                            </Row>
                            <Row style={{ height: 50, paddingLeft: 25 }}>
                                <FaIndustry style={{ width: 30, height: 30, color: 'rgb(52, 181, 122)' }} />
                                <span style={{ fontWeight: 'bold', marginLeft: 10, paddingTop: 5 }}>: Competition</span><span style={{ paddingTop: 5, paddingLeft: 5 }}>Data</span>
                            </Row>
                            <Row style={{ height: 50, paddingLeft: 25 }}>
                                <IoStorefront style={{ width: 30, height: 30, color: 'rgb(52, 181, 122)' }} />
                                <span style={{ fontWeight: 'bold', marginLeft: 10, paddingTop: 5 }}>: Property</span><span style={{ paddingTop: 5, paddingLeft: 5 }}>Data</span>
                            </Row>
                        </div>
                    </div>
                ),
                placement: 'auto',
                disableBeacon: true
            },
            {
                target: '.mapIntroListing',
                content: (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 350 }}>
                        <Row style={{ height: '90%' }}>
                            <h4 style={{ textAlign: 'center', marginTop: 20, fontFamily: 'sans-serif', width: '100%', wordSpacing: 1, textShadow: '1px 1px 2px rgb(21, 134, 84)' }}>Each space is given a score from 50-100 based on the quality of the space AND the area it's in:</h4>
                            <div style={{ textAlign: 'center', margin: 'auto', width: '90%', height: '50%', position: 'relative' }}>
                                <div style={{ position: 'absolute', borderBottom: '110px solid rgba(52, 181, 122, .4)', borderLeft: '55px solid transparent', borderRight: '55px solid transparent', height: 0, width: 235, marginTop: 35, marginLeft: 50 }}></div>
                                <div style={{ backgroundColor: 'red', opacity: .5, height: 50, width: 100, borderRadius: '50%', position: 'absolute', left: '50%', top: '50%', marginLeft: -45, marginTop: -5, zIndex: 11 }}></div>
                                <div style={{ position: 'absolute', zIndex: 11, borderBottom: '100px solid rgba(52, 181, 122, .4)', borderLeft: '50px solid transparent', borderRight: '50px solid transparent', height: 0, width: 220, marginTop: 40, marginLeft: 57 }}>
                                    <FaMapMarker style={{ zIndex: 12, width: 60, height: 60, color: 'darkgreen' }} />
                                </div>
                                <div style={{ position: 'absolute', backgroundColor: 'white', border: '1px solid green', paddingTop: 4, fontWeight: 'bold', zIndex: 12, width: 35, height: 35, bottom: '39.5%', left: '51.5%', transform: 'translate(-50%, -50%)', borderRadius: '50%', textAlign: 'center' }}>91</div>
                            </div>
                        </Row>
                    </div>
                ),
                placement: 'auto',
                disableBeacon: true
            },
            {
                target: '.mapIntroListing',
                content: (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 350 }}>
                        <Row style={{ height: '100%' }}>
                            <h4 style={{ textAlign: 'center', fontFamily: 'sans-serif', width: '100%', wordSpacing: 1, textShadow: '1px 1px 2px rgb(21, 134, 84)' }}>Area of analysis:</h4>
                            <div style={{ textAlign: 'center', margin: 'auto', position: 'relative', overflowY: 'hidden', height: '95%' }}>
                                <img alt='fourcast analysis area' src={fourcastAnalysisArea} style={{ height: '100%', width: '100%' }} />
                                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left', flexWrap: 'wrap' }}>
                                    <div style={{ position: 'absolute', left: '35%', bottom: '5%', width: '50%', backgroundColor: 'white', border: '1px solid rgb(21, 134, 84)' }}>
                                        <div style={{ fontSize: 18, fontFamily: 'sans-serif', textShadow: '1px 1px 2px rgb(21, 134, 84)' }}>250 meter radius</div>
                                        <div style={{ fontSize: 18, fontFamily: 'sans-serif', textShadow: '1px 1px 2px rgb(21, 134, 84)' }}>(~5 min walk)</div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                ),
                placement: 'auto',
                disableBeacon: true
            },
            {
                target: '.mapIntroListing',
                content: (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 350 }}>
                        <Row style={{ height: '100%' }}>
                            <h4 style={{ textAlign: 'center', fontFamily: 'sans-serif', width: '100%', wordSpacing: 1, textShadow: '1px 1px 2px rgb(21, 134, 84)' }}>Relative grading:</h4>
                            <div style={{ textAlign: 'center', margin: 'auto', position: 'relative', overflowY: 'hidden', height: '95%' }}>
                                <img alt='fourcast analysis area 2' src={fourcastAnalysisArea2} style={{ height: '100%', width: '100%' }} />
                                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left', flexWrap: 'wrap' }}>
                                    <div style={{ position: 'absolute', left: '5%', bottom: '5%', width: '60%', backgroundColor: 'white', border: '1px solid rgb(21, 134, 84)' }}>
                                        <div style={{ fontSize: 16, fontFamily: 'sans-serif', textShadow: '1px 1px 2px rgb(21, 134, 84)' }}>Scores are given based on how they compare to other listings</div>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </div>
                ),
                placement: 'auto',
                disableBeacon: true
            },
            {
                target: '.mapIntroListing',
                content: (
                    <div style={{ position: 'relative' }}>
                        <h4 style={{ textAlign: 'center', fontFamily: 'sans-serif', marginTop: 20, width: '100%', wordSpacing: 1, textShadow: '1px 1px 2px rgb(21, 134, 84)' }}>The listing's overall Fourcast Score is based on the average of four sub-category scores:</h4>
                        <div style={{ display: 'grid', gridTemplateColumns: '3fr 2fr', paddingBottom: 50, marginTop: '2rem' }}>
                            <div>
                                <div style={{ textAlign: 'left', marginTop: 5 }}><span style={{ fontWeight: 'bold', fontSize: 24, color: 'darkgreen', fontFamily: 'sans-serif' }}>98</span><span style={{ marginLeft: 15 }}>Traffic Score</span></div>
                                <div style={{ textAlign: 'left', marginTop: 5 }}><span style={{ fontWeight: 'bold', fontSize: 24, color: 'darkgreen', fontFamily: 'sans-serif' }}>97</span><span style={{ marginLeft: 15 }}>Demographics Score</span></div>
                                <div style={{ textAlign: 'left', marginTop: 5 }}><span style={{ fontWeight: 'bold', fontSize: 24, color: 'darkgreen', fontFamily: 'sans-serif' }}>83</span><span style={{ marginLeft: 15 }}>Competition Score</span></div>
                                <div style={{ textAlign: 'left', marginTop: 5 }}><span style={{ fontWeight: 'bold', fontSize: 24, color: 'darkgreen', fontFamily: 'sans-serif' }}>88</span><span style={{ marginLeft: 15 }}>Property Score</span></div>
                            </div>
                            <div style={{ height: '100%', display: 'grid', gridTemplateColumns: '1fr 2fr' }}>
                                <div>
                                    <MdArrowForwardIos style={{ zIndex: 12, width: 50, height: "100%" }} />
                                </div>
                                <div style={{ height: '100%' }}>
                                    <div style={{ margin: 'auto auto', marginTop: 45, backgroundColor: 'white', border: '3px solid green', paddingTop: 3, fontWeight: 'bold', zIndex: 12, width: 40, height: 40, borderRadius: '50%', textAlign: 'center', fontSize: 20 }}>91</div>
                                    <div style={{ fontWeight: 'bold', margin: 'auto auto', fontSize: 12, marginTop: 5 }}>FOURCAST SCORE</div>
                                </div>
                            </div>
                        </div>
                    </div>
                ),
                placement: 'auto',
                disableBeacon: true
            },
            {
                target: '.mapIntroListing',
                content: (
                    <div style={{ height: 350 }}>
                            <h4 style={{ textAlign: 'center', marginTop: 20, fontFamily: 'sans-serif', width: '100%', wordSpacing: 1, textShadow: '1px 1px 2px rgb(21, 134, 84)' }}>What Influences Scoring:</h4>
                            <div style={{ height: 150, marginTop: '1rem', display: 'grid', gridTemplateColumns: '1fr 1fr', fontFamily: 'Arial, Helvetica, sans-serif' }}>
                                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left', flexWrap: 'wrap' }}>
                                    <div style={{ width: '100%', display: 'inline-flex' }}>
                                        <FaWalking style={{ width: 30, height: 30, color: 'rgb(52, 181, 122)' }} />
                                        <div style={{ fontSize: 19, fontFamily: 'sans-serif', textShadow: '1px 1px 2px rgb(21, 134, 84)' }}>Traffic</div>
                                    </div>
                                    <ul style={{ paddingLeft: 10, width: '100%', fontSize: 13, marginTop: 10, fontFamily: 'sans-serif', textAlign: 'left' }}>
                                        <li>Foot Traffic</li>
                                        <li>Nearby Attractions</li>
                                        <li>& more</li>
                                    </ul>
                                </div>
                                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                                    <div style={{ display: 'inline-flex' }}>
                                        <BsPeopleFill style={{ marginRight: 5, width: 30, height: 30, color: 'rgb(52, 181, 122)' }} />
                                        <div style={{ fontSize: 19, fontFamily: 'sans-serif', textShadow: '1px 1px 2px rgb(21, 134, 84)' }}>Demographics</div>
                                    </div>
                                    <ul style={{ fontSize: 13, marginTop: 10, fontFamily: 'sans-serif', textAlign: 'left' }}>
                                        <li>Avg. Income Levels</li>
                                        <li>Spending Potential</li>
                                        <li>& more</li>
                                    </ul>
                                </div>
                            </div>
                            <div style={{ height: 150, marginTop: '1rem', display: 'grid', gridTemplateColumns: '1fr 1fr', fontFamily: 'Arial, Helvetica, sans-serif' }}>
                                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                                    <div style={{ display: 'inline-flex', textAlign: 'left' }}>
                                        <FaIndustry style={{ marginRight: 5, width: 30, height: 30, color: 'rgb(52, 181, 122)' }} />
                                        <div style={{ fontSize: 19, fontFamily: 'sans-serif', textShadow: '1px 1px 2px rgb(21, 134, 84)' }}>Competition</div>
                                    </div>
                                    <ul style={{ paddingLeft: 10, width: '100%', fontSize: 13, marginTop: 10, fontFamily: 'sans-serif', textAlign: 'left' }}>
                                        <li>Business Density</li>
                                        <li>Business Popularity</li>
                                        <li>& more</li>
                                    </ul>
                                </div>
                                <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                                    <div style={{ display: 'inline-flex' }}>
                                        <IoStorefront style={{ marginRight: 5, width: 30, height: 30, color: 'rgb(52, 181, 122)' }} />
                                        <div style={{ fontSize: 19, fontFamily: 'sans-serif', textShadow: '1px 1px 2px rgb(21, 134, 84)' }}>Property</div>
                                    </div>
                                    <ul style={{ fontSize: 13, marginTop: 10, fontFamily: 'sans-serif', textAlign: 'left' }}>
                                        <li>Visibility</li>
                                        <li>Pricing (if available)</li>
                                        <li>& more</li>
                                    </ul>
                                </div>
                            </div>
                    </div>
                ),
                placement: 'auto',
                disableBeacon: true
            },
            {
                target: '#navbar-fourcast-match',
                content: (
                    <div style={{ width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
                        <Row>
                            <img style={{ marginTop: '2rem', width: 325, marginRight: 'auto', marginLeft: 'auto' }} alt='Try Fourcast Match' src={fourcastMatchLogo} />
                        </Row>
                        <div style={{ marginTop: '3rem', width: '95%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <Row style={{ height: 125 }}>
                                <Col sm={12} style={{ height: '90%', width: '95%', marginLeft: 'auto', marginRight: 'auto'}}>
                                    <Row style={{ marginLeft: 'auto', marginRight: 'auto', height: 125, display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                        <h4 style={{ textAlign: 'center', marginBottom: 20, fontFamily: 'sans-serif', width: '100%', wordSpacing: 1, textShadow: '1px 1px 2px rgb(21, 134, 84)' }}>Get instantly matched with spaces and areas that fit exactly what you're looking for.</h4>
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{ height: 175 }}>
                                <Col sm={12} style={{ height: '90%', position: 'relative' }}>
                                    <Row style={{ marginLeft: 'auto', marginRight: 'auto', height: 175 }}>
                                        <div className='descriptionGraphic'>
                                            <div className='trapezoid'></div>
                                            <div className='trapezoid1'>
                                                <FaMapMarker id='trap1' />
                                                <FaMapMarker id='trap2' />
                                                <FaMapMarker id='trap3' />
                                            </div>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <Row style={{ textAlign: 'center', width: '70%', marginLeft: 'auto', marginRight: 'auto', height: 100 }}>
                            <Link to="/customSearch" style={{ textDecoration: 'none', marginLeft: 'auto', marginRight: 'auto', marginBottom: 'auto' }}>
                                <Button
                                    variant="contained"
                                    category='fourcastMatch'
                                    style={{ height: 40, width: 200, fontSize: 14, backgroundColor: 'rgb(35, 126, 85)', color: 'white', outline: 'none' }}
                                >
                                Try Fourcast Match
                                </Button>
                            </Link>
                        </Row>
                    </div>
                ),
                placement: 'auto',
                disableBeacon: true
            }
        ];

        const listingDetailsSteps = [
            {
                target: '.sideGrades',
                content: "Switch between TRAFFIC, DEMOGRAPHICS, COMPETITION, and PROPERTY sections.",
                placement: 'auto',
                disableBeacon: true
            }
        ];

        return (
            <div className="app">
                <Joyride
                    callback={this.handleJoyrideCallback}
                    run={start}
                    stepIndex={stepIndex}
                    steps={page === 'customMap' ? customMapSteps : page === 'mapIntroListing' ? mapIntroSteps : page === 'listingDetails' ? listingDetailsSteps : null}
                    showProgress
                    continuous
                />
            </div>
        );
    }
}


export default StartJoyRide;