import React, { Component } from 'react';
import { Card, CardActions, CardContent, Button, Fab } from '@material-ui/core';
import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Row, Col } from 'reactstrap';

class TrafficFilters extends Component {
    
    state = { 
        trafficFilters: this.props.values.trafficFilters,
    }
    
    continue = e => {
        this.props.nextStep();
        this.props.setFilters('trafficFilters', this.state.trafficFilters);
    }

    back = e => {
        this.props.prevStep();
    }

    render() { 

        const FilterTagTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} />
            ))(({ theme }) => ({
                [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: 'snow',
                color: 'black',
                fontWeight: 'bold',
                maxWidth: 300,
                fontSize: theme.typography.pxToRem(14),
                padding: 25,
                border: '2px solid rgb(52, 181, 122)',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                },
        }));
        
        return ( 
            <div style={{ backgroundColor: 'whitesmoke', height: '100vh', paddingTop: 50 }}>
                <Card className='customCardBackgroundColor' style={{ width: '75vw', height: '75vh', margin: 'auto', overflowY: 'scroll' }}>
                    <CardContent>
                        <h2 style={{ textAlign: 'center', marginTop: 25 }}>Traffic Filters</h2>
                        <Row style={{ width: 850, height: 115, marginLeft: 'auto', marginRight: 'auto', marginTop: 100 }}>
                            <Col sm={4}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div id='nearAttraction'>
                                        <FilterTagTooltip arrow placement="top"
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Spaces that are within a 250 meter radius (~5 min walk) of a major attraction, which can generate traffic and tourists in the area.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                onClick={(e) => {
                                                    const value = 'nearAttraction';
                                                    const checked = this.state.trafficFilters.includes('nearAttraction');
                                                    let filterTag = checked ? this.state.trafficFilters.filter((tags) => tags !== value) : [...this.state.trafficFilters, value]
                                                    this.setState({ trafficFilters: filterTag });
                                                }}
                                                style={ this.state.trafficFilters.includes('nearAttraction') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 200, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 200, fontSize: 12, outline: 'none' }}
                                            >
                                            Near Major Attraction
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={4}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div id='seasonal'>
                                        <FilterTagTooltip arrow placement="top"
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas where traffic fluctuates depending on the season or time of year.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                category='seasonal'
                                                checked={this.state.trafficFilters.includes('seasonal')}
                                                onClick={(e) => {
                                                    const value = 'seasonal';
                                                    const checked = this.state.trafficFilters.includes('seasonal');
                                                    let filterTag = checked ? this.state.trafficFilters.filter((tags) => tags !== value) : [...this.state.trafficFilters, value]
                                                    this.setState({ trafficFilters: filterTag });
                                                }}
                                                style={ this.state.trafficFilters.includes('seasonal') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 200, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 200, fontSize: 12, outline: 'none' }}
                                            >
                                            Seasonal
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={4}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <FilterTagTooltip arrow placement="top"
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas where traffic is high mostly during the day.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                onClick={(e) => {
                                                    const value = 'busyDayTime';
                                                    const checked = this.state.trafficFilters.includes('busyDayTime');
                                                    let filterTag = checked ? this.state.trafficFilters.filter((tags) => tags !== value) : [...this.state.trafficFilters, value]
                                                    this.setState({ trafficFilters: filterTag });
                                                }}
                                                style={ this.state.trafficFilters.includes('busyDayTime') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 200, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 200, fontSize: 12, outline: 'none' }}
                                            >
                                            Heavy Daytime Traffic
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ width: '80%', height: 100, marginLeft: 'auto', marginRight: 'auto' }}>
                            <Col sm={4}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <FilterTagTooltip arrow  placement="left"
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas where traffic is high mostly during the weekdays, Monday-Friday.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                category='busyMonThruFri'
                                                checked={this.state.trafficFilters.includes('busyMonThruFri')}
                                                onClick={(e) => {
                                                    const value = 'busyMonThruFri';
                                                    const checked = this.state.trafficFilters.includes('busyMonThruFri');
                                                    let filterTag = checked ? this.state.trafficFilters.filter((tags) => tags !== value) : [...this.state.trafficFilters, value]
                                                    this.setState({ trafficFilters: filterTag });
                                                }}
                                                style={ this.state.trafficFilters.includes('busyMonThruFri') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 200, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 200, fontSize: 12, outline: 'none' }}
                                            >
                                            Heavy Mon-Fri Traffic
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={4}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <FilterTagTooltip arrow
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas that have weekend traffic, Friday-Sunday.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                onClick={(e) => {
                                                    const value = 'busyWeekend';
                                                    const checked = this.state.trafficFilters.includes('busyWeekend');
                                                    let filterTag = checked ? this.state.trafficFilters.filter((tags) => tags !== value) : [...this.state.trafficFilters, value]
                                                    this.setState({ trafficFilters: filterTag });
                                                }}
                                                style={ this.state.trafficFilters.includes('busyWeekend') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 200, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 200, fontSize: 12, outline: 'none' }}
                                            >
                                            Heavy Weekend Traffic
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={4}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <FilterTagTooltip arrow placement="right"
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas that have clusters of nightlife spots that are open late.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                category='busyNightLife'
                                                checked={this.state.trafficFilters.includes('busyNightLife')}
                                                onClick={(e) => {
                                                    const value = 'busyNightLife';
                                                    const checked = this.state.trafficFilters.includes('busyNightLife');
                                                    let filterTag = checked ? this.state.trafficFilters.filter((tags) => tags !== value) : [...this.state.trafficFilters, value]
                                                    this.setState({ trafficFilters: filterTag });
                                                }}
                                                style={ this.state.trafficFilters.includes('busyNightLife') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 200, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 200, fontSize: 12, outline: 'none' }}
                                            >
                                            Heavy Nightlife
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                        <CardActions>
                            <Button style={{ marginTop: 50, marginRight: 20, marginLeft: 'auto' }} variant="contained" color="default" size="medium" onClick={() => this.back()}>Back</Button>
                            <Button style={{ marginTop: 50, marginRight: 'auto', marginLeft: 30, backgroundColor: '#007FFF', color: 'white' }} variant="contained" size="medium" onClick={() => this.continue()}>Continue</Button>
                        </CardActions>
                    </CardContent>
                </Card>
            </div>
        );
    }
}
 
export default TrafficFilters;

