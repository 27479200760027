import React, { Component } from 'react';
import { FaCloudMoon, FaCloudSun } from 'react-icons/fa';
import { Row, Col } from 'reactstrap';
import './traffic.css';

class MobileBusyHours extends Component {
    
    
    render() {

        const { screenWidth, thisListing, thisListing: { yelpData: { businesses } = {}} = {}} = this.props;

        const { filterables: { busyHours: { openingTimes: { openFiveAm: thisOpenFiveAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { openingTimes: { openFiveThirtyAm: thisOpenFiveThirtyAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { openingTimes: { openSixAm: thisOpenSixAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { openingTimes: { openSixThirtyAm: thisOpenSixThirtyAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { openingTimes: { openSevenAm: thisOpenSevenAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { openingTimes: { openSevenThirtyAm: thisOpenSevenThirtyAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { openingTimes: { openEightAm: thisOpenEightAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { openingTimes: { openEightThirtyAm: thisOpenEightThirtyAm } = {}} = {}} = {}} = this.props;

        const { filterables: { busyHours: { closingTimes: { closeMidnight: thisCloseMidnight } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { closingTimes: { closeMidnightThirty: thisCloseMidnightThirty } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { closingTimes: { closeOneAm: thisCloseOneAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { closingTimes: { closeOneThirtyAm: thisCloseOneThirtyAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { closingTimes: { closeTwoAm: thisCloseTwoAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { closingTimes: { closeTwoThirtyAm: thisCloseTwoThirtyAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { closingTimes: { closeThreeAm: thisCloseThreeAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { closingTimes: { closeThreeThirtyAm: thisCloseThreeThirtyAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { closingTimes: { closeFourAm: thisCloseFourAm } = {}} = {}} = {}} = this.props;

        
        let earlyMorningCount = ((thisOpenFiveAm + thisOpenFiveThirtyAm + thisOpenSixAm + thisOpenSixThirtyAm + thisOpenSevenAm + thisOpenSevenThirtyAm + thisOpenEightAm + thisOpenEightThirtyAm) * .25);
        
        if (earlyMorningCount) {
            
            let dayTrafficGaugeScore = 0;

            // Calculate Daytime Traffic Gauge Score !!!!!!!!!!!!!!!!!!!!!

            // These figures were taken from the Traffic Volatility Function in "tasks"
            const globalHighestDayPopChange = 4818;
            const dayPopChangeMedian = 60;
            const globalLowestDayPopChange = 3;
            
            // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            
            const secondaryNeighb = thisListing && thisListing.attomData && thisListing.attomData.geoIdV4 && thisListing.attomData.geoIdV4.filter(neighb => ((neighb.type === 'N2') || (neighb.type === 'N3')));
            const thisDayPop = parseFloat(secondaryNeighb && secondaryNeighb[0].commV4Data && secondaryNeighb[0].commV4Data.population_Daytime);
            const thisPopCy = parseFloat(secondaryNeighb && secondaryNeighb[0].commV4Data && secondaryNeighb[0].commV4Data.population);
            const dayPopChange = ((thisPopCy > 0) && (thisDayPop > 0)) ? (thisPopCy < thisDayPop) ? (((thisDayPop - thisPopCy)/thisPopCy) * 100) : (((thisPopCy - thisDayPop)/thisPopCy) * -100) : 0;

            if (dayPopChange > dayPopChangeMedian) {
                dayTrafficGaugeScore = earlyMorningCount + Math.ceil((((dayPopChange - dayPopChangeMedian) / (globalHighestDayPopChange - dayPopChangeMedian)) * 50) + 50);
            } else if (dayPopChange < dayPopChangeMedian) {
                dayTrafficGaugeScore = earlyMorningCount + Math.ceil((((dayPopChange - dayPopChangeMedian) / (globalLowestDayPopChange - dayPopChangeMedian)) * -50) + 50);
            } else {
                dayTrafficGaugeScore = earlyMorningCount + 50;
            }

            if (dayTrafficGaugeScore > 100) {
                dayTrafficGaugeScore = 100;
            } else if (dayTrafficGaugeScore < 5) {
                dayTrafficGaugeScore = 5;
            }

            const setDayTrafficGauge = (score) => {
                setTimeout(() => {
                    const dayTrafficGauge = document.querySelector('.dayTrafficGaugePointer');
                    let dayRotation = score;                
                    dayTrafficGauge && dayTrafficGauge.style.setProperty('--bottomDay', dayRotation);
                }, 100, { once: true });
            }
    
            dayTrafficGaugeScore && setDayTrafficGauge(dayTrafficGaugeScore);

        }



        // Calculate Nightlife Gauge Score !!!!!!!!!!!!!!!!!!!!!!!!!!!
        
        const nightLifeCount = [thisCloseMidnight, thisCloseMidnightThirty, thisCloseOneAm, thisCloseOneThirtyAm, thisCloseTwoAm, thisCloseTwoThirtyAm, thisCloseThreeAm, thisCloseThreeThirtyAm, thisCloseFourAm];
        let nightlifeBusinesses = [];

        if (nightLifeCount) {
            
            let nightlifeGaugeScore = 0;

            const nightlifeCategories = ["bars", "adultentertainment", "barcrawl", "beergardens", "clubcrawl", "comedyclubs", "countrydancehalls", "danceclubs", "dancerestaurants", "fasil", "jazzandblues", "karaoke", "musicvenues", "pianobars", "poolhalls"];

            // pushes businesses with a nightlife "category" aka bars, clubs, lounge, etc.
            for (let biz of businesses) {
                for (let category of biz.categories) {
                    if (nightlifeCategories.some(nightCategory => category.alias === nightCategory)) {
                        nightlifeBusinesses.push(biz);
                    };
                }
            }

            nightlifeBusinesses = [...new Set([...nightlifeBusinesses])];
            
            // for businesses open til midnight or later, increase the points given the later they are open til
            let multiplier = .75;
            for (let i = 0; i < nightLifeCount.length; i++) {
                nightlifeGaugeScore += nightLifeCount[i] * multiplier;
                multiplier += .25;
            }
            
            // award .5 points for every 100 reviews of nightlife businesses
            for (let nightlifeBiz of nightlifeBusinesses) {
                nightlifeGaugeScore += ((nightlifeBiz.review_count / 100) * .5);
            }

            if (nightlifeGaugeScore > 100) {
                nightlifeGaugeScore = 100;
            } else if (nightlifeGaugeScore < 5) {
                nightlifeGaugeScore = 5;
            }

            const setNightlifeGauge = (score) => {
                setTimeout(() => {
                    const nightlifeGauge = document.querySelector('.nightlifeGaugePointer');
                    let nightRotation = score;                
                    nightlifeGauge && nightlifeGauge.style.setProperty('--bottomNight', nightRotation);
                }, 100, { once: true });
            }

            nightlifeGaugeScore && setNightlifeGauge(nightlifeGaugeScore);
        }
        

        return (
            <div id='busyHoursData'>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr', width: `${screenWidth < 1000 ? screenWidth : 420}`, height: 180 }}>
                    <div style={{ position: 'relative' }}>
                        <div id='daytimeTrafficTitle'>Daytime Traffic</div>
                        <div id='nightlifeTitle'>Nightlife</div>
                        <FaCloudMoon className='moon' />
                        <FaCloudSun className='sun' />
                    </div>
                    <div style={{ position: 'relative' }} >
                        <div className='dayTrafficGauge' id='dayGauge'></div>
                        <div className='dayTrafficGaugePointer' id='dayGaugePointer'></div>
                        <div className='dayTrafficGaugeHigh'>High</div>
                        <div className='dayTrafficGaugeLow'>Low</div>
                        <div className='nightlifeGaugeHigh'>High</div>
                        <div className='nightlifeGauge'></div>
                        <div className='nightlifeGaugePointer'></div>
                        <div className='nightlifeGaugeLow'>Low</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MobileBusyHours;