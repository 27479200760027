import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import { Nav } from 'reactstrap';
import { Button, Menu, MenuItem, Container, AppBar, Box, Toolbar, Divider } from '@mui/material';
import { AiOutlineMenu } from 'react-icons/ai';
import { styled, alpha } from '@mui/material/styles';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { Link } from 'react-router-dom';
import fourcastLogo from '../../Assets/fourcast_alt_logo_white.png';
import fourcastMobileLogo from '../../Assets/fourcast_alt_logo_white.png';
import fourcastShortLogo from '../../Assets/fourcast_short_logo_white.png';
import fourcastMatchLogo from '../../Assets/fourcast_match_logo_black.png';
import Logout from '../auth/logout';
import LoginModal from '../auth/loginModal';
import RegisterModal from '../auth/registerModal';
import './navbar.css';
import SavedModal from '../savedProperties/savedModal';
 
class CustomNavbar extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: 1000,
            mobileMode: false,
            anchorEl: null,
            mobileAnchorEl: null
        }
    }
    
    static propTypes = {
        auth: PropTypes.object.isRequired
    }

    componentDidMount() {
        window && window.addEventListener('resize', this.mobileMode.bind(this));
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ mobileMode: true, screenWidth: screenWidth });
        } else {
            this.setState({ mobileMode: false });
        }
        
    }

    componentWillUnmount() {
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
    }

    mobileMode() {
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth, mobileMode: true });
        } else {
            this.setState({ mobileMode: false });
        }
    }
    
    render() { 

        const { user, isAuthenticated, paymentInfoNeeded } = this.props.auth;
        const { auth } = this.props;

        const pathname = this.props.currentPath;


        const StyledMenu = styled((props) => (
            <Menu
              elevation={0}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              {...props}
            />
          ))(({ theme }) => ({
            '& .MuiPaper-root': {
              borderRadius: 2,
              marginTop: theme.spacing(1),
              minWidth: 200,
              minHeight: 300,
              textAlign: 'right',
              backgroundColor: 'whitesmoke',
              color:
                theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
              boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
              '& .MuiMenu-list': {
                padding: '4px 0',
              },
              '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                  fontSize: 18,
                  color: theme.palette.text.secondary,
                  marginRight: theme.spacing(1.5),
                },
                '&:active': {
                  backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                  ),
                },
              },
            },
        }));

        const open = Boolean(this.state.anchorEl);
        const { anchorEl } = this.state;
        const handleClick = (event) => {
            this.setState({ anchorEl: event.currentTarget });
        };
        const handleClose = () => {
            this.setState({ anchorEl: false });
        };


        const authLinks = (
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
                {user ? <Nav style={{ justifyContent: 'center' }}><Link style={{ color: 'white', textDecoration: 'none', fontSize: 13 }} to={user.id !== undefined ? `/user/${user.id}` : `/user/${user._id}`}>
                    MY ACCOUNT
                </Link></Nav> : null}
                <Nav style={{ justifyContent: 'center' }}>
                    <Logout />
                </Nav>
            </div>
        );

        const guestLinks = (
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', justifyContent: 'center', alignItems: 'center' }}>
               <Nav style={{ justifyContent: 'center' }}>
                    <LoginModal />
                </Nav>
               <Nav style={{ justifyContent: 'center' }}>
                    <RegisterModal />
                </Nav>
            </div>
        );

        // MOBILE 

        const MobileStyledMenu = styled((props) => (
            <Menu
              elevation={0}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              {...props}
            />
          ))(({ theme }) => ({
            '& .MuiPaper-root': {
              borderRadius: 2,
              marginTop: theme.spacing(2.5),
              minWidth: '100%',
              minHeight: '50%',
              textAlign: 'right',
              backgroundColor: 'whitesmoke',
              color:
                theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
              boxShadow:
                'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
              '& .MuiMenu-list': {
                padding: '4px 0',
              },
              '& .MuiMenuItem-root': {
                '& .MuiSvgIcon-root': {
                  fontSize: 18,
                  color: theme.palette.text.secondary,
                  marginRight: theme.spacing(0),
                },
                '&:active': {
                  backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                  ),
                },
              },
            },
        }));
  
        const mobileOpen = Boolean(this.state.mobileAnchorEl);
        const { mobileAnchorEl } = this.state;
        const handleMobileClick = (event) => {
            this.setState({ mobileAnchorEl: event.currentTarget });
        };
        const handleMobileClose = () => {
            this.setState({ mobileAnchorEl: false });
        };

        const { mobileMode } = this.state;

        return ( 
            <>
            {!mobileMode ? 
            (<div className='navbarBackground'>
                <Container className='navbar-items' style={{ height: '100%', display: 'grid', gridTemplateColumns: '1fr 2fr 2fr 1fr' }}>
                    <div className='navbar-fourcast-logo' style={{ width: '100%' }}>
                        <Link to='/' className='router-link-exact-active router-link-active'>
                            <Image className='logo' style={{ height: 40 }} src={fourcastLogo} id='fourcastLogo' />
                        </Link>
                    </div>
                    <div className='navbar-fourcast-features'>
                        <div style={{ justifyContent: 'center', display: 'flex' }}>
                            <Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                variant='link'
                                endIcon={<MdKeyboardArrowDown />}
                                style={{ color: 'white', outline: 'none', textDecoration: 'none', fontSize: 13, marginTop: 2.5 }}
                            >
                                For Lease
                            </Button>
                            <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                style={{ width: 300 }}
                            >
                                <Link style={{ textDecoration: 'none', color: 'black' }} to='/listings' className='navbar-item'>
                                    <MenuItem  className='navbar-item' style={{ width: '100%', height: 50, fontWeight: 'bold' }}>New York</MenuItem>
                                </Link>
                                <MenuItem style={{ width: '100%', height: 50, fontWeight: 'bold' }} disabled>Coming Soon...</MenuItem>
                                <MenuItem style={{ width: '100%', height: 50, fontWeight: 'bold' }} disabled>Los Angeles</MenuItem>
                                <MenuItem style={{ width: '100%', height: 50, fontWeight: 'bold' }} disabled>Chicago</MenuItem>
                                <MenuItem style={{ width: '100%', height: 50, fontWeight: 'bold' }} disabled>& more</MenuItem>
                            </StyledMenu>
                        </div>
                        <div className='navbar-item' style={{ justifyContent: 'center', display: 'flex', color: 'white', outline: 'none', textDecoration: 'none', fontSize: 13, marginTop: 2.5 }}>
                            <Link style={{ textDecoration: 'none', color: 'white', fontSize: 13 }} to={`/reports`}>REPORTS</Link>
                        </div>
                        <div className='navbar-item' style={{ justifyContent: 'center', display: 'flex', color: 'white', outline: 'none', textDecoration: 'none', fontSize: 13, marginTop: 2.5 }}>
                            {user ? 
                                <Link style={{ textDecoration: 'none', color: 'white', fontSize: 13 }} to={`/user/${user._id}`}>
                                    <SavedModal />
                                </Link> : (
                                <Link style={{ textDecoration: 'none', color: 'white', fontSize: 13 }}>
                                    <LoginModal navbarText linkText='SAVED' />
                                </Link>
                            )}
                        </div>
                    </div>
                    <div className='navbar-fourcast-services'>
                        <div style={{ justifyContent: 'center', display: 'flex', marginLeft: 'auto', marginRight: 5, border: '1px solid white', borderRadius: 5, padding: '5px 10px' }}>
                            <Link to='/customSearch' id='navbar-fourcast-match' className='navbar-item' style={{ textDecoration: 'none', color: 'white', fontWeight: 'bolder', fontSize: 13 }}>FOURCAST MATCH</Link>
                        </div>
                        <div className='navbar-item' style={{ justifyContent: 'center', display: 'flex', color: 'white', outline: 'none', textDecoration: 'none', fontSize: 13, marginRight: 'auto', marginLeft: 5, border: '1px solid white', borderRadius: 5, padding: '5px 10px' }}>
                            {user ? 
                                <Link style={{ textDecoration: 'none', color: 'white', fontWeight: 'bolder', fontSize: 13 }} to={`/user/${user._id}`}>
                                    SUBMIT LISTINGS
                                </Link> : (
                                <Link style={{ textDecoration: 'none', color: 'white', fontWeight: 'bolder', fontSize: 13 }}>
                                    <LoginModal navbarText linkText='SUBMIT LISTINGS' />
                                </Link>
                            )}
                        </div>
                    </div>
                    { isAuthenticated ? authLinks : guestLinks}
                </Container>
                {(isAuthenticated && paymentInfoNeeded) ? <Redirect to={{
                    pathname: '/registration/payment-method',
                    state: { token: auth.token }
                }} /> : null}
            </div>) : (
            <div> 
                <AppBar position="static">
                    <Container id='mobileNavbarBackground' maxWidth="xl">
                        <Toolbar disableGutters>
                        <div className='navbar-fourcast-logo'>
                            <Link to='/' className='router-link-exact-active router-link-active'>
                                <Image src={pathname !== '/listings' ? fourcastMobileLogo : fourcastShortLogo} style={{ height: 40 }} />
                            </Link>
                        </div>

                        <Box sx={{ flexGrow: 0, marginLeft: 'auto' }}>
                            <div>
                                <div style={{ justifyContent: 'center', display: 'flex' }}>
                                    <Button
                                        id="basic-button"
                                        aria-controls={mobileOpen ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={mobileOpen ? 'true' : undefined}
                                        onClick={handleMobileClick}
                                        variant='link'
                                        style={{ color: 'white', outline: 'none', textDecoration: 'none', fontSize: 13, marginTop: 2.5 }}
                                    >
                                        <AiOutlineMenu style={{ width: '90%', height: '90%' }} />
                                    </Button>
                                    <MobileStyledMenu
                                        id="demo-customized-menu"
                                        MenuListProps={{
                                        'aria-labelledby': 'demo-customized-button',
                                        }}
                                        anchorEl={mobileAnchorEl}
                                        open={mobileOpen}
                                        onClose={handleMobileClose}
                                        style={{ width: '90%', zIndex: 4 }}
                                    >
                                        <MenuItem style={{ width: '100%', height: 50, fontWeight: 'bold' }}>
                                        <div className='navbar-item' style={{ justifyContent: 'center', display: 'flex', color: 'black', outline: 'none', textDecoration: 'none', fontSize: 13, marginTop: 2.5 }}>
                                            <Link style={{ textDecoration: 'none', color: 'black', fontSize: 13 }} to={`/listings`}>Browse Listings</Link>
                                        </div>
                                        </MenuItem>
                                        <Divider variant="middle" />
                                        <MenuItem style={{ width: '100%', height: 50, fontWeight: 'bold' }}>
                                        <div style={{ justifyContent: 'center', display: 'flex', color: 'black', outline: 'none', textDecoration: 'none', fontSize: 13, marginTop: 2.5 }}>
                                            <Link to='/customSearch' style={{ textDecoration: 'none', color: 'black', fontWeight: 'bolder', fontSize: 13 }}><Image src={fourcastMatchLogo} style={{ height: 25, marginTop: 5 }} /></Link>
                                        </div>
                                        </MenuItem>
                                        <Divider variant="middle" />
                                        <MenuItem style={{ width: '100%', height: 50, fontWeight: 'bold' }}>
                                        <div style={{ justifyContent: 'center', display: 'flex', color: 'black', outline: 'none', textDecoration: 'none', fontSize: 13, marginTop: 2.5 }}>
                                            <Link style={{ textDecoration: 'none', color: 'black', fontSize: 13 }} to={`/reports`}>Reports</Link>
                                        </div>
                                        </MenuItem>
                                        <Divider variant="middle" />
                                        <MenuItem style={{ width: '100%', height: 50, fontWeight: 'bold', color: 'black' }}>
                                        <div style={{ justifyContent: 'center', display: 'flex', color: 'black', outline: 'none', textDecoration: 'none', fontSize: 13, marginTop: 2.5 }}>
                                            {user ? 
                                                <Link style={{ textDecoration: 'none', color: 'black', fontSize: 13 }} to={`/user/${user._id}`}>
                                                    <SavedModal />
                                                </Link> : (
                                                <Link style={{ textDecoration: 'none', color: 'black', fontSize: 13 }}>
                                                    <LoginModal linkText='Saved' />
                                                </Link>
                                            )}
                                        </div>
                                        </MenuItem>
                                        <Divider variant="middle" />
                                        <MenuItem style={{ width: '100%', height: 50, fontWeight: 'bold' }}>
                                        <div style={{ justifyContent: 'center', display: 'flex', color: 'black', outline: 'none', textDecoration: 'none', fontSize: 13, marginTop: 2.5 }}>
                                            {user ? 
                                                <Link style={{ textDecoration: 'none', color: 'black', fontWeight: 'bolder', fontSize: 13 }} to={`/user/${user._id}`}>
                                                    SUBMIT LISTINGS
                                                </Link> : (
                                                <Link style={{ textDecoration: 'none', color: 'black', fontWeight: 'bolder', fontSize: 13 }}>
                                                    <LoginModal linkText='Submit Listings' />
                                                </Link>
                                            )}
                                        </div>
                                        </MenuItem>
                                        <Divider variant="middle" />
                                        <MenuItem style={{ width: '100%', height: 50, fontWeight: 'bold' }}>
                                        { isAuthenticated ? (
                                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
                                            {user ? 
                                                <Link style={{ color: 'black', textDecoration: 'none', fontSize: 13 }} to={user.id !== undefined ? `/user/${user.id}` : `/user/${user._id}`}>
                                                    My Account
                                                </Link> : null}
                                            
                                            </div>) : 
                                            (<Link style={{ textDecoration: 'none', color: 'black', fontSize: 13 }}>
                                                <RegisterModal style={{ color: 'black' }} />
                                            </Link>
                                        )}
                                        </MenuItem>
                                        <Divider variant="middle" />
                                        <MenuItem style={{ width: '100%', height: 50, fontWeight: 'bold' }} autoFocus>
                                        { isAuthenticated ?
                                            (<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
                                            {user ? 
                                                (<Link style={{ textDecoration: 'none', color: 'black', fontSize: 13 }}>
                                                <Logout />
                                                </Link>) : null}
                                            </div>) : 
                                            (<Link style={{ textDecoration: 'none', color: 'black', fontSize: 13 }}>
                                                <LoginModal linkText='LOG IN' />
                                            </Link>
                                            )
                                        }
                                        </MenuItem>
                                    </MobileStyledMenu>
                                </div>
                            </div>
                        </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
                {(isAuthenticated && paymentInfoNeeded) ? <Redirect to={{
                    pathname: '/registration/payment-method',
                    state: { token: auth.token }
                }} /> : null}
            </div>)}
            </>
        ); 
    }
}

const mapStateToProps = state => ({
    auth: state.auth
})
 
export default connect(mapStateToProps, null)(CustomNavbar);

