import React, { Component } from 'react';
import { Alert, Stack, AlertTitle } from '@mui/material';
import { Card, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addListing } from '../../actions/listingActions';
import { updateUser } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import './createListing.css';
import axios from 'axios';

// Pointing Axios baseURL towards AWS API Gateway URL
// Will most likely be changed again at some point because of the /dev
const axiosAWS = axios.create({
    baseURL: 'https://bgeg45v1tk.execute-api.us-east-1.amazonaws.com/dev'
});

class CreateListingConfirm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: null,
            updatedListingsNumber: null,
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { error } = this.props;
        const { updatedListingsNumber } = this.state;

        if (error !== prevProps.error) {
            
            //Check for Create Listing Error
            if (error.id === 'ADD_LISTING_FAIL') {
                this.setState({ msg: error.msg.msg })
            } else {
                this.setState({ msg: null });
            }
        }

        if (updatedListingsNumber !== prevState.updatedListingsNumber) {
            this.setState({ successAlert: true });
        }
    }

    
    onSubmit = async e => {
        e.preventDefault();

        const { values, user, auth, thisUsersListings } = this.props;
        
        const capitalizeIt = (string) => {
            let splitArr = string.split(" ");
            for (let i = 0; i < splitArr.length; i++) {
                splitArr[i] = splitArr[i][0].toUpperCase() + splitArr[i].substring(1);
            }
            let joinedArr = splitArr.join(" ");
            return joinedArr;
        };
        
        let capAddress = capitalizeIt(values.streetAddress);
        let squareFeet1 = parseFloat(values.squareFeet1);
        let squareFeet2 = (values.squareFeet2 !== null && values.squareFeet2.length > 0) ? parseFloat(values.squareFeet2) : null;
        let pricePerSquareFoot = parseFloat(values.pricePerSquareFoot);
        let frontage = parseFloat(values.frontage);
        const checkDivisible = (((values.squareFeet1 !== null && values.squareFeet1.length > 0) && (values.squareFeet2 !== null && values.squareFeet2.length > 0)) && (!values.propertyFeatures.includes('divisible'))) ? values.propertyFeatures.push('divisible') : null;
    

        const newListing = {
            listingAddress: `${capAddress}, ${values.city}, ${values.state}, ${values.zip}`,
            squareFeet: squareFeet2 !== null ? squareFeet1 <= squareFeet2 ? [squareFeet1, squareFeet2] : [squareFeet2, squareFeet1] : [squareFeet1],
            pricePerSquareFoot: pricePerSquareFoot,
            frontage: frontage,
            propertyFeatures: values.propertyFeatures,
            images: values.images,
            createdBy: this.props.user,
            active: true
        };
        
        await this.props.addListing(newListing);

        let currentListings = thisUsersListings && thisUsersListings.map(listing => listing.listingAddress);

        let newListings = [...currentListings, newListing.listingAddress]; 
        
        const updatedUser = {
            userId: user._id,
            accountType: user.accountType,
            engagement: {
                listings: newListings,
                searches: user.engagement.searches,
                savedListings: user.engagement.savedListings,
                savedSearches: user.engagement.savedSearches
            }
        };
        
        updatedUser && updatedUser ? await this.props.updateUser(updatedUser) : console.log('User undefined');

        const existingNumberOfListings = newListings && newListings ? newListings.length : 1;

        let tokenConfig = thisToken => {
    
            // Get Token from localStorage
            const token = thisToken;
        
            // Headers 
            const config = {
                headers: {
                    "Content-type": "application/json"
                }
            }
        
            // If token, add to headers
            if (token) {
                config.headers['Authorization'] = token;
            }
        
            return config;
        }

        await axiosAWS.post('/api/subscriptions/update', { user, existingNumberOfListings: existingNumberOfListings }, tokenConfig(auth.token))
            .then(res => {
                const { data } = res.data.items;
                const updatedQuantityCharged = data && data[0].quantity;
                this.setState({ updatedListingsNumber: updatedQuantityCharged });
            })
            .catch(err => {
                console.log(err);
            });
        
        
    }

    render() {
        
        const { values } = this.props;
        
        const capitalizeIt = (string) => {
            if (string) {
                let splitArr = string.split(" ");
                for (let i = 0; i < splitArr.length; i++) {
                    splitArr[i] = splitArr[i][0].toUpperCase() + splitArr[i].substring(1);
                }
                let joinedArr = splitArr.join(" ");
                return joinedArr;
            }
        };
        
        let capAddress = values ? capitalizeIt(values.streetAddress) : null;

        const propFeatureSwitch = (feature) => {
            switch(feature) {
                case 'cornerLocation':
                    return (
                        'Corner Location'
                    )
                case 'bigFrontage': 
                    return (
                        'Big Frontage'
                    )
                case 'divisible':
                    return (
                        'Divisible'
                    )
                case 'multiLevel':
                    return (
                        'Multi Level'
                    )
                case 'newConstruction':
                    return (
                        'New Construction'
                    )
                case 'outdoorSpace':
                    return (
                        'Outdoor Space'
                    )
                default:
                    return (
                        'N/A'
                    )
            }
        }
    

        const newListing = {
            listingAddress: `${capAddress}, ${values.city}, ${values.state}, ${values.zip}`,
            squareFeet1: values.squareFeet1,
            squareFeet2: values.squareFeet2,
            pricePerSquareFoot: values.pricePerSquareFoot,
            frontage: values.frontage,
            propertyFeatures: values.propertyFeatures,
            images: values.images,
            createdBy: this.props.user,
            active: true
        };

        return(
            <>
            <Card className="addListing" style={{ textAlign: 'center' }}>
                { this.state.msg ? (
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity='error' variant="outlined">{`Error! ${this.state.msg}`}</Alert>
                    </Stack>
                ) : this.state.successAlert ? (
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity='success' variant="outlined">Success! Listings Submitted.</Alert>
                    </Stack>
                ) : null}
                <h4 style={{ textAlign: 'center', textDecoration: 'underline', textUnderlineOffset: 5 }}>Confirm New Listing</h4>
                <div style={{ marginTop: 40, width: '75%', marginLeft: 'auto', marginRight: 'auto', fontFamily: 'Arial, Helvetica, sans-serif' }}>
                    <div style={{ fontSize: 24, fontWeight: 'bold', textAlign: 'center', color: 'rgb(35, 126, 85)' }}>
                       {values && `${newListing.listingAddress}`}
                    </div>
                    <div style={{ marginTop: 30, display: 'grid', gridTemplateColumns: '1fr 2fr', gap: 2 }}>
                       <div style={{ textAlign: 'right', fontSize: 16 }}>Sqft:</div>
                       <div style={{ fontWeight: 'bold', fontSize: 18, color: 'rgb(35, 126, 85)' }}>{values && (values.squareFeet2 !== null && values.squareFeet2.length > 0) ? ` ${newListing.squareFeet1} - ${newListing.squareFeet2}` : ` ${newListing.squareFeet1}`}</div>
                    </div>
                    <div style={{ marginTop: 15, display: 'grid', gridTemplateColumns: '1fr 2fr', gap: 2 }}>
                        <div style={{ textAlign: 'right', fontSize: 16 }}>Price per Sqft / yr:</div>
                        <div style={{ fontWeight: 'bold', fontSize: 18, color: 'rgb(35, 126, 85)' }}>{values && ` $${newListing.pricePerSquareFoot}`}</div>
                    </div>
                    <div style={{ marginTop: 15, display: 'grid', gridTemplateColumns: '1fr 2fr', gap: 2 }}>
                       <div style={{ textAlign: 'right', fontSize: 16 }}>Frontage:</div>
                       <div style={{ fontWeight: 'bold', fontSize: 18, color: 'rgb(35, 126, 85)' }}>{values && ` ${newListing.frontage} ft`}</div>
                    </div>
                    <div style={{ marginTop: 15, display: 'grid', gridTemplateColumns: '1fr 2fr', gap: 2 }}>
                        <div style={{ textAlign: 'right', fontSize: 16 }}>Property Features:</div>
                        <div style={{ fontWeight: 'bold', fontSize: 17, color: 'rgb(35, 126, 85)' }}>{values && values.propertyFeatures.length > 0 ? newListing.propertyFeatures.map((feature) => ((newListing.propertyFeatures.indexOf(feature)) === (newListing.propertyFeatures.length - 1)) ? ` ${propFeatureSwitch(feature)}` : ` ${propFeatureSwitch(feature)},`) : 'None'}</div>
                    </div>
                    <div style={{ marginTop: 15, display: 'grid', gridTemplateColumns: '1fr 2fr', gap: 2 }}>
                       <div style={{ textAlign: 'right', fontSize: 16 }}>Active:</div>
                       <div style={{ fontWeight: 'bold', fontSize: 18, color: 'rgb(35, 126, 85)' }}>True</div>
                    </div>
                </div>
                <Button variant='contained' style={{ marginTop: 40, backgroundColor: 'rgb(35, 126, 85)', color: 'white', outline: 'none' }} onClick={this.onSubmit} hidden={this.state.successAlert ? true : false}>Submit Listing</Button>
            </Card>
            </>
        )
    }
}

CreateListingConfirm.propTypes = {
    addListing: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    error: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    listing: state,
    error: state.error,
});

export default connect(mapStateToProps, { clearErrors, addListing, updateUser })(CreateListingConfirm);