import React, { Component } from 'react';
import { Container } from 'reactstrap';
import '../welcome/description.css';

class AboutStatement extends Component {
    
    render() { 
        return ( 
            <Container style={{ backgroundColor: 'white', height: 600, paddingTop: 25 }} fluid>
                <div style={{ width: 1150, height: 500, margin: 'auto', border: '10px solid rgba(52, 181, 122, .8)' }}>
                    <div style={{ height: 250, width: '55%', margin: 'auto', textAlign: 'center', marginTop: 125 }}>
                        <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 44, fontWeight: 'bold' }}>About Fourcast.</div>
                        <div style={{ paddingTop: 15, fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20 }}>Fourcast provides information on available retail spaces and the area surrounding it. We analyze raw hyperlocal data with our proprietary algorithms to produce scores and insights that help you find and compare compatible locations for your business.</div>
                    </div>
                </div>
            </Container>
        );
    }
}
 
export default AboutStatement;
