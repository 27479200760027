import React, { Component } from 'react';
import './listingMap.css';
import { Modal, InputAdornment, Button, Fab, Checkbox, Box, Tooltip, tooltipClasses, Stack, Chip, Divider, FormControl, FormGroup, FormControlLabel, TextField } from '@mui/material';
import { MdClose } from 'react-icons/md';
import { styled } from '@mui/material/styles';



const filterLabels = (label) => {    
    
    switch(label) {
        case "nearAttraction":
            return (
                "Near Major Attraction"
            )
        case "seasonal":
            return ( 
                "Seasonal"
            )
        case "busyDayTime":
            return ( 
                "Heavy Daytime Traffic"
            )
        case "busyMonThruFri":
            return (
                "Heavy Mon-Fri Traffic"
            )
        case "busyWeekend":
            return ( 
                "Heavy Weekend Traffic"
            )
        case "busyNightLife":
            return ( 
                "Heavy Nightlife"
            )
        case "localResidents":
            return (
                "Mostly Local Resident"
            )
        case "officeWorkers":
            return ( 
                "Mostly People at Work"
            )
        case "students":
            return (
                "Students"
            )
        case "highIncomeArea":
            return ( 
                "High Income Area"
            )
        case "genZ":
            return ( 
                "Gen Z"
            )
        case "millennialsTo34":
            return ( 
                "Young Millennials"
            )
        case "millennialsTo44":
            return ( 
                "Older Millennials"
            )
        case "genX":
            return ( 
                "Gen X"
            )
        case "boomers":
            return ( 
                "Boomers"
            )
        case "areasWithChildren":
            return ( 
                "Areas WITH Children"
            )
        case "affordableRE":
            return ( 
                "Affordable"
            )
        case "expensiveRE":
            return ( 
                "Expensive"
            )
        case "highEndBiz":
            return ( 
                "$$$$"

            )
        case "lowEndBiz":
            return ( 
                "$"

            )
        case "highBizDensity":
            return ( 
                "High Biz Density"

            )
        case "lowBizDensity" :
            return ( 
                "Low Biz Density"

            )
        case "cornerLocation":
            return ( 
                "Corner Location"

            )
        case "bigFrontage":
            return ( 
                "Big Frontage"
            )
        case "divisible":
            return ( 
                "Divisible"
            )
        case "multiLevel":
            return ( 
                "Multi Level"

            )
        case "newConstruction":
            return ( 
                "New Construction"

            )
        case "outdoorSpace":
            return ( 
                "Outdoor Space"

            )
        default:
            return null
    }
}



class MobileMapFiltersModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterBoxOpen: true,
            selected: null,
            score: null,
            minSF: 0,
            maxSF: 19999,
            minPrice: 0,
            maxPrice: 19999,
            filterTags: [],
            customSearchFilters: null,
            showAll: false,
            showUndisclosed: true,
            autocomplete: null,
            neighborhoods: [],
            searchedPlace: [],
            searchedPlaceSelected: null,
            listingsNearSearchedPlace: [],
            drawPolygonMode: false,
            polygonCoords: [],
            screenWidth: 390,
            modalOpen: false,
            filtersApplied: false
        }
        
    }
    
    async componentDidMount() {
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
        // setting mobile views
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth });
        }

        const { values } = this.props;
        values && this.setState({ minSF: values.minSF, maxSF: values.maxSF, minPrice: values.minPrice, maxPrice: values.maxPrice, filterTags: values.filterTags, showUndisclosed: values.showUndisclosed, neighborhoods: values.neighborhoods, searchedPlace: values.searchedPlace })

    }

    componentWillUnmount() {
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
    }

    mobileMode() {
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth });
        }
    }

    setMinSF = (value) => {
        this.setState({ minSF: value });
    }

    setMaxSF = (value) => {
        this.setState({ maxSF: value });
    }

    setMinPrice = (value) => {
        this.setState({ minPrice: value });
    }

    setMaxPrice = (value) => {
        this.setState({ maxPrice: value });
    }


    async applyFilters() {
        
        const { minSF, maxSF, minPrice, maxPrice, filterTags, showUndisclosed, neighborhoods, searchedPlace } = this.state;
        const stateFilterValues = { minSF, maxSF, minPrice, maxPrice, filterTags, showUndisclosed, neighborhoods, searchedPlace };
        await this.props.setMobileFilterModalState(stateFilterValues);

        this.setState({ modalOpen: false });
    }

    
    async clearAllState() {
        
        const stateFilterValues = { minSF: 0, maxSF: 19999, minPrice: 0, maxPrice: 19999, filterTags: [], showUndisclosed: true, neighborhoods: [], searchedPlace: [] };
        await this.props.setMobileFilterModalState(stateFilterValues);

        this.setState({ 
            minSF: 0,
            maxSF: 19999,
            minPrice: 0,
            maxPrice: 19999,
            filterTags: [],
            showUndisclosed: true,
            autocomplete: null,
            neighborhoods: [],
            searchedPlace: [],
            searchedPlaceSelected: null,
            listingsNearSearchedPlace: [],
            drawPolygonMode: false,
            polygonCoords: [],
            filtersApplied: false
        })
    }


    
    render() {


    const { neighborhoods, searchedPlace, screenWidth, modalOpen } = this.state;
    

    const FilterTagTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
        ))(({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'snow',
            color: 'black',
            fontWeight: 'bold',
            maxWidth: 300,
            fontSize: theme.typography.pxToRem(14),
            padding: 25,
            border: '2px solid rgb(52, 181, 122)',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
            },
    }));

    const modalStyle = {
        width: screenWidth,
        backgroundColor: 'white',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4
    };

    const getFilterTotalNum = () => {
        
        let totalNum = 0;

        this.state.minSF !== 0 ? totalNum += 1 : totalNum += 0;
        this.state.maxSF !== 19999 ? totalNum += 1 : totalNum += 0;
        this.state.minPrice !== 0 ? totalNum += 1 : totalNum += 0;
        this.state.maxPrice !== 19999 ? totalNum += 1 : totalNum += 0;
        totalNum += neighborhoods.length;
        totalNum += this.state.filterTags.length;
        totalNum += this.state.searchedPlace.length;
        return totalNum;
    };
    let filterTotalNum = getFilterTotalNum();

    return (
      <>
        <div style={{ width: '95%' }}>
            <Button
                variant="contained"
                className="whatIsThisButton"
                onClick={(e) => this.setState({ modalOpen: true })}
                style={{ backgroundColor: 'white', height: 50, width: '95%', padding: '2%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', borderRadius: '5px', outline: 'none', color: `${filterTotalNum > 0 ? 'dodgerblue' : 'black'}`, border: `${filterTotalNum > 0 ? '1px solid dodgerblue' : null}` }}
            >
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center' }}>
                    <div style={{ fontSize: 12, fontWeight: 'bolder' }}>{`All Filters (+${filterTotalNum > 0 ? filterTotalNum : (0)})`}</div>
                </div>
            </Button>
            <Modal
                open={modalOpen}
                onClose={(e) => this.setState({ modalOpen: false })}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ zIndex: 1301, width: '100%', overflowY: 'scroll' }}
            >
                <Box sx={modalStyle}>
                    <div style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr 5fr 1fr' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, color: 'dodgerblue' }}>
                            <Button
                            variant="text"
                            onClick={() => this.clearAllState()}
                        >
                            Clear
                        </Button></div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <h4 style={{ fontWeight: 500 }}>Filter Properties</h4>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <MdClose onClick={(e) => this.setState({ modalOpen: false })} style={{ height: 30, width: 30 }} />
                        </div>
                    </div>
                    <Divider style={{ marginTop: 10 }} />
                    <div style={{ display: 'grid', gridTemplateColumns: '6fr 1fr' }}>
                        <div style={{ overflowX: 'scroll' }}>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                <div>
                                    <h5 style={{ fontWeight: 500 }}>Location(s)</h5>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', width: '100%' }}>
                                <Stack direction="row" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingBottom: 15 }} spacing={1}>
                                    {neighborhoods.map(neighb => (
                                        <Chip
                                            label={neighb.short_name}
                                            style={{ borderRadius: '1rem', backgroundColor: 'whitesmoke', marginTop: 15 }}
                                            key={neighb.short_name}
                                            onDelete={(e) => {
                                                const value = neighb;
                                                let updatedNeighbs = neighborhoods.filter((thisNeighb) => thisNeighb !== value);
                                                this.setState({ neighborhoods: updatedNeighbs });
                                            }} 
                                        />
                                    ))}
                                    {searchedPlace.map(place => (
                                        <Chip
                                            label={place.address}
                                            style={{ borderRadius: '1rem', backgroundColor: 'whitesmoke', maxWidth: 100, marginTop: 15  }}
                                            key={place.address}
                                            onDelete={(e) => {
                                                const value = place;
                                                let updatedPlace = searchedPlace.filter((thisPlace) => thisPlace !== value);
                                                this.setState({ searchedPlace: updatedPlace });
                                            }} 
                                        />
                                    ))}
                                    {(!neighborhoods.length && !searchedPlace.length) ? (
                                        <Chip
                                        label={'New York, NY'}
                                        style={{ borderRadius: '1rem', backgroundColor: 'whitesmoke' }}
                                    />
                                    ) : null}
                                </Stack>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                <div>
                                    <h5 style={{ fontWeight: 500 }}>Price & Size</h5>
                                </div>
                            </div>
                            <FormControl component="fieldset" variant="standard">
                                <FormGroup sx={{ m: 3 }} >
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.showUndisclosed}
                                            onClick={(e) => this.setState({ showUndisclosed: !this.state.showUndisclosed })}
                                        />
                                        }
                                        label="Include Undisclosed Rates"
                                    />
                                </FormGroup>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                                    <TextField
                                        id="outlined-number"
                                        sx={{ width: '90%' }}
                                        label="Min sq.ft."
                                        type="number"
                                        placeholder='0'
                                        defaultValue={(this.state.minSF !== 0) ? this.state.minSF : null}
                                        onChange={(e) => {
                                            e.target.value === '' ? this.setMinSF(0) : this.setMinSF(e.target.value);
                                        }}
                                    />
                                    <TextField
                                        id="outlined-number"
                                        sx={{ width: '90%' }}
                                        label="Max sq.ft."
                                        type="number"
                                        placeholder='99999'
                                        defaultValue={(this.state.maxSF !== 19999) ? this.state.maxSF : null}
                                        onChange={(e) => {
                                            e.target.value === '' ? this.setMaxSF(19999) : this.setMaxSF(e.target.value);
                                        }}
                                    />
                                </div>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: 20 }}>
                                    <TextField
                                        id="outlined-number"
                                        sx={{ width: '90%' }}
                                        label="Min $ / sf"
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        type="number"
                                        placeholder='0'
                                        defaultValue={(this.state.minPrice !== 0) ? this.state.minPrice : null}
                                        onChange={(e) => {
                                            e.target.value === '' ? this.setMinPrice(0) : this.setMinPrice(e.target.value);
                                        }}
                                    />
                                    <TextField
                                        id="outlined-number"
                                        sx={{ width: '90%' }}
                                        label="Max $$ / sf"
                                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                        type="number"
                                        placeholder='99999'
                                        defaultValue={(this.state.maxPrice !== 19999) ? this.state.maxPrice : null}
                                        onChange={(e) => {
                                            e.target.value === '' ? this.setMaxPrice(19999) : this.setMaxPrice(e.target.value);
                                        }}
                                    />
                                </div>
                            </FormControl>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 40 }}>
                                <div>
                                    <h5 style={{ fontWeight: 500 }}>Category Scores</h5>
                                    <div style={{ fontWeight: 500, fontSize: 12, width: '75%' }}>Display Only Properties With Category Score Over 80.</div>
                                </div>
                            </div>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('highTraffic')}
                                            onClick={(e) => {
                                                const value = 'highTraffic';
                                                const checked = this.state.filterTags.includes('highTraffic');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="highTraffic" />
                                        }
                                        label="Traffic"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('highDemographics')}
                                            onClick={(e) => {
                                                const value = 'highDemographics';
                                                const checked = this.state.filterTags.includes('highDemographics');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="highDemographics" />
                                        }
                                        label="Demographics"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('highCompetition')}
                                            onClick={(e) => {
                                                const value = 'highCompetition';
                                                const checked = this.state.filterTags.includes('highCompetition');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="highCompetition" />
                                        }
                                        label="Competition"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('highProperty')}
                                            onClick={(e) => {
                                                const value = 'highProperty';
                                                const checked = this.state.filterTags.includes('highProperty');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="highProperty" />
                                        }
                                        label="Property"
                                    />
                                </FormGroup>
                            </FormControl>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                <h5 style={{ fontWeight: 500 }}>Demographics</h5>
                            </div>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('localResidents')} 
                                            onChange={(e) => {
                                                const value = 'localResidents';
                                                const checked = this.state.filterTags.includes('localResidents');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="localResidents" />
                                        }
                                        label="Mostly Residents"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('officeWorkers')} 
                                            onChange={(e) => {
                                                const value = 'officeWorkers';
                                                const checked = this.state.filterTags.includes('officeWorkers');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="officeWorkers" />
                                        }
                                        label="Workers"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('nearAttraction')} 
                                            onChange={(e) => {
                                                const value = 'nearAttraction';
                                                const checked = this.state.filterTags.includes('nearAttraction');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="nearAttraction" />
                                        }
                                        label="Tourists"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('students')} 
                                            onChange={(e) => {
                                                const value = 'students';
                                                const checked = this.state.filterTags.includes('students');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="students" />
                                        }
                                        label="Students"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('highIncomeArea')} 
                                            onChange={(e) => {
                                                const value = 'highIncomeArea';
                                                const checked = this.state.filterTags.includes('highIncomeArea');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="highIncomeArea" />
                                        }
                                        label="Higher Income"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('affordableRE')} 
                                            onChange={(e) => {
                                                const value = 'affordableRE';
                                                const checked = this.state.filterTags.includes('affordableRE');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="affordableRE" />
                                        }
                                        label="Affordable Area"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('areasWithChildren')} 
                                            onChange={(e) => {
                                                const value = 'areasWithChildren';
                                                const checked = this.state.filterTags.includes('areasWithChildren');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="areasWithChildren" />
                                        }
                                        label="Areas WITH Children"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('genZ')} 
                                            onChange={(e) => {
                                                const value = 'genZ';
                                                const checked = this.state.filterTags.includes('genZ');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="genZ" />
                                        }
                                        label="Gen Z"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('millennialsTo34')} 
                                            onChange={(e) => {
                                                const value = 'millennialsTo34';
                                                const checked = this.state.filterTags.includes('millennialsTo34');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="millennialsTo34" />
                                        }
                                        label="Millennials (25-34)"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('millennialsTo44')} 
                                            onChange={(e) => {
                                                const value = 'millennialsTo44';
                                                const checked = this.state.filterTags.includes('millennialsTo44');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="millennialsTo44" />
                                        }
                                        label="Millennials (35-44)"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('genX')} 
                                            onChange={(e) => {
                                                const value = 'genX';
                                                const checked = this.state.filterTags.includes('genX');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="genX" />
                                        }
                                        label="Gen X"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('boomers')} 
                                            onChange={(e) => {
                                                const value = 'boomers';
                                                const checked = this.state.filterTags.includes('boomers');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="boomers" />
                                        }
                                        label="Baby Boomers"
                                    />
                                </FormGroup>
                            </FormControl>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                <h5 style={{ fontWeight: 500 }}>Traffic</h5>
                            </div>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('nearAttraction')} 
                                            onChange={(e) => {
                                                const value = 'nearAttraction';
                                                const checked = this.state.filterTags.includes('nearAttraction');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="nearAttraction" />
                                        }
                                        label="Near Major Attraction"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('busyDaytime')} 
                                            onChange={(e) => {
                                                const value = 'busyDaytime';
                                                const checked = this.state.filterTags.includes('busyDaytime');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="busyDaytime" />
                                        }
                                        label="Busy Daytime"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('busyNightLife')} 
                                            onChange={(e) => {
                                                const value = 'busyNightLife';
                                                const checked = this.state.filterTags.includes('busyNightLife');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="busyNightLife" />
                                        }
                                        label="Busy Nightlife"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('busyMonThruFri')} 
                                            onChange={(e) => {
                                                const value = 'busyMonThruFri';
                                                const checked = this.state.filterTags.includes('busyMonThruFri');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="busyMonThruFri" />
                                        }
                                        label="Monday Thru Friday"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('busyWeekend')} 
                                            onChange={(e) => {
                                                const value = 'busyWeekend';
                                                const checked = this.state.filterTags.includes('busyWeekend');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="busyWeekend" />
                                        }
                                        label="Busy Weekends"
                                    />
                                </FormGroup>
                            </FormControl>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                <h5 style={{ fontWeight: 500 }}>Competition</h5>
                            </div>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('lowEndBiz')} 
                                            onChange={(e) => {
                                                const value = 'lowEndBiz';
                                                const checked = this.state.filterTags.includes('lowEndBiz');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="lowEndBiz" />
                                        }
                                        label="$"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('highEndBiz')} 
                                            onChange={(e) => {
                                                const value = 'highEndBiz';
                                                const checked = this.state.filterTags.includes('highEndBiz');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="highEndBiz" />
                                        }
                                        label="$$$$"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('highBizDensity')} 
                                            onChange={(e) => {
                                                const value = 'highBizDensity';
                                                const checked = this.state.filterTags.includes('highBizDensity');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="highBizDensity" />
                                        }
                                        label="High Business Density"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('lowBizDensity')} 
                                            onChange={(e) => {
                                                const value = 'lowBizDensity';
                                                const checked = this.state.filterTags.includes('lowBizDensity');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="lowBizDensity" />
                                        }
                                        label="Low Business Density"
                                    />
                                </FormGroup>
                            </FormControl>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                <h5 style={{ fontWeight: 500 }}>Property</h5>
                            </div>
                            <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('bigFrontage')} 
                                            onChange={(e) => {
                                                const value = 'bigFrontage';
                                                const checked = this.state.filterTags.includes('bigFrontage');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="bigFrontage" />
                                        }
                                        label="Big Frontage"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('cornerLocation')} 
                                            onChange={(e) => {
                                                const value = 'cornerLocation';
                                                const checked = this.state.filterTags.includes('cornerLocation');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="cornerLocation" />
                                        }
                                        label="Corner Location"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('divisible')} 
                                            onChange={(e) => {
                                                const value = 'divisible';
                                                const checked = this.state.filterTags.includes('divisible');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="divisible" />
                                        }
                                        label="Divisible"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('multiLevel')} 
                                            onChange={(e) => {
                                                const value = 'multiLevel';
                                                const checked = this.state.filterTags.includes('multiLevel');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="multiLevel" />
                                        }
                                        label="Multi-Level"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('newConstruction')} 
                                            onChange={(e) => {
                                                const value = 'newConstruction';
                                                const checked = this.state.filterTags.includes('newConstruction');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="newConstruction" />
                                        }
                                        label="New Construction"
                                    />
                                    <FormControlLabel
                                        control={
                                        <Checkbox 
                                            checked={this.state.filterTags.includes('outdoorSpace')} 
                                            onChange={(e) => {
                                                const value = 'outdoorSpace';
                                                const checked = this.state.filterTags.includes('outdoorSpace');
                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                this.setState({ filterTags: filterTag });
                                            }} 
                                            name="outdoorSpace" />
                                        }
                                        label="Outdoor Space"
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', position: 'sticky', right: 0, top: 100, paddingTop: 625, paddingRight: 50, alignSelf: 'start' }}>
                            <div style={{ position: 'absolute' }}>
                                <Fab
                                    variant="extended"
                                    disabled={(filterTotalNum < 1) ? true : false}
                                    style={{ backgroundColor: `${(filterTotalNum < 1) ? 'lightgray' : 'dodgerblue'}`, width: 125, height: 50, borderRadius: 50, color: 'white' }}
                                    onClick={(e) => {
                                        this.applyFilters()
                                    }}
                                >
                                Apply
                                </Fab>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
      </>
    )
  }
}

export default MobileMapFiltersModal;