import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import './demographics.css';
import Tourists from '../../../Assets/tourist.png';
import Residents from '../../../Assets/residents.png';
import OfficeWorkers from '../../../Assets/corporate.png';
import Students from '../../../Assets/students.png';

class CommonPeople extends Component {
    
    
    render() { 

        const { daytimePop, employeePop, currentPop, studentPop, screenWidth } = this.props;
        const { scores: { POI: { poi1 } = {}} = {}} = this.props;
        const { scores: { POI: { poi2 } = {}} = {}} = this.props;
        const { scores: { POI: { poi3 } = {}} = {}} = this.props;


        // GET THIS LISTINGS POPS

        const rawDaytimePop = parseFloat(daytimePop);
        const rawEmployeePop = parseFloat(employeePop);
        const rawCurrentPop = parseFloat(currentPop);
        const rawStudentPop = parseFloat(studentPop);

        const residentGauge = ((rawCurrentPop / rawDaytimePop) + 1);
        const workersGauge = ((rawEmployeePop / rawDaytimePop) + 1);
        let touristGauge = 0;
        const studentsGauge = [];

        // SET TOURIST GAUGE 

        const checkNearbyAttractions = (poi) => {
            if (poi.majorAttraction === true) {
                touristGauge += 1;
                if (poi.distance.elements[0].distance.value <= 150) {
                    touristGauge += 1;
                } else if (poi.distance.elements[0].distance.value <= 200) {
                    touristGauge += .75;
                } else if (poi.distance.elements[0].distance.value <= 250) {
                    touristGauge += .5;
                } else if (poi.distance.elements[0].distance.value <= 300) {
                    touristGauge += .25;
                } else {
                    touristGauge += .1;
                }
            }    
            touristGauge += 0;
        };

        checkNearbyAttractions(poi1);
        checkNearbyAttractions(poi2);
        checkNearbyAttractions(poi3);

        
        // SET STUDENT GAUGE

        const studentPercentage = ((rawStudentPop / rawCurrentPop) * 100);

        const checkStudentsNearby = (studentsParam) => {
            if (studentsParam >= 5) {
                studentsGauge.push(1);
            }
            studentsGauge.push(0);
        };

        checkStudentsNearby(studentPercentage);

        if (studentsGauge[0] === 1) {
            studentsGauge[0] += ((rawStudentPop / rawCurrentPop));
        };

        const studentGauge = studentsGauge[0];

 
        // SET ICON LAYOUTS 

        const residentsSelection = (<div>
            <img id='residentsIcon' src={Residents} alt='' />
            <div id='residentsDescription'>Residents</div>
        </div>);

        const officeWorkersSelection = (<div>
                        <img id='officeWorkersIcon' src={OfficeWorkers} alt='' />
                        <div id='officeWorkersDescription'>Workers</div>
                    </div>);

        const touristsSelection = ( <div>
                    <img id='touristIcon' src={Tourists} alt='' />
                    <div id='touristsDescription'>Tourists / Visitors</div>
                </div>);

        const studentsSelection = ( <div>
                    <img id='studentsIcon' src={Students} alt='' />
                    <div id='studentsDescription'>Students</div>
                </div>);


        const peopleTypeArr = [residentGauge, workersGauge, touristGauge, studentGauge];

        const topPeople = peopleTypeArr.sort(function(a, b){return b-a});

        let typeOfPerson1 = topPeople[0] > 0 ? topPeople[0]: null;
        let typeOfPerson2 = topPeople[1] > 0 ? topPeople[1]: null;
        let typeOfPerson3 = topPeople[2] > 0 ? topPeople[2]: null;
        let typeOfPerson4 = topPeople[3] > 0 ? topPeople[3]: null;

        return (
            <>
            {((screenWidth > 1000) || (screenWidth === undefined)) ? (<div id='highlightBox1'>
                <Row>
                    <Col sm={3} className='peopleTypeCol'>
                        <div className='typeOfPeople1'>
                            {typeOfPerson1 === residentGauge ? residentsSelection : 
                            typeOfPerson1 === workersGauge ? officeWorkersSelection : 
                            typeOfPerson1 === touristGauge ? touristsSelection : 
                            typeOfPerson1 === studentGauge ? studentsSelection : null
                            }
                        </div>
                    </Col>
                    <Col sm={3} className='peopleTypeCol'>
                        <div className='typeOfPeople2'>
                            {typeOfPerson2 === residentGauge ? residentsSelection : 
                            typeOfPerson2 === workersGauge ? officeWorkersSelection : 
                            typeOfPerson2 === touristGauge ? touristsSelection : 
                            typeOfPerson2 === studentGauge ? studentsSelection : null
                            }
                        </div>
                    </Col>
                    <Col sm={3} className='peopleTypeCol'>
                        <div className='typeOfPeople3'>
                            {typeOfPerson3 === residentGauge ? residentsSelection : 
                            typeOfPerson3 === workersGauge ? officeWorkersSelection : 
                            typeOfPerson3 === touristGauge ? touristsSelection : 
                            typeOfPerson3 === studentGauge ? studentsSelection : null
                            }
                        </div>
                    </Col>
                    <Col sm={3} className='peopleTypeCol'>
                        <div className='typeOfPeople4'>
                            {typeOfPerson4 === residentGauge ? residentsSelection : 
                            typeOfPerson4 === workersGauge ? officeWorkersSelection : 
                            typeOfPerson4 === touristGauge ? touristsSelection : 
                            typeOfPerson4 === studentGauge ? studentsSelection : null
                            }
                        </div>
                    </Col>
                </Row>
            </div>) : (<div style={{ postion: 'relative' }}>
                <div style={{ position: 'absolute', top: '75%', left: '50%', transform: 'translate(-50%, -75%)', height: 85, width: 275, textAlign: 'center', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: 10 }}>
                    <div className='peopleTypeCol'>
                        <div className='typeOfPeople1'>
                            {typeOfPerson1 === residentGauge ? residentsSelection : 
                            typeOfPerson1 === workersGauge ? officeWorkersSelection : 
                            typeOfPerson1 === touristGauge ? touristsSelection : 
                            typeOfPerson1 === studentGauge ? studentsSelection : null
                            }
                        </div>
                    </div>
                    <div className='peopleTypeCol'>
                        <div className='typeOfPeople2'>
                            {typeOfPerson2 === residentGauge ? residentsSelection : 
                            typeOfPerson2 === workersGauge ? officeWorkersSelection : 
                            typeOfPerson2 === touristGauge ? touristsSelection : 
                            typeOfPerson2 === studentGauge ? studentsSelection : null
                            }
                        </div>
                    </div>
                    <div className='peopleTypeCol'>
                        <div className='typeOfPeople3'>
                            {typeOfPerson3 === residentGauge ? residentsSelection : 
                            typeOfPerson3 === workersGauge ? officeWorkersSelection : 
                            typeOfPerson3 === touristGauge ? touristsSelection : 
                            typeOfPerson3 === studentGauge ? studentsSelection : null
                            }
                        </div>
                    </div>
                    <div className='peopleTypeCol'>
                        <div className='typeOfPeople4'>
                            {typeOfPerson4 === residentGauge ? residentsSelection : 
                            typeOfPerson4 === workersGauge ? officeWorkersSelection : 
                            typeOfPerson4 === touristGauge ? touristsSelection : 
                            typeOfPerson4 === studentGauge ? studentsSelection : null
                            }
                        </div>
                    </div>
                </div>
            </div>)}
            </>
        );
    }
}
 
export default CommonPeople;