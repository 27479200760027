import React, { Component } from 'react';
import axios from 'axios';
import { Card } from '@material-ui/core';
import { Paper, Button, IconButton, Input, Snackbar, Alert } from '@mui/material';
import { RiImageAddFill } from "react-icons/ri";
import { FaCheckCircle, FaEdit } from "react-icons/fa";
import fourcastLogo from '../../Assets/fourcast_alt_logo_white.png';
import manhattanSkyline from '../../Assets/manhattan_skyline.png';

const manhattanSkylinePic = {id: 'skylinePicId', url: manhattanSkyline};


class ReportCoverPage extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            selectedCoverImage: null,
            unusedImages: [manhattanSkylinePic],
            finalConfirm: false,
            thisStreetViewPic: [],
            uploadedImages: []
        }
    }

    componentDidMount() {
        const { thisListing, streetViewImages, thisListing: { images } = {}} = this.props;
        if (images && images.length) {
            this.setState({ unusedImages: [manhattanSkylinePic, ...images] });
        }
        
        let thisListingStreetViewPic = streetViewImages && streetViewImages.length && streetViewImages.filter(imageObj => imageObj.id === thisListing._id);
        this.setState({ thisStreetViewPic: thisListingStreetViewPic[0], selectedCoverImage: thisListingStreetViewPic[0] });
    }

    componentDidUpdate(prevProps, prevState) {
        const { thisListing, streetViewImages, thisListing: { images } = {} } = this.props;
        const { thisStreetViewPic, selectedCoverImage, unusedImages, uploadedImages } = this.state;

        if (prevProps.thisListing._id !== thisListing._id) {
            if (thisStreetViewPic && thisStreetViewPic.id && thisStreetViewPic.id !== thisListing._id) {
                let thisListingStreetViewPic = streetViewImages && streetViewImages.length && streetViewImages.filter(imageObj => imageObj.id === thisListing._id);

                let findUploadedPics = uploadedImages && uploadedImages.length && uploadedImages.filter(uploadedImageObj => uploadedImageObj.listingId === thisListing._id);
                let createUpdatedUploadedPicsArr = findUploadedPics && findUploadedPics.length && findUploadedPics.map(uploadedObj => uploadedObj.images && uploadedObj.images);
                let updatedUploadedPicsArr = createUpdatedUploadedPicsArr[0];
                this.setState({ thisStreetViewPic: thisListingStreetViewPic[0], selectedCoverImage: thisListingStreetViewPic[0], unusedImages: (images && images.length) ? updatedUploadedPicsArr && updatedUploadedPicsArr.length ? [manhattanSkylinePic, ...updatedUploadedPicsArr, ...images] : [manhattanSkylinePic, ...images] : updatedUploadedPicsArr && updatedUploadedPicsArr.length ? [manhattanSkylinePic, ...updatedUploadedPicsArr] : [manhattanSkylinePic] });
            }
        }
    }

    setCoverPageImage = (selectedImage) => {
        const { selectedCoverImage, unusedImages } = this.state;
        const currentSelectedUrl = selectedCoverImage && (selectedCoverImage !== null) ? selectedCoverImage.url : null;
        const allPics = [...unusedImages, selectedCoverImage];
        if (selectedImage && selectedImage.url && (selectedImage.url !== currentSelectedUrl)) {
            const checked = selectedCoverImage.url === selectedImage.url;
            let newUnusedPics = (checked && (checked !== null)) ? null : allPics.filter((pic) => pic.url !== selectedImage.url);
            this.setState({ selectedCoverImage: selectedImage, unusedImages: newUnusedPics });
        }
    }

    uploadFileHandler = async (e) => {
        const { unusedImages, uploadedImages } = this.state;
        const { thisListing } = this.props;
        const file = e.target.files[0];

        if (uploadedImages && uploadedImages.length) {
            
            let thisListingsUploadedPics = uploadedImages && uploadedImages.filter(uploadedObj => uploadedObj.listingId === thisListing._id);
            
            if (thisListingsUploadedPics && thisListingsUploadedPics.length) {
                // gets uploaded pics from the other listings
                let otherListingsUploadedPics = uploadedImages && uploadedImages.filter(uploadedObj => uploadedObj.listingId !== thisListing._id);
                
                // gets uploaded pics from the current selected listing
                let existingUploadedImagesArr = thisListingsUploadedPics && thisListingsUploadedPics.images && thisListingsUploadedPics.images;
                let updatedUploadedImagesObj = {listingId: thisListing._id, images: [...existingUploadedImagesArr, {id: URL.createObjectURL(file), url: URL.createObjectURL(file)}]}
                this.setState({ uploadedImages: [...otherListingsUploadedPics, updatedUploadedImagesObj] })
            } else {
                let newUploadedImagesObj = {listingId: thisListing._id, images: [{id: URL.createObjectURL(file), url: URL.createObjectURL(file)}]}
                this.setState({ uploadedImages: [...uploadedImages, newUploadedImagesObj] })
            }

            
        } else {
            uploadedImages.push({listingId: thisListing._id, images: [{id: URL.createObjectURL(file), url: URL.createObjectURL(file)}]});
        }

        this.setState({ unusedImages: [...unusedImages, {id: 0, url: URL.createObjectURL(file) }] })
    }
    
    render() { 
        
        const { notConfirmedError, editMode, thisListing, thisListing: { listingAddress: getListingAddress } = {}} = this.props;
        const { selectedCoverImage, unusedImages } = this.state;

        let thisAddressArray = [];
        thisAddressArray.push(getListingAddress);
        let addressString = thisAddressArray.toString();
        let thisListingAddress = addressString.split(',');

        // square footage
        const squareFeet = thisListing && thisListing.squareFeet;

        // price
        const pricePerSquareFoot = thisListing && thisListing.pricePerSquareFoot;

                
        return (
            <>
            <Paper id="reportCoverPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                <div id='reportCoverPageFourcastLogoArea'>
                    <div id='reportCoverPageGreenSquare'>
                        <img style={{ zIndex: 110 }} id='reportCoverPageFourcastLogo' src={fourcastLogo} alt='Fourcast Reports Logo' />
                    </div>
                </div>
                <div id='reportCoverPageAddress'>
                    <h1 style={{ zIndex: 100 }}>{thisListingAddress[0]}</h1>
                </div>
                {(editMode === true) ? (
                <div style={{ height: 515, width: '100%', position: 'relative', border: '2px solid rgb(255, 0, 0)' }}>
                    {editMode === true ? (
                        <div style={{ position: 'absolute', top: '50%', right: '.5%', transform: 'translate(1%, -60%)', height: 150, width: 110 }}>
                            <div style={{ height: '50%', width: '100%', fontWeight: 800, textAlign: 'center', color: 'rgb(223, 82, 69)', fontSize: 14, fontFamily: 'Arial, Helvetica, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>CONFIRM COVER PHOTO</div>
                            <IconButton 
                                style={{ height: '50%', width: '95%', display: 'flex', justifyContent: 'center', alignItems: 'center', outline: 'none', boxShadow: `0 1px 1px rgba(0, 0, 0, 0.2)` }}
                                onClick={(e) => this.props.setEditMode(false)}
                            >
                                <FaCheckCircle style={{ color: 'rgb(52, 181, 122)', width: '50%', height: '50%' }} />
                            </IconButton>
                        </div>
                    ) : null}
                    <div style={{ height: '80%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'top' }}>
                        <Card className="reportCoverPageListingPicCard">
                            <img id="reportCoverPageListingPic" key={selectedCoverImage && selectedCoverImage.id} src={(selectedCoverImage && selectedCoverImage.url)} alt="Report Listing Pic" />
                        </Card>
                    </div>
                    <div style={{ height: '20%', width: '100%', display: 'flex', justifyContent: 'left', alignItems: 'center', position: 'relative', overflowX: 'scroll' }}>
                        <div style={{ height: '100%', width: '15%', marginLeft: 10, marginRight: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 600, fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 10, textAlign: 'center' }}>**Uploaded photos are NOT saved. Go to "My Account" to edit listings.</div>
                        <div style={{ height: '100%', width: '85%', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                            <Card style={{ height: 90, width: 100, backgroundColor: 'whitesmoke', display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 10, marginRight: 10 }}>
                                <Button 
                                    component="label" 
                                    variant="text" 
                                    style={{ width: '100%', height: '100%' }}
                                >
                                    <RiImageAddFill style={{ height: 30, width: 30 }} />
                                    <Input type="file" onChange={this.uploadFileHandler} style={{ display: 'none' }} />
                                </Button>
                            </Card>
                            {unusedImages && unusedImages.map(unusedPic =>
                            <Card style={{ height: 90, width: 150, marginLeft: 10, marginRight: 10 }}>
                                <Button 
                                    component="label" 
                                    variant="text" 
                                    style={{ width: '100%', height: '100%' }}
                                    onClick={(e) => this.setCoverPageImage(unusedPic)}
                                >
                                    <img id="reportCoverPageListingPic" src={unusedPic && unusedPic.url} alt="Listing Photos" />
                                </Button>
                            </Card>)}
                        </div>
                    </div>
                </div>
                ) : (
                <div id='reportCoverPageListingPicArea' style={{ position: 'relative' }}>
                    {editMode === false ? (
                        <div style={{ position: 'absolute', top: '5%', right: '4px', height: 125, width: 110 }} data-html2canvas-ignore="true">
                            <div style={{ height: '40%', width: '100%', fontWeight: 800, textAlign: 'center', fontFamily: 'Arial, Helvetica, sans-serif', display: 'flex', justifyContent: 'center', alignItems: 'end', color: 'dodgerblue'  }}>EDIT PHOTO</div>
                            <IconButton 
                                style={{ height: '60%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', outline: 'none' }}
                                onClick={(e) => this.props.setEditMode(true)}
                            >
                                <FaEdit style={{ color: 'dodgerblue', width: '40%', height: '40%' }} />
                            </IconButton>
                        </div>
                    ) : null}
                    <Card className="reportCoverPageListingPicCard">
                        {(this.state.selectedCoverImage === null) ? <img id="reportCoverPageListingPic" src={manhattanSkyline} alt="Fourcast Report Listing Pic" /> : <img id="reportCoverPageListingPic" src={selectedCoverImage && selectedCoverImage.url} alt="Report Listing Pic" />}
                    </Card>
                </div>)}
                <div id='reportAddressAndSize'>
                    <div id='reportCoverPageSizeAndPrice'>
                        <div>
                            <div>
                                <div><span style={{ fontSize: 28, letterSpacing: 1, fontWeight: 'bold', fontFamily: 'sans-serif' }}>{squareFeet.length > 1 ? `${squareFeet[0]}-${squareFeet[1]}` : `${squareFeet}`}</span><span style={{ marginLeft: 7, fontSize: 28, fontFamily: 'sans-serif' }}>SQ.FT.</span></div>
                            </div>
                            <hr style={{ width: '80%' }} />
                            <div>
                                <div><span style={{ fontSize: 28, letterSpacing: 1, fontWeight: 'bold', fontFamily: 'sans-serif' }}>{pricePerSquareFoot !== -1 ? `$${pricePerSquareFoot} per sf / yr` : 'Undisclosed Price'}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%', fontSize: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 800, color: 'darkgreen' }}>fourcastscore.com</div>
            </Paper>
            <Snackbar open={notConfirmedError} autoHideDuration={6000} onClose={this.props.handleCloseAlert}>
                <Alert onClose={this.props.handleCloseAlert} variant="filled" severity="error" style={{ color: 'red', fontWeight: 800 }}>
                    Must Confirm Cover Page Photo!
                </Alert>
            </Snackbar>
            </>
        );
    }
}

export default ReportCoverPage;