import React, { Component } from 'react';
import { Paper } from '@mui/material';
import IncomeGraph from '../listingDetailsLayouts/demographics/incomeGraph';
import IncomeGauge from '../listingDetailsLayouts/demographics/incomeGauge';
import fourcastLogo from '../../Assets/fourcast_alt_logo_green.png';
import '../listingDetailsLayouts/traffic/traffic.css';
import '../listingDetailsLayouts/demographics/demographics.css';
import '../listingDetailsLayouts/summary/summary.css';

const greenArrow = <div className='greenArrowReport'></div>;
const redArrow = <div className='redArrowReport'></div>;


class IncomeReport extends Component {
    
    
    render() { 

        const { scores, attomData, thisListing, thisListing: { listingAddress: getListingAddress } = {}} = this.props;
        
        // INCOME GRAPH DATA
        
        // get current geoIdV4 array
        const { attomData: { geoIdV4 } = {}} = this.props;
    
        let averageHouseholdIncome = 0;
        let averageIndividualIncome = 0;
        const getTotalNumOfHouseholds = [];

        // number of people in each income bracket
        let householdIncomeFifteenOrLess = 0;
        let householdIncomeFifteenToTwentyFive = 0;
        let householdIncomeTwentyFiveToThirtyFive = 0;
        let householdIncomeThirtyFiveToFifty = 0;
        let householdIncomeFiftyToSeventyFive = 0;
        let householdIncomeSeventyFiveToOneHundred = 0;
        let householdIncomeOneHundredToOneHundredTwentyFive = 0;
        let householdIncomeOneHundredTwentyFiveToOneHundredFifty = 0;
        let householdIncomeOneHundredFiftyToTwoHundred = 0;
        let householdIncomeTwoHundredAndMore = 0;
        
        if (geoIdV4 && geoIdV4.length) {
            
            // avg income numbers
            let primaryIndIncArea = null;
            let secondaryIndIncArea = null;
            let primaryHouseIncArea = null;
            let secondaryHouseIncArea = null;

            
            
            for (let g = 0; g < geoIdV4.length; g++) {
                if (geoIdV4 && (geoIdV4[g].type === 'N4')) {
                    primaryHouseIncArea = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.avg_Household_Income;
                    primaryIndIncArea = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.household_Income_Per_Capita;
                    // income bracket percentages
                    const getGlobalTotalHouseholdsFamNum = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Non_Family;
                    const getGlobalTotalHouseholdsNonFamNum = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Family;
                    const globalTotalHouseholds = (getGlobalTotalHouseholdsFamNum + getGlobalTotalHouseholdsNonFamNum);
                    getTotalNumOfHouseholds.push(globalTotalHouseholds);
                    householdIncomeFifteenOrLess += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_Less_Than_15000_Pct * .01));
                    householdIncomeFifteenToTwentyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_15000_24999_Pct * .01));
                    householdIncomeTwentyFiveToThirtyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_25000_34999_Pct * .01));
                    householdIncomeThirtyFiveToFifty += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_35000_49999_Pct * .01));
                    householdIncomeFiftyToSeventyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_50000_74999_Pct * .01));
                    householdIncomeSeventyFiveToOneHundred += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_75000_99999_Pct * .01));
                    householdIncomeOneHundredToOneHundredTwentyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_100000_124999_Pct * .01));
                    householdIncomeOneHundredTwentyFiveToOneHundredFifty += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_125000_149999_Pct * .01));
                    householdIncomeOneHundredFiftyToTwoHundred += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_150000_199999_Pct * .01));
                    householdIncomeTwoHundredAndMore += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_200000_And_Over_Pct * .01));
                } else {
                    secondaryHouseIncArea = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.avg_Household_Income;
                    secondaryIndIncArea = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.household_Income_Per_Capita;
                    // income bracket percentages
                    const getGlobalTotalHouseholdsFamNum = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Non_Family;
                    const getGlobalTotalHouseholdsNonFamNum = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Family;
                    const globalTotalHouseholds = (getGlobalTotalHouseholdsFamNum + getGlobalTotalHouseholdsNonFamNum);
                    getTotalNumOfHouseholds.push(globalTotalHouseholds);
                    householdIncomeFifteenOrLess += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_Less_Than_15000_Pct * .01));
                    householdIncomeFifteenToTwentyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_15000_24999_Pct * .01));
                    householdIncomeTwentyFiveToThirtyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_25000_34999_Pct * .01));
                    householdIncomeThirtyFiveToFifty += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_35000_49999_Pct * .01));
                    householdIncomeFiftyToSeventyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_50000_74999_Pct * .01));
                    householdIncomeSeventyFiveToOneHundred += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_75000_99999_Pct * .01));
                    householdIncomeOneHundredToOneHundredTwentyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_100000_124999_Pct * .01));
                    householdIncomeOneHundredTwentyFiveToOneHundredFifty += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_125000_149999_Pct * .01));
                    householdIncomeOneHundredFiftyToTwoHundred += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_150000_199999_Pct * .01));
                    householdIncomeTwoHundredAndMore += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_200000_And_Over_Pct * .01));
                }
            }
            
            let avgHouseInc = (primaryHouseIncArea === null) ? secondaryHouseIncArea : parseFloat(Math.ceil((primaryHouseIncArea * .6) + (secondaryHouseIncArea * .4)).toFixed(0));
            let avgIndInc = (primaryIndIncArea === null) ? secondaryIndIncArea : parseFloat(Math.ceil((primaryIndIncArea * .6) + (secondaryIndIncArea * .4)).toFixed(0));
            averageIndividualIncome = avgIndInc;
            averageHouseholdIncome = avgHouseInc;
        }

        // number of people in each income bracket
        const graphIncomeUnderSeventyFive = [householdIncomeFifteenOrLess, householdIncomeFifteenToTwentyFive, householdIncomeTwentyFiveToThirtyFive, householdIncomeThirtyFiveToFifty, householdIncomeFiftyToSeventyFive];
        const graphIncomeSeventyFiveToOneFifty = [householdIncomeSeventyFiveToOneHundred, householdIncomeOneHundredToOneHundredTwentyFive, householdIncomeOneHundredTwentyFiveToOneHundredFifty];
        
        const underSeventyFiveSum = graphIncomeUnderSeventyFive.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });
        const seventyFiveToOneFiftySum = graphIncomeSeventyFiveToOneFifty.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });
        const oneFiftyToTwoHundredSum = parseFloat(householdIncomeOneHundredFiftyToTwoHundred);
        const overTwoHundredSum = parseFloat(householdIncomeTwoHundredAndMore);

        const thisIncomeData = {
            incomeData: {
                labels: [' Under $75k', '$75k-$150k', '$150k-$200k', 'Over $200k'],
                datasets: [
                    {
                        backgroundColor: ['rgb(187, 236, 205)', 'rgb(124, 218, 158)', 'rgb(60, 200, 112)', 'rgb(21, 76, 41)'],
                        data: [underSeventyFiveSum, seventyFiveToOneFiftySum, oneFiftyToTwoHundredSum, overTwoHundredSum]
                    }
                ],
            }
        };
        
        //////////////////////////////////////////////////////////////
        // Income Trends Data
        // Old commData average income for change analyses and historical datapoints
        const totalNumOfHouseholds = getTotalNumOfHouseholds.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });
        const thisOldCommData = thisListing && thisListing.oldCommData && thisListing.oldCommData.sort(function(a, b){return a.year-b.year});
        
        const hhdNum = thisOldCommData && thisOldCommData.length && thisOldCommData[0].oldCommData && thisOldCommData[0].oldCommData.hhd;
        const averageHouseholdIncomeIn2021 = thisOldCommData && thisOldCommData.length && thisOldCommData[0].oldCommData && thisOldCommData[0].oldCommData.inccyavehh;
        
        // insert logic eventually to get the year from the "year" value in the oldCommData object. Right now it is just using 2021, however, the more more "old" neighborhoods being created as the years go on, eventually the year from the created date will have to be extracted.
        //const previousCommDataYear = thisOldCommData && thisOldCommData.length && thisOldCommData[0].year && thisOldCommData[0].year;
        const previousCommDataYear = (attomData && attomData.oldCommData) ? attomData.oldCommData.map(oldCommDataObj => oldCommDataObj.year) : '2021';
        const incomeChangeSince2021 = parseFloat(((averageHouseholdIncome - averageHouseholdIncomeIn2021) / averageHouseholdIncomeIn2021) * 100).toFixed(0);
        const hhdChangeSince2021 = parseFloat(((totalNumOfHouseholds - hhdNum) / hhdNum) * 100).toFixed(0);


        const commaNumber = (inc) => {
            let newNum = Number(inc);
            let numString = newNum.toLocaleString("en-US");
            return numString;
        }
        

        let thisAddressArray = [];
        thisAddressArray.push(getListingAddress);
        let addressString = thisAddressArray.toString();
        let thisListingAddress = addressString.split(',');
        

        return (
            <Paper id="trafficReportPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 15, position: 'relative' }}>
                <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '6fr 1fr' }}>
                        <div className='reportAddress'>
                            <h2>{thisListingAddress[0]}</h2>
                        </div>
                        <div className='reportFourcastLogoArea'>
                            <img className='reportFourcastLogo' src={fourcastLogo} alt='Fourcast Reports Logo' />
                        </div>
                    </div>
                    <hr />
                    <div style={{ marginTop: 15, height: '30%', display: 'grid', gridTemplateColumns: '4fr 5fr' }}>
                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', backgroundColor: 'rgb(21, 134, 84)' }}>
                            <h5 style={{ margin: '15px 0px 10px 20px', color: 'white' }}>Local Income Gauge:</h5>
                        </div>
                        <div style={{ width: 350, height: 170, marginLeft: 'auto', marginRight: 'auto', marginTop: 25 }}>
                            <div style={{ height: 170, position: 'relative', margin: 'auto' }}>
                                <div id='averageIncomeTitle'>Individual Income</div>
                                <div className='individualIncomeHighlight'>
                                    <span id='individualIncomeHighlightValue'>{`$${commaNumber(averageIndividualIncome)}`}</span>
                                    <span id='indAvg'>/ Avg</span>
                                </div>
                                <div id='householdIncomeTitle'>Household Income</div>
                                <div className='householdIncomeHighlight'>
                                    <span id='householdIncomeHighlightValue'>{`$${commaNumber(averageHouseholdIncome)}`}</span>
                                    <span id='houseAvg'>/ Avg</span>
                                </div>
                                <IncomeGauge scores={scores} />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div style={{ marginTop: 25, height: '23%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                        <div style={{ height: 250, width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <h5 style={{ margin: '15px 0px 10px 20px', textAlign: 'center' }}>{`Avg. Household Income Since ${previousCommDataYear}:`}</h5>
                            </div>
                            <div style={{ margin: 'auto', fontSize: 12, fontFamily: 'sans-serif', fontWeight: 'bold', textAlign: 'center' }}>
                                <div>
                                    <h5>Income</h5>
                                </div>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: 125, marginTop: 'auto', marginBottom: 'auto' }}>
                                    <h1 style={incomeChangeSince2021 >= 0 ? { display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(21, 134, 84)' } : { display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(228, 114, 103)' }}>{`${incomeChangeSince2021}%`}</h1>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 5 }}>
                                        {incomeChangeSince2021 >= 0 ? greenArrow : incomeChangeSince2021 < 0 ? redArrow : '' }
                                    </div>
                                </div>
                            </div>
                            <div style={{ margin: 'auto', fontSize: 12, fontFamily: 'sans-serif', fontWeight: 'bold', textAlign: 'center' }}>
                                <h5>Number of Households</h5>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: 125, margin: 'auto', height: '50%' }}>
                                    <h1 style={hhdChangeSince2021 >= 0 ? { display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(21, 134, 84)' } : { display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(228, 114, 103)' }}>{`${hhdChangeSince2021}%`}</h1>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 5 }}>
                                        {hhdChangeSince2021 >= 0 ? greenArrow : hhdChangeSince2021 < 0 ? redArrow : '' }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div style={{ height: '30%', display: 'grid', gridTemplateColumns: '4fr 5fr' }}>
                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', backgroundColor: 'rgb(21, 134, 84)' }}>
                            <h5 style={{ margin: '15px 0px 10px 20px', color: 'white' }}>Income Breakdown:</h5>
                        </div>
                        <div style={{ maxWidth: 350, marginLeft: 50 }}>
                            <IncomeGraph thisIncomeData={thisIncomeData} />
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: 10, position: 'absolute', bottom: '1%', right: '1%', fontWeight: 800, color: 'darkgreen' }}>fourcastscore.com</div>
            </Paper>
        );
    }
}

export default IncomeReport;