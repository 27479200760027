import React, { Component } from 'react';
import { FaCloudMoon, FaCloudSun } from 'react-icons/fa';
import { Table } from '@mui/material';
import FourcastLogo from '../../../Assets/fourcast_alt_logo_green.png';
import '../../listingDetailsLayouts/traffic/traffic.css';
import './matchComparison.css';

class PopularTimesMatchComparison extends Component {
    
    
    render() {

        const { listings, inputValueTitle } = this.props;

        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.scores.fourcastScore - a.scores.fourcastScore});
        
        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }

        let getThisListingsBusyHours = (thisListing) => {
            
            const businesses = thisListing && thisListing.yelpData && thisListing.yelpData.businesses && thisListing.yelpData.businesses;
            
            const thisOpenFiveAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.openingTimes && thisListing.filterables.busyHours.openingTimes.openFiveAm;
            const thisOpenFiveThirtyAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.openingTimes && thisListing.filterables.busyHours.openingTimes.openFiveThirtyAm;
            const thisOpenSixAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.openingTimes && thisListing.filterables.busyHours.openingTimes.openSixAm;
            const thisOpenSixThirtyAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.openingTimes && thisListing.filterables.busyHours.openingTimes.openSixThirtyAm;
            const thisOpenSevenAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.openingTimes && thisListing.filterables.busyHours.openingTimes.openSevenAm;
            const thisOpenSevenThirtyAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.openingTimes && thisListing.filterables.busyHours.openingTimes.openSevenThirtyAm;
            const thisOpenEightAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.openingTimes && thisListing.filterables.busyHours.openingTimes.openEightAm;
            const thisOpenEightThirtyAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.openingTimes && thisListing.filterables.busyHours.openingTimes.openEightThirtyAm;
            const thisCloseMidnight = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.closingTimes && thisListing.filterables.busyHours.closingTimes.closeMidnight;
            const thisCloseMidnightThirty = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.closingTimes && thisListing.filterables.busyHours.closingTimes.closeMidnightThirty;
            const thisCloseOneAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.closingTimes && thisListing.filterables.busyHours.closingTimes.closeOneAm;
            const thisCloseOneThirtyAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.closingTimes && thisListing.filterables.busyHours.closingTimes.closeOneThirtyAm;
            const thisCloseTwoAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.closingTimes && thisListing.filterables.busyHours.closingTimes.closeTwoAm;
            const thisCloseTwoThirtyAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.closingTimes && thisListing.filterables.busyHours.closingTimes.closeTwoThirtyAm;
            const thisCloseThreeAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.closingTimes && thisListing.filterables.busyHours.closingTimes.closeThreeAm;
            const thisCloseThreeThirtyAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.closingTimes && thisListing.filterables.busyHours.closingTimes.closeThreeThirtyAm;
            const thisCloseFourAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.closingTimes && thisListing.filterables.busyHours.closingTimes.closeFourAm;

            let earlyMorningCount = (((thisOpenFiveAm + thisOpenFiveThirtyAm + thisOpenSixAm + thisOpenSixThirtyAm + thisOpenSevenAm + thisOpenSevenThirtyAm + thisOpenEightAm + thisOpenEightThirtyAm) * .25) + .01);
            
            const getDayTrafficGaugeScore = [];
            const getNightlifeGaugeScore = [];
            if (earlyMorningCount) {
                
                let dayTrafficGaugeScore = 0;

                // Calculate Daytime Traffic Gauge Score !!!!!!!!!!!!!!!!!!!!!

                // These figures were taken from the Traffic Volatility Function in "tasks"
                const globalHighestDayPopChange = 4818;
                const dayPopChangeMedian = 60;
                const globalLowestDayPopChange = 3;
                
                // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                
                const secondaryNeighb = thisListing && thisListing.attomData && thisListing.attomData.geoIdV4 && thisListing.attomData.geoIdV4.filter(neighb => ((neighb.type === 'N2') || (neighb.type === 'N3')));
                
                const thisDayPop = parseFloat(secondaryNeighb && secondaryNeighb[0].commV4Data && secondaryNeighb[0].commV4Data.population_Daytime);
                const thisPopCy = parseFloat(secondaryNeighb && secondaryNeighb[0].commV4Data && secondaryNeighb[0].commV4Data.population);
                const dayPopChange = ((thisPopCy > 0) && (thisDayPop > 0)) ? (thisPopCy < thisDayPop) ? (((thisDayPop - thisPopCy)/thisPopCy) * 100) : (((thisPopCy - thisDayPop)/thisPopCy) * -100) : 0;

                if (dayPopChange > dayPopChangeMedian) {
                    dayTrafficGaugeScore = earlyMorningCount + Math.ceil((((dayPopChange - dayPopChangeMedian) / (globalHighestDayPopChange - dayPopChangeMedian)) * 50) + 50);
                } else if (dayPopChange < dayPopChangeMedian) {
                    dayTrafficGaugeScore = earlyMorningCount + Math.ceil((((dayPopChange - dayPopChangeMedian) / (globalLowestDayPopChange - dayPopChangeMedian)) * -50) + 50);
                } else {
                    dayTrafficGaugeScore = earlyMorningCount + 50;
                }

                if (dayTrafficGaugeScore > 95) {
                    dayTrafficGaugeScore = 95;
                } else if (dayTrafficGaugeScore < 2) {
                    dayTrafficGaugeScore = 2;
                }

                getDayTrafficGaugeScore.push(dayTrafficGaugeScore);
                
            }



            // Calculate Nightlife Gauge Score !!!!!!!!!!!!!!!!!!!!!!!!!!!
            
            const nightLifeCount = [thisCloseMidnight, thisCloseMidnightThirty, thisCloseOneAm, thisCloseOneThirtyAm, thisCloseTwoAm, thisCloseTwoThirtyAm, thisCloseThreeAm, thisCloseThreeThirtyAm, thisCloseFourAm];
            let nightlifeBusinesses = [];
            
            if (nightLifeCount) {
                
                let nightlifeGaugeScore = 0;

                const nightlifeCategories = ["bars", "adultentertainment", "barcrawl", "beergardens", "clubcrawl", "comedyclubs", "countrydancehalls", "danceclubs", "dancerestaurants", "fasil", "jazzandblues", "karaoke", "musicvenues", "pianobars", "poolhalls"];

                // pushes businesses with a nightlife "category" aka bars, clubs, lounge, etc.
                if (businesses) {
                    for (let biz of businesses) {
                        for (let category of biz.categories) {
                            if (nightlifeCategories.some(nightCategory => category.alias === nightCategory)) {
                                nightlifeBusinesses.push(biz);
                            };
                        }
                    }
                }

                nightlifeBusinesses = [...new Set([...nightlifeBusinesses])];
                
                // for businesses open til midnight or later, increase the points given the later they are open til
                let multiplier = .75;
                for (let i = 0; i < nightLifeCount.length; i++) {
                    nightlifeGaugeScore += nightLifeCount[i] * multiplier;
                    multiplier += .25;
                }
                
                // award .5 points for every 100 reviews of nightlife businesses
                for (let nightlifeBiz of nightlifeBusinesses) {
                    nightlifeGaugeScore += ((nightlifeBiz.review_count / 100) * .5);
                }

                if (nightlifeGaugeScore > 95) {
                    nightlifeGaugeScore = 95;
                } else if (nightlifeGaugeScore < 2) {
                    nightlifeGaugeScore = 2;
                }
                getNightlifeGaugeScore.push(nightlifeGaugeScore)
                
            }

            const [nightlifeGaugeScore] = getNightlifeGaugeScore;
            const [dayTrafficGaugeScore] = getDayTrafficGaugeScore;
            const busyHoursObj = {address: thisListing.listingAddress, daytime: dayTrafficGaugeScore, nightlife: nightlifeGaugeScore};
            
            return busyHoursObj;
        }


        // SORT BY DAYTIME TRAFFIC
        const sortedByDaytimeTraffic = (inputValueTitle === "Daytime") ? sortedByFourcastScore && sortedByFourcastScore.map(listing => getThisListingsBusyHours(listing)).sort(function(a, b){return b.daytime - a.daytime}) : sortedByFourcastScore && sortedByFourcastScore.map(listing => getThisListingsBusyHours(listing)).sort(function(a, b){return b.nightlife - a.nightlife});
        
        let listing1 = (sortedByDaytimeTraffic && sortedByDaytimeTraffic.length && (sortedByDaytimeTraffic[0] !== undefined)) ? sortedByDaytimeTraffic[0] : null;
        let listing2 = (sortedByDaytimeTraffic && sortedByDaytimeTraffic.length && (sortedByDaytimeTraffic[1] !== undefined)) ? sortedByDaytimeTraffic[1] : null;
        let listing3 = (sortedByDaytimeTraffic && sortedByDaytimeTraffic.length && (sortedByDaytimeTraffic[2] !== undefined)) ? sortedByDaytimeTraffic[2] : null;
        let listing4 = (sortedByDaytimeTraffic && sortedByDaytimeTraffic.length && (sortedByDaytimeTraffic[3] !== undefined)) ? sortedByDaytimeTraffic[3] : null;
        let listing5 = (sortedByDaytimeTraffic && sortedByDaytimeTraffic.length && (sortedByDaytimeTraffic[4] !== undefined)) ? sortedByDaytimeTraffic[4] : null;
        let listing6 = (sortedByDaytimeTraffic && sortedByDaytimeTraffic.length && (sortedByDaytimeTraffic[5] !== undefined)) ? sortedByDaytimeTraffic[5] : null;
        let listing7 = (sortedByDaytimeTraffic && sortedByDaytimeTraffic.length && (sortedByDaytimeTraffic[6] !== undefined)) ? sortedByDaytimeTraffic[6] : null;
        let listing8 = (sortedByDaytimeTraffic && sortedByDaytimeTraffic.length && (sortedByDaytimeTraffic[7] !== undefined)) ? sortedByDaytimeTraffic[7] : null;

        const setDayTrafficGauge = async (score, el) => {
            setTimeout(async () => {
                const dayTrafficGauge = await document.querySelector(el);
                let dayRotation = score;                
                dayTrafficGauge && await dayTrafficGauge.style.setProperty('--bottomDay', dayRotation);
            }, 100, { once: true });
        }

        (listing1 !== null) && listing1.daytime && setDayTrafficGauge(listing1.daytime, '.matchComparisonDaytimeComparisonPointer1');
        (listing2 !== null) && listing2.daytime && setDayTrafficGauge(listing2.daytime, '.matchComparisonDaytimeComparisonPointer2');
        (listing3 !== null) && listing3.daytime && setDayTrafficGauge(listing3.daytime, '.matchComparisonDaytimeComparisonPointer3');
        (listing4 !== null) && listing4.daytime && setDayTrafficGauge(listing4.daytime, '.matchComparisonDaytimeComparisonPointer4');
        (listing5 !== null) && listing5.daytime && setDayTrafficGauge(listing5.daytime, '.matchComparisonDaytimeComparisonPointer5');
        (listing6 !== null) && listing6.daytime && setDayTrafficGauge(listing6.daytime, '.matchComparisonDaytimeComparisonPointer6');
        (listing7 !== null) && listing7.daytime && setDayTrafficGauge(listing7.daytime, '.matchComparisonDaytimeComparisonPointer7');
        (listing8 !== null) && listing8.daytime && setDayTrafficGauge(listing8.daytime, '.matchComparisonDaytimeComparisonPointer8');


        const setNightlifeGauge = async (score, el) => {
            setTimeout(async () => {
                const nightlifeGauge = await document.querySelector(el);
                let nightRotation = score;                
                nightlifeGauge && await nightlifeGauge.style.setProperty('--bottomNight', nightRotation);
            }, 100, { once: true });
        }

        (listing1 !== null) && listing1.nightlife && setNightlifeGauge(listing1.nightlife, '.matchComparisonNightlifeComparisonPointer1');
        (listing2 !== null) && listing2.nightlife && setNightlifeGauge(listing2.nightlife, '.matchComparisonNightlifeComparisonPointer2');
        (listing3 !== null) && listing3.nightlife && setNightlifeGauge(listing3.nightlife, '.matchComparisonNightlifeComparisonPointer3');
        (listing4 !== null) && listing4.nightlife && setNightlifeGauge(listing4.nightlife, '.matchComparisonNightlifeComparisonPointer4');
        (listing5 !== null) && listing5.nightlife && setNightlifeGauge(listing5.nightlife, '.matchComparisonNightlifeComparisonPointer5');
        (listing6 !== null) && listing6.nightlife && setNightlifeGauge(listing6.nightlife, '.matchComparisonNightlifeComparisonPointer6');
        (listing7 !== null) && listing7.nightlife && setNightlifeGauge(listing7.nightlife, '.matchComparisonNightlifeComparisonPointer7');
        (listing8 !== null) && listing8.nightlife && setNightlifeGauge(listing8.nightlife, '.matchComparisonNightlifeComparisonPointer8');

        if (listing1) {listing1.color = "rgba(254, 39, 18, 0.2)"};
        if (listing2) {listing2.color = "rgba(102, 176, 50, 0.2)"};
        if (listing3) {listing3.color = "rgba(251, 153, 2, 0.2)"};
        if (listing4) {listing4.color = "rgba(52, 124, 152, 0.2)"};
        if (listing5) {listing5.color = "rgb(254, 240, 51, 0.2)"};
        if (listing6) {listing6.color = "rgba(128, 0, 0, 0.2)"};
        if (listing7) {listing7.color = "rgba(2, 71, 254, 0.2)"};
        if (listing8) {listing8.color = "rgba(134, 1, 175, 0.2)"};

        
        const nightlifeArr = [listing1, listing2, listing3, listing4, listing5, listing6, listing7, listing8];
        
        const sortedByNightlife = nightlifeArr && nightlifeArr.filter(listing => listing && listing.nightlife).sort(function(a, b){return b.nightlife - a.nightlife});
        

        return (
            <div style={{ position: 'relative', width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                    <div style={{ fontSize: 24, fontWeight: 700, color: 'darkgreen' }}>{inputValueTitle}</div>
                    <div style={{ fontSize: 22, fontWeight: 500, marginLeft: 5 }}>Traffic:</div>
                </div>
                <div className='busyTimesCompareGaugeArea' style={{ position: 'relative', display: 'flex', marginTop: 100 }}>
                    {(inputValueTitle === 'Daytime') ? (<div style={{ flex: 1 }}>
                        <div style={{ width: '90%', margin: 'auto auto' }}>
                            <Table style={{ tableLayout: 'fixed' }}>
                                <thead>
                                    <tr style={{ textAlign: 'center' }}>
                                        <th style={{ width: '15%' }}></th>
                                        <th></th>
                                        <th style={{ width: '25%' }}>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img alt="Fourcast Nightlife Gauge Logo" src={FourcastLogo} style={{ width: 80 }} /></div>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, fontWeight: 700, padding: 5, color: 'rgb(52, 181, 122)' }}>Daytime Gauge</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedByDaytimeTraffic && sortedByDaytimeTraffic.map(listing => 
                                        <tr style={{ textAlign: 'center' }}>
                                            <th style={{ width: '15%' }}>
                                                {sortedByDaytimeTraffic && sortedByDaytimeTraffic.length && sortedByDaytimeTraffic.indexOf(listing) === 0 ? <div style={{ display: 'flex', fontSize: 14, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', color: 'rgb(52, 181, 122)', marginLeft: 10, marginTop: 1 }}>Best</div> : <div style={{ display: 'flex', fontSize: 13, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', marginLeft: 10, marginTop: 1 }}>{(sortedByDaytimeTraffic.indexOf(listing) + 1)}</div>}
                                            </th>
                                            <td style={{ border: '1px solid lightgray', fontSize: 14, padding: 10, backgroundColor: listing.color }}>
                                                {listing && addressStyle(listing && listing.address)}
                                            </td>
                                            <td style={{ width: '25%', border: '1px solid lightgray', fontSize: 14, padding: 10, backgroundColor: listing.color }}>
                                                {listing && listing.daytime && `${listing.daytime.toFixed(0)}%`}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        <div style={{ width: '90%', margin: 'auto auto', marginTop: 150 }}>
                            <Table style={{ tableLayout: 'fixed' }}>
                                <tbody>
                                    <tr style={{ textAlign: 'center' }}>
                                        <th style={{ width: '20%' }}>
                                            <div style={{ display: 'flex', fontSize: 14, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', marginLeft: 10, marginTop: 1 }}>Citywide Gauge</div>
                                        </th>
                                        <td>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                <div style={{ fontWeight: 500, fontSize: 20 }}>Daytime Traffic</div>
                                                <div style={{ marginLeft: 10 }}><FaCloudSun style={{ height: 25, width: 25, color: 'orange' }} /></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className='traffGaugeArea' style={{ flex: 1, width: '100%', height: 100, position: 'relative', marginTop: 25 }}>
                            <div className='matchComparisonDayTrafficComparisonGauge'></div>
                            {((listing1 !== null) && (listing1.daytime)) ? <div className='matchComparisonDaytimeComparisonPointer1'></div> : null}
                            {((listing2 !== null) && (listing2.daytime)) ? <div className='matchComparisonDaytimeComparisonPointer2'></div> : null}
                            {((listing3 !== null) && (listing3.daytime)) ? <div className='matchComparisonDaytimeComparisonPointer3'></div> : null}
                            {((listing4 !== null) && (listing4.daytime)) ? <div className='matchComparisonDaytimeComparisonPointer4'></div> : null}
                            {((listing5 !== null) && (listing5.daytime)) ? <div className='matchComparisonDaytimeComparisonPointer5'></div> : null}
                            {((listing6 !== null) && (listing6.daytime)) ? <div className='matchComparisonDaytimeComparisonPointer6'></div> : null}
                            {((listing7 !== null) && (listing7.daytime)) ? <div className='matchComparisonDaytimeComparisonPointer7'></div> : null}
                            {((listing8 !== null) && (listing8.daytime)) ? <div className='matchComparisonDaytimeComparisonPointer8'></div> : null}
                            <div className='matchComparisonDayTrafficComparisonGaugeHigh'>High</div>
                            <div className='matchComparisonDayTrafficComparisonGaugeLow'>Low</div>
                        </div>
                    </div>) : null}
                    {(inputValueTitle === 'Nightlife') ? (<div style={{ flex: 1 }}>
                        <div style={{ width: '90%', margin: 'auto auto' }}>
                            <Table style={{ tableLayout: 'fixed' }}>
                                <thead>
                                    <tr style={{ textAlign: 'center' }}>
                                        <th style={{ width: '15%' }}></th>
                                        <th></th>
                                        <th style={{ width: '25%' }}>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img alt="Fourcast Nightlife Gauge Logo" src={FourcastLogo} style={{ width: 80 }} /></div>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, fontWeight: 700, padding: 5, color: 'rgb(52, 181, 122)' }}>Nightlife Gauge</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedByNightlife && sortedByNightlife.map(listing => 
                                        <tr style={{ textAlign: 'center' }}>
                                            <th style={{ width: '15%' }}>
                                                {sortedByNightlife && sortedByNightlife.length && sortedByNightlife.indexOf(listing) === 0 ? <div style={{ display: 'flex', fontSize: 14, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', color: 'rgb(52, 181, 122)', marginLeft: 10, marginTop: 1 }}>Best</div> : <div style={{ display: 'flex', fontSize: 13, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', marginLeft: 10, marginTop: 1 }}>{(sortedByNightlife.indexOf(listing) + 1)}</div>}
                                            </th>
                                            <td style={{ border: '1px solid lightgray', fontSize: 14, padding: 10, backgroundColor: listing.color }}>
                                                {listing && addressStyle(listing && listing.address)}
                                            </td>
                                            <td style={{ width: '25%', border: '1px solid lightgray', fontSize: 14, padding: 10, backgroundColor: listing.color }}>
                                                {listing && listing.nightlife && `${listing.nightlife.toFixed(0)}%`}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        <div style={{ width: '90%', margin: 'auto auto', marginTop: 150 }}>
                            <Table style={{ tableLayout: 'fixed' }}>
                                <tbody>
                                    <tr style={{ textAlign: 'center' }}>
                                        <th style={{ width: '20%' }}>
                                            <div style={{ display: 'flex', fontSize: 14, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', marginLeft: 10, marginTop: 1 }}>Citywide Gauge</div>
                                        </th>
                                        <td>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                <div style={{ fontWeight: 500, fontSize: 20 }}>Nightlife</div>
                                                <div style={{ marginLeft: 10 }}><FaCloudMoon style={{ height: 25, width: 25, color: 'midnightblue' }} /></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className='traffGaugeArea' style={{ flex: 1, width: '100%', height: 100, position: 'relative', marginTop: 25 }}>
                            <div className='matchComparisonNightlifeComparisonGauge'></div>
                            {((listing1 !== null) && (listing1.nightlife)) ? <div className='matchComparisonNightlifeComparisonPointer1'></div> : null}
                            {((listing2 !== null) && (listing2.nightlife)) ? <div className='matchComparisonNightlifeComparisonPointer2'></div> : null}
                            {((listing3 !== null) && (listing3.nightlife)) ? <div className='matchComparisonNightlifeComparisonPointer3'></div> : null}
                            {((listing4 !== null) && (listing4.nightlife)) ? <div className='matchComparisonNightlifeComparisonPointer4'></div> : null}
                            {((listing5 !== null) && (listing5.nightlife)) ? <div className='matchComparisonNightlifeComparisonPointer5'></div> : null}
                            {((listing6 !== null) && (listing6.nightlife)) ? <div className='matchComparisonNightlifeComparisonPointer6'></div> : null}
                            {((listing7 !== null) && (listing7.nightlife)) ? <div className='matchComparisonNightlifeComparisonPointer7'></div> : null}
                            {((listing8 !== null) && (listing8.nightlife)) ? <div className='matchComparisonNightlifeComparisonPointer8'></div> : null}
                            <div className='matchComparisonNightlifeComparisonGaugeHigh'>High</div>
                            <div className='matchComparisonNightlifeComparisonGaugeLow'>Low</div>
                        </div>
                    </div>) : null}
                </div>
                <hr />
            </div>
        );
    }
}

export default PopularTimesMatchComparison;