import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import { Alert, Box, Stack, Button, Snackbar, Fab } from '@mui/material';
import { Container } from 'reactstrap';
import FourcastMatchLogo from '../../Assets/fourcast_match_logo_green.png';
import FourcastCompLogo from '../../Assets/fourcast_comp_logo_green.png';
import FourcastReportLogo from '../../Assets/fourcast_report_logo_green.png';
import { connect } from 'react-redux';
import { loadUser } from '../../actions/authActions';
import PropTypes from 'prop-types';
import './description.css';

class DiscoverTools extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            discoverToolsForSearching: true,
            loginModalOpen: false,
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
    }
    
    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }

    handleClickOutside = async event => {
        const domNode = ReactDOM.findDOMNode(this);
    
        if (!domNode || !domNode.contains(event.target)) {
            let clickedOnModal = await event.target.classList.contains('form-control');
            if (!clickedOnModal) {
                this.setState({
                    loginModalOpen: false
                });
            }           
        }
    }

    handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
          this.setState({ loginModalOpen: false });
    }

    render() { 

        const { user, isAuthenticated } = this.props;

        
        return ( 
            <Container style={{ height: 600, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', paddingBottom: 50 }} fluid>
                <div style={{ width: '90%', height: 500, marginTop: 'auto' }}>
                    <div className='perfectSpace' style={{ display: 'flex', justifyContent: 'center', height: '15%' }}>
                        <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                            <h2>Discover tools that can help</h2>
                        </div>
                    </div>
                    <div style={{ height: '15%', width: '100%', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                        <Box sx={{ '& > :not(style)': { m: 1 } }}>
                            <Fab variant='extended' style={{ outline: 'none', backgroundColor: `${this.state.discoverToolsForSearching ? 'rgb(52, 181, 122)' : 'white'}`, color: `${this.state.discoverToolsForSearching ? 'white' : 'black'}`, fontFamily: 'Arial, Helvetica, sans-serif', position: 'relative', padding: '12px 16px', borderRadius: 40, height: 48, minWidth: 'fit-content', fontSize: 16, fontWeight: 500, lineHeight: 24, textTransform: 'none' }} onClick={() => this.setState({ discoverToolsForSearching: true })}>
                                Searching
                            </Fab>
                            <Fab variant='extended' style={{ outline: 'none', backgroundColor: `${!this.state.discoverToolsForSearching ? 'rgb(52, 181, 122)' : 'white'}`, color: `${!this.state.discoverToolsForSearching ? 'white' : 'black'}`, fontFamily: 'Arial, Helvetica, sans-serif', position: 'relative', padding: '12px 16px', borderRadius: 40, height: 48, minWidth: 'fit-content', fontSize: 16, fontWeight: 500, lineHeight: 24, textTransform: 'none' }} onClick={() => this.setState({ discoverToolsForSearching: false })}>
                                Listing / Advertising
                            </Fab>
                        </Box>
                    </div>
                    {this.state.discoverToolsForSearching ? (
                    <div style={{ height: '70%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        <Stack spacing={2} direction="row" style={{ width: '100%' }}>
                            <div style={{ width: '33%' }}>
                                <Link 
                                    style={{ textDecoration: "none" }}
                                    to={'/customSearch'}
                                >
                                    <Button variant="outlined" style={{ width: '100%', height: '100%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '100%', textTransform: 'none' }}>
                                            <div style={{ display: 'flex', alignItems: 'end', textAlign: 'left', width: '75%', fontSize: 22, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 600, color: 'black', height: 70 }}>Personalized searching</div>
                                            <div style={{ textAlign: 'left', width: '90%', fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black', paddingTop: 15 }}>Input a specific criteria of the type of space AND area you're looking for, and get instantly paired with compatible listings.</div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', paddingTop: 30, paddingBottom: 15 }}>
                                                <div style={{ textAlign: 'left', textDecoration: 'underline', textUnderlineOffset: 5, fontWeight: 600, color: 'black', fontSize: 16 }}>Try Fourcast Match</div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 10, paddingTop: 5 }}>
                                                    <img style={{ width: 125, height: 29.75 }} alt='Try Fourcast Match' src={FourcastMatchLogo} />
                                                </div>
                                            </div>
                                        </div>
                                    </Button>
                                </Link>
                            </div>
                            <div style={{ width: '33%' }}>
                                <Link 
                                    style={{ textDecoration: "none" }}
                                    to={'/compare-listings'}
                                >
                                    <Button variant="outlined" style={{ width: '100%', height: '100%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '100%', textTransform: 'none' }}>
                                            <div style={{ display: 'flex', alignItems: 'end', textAlign: 'left', width: '75%', fontSize: 22, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 600, color: 'black', height: 70 }}>Instantly compare listings</div>
                                            <div style={{ textAlign: 'left', width: '90%', fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black', paddingTop: 15 }}>Create powerful, side-by-side comp reports that highlight differences in the quality and characteristics of each space.</div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', paddingTop: 30, paddingBottom: 15 }}>
                                                <div style={{ textAlign: 'left', textDecoration: 'underline', textUnderlineOffset: 5, fontWeight: 600, color: 'black', fontSize: 16 }}>Try Fourcast Comp</div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img style={{ width: 144.5, height: 40.25 }} alt='Try Fourcast Match' src={FourcastCompLogo} />
                                                </div>
                                            </div>
                                        </div>
                                    </Button>
                                </Link>
                            </div>
                            <div style={{ width: '33%' }}>
                                <Link 
                                    style={{ textDecoration: "none" }}
                                    to={'/reports'}
                                >
                                    <Button variant="outlined" style={{ width: '100%', height: '100%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '100%', textTransform: 'none' }}>
                                            <div style={{ display: 'flex', alignItems: 'end', textAlign: 'left', width: '75%', fontSize: 22, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 600, color: 'black', height: 70 }}>Generate listing reports</div>
                                            <div style={{ textAlign: 'left', width: '90%', fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black', paddingTop: 15 }}>Customize printable PDF reports on any active listing and include valuable Fourcast traffic, demographics and competition data.</div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', paddingTop: 30, paddingBottom: 15 }}>
                                                <div style={{ textAlign: 'left', textDecoration: 'underline', textUnderlineOffset: 5, fontWeight: 600, color: 'black', fontSize: 16 }}>Try Fourcast Report</div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img style={{ width: 144.5, height: 40.25 }} alt='Try Fourcast Match' src={FourcastReportLogo} />
                                                </div>
                                            </div>
                                        </div>
                                    </Button>
                                </Link>
                            </div>
                        </Stack>
                    </div>
                    ) : (
                    <div style={{ height: '70%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                        <Stack spacing={2} direction="row" style={{ width: '100%' }}>
                            <div style={{ width: '33%' }}>
                                <Link 
                                    style={{ textDecoration: "none" }}
                                    to={'/reports'}
                                >
                                    <Button variant="outlined" style={{ width: '100%', height: '100%' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '100%', textTransform: 'none' }}>
                                            <div style={{ display: 'flex', alignItems: 'end', textAlign: 'left', width: '75%', fontSize: 22, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 600, color: 'black', height: 70 }}>Powerful marketing content</div>
                                            <div style={{ textAlign: 'left', width: '90%', fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black', paddingTop: 15 }}>Attract potential tenants with rich, insightful listing reports that highlight the qualities of your space and the area it's in.</div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', paddingTop: 30, paddingBottom: 15 }}>
                                                <div style={{ textAlign: 'left', textDecoration: 'underline', textUnderlineOffset: 5, fontWeight: 600, color: 'black', fontSize: 16 }}>Try Fourcast Report</div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    <img style={{ width: 144.5, height: 40.25 }} alt='Try Fourcast Match' src={FourcastReportLogo} />
                                                </div>
                                            </div>
                                        </div>
                                    </Button>
                                </Link>
                            </div>
                            <div style={{ width: '33%', position: 'relative' }}>
                                <Link 
                                    style={{ textDecoration: "none" }}
                                    to={(user && isAuthenticated) ? `/user/${user._id}` : '/'}
                                >
                                    <Button onClick={() => user ? null : this.setState({ loginModalOpen: true })} variant="outlined" style={{ width: '100%', height: '100%' }}>
                                        <Snackbar open={this.state.loginModalOpen} autoHideDuration={6000} onClose={this.handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                                            <Alert onClose={this.handleCloseAlert} variant="filled" severity="error" style={{ backgroundColor: 'firebrick', color: 'white', fontWeight: 600, textTransform: 'none' }}>
                                                Must be logged in to view leads!
                                            </Alert>
                                        </Snackbar>
                                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '100%', textTransform: 'none' }}>
                                            <div style={{ display: 'flex', alignItems: 'end', textAlign: 'left', width: '75%', fontSize: 22, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 600, color: 'black', height: 70 }}>Pre-screened leads</div>
                                            <div style={{ textAlign: 'left', width: '90%', fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', color: 'black', paddingTop: 15 }}>Immediately compare one lead to another through a graded, pre-screened, comprehensive breakdown of each applicant.</div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', paddingTop: 30, paddingBottom: 15 }}>
                                                <div style={{ textAlign: 'left', textDecoration: 'underline', textUnderlineOffset: 5, fontWeight: 600, color: 'black', fontSize: 16 }}>Explore My Leads</div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 10, paddingTop: 5 }}>
                                                    <Fab
                                                        variant="extended"
                                                        size="sm"
                                                        category='myLeads'
                                                        disabled
                                                        style={{ color: 'white', backgroundColor: 'rgb(207, 83, 72)', width: 140, height: 40.25, textAlign: 'right', fontSize: 12, outline: 'none', borderRadius: 40 }}
                                                    >
                                                        My Leads
                                                    </Fab>
                                                </div>
                                            </div>
                                        </div>
                                    </Button>
                                </Link>
                            </div>
                        </Stack>
                    </div>
                    )}
                </div>
            </Container>
         );
    }
}
 
DiscoverTools.propTypes = {
    getListing: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.auth.user ? state.auth.user : '',
    isAuthenticated: state.auth.isAuthenticated ? state.auth.isAuthenticated : ''
});

export default connect(mapStateToProps, { loadUser })(DiscoverTools);