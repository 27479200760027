import React, { Component } from 'react';
import { Table } from '@mui/material';
import { MdStar, MdOutlineStar } from 'react-icons/md';
import '../../listingDetailsLayouts/demographics/demographics.css';
import './matchComparison.css';

class MajorAttractionMatchComparison extends Component {
    
    
    render() {

        const { listings, inputValueTitle } = this.props;
        
        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){
            let aPoiReviewCount1 = a && a.scores && a.scores.POI && a.scores.POI.poi1 && a.scores.POI.poi1.user_ratings_total && a.scores.POI.poi1.user_ratings_total;
            let aPoiReviewCount2 = a && a.scores && a.scores.POI && a.scores.POI.poi2 && a.scores.POI.poi2.user_ratings_total && a.scores.POI.poi2.user_ratings_total;
            let aPoiReviewCount3 = a && a.scores && a.scores.POI && a.scores.POI.poi3 && a.scores.POI.poi3.user_ratings_total && a.scores.POI.poi3.user_ratings_total;
            let aTotalReviewCount = Math.ceil(aPoiReviewCount1 + aPoiReviewCount2 + aPoiReviewCount3);
            
            let bPoiReviewCount1 = b && b.scores && b.scores.POI && b.scores.POI.poi1 && b.scores.POI.poi1.user_ratings_total && b.scores.POI.poi1.user_ratings_total;
            let bPoiReviewCount2 = b && b.scores && b.scores.POI && b.scores.POI.poi2 && b.scores.POI.poi2.user_ratings_total && b.scores.POI.poi2.user_ratings_total;
            let bPoiReviewCount3 = b && b.scores && b.scores.POI && b.scores.POI.poi3 && b.scores.POI.poi3.user_ratings_total && b.scores.POI.poi3.user_ratings_total;
            let bTotalReviewCount = Math.ceil(bPoiReviewCount1 + bPoiReviewCount2 + bPoiReviewCount3);
            return bTotalReviewCount - aTotalReviewCount;
        });

        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        };

        let thisListingsMostVisitedPlaces = (thisListing) => {
            
            
            const poi1Name = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi1 && thisListing.scores.POI.poi1.name && thisListing.scores.POI.poi1.name;
            const poi2Name = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi2 && thisListing.scores.POI.poi2.name && thisListing.scores.POI.poi2.name;
            const poi3Name = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi3 && thisListing.scores.POI.poi3.name && thisListing.scores.POI.poi3.name;
        
            const poi1Icon = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi1 && thisListing.scores.POI.poi1.icon && thisListing.scores.POI.poi1.icon;
            const poi2Icon = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi2 && thisListing.scores.POI.poi2.icon && thisListing.scores.POI.poi2.icon;
            const poi3Icon = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi3 && thisListing.scores.POI.poi3.icon && thisListing.scores.POI.poi3.icon;

            const poi1Major = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi1 && thisListing.scores.POI.poi1.majorAttraction && thisListing.scores.POI.poi1.majorAttraction;
            const poi2Major = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi2 && thisListing.scores.POI.poi2.majorAttraction && thisListing.scores.POI.poi2.majorAttraction;
            const poi3Major = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi3 && thisListing.scores.POI.poi3.majorAttraction && thisListing.scores.POI.poi3.majorAttraction;
        
            const distance1Elem = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi1 && thisListing.scores.POI.poi1.distance && thisListing.scores.POI.poi1.distance.elements && thisListing.scores.POI.poi1.distance.elements;
            const distance2Elem = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi2 && thisListing.scores.POI.poi2.distance && thisListing.scores.POI.poi2.distance.elements && thisListing.scores.POI.poi2.distance.elements;
            const distance3Elem = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi3 && thisListing.scores.POI.poi3.distance && thisListing.scores.POI.poi3.distance.elements && thisListing.scores.POI.poi3.distance.elements;
        
            const poi1ReviewCount = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi1 && thisListing.scores.POI.poi1.user_ratings_total && thisListing.scores.POI.poi1.user_ratings_total;
            const poi2ReviewCount = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi2 && thisListing.scores.POI.poi2.user_ratings_total && thisListing.scores.POI.poi2.user_ratings_total;
            const poi3ReviewCount = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi3 && thisListing.scores.POI.poi3.user_ratings_total && thisListing.scores.POI.poi3.user_ratings_total;

            let totalReviewCount = Math.ceil(poi1ReviewCount + poi2ReviewCount + poi3ReviewCount);

            let poi1Distance = '';
            let poi2Distance = '';
            let poi3Distance = '';

            if (distance1Elem) {
                for (let i = 0; i < distance1Elem.length; i++ ) {
                    poi1Distance = distance1Elem[i].duration && distance1Elem[i].duration.text;
                }
            }

            if (distance2Elem) {
                for (let i = 0; i < distance2Elem.length; i++ ) {
                    poi2Distance = distance2Elem[i].duration && distance2Elem[i].duration.text;
                }
            }

            if (distance3Elem) {
                for (let i = 0; i < distance3Elem.length; i++ ) {
                    poi3Distance = distance3Elem[i].duration && distance3Elem[i].duration.text;
                }
            }


            const poi1Obj = {name: poi1Name, icon: poi1Icon, major: poi1Major, distance: poi1Distance, user_ratings_total: poi1ReviewCount};
            const poi2Obj = {name: poi2Name, icon: poi2Icon, major: poi2Major, distance: poi2Distance, user_ratings_total: poi2ReviewCount};
            const poi3Obj = {name: poi3Name, icon: poi3Icon, major: poi3Major, distance: poi3Distance, user_ratings_total: poi3ReviewCount};

            const placesArr = {pois: [poi1Obj, poi2Obj, poi3Obj], totalReviewCount: totalReviewCount};
            return placesArr;
        }
        
        let filteredListingMostVisitedPlaces = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            
            let thisListingsPois = thisListingsMostVisitedPlaces(listing);
                        
            let filteredListingPOIList = thisListingsPois && thisListingsPois.pois && thisListingsPois.pois.length && thisListingsPois.pois.map((place) => {
                return (
                    <tr>
                        <td style={{ fontSize: 12, width: '5%', overflow: 'scroll' }}>
                            <div style={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{(place && place.major && (place.major === true)) ? <MdStar alt='major attraction star desc' style={{ color: 'gold', height: 20, width: 20 }} /> : <MdOutlineStar alt='major attraction star desc' style={{ color: 'lightgray', height: 20, width: 20 }} />}</div>
                        </td>
                        <td style={{ fontSize: 12, width: '45%', overflowX: 'scroll' }}>
                            <div style={{ overflowX: 'scroll' }}>{place && place.name && place.name}</div>
                        </td>
                        <td style={{ fontSize: 12, width: '15%' }}>
                            <div>{place && place.user_ratings_total && place.user_ratings_total}</div>
                        </td>
                        <td style={{ fontSize: 12, width: '15%' }}>
                            <div>{`${place && place.distance && place.distance} walk`}</div>
                        </td>
                        <td style={{ fontSize: 12, width: '15%' }}>
                            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <img style={{ height: 20, width: 20 }} src={place && place.icon && place.icon} />
                            </div>
                        </td>
                    </tr>
                )
            });

            return (
                <div style={{ position: 'relative', width: '100%', marginTop: 25 }}>
                    <div style={{ marginLeft: 10, display: 'flex', alignItems: 'end', width: '100%' }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>{sortedByFourcastScore && sortedByFourcastScore.length && (sortedByFourcastScore.indexOf(listing) + 1)}</div>
                        <div style={{ marginLeft: 10, fontSize: 14, fontWeight: 700, overflowY: 'scroll', width: '40%', display: 'flex' }}>
                            {listing && addressStyle(listing.listingAddress)}
                            {sortedByFourcastScore && sortedByFourcastScore.length && sortedByFourcastScore.indexOf(listing) === 0 ? <div style={{ display: 'flex', fontSize: 12, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', color: 'rgb(52, 181, 122)', marginLeft: 10 }}>Best</div> : null}
                        </div>
                    </div>
                    {filteredListingPOIList && filteredListingPOIList.length ? (
                    <div style={{ fontSize: 12, width: '99%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>Reviews</th>
                                        <th>Distance</th>
                                        <th>Category</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredListingPOIList}
                                </tbody>
                            </Table>
                        </div>
                    </div>) : (
                    <div style={{ fontSize: 12, width: '99%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Name</th>
                                        <th>Reviews</th>
                                        <th>Distance</th>
                                        <th>Category</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    None
                                </tbody>
                            </Table>
                        </div>
                    </div>)}
                </div>
            )
        });

        

        return (
            <div style={{ position: 'relative', width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                    <div style={{ fontSize: 22, fontWeight: 500 }}>Major Attractions & Tourist Area:</div>
                    <div style={{ fontSize: 12, fontWeight: 500, display: 'flex', marginLeft: 25 }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}><MdStar alt='major attraction star desc' style={{ color: 'gold', height: 20, width: 20 }} /></div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>= Major Attraction</div>
                    </div>
                </div>
                <div>
                    {filteredListingMostVisitedPlaces}
                </div>
            </div>
        );
    }
}

export default MajorAttractionMatchComparison;