import React, { Component } from 'react';
import { Table } from 'react-bootstrap';
import YelpStarRating from './yelpStarRating';

class PopularBusinessTable extends Component {
    
    render() { 
        const { yelpData: { businesses: yelpBusinesses } = {}} = this.props;
        const topBusinesses = yelpBusinesses.sort((a, b) => {
            return b.review_count - a.review_count
        });
        const topTenBusinesses = topBusinesses.slice(0, 9);
        

        return (
            <div>
                <Table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Rating</th>
                            <th>Reviews</th>
                            <th>Price</th>
                            <th>Category</th>
                        </tr>
                    </thead>
                    <tbody>
                        {topTenBusinesses.map((business) => (
                        <tr>
                            <th style={{ fontSize: 14 }}>
                            {business.name}
                            </th>
                            <td>
                                <YelpStarRating business={business} />
                            </td>
                            <td style={{ fontSize: 14 }}>
                                {business.review_count}
                            </td>
                            <td style={{ fontSize: 14 }}>
                                {business.price}
                            </td>
                            <td style={{ fontSize: 14 }}>
                            {business.categories[0].title}
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }
}

export default PopularBusinessTable;