import React, { Component } from 'react';
import { 
    Modal, 
    ModalHeader, 
    ModalBody, 
    Row,
    Col }
from 'reactstrap';
import { Fab } from '@material-ui/core';
import BizListModal from '../CustomSearch/bizListModal';

const filterLabels = (label) => {    
    
    switch(label) {
        case "nearAttraction":
            return (
                "Near Major Attraction"
            )
        case "seasonal":
            return ( 
                "Seasonal"
            )
        case "busyDayTime":
            return ( 
                "Heavy Daytime Traffic"
            )
        case "busyMonThruFri":
            return (
                "Heavy Mon-Fri Traffic"
            )
        case "busyWeekend":
            return ( 
                "Heavy Weekend Traffic"
            )
        case "busyNightLife":
            return ( 
                "Heavy Nightlife"
            )
        case "localResidents":
            return (
                "Mostly Local Resident"
            )
        case "officeWorkers":
            return ( 
                "Mostly People at Work"
            )
        case "students":
            return (
                "Students"
            )
        case "highIncomeArea":
            return ( 
                "High Income Area"
            )
        case "genZ":
            return ( 
                "Gen Z"
            )
        case "millennialsTo34":
            return ( 
                "Young Millennials"
            )
        case "millennialsTo44":
            return ( 
                "Older Millennials"
            )
        case "genX":
            return ( 
                "Gen X"
            )
        case "boomers":
            return ( 
                "Boomers"
            )
        case "areasWithChildren":
            return ( 
                "Areas WITH Children"
            )
        case "affordableRE":
            return ( 
                "Affordable"
            )
        case "expensiveRE":
            return ( 
                "Expensive"
            )
        case "highEndBiz":
            return ( 
                "$$$$"

            )
        case "lowEndBiz":
            return ( 
                "$"

            )
        case "highBizDensity":
            return ( 
                "High Biz Density"

            )
        case "lowBizDensity" :
            return ( 
                "Low Biz Density"

            )
        case "cornerLocation":
            return ( 
                "Corner Location"

            )
        case "bigFrontage":
            return ( 
                "Big Frontage"
            )
        case "divisible":
            return ( 
                "Divisible"
            )
        case "multiLevel":
            return ( 
                "Multi Level"

            )
        case "newConstruction":
            return ( 
                "New Construction"

            )
        case "outdoorSpace":
            return ( 
                "Outdoor Space"

            )
        default:
            return null
    }
}

class MatchModal extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    }

    render() {
        
        const { lead, thisListing } = this.props;

        const colorCodeScores = (score) => {
            if (score === 50) {
                return 'rgb(228, 9, 9)';
            } else if (score <= 60) {
                return 'rgb(246, 79, 6)';
            } else if (score <= 70) {
                return 'rgb(237, 134, 12)';
            } else if (score <= 80) {
                return 'rgb(249, 183, 24)';
            } else if (score <= 90) {
                return 'rgb(145, 179, 8)';
            } else if (score <= 100) {
                return 'rgb(99, 157, 7)';
            } else {
                return 'rgb(81, 130, 2)';
            }
        }
        
        let leadMinSize = parseFloat(lead.fourcastMatch.minSize);
        let leadMaxSize = parseFloat(lead.fourcastMatch.maxSize);
        let leadBudget = parseFloat(lead.fourcastMatch.budget);
        let yearlyPriceOfSpace = Math.ceil(thisListing.squareFeet * thisListing.pricePerSquareFoot);
        
        return (
            <div>
                <Fab
                    variant="extended"
                    size="medium"
                    onClick={this.toggle}
                    style={{ backgroundColor: colorCodeScores(lead.fourcastMatch.score), fontSize: 14, width: 75, textAlign: 'center', color: 'white', outline: 'none' }}
                >
                    {lead.fourcastMatch.score}
                </Fab>
                <Modal style={{ minWidth: 450 }} isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Fourcast Match Details</ModalHeader>
                    <ModalBody style={{ width: 450, margin: 'auto' }}>
                        <h3 style={{ marginBottom: 20, fontFamily: 'sans-serif', wordSpacing: 1, textAlign: 'center', color: 'darkgreen' }}>{lead.sender.company}</h3>
                        <Row style={{ width: 450, textAlign: 'center' }}>
                            <Col sm={12}>
                                <p style={{ fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold' }}>Fourcast Match</p>
                            </Col>
                        </Row>
                        <Row style={{ width: 450, textAlign: 'center', marginBottom: 20 }}>
                            <Col sm={12}>
                                <h3 style={{ color: 'darkgreen', fontWeight: 'bold' }}>{lead.fourcastMatch.score}</h3>
                            </Col>
                        </Row>
                        <Row style={{ width: 425, textAlign: 'center' }}>
                            <div style={{ width: '100%', textAlign: 'left' }}>
                                <p style={{ fontSize: 14, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}>Applicant Background: </p>
                                <Col sm={12}>
                                    <span style={{ fontSize: 14, fontWeight: 'bold', fontFamily: 'sans-serif' }}>Business Type: </span><span style={{ fontSize: 12, fontFamily: 'sans-serif', color: 'black' }}> {lead.fourcastMatch.filters.userBizTypes.type2}</span>
                                </Col>
                            </div>
                        </Row>
                        <Row style={{ width: 425, marginTop: 30 }}>
                            <div style={{ fontSize: 13, paddingTop: 5 }} className='customSearchInfoBoxGoodBizArea'>
                                <span style={{ fontSize: 14, fontWeight: 'bold', color: 'darkgreen' }}>Good Businesses: </span>
                                {lead.fourcastMatch.filters.goodBiz.types.map(biz => (
                                <span className='customListingTags'>{((lead.fourcastMatch.filters.goodBiz.types.indexOf(biz)) === (lead.fourcastMatch.filters.goodBiz.types.length - 1)) ? ` ${biz.label}` : ` ${biz.label},`}</span>
                                ))}
                            </div>
                        </Row>
                        <Row style={{ width: 425 }}>
                            <div style={{ fontSize: 13, paddingTop: 5 }} className='customSearchInfoBoxBadBizArea'>
                                <span style={{ fontSize: 14, fontWeight: 'bold', color: 'darkred' }}>Bad Businesses: </span>
                                {lead.fourcastMatch.filters.badBiz.types.map(biz => (
                                <span className='customListingTags'>{((lead.fourcastMatch.filters.badBiz.types.indexOf(biz)) === (lead.fourcastMatch.filters.badBiz.types.length - 1)) ? ` ${biz.label}` : ` ${biz.label},`}</span>
                                ))}
                            </div>
                        </Row>
                        <Row style={{ width: 379, marginTop: 20, marginLeft: 1 }}>
                            <Col sm={6}>
                                <p style={{ marginTop: 10, textAlign: 'center', fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}>Good</p>
                                <div style={{ width: '100%', textAlign: 'left' }}>
                                <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif', marginBottom: 30 }}><span>+ Found <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}><BizListModal bizList={lead.fourcastMatch.filters.goodBiz.goodBizList} goodOrBad='good' /></span> Good Businesses Nearby</span></p>
                                    <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}>{thisListing.squareFeet && (thisListing.squareFeet.some(sqFt => sqFt >= leadMinSize)) ? <span>+ MIN: <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}>{leadMinSize}</span> sq.ft.</span> : null}</p>
                                    <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}>{thisListing.squareFeet && (thisListing.squareFeet.some(sqFt => sqFt <= leadMaxSize)) ? <span>+ MAX: <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}>{leadMaxSize}</span> sq.ft.</span> : null}</p>
                                    <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}>{yearlyPriceOfSpace && (yearlyPriceOfSpace <= leadBudget) ? <span>+ BUDGET: <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}>${leadBudget}</span> / year</span> : null}</p>
                                    {lead.fourcastMatch.filters.trueTags.length > 0 ? lead.fourcastMatch.filters.trueTags.map(tag => (
                                    <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}><span>+ <span style={{ fontSize: 14, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}> {filterLabels(tag)}</span></span></p>
                                    )) : null}
                                </div>
                            </Col>
                            <Col sm={6}>
                                <p style={{ marginTop: 10, textAlign: 'center', fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkred' }}>Bad</p>
                                <div style={{ width: '100%', textAlign: 'right' }}>
                                    <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif', marginBottom: 30 }}><span>- Found <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkred' }}><BizListModal bizList={lead.fourcastMatch.filters.badBiz.badBizList} goodOrBad='bad' /></span> Bad Businesses Nearby</span></p>
                                    <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}>{thisListing.squareFeet && ((thisListing.squareFeet[0] < leadMinSize) && (thisListing.squareFeet[thisListing.squareFeet.length - 1] < leadMinSize)) ? <span>- MIN: <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkred' }}>{leadMinSize}</span> sq.ft.</span> : null}</p>
                                    <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}>{thisListing.squareFeet && ((thisListing.squareFeet[0] > leadMaxSize) && (thisListing.squareFeet[thisListing.squareFeet.length - 1] > leadMaxSize)) ? <span>- MAX: <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkred' }}>{leadMaxSize}</span> sq.ft.</span> : null}</p>
                                    <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}>{yearlyPriceOfSpace && (yearlyPriceOfSpace > leadBudget) ? <span>- BUDGET: <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkred' }}>${leadBudget}</span> / year</span> : null}</p>
                                    {lead.fourcastMatch.filters.falseTags.length > 0 ? lead.fourcastMatch.filters.falseTags.map(tag => (
                                    <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}><span>- <span style={{ fontSize: 14, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkred' }}> {filterLabels(tag)}</span></span></p>
                                    )) : null}
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
 
export default MatchModal;

