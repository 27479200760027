import React, { useState } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ListingMap from './Components/listingsMap';
import CustomNavbar from './Components/welcome/navbar';
import UserProfile from './Components/userProfile/userProfile';
import EditListing from './Components/userProfile/editListings';
import CustomSearch from './Components/CustomSearch/customSearch';
import ListingDetails from './Components/listingDetails';
import WelcomeIndex from './Components/welcome/welcomeIndex';
import AboutIndex from './Components/about/aboutIndex';
import ForgotPassword from './Components/auth/forgotPassword';
import ResetPassword from './Components/auth/resetPassword';
import ContactForm from './Components/welcome/contactForm';
import PaymentInfo from './Components/auth/paymentInfo';
import PaymentSuccess from './Components/auth/paymentSuccess';
import PaymentFailed from './Components/auth/paymentFailed';
import CreateReport from './Components/CreateReports/createReport';
import CompareListingsIndex from './Components/CompareListings/indexCompareListings';
import SearchedAddressSummary from './Components/searchedAddressDetails/searchedAddressSummary';
import { Provider } from 'react-redux';
import store from './store';

function App() {
  const [location, setLocation] = useState('/');
  const getLocation = (theLoc) => {
    setLocation(theLoc);
  }
  return ( 
    <Provider store={store}>
      <Router>
        <div>
          {location === '/listings' ? null : <CustomNavbar currentPath={location} />}
          <Route exact path="/" render={(props) => <WelcomeIndex {...props} getLocation={getLocation} />} />
          <Route exact path="/about" render={(props) => <AboutIndex {...props} getLocation={getLocation} />} />
          <Route exact path="/contact" render={(props) => <ContactForm {...props} getLocation={getLocation} />} />
          <Route exact path="/listings" render={(props) => <ListingMap {...props} getLocation={getLocation} />} />
          <Route exact path="/compare-listings" render={(props) => <CompareListingsIndex {...props} getLocation={getLocation} />} />
          <Route exact path="/reports" render={(props) => <CreateReport {...props} getLocation={getLocation} />} />
          <Route exact path="/customSearch" render={(props) => <CustomSearch {...props} getLocation={getLocation} />} />
          <Route exact path="/user/:id" render={(props) => <UserProfile {...props} getLocation={getLocation} />} />
          <Route exact path="/user/editListing/:id" render={(props) => <EditListing {...props} getLocation={getLocation} />} />
          <Route exact path="/listings/:id" render={(props) => <ListingDetails {...props} getLocation={getLocation} />} />
          <Route exact path="/scores/searched-address/:address" render={(props) => <SearchedAddressSummary {...props} getLocation={getLocation} />} />
          <Route exact path="/forgot-password" render={(props) => <ForgotPassword {...props} getLocation={getLocation} />} />
          <Route exact path="/forgot-password/:resetToken" render={(props) => <ResetPassword {...props} getLocation={getLocation} />} />
          <Route exact path="/registration/payment-method" render={(props) => <PaymentInfo {...props}/>} />
          <Route exact path="/registration/payment-method/success" render={(props) => <PaymentSuccess {...props}/>} />
          <Route exact path="/registration/payment-method/failed" render={(props) => <PaymentFailed {...props}/>} />
        </div>
      </Router>
    </Provider> 
  );
}

export default App;
