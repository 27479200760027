import React, { Component } from 'react';
import { 
    Modal, 
    ModalHeader, 
    ModalBody,
    Container } 
from 'reactstrap';
import { Fab, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ImCheckmark, ImCross } from 'react-icons/im';
import { loadUser, updateUser } from '../../actions/authActions';
import './userProfile.css';

class UpgradeAccount extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            accountType: props.user.accountType,
            successAlert: false,
            changeMade: false
        }
    }


    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    }

    selectChange = state => value  => {
        this.setState({ [state]: value.value });
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
        this.setState({ successAlert: false });
    }
    
    onSubmit = e => {
        e.preventDefault();
        
        
        const { user } = this.props;
        
        let updatedUser = {
            userId: user._id,
            accountType: this.state.accountType === null ? user.accountType : this.state.accountType,
            engagement: user.engagement
        };
    
        updatedUser && updatedUser ? this.props.updateUser(updatedUser) : console.log('Could Not Update User');

        this.setState({ successAlert: true });

    }

    render() {
        
        return(
            <Container>
                <Fab
                    variant="extended"
                    size="md"
                    onClick={this.toggle}
                    style={{ color: 'white', width: 200, marginTop: 'auto', marginBottom: 'auto', backgroundColor: 'rgb(52, 181, 122)', fontSize: 12, outline: 'none' }}
                >
                + Create New Listing
                </Fab>
                <Modal style={{ minWidth: 1100 }} isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Upgrade To Broker</ModalHeader>
                    <ModalBody style={{ width: 1100, margin: 'auto', paddingBottom: 100 }}>
                        <div style={{ width: '100%', marginTop: 15, textAlign: 'center' }}><span style={{ fontSize: 22 }}>Upgrade to</span><span style={{ marginLeft: 5, marginRight: 5, fontSize: 26, fontWeight: 'bold', color: 'rgb(52, 181, 122)' }}>BROKER & Activate Payments</span><span style={{ fontSize: 22 }}>to Submit Listings</span></div>
                        <div style={{ width: '100%', marginTop: 25 }}><span style={{ paddingTop: 10, width: '100%', textDecoration: 'underline', textUnderlineOffset: 4, fontSize: 16 }}>Account Type:</span>{(!this.state.successAlert && this.state.changeMade) ? <span style={{ marginLeft: 25 }}><Button variant="contained" style={{ color: 'white', textDecoration: 'none', backgroundColor: 'rgb(52, 181, 122)', outline: 'none' }} onClick={this.onSubmit}>Save Changes</Button></span> : this.state.successAlert ? <span style={{ marginLeft: 25, color: 'darkgreen', fontWeight: 'bold' }}><ImCheckmark style={{ color: 'darkgreen' }} /> Changes Saved!</span> : null}</div>
                        <div className="accountTypeArea">
                            <div>
                            <Button 
                                variant="contained"
                                size="large"
                                style={this.state.accountType === 'basic' ? { outline: 'none', color: 'black', backgroundColor: 'white', border: '5px solid rgb(52, 181, 122)', fontSize: 12, width: 300, height: 150 } : { backgroundColor: 'whitesmoke', color: 'black', fontSize: 12, width: 300, height: 150 }}
                                category='basic'
                                checked={this.state.accountType === 'basic'}
                                onClick={(e) => {
                                    this.setState({ accountType: 'basic', successAlert: false, changeMade: true });
                                }}
                            >
                            <div>
                                <h4 style={{ marginTop: 15 }}>Basic</h4>
                                <p style={{ marginTop: 10, fontSize: 13 }}>Free</p>
                            </div>
                            </Button>
                            </div>
                            <div>
                            <Button 
                                variant="contained"
                                size="large"
                                style={this.state.accountType === 'pro' ? { outline: 'none', color: 'black', backgroundColor: 'white', border: '5px solid rgb(52, 181, 122)', fontSize: 12, width: 300, height: 150 } : { backgroundColor: 'whitesmoke', color: 'black', fontSize: 12, width: 300, height: 150 }}
                                category='pro'
                                checked={this.state.accountType === 'pro'}
                                onClick={(e) => {
                                    this.setState({ accountType: 'pro', successAlert: false, changeMade: true });
                                }}
                            >
                            <div>
                                <h4 style={{ marginTop: 15 }}>Pro</h4>
                                <p style={{ marginTop: 10, fontSize: 13 }}>$99.99 / month</p>
                            </div>
                            </Button>
                            </div>
                            <div>
                            <Button 
                                variant="contained"
                                size="large"
                                style={this.state.accountType === 'broker' ? { outline: 'none', color: 'black', backgroundColor: 'white', border: '5px solid rgb(52, 181, 122)', fontSize: 12, width: 300, height: 150 } : { backgroundColor: 'whitesmoke', color: 'black', fontSize: 12, width: 300, height: 150 }}
                                category='broker'
                                checked={this.state.accountType === 'broker'}
                                onClick={(e) => {
                                    this.setState({ accountType: 'broker', successAlert: false, changeMade: true });
                                }}
                            >
                            <div>
                                <h4 style={{ marginTop: 15 }}>Broker</h4>
                                <p style={{ marginTop: 10, fontSize: 13 }}>$49.99 per Listing / month</p>
                            </div>
                            </Button>
                            </div>
                        </div>
                        <div className="accountTypeDescArea">
                            <div style={{ marginLeft: 100 }}>
                                <div className="accountTypeDesc">
                                    <div><ImCheckmark style={{ color: 'darkgreen' }} /></div>
                                    <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Browse Listings</p>
                                </div>
                                <div className="accountTypeDesc">
                                    <div><ImCross style={{ color: 'darkred' }} /></div>
                                    <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Fourcast Match</p>
                                </div>
                                <div className="accountTypeDesc">
                                    <div><ImCross style={{ color: 'darkred' }} /></div>
                                    <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Submit & Advertise Listings</p>
                                </div>
                            </div>
                            <div style={{ marginLeft: 100 }}>
                                <div className="accountTypeDesc">
                                    <div><ImCheckmark style={{ color: 'darkgreen' }} /></div>
                                    <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Browse Listings</p>
                                </div>
                                <div className="accountTypeDesc">
                                    <div><ImCheckmark style={{ color: 'darkgreen' }} /></div>
                                    <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Fourcast Match</p>
                                </div>
                                <div className="accountTypeDesc">
                                    <div><ImCross style={{ color: 'darkred' }} /></div>
                                    <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Submit & Advertise Listings</p>
                                </div>
                            </div>
                            <div style={{ marginLeft: 100 }}>
                                <div className="accountTypeDesc">
                                    <div><ImCheckmark style={{ color: 'darkgreen' }} /></div>
                                    <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Browse Listings</p>
                                </div>
                                <div className="accountTypeDesc">
                                    <div><ImCheckmark style={{ color: 'darkgreen' }} /></div>
                                    <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Fourcast Match</p>
                                </div>
                                <div className="accountTypeDesc">
                                    <div><ImCheckmark style={{ color: 'darkgreen' }} /></div>
                                    <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Submit & Advertise Listings</p>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </Container>
        )
    }
}

UpgradeAccount.propTypes = {
    updateUser: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});

export default connect(mapStateToProps, { loadUser, updateUser })(UpgradeAccount);