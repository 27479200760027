import React, { Component } from 'react';
import { Table } from '@mui/material';
import PriceGauge from '../../../Assets/priceGauge.png';
import '../../listingDetailsLayouts/demographics/demographics.css';
import '../compareListings.css';

class HighEndBizComparison extends Component {
    
    
    render() {

        const { listings } = this.props;

        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.scores.fourcastScore - a.scores.fourcastScore});
        
        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }

        let getThisListingsBizPricesGauge = (thisListing) => {
            
            const bizPricesIndex = thisListing && thisListing.filterables && thisListing.filterables.bizPricesIndex && thisListing.filterables.bizPricesIndex;
            
            const percentageScore = bizPricesIndex && bizPricesIndex.percentageScore;
            const thisListingsPriceVolume = bizPricesIndex && bizPricesIndex.priceLevels;

            let priceGaugeNeedle = 0;
            
            if (percentageScore >= 95) {
                priceGaugeNeedle = 80;
            } else if (percentageScore >= 90) {
                priceGaugeNeedle = 70;
            } else if (percentageScore >= 85) {
                priceGaugeNeedle = 60;
            } else if (percentageScore >= 80) {
                priceGaugeNeedle = 40;
            } else if (percentageScore >= 75) {
                priceGaugeNeedle = 25;
            } else if (percentageScore >= 70) {
                priceGaugeNeedle = 15;
            } else if (percentageScore >= 65) {
                priceGaugeNeedle = 5;
            } else if (percentageScore >= 60) {
                priceGaugeNeedle = -5;
            } else if (percentageScore >= 55) {
                priceGaugeNeedle = -15;
            } else if (percentageScore >= 50) {
                priceGaugeNeedle = -25;
            } else if (percentageScore >= 45) {
                priceGaugeNeedle = -40;
            } else if (percentageScore >= 40) {
                priceGaugeNeedle = -60;
            } else if (percentageScore >= 35) {
                priceGaugeNeedle = -70;
            } else if (percentageScore >= 0) {
                priceGaugeNeedle = -80;
            } else {
                priceGaugeNeedle = 0;
            }

            
            const bizPricesObj = {address: thisListing.listingAddress, percentageScore: priceGaugeNeedle, thisListingsPriceVolume: thisListingsPriceVolume};
            return bizPricesObj;
        }


        // SORT BY RES PRICES
        const filteredListingsBizPriceGauges = sortedByFourcastScore && sortedByFourcastScore.map(listing => getThisListingsBizPricesGauge(listing));
        
        let listing1 = (filteredListingsBizPriceGauges && filteredListingsBizPriceGauges.length && (filteredListingsBizPriceGauges[0] !== undefined)) ? filteredListingsBizPriceGauges[0] : null;
        let listing2 = (filteredListingsBizPriceGauges && filteredListingsBizPriceGauges.length && (filteredListingsBizPriceGauges[1] !== undefined)) ? filteredListingsBizPriceGauges[1] : null;
        let listing3 = (filteredListingsBizPriceGauges && filteredListingsBizPriceGauges.length && (filteredListingsBizPriceGauges[2] !== undefined)) ? filteredListingsBizPriceGauges[2] : null;
        let listing4 = (filteredListingsBizPriceGauges && filteredListingsBizPriceGauges.length && (filteredListingsBizPriceGauges[3] !== undefined)) ? filteredListingsBizPriceGauges[3] : null;

        const setBizPricesNeedle = async (gauge, el) => {
            setTimeout(async () => {
                const bizPricesGauge = await document.querySelector(el);
                let bizPricesRotation = gauge;                
                bizPricesGauge && await bizPricesGauge.style.setProperty('--rotation', bizPricesRotation);
            }, 100, { once: true });
        }

        (listing1 !== null) && listing1.percentageScore && setBizPricesNeedle(listing1.percentageScore, '.bizPricesComparisonNeedle1');
        (listing2 !== null) && listing2.percentageScore && setBizPricesNeedle(listing2.percentageScore, '.bizPricesComparisonNeedle2');
        (listing3 !== null) && listing3.percentageScore && setBizPricesNeedle(listing3.percentageScore, '.bizPricesComparisonNeedle3');
        (listing4 !== null) && listing4.percentageScore && setBizPricesNeedle(listing4.percentageScore, '.bizPricesComparisonNeedle4');
        

        let display$ = (listing) => {
            return (
                <>
                    {(listing && listing.thisListingsPriceVolume && listing.thisListingsPriceVolume.priceLevel1) ? ( 
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                                <div style={{ fontWeight: 800, marginLeft: 5 }}>{listing && listing.thisListingsPriceVolume && listing.thisListingsPriceVolume.priceLevel1}</div>
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }
        let display$$ = (listing) => {
            return (
                <>
                    {(listing && listing.thisListingsPriceVolume && listing.thisListingsPriceVolume.priceLevel2) ? ( 
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                                <div style={{ fontWeight: 800, marginLeft: 5 }}>{listing && listing.thisListingsPriceVolume && listing.thisListingsPriceVolume.priceLevel2}</div>
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }
        let display$$$ = (listing) => {
            return (
                <>
                    {(listing && listing.thisListingsPriceVolume && listing.thisListingsPriceVolume.priceLevel3) ? ( 
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                                <div style={{ fontWeight: 800, marginLeft: 5 }}>{listing && listing.thisListingsPriceVolume && listing.thisListingsPriceVolume.priceLevel3}</div>
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }
        let display$$$$ = (listing) => {
            return (
                <>
                    {(listing && listing.thisListingsPriceVolume && listing.thisListingsPriceVolume.priceLevel4) ? ( 
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                                <div style={{ fontWeight: 800, marginLeft: 5 }}>{listing && listing.thisListingsPriceVolume && listing.thisListingsPriceVolume.priceLevel4}</div>
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }

        let filteredListings$ = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsBiz$ = getThisListingsBizPricesGauge(listing);
            return (
                <td style={{ fontSize: 14, padding: 10 }}>
                    {thisListingsBiz$ && display$(thisListingsBiz$)}
                </td>
            )
        });
        let filteredListings$$ = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsBiz$$ = getThisListingsBizPricesGauge(listing);
            return (
                <td style={{ fontSize: 14, padding: 10 }}>
                    {thisListingsBiz$$ && display$$(thisListingsBiz$$)}
                </td>
            )
        });
        let filteredListings$$$ = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsBiz$$$ = getThisListingsBizPricesGauge(listing);
            return (
                <td style={{ fontSize: 14, padding: 10 }}>
                    {thisListingsBiz$$$ && display$$$(thisListingsBiz$$$)}
                </td>
            )
        });
        let filteredListings$$$$ = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsBiz$$$$ = getThisListingsBizPricesGauge(listing);
            return (
                <td style={{ fontSize: 14, padding: 10 }}>
                    {thisListingsBiz$$$$ && display$$$$(thisListingsBiz$$$$)}
                </td>
            )
        });

        return (
            <div style={{ position: 'relative', width: '98%' }}>
                <div style={{ display: 'flex', justifyContent: 'left', paddingBottom: 20 }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>High-End Business Area Gauge</div>
                </div>
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', justifyContent: 'space-around', marginTop: 40 }}>
                    {listing1 !== null ? (<div style={{ position: 'relative' }}>
                        <div className='resPriceGaugeComparison'>
                            <img src={PriceGauge} alt='' />
                            <div className='bizPricesComparisonNeedle1'></div>
                        </div>
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing1.address)}</div>
                    </div>) : null}
                    {listing2 !== null ? (<div style={{ position: 'relative' }}>
                        <div className='resPriceGaugeComparison'>
                            <img src={PriceGauge} alt='' />
                            <div className='bizPricesComparisonNeedle2'></div>
                        </div>
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing2.address)}</div>
                    </div>) : null}
                    {listing3 !== null ? (<div style={{ position: 'relative' }}>
                        <div className='resPriceGaugeComparison'>
                            <img src={PriceGauge} alt='' />
                            <div className='bizPricesComparisonNeedle3'></div>
                        </div>
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing3.address)}</div>
                    </div>) : null}
                    {listing4 !== null ? (<div style={{ position: 'relative' }}>
                        <div className='resPriceGaugeComparison'>
                            <img src={PriceGauge} alt='' />
                            <div className='bizPricesComparisonNeedle4'></div>
                        </div>
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing4.address)}</div>
                    </div>) : null}
                </div>
                <div style={{ marginTop: 50 }}>
                    <div style={{ width: '90%', display: 'flex', justifyContent: 'center', paddingTop: 30, marginLeft: 'auto', marginRight: 'auto', paddingBottom: 10 }}>
                        <div style={{ fontWeight: 500, fontSize: 20 }}>Number of Businesses by Price Level</div>
                    </div>
                    <div>
                        <Table style={{ tableLayout: 'fixed' }}>
                            <thead>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '10%' }}></th>
                                    {sortedByFourcastScore && sortedByFourcastScore.map(listing => {return (<th style={{ padding: 20, color: 'darkgreen' }}>{addressStyle(listing.listingAddress)}</th>)})}
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '10%' }}>
                                       $
                                    </th>
                                    {filteredListings$}
                                </tr>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '10%' }}>
                                        $$
                                    </th>
                                    {filteredListings$$}
                                </tr>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '10%' }}>
                                       $$$
                                    </th>
                                    {filteredListings$$$}
                                </tr>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '10%' }}>
                                        $$$$
                                    </th>
                                    {filteredListings$$$$}
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}

export default HighEndBizComparison;
