import { GET_LISTINGS, GET_LISTING, ADD_LISTING, ADD_LISTING_FAIL, LISTINGS_LOADING, UPDATE_LISTING, GET_SAVED_LISTINGS } from '../actions/types';

const initialState = {
    listings: [],
    loading: false,
    thisListing: [],
    savedListings: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_LISTINGS:
            return {
                ...state,
                listings: action.payload,
                loading: false
            };
        case GET_LISTING:
            return {
                ...state,
                thisListing: action.payload,
                loading: false
            }
        case UPDATE_LISTING:
            return {
                ...state,
                thisListing: action.payload,
            }
        case ADD_LISTING:
            return {
                ...state,
                listings: [action.payload, ...state.listings]
            };
        case LISTINGS_LOADING:
            return {
                ...state,
                loading: true
            };
        case ADD_LISTING_FAIL:
            return {
                ...state,
            };
        case GET_SAVED_LISTINGS:
            return {
                ...state,
                savedListings: action.payload,
                loading: false
            };
        default:
            return state
    }
};