import React from 'react';
import { Card, CardContent } from '@mui/material';
import Footer from './footer';

export default function ContactForm(props) {
    
    return (
        <>
            <div id="email-area" style={{ backgroundColor: 'whitesmoke', height: '100vh', paddingTop: 50 }}>
                <Card style={{ textAlign: 'center', backgroundColor: 'rgb(20, 83, 55)', width: '75vw', height: '75vh', margin: 'auto' }}>
                    <CardContent>
                        <h1 style={{ color: 'white', width: '60%', margin: '100px auto', fontFamily: 'Arial, Helvetica, sans-serif', letterSpacing: 1, fontWeight: 'bold' }}>Reach out!</h1>
                        <h4 style={{ color: 'white', width: '60%', margin: '50px auto', fontFamily: 'Arial, Helvetica, sans-serif', letterSpacing: 1, fontWeight: 'bold' }}>
                            <span>Email us at</span><span style={{ marginLeft: 5, fontStyle: 'italic', letterSpacing: 1 }}> support@fourcastscore.com</span>
                        </h4>
                    </CardContent>
                </Card>
            </div>
            <Footer />
        </>
    )
}
