import React, { Component } from 'react';
import { 
    Modal, 
    ModalHeader, 
    ModalBody, 
    Row } 
from 'reactstrap';
import { Button } from '@mui/material';
import LoginModal from '../auth/loginModal';
import RegisterModal from '../auth/registerModal';
import fourcastLogo from '../../Assets/fourcast_alt_logo_green.png';

class CustomLoginRegisterModal extends Component {
    
    constructor(props) {
        
        super(props);
        this.state = {
            modal: false,
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    }

    handleChange = input => e => {
        this.setState({ [input]: e.target.value });
    }

    render() {
        
        return (
            <div style={{ width: '100%' }}>
                <Button
                    onClick={this.toggle}
                    style={{ width: 200, margin: 'auto', marginTop: 20, fontSize: 16, fontWeight: 'bold', color: 'white', textShadow: '1px 1px 1px black', letterSpacing: 1, backgroundColor: 'rgb(21, 134, 84)', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}
                >
                    TRY IT FOR FREE!
                </Button>
                <Modal style={{ minWidth: 650 }} isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Login or Register</ModalHeader>
                    <ModalBody style={{ width: 650, margin: 'auto' }}>
                        <Row>
                            <img style={{ marginTop: '3rem', width: 350, marginRight: 'auto', marginLeft: 'auto' }} alt='Fourcast Logo Log In' src={fourcastLogo} />
                        </Row>
                        <Row style={{ marginTop: 60 }}>
                            <strong style={{ marginLeft: 225 }}>Log In or Sign Up For Free</strong>
                        </Row>
                        <Row style={{ marginTop: 50, paddingBottom: 40 }}>
                            <span><Button style={{ marginLeft: 150, backgroundColor: '#007FFF', color: 'white', width: 125 }}>
                                    <LoginModal />
                                </Button>
                            </span>
                            <span><Button style={{ marginLeft: 100, backgroundColor: 'rgb(35, 126, 85)', color: 'white', width: 125 }}>
                                    <RegisterModal />
                                </Button>
                            </span>
                        </Row>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
 
export default CustomLoginRegisterModal;