import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getListings, getListing } from '../../actions/listingActions';
import { loadUser, updateUser } from '../../actions/authActions';
import PropTypes from 'prop-types';
import './compareListings.css';
import CompareListingsWelcomePage from './welcomeCompareListings';
import CompareListings from './compareListings';


class CompareListingsIndex extends Component {

    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            allListingInfo: [],
            selected: [],
            doubleChecked: false
        }
    }
    
    async componentDidMount() {
        
        const { isAuthenticated } = this.props;

        this.props.getLocation(this.props.location.pathname);
        await this.props.loadUser();

        if (isAuthenticated && this.props.location.state) {
            
            const { listingIds } = this.props.location.state;
    
            if (listingIds.length) {
                this.setSelected(listingIds);
            }
            this.setState({ step: 1 });
            
            const { user } = this.props;

            let searchCount = user && user.engagement.searches;
            searchCount += 1;

            /*let saveThisSearch = (customSearchFilters && customSearchFilters.saveThisSearch && (customSearchFilters.saveThisSearch === true)) ? true : false;
            const newSearches = [...user.engagement.savedSearches, customSearchFilters];
            const mapNewSearches = newSearches && newSearches.length && newSearches.flatMap(search => search);
            const uniqueSavedSearches = mapNewSearches && mapNewSearches.length && [...new Map(mapNewSearches.map(v => [JSON.stringify(v && v), v])).values()];
            const savedSearches = (saveThisSearch && (saveThisSearch === true)) ? uniqueSavedSearches : user.engagement.savedSearches;
            
            const updatedUser = {
                userId: user._id,
                accountType: user.accountType,
                engagement: {
                    listings: user.engagement.listings,
                    searches: searchCount,
                    savedListings: user.engagement.savedListings,
                    savedSearches: savedSearches
                }
            };
            
            updatedUser && updatedUser ? this.props.updateUser(updatedUser) : console.log('User undefined');*/
        }
        
    }

    async componentDidUpdate(prevProps, prevState) {
        
        // USER INFO
        const { user, isAuthenticated } = this.props;
        const { doubleChecked } = this.state;
        const getUserSubData = user && user.stripeSubData;

        if (!getUserSubData) {
            if (user && (doubleChecked === false)) {
                await this.checkUserAgain();
            }
        }

        // LISTING INFO
        const { selected, allListingInfo } = this.state;
        const { thisListing } = this.props;

        const selectedAddys = selected && selected.length && selected.map(selectedListing => selectedListing.label).sort();
        const gatheredAddys = allListingInfo && allListingInfo.length && allListingInfo.map(gatheredListing => gatheredListing.listingAddress).sort();
        
        if (user && isAuthenticated) {
            if ((selected.length !== allListingInfo.length) || (JSON.stringify(selectedAddys) !== JSON.stringify(gatheredAddys))) {
                if ((!thisListing._id) || (JSON.stringify(selectedAddys) !== JSON.stringify(gatheredAddys))) {
                    if (selected.length !== allListingInfo.length) {
                        this.getSelectedListings(selected);
                    }
                }

                if (thisListing && thisListing._id) {
                    if (selected.some(selectListing => selectListing.value === thisListing._id)) {
                        if (allListingInfo.some(gatheredListing => gatheredListing._id === thisListing._id)) {
                            return null;
                        } else { 
                            allListingInfo.push(thisListing);
                        }
                    } else {
                        await this.getSelectedListings(selected);
                    }
                }
            }
        }
    }

    checkUserAgain = async () => {
        await this.props.loadUser();
        this.setState({ doubleChecked: true });
    }


    // Select the listings to generate comparison for
    setSelected = (selectedListings) => {
        const { allListingInfo } = this.state;
        const selectedListingAddys = selectedListings && selectedListings.length && selectedListings.map(selectedList => selectedList.label).sort();
        const filterAllListingInfo = allListingInfo && allListingInfo.length && allListingInfo.filter(gatheredListing => selectedListingAddys && selectedListingAddys.includes(gatheredListing.listingAddress));

        if (filterAllListingInfo && (filterAllListingInfo.length === allListingInfo.length)) {
            this.setState({ selected: selectedListings });
        } else {
            this.setState({ selected: selectedListings, allListingInfo: [] });
        }
    }

    // Get the selected listing's data
    getSelectedListings = async (selected) => {
    
        if (selected.length) {
            for (let i = 0; i < selected.length; i++) {
                try {
                    let listingId;
                    listingId = selected[i].value;
                    await this.props.getListing(listingId);
                } catch (err) {
                    console.log(err);
                }
            }
        }
    }


    // Proceed to next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({ step: step + 1 });
    }

    // Go back to previous step
    prevStep = () => {
        const { step } = this.state;
        this.setState({ step: step - 1, selected: [] });
    }

    // Select the listings to generate reports for
    setSelected = (selectedListings) => {
        this.setState({ selected: selectedListings });
    }

    
    render() {
        const { isAuthenticated } = this.props;
        const { step, selected, allListingInfo } = this.state;
        

        switch(step) {
            case 0:
                return (
                    <CompareListingsWelcomePage 
                        nextStep={this.nextStep}
                    />
                )
            case 1:
                return (
                    <>{(isAuthenticated) ? <CompareListings 
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        setSelected={this.setSelected}
                        selected={selected}
                        allListingInfo={allListingInfo}
                    /> : 'Loading...'}</>
                )
            default:
                return (
                    <CompareListingsWelcomePage 
                        nextStep={this.nextStep}
                    />
                )
        }
    }
}

CompareListingsIndex.propTypes = {
    getListings: PropTypes.func.isRequired,
    listings: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    listings: state.listing.listings,
    thisListing: state.listing.thisListing,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});

export default connect(mapStateToProps, { getListings, getListing, loadUser, updateUser })(CompareListingsIndex);