import React, { Component } from 'react';
import { Row } from 'reactstrap';
import { Button } from '@material-ui/core';
import { Tabs, Tab, Box, Dialog, DialogContent, DialogContentText, DialogActions, IconButton, Fab, Tooltip } from '@mui/material';
import { MdFavoriteBorder, MdFavorite, MdArrowBack } from 'react-icons/md';
import { HiOutlineDocumentReport } from "react-icons/hi";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getListing, setListingsLoading } from '../actions/listingActions';
import { loadUser, updateUser } from '../actions/authActions';
import LoginModal from './auth/loginModal';
import RegisterModal from './auth/registerModal';
import PropTypes from 'prop-types';
import Summary from './listingDetailsLayouts/summary/summary';
import MobileSummary from './listingDetailsLayouts/summary/mobileSummary';
import Traffic from './listingDetailsLayouts/traffic/traffic';
import MobileTraffic from './listingDetailsLayouts/traffic/mobileTraffic';
import Demographics from './listingDetailsLayouts/demographics/demographics';
import MobileDemographics from './listingDetailsLayouts/demographics/mobileDemographics';
import Competition from './listingDetailsLayouts/competition/competition';
import MobileCompetition from './listingDetailsLayouts/competition/mobileCompetition';
import Property from './listingDetailsLayouts/property/property';
import MobileProperty from './listingDetailsLayouts/property/mobileProperty';
import ContactModal from './contactModal';
import SkeletonDetailsPage from './skeletons/skeletonDetailsPage';
import Footer from './welcome/footer';
import MobileFooter from './welcome/mobileFooter';
import '../Components/listingDetails.css';
import Joyride from './skeletons/joyRide';
import FourcastReportLogo from '../Assets/fourcast_report_logo_green.png';


export class ListingDetails extends Component {
    
    
    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
            categoryName: 'summary',
            thisListing: props.thisListing,
            walkScoreData: props.walkScoreData,
            yelpData: props.yelpData,
            nearbyPlaces: props.nearbyPlaces,
            filterables: props.filterables,
            scores: props.scores,
            joyRide: false,
            isThisSaved: false,
            loginWarning: false,
            createReport: false,
            screenWidth: null,
        }
    }

    componentDidMount() {
        this.props.getListing(this.props.match.params.id);
        this.props.getLocation(this.props.location.pathname);

        window && window.removeEventListener('resize', this.mobileMode.bind(this));
        // setting mobile views
        let screenWidth = window.screen.width;
        this.setState({ screenWidth: screenWidth });
        
        const { user, thisListing } = this.props;

        const usersSavedListings = user && user.engagement && user.engagement.savedListings;
        usersSavedListings && usersSavedListings.includes(thisListing._id) ? this.setState({ isThisSaved: true }) : this.setState({ isThisSaved: false });

        const firstTimeJoyride = localStorage.getItem("firstTimeDetailsJoyride");
        if (!firstTimeJoyride) {
            const timer = setTimeout(() => {
            this.setState({ joyRide: true })
            localStorage.setItem("firstTimeDetailsJoyride", "1");
            }, 2000);
            return () => clearTimeout(timer);
        }
    }

    componentDidUpdate(prevProps) {
        const { user, thisListing } = this.props;

        if (user !== prevProps.user) {
            const usersSavedListings = user && user.engagement && user.engagement.savedListings;

            usersSavedListings && usersSavedListings.includes(thisListing._id) ? this.setState({ isThisSaved: true }) : this.setState({ isThisSaved: false });
        }
    }

    componentWillUnmount() {
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
    }

    mobileMode() {
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth });
        }
    }

    setJoyRide = (value) => {
        this.setState({ joyRide: value })
    }

    changeSavedState = (value) => {
        this.setState({ isThisSaved: value });
    }

    saveListing = () => {
        
        const { user, thisListing } = this.props;

        if (user && thisListing) {
            let savedListings = user && user.engagement.savedListings;

            let newListingId = thisListing && thisListing._id;

            let newSavedListings = [...new Set([...savedListings, newListingId])];
            
            const updatedUser = {
                userId: user._id,
                accountType: user.accountType,
                engagement: {
                    listings: user.engagement.listings,
                    searches: user.engagement.searches,
                    savedListings: newSavedListings,
                    savedSearches: user.engagement.savedSearches
                }
            };
            
            updatedUser && updatedUser ? this.props.updateUser(updatedUser) : console.log('User undefined');
            if (!this.state.isThisSaved) {
                this.changeSavedState(true);
            }
        }
        
        if (!user) {
            this.setState({ loginWarning: true });
        }
    }

    unsaveListing = () => {
        
        const { user, thisListing } = this.props;

        if (user && thisListing) {
            let savedListings = user && user.engagement.savedListings;

            let newListingId = thisListing && thisListing._id;

            let filteredListings = savedListings.filter(savedListing => savedListing !== newListingId);

            let newSavedListings = [...new Set([...filteredListings])];
            
            const updatedUser = {
                userId: user._id,
                accountType: user.accountType,
                engagement: {
                    listings: user.engagement.listings,
                    searches: user.engagement.searches,
                    savedListings: newSavedListings,
                    savedSearches: user.engagement.savedSearches
                }
            };
            
            updatedUser && updatedUser ? this.props.updateUser(updatedUser) : console.log('User undefined');
            if (this.state.isThisSaved) {
                this.changeSavedState(false);
            }
        }
        
        if (!user) {
            this.setState({ loginWarning: true });
        }
    }

    render() { 

        const { screenWidth } = this.state;
        const { thisListing, walkScoreData, yelpData, nearbyPlaces, filterables, scores, attomData, user, isAuthenticated } = this.props;
        const { thisListing: { listingAddress: getListingAddress } = {}} = this.props;

        let thisAddressArray = [];
        thisAddressArray.push(getListingAddress);
        let addressString = thisAddressArray.toString();
        let thisListingAddress = addressString.split(',');


        const tabClick = (e, value) => this.setState({ clicked: true, categoryName: value });


        const setCategoryDetails = (category) => {    
    
            switch(category) {
                case "summary":
                    return (
                        (screenWidth > 1000) ? <Summary thisListing={thisListing} scores={scores} /> : <MobileSummary thisListing={thisListing} scores={scores} screenWidth={screenWidth} />
                    )
                case "traffic":
                    return (
                        (screenWidth > 1000) ? <Traffic thisListing={thisListing} walkScoreData={walkScoreData} yelpData={yelpData} nearbyPlaces={nearbyPlaces} filterables={filterables} scores={scores} attomData={attomData} /> : <MobileTraffic thisListing={thisListing} walkScoreData={walkScoreData} yelpData={yelpData} nearbyPlaces={nearbyPlaces} filterables={filterables} scores={scores} attomData={attomData} screenWidth={screenWidth} />
                    )
                case "demographics":
                    return ( 
                        (screenWidth > 1000) ? <Demographics thisListing={thisListing} scores={scores} attomData={attomData} filterables={filterables} /> : <MobileDemographics thisListing={thisListing} scores={scores} attomData={attomData} filterables={filterables} screenWidth={screenWidth} />
                        
                    )
                case "competition":
                    return ( 
                        (screenWidth > 1000) ? <Competition thisListing={thisListing} yelpData={yelpData} scores={scores} filterables={filterables} /> : <MobileCompetition thisListing={thisListing} yelpData={yelpData} scores={scores} filterables={filterables} screenWidth={screenWidth} />
                    )
                case "property":
                    return (
                        (screenWidth > 1000) ? <Property thisListing={thisListing} scores={scores} filterables={filterables} /> : <MobileProperty screenWidth={screenWidth} thisListing={thisListing} scores={scores} filterables={filterables} />
                    )
                default:
                    return (
                        (screenWidth > 1000) ? <Summary thisListing={thisListing} scores={scores} /> : <MobileSummary thisListing={thisListing} scores={scores} screenWidth={screenWidth} />
                    )
            }
        }
        
        let skeletonLength = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

        const getUserSubData = user && user.stripeSubData;
        const stripeSubStatus = getUserSubData && getUserSubData.status;
        const stripeSubType = getUserSubData && getUserSubData.subType;

        const requestedListing = {label: thisListing.listingAddress, value: thisListing._id};
        
        return (
            <>
            {(this.state.joyRide === true) ? (
                <>
                    <Joyride run={this.state.joyRide} page={'listingDetails'} timeout={4500} setJoyRide={this.setJoyRide} />
                </>
            ) : null}
            {(!scores || !filterables || !yelpData) ? skeletonLength.map((n) => <SkeletonDetailsPage key={n} />) : (
            <div fluid='true' style={{ backgroundColor: 'whitesmoke', minWidth: 'min-content' }}>
                {(thisListing && (screenWidth !== null)) ? (
                <div>
                    <div className="banner" style={{ height: `${(screenWidth < 1000) ? 175 : 125}`, width: '100%', display: `${(screenWidth < 1000) ? null : 'grid'}`, gridTemplateColumns: '1fr 2fr 1fr', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', position: 'relative' }}>
                    {screenWidth < 1000 ? (
                        <div className='bannerTextSummary' style={{ display: 'grid', gridTemplateColumns: '1fr 6fr', paddingTop: 20, paddingBottom: 20 }}>
                            <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                <Link to='/listings'>
                                    <IconButton style={{ width: '100%', height: '100%' }}>
                                        <MdArrowBack />
                                    </IconButton>
                                </Link>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <h2 style={{ fontSize: '1.5em', paddingLeft: 10, marginTop: 'auto', marginBottom: 'auto' }}>{thisListingAddress[0]}</h2>
                            </div>
                        </div>) : (<div className='bannerTextSummary' style={{ display: 'grid', gridTemplateColumns: '1fr 6fr', height: 125 }}>
                            <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                <Link to='/listings'>
                                    <IconButton style={{ width: '100%', height: '100%' }}>
                                        <MdArrowBack />
                                    </IconButton>
                                </Link>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <h2 style={{ fontSize: '1.75em', paddingLeft: 10, marginTop: 'auto', marginBottom: 'auto' }}>{thisListingAddress[0]}</h2>
                            </div>
                        </div>)}
                        {screenWidth < 1000 ? (
                        <div style={{ width: '100%', paddingBottom: 20 }}>
                            <Box sx={{ maxWidth: 390 }}>
                                <Tabs
                                    value={this.state.categoryName}
                                    onChange={tabClick}
                                    textColor="primary"
                                    indicatorColor="primary"
                                    variant="scrollable"
                                    scrollButtons
                                    allowScrollButtonsMobile
                                    aria-label="secondary tabs example"
                                    TabIndicatorProps={{
                                        style: {
                                        background: "rgb(52, 181, 122)",
                                        }
                                    }}
                                >
                                    <Tab style={{ marginLeft: 5, marginRight: 5, fontSize: 14, fontWeight: 600, outline: 'none' }} value="summary" label="Summary" />
                                    <Tab style={{ marginLeft: 5, marginRight: 5, fontSize: 14, fontWeight: 600, outline: 'none' }} value="traffic" label="Traffic" />
                                    <Tab style={{ marginLeft: 5, marginRight: 5, fontSize: 14, fontWeight: 600, outline: 'none' }} value="demographics" label="Demographics" />
                                    <Tab style={{ marginLeft: 5, marginRight: 5, fontSize: 14, fontWeight: 600, outline: 'none' }} value="competition" label="Competition" />
                                    <Tab style={{ marginLeft: 5, marginRight: 5, fontSize: 14, fontWeight: 600, outline: 'none' }} value="property" label="Property" />
                                </Tabs>
                            </Box>
                        </div>) : (<div style={{ width: '100%' }}>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Tabs
                                    value={this.state.categoryName}
                                    onChange={tabClick}
                                    textColor="primary"
                                    indicatorColor="primary"
                                    aria-label="secondary tabs example"
                                    TabIndicatorProps={{
                                        style: {
                                        background: "rgb(52, 181, 122)",
                                        }
                                    }}
                                >
                                    <Tab style={{ marginLeft: 10, marginRight: 10, fontSize: 14, fontWeight: 600, outline: 'none' }} value="summary" label="Summary" />
                                    <Tab style={{ marginLeft: 10, marginRight: 10, fontSize: 14, fontWeight: 600, outline: 'none' }} value="traffic" label="Traffic" />
                                    <Tab style={{ marginLeft: 10, marginRight: 10, fontSize: 14, fontWeight: 600, outline: 'none' }} value="demographics" label="Demographics" />
                                    <Tab style={{ marginLeft: 10, marginRight: 10, fontSize: 14, fontWeight: 600, outline: 'none' }} value="competition" label="Competition" />
                                    <Tab style={{ marginLeft: 10, marginRight: 10, fontSize: 14, fontWeight: 600, outline: 'none' }} value="property" label="Property" />
                                </Tabs>
                            </Box>
                        </div>)}
                        <div style={{ display: 'flex', justifyContent: `${screenWidth < 1000 ? 'center' : 'space-around'}`, alignItems: 'center' }}>
                            <div id='saveListingHeart' style={{  width: '45%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                {this.state.isThisSaved ? (<Tooltip title="Unsave Listing">
                                    <Fab style={{ backgroundColor: 'rgb(228, 114, 103)' }} onClick={() => this.unsaveListing()}>
                                        {thisListing ? <MdFavorite style={{ height: '50%', width: '50%' }} color={'rgb(250, 250, 250)'} /> : null}
                                    </Fab>
                                </Tooltip>) : (<Tooltip title="Save Listing">
                                    <Fab onClick={() => this.saveListing()}>
                                        {thisListing ? <MdFavoriteBorder style={{ height: '50%', width: '50%' }} color={'rgb(228, 114, 103)'} /> : null}
                                    </Fab>
                                </Tooltip>)}
                                <Tooltip title="Generate PDF Report">
                                    <Fab style={{ backgroundColor: 'rgb(30, 144, 255)' }} onClick={() => this.setState({ createReport: true })}>
                                        <HiOutlineDocumentReport style={{ width: '50%', height: '50%' }} color='white' />
                                    </Fab>
                                </Tooltip>
                                <Dialog
                                    open={this.state.createReport}
                                    onClose={() => this.setState({ createReport: false })}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{ zIndex: 999 }}
                                >
                                    <DialogContent>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 50 }}>
                                            <img src={FourcastReportLogo} style={{ width: 289, height: 80 }} alt="Fourcast Report Logo" />
                                        </div>
                                        <DialogContentText id="alert-dialog-description" style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bolder' }}>
                                            Generate a custom PDF Report of this listing.
                                        </DialogContentText>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10, marginBottom: 40 }}>
                                            {isAuthenticated ? (((user.accountType !== 'basic') && ((stripeSubStatus && stripeSubStatus === 'active') || (stripeSubStatus && stripeSubStatus === 'trialing')) && (stripeSubType && stripeSubType !== 'basic')) || (user.accountType === 'admin')) ? 
                                            (
                                                <Link style={{ textDecoration: 'none' }} to={{
                                                pathname: '/reports',
                                                state: {
                                                    requestedListing: requestedListing
                                                }
                                            }}>
                                                    <Button style={{ color: 'white', backgroundColor: 'dodgerblue', marginTop: 40 }} variant="contained" size="large">Continue</Button>
                                                </Link>
                                            ) : (user && user.engagement.searches > 1) ? 
                                            (
                                                <Link style={{ textDecoration: 'none' }} to={`/user/${user._id}`}>
                                                    <Button style={{ color: 'white', backgroundColor: 'dodgerblue', marginTop: 40 }} variant="contained" size="large">Upgrade To Continue</Button>
                                                </Link>
                                            ) : 
                                            (
                                                <Link style={{ textDecoration: 'none' }} to={{
                                                pathname: '/reports',
                                                state: {
                                                    requestedListing: requestedListing
                                                }
                                            }}>
                                                    <Button style={{ color: 'white', backgroundColor: 'dodgerblue', marginTop: 40 }} variant="contained" size="large">Continue</Button>
                                                </Link>
                                            ) : 
                                            (<div style={{ width: '100%', marginTop: 25, display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                                <Button style={{ backgroundColor: '#007FFF', color: 'white', width: 125 }}>
                                                    <LoginModal style={{ zIndex: 1001 }} />
                                                </Button>
                                                <Button style={{ backgroundColor: 'rgb(35, 126, 85)', color: 'white', width: 125 }}>
                                                    <RegisterModal style={{ zIndex: 1002, color: 'white' }} />
                                                </Button>
                                            </div>)}
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => this.setState({ createReport: false })}>
                                            Close
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                                <Dialog
                                    open={this.state.loginWarning}
                                    onClose={() => this.setState({ loginWarning: false })}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    style={{ zIndex: 999 }}
                                >
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description" style={{ marginTop: 25, padding: 10, fontWeight: 'bolder', color: 'darkred' }}>
                                            Must be logged in to save listings.
                                        </DialogContentText>
                                        <div style={{ width: '100%', marginTop: 25, paddingBottom: 25, display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                            <Button style={{ backgroundColor: '#007FFF', color: 'white', width: 125 }}>
                                                <LoginModal style={{ zIndex: 1001 }} />
                                            </Button>
                                            <Button style={{ backgroundColor: 'rgb(35, 126, 85)', color: 'white', width: 125 }}>
                                                <RegisterModal style={{ zIndex: 1002, color: 'white' }} />
                                            </Button>
                                        </div>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => this.setState({ loginWarning: false })}>
                                            Okay
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </div>
                            <div style={{ paddingBottom: 10 }}>
                                {thisListing ? <ContactModal selected={thisListing} /> : null}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <Row className="mainContent">
                        {this.props.thisListing ? setCategoryDetails(this.state.categoryName) : 'LOADING...'}
                    </Row>
                    <hr />
                    <Row>
                        {((screenWidth < 1000) || (screenWidth === undefined)) ? <MobileFooter /> : <Footer />}
                    </Row>
                </div>) : 'LOADING...'}
            </div>
            )}</>
        );
    }
}

ListingDetails.propTypes = {
    getListing: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    loading: state.listing.loading,
    thisListing: state.listing.thisListing,
    walkScoreData: state.listing.thisListing.walkScoreData ? state.listing.thisListing.walkScoreData : '',
    yelpData: state.listing.thisListing.yelpData ? state.listing.thisListing.yelpData : '',
    nearbyPlaces: state.listing.thisListing.nearbyPlaces ? state.listing.thisListing.nearbyPlaces : '',
    filterables: state.listing.thisListing.filterables ? state.listing.thisListing.filterables : '',
    scores: state.listing.thisListing.scores ? state.listing.thisListing.scores : '',
    attomData: state.listing.thisListing.attomData ? state.listing.thisListing.attomData : '',
    user: state.auth.user ? state.auth.user : '',
    isAuthenticated: state.auth.isAuthenticated ? state.auth.isAuthenticated : ''
});

export default connect(mapStateToProps, { getListing, setListingsLoading, loadUser, updateUser })(ListingDetails);