import React, { Component } from 'react';
import { Container, Form, FormGroup, Input, Alert } from 'reactstrap';
import { Button } from '@material-ui/core';
import { CircularProgress, Box } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { forgotPassword } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';


class ForgotPassword extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            email: null,
            msg: null,
            successAlert: null,
            loading: false
        }
    }

    static propTypes = {
        emailSent: PropTypes.bool,
        error: PropTypes.object.isRequired,
        clearErrors: PropTypes.func.isRequired,
        forgotPassword: PropTypes.func.isRequired
    }

    async componentDidMount() {
        await this.props.getLocation(this.props.location.pathname);
    }

    componentDidUpdate(prevProps) {
        const { error, emailSent } = this.props;

        if (error !== prevProps.error) {
            
            //Check for Login Error
            if (error.id === 'FORGOT_PASSWORD_FAIL') {
                this.setState({ msg: error.msg.msg })
            } else {
                this.setState({ msg: null });
            }
        }

        if (emailSent !== prevProps.emailSent) {
            this.setState({ successAlert: true });
            
            // Clear errors if email is successfully sent
            this.props.clearErrors();
        }
    }

    
    onChange = e => {
        this.setState({ [e.target.name]: e.target.value, successAlert: false });
    };


    onSubmit = e => {
        e.preventDefault();

        const { email } = this.state;

        // Attempt to Send Reset Link
        this.props.forgotPassword(email);

        this.setState({ loading: true });
        
    }

    render() { 

        return ( 
            <Container style={{ marginTop: 125, minWidth: 600, minHeight: 450 }}>
                <div style={{ width: 500, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                    <h2 style={{ width: '100%', textAlign: 'center' }}>Send Link to Reset Password</h2>
                    { this.state.msg ? (
                        <Alert style={{ marginTop: '4rem' }} color='danger'>{this.state.msg}</Alert>
                    ) : (this.state.loading && (this.state.successAlert === false) && (this.state.msg === null)) ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
                            <CircularProgress />
                        </Box>
                    ) : this.state.successAlert ? (
                        <Alert style={{ marginTop: '4rem' }} color='success'>Email Sent!</Alert>
                    ) : null}
                    {!this.state.successAlert ?
                    (<Form onSubmit={this.onSubmit} style={!this.state.successAlert || !this.state.msg ? { marginTop: '4rem' } : { marginTop: '1rem' }}>
                        <FormGroup>
                            <Input 
                                type='email'
                                name='email'
                                id='email'
                                placeholder='Email'
                                className='mb-3'
                                onChange={this.onChange}
                            />
                        </FormGroup>
                        <Button onClick={this.onSubmit} style={{ width: '100%', marginLeft: 'auto', marginTop: '2rem', backgroundColor: 'rgb(35, 126, 85)', color: 'white', outline: 'none' }}>
                            Send Request
                        </Button>
                    </Form>) 
                    : (
                        <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 22, marginTop: '4rem' }}>Check your email and follow the link in the email to reset your password!</div>
                    )}
                </div>
            </Container>
        );
    }
}


const mapStateToProps = (state) => ({
    emailSent: state.forgotPassword.emailSent,
    error: state.error,
});

export default connect(mapStateToProps, { forgotPassword, clearErrors })(ForgotPassword);