import React, { Component } from 'react';
import { Jumbotron, Container } from 'reactstrap';
import amdfStorefront from '../../Assets/amdfStorefront.png';
import '../welcome/description.css';

class ScoringMethodology extends Component {
    
    render() { 
        return ( 
            <Container style={{ backgroundColor: 'black', height: 850, paddingTop: 25, position: 'relative' }} fluid>
                <Jumbotron id="methodBackground" style={{ backgroundImage: `url(${amdfStorefront})` }}></Jumbotron>
                <div style={{ height: 550, width: 1000, textAlign: 'center', backgroundColor: 'rgba(0, 0, 0, .8)', color: 'white', position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 44, fontWeight: 'bold' }}>Methodology.</div>
                    <div style={{ paddingTop: 15, fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 18, marginTop: 20 }}>
                        <span>Fourcast puts an emphasis on critical characteristics</span>
                        <span style={{ marginLeft: 5, fontStyle: 'italic' }}>outside</span>
                        <span style={{ marginLeft: 5 }}>each space:</span>
                    </div>
                    <div style={{ paddingTop: 30, fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20 }}>
                        Traffic. Demographics. Competition. Property.
                    </div>
                    <div style={{ paddingTop: 30, fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 18 }}>
                        The core hypotheses that serve as the foundation of Fourcast are:
                    </div>
                    <div style={{ paddingTop: 30, fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16 }}>
                        <span>Heavy, sustained foot traffic is</span><span style={{ marginLeft: 5, fontStyle: 'italic' }}>Good.</span>
                    </div>
                    <div style={{ paddingTop: 5, fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16 }}>
                        <span>High income areas with high spending potential are</span><span style={{ marginLeft: 5, fontStyle: 'italic' }}>Good.</span>
                    </div>
                    <div style={{ paddingTop: 5, fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16 }}>
                        <span>Areas with a high volume of businesses, and high quality businesses are</span><span style={{ marginLeft: 5, fontStyle: 'italic' }}>Good.</span>
                    </div>
                    <div style={{ paddingTop: 5, fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16 }}>
                        <span>Spaces with high visibility are</span><span style={{ marginLeft: 5, fontStyle: 'italic' }}>Good.</span>
                    </div>
                    <div style={{ paddingTop: 30, fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 18 }}>
                        <span>Fourcast also provides 'filterable' data - such as</span>
                        <span style={{ marginLeft: 5, fontStyle: 'italic' }}>Busiest Times</span>
                        <span>, which provides the times of day / week that traffic in the area is at it's peak - which can't objectively be measured as good or bad because it depends on the preference of each individual business, but can be a deciding factor in ultimately choosing a location.</span>
                    </div>
                </div>
            </Container>
        );
    }
}
 
export default ScoringMethodology;