import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button } from '@material-ui/core';
import { Divider } from '@mui/material';
import { Row } from 'reactstrap';
import { connect } from 'react-redux';
import { loadUser } from '../../actions/authActions';
import { FaChartBar, FaChartPie } from 'react-icons/fa';
import { MdLocationPin } from 'react-icons/md';
import PropTypes from 'prop-types';
import CustomLoginRegisterModal from '../CustomSearch/customLoginRegisterModal';
import FourcastReportLogo from '../../Assets/fourcast_report_logo_green.png';
import FourcastCompLogo from '../../Assets/fourcast_comp_logo_green.png';
import '../welcome/description.css';
import NotMobileFriendly from '../welcome/notMobileFriendly';


class CreateReportWelcomePage extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            doubleChecked: false,
            mobile: false,
            screenWidth: null
        }
    }

    componentDidMount() {
        this.props.loadUser();

        window && window.addEventListener('resize', this.mobileMode.bind(this));
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth });
        } else {
            this.setState({ mobile: false });
        }
    }

    componentWillUnmount() {
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
    }

    async componentDidUpdate(prevProps, prevState) {
        const { user } = this.props;
        const { doubleChecked } = this.state;
        const getUserSubData = user && user.stripeSubData;

        if (!getUserSubData) {
            if (user && (doubleChecked === false)) {
                await this.checkUserAgain();
            }
        }

        
    }

    checkUserAgain = async () => {
        await this.props.loadUser();
        this.setState({ doubleChecked: true });
    }
    
    
    continue = e => {
        this.props.nextStep();
    }


    mobileMode() {
        let screenWidth = window.screen.width;
        
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth, mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }



    render() { 
        
        const { mobile, screenWidth } = this.state;
        const { isAuthenticated, user } = this.props;

        const getUserSubData = user && user.stripeSubData;
        const stripeSubStatus = getUserSubData && getUserSubData.status;
        const stripeSubType = getUserSubData && getUserSubData.subType;
        
        return (
            <>{(mobile || (screenWidth && (screenWidth < 1000))) ? <NotMobileFriendly /> : (<div style={{ width: '100%', height: 775, backgroundColor: 'whitesmoke', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card style={{ width: '70%', height: 600 }}>
                    <div style={{ height: 400, display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: 25 }}>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={FourcastCompLogo} style={{ width: 289, height: 80 }} alt="Fourcast Comp Logo" />
                            </div>
                            <div style={{ height: '80%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Row style={{ marginLeft: 'auto', marginRight: 'auto', height: 350 }}>
                                    <Card id='reportWelcomePageGraphic' elevation={5}>
                                        <div style={{ height: '100%', paddingTop: 15 }}>
                                            <div style={{ height: '15%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                                <div style={{ height: '100%' }}>
                                                    <MdLocationPin style={{ height: '100%', width: '100%', color: 'rgba(52, 181, 122, .6)' }} />
                                                </div>
                                                <div style={{ height: '100%' }}>
                                                    <MdLocationPin style={{ height: '100%', width: '100%', color: 'rgba(223, 82, 69, .6)' }} />
                                                </div>
                                                <div style={{ height: '100%' }}>
                                                    <MdLocationPin style={{ height: '100%', width: '100%', color: 'rgba(250, 204, 68, .6)' }} />
                                                </div>
                                            </div>
                                            <div style={{ height: '20%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 24, fontWeight: 800, color: 'rgba(52, 181, 122, .6)' }}>
                                                    A
                                                </div>
                                                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 24, fontWeight: 800, color: 'rgba(223, 82, 69, .6)' }}>
                                                    B
                                                </div>
                                                <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 24, fontWeight: 800, color: 'rgba(250, 204, 68, .6)' }}>
                                                    C
                                                </div>
                                            </div>
                                            <Divider variant="middle" />
                                            <div style={{ height: '55%', display: 'flex', justifyContent: 'space-around' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <FaChartBar style={{ width: 35, height: 35, color: 'rgba(30, 144, 255, .8)' }} />
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <FaChartPie style={{ width: 35, height: 35, color: 'rgba(128, 14, 103, .8)' }} />
                                                    </div>
                                                </div>
                                                <Divider orientation="vertical" variant="middle" flexItem />
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <FaChartBar style={{ width: 35, height: 35, color: 'rgba(30, 144, 255, .8)' }} />
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <FaChartPie style={{ width: 35, height: 35, color: 'rgba(128, 14, 103, .8)' }} />
                                                    </div>
                                                </div>
                                                <Divider orientation="vertical" variant="middle" flexItem />
                                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <FaChartBar style={{ width: 35, height: 35, color: 'rgba(30, 144, 255, .8)' }} />
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <FaChartPie style={{ width: 35, height: 35, color: 'rgba(128, 14, 103, .8)' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card>
                                </Row>
                            </div>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {isAuthenticated ? (((user.accountType !== 'basic') && ((stripeSubStatus && stripeSubStatus === 'active') || (stripeSubStatus && stripeSubStatus === 'trialing')) && (stripeSubType && stripeSubType !== 'basic')) || (user.accountType === 'admin')) ? 
                                (
                                    <Link style={{ textDecoration: 'none' }} to={`/compare-listings`}>
                                        <Button style={{ color: 'white', backgroundColor: 'rgba(52, 181, 122)' }} variant="contained" size="large">Continue</Button>
                                    </Link>
                                ) : (user && user.engagement.searches > 1) ? 
                                (
                                    <Link style={{ textDecoration: 'none' }} to={`/user/${user._id}`}>
                                        <Button style={{ color: 'white', backgroundColor: 'rgba(52, 181, 122)' }} variant="contained" size="large">Upgrade To Continue</Button>
                                    </Link>
                                ) : 
                                (
                                    <Link style={{ textDecoration: 'none' }} to={`/compare-listings`}>
                                        <Button style={{ color: 'white', backgroundColor: 'rgba(52, 181, 122)' }} variant="contained" size="large">Continue</Button>
                                    </Link>
                                ) : 
                                (<div><CustomLoginRegisterModal /></div>)}
                            </div>
                        </div>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={FourcastReportLogo} style={{ width: 289, height: 80 }} alt="Fourcast Report Logo" />
                            </div>
                            <div style={{ height: '80%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Row style={{ marginLeft: 'auto', marginRight: 'auto', height: 350 }}>
                                    <Card id='reportWelcomePageGraphic' elevation={5}>
                                        <div style={{ height: '100%', paddingTop: 15 }}>
                                            <div style={{ height: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div style={{ color: 'rgba(30, 144, 255, .6)', fontWeight: 700 }}>123 Main St.</div>
                                            </div>
                                            <div style={{ height: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div style={{ borderBottom: '12px solid darkgray', width: '45%' }}></div>
                                            </div>
                                            <div style={{ height: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div style={{ borderBottom: '10px solid darkgray', width: '56%' }}></div>
                                            </div>
                                            <div style={{ height: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div style={{ width: '80%', display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <FaChartPie style={{ width: 35, height: 35, color: 'rgba(228, 114, 103, .8)' }} />
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <FaChartBar style={{ width: 35, height: 35, color: 'rgba(250, 204, 68, .8)' }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ height: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div style={{ borderBottom: '10px solid darkgray', width: '44%' }}></div>
                                            </div>
                                            <div style={{ height: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div style={{ borderBottom: '10px solid darkgray', width: '52%' }}></div>
                                            </div>
                                            <div style={{ height: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div style={{ borderBottom: '10px solid darkgray', width: '47%' }}></div>
                                            </div>
                                        </div>
                                    </Card>
                                </Row>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                {isAuthenticated ? (((user.accountType !== 'basic') && ((stripeSubStatus && stripeSubStatus === 'active') || (stripeSubStatus && stripeSubStatus === 'trialing')) && (stripeSubType && stripeSubType !== 'basic')) || (user.accountType === 'admin')) ? 
                                (
                                    <Button onClick={() => this.continue()} style={{ backgroundColor: "#4082ef", color: 'white' }} variant="contained" size="large">Continue</Button>
                                ) : (user && user.engagement.searches > 1) ? 
                                (
                                    <Link style={{ textDecoration: 'none' }} to={`/user/${user._id}`}>
                                        <Button style={{ color: 'white', backgroundColor: '#4082ef' }} variant="contained" size="large">Upgrade To Continue</Button>
                                    </Link>
                                ) : 
                                (
                                    <Button onClick={() => this.continue()} style={{ backgroundColor: '#4082ef', color: 'white' }} variant="contained" size="large">Continue</Button>
                                ) : 
                                (<div><CustomLoginRegisterModal /></div>)}
                            </div>
                        </div>
                    </div>
                </Card>
            </div>)}</>
        );
    }
}

CreateReportWelcomePage.propTypes = {
    loadUser: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});
 

export default connect(mapStateToProps, { loadUser })(CreateReportWelcomePage);