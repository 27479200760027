import React, { Component } from 'react';
import { Table } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-datalabels';
import '../../listingDetailsLayouts/competition/competition.css';
import './matchComparison.css';

Chart.register(ChartDataLabels);



class BizDensityMatchComparison extends Component {
    
    
    render() {

        const { listings, inputValueTitle } = this.props;

        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.scores.fourcastScore - a.scores.fourcastScore});
        
        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }

        let getThisListingsBizDensity = (thisListing) => {
            
            
            // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!TOTAL BUSINESS GRAPH!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!        
        
            // gauges how the # of total biz's for this particular listing compares to every other listing
            const totalBusinessesGauge = thisListing && thisListing.scores && thisListing.scores.bizDensity && thisListing.scores.bizDensity;
            const yelpTotal = thisListing && thisListing.yelpBusinesses && thisListing.yelpBusinesses.totalBizCount && thisListing.yelpBusinesses.totalBizCount;
            let totalGaugeNeedle = 0;
            let totalChartData = {};
            
            
            if (totalBusinessesGauge < 55) {
                    totalGaugeNeedle = -80;
                    totalChartData = {
                        labels: ['Low Density', 'Average Density', 'High Density'],
                        datasets: [
                            {
                                label: 'Business Density in Area',
                                backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                                data: [30, 40, 30]
                            }
                        ],
                    }
            } else if (totalBusinessesGauge < 60) {
                    totalGaugeNeedle = -58;
                    totalChartData = {
                        labels: ['Low Density', 'Average Density', 'High Density'],
                        datasets: [
                            {
                                label: 'Business Density in Area',
                                backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                                data: [30, 40, 30]
                            }
                        ],
                    }
            } else if (totalBusinessesGauge < 65) {
                    totalGaugeNeedle = -38;
                    totalChartData = {
                        labels: ['Low Density', 'Average Density', 'High Density'],
                        datasets: [
                            {
                                label: 'Business Density in Area',
                                backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                                data: [30, 40, 30]
                            }
                        ],
                    }
            } else if (totalBusinessesGauge < 70) {
                    totalGaugeNeedle = -18;
                    totalChartData = {
                        labels: ['Low Density', 'Average Density', 'High Density'],
                        datasets: [
                            {
                                label: 'Business Density in Area',
                                backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                                data: [30, 40, 30]
                            }
                        ],
                    }
            } else if (totalBusinessesGauge === 75) {
                    totalGaugeNeedle = 2;
                    totalChartData = {
                        labels: ['Low Density', 'Average Density', 'High Density'],
                        datasets: [
                            {
                                label: 'Business Density in Area',
                                backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                                data: [30, 40, 30]
                            }
                        ],
                    }
            } else if (totalBusinessesGauge < 80) {
                    totalGaugeNeedle = 18;
                    totalChartData = {
                        labels: ['Low Density', 'Average Density', 'High Density'],
                        datasets: [
                            {
                                label: 'Business Density in Area',
                                backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                                data: [30, 40, 30]
                            }
                        ],
                    }
            } else if (totalBusinessesGauge <= 85) {
                    totalGaugeNeedle = 38;
                    totalChartData = {
                        labels: ['Low Density', 'High Density'],
                        datasets: [
                            {
                                label: 'Business Density in Area',
                                backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                                data: [30, 40, 30]
                            }
                        ],
                    }
            } else if (totalBusinessesGauge <= 90) {
                    totalGaugeNeedle = 58;
                    totalChartData = {
                        labels: ['Low Density', 'Average Density', 'High Density'],
                        datasets: [
                            {
                                label: 'Business Density in Area',
                                backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                                data: [30, 40, 30]
                            }
                        ],
                    }
            } else if (totalBusinessesGauge <= 95) {
                    totalGaugeNeedle = 78;
                    totalChartData = {
                        labels: ['Low Density', 'Average Density', 'High Density'],
                        datasets: [
                            {
                                label: 'Business Density in Area',
                                backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                                data: [30, 40, 30]
                            }
                        ],
                    }
            } else {
                    totalGaugeNeedle = 85;
                    totalChartData = {
                        labels: ['Low Density', 'Average Density', 'High Density'],
                        datasets: [
                            {
                                label: 'Business Density in Area',
                                backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                                data: [30, 40, 30]
                            }
                        ],
                    }
            }
            
            
            const bizDensityObj = {address: thisListing.listingAddress, totalGaugeNeedle: totalGaugeNeedle, totalChartData: totalChartData, yelpTotal: yelpTotal};
            return bizDensityObj;
        }


        // SORT BY LISTING
        const getFilteredListingsBizDensity = sortedByFourcastScore && sortedByFourcastScore.map(listing => getThisListingsBizDensity(listing));
        const filteredListingsBizDensity = inputValueTitle ? (inputValueTitle && (inputValueTitle === 'Low')) ? getFilteredListingsBizDensity && getFilteredListingsBizDensity.length && getFilteredListingsBizDensity.sort(function(a, b){return a.totalGaugeNeedle - b.totalGaugeNeedle}) : getFilteredListingsBizDensity && getFilteredListingsBizDensity.length && getFilteredListingsBizDensity.sort(function(a, b){return b.totalGaugeNeedle - a.totalGaugeNeedle}) : getFilteredListingsBizDensity.sort(function(a, b){return b.totalGaugeNeedle - a.totalGaugeNeedle});
        
        let listing1 = (filteredListingsBizDensity && filteredListingsBizDensity.length && (filteredListingsBizDensity[0] !== undefined)) ? filteredListingsBizDensity[0] : null;
        let listing2 = (filteredListingsBizDensity && filteredListingsBizDensity.length && (filteredListingsBizDensity[1] !== undefined)) ? filteredListingsBizDensity[1] : null;
        let listing3 = (filteredListingsBizDensity && filteredListingsBizDensity.length && (filteredListingsBizDensity[2] !== undefined)) ? filteredListingsBizDensity[2] : null;
        let listing4 = (filteredListingsBizDensity && filteredListingsBizDensity.length && (filteredListingsBizDensity[3] !== undefined)) ? filteredListingsBizDensity[3] : null;
        let listing5 = (filteredListingsBizDensity && filteredListingsBizDensity.length && (filteredListingsBizDensity[4] !== undefined)) ? filteredListingsBizDensity[4] : null;
        let listing6 = (filteredListingsBizDensity && filteredListingsBizDensity.length && (filteredListingsBizDensity[5] !== undefined)) ? filteredListingsBizDensity[5] : null;
        let listing7 = (filteredListingsBizDensity && filteredListingsBizDensity.length && (filteredListingsBizDensity[6] !== undefined)) ? filteredListingsBizDensity[6] : null;
        let listing8 = (filteredListingsBizDensity && filteredListingsBizDensity.length && (filteredListingsBizDensity[7] !== undefined)) ? filteredListingsBizDensity[7] : null;
        
        const setBizDensityNeedle = async (gauge, el) => {
            setTimeout(async () => {
                const totalGaugeNeedle = await document.querySelector(el);
                let thisRotation = gauge;                
                totalGaugeNeedle && await totalGaugeNeedle.style.setProperty('--rotation', thisRotation);
            }, 100, { once: true });
        }


        (listing1 !== null) && listing1.totalGaugeNeedle && setBizDensityNeedle(listing1.totalGaugeNeedle, '.matchComparisonBizDensityComparisonNeedle1');
        (listing2 !== null) && listing2.totalGaugeNeedle && setBizDensityNeedle(listing2.totalGaugeNeedle, '.matchComparisonBizDensityComparisonNeedle2');
        (listing3 !== null) && listing3.totalGaugeNeedle && setBizDensityNeedle(listing3.totalGaugeNeedle, '.matchComparisonBizDensityComparisonNeedle3');
        (listing4 !== null) && listing4.totalGaugeNeedle && setBizDensityNeedle(listing4.totalGaugeNeedle, '.matchComparisonBizDensityComparisonNeedle4');
        (listing5 !== null) && listing5.totalGaugeNeedle && setBizDensityNeedle(listing5.totalGaugeNeedle, '.matchComparisonBizDensityComparisonNeedle5');
        (listing6 !== null) && listing6.totalGaugeNeedle && setBizDensityNeedle(listing6.totalGaugeNeedle, '.matchComparisonBizDensityComparisonNeedle6');
        (listing7 !== null) && listing7.totalGaugeNeedle && setBizDensityNeedle(listing7.totalGaugeNeedle, '.matchComparisonBizDensityComparisonNeedle7');
        (listing8 !== null) && listing8.totalGaugeNeedle && setBizDensityNeedle(listing8.totalGaugeNeedle, '.matchComparisonBizDensityComparisonNeedle8');
        

        return (
            <div style={{ position: 'relative', width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'left', paddingBottom: 20 }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Business Density</div>
                </div>
                <div style={{ width: '90%', display: 'flex', justifyContent: 'center', marginTop: 50, marginLeft: 'auto', marginRight: 'auto', paddingBottom: 10 }}>
                    <div style={{ fontWeight: 500, fontSize: 20 }}>Number of Surrounding Businesses</div>
                </div>
                <div style={{ width: '90%', display: 'flex', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto', paddingBottom: 10 }}>
                    <div style={{ fontWeight: 500, fontSize: 14 }}>(within 5 minute walk radius)</div>
                </div>
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', marginTop: 40, paddingBottom: 40, justifyContent: 'space-around' }}>
                    {listing1 !== null ? (<div style={{ position: 'relative', width: 250, height: 250 }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>1</div>
                        <div style={{ fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing1.address)}</div>
                        <div className="matchComparisonTotalBizAreaComparison">
                            <Doughnut
                                data={listing1.totalChartData}
                                options={{
                                    title: {
                                        display: true,
                                        text: 'Business Density in Area',
                                        fontSize: 22,
                                        color: 'black'
                                    },
                                    circumference: 59 * Math.PI,
                                    rotation: 85 * Math.PI,
                                    plugins: {
                                        datalabels: {
                                        display: false,
                                        },
                                        legend: {
                                            display: false
                                        }
                                    },
                                    cutout: 50,
                                    layout: {
                                        padding: {
                                            bottom: 55
                                        }
                                    },
                                    scales: {
                                        x: [{
                                            grid: {
                                                display: false
                                            }
                                        }],
                                    },
                                }}
                            />
                            <div className="matchComparisonLowDensityLabelComparison">Low Density</div>
                            <div className="matchComparisonHighDensityLabelComparison">High Density</div>
                            <div className='matchComparisonBizDensityComparisonNeedle1'></div>
                            <h4 className='matchComparisonTotalBusinessNumberComparison'>{listing1.yelpTotal}</h4>
                        </div>
                    </div>) : null}
                    {listing2 !== null ? (<div style={{ position: 'relative', width: 250, height: 250, }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>2</div>
                        <div style={{ fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing2.address)}</div>
                        <div className="matchComparisonTotalBizAreaComparison">
                            <Doughnut
                                data={listing2.totalChartData}
                                options={{
                                    title: {
                                        display: true,
                                        text: 'Business Density in Area',
                                        fontSize: 22,
                                        color: 'black'
                                    },
                                    circumference: 59 * Math.PI,
                                    rotation: 85 * Math.PI,
                                    plugins: {
                                        datalabels: {
                                        display: false,
                                        },
                                        legend: {
                                            display: false
                                        }
                                    },
                                    cutout: 50,
                                    layout: {
                                        padding: {
                                            bottom: 55
                                        }
                                    },
                                    scales: {
                                        x: [{
                                            grid: {
                                                display: false
                                            }
                                        }],
                                    },
                                }}
                            />
                            <div className="matchComparisonLowDensityLabelComparison">Low Density</div>
                            <div className="matchComparisonHighDensityLabelComparison">High Density</div>
                            <div className='matchComparisonBizDensityComparisonNeedle2'></div>
                            <h4 className='matchComparisonTotalBusinessNumberComparison'>{listing2.yelpTotal}</h4>
                        </div>
                    </div>) : null}
                    {listing3 !== null ? (<div style={{ position: 'relative', width: 250, height: 250, }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>3</div>
                        <div style={{ fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing3.address)}</div>
                        <div className="matchComparisonTotalBizAreaComparison">
                            <Doughnut
                                data={listing3.totalChartData}
                                options={{
                                    title: {
                                        display: true,
                                        text: 'Business Density in Area',
                                        fontSize: 22,
                                        color: 'black'
                                    },
                                    circumference: 59 * Math.PI,
                                    rotation: 85 * Math.PI,
                                    plugins: {
                                        datalabels: {
                                        display: false,
                                        },
                                        legend: {
                                            display: false
                                        }
                                    },
                                    cutout: 50,
                                    layout: {
                                        padding: {
                                            bottom: 55
                                        }
                                    },
                                    scales: {
                                        x: [{
                                            grid: {
                                                display: false
                                            }
                                        }],
                                    },
                                }}
                            />
                            <div className="matchComparisonLowDensityLabelComparison">Low Density</div>
                            <div className="matchComparisonHighDensityLabelComparison">High Density</div>
                            <div className='matchComparisonBizDensityComparisonNeedle3'></div>
                            <h4 className='matchComparisonTotalBusinessNumberComparison'>{listing3.yelpTotal}</h4>
                        </div>
                    </div>) : null}
                    {listing4 !== null ? (<div style={{ position: 'relative', width: 250, height: 250, }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>4</div>
                        <div style={{ fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing4.address)}</div>
                        <div className="matchComparisonTotalBizAreaComparison">
                            <Doughnut
                                data={listing4.totalChartData}
                                options={{
                                    title: {
                                        display: true,
                                        text: 'Business Density in Area',
                                        fontSize: 22,
                                        color: 'black'
                                    },
                                    circumference: 59 * Math.PI,
                                    rotation: 85 * Math.PI,
                                    plugins: {
                                        datalabels: {
                                        display: false,
                                        },
                                        legend: {
                                            display: false
                                        }
                                    },
                                    cutout: 50,
                                    layout: {
                                        padding: {
                                            bottom: 55
                                        }
                                    },
                                    scales: {
                                        x: [{
                                            grid: {
                                                display: false
                                            }
                                        }],
                                    },
                                }}
                            />
                            <div className="matchComparisonLowDensityLabelComparison">Low Density</div>
                            <div className="matchComparisonHighDensityLabelComparison">High Density</div>
                            <div className='matchComparisonBizDensityComparisonNeedle4'></div>
                            <h4 className='matchComparisonTotalBusinessNumberComparison'>{listing4.yelpTotal}</h4>
                        </div>
                    </div>) : null}
                    {listing5 !== null ? (<div style={{ position: 'relative', width: 250, height: 250 }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>5</div>
                        <div style={{ fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing5.address)}</div>
                        <div className="matchComparisonTotalBizAreaComparison">
                            <Doughnut
                                data={listing5.totalChartData}
                                options={{
                                    title: {
                                        display: true,
                                        text: 'Business Density in Area',
                                        fontSize: 22,
                                        color: 'black'
                                    },
                                    circumference: 59 * Math.PI,
                                    rotation: 85 * Math.PI,
                                    plugins: {
                                        datalabels: {
                                        display: false,
                                        },
                                        legend: {
                                            display: false
                                        }
                                    },
                                    cutout: 50,
                                    layout: {
                                        padding: {
                                            bottom: 55
                                        }
                                    },
                                    scales: {
                                        x: [{
                                            grid: {
                                                display: false
                                            }
                                        }],
                                    },
                                }}
                            />
                            <div className="matchComparisonLowDensityLabelComparison">Low Density</div>
                            <div className="matchComparisonHighDensityLabelComparison">High Density</div>
                            <div className='matchComparisonBizDensityComparisonNeedle5'></div>
                            <h4 className='matchComparisonTotalBusinessNumberComparison'>{listing5.yelpTotal}</h4>
                        </div>
                    </div>) : null}
                    {listing6 !== null ? (<div style={{ position: 'relative', width: 250, height: 250, }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>6</div>
                        <div style={{ fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing6.address)}</div>
                        <div className="matchComparisonTotalBizAreaComparison">
                            <Doughnut
                                data={listing6.totalChartData}
                                options={{
                                    title: {
                                        display: true,
                                        text: 'Business Density in Area',
                                        fontSize: 22,
                                        color: 'black'
                                    },
                                    circumference: 59 * Math.PI,
                                    rotation: 85 * Math.PI,
                                    plugins: {
                                        datalabels: {
                                        display: false,
                                        },
                                        legend: {
                                            display: false
                                        }
                                    },
                                    cutout: 50,
                                    layout: {
                                        padding: {
                                            bottom: 55
                                        }
                                    },
                                    scales: {
                                        x: [{
                                            grid: {
                                                display: false
                                            }
                                        }],
                                    },
                                }}
                            />
                            <div className="matchComparisonLowDensityLabelComparison">Low Density</div>
                            <div className="matchComparisonHighDensityLabelComparison">High Density</div>
                            <div className='matchComparisonBizDensityComparisonNeedle6'></div>
                            <h4 className='matchComparisonTotalBusinessNumberComparison'>{listing6.yelpTotal}</h4>
                        </div>
                    </div>) : null}
                    {listing7 !== null ? (<div style={{ position: 'relative', width: 250, height: 250, }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>7</div>
                        <div style={{ fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing7.address)}</div>
                        <div className="matchComparisonTotalBizAreaComparison">
                            <Doughnut
                                data={listing7.totalChartData}
                                options={{
                                    title: {
                                        display: true,
                                        text: 'Business Density in Area',
                                        fontSize: 22,
                                        color: 'black'
                                    },
                                    circumference: 59 * Math.PI,
                                    rotation: 85 * Math.PI,
                                    plugins: {
                                        datalabels: {
                                        display: false,
                                        },
                                        legend: {
                                            display: false
                                        }
                                    },
                                    cutout: 50,
                                    layout: {
                                        padding: {
                                            bottom: 55
                                        }
                                    },
                                    scales: {
                                        x: [{
                                            grid: {
                                                display: false
                                            }
                                        }],
                                    },
                                }}
                            />
                            <div className="matchComparisonLowDensityLabelComparison">Low Density</div>
                            <div className="matchComparisonHighDensityLabelComparison">High Density</div>
                            <div className='matchComparisonBizDensityComparisonNeedle7'></div>
                            <h4 className='matchComparisonTotalBusinessNumberComparison'>{listing7.yelpTotal}</h4>
                        </div>
                    </div>) : null}
                    {listing8 !== null ? (<div style={{ position: 'relative', width: 250, height: 250, }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>8</div>
                        <div style={{ fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing8.address)}</div>
                        <div className="matchComparisonTotalBizAreaComparison">
                            <Doughnut
                                data={listing8.totalChartData}
                                options={{
                                    title: {
                                        display: true,
                                        text: 'Business Density in Area',
                                        fontSize: 22,
                                        color: 'black'
                                    },
                                    circumference: 59 * Math.PI,
                                    rotation: 85 * Math.PI,
                                    plugins: {
                                        datalabels: {
                                        display: false,
                                        },
                                        legend: {
                                            display: false
                                        }
                                    },
                                    cutout: 50,
                                    layout: {
                                        padding: {
                                            bottom: 55
                                        }
                                    },
                                    scales: {
                                        x: [{
                                            grid: {
                                                display: false
                                            }
                                        }],
                                    },
                                }}
                            />
                            <div className="matchComparisonLowDensityLabelComparison">Low Density</div>
                            <div className="matchComparisonHighDensityLabelComparison">High Density</div>
                            <div className='matchComparisonBizDensityComparisonNeedle8'></div>
                            <h4 className='matchComparisonTotalBusinessNumberComparison'>{listing8.yelpTotal}</h4>
                        </div>
                    </div>) : null}
                </div>
            </div>
        );
    }
}

export default BizDensityMatchComparison;