import React, { Component } from 'react';
import { Table } from '@mui/material';
import PriceGauge from '../../../Assets/priceGauge.png';
import '../../listingDetailsLayouts/demographics/demographics.css';
import './matchComparison.css';

class HighEndMatchComparison extends Component {
    
    
    render() {

        const { listings, inputValueTitle } = this.props;
        
        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b && b.filterables && b.filterables.bizPricesIndex && b.filterables.bizPricesIndex.percentageScore && b.filterables.bizPricesIndex.percentageScore - a && a.filterables && a.filterables.bizPricesIndex && a.filterables.bizPricesIndex.percentageScore && a.filterables.bizPricesIndex.percentageScore});

        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }

        let getThisListingsBizPricesGauge = (thisListing) => {
            
            const bizPricesIndex = thisListing && thisListing.filterables && thisListing.filterables.bizPricesIndex && thisListing.filterables.bizPricesIndex;
            
            const percentageScore = bizPricesIndex && bizPricesIndex.percentageScore;
            const thisListingsPriceVolume = bizPricesIndex && bizPricesIndex.priceLevels;

            let priceGaugeNeedle = 0;
            
            if (percentageScore >= 95) {
                priceGaugeNeedle = 80;
            } else if (percentageScore >= 90) {
                priceGaugeNeedle = 70;
            } else if (percentageScore >= 85) {
                priceGaugeNeedle = 60;
            } else if (percentageScore >= 80) {
                priceGaugeNeedle = 40;
            } else if (percentageScore >= 75) {
                priceGaugeNeedle = 25;
            } else if (percentageScore >= 70) {
                priceGaugeNeedle = 15;
            } else if (percentageScore >= 65) {
                priceGaugeNeedle = 5;
            } else if (percentageScore >= 60) {
                priceGaugeNeedle = -5;
            } else if (percentageScore >= 55) {
                priceGaugeNeedle = -15;
            } else if (percentageScore >= 50) {
                priceGaugeNeedle = -25;
            } else if (percentageScore >= 45) {
                priceGaugeNeedle = -40;
            } else if (percentageScore >= 40) {
                priceGaugeNeedle = -60;
            } else if (percentageScore >= 35) {
                priceGaugeNeedle = -70;
            } else if (percentageScore >= 0) {
                priceGaugeNeedle = -80;
            } else {
                priceGaugeNeedle = 0;
            }

            
            const bizPricesObj = {address: thisListing.listingAddress, percentageScore: priceGaugeNeedle, thisListingsPriceVolume: thisListingsPriceVolume};
            return bizPricesObj;
        }


        // SORT BY RES PRICES
        const getFilteredListingsBizPriceGauges = sortedByFourcastScore && sortedByFourcastScore.map(listing => getThisListingsBizPricesGauge(listing));
        const filteredListingsBizPriceGauges = inputValueTitle ? (inputValueTitle && (inputValueTitle === '$')) ? getFilteredListingsBizPriceGauges && getFilteredListingsBizPriceGauges.length && getFilteredListingsBizPriceGauges.sort(function(a, b){return a.percentageScore - b.percentageScore}) : getFilteredListingsBizPriceGauges && getFilteredListingsBizPriceGauges.length && getFilteredListingsBizPriceGauges.sort(function(a, b){return b.percentageScore - a.percentageScore}) : getFilteredListingsBizPriceGauges.sort(function(a, b){return b.percentageScore - a.percentageScore});
        
        let listing1 = (filteredListingsBizPriceGauges && filteredListingsBizPriceGauges.length && (filteredListingsBizPriceGauges[0] !== undefined)) ? filteredListingsBizPriceGauges[0] : null;
        let listing2 = (filteredListingsBizPriceGauges && filteredListingsBizPriceGauges.length && (filteredListingsBizPriceGauges[1] !== undefined)) ? filteredListingsBizPriceGauges[1] : null;
        let listing3 = (filteredListingsBizPriceGauges && filteredListingsBizPriceGauges.length && (filteredListingsBizPriceGauges[2] !== undefined)) ? filteredListingsBizPriceGauges[2] : null;
        let listing4 = (filteredListingsBizPriceGauges && filteredListingsBizPriceGauges.length && (filteredListingsBizPriceGauges[3] !== undefined)) ? filteredListingsBizPriceGauges[3] : null;
        let listing5 = (filteredListingsBizPriceGauges && filteredListingsBizPriceGauges.length && (filteredListingsBizPriceGauges[4] !== undefined)) ? filteredListingsBizPriceGauges[4] : null;
        let listing6 = (filteredListingsBizPriceGauges && filteredListingsBizPriceGauges.length && (filteredListingsBizPriceGauges[5] !== undefined)) ? filteredListingsBizPriceGauges[5] : null;
        let listing7 = (filteredListingsBizPriceGauges && filteredListingsBizPriceGauges.length && (filteredListingsBizPriceGauges[6] !== undefined)) ? filteredListingsBizPriceGauges[6] : null;
        let listing8 = (filteredListingsBizPriceGauges && filteredListingsBizPriceGauges.length && (filteredListingsBizPriceGauges[7] !== undefined)) ? filteredListingsBizPriceGauges[7] : null;

        const setBizPricesNeedle = async (gauge, el) => {
            setTimeout(async () => {
                const bizPricesGauge = await document.querySelector(el);
                let bizPricesRotation = gauge;                
                bizPricesGauge && await bizPricesGauge.style.setProperty('--rotation', bizPricesRotation);
            }, 100, { once: true });
        }

        (listing1 !== null) && listing1.percentageScore && setBizPricesNeedle(listing1.percentageScore, '.matchComparisonBizPricesComparisonNeedle1');
        (listing2 !== null) && listing2.percentageScore && setBizPricesNeedle(listing2.percentageScore, '.matchComparisonBizPricesComparisonNeedle2');
        (listing3 !== null) && listing3.percentageScore && setBizPricesNeedle(listing3.percentageScore, '.matchComparisonBizPricesComparisonNeedle3');
        (listing4 !== null) && listing4.percentageScore && setBizPricesNeedle(listing4.percentageScore, '.matchComparisonBizPricesComparisonNeedle4');
        (listing5 !== null) && listing5.percentageScore && setBizPricesNeedle(listing5.percentageScore, '.matchComparisonBizPricesComparisonNeedle5');
        (listing6 !== null) && listing6.percentageScore && setBizPricesNeedle(listing6.percentageScore, '.matchComparisonBizPricesComparisonNeedle6');
        (listing7 !== null) && listing7.percentageScore && setBizPricesNeedle(listing7.percentageScore, '.matchComparisonBizPricesComparisonNeedle7');
        (listing8 !== null) && listing8.percentageScore && setBizPricesNeedle(listing8.percentageScore, '.matchComparisonBizPricesComparisonNeedle8');
        

        let display$ = (listing) => {
            return (
                <>
                    {(listing && listing.thisListingsPriceVolume && listing.thisListingsPriceVolume.priceLevel1) ? ( 
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                                <div style={{ fontWeight: 800, marginLeft: 5 }}>{listing && listing.thisListingsPriceVolume && listing.thisListingsPriceVolume.priceLevel1}</div>
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }
        let display$$ = (listing) => {
            return (
                <>
                    {(listing && listing.thisListingsPriceVolume && listing.thisListingsPriceVolume.priceLevel2) ? ( 
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                                <div style={{ fontWeight: 800, marginLeft: 5 }}>{listing && listing.thisListingsPriceVolume && listing.thisListingsPriceVolume.priceLevel2}</div>
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }
        let display$$$ = (listing) => {
            return (
                <>
                    {(listing && listing.thisListingsPriceVolume && listing.thisListingsPriceVolume.priceLevel3) ? ( 
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                                <div style={{ fontWeight: 800, marginLeft: 5 }}>{listing && listing.thisListingsPriceVolume && listing.thisListingsPriceVolume.priceLevel3}</div>
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }
        let display$$$$ = (listing) => {
            return (
                <>
                    {(listing && listing.thisListingsPriceVolume && listing.thisListingsPriceVolume.priceLevel4) ? ( 
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                                <div style={{ fontWeight: 800, marginLeft: 5 }}>{listing && listing.thisListingsPriceVolume && listing.thisListingsPriceVolume.priceLevel4}</div>
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }

        let filteredListings$ = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsBiz$ = getThisListingsBizPricesGauge(listing);
            return (
                <td style={{ fontSize: 14, padding: 10 }}>
                    {thisListingsBiz$ && display$(thisListingsBiz$)}
                </td>
            )
        });
        let filteredListings$$ = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsBiz$$ = getThisListingsBizPricesGauge(listing);
            return (
                <td style={{ fontSize: 14, padding: 10 }}>
                    {thisListingsBiz$$ && display$$(thisListingsBiz$$)}
                </td>
            )
        });
        let filteredListings$$$ = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsBiz$$$ = getThisListingsBizPricesGauge(listing);
            return (
                <td style={{ fontSize: 14, padding: 10 }}>
                    {thisListingsBiz$$$ && display$$$(thisListingsBiz$$$)}
                </td>
            )
        });
        let filteredListings$$$$ = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsBiz$$$$ = getThisListingsBizPricesGauge(listing);
            return (
                <td style={{ fontSize: 14, padding: 10 }}>
                    {thisListingsBiz$$$$ && display$$$$(thisListingsBiz$$$$)}
                </td>
            )
        });

        return (
            <div style={{ position: 'relative', width: '98%' }}>
                <div style={{ display: 'flex', justifyContent: 'left', paddingBottom: 20 }}>
                    <div style={{ fontSize: 22, fontWeight: 500 }}>Product Prices:</div>
                    <div style={{ fontSize: 24, fontWeight: 700, marginLeft: 5, color: 'darkgreen' }}>{inputValueTitle}</div>
                </div>
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', justifyContent: 'space-around', marginTop: 25 }}>
                    <div style={{ width: '90%', display: 'flex', justifyContent: 'center', marginLeft: 'auto', marginRight: 'auto', paddingBottom: 20 }}>
                        <div style={{ fontWeight: 500, fontSize: 20 }}>High-End Business Area Gauge</div>
                    </div>
                    {listing1 !== null ? (<div style={{ position: 'relative' }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>1</div>
                        <div className='matchComparisonBizPricesGaugeComparison'>
                            <img src={PriceGauge} alt='' />
                            <div className='matchComparisonBizPricesComparisonNeedle1'></div>
                        </div>
                        <div style={{ padding: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700, overflowX: 'scroll' }}>{addressStyle(listing1.address)}</div>
                        <div style={{ display: 'flex', fontSize: 12, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', color: 'rgb(52, 181, 122)', fontWeight: 700 }}>Best</div>
                    </div>) : null}
                    {listing2 !== null ? (<div style={{ position: 'relative' }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>2</div>
                        <div className='matchComparisonBizPricesGaugeComparison'>
                            <img src={PriceGauge} alt='' />
                            <div className='matchComparisonBizPricesComparisonNeedle2'></div>
                        </div>
                        <div style={{ padding: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700, overflowX: 'scroll' }}>{addressStyle(listing2.address)}</div>
                    </div>) : null}
                    {listing3 !== null ? (<div style={{ position: 'relative' }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>3</div>
                        <div className='matchComparisonBizPricesGaugeComparison'>
                            <img src={PriceGauge} alt='' />
                            <div className='matchComparisonBizPricesComparisonNeedle3'></div>
                        </div>
                        <div style={{ padding: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700, overflowX: 'scroll' }}>{addressStyle(listing3.address)}</div>
                    </div>) : null}
                    {listing4 !== null ? (<div style={{ position: 'relative', marginTop: 20 }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>4</div>
                        <div className='matchComparisonBizPricesGaugeComparison'>
                            <img src={PriceGauge} alt='' />
                            <div className='matchComparisonBizPricesComparisonNeedle4'></div>
                        </div>
                        <div style={{ padding: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700, overflowX: 'scroll' }}>{addressStyle(listing4.address)}</div>
                    </div>) : null}
                    {listing5 !== null ? (<div style={{ position: 'relative', marginTop: 20 }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>5</div>
                        <div className='matchComparisonBizPricesGaugeComparison'>
                            <img src={PriceGauge} alt='' />
                            <div className='matchComparisonBizPricesComparisonNeedle5'></div>
                        </div>
                        <div style={{ padding: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700, overflowX: 'scroll' }}>{addressStyle(listing5.address)}</div>
                    </div>) : null}
                    {listing6 !== null ? (<div style={{ position: 'relative', marginTop: 20 }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>6</div>
                        <div className='matchComparisonBizPricesGaugeComparison'>
                            <img src={PriceGauge} alt='' />
                            <div className='matchComparisonBizPricesComparisonNeedle6'></div>
                        </div>
                        <div style={{ padding: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700, overflowX: 'scroll' }}>{addressStyle(listing6.address)}</div>
                    </div>) : null}
                    {listing7 !== null ? (<div style={{ position: 'relative', marginTop: 20 }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>7</div>
                        <div className='matchComparisonBizPricesGaugeComparison'>
                            <img src={PriceGauge} alt='' />
                            <div className='matchComparisonBizPricesComparisonNeedle7'></div>
                        </div>
                        <div style={{ padding: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700, overflowX: 'scroll' }}>{addressStyle(listing7.address)}</div>
                    </div>) : null}
                    {listing8 !== null ? (<div style={{ position: 'relative', marginTop: 20 }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>8</div>
                        <div className='matchComparisonBizPricesGaugeComparison'>
                            <img src={PriceGauge} alt='' />
                            <div className='matchComparisonBizPricesComparisonNeedle8'></div>
                        </div>
                        <div style={{ padding: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700, overflowX: 'scroll' }}>{addressStyle(listing8.address)}</div>
                    </div>) : null}
                </div>
                <div style={{ marginTop: 40 }}>
                    <div style={{ width: '90%', display: 'flex', justifyContent: 'center', paddingTop: 30, marginLeft: 'auto', marginRight: 'auto', paddingBottom: 10 }}>
                        <div style={{ fontWeight: 500, fontSize: 20 }}>Number of Businesses by Price Level</div>
                    </div>
                    <div>
                        <Table style={{ tableLayout: 'fixed' }}>
                            <thead>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '7%' }}></th>
                                    {sortedByFourcastScore && sortedByFourcastScore.map(listing => {return (<th style={{ padding: 20, color: 'darkgreen', fontSize: 12 }}>{addressStyle(listing.listingAddress)}</th>)})}
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '10%' }}>
                                       $
                                    </th>
                                    {filteredListings$}
                                </tr>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '10%' }}>
                                        $$
                                    </th>
                                    {filteredListings$$}
                                </tr>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '10%' }}>
                                       $$$
                                    </th>
                                    {filteredListings$$$}
                                </tr>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '10%' }}>
                                        $$$$
                                    </th>
                                    {filteredListings$$$$}
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}

export default HighEndMatchComparison;