import { GET_PRICES, STRIPE_LOADING, PRICES_ERROR } from '../actions/types';

const initialState = {
    prices: [],
    loading: false,
    error: null
}

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_PRICES:
            return {
                ...state,
                prices: action.payload,
                loading: false
            };
        case PRICES_ERROR:
            return {
                ...state,
                prices: null,
                loading: false,
                error: action.payload
            }
        case STRIPE_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state
    }
};