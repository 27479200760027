import React, { Component } from 'react';
import oneStarSmall from '/Users/johntormey/Desktop/fourcast/client/src/Assets/yelp_stars/web_and_ios/small/small_1.png';
import oneHalfStarSmall from '/Users/johntormey/Desktop/fourcast/client/src/Assets/yelp_stars/web_and_ios/small/small_1_half.png';
import twoStarSmall from '/Users/johntormey/Desktop/fourcast/client/src/Assets/yelp_stars/web_and_ios/small/small_2.png';
import twoHalfStarSmall from '/Users/johntormey/Desktop/fourcast/client/src/Assets/yelp_stars/web_and_ios/small/small_2_half.png';
import threeStarSmall from '/Users/johntormey/Desktop/fourcast/client/src/Assets/yelp_stars/web_and_ios/small/small_3.png';
import threeHalfStarSmall from '/Users/johntormey/Desktop/fourcast/client/src/Assets/yelp_stars/web_and_ios/small/small_3_half.png';
import fourStarSmall from '/Users/johntormey/Desktop/fourcast/client/src/Assets/yelp_stars/web_and_ios/small/small_4.png';
import fourHalfStarSmall from '/Users/johntormey/Desktop/fourcast/client/src/Assets/yelp_stars/web_and_ios/small/small_4_half.png';
import fiveStarSmall from '/Users/johntormey/Desktop/fourcast/client/src/Assets/yelp_stars/web_and_ios/small/small_5.png';

class YelpStarRating extends Component {
    render(){
        const { business: { rating: thisRating } = {}} = this.props;
        const stars = [];

        if (thisRating < 1.5) {
            stars.push(<img src={oneStarSmall} alt='Yelp Star Rating' />)
        } else if (thisRating < 2) {
            stars.push(<img src={oneHalfStarSmall} alt='Yelp Star Rating' />)
        } else if (thisRating < 2.5) {
            stars.push(<img src={twoStarSmall} alt='Yelp Star Rating' />)
        } else if (thisRating < 3) {
            stars.push(<img src={twoHalfStarSmall} alt='Yelp Star Rating' />)
        } else if (thisRating < 3.5) {
            stars.push(<img src={threeStarSmall} alt='Yelp Star Rating' />)
        } else if (thisRating < 4) {
            stars.push(<img src={threeHalfStarSmall} alt='Yelp Star Rating' />)
        } else if (thisRating < 4.5) {
            stars.push(<img src={fourStarSmall} alt='Yelp Star Rating' />)
        } else if (thisRating < 5) {
            stars.push(<img src={fourHalfStarSmall} alt='Yelp Star Rating' />)
        } else {
            stars.push(<img src={fiveStarSmall} alt='Yelp Star Rating' />)
        }

        return (
            <div>
                {stars}
            </div>
        )
    }
}

export default YelpStarRating;