import React, { Component } from 'react';
import { FaCloudMoon, FaCloudSun } from 'react-icons/fa';
import { Table } from '@mui/material';
import '../../listingDetailsLayouts/traffic/traffic.css';
import '../compareListings.css';

class BusyHoursComparison extends Component {
    
    
    render() {

        const { listings } = this.props;

        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.scores.fourcastScore - a.scores.fourcastScore});
        
        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }

        let getThisListingsBusyHours = (thisListing) => {
            
            const businesses = thisListing && thisListing.yelpData && thisListing.yelpData.businesses && thisListing.yelpData.businesses;

            const thisOpenFiveAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.openingTimes && thisListing.filterables.busyHours.openingTimes.openFiveAm;
            const thisOpenFiveThirtyAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.openingTimes && thisListing.filterables.busyHours.openingTimes.openFiveThirtyAm;
            const thisOpenSixAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.openingTimes && thisListing.filterables.busyHours.openingTimes.openSixAm;
            const thisOpenSixThirtyAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.openingTimes && thisListing.filterables.busyHours.openingTimes.openSixThirtyAm;
            const thisOpenSevenAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.openingTimes && thisListing.filterables.busyHours.openingTimes.openSevenAm;
            const thisOpenSevenThirtyAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.openingTimes && thisListing.filterables.busyHours.openingTimes.openSevenThirtyAm;
            const thisOpenEightAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.openingTimes && thisListing.filterables.busyHours.openingTimes.openEightAm;
            const thisOpenEightThirtyAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.openingTimes && thisListing.filterables.busyHours.openingTimes.openEightThirtyAm;
            const thisCloseMidnight = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.closingTimes && thisListing.filterables.busyHours.closingTimes.closeMidnight;
            const thisCloseMidnightThirty = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.closingTimes && thisListing.filterables.busyHours.closingTimes.closeMidnightThirty;
            const thisCloseOneAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.closingTimes && thisListing.filterables.busyHours.closingTimes.closeOneAm;
            const thisCloseOneThirtyAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.closingTimes && thisListing.filterables.busyHours.closingTimes.closeOneThirtyAm;
            const thisCloseTwoAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.closingTimes && thisListing.filterables.busyHours.closingTimes.closeTwoAm;
            const thisCloseTwoThirtyAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.closingTimes && thisListing.filterables.busyHours.closingTimes.closeTwoThirtyAm;
            const thisCloseThreeAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.closingTimes && thisListing.filterables.busyHours.closingTimes.closeThreeAm;
            const thisCloseThreeThirtyAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.closingTimes && thisListing.filterables.busyHours.closingTimes.closeThreeThirtyAm;
            const thisCloseFourAm = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.closingTimes && thisListing.filterables.busyHours.closingTimes.closeFourAm;

            let earlyMorningCount = (((thisOpenFiveAm + thisOpenFiveThirtyAm + thisOpenSixAm + thisOpenSixThirtyAm + thisOpenSevenAm + thisOpenSevenThirtyAm + thisOpenEightAm + thisOpenEightThirtyAm) * .25) + .01);
            
            const getDayTrafficGaugeScore = [];
            const getNightlifeGaugeScore = [];
            if (earlyMorningCount) {
                
                let dayTrafficGaugeScore = 0;

                // Calculate Daytime Traffic Gauge Score !!!!!!!!!!!!!!!!!!!!!

                // These figures were taken from the Traffic Volatility Function in "tasks"
                const globalHighestDayPopChange = 4818;
                const dayPopChangeMedian = 60;
                const globalLowestDayPopChange = 3;
                
                // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
                
                const secondaryNeighb = thisListing && thisListing.attomData && thisListing.attomData.geoIdV4 && thisListing.attomData.geoIdV4.filter(neighb => ((neighb.type === 'N2') || (neighb.type === 'N3')));
                
                const thisDayPop = parseFloat(secondaryNeighb && secondaryNeighb[0].commV4Data && secondaryNeighb[0].commV4Data.population_Daytime);
                const thisPopCy = parseFloat(secondaryNeighb && secondaryNeighb[0].commV4Data && secondaryNeighb[0].commV4Data.population);
                const dayPopChange = ((thisPopCy > 0) && (thisDayPop > 0)) ? (thisPopCy < thisDayPop) ? (((thisDayPop - thisPopCy)/thisPopCy) * 100) : (((thisPopCy - thisDayPop)/thisPopCy) * -100) : 0;

                if (dayPopChange > dayPopChangeMedian) {
                    dayTrafficGaugeScore = earlyMorningCount + Math.ceil((((dayPopChange - dayPopChangeMedian) / (globalHighestDayPopChange - dayPopChangeMedian)) * 50) + 50);
                } else if (dayPopChange < dayPopChangeMedian) {
                    dayTrafficGaugeScore = earlyMorningCount + Math.ceil((((dayPopChange - dayPopChangeMedian) / (globalLowestDayPopChange - dayPopChangeMedian)) * -50) + 50);
                } else {
                    dayTrafficGaugeScore = earlyMorningCount + 50;
                }

                if (dayTrafficGaugeScore > 95) {
                    dayTrafficGaugeScore = 95;
                } else if (dayTrafficGaugeScore < 2) {
                    dayTrafficGaugeScore = 2;
                }

                getDayTrafficGaugeScore.push(dayTrafficGaugeScore);
                
            }



            // Calculate Nightlife Gauge Score !!!!!!!!!!!!!!!!!!!!!!!!!!!
            
            const nightLifeCount = [thisCloseMidnight, thisCloseMidnightThirty, thisCloseOneAm, thisCloseOneThirtyAm, thisCloseTwoAm, thisCloseTwoThirtyAm, thisCloseThreeAm, thisCloseThreeThirtyAm, thisCloseFourAm];
            let nightlifeBusinesses = [];
            
            if (nightLifeCount) {
                
                let nightlifeGaugeScore = 0;

                const nightlifeCategories = ["bars", "adultentertainment", "barcrawl", "beergardens", "clubcrawl", "comedyclubs", "countrydancehalls", "danceclubs", "dancerestaurants", "fasil", "jazzandblues", "karaoke", "musicvenues", "pianobars", "poolhalls"];

                // pushes businesses with a nightlife "category" aka bars, clubs, lounge, etc.
                for (let biz of businesses) {
                    for (let category of biz.categories) {
                        if (nightlifeCategories.some(nightCategory => category.alias === nightCategory)) {
                            nightlifeBusinesses.push(biz);
                        };
                    }
                }

                nightlifeBusinesses = [...new Set([...nightlifeBusinesses])];
                
                // for businesses open til midnight or later, increase the points given the later they are open til
                let multiplier = .75;
                for (let i = 0; i < nightLifeCount.length; i++) {
                    nightlifeGaugeScore += nightLifeCount[i] * multiplier;
                    multiplier += .25;
                }
                
                // award .5 points for every 100 reviews of nightlife businesses
                for (let nightlifeBiz of nightlifeBusinesses) {
                    nightlifeGaugeScore += ((nightlifeBiz.review_count / 100) * .5);
                }

                if (nightlifeGaugeScore > 95) {
                    nightlifeGaugeScore = 95;
                } else if (nightlifeGaugeScore < 2) {
                    nightlifeGaugeScore = 2;
                }
                getNightlifeGaugeScore.push(nightlifeGaugeScore)
                
            }

            const [nightlifeGaugeScore] = getNightlifeGaugeScore;
            const [dayTrafficGaugeScore] = getDayTrafficGaugeScore;
            const busyHoursObj = {address: thisListing.listingAddress, daytime: dayTrafficGaugeScore, nightlife: nightlifeGaugeScore};
            
            return busyHoursObj;
        }


        // SORT BY DAYTIME TRAFFIC
        const sortedByDaytimeTraffic = sortedByFourcastScore && sortedByFourcastScore.map(listing => getThisListingsBusyHours(listing)).sort(function(a, b){return b.daytime - a.daytime});
        
        let listing1 = (sortedByDaytimeTraffic && sortedByDaytimeTraffic.length && (sortedByDaytimeTraffic[0] !== undefined)) ? sortedByDaytimeTraffic[0] : null;
        let listing2 = (sortedByDaytimeTraffic && sortedByDaytimeTraffic.length && (sortedByDaytimeTraffic[1] !== undefined)) ? sortedByDaytimeTraffic[1] : null;
        let listing3 = (sortedByDaytimeTraffic && sortedByDaytimeTraffic.length && (sortedByDaytimeTraffic[2] !== undefined)) ? sortedByDaytimeTraffic[2] : null;
        let listing4 = (sortedByDaytimeTraffic && sortedByDaytimeTraffic.length && (sortedByDaytimeTraffic[3] !== undefined)) ? sortedByDaytimeTraffic[3] : null;

        const setDayTrafficGauge = async (score, el) => {
            setTimeout(async () => {
                const dayTrafficGauge = await document.querySelector(el);
                let dayRotation = score;                
                dayTrafficGauge && await dayTrafficGauge.style.setProperty('--bottomDay', dayRotation);
            }, 100, { once: true });
        }

        (listing1 !== null) && listing1.daytime && setDayTrafficGauge(listing1.daytime, '.daytimeComparisonPointer1');
        (listing2 !== null) && listing2.daytime && setDayTrafficGauge(listing2.daytime, '.daytimeComparisonPointer2');
        (listing3 !== null) && listing3.daytime && setDayTrafficGauge(listing3.daytime, '.daytimeComparisonPointer3');
        (listing4 !== null) && listing4.daytime && setDayTrafficGauge(listing4.daytime, '.daytimeComparisonPointer4');


        const setNightlifeGauge = async (score, el) => {
            setTimeout(async () => {
                const nightlifeGauge = await document.querySelector(el);
                let nightRotation = score;                
                nightlifeGauge && await nightlifeGauge.style.setProperty('--bottomNight', nightRotation);
            }, 100, { once: true });
        }

        (listing1 !== null) && listing1.nightlife && setNightlifeGauge(listing1.nightlife, '.nightlifeComparisonPointer1');
        (listing2 !== null) && listing2.nightlife && setNightlifeGauge(listing2.nightlife, '.nightlifeComparisonPointer2');
        (listing3 !== null) && listing3.nightlife && setNightlifeGauge(listing3.nightlife, '.nightlifeComparisonPointer3');
        (listing4 !== null) && listing4.nightlife && setNightlifeGauge(listing4.nightlife, '.nightlifeComparisonPointer4');

        if (listing1) {listing1.color = 'rgba(52, 181, 122, .2)'};
        if (listing2) {listing2.color = 'rgba(223, 82, 69, .2)'};
        if (listing3) {listing3.color = 'rgba(250, 204, 68, .2)'};
        if (listing4) {listing4.color = 'rgba(30, 144, 255, .2)'};

        const nightlifeArr = [listing1, listing2, listing3, listing4];
        
        const sortedByNightlife = nightlifeArr && nightlifeArr.filter(listing => listing && listing.nightlife).sort(function(a, b){return b.nightlife - a.nightlife});
        

        return (
            <div style={{ position: 'relative', width: '98%' }}>
                <div style={{ display: 'flex', justifyContent: 'left', paddingBottom: 20 }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Busy Hours</div>
                </div>
                <div className='busyTimesCompareGaugeArea' style={{ position: 'relative', display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <div style={{ width: '90%', margin: 'auto auto' }}>
                            <Table style={{ tableLayout: 'fixed' }}>
                                <tbody>
                                    {sortedByDaytimeTraffic && sortedByDaytimeTraffic.map(listing => 
                                        <tr style={{ border: '1px solid lightgray', textAlign: 'center' }}>
                                            <td style={{ fontSize: 14, padding: 20, backgroundColor: listing.color }}>
                                                {listing && addressStyle(listing && listing.address)}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        <div style={{ width: '90%', display: 'flex', justifyContent: 'center', paddingTop: 40, marginLeft: 'auto', marginRight: 'auto' }}>
                            <div style={{ fontWeight: 500, fontSize: 20 }}>Daytime Traffic</div>
                            <div style={{ marginLeft: 10 }}><FaCloudSun style={{ height: 25, width: 25, color: 'orange' }} /></div>
                        </div>
                        <div className='traffGaugeArea' style={{ flex: 1, width: '100%', height: 100, position: 'relative' }}>
                            <div className='dayTrafficComparisonGauge'></div>
                            {((listing1 !== null) && (listing1.daytime)) ? <div className='daytimeComparisonPointer1'></div> : null}
                            {((listing2 !== null) && (listing2.daytime)) ? <div className='daytimeComparisonPointer2'></div> : null}
                            {((listing3 !== null) && (listing3.daytime)) ? <div className='daytimeComparisonPointer3'></div> : null}
                            {((listing4 !== null) && (listing4.daytime)) ? <div className='daytimeComparisonPointer4'></div> : null}
                            <div className='dayTrafficComparisonGaugeHigh'>High</div>
                            <div className='dayTrafficComparisonGaugeLow'>Low</div>
                        </div>
                    </div>
                    <div style={{ flex: 1 }}>
                        <div style={{ width: '90%', margin: 'auto auto' }}>
                            <Table style={{ tableLayout: 'fixed' }}>
                                <tbody>
                                    {sortedByNightlife && sortedByNightlife.map(listing => 
                                        <tr style={{ border: '1px solid lightgray', textAlign: 'center' }}>
                                            <td style={{ fontSize: 14, padding: 20, backgroundColor: listing.color }}>
                                                {listing && addressStyle(listing && listing.address)}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        <div style={{ width: '90%', display: 'flex', justifyContent: 'center', paddingTop: 40, marginLeft: 'auto', marginRight: 'auto' }}>
                            <div style={{ fontWeight: 500, fontSize: 20 }}>Nightlife</div>
                            <div style={{ marginLeft: 10 }}><FaCloudMoon style={{ height: 25, width: 25, color: 'midnightblue' }} /></div>
                        </div>
                        <div className='traffGaugeArea' style={{ flex: 1, width: '100%', height: 100, position: 'relative' }}>
                            <div className='nightlifeComparisonGauge'></div>
                            {((listing1 !== null) && (listing1.nightlife)) ? <div className='nightlifeComparisonPointer1'></div> : null}
                            {((listing2 !== null) && (listing2.nightlife)) ? <div className='nightlifeComparisonPointer2'></div> : null}
                            {((listing3 !== null) && (listing3.nightlife)) ? <div className='nightlifeComparisonPointer3'></div> : null}
                            {((listing4 !== null) && (listing4.nightlife)) ? <div className='nightlifeComparisonPointer4'></div> : null}
                            <div className='nightlifeComparisonGaugeHigh'>High</div>
                            <div className='nightlifeComparisonGaugeLow'>Low</div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        );
    }
}

export default BusyHoursComparison;