import React, { Component } from 'react';
import Select from 'react-select';
import { FormHelperText, FormControl, Card, CardActions, CardContent, Button } from '@material-ui/core';


class UserBizType extends Component {
    

    continue = e => {
        this.props.nextStep();
    }

    

    render() { 
        
        const { values, onChange, bizTypes, foodBevTypes, medicalHealthTypes, shoppingTypes, localServicesTypes, artsEntertainmentTypes, fitnessInstructionsTypes, educationTypes, beautySpaTypes } = this.props;
        
        const subCategoryOptions = (bizType) => {
            switch(bizType) {
                case 'foodBev':
                    return (
                        <FormControl style={{ textAlign: 'left', minWidth: 300, marginLeft: 50, marginTop: 100 }}>
                            <Select 
                                options={foodBevTypes}
                                onChange={onChange('userBizType2')}
                                defaultValue={values.userBizType2}
                            />
                            <FormHelperText>Food/Beverage Type</FormHelperText>
                        </FormControl> 
                    )
                case 'shopping':
                    return (
                        <FormControl style={{ textAlign: 'left', minWidth: 300, marginLeft: 50, marginTop: 100 }}>
                            <Select 
                                options={shoppingTypes}
                                onChange={onChange('userBizType2')}
                                defaultValue={values.userBizType2}
                            />
                            <FormHelperText>Shopping Type</FormHelperText>
                        </FormControl> 
                    )
                case 'beautySpas':
                    return (
                        <FormControl style={{ textAlign: 'left', minWidth: 300, marginLeft: 50, marginTop: 100 }}>
                            <Select 
                                options={beautySpaTypes}
                                onChange={onChange('userBizType2')}
                                defaultValue={values.userBizType2}
                            />
                            <FormHelperText>Beauty/Spa Type</FormHelperText>
                        </FormControl> 
                    )
                case 'fitnessInstructions':
                    return (
                        <FormControl style={{ textAlign: 'left', minWidth: 300, marginLeft: 50, marginTop: 100 }}>
                            <Select 
                                options={fitnessInstructionsTypes}
                                onChange={onChange('userBizType2')}
                                defaultValue={values.userBizType2}
                            />
                            <FormHelperText>Fitness/Instructions Type</FormHelperText>
                        </FormControl> 
                    )
                case 'artsEntertainment':
                    return (
                        <FormControl style={{ textAlign: 'left', minWidth: 300, marginLeft: 50, marginTop: 100 }}>
                            <Select 
                                options={artsEntertainmentTypes}
                                onChange={onChange('userBizType2')}
                                defaultValue={values.userBizType2}
                            />
                            <FormHelperText>Arts/Entertainment Type</FormHelperText>
                        </FormControl> 
                    )
                case 'healthMedical':
                    return (
                        <FormControl style={{ textAlign: 'left', minWidth: 300, marginLeft: 50, marginTop: 100 }}>
                            <Select 
                                options={medicalHealthTypes}
                                onChange={onChange('userBizType2')}
                                defaultValue={values.userBizType2}
                            />
                            <FormHelperText>Health/Medical Type</FormHelperText>
                        </FormControl> 
                    )
                case 'localServices':
                    return (
                        <FormControl style={{ textAlign: 'left', minWidth: 300, marginLeft: 50, marginTop: 100 }}>
                            <Select 
                                options={localServicesTypes}
                                onChange={onChange('userBizType2')}
                                defaultValue={values.userBizType2}
                            />
                            <FormHelperText>Local Services Type</FormHelperText>
                        </FormControl> 
                    )
                case 'education':
                    return (
                        <FormControl style={{ textAlign: 'left', minWidth: 300, marginLeft: 50, marginTop: 100 }}>
                            <Select 
                                options={educationTypes}
                                onChange={onChange('userBizType2')}
                                defaultValue={values.userBizType2}
                            />
                            <FormHelperText>Education Type</FormHelperText>
                        </FormControl> 
                    )
                default: 
                    return null;
            }
        };
        
        return (
            <div style={{ backgroundColor: 'whitesmoke', height: '100vh', paddingTop: 50 }}>
                <Card className='customCardBackgroundColor' style={{ width: '75vw', height: '75vh', margin: 'auto', textAlign: 'center', overflowY: 'scroll' }}>
                    <CardContent>
                        <h2 style={{ textAlign: 'center', marginTop: 30 }}>What Type Of Business Are You Opening?</h2>
                        <FormControl style={{ textAlign: 'left', minWidth: 300, marginTop: 100, marginLeft: 'auto' }}>
                            <Select 
                                options={bizTypes}
                                name="Business Type"
                                onChange={onChange('userBizType1')}
                                defaultValue={values.userBizType1}
                            />
                            <FormHelperText>Business Type</FormHelperText>
                        </FormControl>
                        {values.userBizType1 !== null ? subCategoryOptions(values.userBizType1) : null}
                        <CardActions>
                            {(values.userBizType1 !== null && values.userBizType2 !== null) ? <Button style={{ marginTop: 100, marginRight: 'auto', marginLeft: 'auto', backgroundColor: '#007FFF', color: 'white' }} variant="contained" size="medium" onClick={() => this.continue()}>Continue</Button> : <Button style={{ marginTop: 100, marginRight: 'auto', marginLeft: 'auto' }} variant="contained" color="primary" size="medium" disabled>Continue</Button>}
                        </CardActions>
                    </CardContent>
                </Card>
            </div>
        );
    }
}
 

export default UserBizType;