import React, { Component } from 'react';
import { Paper } from '@mui/material';
import '../listingDetailsLayouts/traffic/traffic.css';
import '../listingDetailsLayouts/summary/summary.css';
import fourcastLogo from '../../Assets/fourcast_alt_logo_green.png';
import poweredByGoogle from '../../Assets/powered_by_google_on_white.png';
const starAttraction = <img alt='major attraction star desc' className='reportStar' src="https://img.icons8.com/fluent/48/000000/star.png"/>;
const starAttraction1 = <img alt='major attraction star value' className='reportStar1' src="https://img.icons8.com/fluent/48/000000/star.png"/>;


class MostVisitedPlacesReport extends Component {
    
    
    render() { 
        const { thisListing, thisListing: { listingAddress: getListingAddress } = {}} = this.props;

        const { scores: { POI: { poi1: { name: poi1Name } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi2: { name: poi2Name } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi3: { name: poi3Name } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi1: { icon: poi1Icon } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi2: { icon: poi2Icon } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi3: { icon: poi3Icon } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi1: { latitude: poi1Lat } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi2: { latitude: poi2Lat } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi3: { latitude: poi3Lat } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi1: { longitude: poi1Lon } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi2: { longitude: poi2Lon } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi3: { longitude: poi3Lon } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi1: { majorAttraction: poi1Major } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi2: { majorAttraction: poi2Major } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi3: { majorAttraction: poi3Major } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi1: { distance: { elements: distance1Elem } = {}} = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi2: { distance: { elements: distance2Elem } = {}} = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi3: { distance: { elements: distance3Elem } = {}} = {}} = {}} = {}} = this.props;

        let poi1Distance = '';
        let poi2Distance = '';
        let poi3Distance = '';

        if (distance1Elem) {
            for (let i = 0; i < distance1Elem.length; i++ ) {
                poi1Distance = distance1Elem[i].duration.text;
            }
        }

        if (distance2Elem) {
            for (let i = 0; i < distance2Elem.length; i++ ) {
                poi2Distance = distance2Elem[i].duration.text;
            }
        }

        if (distance3Elem) {
            for (let i = 0; i < distance3Elem.length; i++ ) {
                poi3Distance = distance3Elem[i].duration.text;
            }
        }

        let thisAddressArray = [];
        thisAddressArray.push(getListingAddress);
        let addressString = thisAddressArray.toString();
        let thisListingAddress = addressString.split(',');


        return (
            <Paper id="mostVisitedPlacesReportPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 15, position: 'relative' }}>
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '6fr 1fr' }}>
                        <div className='reportAddress'>
                            <h2>{thisListingAddress[0]}</h2>
                        </div>
                        <div className='reportFourcastLogoArea'>
                            <img className='reportFourcastLogo' src={fourcastLogo} alt='Fourcast Reports Logo' />
                        </div>
                    </div>
                    <hr />
                    <div style={{ marginTop: 15 }}>
                        <div style={{ width: '100%', margin: 'auto auto', height: '100%' }}>
                            <div>
                                <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr 2fr', paddingBottom: 25, paddingTop: 25 }}>
                                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                        <h5 style={{ margin: '15px 0px 10px 20px', paddingBottom: 10 }}>Most Visited Places Nearby:</h5>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img className='reportPoweredByGoogle' src={poweredByGoogle} alt='POI Powered By Google' />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div>{starAttraction1}</div>
                                        <div className='reportStarDescription'>- Major Attraction</div>
                                    </div>
                                </div>
                                <div className='poiContent' style={{ paddingLeft: 75, height: 110, marginTop: 50, display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
                                    <div className="poi1Column" style={{ position: 'relative' }}>
                                        <div className="poi1Icon">
                                            <div className='markerNumber'>1</div>
                                            <span className="poiName">{poi1Name}</span><span>{poi1Major === true ? starAttraction : ''}</span>
                                        </div>
                                        <div className="poiDistanceReport">
                                            <span>{`${poi1Distance} walk`}<img className='poiIcon' src={poi1Icon} alt='' /></span>
                                        </div>
                                    </div>
                                    <div className="poi2Column" style={{ position: 'relative' }}>
                                        <div className="poi2Icon">
                                            <div className='markerNumber'>2</div>
                                            <span className="poiName">{poi2Name}</span><span>{poi2Major === true ? starAttraction : ''}</span>
                                        </div>
                                        <div className="poiDistanceReport">
                                            <span>{`${poi2Distance} walk`}<img className='poiIcon' src={poi2Icon} alt='' /></span>
                                        </div>
                                    </div>
                                    <div className="poi3Column" style={{ position: 'relative' }}>
                                        <div className="poi3Icon">
                                            <div className='markerNumber'>3</div>
                                            <span className="poiName">{poi3Name}</span><span>{poi3Major === true ? starAttraction : ''}</span>
                                        </div>
                                        <div className="poiDistanceReport">
                                            <span>{`${poi3Distance} walk`}<img className='poiIcon' src={poi3Icon} alt='' /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ position: 'relative', paddingBottom: 25 }}>
                                <div className="trafficStaticMapReport">
                                    <img style={{ boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)' }} alt='static google map on traffic report page' src={`https://maps.googleapis.com/maps/api/staticmap?center=${thisListing.latitude},${thisListing.longitude}&zoom=16&scale=1&size=800x500&maptype=terrain&markers=color:eb2741cb%7C${thisListing.latitude},${thisListing.longitude}&markers=color:purple%7Clabel:1%7C${poi1Lat},${poi1Lon}&markers=color:purple%7Clabel:2%7C${poi2Lat},${poi2Lon}&markers=color:purple%7Clabel:3%7C${poi3Lat},${poi3Lon}&style=feature:poi.business|visibility:off&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: 10, position: 'absolute', bottom: '1%', right: '1%', fontWeight: 800, color: 'darkgreen' }}>fourcastscore.com</div>
            </Paper>
        );
    }
}

export default MostVisitedPlacesReport;