import React, { Component, Fragment } from 'react';
import { NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import { logout } from '../../actions/authActions';
import PropTypes from 'prop-types';

class Logout extends Component {

    static propTypes = {
        logout: PropTypes.func.isRequired
    }

    render() { 
        return ( 
            <Fragment>
                <NavLink style={{ color: 'white', fontSize: 13 }} onClick={this.props.logout} href='/'>LOGOUT</NavLink>
            </Fragment>
        );
    }
}
 
export default connect(null, { logout })(Logout);