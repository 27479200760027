import React, { Component } from 'react';
import { Table } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-datalabels';
import '../../listingDetailsLayouts/demographics/demographics.css';
import '../compareListings.css';

Chart.register(ChartDataLabels);



class FamilyComparison extends Component {
    
    
    render() {

        const { listings } = this.props;

        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.scores.fourcastScore - a.scores.fourcastScore});
        
        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }

        let getThisListingsPopulation = (thisListing) => {
            
            const geoIdV4 = thisListing && thisListing.attomData && thisListing.attomData.geoIdV4 && thisListing.attomData.geoIdV4;

                // family status
            const getFamilyHouseholds = [];
            const getNonFamilyHouseholds = [];

                // children pop
            const getHouseholdsWithChildren = [];

            
            if (geoIdV4 && geoIdV4.length) {

                
                for (let g = 0; g < geoIdV4.length; g++) {

                    const famHouseNum = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.households_Family);
                    getFamilyHouseholds.push(famHouseNum);
                    const nonFamHouseNum = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.households_Non_Family);
                    getNonFamilyHouseholds.push(nonFamHouseNum);

                    const totalHouseholdsNum = (famHouseNum + nonFamHouseNum);
                    const famHouseWithChildPct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.households_Family_W_Children_Pct);
                    const famHouseWithChildNum = (totalHouseholdsNum * (famHouseWithChildPct * .01));
                    const nonFamHouseWithChildPct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.households_Non_Family_W_Children_Pct);
                    const nonFamHouseWithChildNum = (totalHouseholdsNum * (nonFamHouseWithChildPct * .01));
                    const householdsWChildrenNum = (famHouseWithChildNum + nonFamHouseWithChildNum);
                    getHouseholdsWithChildren.push(householdsWChildrenNum);

                }
                
            }


            // FAMILY GRAPH DATA

            const familyHouseholds = getFamilyHouseholds.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });
            const nonFamilyHouseholds = getNonFamilyHouseholds.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });

            const famHouseholdsPct = Math.floor((familyHouseholds / (familyHouseholds + nonFamilyHouseholds)) * 100);
            const nonFHouseholdsPct = Math.floor((nonFamilyHouseholds / (familyHouseholds + nonFamilyHouseholds)) * 100);

            const thisFamilyData = {
                familyData: {
                    labels: ['Family', 'Non-Family'],
                    datasets: [
                        {
                            label: 'Percentage of All Households',
                            backgroundColor: [
                                'rgb(187, 236, 205)',
                                'rgb(52, 181, 122)'
                            ],
                            data: [famHouseholdsPct, nonFHouseholdsPct]
                        },
                    ]
                }
            };


            // HOUSEHOLDS WITH CHILDREN 

            const householdsWithChildren = getHouseholdsWithChildren.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const thisHousesWithChildrenGauge = thisListing && thisListing.filterables && thisListing.filterables.childrenHousesGauge && thisListing.filterables.childrenHousesGauge;
            let childrenHousesNeedle = (thisHousesWithChildrenGauge && (thisHousesWithChildrenGauge > 0)) ? thisHousesWithChildrenGauge : 50;

            
            const populationObj = {address: thisListing.listingAddress, childrenHousesNum: householdsWithChildren, childrenHousesGauge: childrenHousesNeedle, thisFamilyData: thisFamilyData};
            return populationObj;
        }


        // SORT BY LISTING        
        const populationReport = false;


        // SORT BY CHILD HOUSES GAUGE
        const sortedByChildrenHouses = sortedByFourcastScore && sortedByFourcastScore.map(listing => getThisListingsPopulation(listing)).sort(function(a, b){return b.childrenHousesGauge - a.childrenHousesGauge});
        
        let listing1Chd = (sortedByChildrenHouses && sortedByChildrenHouses.length && (sortedByChildrenHouses[0] !== undefined)) ? sortedByChildrenHouses[0] : null;
        let listing2Chd = (sortedByChildrenHouses && sortedByChildrenHouses.length && (sortedByChildrenHouses[1] !== undefined)) ? sortedByChildrenHouses[1] : null;
        let listing3Chd = (sortedByChildrenHouses && sortedByChildrenHouses.length && (sortedByChildrenHouses[2] !== undefined)) ? sortedByChildrenHouses[2] : null;
        let listing4Chd = (sortedByChildrenHouses && sortedByChildrenHouses.length && (sortedByChildrenHouses[3] !== undefined)) ? sortedByChildrenHouses[3] : null;
        
        const setChdHousesGaugePointer = async (gauge, el) => {
            setTimeout(async () => {
                const childHouses = await document.querySelector(el);
                let chdHousesRotation = gauge;                
                childHouses && await childHouses.style.setProperty('--bottomChdHouses', chdHousesRotation);
            }, 100, { once: true });
        }

        (listing1Chd !== null) && listing1Chd.childrenHousesGauge && setChdHousesGaugePointer(listing1Chd.childrenHousesGauge, '.childHousesComparisonPointer1');
        (listing2Chd !== null) && listing2Chd.childrenHousesGauge && setChdHousesGaugePointer(listing2Chd.childrenHousesGauge, '.childHousesComparisonPointer2');
        (listing3Chd !== null) && listing3Chd.childrenHousesGauge && setChdHousesGaugePointer(listing3Chd.childrenHousesGauge, '.childHousesComparisonPointer3');
        (listing4Chd !== null) && listing4Chd.childrenHousesGauge && setChdHousesGaugePointer(listing4Chd.childrenHousesGauge, '.childHousesComparisonPointer4');
        
        if (listing1Chd) {listing1Chd.color = 'rgba(52, 181, 122, .2)'};
        if (listing2Chd) {listing2Chd.color = 'rgba(223, 82, 69, .2)'};
        if (listing3Chd) {listing3Chd.color = 'rgba(250, 204, 68, .2)'};
        if (listing4Chd) {listing4Chd.color = 'rgba(30, 144, 255, .2)'};

        const commaNumber = (num) => {
            let newNum = Number(num);
            let numString = newNum.toLocaleString("en-US");
            return numString;
        }

        return (
            <div style={{ position: 'relative', width: '98%' }}>
                <div style={{ display: 'flex', justifyContent: 'left', paddingBottom: 20 }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Households With Children</div>
                </div>
                <div className='busyTimesCompareGaugeArea' style={{ position: 'relative', display: 'flex', marginTop: 20 }}>
                    <div style={{ flex: 1 }}>
                        <div style={{ width: '90%', margin: 'auto auto' }}>
                            <Table style={{ tableLayout: 'fixed' }}>
                                <tbody>
                                    {sortedByChildrenHouses && sortedByChildrenHouses.map(listing => 
                                        <tr style={{ border: '1px solid lightgray', textAlign: 'center' }}>
                                            <th style={{ fontSize: 18, padding: 20, backgroundColor: listing.color }}>
                                                {listing && addressStyle(listing && listing.address)}
                                            </th>
                                            <td style={{ fontSize: 14, padding: 20, backgroundColor: listing.color }}>
                                                {listing && commaNumber(listing && listing.childrenHousesNum)}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        <div className='traffGaugeArea' style={{ flex: 1, width: '50%', height: 100, position: 'relative', marginLeft: 'auto', marginRight: 'auto' }}>
                            <div className='dayTrafficComparisonGauge'></div>
                            {((listing1Chd !== null) && (listing1Chd.childrenHousesGauge)) ? <div className='childHousesComparisonPointer1'></div> : null}
                            {((listing2Chd !== null) && (listing2Chd.childrenHousesGauge)) ? <div className='childHousesComparisonPointer2'></div> : null}
                            {((listing3Chd !== null) && (listing3Chd.childrenHousesGauge)) ? <div className='childHousesComparisonPointer3'></div> : null}
                            {((listing4Chd !== null) && (listing4Chd.childrenHousesGauge)) ? <div className='childHousesComparisonPointer4'></div> : null}
                            <div className='dayTrafficComparisonGaugeHigh'>High</div>
                            <div className='dayTrafficComparisonGaugeLow'>Low</div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'left', paddingTop: 40, paddingBottom: 20 }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Family vs Non-Family Households in the Area</div>
                </div>
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', marginTop: 20, justifyContent: 'space-around' }}>
                    {listing1Chd !== null ? (<div style={{ position: 'relative', maxWidth: 200 }}>
                    <Bar
                        data={listing1Chd.thisFamilyData.familyData}
                        height={200}
                        width={200}
                        options={{
                            title: {
                                display: true,
                                text: 'Population By Age',
                                fontSize: 14,
                                color: 'black'
                            },
                            layout: {
                                padding: {
                                    top: 20
                                }
                            },
                            plugins: {
                                datalabels: {
                                    display: true,
                                    color: "black",
                                    anchor: "end",
                                    offset: -20,
                                    align: "start",
                                    font: {
                                        weight: 'bold',
                                        size: 12,
                                    },
                                    formatter: function(value, context) {
                                        return value + '%';
                                    },
                                },
                                legend: {
                                    display: true,
                                    position: "bottom"
                                }
                            },
                            indexAxis: `${populationReport ? 'y' : 'x'}`,
                            scales: {
                                x: populationReport ? {
                                    ticks: {
                                        display: false,
                                        beginAtZero: true,
                                    },
                                    grid: {
                                        display: false
                                    },
                                } : {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                    grid: {
                                        display: false
                                    },
                                    barPercentage: .95
                                },
                                y: populationReport ? {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                    grid: {
                                        display: false
                                    },
                                    barPercentage: .95
                                } : {
                                    ticks: {
                                        display: false,
                                        beginAtZero: true,
                                    },
                                    grid: {
                                        display: false
                                    },
                                },
                            }
                        }} 
                    />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing1Chd.address)}</div>
                    </div>) : null}
                    {listing2Chd !== null ? (<div style={{ position: 'relative', maxWidth: 200 }}>
                        <Bar
                            data={listing2Chd.thisFamilyData.familyData}
                            height={200}
                            width={200}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Population By Age',
                                    fontSize: 14,
                                    color: 'black'
                                },
                                layout: {
                                    padding: {
                                        top: 20
                                    }
                                },
                                plugins: {
                                    datalabels: {
                                        display: true,
                                        color: "black",
                                        anchor: "end",
                                        offset: -20,
                                        align: "start",
                                        font: {
                                            weight: 'bold',
                                            size: 12,
                                        },
                                        formatter: function(value, context) {
                                            return value + '%';
                                        },
                                    },
                                    legend: {
                                        display: true,
                                        position: "bottom"
                                    }
                                },
                                indexAxis: `${populationReport ? 'y' : 'x'}`,
                                scales: {
                                    x: populationReport ? {
                                        ticks: {
                                            display: false,
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                    } : {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                        barPercentage: .95
                                    },
                                    y: populationReport ? {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                        barPercentage: .95
                                    } : {
                                        ticks: {
                                            display: false,
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                    },
                                }
                            }} 
                        />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing2Chd.address)}</div>
                    </div>) : null}
                    {listing3Chd !== null ? (<div style={{ position: 'relative', maxWidth: 200 }}>
                        <Bar
                            data={listing3Chd.thisFamilyData.familyData}
                            height={200}
                            width={200}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Population By Age',
                                    fontSize: 14,
                                    color: 'black'
                                },
                                layout: {
                                    padding: {
                                        top: 20
                                    }
                                },
                                plugins: {
                                    datalabels: {
                                        display: true,
                                        color: "black",
                                        anchor: "end",
                                        offset: -20,
                                        align: "start",
                                        font: {
                                            weight: 'bold',
                                            size: 12,
                                        },
                                        formatter: function(value, context) {
                                            return value + '%';
                                        },
                                    },
                                    legend: {
                                        display: true,
                                        position: "bottom"
                                    }
                                },
                                indexAxis: `${populationReport ? 'y' : 'x'}`,
                                scales: {
                                    x: populationReport ? {
                                        ticks: {
                                            display: false,
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                    } : {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                        barPercentage: .95
                                    },
                                    y: populationReport ? {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                        barPercentage: .95
                                    } : {
                                        ticks: {
                                            display: false,
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                    },
                                }
                            }} 
                        />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing3Chd.address)}</div>
                    </div>) : null}
                    {listing4Chd !== null ? (<div style={{ position: 'relative', maxWidth: 200 }}>
                        <Bar
                            data={listing4Chd.thisFamilyData.familyData}
                            height={200}
                            width={200}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Population By Age',
                                    fontSize: 14,
                                    color: 'black'
                                },
                                layout: {
                                    padding: {
                                        top: 20
                                    }
                                },
                                plugins: {
                                    datalabels: {
                                        display: true,
                                        color: "black",
                                        anchor: "end",
                                        offset: -20,
                                        align: "start",
                                        font: {
                                            weight: 'bold',
                                            size: 12,
                                        },
                                        formatter: function(value, context) {
                                            return value + '%';
                                        },
                                    },
                                    legend: {
                                        display: true,
                                        position: "bottom"
                                    }
                                },
                                indexAxis: `${populationReport ? 'y' : 'x'}`,
                                scales: {
                                    x: populationReport ? {
                                        ticks: {
                                            display: false,
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                    } : {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                        barPercentage: .95
                                    },
                                    y: populationReport ? {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                        barPercentage: .95
                                    } : {
                                        ticks: {
                                            display: false,
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                    },
                                }
                            }} 
                        />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing4Chd.address)}</div>
                    </div>) : null}
                </div>
            </div>
        );
    }
}

export default FamilyComparison;