import React, { Component } from 'react';
import { 
    Modal, 
    ModalHeader, 
    ModalBody, 
    Row,
    Table }
from 'reactstrap';
import moment from 'moment';
import { Button } from '@mui/material';
import MatchModal from './matchModal';

class LeadsModal extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    }

    render() {
        
        const { thisUsersLeads, thisListing, user } = this.props;

        let newOrNot = (createdTime) => {
            let rightNow = moment();
            let tOC = createdTime;
            let difference = rightNow.diff(tOC, 'days');
            if (difference <= 1) {
                return 'NEW'
            }
            return '';
        };
        
        let sortedLeads = thisUsersLeads && thisUsersLeads.filter((lead) => {
            if (lead.listing._id === thisListing._id) {
                return lead
            }
        }).map((lead) => {
                        
            return (
                <tr style={{ alignItems: 'center' }}>
                    <th style={{ fontSize: 14, marginLeft: '1rem', color: 'darkgreen', verticalAlign: 'middle' }}>
                        {newOrNot(lead.createdAt)}
                    </th>
                    <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                        {lead.fourcastMatch ? <MatchModal lead={lead} thisListing={thisListing} /> : 'None'}
                    </td>
                    <td style={{ fontSize: 14, verticalAlign: 'middle' }}>
                        {lead.createdAt ? moment(lead.createdAt).fromNow() : 'N/A'}
                    </td>
                    <td style={{ fontSize: 14, verticalAlign: 'middle' }}>
                        {lead.sender.company}
                    </td>
                    <td style={{ fontSize: 14, verticalAlign: 'middle' }}>
                        {lead.sender.email}
                    </td>
                    <td>
                        {(user && thisListing && lead) ? <Button size="small" style={{ color: 'white', backgroundColor: '#007FFF', outline: 'none' }}><a style={{ color: 'white', textDecoration: 'none' }} href={`mailto:${lead.sender.email}?subject=Fourcast Lead: ${thisListing.listingAddress}`}>Send Message</a></Button> : null}
                    </td>
                </tr>
            )
        });

        return (
            <div>
                <Button
                    onClick={this.toggle}
                    style={{ color: 'white', backgroundColor: 'rgb(52, 181, 122)', outline: 'none' }}
                    size="small"
                >
                    VIEW LEADS
                </Button>
                <Modal style={{ minWidth: 975 }} isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>All Leads For This Listing</ModalHeader>
                    <ModalBody style={{ width: 950, margin: 'auto' }}>
                        <Row>
                            <h4 style={{ fontFamily: 'sans-serif', color: 'darkgreen', fontWeight: 'bold', wordSpacing: 1, marginRight: 'auto', marginLeft: 'auto' }}>{thisListing.listingAddress}</h4>
                        </Row>
                        <Row style={{ marginTop: 20 }}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th> </th>
                                        <th>Fourcast Match</th>
                                        <th>Submitted</th>
                                        <th>Business</th>
                                        <th>Contact</th>
                                        <th> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedLeads}
                                </tbody>
                            </Table>
                        </Row>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default LeadsModal;