import React, { Component } from 'react';
import { Button, Snackbar, Alert } from '@mui/material';
import ReportCoverPage from './reportCoverPage';
import ReportSummaryPage from './reportSummaryPage';
import MostVisitedPlacesReport from './mostVisitedPlacesReport';
import BusyTimesReport from './busyTimesReport';
import AccessibilityAndEngagementReport from './accessibilityAndEngagementReport';
import IncomeReport from './incomeReport';
import PopulationReport from './populationReport';
import ResidentialREReport from './residentialReReport';
import BizDensityAndCommonBizTypesReport from './bizDensityAndCommonBizTypesReport';
import HighEndBizReport from './highEndBizReport';
import TopBizNearbyReport from './topBizNearbyReport';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import moment from 'moment';
import { MdFileDownload } from 'react-icons/md';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import '../listingDetailsLayouts/traffic/traffic.css';
import '../listingDetailsLayouts/summary/summary.css';



class CreateReportOutput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedListingNum: 0,
            downloaded: [],
            editMode: true,
            notConfirmedError: false
        }
    }

    setDownloaded () {
        const { downloaded, selectedListingNum } = this.state;
        this.setState({ downloaded: [...downloaded, selectedListingNum] });
    }

    setEditMode = (status) => {
        const { editMode } = this.state;
        if (editMode !== status) {
            this.setState({ editMode: status });
        }
    }

    handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
          this.setState({ notConfirmedError: false });
    }

    setNotConfirmedError = (event) => {
        this.setState({ notConfirmedError: true });
    }
    
    render() { 
        
        const { downloaded } = this.state;
        const { components, selected, thisListing, scores, filterables, selectedListingNum, streetViewImages } = this.props;
        const { thisListing: { attomData } = {}, thisListing: { walkScoreData } = {}, thisListing: { yelpData } = {}, thisListing: { listingAddress: getListingAddress } = {}} = this.props;
        const coverPage = components && components.coverPage;
        const summaryPage = components && components.summaryPage;
        const traffComponents = components && components.traffAddOns;
        const demoComponents = components && components.demoAddOns;
        const compComponents = components && components.compAddOns;

        let thisAddressArray = [];
        thisAddressArray.push(getListingAddress);
        let addressString = thisAddressArray.toString();
        let [thisListingAddress] = addressString.split(',');
        
        const getReportElems = (elems) => {
            const newElems = [];
            for (let i = 0; i < elems.length; i++) {
                let newName = `${elems[i]}Component`
                newElems.push(newName)
            }
            return newElems;
        }

        const finalTraffElems = getReportElems(traffComponents);
        const finalDemoElems = getReportElems(demoComponents);
        const finalCompElems = getReportElems(compComponents);
        const reportElems =  [(coverPage === true) ? 'reportCoverPageComponent' : null, (summaryPage === true) ? 'reportSummaryPageComponent' : null, ...finalTraffElems, ...finalDemoElems, ...finalCompElems];
        
        const getCanvasData = element => {
            
            return new Promise(async (resolve, reject) => {
              await html2canvas(element, {logging: true, letterRendering: 1, useCORS: true})
                .then(function(canvas) {
                  resolve(canvas.toDataURL("image/jpeg"));
                })
                .catch(function(error) {
                  reject(
                    "Error while creating canvas for element with ID: " + element.id
                  );
                });
            });
        };
        
        const downloadPdfDocument = async () => {
            
            var pdf = new jsPDF({
              orientation: "p",
              unit: "mm",
              format: "a4",
              compression: true
            });
        
            let pageIds = reportElems;
            let promises = [];
            
            await pageIds.forEach(page => {
                promises.push(getCanvasData(document.getElementById(page)));
                this.setDownloaded();
            });
        
            Promise.all(promises).then(dataUrls => {
                dataUrls.forEach((dataUrl, i) => {
                    const imgWidth = pdf.internal.pageSize.getWidth() * 2;
                    const imgHeight = pdf.internal.pageSize.getHeight();
                    const imgX = ((pdf.internal.pageSize.getWidth() - imgWidth) / 2);
                    pdf.addImage(dataUrl, "JPEG", imgX+10.20, 0, imgWidth-20, imgHeight, undefined, "FAST");
                    if (i !== reportElems.length - 1) {
                        pdf.addPage();
                    }
                });
                pdf.save(`${thisListingAddress}_Fourcast_Report.pdf`);
            });
        };


        return (
            <div style={{ backgroundColor: 'lightgray', paddingTop: 25, paddingBottom: 25 }}>
                <div style={{ position: 'absolute', left: '.5%', top: '8%', zIndex: 600 }}>
                    <Button onClick={() => this.props.prevStep()} variant='link'>Back</Button>
                </div>
                <div style={{ backgroundColor: 'lightgray', width: '100%', height: 100, display: 'grid', gridTemplateColumns: '2fr 1fr 2fr', position: 'sticky', top: 0, zIndex: 500 }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button onClick={() => this.props.prevListing()} variant='contained' style={(selectedListingNum < 1) ? {} : { backgroundColor: 'rgb(21, 134, 84)' }} disabled={(selectedListingNum < 1)}>Previous Listing</Button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button className='downloadReportBtn' style={{ backgroundColor: 'rgb(35, 126, 85)', color: 'white', width: 275, outline: 'none', padding: 5 }} onClick={(this.state.editMode === true) ? this.setNotConfirmedError : downloadPdfDocument} key={selectedListingNum} startIcon={downloaded.includes(selectedListingNum) ? <AiOutlineCheckCircle /> : <MdFileDownload />}>{downloaded.includes(selectedListingNum) ? 'Downloaded' : 'Download Fourcast Report'}</Button>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button onClick={() => this.props.nextListing()} variant='contained' style={(selectedListingNum === (selected.length - 1)) ? {} : { backgroundColor: 'rgb(21, 134, 84)' }} disabled={(selectedListingNum === (selected.length - 1))}>Next Listing</Button>
                    </div>
                </div>
                {(coverPage && coverPage === true) ? <div id='reportCoverPageComponent'>
                    <ReportCoverPage streetViewImages={streetViewImages} thisListing={thisListing} handleCloseAlert={this.handleCloseAlert} notConfirmedError={this.state.notConfirmedError} editMode={this.state.editMode} setEditMode={this.setEditMode} />
                </div> : null}
                {(summaryPage && summaryPage === true) ? <div id='reportSummaryPageComponent' style={{ marginTop: 25 }}>
                    <ReportSummaryPage thisListing={thisListing} scores={scores} attomData={attomData} />
                </div> : null}
                {(traffComponents && traffComponents.includes('mostVisitedPlacesData')) ? <div id='mostVisitedPlacesDataComponent' style={{ marginTop: 25 }}>
                    <MostVisitedPlacesReport thisListing={thisListing} scores={scores} />
                </div> : null}
                {(traffComponents && traffComponents.includes('busyTimesOfDay')) ? <div id='busyTimesOfDayComponent' style={{ marginTop: 25 }}>
                    <BusyTimesReport thisListing={thisListing} filterables={filterables} />
                </div> : null}
                {(traffComponents && traffComponents.includes('accessibilityAndEngagement')) ? <div id='accessibilityAndEngagementComponent' style={{ marginTop: 25 }}>
                    <AccessibilityAndEngagementReport thisListing={thisListing} yelpData={yelpData} scores={scores} walkScoreData={walkScoreData} />
                </div> : null}
                {(demoComponents && demoComponents.includes('incomeData')) ? <div id='incomeDataComponent' style={{ marginTop: 25 }}>
                    <IncomeReport thisListing={thisListing} attomData={attomData} scores={scores} />
                </div> : null}
                {(demoComponents && demoComponents.includes('populationData')) ? <div id='populationDataComponent' style={{ marginTop: 25 }}>
                    <PopulationReport thisListing={thisListing} attomData={attomData} scores={scores} filterables={filterables} />
                </div> : null}
                {(demoComponents && demoComponents.includes('residentialREData')) ? <div id='residentialREDataComponent' style={{ marginTop: 25 }}>
                    <ResidentialREReport thisListing={thisListing} attomData={attomData} scores={scores} />
                </div> : null}
                {(compComponents && compComponents.includes('popularBizData')) ? <div id='popularBizDataComponent' style={{ marginTop: 25 }}>
                    <TopBizNearbyReport thisListing={thisListing} yelpData={yelpData} />
                </div> : null}
                {(compComponents && compComponents.includes('businessDensityAndCommonBizTypes')) ? <div id='businessDensityAndCommonBizTypesComponent' style={{ marginTop: 25 }}>
                    <BizDensityAndCommonBizTypesReport thisListing={thisListing} yelpData={yelpData} scores={scores} filterables={filterables} />
                </div> : null}
                {(compComponents && compComponents.includes('highEndData')) ? <div id='highEndDataComponent' style={{ marginTop: 25 }}>
                    <HighEndBizReport thisListing={thisListing} filterables={filterables} />
                </div> : null}
            </div>
        );
    }
}

export default CreateReportOutput;