import axios from 'axios';
import { GET_LEADS, ADD_LEAD, LEADS_LOADING, GET_LEAD, UPDATE_LEAD } from './types';
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';

// Pointing Axios baseURL towards AWS API Gateway URL
// Will most likely be changed again at some point because of the /dev
const axiosAWS = axios.create({
    baseURL: 'https://bgeg45v1tk.execute-api.us-east-1.amazonaws.com/dev'
});

export const getLeads = () => (dispatch, getState) => {
    dispatch(setLeadsLoading());
    axiosAWS
        .get('/api/leads', tokenConfig(getState))
        .then(res => 
            dispatch({
                type: GET_LEADS,
                payload: res.data
            })
        ).catch(err => 
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const addLead = lead => (dispatch, getState) => {
    axiosAWS
        .post('/api/leads', lead, tokenConfig(getState))
        .then(res => 
            dispatch({
                type: ADD_LEAD,
                payload: res.data
            })
        ).catch(err => 
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const getLead = id => (dispatch, getState) => {
    dispatch(setLeadsLoading());
    axiosAWS
        .get(`/api/leads/${id}`, tokenConfig(getState))
        .then(res => 
            dispatch({
                type: GET_LEAD,
                payload: res.data
            })
        ).catch(err => 
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const updateLead = updatedLead => (dispatch, getState) => {
    axiosAWS
        .patch(`/api/leads/${updatedLead.thisLeadsID}`, { 
            sender: updatedLead.sender,
        }, tokenConfig(getState))
        .then(res => 
            dispatch({
                type: UPDATE_LEAD,
                payload: res.data
            })
        ).catch(err => 
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const setLeadsLoading = () => {
    return {
        type: LEADS_LOADING
    }
};