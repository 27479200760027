import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { 
    Modal, 
    ModalHeader, 
    ModalBody } 
from 'reactstrap';
import fourcastMatchLogo from '../../Assets/fourcast_match_logo_green.png';
import { Button } from '@mui/material';
import '../CompareListings/compareListings.css';


class FourcastMatchModal extends Component {
    
    constructor(props) {
        
        super(props);
        this.state = {
            fourcastMatchModal: false,
        }
    }

    toggle = () => {
        this.setState({
            fourcastMatchModal: !this.state.fourcastMatchModal,
        });
    }


    render() {
        
        return (
            <div>
                <Button
                    variant="contained"
                    className="compareListingsMapButton"
                    onClick={(e) => this.setState({ fourcastMatchModal: true })}
                    style={{ backgroundColor: 'white', height: 45, width: 190, textAlign: 'center', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', borderRadius: '5px', outline: 'none' }}
                >
                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 2.5 }}>
                        <img style={{ width: 139.5, height: 34 }} alt='Try Fourcast Comps' src={fourcastMatchLogo} />
                    </div>
                </Button>
                <Modal style={{ margin: '50%, 50%', minWidth: 650 }} isOpen={this.state.fourcastMatchModal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Fourcast Match</ModalHeader>
                    <ModalBody style={{margin: 'auto', overflowY: 'scroll', maxHeight: 650, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ width: '100%', height: 250 }}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img style={{ width: 250, height: 59.5 }} alt='Try Fourcast Comps' src={fourcastMatchLogo} />
                            </div>
                            <div style={{ height: 100, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ width: '75%', fontSize: 18, fontWeight: 800, textAlign: 'center' }}>Get immediately paired with listings that match your criteria!</div>
                            </div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/znIPgwh9GQw?si=OkqO6b-DQFyvBbSU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                        <div style={{ marginTop: 50, height: 75, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 25}}>
                            <Link 
                                style={{ textDecoration: "none" }}
                                to={'/customSearch'}
                            >
                                <Button variant="contained" style={{ backgroundColor: 'rgb(52, 181, 122)' }}>
                                    <div style={{ color: 'white', textDecoration: 'none' }}>Launch Fourcast Match!</div>
                                </Button>
                            </Link>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}


 
export default FourcastMatchModal;