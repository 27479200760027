import React, { Component } from 'react';
import { Card } from '@material-ui/core';
import { Container, Row, Col } from 'reactstrap';
import amdfStorefront from '../../Assets/amdfStorefront.png';
import './description.css';

class MobileAMDFHighlight extends Component {
    

    constructor(props) {
        super(props);
        this.state = {
            screenWidth: window.screen.width,
            mobile: true,
            lineFontSize: 20
        }
    }

    componentDidMount() {
        window && window.addEventListener('resize', this.mobileMode.bind(this));
        window && window.addEventListener('resize', this.setFontSizes.bind(this));
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth });
        } else {
            this.setState({ mobile: false });
        }

        if (screenWidth && (screenWidth > 900)) {
            this.setState({ lineFontSize: 44 })
        } else if (screenWidth && (screenWidth > 800)) {
            this.setState({ lineFontSize: 40 })
        } else if (screenWidth && (screenWidth > 700)) {
            this.setState({ lineFontSize: 36 })
        } else if (screenWidth && (screenWidth > 600)) {
            this.setState({ lineFontSize: 32 })
        } else if (screenWidth && (screenWidth > 500)) {
            this.setState({ lineFontSize: 28 })
        } else {
            this.setState({ lineFontSize: 24 })
        }
    }

    componentWillUnmount() {
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
        window && window.removeEventListener('resize', this.setFontSizes.bind(this));
    }

    mobileMode() {
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth, mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }

    setFontSizes() {
        let screenWidth = window.screen.width;
        if (screenWidth && (screenWidth > 900)) {
            this.setState({ lineFontSize: 44 })
        } else if (screenWidth && (screenWidth > 800)) {
            this.setState({ lineFontSize: 40 })
        } else if (screenWidth && (screenWidth > 700)) {
            this.setState({ lineFontSize: 36 })
        } else if (screenWidth && (screenWidth > 600)) {
            this.setState({ lineFontSize: 32 })
        } else if (screenWidth && (screenWidth > 500)) {
            this.setState({ lineFontSize: 28 })
        } else {
            this.setState({ lineFontSize: 24 })
        }
    }

    
    render() { 
        
        const { screenWidth, lineFontSize } = this.state;

        return ( 
            <Container style={{ width: screenWidth, position: 'relative', paddingTop: 100, paddingBottom: 50 }} fluid>
                <div style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', paddingBottom: 50 }}>
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                            <div style={{ width: '75%' }}>
                                <Row style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: lineFontSize, fontWeight: 'bold', fontStyle: 'italic', marginTop: 40 }}>
                                    “I was really surprised and impressed with the space Fourcast matched us with during our search for a second NYC location - it is literally across the street from a space that we tried to get during our previous search...”
                                </Row>
                                <Row style={{ marginTop: 20, position: 'relative' }}>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontStyle: 'italic', fontWeight: 'bold', fontSize: 16 }}>Antoine</div>
                                </Row>
                                <Row>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16 }}>Aux Merveilleux de Fred</div>
                                </Row>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 15 }}>
                            <Card id="clientPicMobile">
                                <img id="amdfStorefrontMobile" src={amdfStorefront} alt="amdfStore" />
                            </Card>
                        </div>
                    </div>
                </div>
            </Container>
         );
    }
}
 
export default MobileAMDFHighlight;