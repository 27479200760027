import React, { Component } from 'react';
import { Card, CardContent, Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemIcon, Button, Checkbox, Box, FormControlLabel, Stack, Alert, TextField, Autocomplete } from '@mui/material';
import { MdExpandMore, MdOutlineCheckCircle, MdOutlineCancel } from 'react-icons/md';
import { connect } from 'react-redux';
import { getListings, getListing } from '../../actions/listingActions';
import { loadUser, updateUser } from '../../actions/authActions';
import Select from 'react-select';
import PropTypes from 'prop-types';
import './createReport.css';



class CreateReportInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            noDataAlert: false,
            selected: [],
            coverPage: true,
            summaryPage: true,
            traffAddOns: ['mostVisitedPlacesData', 'busyTimesOfDay', 'accessibilityAndEngagement'],
            demoAddOns: ['incomeData', 'populationData', 'residentialREData'],
            compAddOns: ['popularBizData', 'businessDensityAndCommonBizTypes', 'highEndData'],
            reqestedListing: []
        }
    }
    
    async componentDidMount() {
        
        await this.props.loadUser();
        const { isAuthenticated, user, requestedListing } = this.props;
        
        if (user && isAuthenticated) {
            if (requestedListing && requestedListing.length) {
                this.setState({ selected: requestedListing });
            }
            
            try {
                let pageAddresses;
                pageAddresses = { path: "listingAddresses" };
                await this.props.getListings(pageAddresses);
            } catch (err) {
                console.log(err);
            }

        }
    }


    async componentDidUpdate(prevState, prevProps) {

        if (this.state.noDataAlert === true) {
            setTimeout(() => {
                this.setState({ noDataAlert: false });
            }, 7500)
        }
        
    }

    async onSubmit() {
        
        const { selected, coverPage, summaryPage, traffAddOns, demoAddOns, compAddOns } = this.state;
        const components = { coverPage, summaryPage, traffAddOns, demoAddOns, compAddOns };
    
        if ((coverPage === false) && (summaryPage === false) && !traffAddOns.length && !demoAddOns.length && !compAddOns.length) {
            this.setState({ noDataAlert: true });
        }

        if (!selected.length) {
            this.setState({ noDataAlert: true });
        }

        if ((selected.length) && ((coverPage === true) || (summaryPage === true) || traffAddOns.length || demoAddOns.length || compAddOns.length)) {
            await this.props.setSelected(selected);
            await this.props.setReportComponents(components);
            await this.props.nextStep();
        }
    }

    
    render() {

        const filterLabels = (label) => {    
    
            switch(label) {
                case 'mostVisitedPlacesData':
                    return (
                        "Most Visited Places Nearby"
                    )
                case 'busyTimesOfDay':
                    return (
                        "Daytime Traffic & Nightlife"
                    )
                case 'accessibilityAndEngagement':
                    return (
                        "Accessibility & Engagement"
                    )
                case 'incomeData':
                    return (
                        "Income Data"
                    )
                case 'populationData':
                    return (
                        "Population Data"
                    )
                case 'residentialREData':
                    return (
                        "Residential Real Estate Data"
                    )
                case 'popularBizData':
                    return (
                        "Most Popular Businesses Nearby"
                    )
                case 'businessDensityAndCommonBizTypes':
                    return (
                        "Business Density & Common Business Types"
                    )
                case 'highEndData':
                    return (
                        "High-End Business Data"
                    )
                default:
                    return null
            }
        }

        const { requestedListing, listings, user: { engagement: { savedListings } = {}} = {}} = this.props;
        const listingAddresses = listings && listings.map(listing => {return {label: listing.listingAddress, value: listing.id}});
        const userSavedListings = listings && listings.filter(listing => savedListings.some(savedId => savedId === listing.id)).map(listing => {return {label: listing.listingAddress, value: listing.id}});
        
        const { noDataAlert, selected, coverPage, summaryPage, traffAddOns, demoAddOns, compAddOns } = this.state;

        const traffChildren = (
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 4 }}>
                <FormControlLabel
                    value={'mostVisitedPlacesData'}
                    control={<Checkbox 
                        checked={(traffAddOns.includes('mostVisitedPlacesData')) ? true : false}
                        onChange={(e) => {
                            const value = 'mostVisitedPlacesData';
                            const checked = (traffAddOns.includes('mostVisitedPlacesData'));
                            let reportTag = checked ? traffAddOns.filter((tags) => tags !== value) : [...traffAddOns, value];
                            this.setState({ traffAddOns: reportTag });
                        }}
                    />}
                    label={<div style={{ fontSize: 14, paddingLeft: 5 }}>Most Visited Places Nearby</div>}
                    labelPlacement="end"
                />
                <FormControlLabel
                    value={'busyTimesOfDay'}
                    control={<Checkbox 
                        checked={(traffAddOns.includes('busyTimesOfDay')) ? true : false}
                        onChange={(e) => {
                            const value = 'busyTimesOfDay';
                            const checked = (traffAddOns.includes('busyTimesOfDay'));
                            let reportTag = checked ? traffAddOns.filter((tags) => tags !== value) : [...traffAddOns, value];
                            this.setState({ traffAddOns: reportTag });
                        }}
                    />}
                    label={<div style={{ fontSize: 14, paddingLeft: 5 }}>Daytime Traffic & Nightlife</div>}
                    labelPlacement="end"
                />
                <FormControlLabel
                    value={'accessibilityAndEngagement'}
                    control={<Checkbox 
                        checked={(traffAddOns.includes('accessibilityAndEngagement')) ? true : false}
                        onChange={(e) => {
                            const value = 'accessibilityAndEngagement';
                            const checked = (traffAddOns.includes('accessibilityAndEngagement'));
                            let reportTag = checked ? traffAddOns.filter((tags) => tags !== value) : [...traffAddOns, value];
                            this.setState({ traffAddOns: reportTag });
                        }}
                    />}
                    label={<div style={{ fontSize: 14, paddingLeft: 5 }}>Accessibility & Engagement</div>}
                    labelPlacement="end"
                />
            </Box>
        );

        const demoChildren = (
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 4 }}>
                <FormControlLabel
                    value={'incomeData'}
                    control={<Checkbox 
                        checked={(demoAddOns.includes('incomeData')) ? true : false}
                        onChange={(e) => {
                            const value = 'incomeData';
                            const checked = (demoAddOns.includes('incomeData'));
                            let reportTag = checked ? demoAddOns.filter((tags) => tags !== value) : [...demoAddOns, value];
                            this.setState({ demoAddOns: reportTag });
                        }}
                    />}
                    label={<div style={{ fontSize: 14, paddingLeft: 5 }}>Income Data</div>}
                    labelPlacement="end"
                />
                <FormControlLabel
                    value={'populationData'}
                    control={<Checkbox 
                        checked={(demoAddOns.includes('populationData')) ? true : false}
                        onChange={(e) => {
                            const value = 'populationData';
                            const checked = (demoAddOns.includes('populationData'));
                            let reportTag = checked ? demoAddOns.filter((tags) => tags !== value) : [...demoAddOns, value];
                            this.setState({ demoAddOns: reportTag });
                        }}
                    />}
                    label={<div style={{ fontSize: 14, paddingLeft: 5 }}>Population Data</div>}
                    labelPlacement="end"
                />
                <FormControlLabel
                    value={'residentialREData'}
                    control={<Checkbox 
                        checked={(demoAddOns.includes('residentialREData')) ? true : false}
                        onChange={(e) => {
                            const value = 'residentialREData';
                            const checked = (demoAddOns.includes('residentialREData'));
                            let reportTag = checked ? demoAddOns.filter((tags) => tags !== value) : [...demoAddOns, value];
                            this.setState({ demoAddOns: reportTag });
                        }}
                    />}
                    label={<div style={{ fontSize: 14, paddingLeft: 5 }}>Residential Real Estate Data</div>}
                    labelPlacement="end"
                />
            </Box>
        );

        const compChildren = (
            <Box sx={{ display: 'flex', flexDirection: 'column', ml: 4 }}>
                <FormControlLabel
                    value={'popularBizData'}
                    control={<Checkbox 
                        checked={(compAddOns.includes('popularBizData')) ? true : false}
                        onChange={(e) => {
                            const value = 'popularBizData';
                            const checked = (compAddOns.includes('popularBizData'));
                            let reportTag = checked ? compAddOns.filter((tags) => tags !== value) : [...compAddOns, value];
                            this.setState({ compAddOns: reportTag });
                        }}
                    />}
                    label={<div style={{ fontSize: 14, paddingLeft: 5 }}>Most Popular Businesses Nearby</div>}
                    labelPlacement="end"
                />
                <FormControlLabel
                    value={'businessDensityAndCommonBizTypes'}
                    control={<Checkbox 
                        checked={(compAddOns.includes('businessDensityAndCommonBizTypes')) ? true : false}
                        onChange={(e) => {
                            const value = 'businessDensityAndCommonBizTypes';
                            const checked = (compAddOns.includes('businessDensityAndCommonBizTypes'));
                            let reportTag = checked ? compAddOns.filter((tags) => tags !== value) : [...compAddOns, value];
                            this.setState({ compAddOns: reportTag });
                        }}
                    />}
                    label={<div style={{ fontSize: 14, paddingLeft: 5 }}>Business Density & Common Business Types</div>}
                    labelPlacement="end"
                />
                <FormControlLabel
                    value={'highEndData'}
                    control={<Checkbox 
                        checked={(compAddOns.includes('highEndData')) ? true : false}
                        onChange={(e) => {
                            const value = 'highEndData';
                            const checked = (compAddOns.includes('highEndData'));
                            let reportTag = checked ? compAddOns.filter((tags) => tags !== value) : [...compAddOns, value];
                            this.setState({ compAddOns: reportTag });
                        }}
                    />}
                    label={<div style={{ fontSize: 14, paddingLeft: 5 }}>High-End Business Data</div>}
                    labelPlacement="end"
                />
            </Box>
        );

        const listingOptions = [
            { label: 'Saved', options: userSavedListings },
            { label: "Active Listings", options: listingAddresses },
        ];

        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          };
          const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: 'black',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
            overflowY: 'scroll'
          };
          
          const formatGroupLabel = data => (
            <div style={groupStyles}>
              <span>{data.label}</span>
              <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
          );
        

        return (
            <div className='createReportSelections'>
                <div className='listingSelectArea'>
                    <div style={{ width: '100%', textAlign: 'center', paddingBottom: 25 }}>
                        <h5 style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: 15, marginTop: 20, fontWeight: 500 }}>1). Select Listings</h5>
                    </div>
                    <div style={{ marginLeft: 'auto', marginRight: 'auto', height: 100, width: '90%' }}>
                        <Select 
                            options={listingOptions}
                            styles={{
                                control: (baseStyles, state) => ({
                                ...baseStyles,
                                width: '95%',
                                margin: 'auto',
                                minHeight: 75,
                                maxHeight: 100,
                                overflowY: 'scroll',
                                border: (!selected.length && (noDataAlert === true)) ? '2px solid darkred' : null
                                }),
                            }}
                            formatGroupLabel={formatGroupLabel}
                            isMulti={true}
                            placeholder="Find Listings..."
                            onChange={(values) => {
                                this.setState({ selected: values });
                            }}
                            defaultValue={requestedListing ? requestedListing : selected}
                        />
                    </div>
                    <Card style={{ backgroundColor: 'snow', marginTop: 50, width: 400, height: 400, marginLeft: 'auto', marginRight: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', overflowY: 'scroll', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)' }}>
                        <CardContent style={{ width: 400, height: 450, paddingTop: 40, paddingBottom: 40 }}>
                                <div>
                                    {selected.length ? selected.map(selectedListing => 
                                    (<List>
                                        <ListItem><ListItemIcon>
                                            <MdOutlineCheckCircle style={{ width: 25, height: 25, color: 'darkgreen' }} />
                                        </ListItemIcon>{selectedListing.label}</ListItem>
                                    </List>)) 
                                    : (<ListItem>None Selected</ListItem>)}
                                </div>
                        </CardContent>
                    </Card>
                </div>
                <div className='reportInputArea'>
                    <div style={{ width: '100%', textAlign: 'center', paddingBottom: 25 }}>
                        <h5 style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: 15, marginTop: 20, fontWeight: 500 }}>2). Select Report Components</h5>
                    </div>
                    <Card style={{ backgroundColor: 'snow', width: 450, height: 75, marginLeft: 'auto', marginRight: 'auto', borderRadius: 0 }}>
                        <CardContent style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <h5 className='filterType' style={{ paddingBottom: 20, height: 40, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>Cover Page</h5>
                            <div style={{ width: 375, paddingBottom: 20, marginTop: -10, display: 'flex', justifyContent: 'right', paddingRight: 20 }}>
                                <Checkbox 
                                    checked={this.state.coverPage === true}
                                    onChange={(e) => {
                                        const checked = (this.state.coverPage === true);
                                        let reportTag = checked ? false : true;
                                        this.setState({ coverPage: reportTag });
                                    }}
                                />
                            </div>
                        </CardContent>
                    </Card>
                    <Card style={{ backgroundColor: 'snow', width: 450, height: 75, marginLeft: 'auto', marginRight: 'auto', marginTop: 10, borderRadius: 0 }}>
                        <CardContent style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <h5 className='filterType' style={{ paddingBottom: 20, height: 40, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>Property Summary</h5>
                            <div style={{ width: 375, paddingBottom: 20, marginTop: -10, display: 'flex', justifyContent: 'right', paddingRight: 20 }}>
                                <Checkbox 
                                    checked={this.state.summaryPage === true}
                                    onChange={(e) => {
                                        const checked = (this.state.summaryPage === true);
                                        let reportTag = checked ? false : true;
                                        this.setState({ summaryPage: reportTag });
                                    }}
                                />
                            </div>
                        </CardContent>
                    </Card>
                    <Accordion style={{ backgroundColor: 'snow', width: 450, marginLeft: 'auto', marginRight: 'auto', marginBottom: 10, marginTop: 10 }}>
                        <AccordionSummary
                            expandIcon={<MdExpandMore style={{ width: 30, height: 30 }} />}
                            style={{ width: '95%' }}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h5 className='filterType'>Traffic Data</h5>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -20 }}>
                            <div style={{ width: 400, paddingLeft: 10 }}>
                                <FormControlLabel
                                    value={this.state.allTrafficData}
                                    control={<Checkbox 
                                        checked={(traffAddOns.includes('mostVisitedPlacesData') && traffAddOns.includes('busyTimesOfDay') && traffAddOns.includes('accessibilityAndEngagement'))}
                                        onChange={(e) => {
                                            const checked = (traffAddOns.includes('mostVisitedPlacesData') && traffAddOns.includes('busyTimesOfDay') && traffAddOns.includes('accessibilityAndEngagement'));
                                            const values = ['mostVisitedPlacesData', 'busyTimesOfDay', 'accessibilityAndEngagement'];
                                            let reportTag = checked ? [] : [...values];
                                            this.setState({ traffAddOns: reportTag });
                                        }}
                                    />}
                                    label="All Traffic Data"
                                    labelPlacement="end"
                                />
                                {traffChildren}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ backgroundColor: 'snow', marginTop: 10, marginBottom: 10, width: 450, marginLeft: 'auto', marginRight: 'auto' }}>
                        <AccordionSummary
                            expandIcon={<MdExpandMore style={{ width: 30, height: 30 }} />}
                            style={{ width: '95%' }}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h5 className='filterType'>Demographic Data</h5>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -20 }}>
                            <div style={{ width: 400, paddingLeft: 10 }}>
                                <FormControlLabel
                                    value={this.state.allDemoData}
                                    control={<Checkbox 
                                        checked={(demoAddOns.includes('incomeData') && demoAddOns.includes('populationData') && demoAddOns.includes('residentialREData'))}
                                        onChange={(e) => {
                                            const checked = (demoAddOns.includes('incomeData') && demoAddOns.includes('populationData') && demoAddOns.includes('residentialREData'));
                                            const values = ['incomeData', 'populationData', 'residentialREData'];
                                            let reportTag = checked ? [] : [...values];
                                            this.setState({ demoAddOns: reportTag });
                                        }}
                                    />}
                                    label="All Demographic Data"
                                    labelPlacement="end"
                                />
                                {demoChildren}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ backgroundColor: 'snow', marginTop: 10, marginBottom: 10, width: 450, marginLeft: 'auto', marginRight: 'auto' }}>
                        <AccordionSummary
                            expandIcon={<MdExpandMore style={{ width: 30, height: 30 }} />}
                            style={{ width: '95%' }}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h5 className='filterType'>Competition Data</h5>
                        </AccordionSummary>
                        <AccordionDetails style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: -20 }}>
                            <div style={{ width: 400, paddingLeft: 10 }}>
                                <FormControlLabel
                                    value={this.state.allTrafficData}
                                    control={<Checkbox 
                                        checked={(compAddOns.includes('popularBizData') && compAddOns.includes('businessDensityAndCommonBizTypes') && compAddOns.includes('highEndData'))}
                                        onChange={(e) => {
                                            const checked = (compAddOns.includes('popularBizData') && compAddOns.includes('businessDensityAndCommonBizTypes') && compAddOns.includes('highEndData'));
                                            const values = ['popularBizData', 'businessDensityAndCommonBizTypes', 'highEndData'];
                                            let reportTag = checked ? [] : [...values];
                                            this.setState({ compAddOns: reportTag });
                                        }}
                                    />}
                                    label="All Competition Data"
                                    labelPlacement="end"
                                />
                                {compChildren}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className='reportConfirmationArea'>
                    <div style={{ textAlign: 'center', paddingBottom: 25 }}>
                        <h5 style={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: 15, marginTop: 20, fontWeight: 500 }}>3). Confirm Report Components</h5>
                    </div>
                    <Card style={{ backgroundColor: 'snow', width: 475, height: 600, marginLeft: 'auto', marginRight: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', overflowY: 'scroll', boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)' }}>
                        <CardContent style={{ width: 475, height: 600 }}>
                                <div>
                                    <List>
                                        {this.state.summaryPage === true ? <ListItem><ListItemIcon>
                                            <MdOutlineCheckCircle style={{ width: 25, height: 25, color: 'darkgreen' }} />
                                        </ListItemIcon>Property Summary</ListItem> : <ListItem><ListItemIcon>
                                            <MdOutlineCancel style={{ width: 25, height: 25, color: 'darkred' }} />
                                        </ListItemIcon>No Property Summary</ListItem>}
                                    </List>
                                </div>
                                <hr />
                                <div>
                                    <List>
                                        {traffAddOns.length ? traffAddOns.map((filter) => (
                                            <ListItem style={{ fontSize: 14 }}><ListItemIcon>
                                                <MdOutlineCheckCircle style={{ width: 25, height: 25, color: 'darkgreen' }} />
                                            </ListItemIcon>{filterLabels(filter)}</ListItem>
                                        )) : <ListItem><ListItemIcon>
                                        <MdOutlineCancel style={{ width: 25, height: 25, color: 'darkred' }} />
                                    </ListItemIcon>No Traffic Data</ListItem>}
                                    </List>
                                </div>
                                <hr />
                                <div>
                                    <List>
                                        {demoAddOns.length ? demoAddOns.map((filter) => (
                                            <ListItem style={{ fontSize: 14 }}><ListItemIcon>
                                                <MdOutlineCheckCircle style={{ width: 25, height: 25, color: 'darkgreen' }} />
                                            </ListItemIcon>{filterLabels(filter)}</ListItem>
                                        )) : <ListItem><ListItemIcon>
                                        <MdOutlineCancel style={{ width: 25, height: 25, color: 'darkred' }} />
                                    </ListItemIcon>No Demographic Data</ListItem>}
                                    </List>
                                </div>
                                <hr />
                                <div>
                                    <List>
                                        {compAddOns.length ? compAddOns.map((filter) => (
                                            <ListItem style={{ fontSize: 14 }}><ListItemIcon>
                                                <MdOutlineCheckCircle style={{ width: 25, height: 25, color: 'darkgreen' }} />
                                            </ListItemIcon>{filterLabels(filter)}</ListItem>
                                        )) : <ListItem><ListItemIcon>
                                        <MdOutlineCancel style={{ width: 25, height: 25, color: 'darkred' }} />
                                    </ListItemIcon>No Competition Data</ListItem>}
                                    </List>
                                </div>
                        </CardContent>
                    </Card>
                    {(this.state.noDataAlert) ? (
                        <div style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Stack sx={{ width: '50%' }} spacing={2}><Alert variant="outlined" severity="error">Report is empty. Please select at least one (1) component and at least one (1) listing.</Alert></Stack>
                        </div>
                    ) : null}
                    <div style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button id='createReportBtn' variant='contained' style={{ backgroundColor: 'rgb(21, 134, 84)' }} onClick={() => this.onSubmit()}>Create Report</Button>
                    </div>
                </div>
            </div>
        )
    }
}

CreateReportInput.propTypes = {
    getListings: PropTypes.func.isRequired,
    listings: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    listings: state.listing.listings,
    thisListing: state.listing.thisListing,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});

export default connect(mapStateToProps, { getListings, getListing, loadUser, updateUser })(CreateReportInput);