import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardActions, CardContent, Button, List, ListItem, Switch } from '@material-ui/core';
import { IconButton, Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import { IoTrashOutline } from 'react-icons/io5';
import { IoMdMore } from 'react-icons/io';
import { FiEdit } from 'react-icons/fi';
import { connect } from 'react-redux';
import { loadUser } from '../../actions/authActions';
import PropTypes from 'prop-types';


class ConfirmFilters extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            values: [],
            dealBreakerTitles: [],
            dealBreakerValues: [],
            editMode: false,
            moreTooltip: false,
            saveThisSearch: false
        }
    }

    componentDidMount() {
        const { values } = this.props;
        this.props.loadUser();
        this.setState({ values: values });
    }
    
    continue = e => {
        this.props.nextStep();
    }

    back = e => {
        this.props.prevStep();
    }

    editFilters = (values) => {
        this.props.setFilters('propertyFilters', values.propertyFilters);
        this.props.setFilters('trafficFilters', values.trafficFilters);
        this.props.setFilters('compFilters', values.compFilters);
        this.props.setFilters('demoFilters', values.demoFilters);
        this.setState({ editMode: false });
    }

    editBadBizList = (values) => {
        this.props.setFilters('badBiz', values.badBiz);
        this.setState({ editMode: false });
    }

    editGoodBizList = (values) => {
        this.props.setFilters('goodBiz', values.goodBiz);
        this.setState({ editMode: false });
    }

    render() { 
        
        const { values, setSaveThisSearch } = this.props;

        const filterLabels = (label) => {    
    
            switch(label) {
                case "nearAttraction":
                    return (
                        "Tourists / Near Major Attraction"
                    )
                case "seasonal":
                    return ( 
                        "Seasonal"
                    )
                case "busyDayTime":
                    return ( 
                        "Heavy Daytime Traffic"
                    )
                case "busyMonThruFri":
                    return (
                        "Heavy Mon-Fri Traffic"
                    )
                case "busyWeekend":
                    return ( 
                        "Heavy Weekend Traffic"
                    )
                case "busyNightLife":
                    return ( 
                        "Heavy Nightlife"
                    )
                case "localResidents":
                    return (
                        "Mostly Local Resident"
                    )
                case "officeWorkers":
                    return ( 
                        "Mostly People at Work"
                    )
                case "students":
                    return (
                        "Students"
                    )
                case "highIncomeArea":
                    return ( 
                        "High Income Area"
                    )
                case "genZ":
                    return ( 
                        "Gen Z"
                    )
                case "millennialsTo34":
                    return ( 
                        "Young Millennials"
                    )
                case "millennialsTo44":
                    return ( 
                        "Older Millennials"
                    )
                case "genX":
                    return ( 
                        "Gen X"
                    )
                case "boomers":
                    return ( 
                        "Boomers"
                    )
                case "areasWithChildren":
                    return ( 
                        "Areas WITH Children"
                    )
                case "affordableRE":
                    return ( 
                        "Affordable"
                    )
                case "expensiveRE":
                    return ( 
                        "Expensive"
                    )
                case "highEndBiz":
                    return ( 
                        "$$$$"
                    )
                case "lowEndBiz":
                    return ( 
                        "$"
                    )
                case "highBizDensity":
                    return ( 
                        "High Business Density"
                    )
                case "lowBizDensity" :
                    return ( 
                        "Low Business Density"
                    )
                case "cornerLocation":
                    return ( 
                        "Corner Location"
                    )
                case "bigFrontage":
                    return ( 
                        "Big Frontage"
                    )
                case "divisible":
                    return ( 
                        "Divisible"
                    )
                case "multiLevel":
                    return ( 
                        "Multi Level"
                    )
                case "newConstruction":
                    return ( 
                        "New Construction"
                    )
                case "outdoorSpace":
                    return ( 
                        "Outdoor Space"
                    )
                default:
                    return null
            }
        }

        const FilterTagTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} />
            ))(({ theme }) => ({
                [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: 'white',
                fontWeight: 'bold',
                maxWidth: 150,
                fontSize: theme.typography.pxToRem(12),
                padding: 10,
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                },
        }));

        let allFilters = values.trafficFilters.concat(values.demoFilters, values.compFilters, values.propertyFilters);

        const dealBreakers = this.state.dealBreakerValues && this.state.dealBreakerValues.length && this.state.dealBreakerValues.map((dealBreaker) => dealBreaker);
        const finalDealBreakers = dealBreakers && dealBreakers.length && [...new Set([...dealBreakers])];
        values.dealBreakers = finalDealBreakers;

        return (
            <div style={{ backgroundColor: 'whitesmoke', height: 900, paddingTop: 40 }}>
                <Card className='customCardBackgroundColor' style={{ width: 1200, minHeight: 600, margin: 'auto', overflowY: 'scroll' }}>
                    <CardContent style={{ position: 'relative' }}>
                        <h2 style={{ textAlign: 'center', marginTop: 20, marginBottom: 20, paddingBottom: 25 }}>Review & Confirm</h2>
                        <div style={{ height: 400, width: 1150, marginLeft: 'auto', marginRight: 'auto', marginTop: 10, textAlign: 'left' }}>
                            <div style={{ width: '100%', marginTop: 40, textAlign: 'left', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 15 }}>
                                <div>
                                    <div>
                                        <Button style={{ color: 'darkgreen', textDecoration: 'underline', textUnderlineOffset: 4, fontWeight: 'bold', fontSize: 16 }} variant="text" onClick={() => this.props.goToPage(1)} endIcon={<FiEdit style={{ color: 'black', width: '75%', height: '75%' }} />}>
                                            Your Business Type
                                        </Button>
                                        <List>
                                            <ListItem>{values.userBizType2}</ListItem>
                                        </List>
                                    </div>
                                    <div>
                                        <Button style={{ color: 'darkgreen', textDecoration: 'underline', textUnderlineOffset: 4, fontWeight: 'bold', fontSize: 16 }} variant="text" onClick={() => this.props.goToPage(2)} endIcon={<FiEdit style={{ color: 'black', width: '75%', height: '75%' }} />}>
                                            Price & Size
                                        </Button>
                                        <List>
                                            <div style={{ display: 'grid', gridTemplateColumns: '5fr 2fr' }}>
                                                <div>
                                                    <ListItem style={{ fontSize: 12, fontWeight: 'bold', color: 'darkgreen' }}>Min SF: <div style={{ fontSize: 16, marginLeft: 5, fontWeight: 500, color: 'black' }}>{values.minSize}</div></ListItem>
                                                </div>
                                                <div style={{ height: '100%', width: '100%' }}>
                                                    <IconButton 
                                                        onClick={(e) => {
                                                            const value = {dealBreakTitle: 'minSize', dealBreakValue: values.minSize};
                                                            const filtTitle = 'minSize';
                                                            const checked = this.state.dealBreakerTitles && this.state.dealBreakerTitles.length && this.state.dealBreakerTitles.includes('minSize');
                                                            let filterTitle = checked ? this.state.dealBreakerTitles.filter((tag) => tag !== filtTitle) : [...this.state.dealBreakerTitles, filtTitle]
                                                            let filterTag = checked ? this.state.dealBreakerValues.filter((dBValue) => dBValue.dealBreakTitle !== value.dealBreakTitle) : [...this.state.dealBreakerValues, value]
                                                            this.setState({ dealBreakerValues: filterTag, dealBreakerTitles: filterTitle });
                                                        }}
                                                        style={{ width: '100%', height: '100%', outline: 'none' }} 
                                                    >
                                                        {this.state.dealBreakerTitles && this.state.dealBreakerTitles.length && this.state.dealBreakerTitles.includes('minSize') ? <AiFillStar style={{ color: 'rgb(228, 114, 103)' }} /> : <AiOutlineStar style={{ color: 'rgb(228, 114, 103)' }} />}
                                                    </IconButton>
                                                </div>
                                            </div>
                                            <div style={{ display: 'grid', gridTemplateColumns: '5fr 2fr', marginTop: 5 }}>
                                                <div>
                                                    <ListItem style={{ fontSize: 12, fontWeight: 'bold', color: 'darkgreen' }}>Max SF: <div style={{ fontSize: 16, marginLeft: 5, fontWeight: 500, color: 'black' }}>{values.maxSize}</div></ListItem>
                                                </div>
                                                <div style={{ height: '100%', width: '100%' }}>
                                                    <IconButton onClick={(e) => {
                                                            const value = {dealBreakTitle: 'maxSize', dealBreakValue: values.maxSize};
                                                            const filtTitle = 'maxSize';
                                                            const checked = this.state.dealBreakerTitles && this.state.dealBreakerTitles.length && this.state.dealBreakerTitles.includes('maxSize');
                                                            let filterTitle = checked ? this.state.dealBreakerTitles.filter((tag) => tag !== filtTitle) : [...this.state.dealBreakerTitles, filtTitle]
                                                            let filterTag = checked ? this.state.dealBreakerValues.filter((dBValue) => dBValue.dealBreakTitle !== value.dealBreakTitle) : [...this.state.dealBreakerValues, value]
                                                            this.setState({ dealBreakerValues: filterTag, dealBreakerTitles: filterTitle });
                                                        }}
                                                        style={{ width: '100%', height: '100%', outline: 'none' }} 
                                                    >
                                                        {this.state.dealBreakerTitles && this.state.dealBreakerTitles.length && this.state.dealBreakerTitles.includes('maxSize') ? <AiFillStar style={{ color: 'rgb(228, 114, 103)' }} /> : <AiOutlineStar style={{ color: 'rgb(228, 114, 103)' }} />}
                                                    </IconButton>
                                                </div>
                                            </div>
                                            <div style={{ display: 'grid', gridTemplateColumns: '5fr 2fr', marginTop: 5 }}>
                                                <div>
                                                    <ListItem style={{ fontSize: 12, fontWeight: 'bold', color: 'darkgreen' }}>Budget: <div style={{ fontSize: 16, marginLeft: 5, fontWeight: 400, paddingRight: 5, color: 'black' }}>${`${parseFloat(values.price).toLocaleString("en-US")}`}</div> {`${(values.budgetType === 1) ? 'total / YEAR' : 'per sqft. / YEAR'}`}</ListItem>
                                                </div>
                                                <div style={{ height: '100%', width: '100%' }}>
                                                    <IconButton onClick={(e) => {
                                                            const value = {dealBreakTitle: 'price', dealBreakValue: values.price};
                                                            const filtTitle = 'price';
                                                            const checked = this.state.dealBreakerTitles && this.state.dealBreakerTitles.length && this.state.dealBreakerTitles.includes('price');
                                                            let filterTitle = checked ? this.state.dealBreakerTitles.filter((tag) => tag !== filtTitle) : [...this.state.dealBreakerTitles, filtTitle]
                                                            let filterTag = checked ? this.state.dealBreakerValues.filter((dBValue) => dBValue.dealBreakTitle !== value.dealBreakTitle) : [...this.state.dealBreakerValues, value]
                                                            this.setState({ dealBreakerValues: filterTag, dealBreakerTitles: filterTitle });
                                                        }}
                                                        style={{ width: '100%', height: '100%', outline: 'none' }} 
                                                    >
                                                        {this.state.dealBreakerTitles && this.state.dealBreakerTitles.length && this.state.dealBreakerTitles.includes('price') ? <AiFillStar style={{ color: 'rgb(228, 114, 103)' }} /> : <AiOutlineStar style={{ color: 'rgb(228, 114, 103)' }} />}
                                                    </IconButton>
                                                </div>
                                            </div>                                                    
                                        </List>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <Button style={{ color: 'darkgreen', textDecoration: 'underline', textUnderlineOffset: 4, fontWeight: 'bold', fontSize: 16 }} variant="text" onClick={() => this.props.goToPage(4)} endIcon={<FiEdit style={{ color: 'black', width: '75%', height: '75%' }} />}>
                                            Good Business Types
                                        </Button>
                                        <List style={{ maxHeight: 175, overflowY: 'scroll' }}>
                                            {values.goodBiz.length ? values.goodBiz.map((biz) => (
                                                <div style={{ display: 'grid', gridTemplateColumns: '5fr 1fr 1fr', marginTop: 5 }}>
                                                    <div>
                                                        <ListItem key={biz.label}>{biz.label}</ListItem>
                                                    </div>
                                                    <div style={{ height: '100%', width: '100%' }}>
                                                        <IconButton onClick={(e) => {
                                                                const value = {cat: 'goodBiz', dealBreakTitle: biz.label, dealBreakValue: biz};
                                                                const filtTitle = biz.label;
                                                                const checked = this.state.dealBreakerTitles && this.state.dealBreakerTitles.length && this.state.dealBreakerTitles.includes(biz.label);
                                                                let filterTitle = checked ? this.state.dealBreakerTitles.filter((tag) => tag !== filtTitle) : [...this.state.dealBreakerTitles, filtTitle]
                                                                let filterTag = checked ? this.state.dealBreakerValues.filter((dBValue) => dBValue.dealBreakTitle !== value.dealBreakTitle) : [...this.state.dealBreakerValues, value]
                                                                this.setState({ dealBreakerValues: filterTag, dealBreakerTitles: filterTitle });
                                                            }}
                                                            style={{ width: '100%', height: '100%', outline: 'none' }} 
                                                            key={biz.label}
                                                        >
                                                            {this.state.dealBreakerTitles && this.state.dealBreakerTitles.length && this.state.dealBreakerTitles.includes(biz.label) ? <AiFillStar style={{ color: 'rgb(228, 114, 103)' }} /> : <AiOutlineStar style={{ color: 'rgb(228, 114, 103)' }} />}
                                                        </IconButton>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <FilterTagTooltip arrow placement="right"
                                                            title={
                                                                <>
                                                                    <Button 
                                                                        startIcon={<IoTrashOutline />}
                                                                        onClick={(e) => {
                                                                            const { values } = this.state;
                                                                            let newGoodBizList = values.goodBiz.filter((goodBizFilt) => goodBizFilt !== biz);
                                                                            values.goodBiz = newGoodBizList;
                                                                            const value = {dealBreakTitle: biz.label, dealBreakValue: biz};
                                                                            const filtTitle = biz.label;
                                                                            const checked = this.state.dealBreakerTitles && this.state.dealBreakerTitles.length && this.state.dealBreakerTitles.includes(biz.label);
                                                                            let filterTitle = checked ? this.state.dealBreakerTitles.filter((tag) => tag !== filtTitle) : null;
                                                                            let filterTag = checked ? this.state.dealBreakerValues.filter((dBValue) => dBValue.dealBreakTitle !== value.dealBreakTitle) : null;
                                                                            this.setState({ dealBreakerValues: filterTag, dealBreakerTitles: filterTitle });
                                                                            this.editGoodBizList(this.state.values)
                                                                        }}
                                                                        key={biz.label}
                                                                        style={{ fontSize: 12, color: 'darkred' }}
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                </>
                                                            }
                                                        >
                                                            <IconButton key={biz.label} onClick={(e) => {this.setState({ moreTooltip: true })}}>
                                                                <IoMdMore />
                                                            </IconButton>
                                                        </FilterTagTooltip>
                                                    </div>
                                                </div>
                                            )) : <ListItem>None</ListItem>}
                                        </List>
                                    </div>
                                    <div style={{ marginTop: 20 }}>
                                        <Button style={{ color: 'darkred', textDecoration: 'underline', textUnderlineOffset: 4, fontWeight: 'bold', fontSize: 16 }} variant="text" onClick={() => this.props.goToPage(3)} endIcon={<FiEdit style={{ color: 'black', width: '75%', height: '75%' }} />}>
                                            Bad Business Types
                                        </Button>
                                        <List style={{ maxHeight: 175, overflowY: 'scroll' }}>
                                            {values.badBiz.length ? values.badBiz.map((biz) => (
                                                <div style={{ display: 'grid', gridTemplateColumns: '5fr 1fr 1fr', marginTop: 5 }}>
                                                    <div>
                                                        <ListItem key={biz.label}>{biz.label}</ListItem>
                                                    </div>
                                                    <div style={{ height: '100%', width: '100%' }}>
                                                        <IconButton onClick={(e) => {
                                                                const value = {cat: 'badBiz', dealBreakTitle: biz.label, dealBreakValue: biz};
                                                                const filtTitle = biz.label;
                                                                const checked = this.state.dealBreakerTitles && this.state.dealBreakerTitles.length && this.state.dealBreakerTitles.includes(biz.label);
                                                                let filterTitle = checked ? this.state.dealBreakerTitles.filter((tag) => tag !== filtTitle) : [...this.state.dealBreakerTitles, filtTitle]
                                                                let filterTag = checked ? this.state.dealBreakerValues.filter((dBValue) => dBValue.dealBreakTitle !== value.dealBreakTitle) : [...this.state.dealBreakerValues, value]
                                                                this.setState({ dealBreakerValues: filterTag, dealBreakerTitles: filterTitle });
                                                            }}
                                                            style={{ width: '100%', height: '100%', outline: 'none' }} 
                                                            key={biz.label}
                                                        >
                                                            {this.state.dealBreakerTitles && this.state.dealBreakerTitles.length && this.state.dealBreakerTitles.includes(biz.label) ? <AiFillStar style={{ color: 'rgb(228, 114, 103)' }} /> : <AiOutlineStar style={{ color: 'rgb(228, 114, 103)' }} />}
                                                        </IconButton>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <FilterTagTooltip arrow placement="right"
                                                            title={
                                                                <>
                                                                    <Button 
                                                                        startIcon={<IoTrashOutline />}
                                                                        onClick={(e) => {
                                                                            const { values } = this.state;
                                                                            let newBadBizList = values.badBiz.filter((badBizFilt) => badBizFilt !== biz);
                                                                            values.badBiz = newBadBizList;
                                                                            const value = {dealBreakTitle: biz.label, dealBreakValue: biz};
                                                                            const filtTitle = biz.label;
                                                                            const checked = this.state.dealBreakerTitles && this.state.dealBreakerTitles.length && this.state.dealBreakerTitles.includes(biz.label);
                                                                            let filterTitle = checked ? this.state.dealBreakerTitles.filter((tag) => tag !== filtTitle) : null;
                                                                            let filterTag = checked ? this.state.dealBreakerValues.filter((dBValue) => dBValue.dealBreakTitle !== value.dealBreakTitle) : null;
                                                                            this.setState({ dealBreakerValues: filterTag, dealBreakerTitles: filterTitle });
                                                                            this.editBadBizList(this.state.values)
                                                                        }}
                                                                        key={biz.label}
                                                                        style={{ fontSize: 12, color: 'darkred' }}
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                </>
                                                            }
                                                        >
                                                            <IconButton key={biz.label} onClick={(e) => {this.setState({ moreTooltip: true })}}>
                                                                <IoMdMore />
                                                            </IconButton>
                                                        </FilterTagTooltip>
                                                    </div>
                                                </div>
                                            )) : <ListItem>None</ListItem>}
                                        </List>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <Button style={{ color: 'darkgreen', textDecoration: 'underline', textUnderlineOffset: 4, fontWeight: 'bold', fontSize: 16 }} variant="text" onClick={() => this.props.goToPage(5)} endIcon={<FiEdit style={{ color: 'black', width: '75%', height: '75%' }} />}>
                                            Additional Filters
                                        </Button>
                                        <List style={{ maxHeight: 400, overflowY: 'scroll' }}>
                                            {allFilters.length ? allFilters.map((filter) => (
                                                <div style={{ display: 'grid', gridTemplateColumns: '5fr 1fr 1fr', marginTop: 5 }}>
                                                    <div>
                                                        <ListItem key={filter}>{filterLabels(filter)}</ListItem>
                                                    </div>
                                                    <div style={{ height: '100%', width: '100%' }}>
                                                        <IconButton onClick={(e) => {
                                                                const value = {dealBreakTitle: filter, dealBreakValue: filter};
                                                                const filtTitle = filter;
                                                                const checked = this.state.dealBreakerTitles && this.state.dealBreakerTitles.length && this.state.dealBreakerTitles.includes(filter);
                                                                let filterTitle = checked ? this.state.dealBreakerTitles.filter((tag) => tag !== filtTitle) : [...this.state.dealBreakerTitles, filtTitle]
                                                                let filterTag = checked ? this.state.dealBreakerValues.filter((dBValue) => dBValue.dealBreakTitle !== value.dealBreakTitle) : [...this.state.dealBreakerValues, value]
                                                                this.setState({ dealBreakerValues: filterTag, dealBreakerTitles: filterTitle });
                                                            }}
                                                            style={{ width: '100%', height: '100%', outline: 'none' }} 
                                                            key={filter}
                                                        >
                                                            {this.state.dealBreakerTitles && this.state.dealBreakerTitles.length && this.state.dealBreakerTitles.includes(filter) ? <AiFillStar style={{ color: 'rgb(228, 114, 103)' }} /> : <AiOutlineStar style={{ color: 'rgb(228, 114, 103)' }} />}
                                                        </IconButton>
                                                    </div>
                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                        <FilterTagTooltip arrow placement="right"
                                                            title={
                                                                <>
                                                                    <Button 
                                                                        startIcon={<IoTrashOutline />}
                                                                        onClick={(e) => {
                                                                            const { values } = this.state;
                                                                            let compFilts = values.compFilters.filter((comFilt) => comFilt !== filter);
                                                                            let trafficFilts = values.trafficFilters.filter((traffFilt) => traffFilt !== filter);
                                                                            let demoFilts = values.demoFilters.filter((demoFilt) => demoFilt !== filter);
                                                                            let propFilts = values.propertyFilters.filter((propFilt) => propFilt !== filter);
                                                                            values.compFilters = compFilts;
                                                                            values.trafficFilters = trafficFilts;
                                                                            values.demoFilters = demoFilts;
                                                                            values.propertyFilters = propFilts;
                                                                            const value = {dealBreakTitle: filter, dealBreakValue: filter};
                                                                            const filtTitle = filter;
                                                                            const checked = this.state.dealBreakerTitles && this.state.dealBreakerTitles.length && this.state.dealBreakerTitles.includes(filter);
                                                                            let filterTitle = checked ? this.state.dealBreakerTitles.filter((tag) => tag !== filtTitle) : null;
                                                                            let filterTag = checked ? this.state.dealBreakerValues.filter((dBValue) => dBValue.dealBreakTitle !== value.dealBreakTitle) : null;
                                                                            this.setState({ dealBreakerValues: filterTag, dealBreakerTitles: filterTitle });
                                                                            this.editFilters(this.state.values)
                                                                        }}
                                                                        key={filter}
                                                                        style={{ fontSize: 12, color: 'darkred' }}
                                                                    >
                                                                        Delete
                                                                    </Button>
                                                                </>
                                                            }
                                                        >
                                                            <IconButton key={filter} onClick={(e) => {this.setState({ moreTooltip: true })}}>
                                                                <IoMdMore />
                                                            </IconButton>
                                                        </FilterTagTooltip>
                                                    </div>
                                                </div>
                                            )) : <ListItem>None</ListItem>}
                                        </List>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 10, paddingBottom: 10 }}>
                            {this.state.dealBreakerTitles && this.state.dealBreakerTitles.length ?
                                <div>
                                    <div>{this.state.dealBreakerTitles && this.state.dealBreakerTitles.length && `(${this.state.dealBreakerTitles.length})`}</div>
                                </div>
                            : null}
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 5fr', width: '30%' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <AiFillStar style={{ color: 'rgb(228, 114, 103)', height: '75%', width: '75%' }} />
                                </div>
                                <div style={{ fontSize: 11, fontWeight: 'bold', marginLeft: 0 }}>
                                    MUST-HAVE: means a listing MUST have the selected characteristic(s) and thus will heavily impact the Fourcast Match scores.
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: 25, paddingBottom: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
                                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <Button variant="contained" color="default" size="medium" onClick={() => this.back()}>Back</Button>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <CardActions>
                                        <Link 
                                            style={{ textDecoration: "none" }}
                                            to={{
                                                pathname: '/listings',
                                                state: {
                                                    customSearchFilters: values
                                                }
                                            }}
                                        >
                                            <Button style={{ margin: 'auto 10px', backgroundColor: '#007FFF', color: 'white' }} variant="contained" size="large">Find Properties!</Button>
                                        </Link>
                                    </CardActions>
                                </div>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <Switch checked={values.saveThisSearch} onChange={setSaveThisSearch} defaultValue={values.saveThisSearch} color="primary" />
                                    </div>
                                    <div style={{ fontSize: 11, fontWeight: 'bold', width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        SAVE SEARCH: notify me when listings matching this search criteria become available.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
        );
    }
}

ConfirmFilters.propTypes = {
    loadUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
});
 

export default connect(mapStateToProps, { loadUser })(ConfirmFilters);

