import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import { Card, Fab } from '@material-ui/core';
import '../traffic/traffic.css';
import './summary.css';


class Summary extends Component {
    
    
    render() { 
        const { thisListing, scores } = this.props;

        const { scores: { POI: { poi1: { latitude: poi1Lat } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi2: { latitude: poi2Lat } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi3: { latitude: poi3Lat } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi1: { longitude: poi1Lon } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi2: { longitude: poi2Lon } = {}} = {}} = {}} = this.props;
        const { scores: { POI: { poi3: { longitude: poi3Lon } = {}} = {}} = {}} = this.props;


        const trafficFilterTags = [];
        const compFilterTags = [];
        const demoFilterTags = [];

        if (thisListing.filterTags) {
            for (let i = 0; i < thisListing.filterTags.length; i ++) {
                
                if (thisListing.filterTags[i].includes('nearAttraction')) {
                    trafficFilterTags.push({tagName: 'Near Major Attraction', tagDescription: 'This space is within a 250 meter radius (~5 min walk) of a major attraction, which can generate traffic and tourists in the area.'});
                    demoFilterTags.push({tagName: 'Tourist Area'});
                } else if (thisListing.filterTags[i].includes('busyDayTime')) {
                    trafficFilterTags.push({tagName: 'Busy Daytime', tagDescription: 'Traffic is high during the day, and drops at night.'});
                } else if (thisListing.filterTags[i].includes('busyMonThruFri')) {
                    trafficFilterTags.push({tagName: 'Busy Weekdays', tagDescription: 'Most traffic occurs during the weekdays, Monday-Friday.'});
                } else if (thisListing.filterTags[i].includes('busyWeekend')) {
                    trafficFilterTags.push({tagName: 'Busy Weekends', tagDescription: 'Traffic is high during the weekends, Friday-Sunday.'});
                } else if (thisListing.filterTags[i].includes('busyNightLife')) {
                    trafficFilterTags.push({tagName: 'Busy Nightlife', tagDescription: 'There are numerous nightlife spots nearby that are open late.'});
                } else if (thisListing.filterTags[i].includes('seasonal')) {
                    trafficFilterTags.push({tagName: 'Seasonal', tagDescription: 'Traffic here fluctuates depending on the season or time of year.'});
                } else if (thisListing.filterTags[i].includes('volatileTraffic')) {
                    trafficFilterTags.push({tagName: 'Volatile Traffic', tagDescription: 'Traffic increases and decreases dramatically depending on the time and day.'});
                } else if (thisListing.filterTags[i].includes('highEndBiz')) {
                    compFilterTags.push({tagName: 'High End Businesses', tagDescription: 'There are numerous high-end businesses nearby.'});
                } else if (thisListing.filterTags[i].includes('lowEndBiz')) {
                    compFilterTags.push({tagName: 'Affordable Businesses', tagDescription: 'There are mostly businesses with affordable product prices nearby.'});
                } else if (thisListing.filterTags[i].includes('highBizDensity')) {
                    compFilterTags.push({tagName: 'High Business Density', tagDescription: 'There is a high concentration of businesses in this area, which typically attracts more shoppers to the area.'});
                } else if (thisListing.filterTags[i].includes('lowBizDensity')) {
                    compFilterTags.push({tagName: 'Low Business Density', tagDescription: "There is a low concentration of businesses in this area, which typically won't attract as many shoppers to the area."});
                } else if (thisListing.filterTags[i].includes('localResidents')) {
                    demoFilterTags.push({tagName: 'Mostly Residential Area', tagDescription: 'This is a largely residential area.'});
                } else if (thisListing.filterTags[i].includes('officeWorkers')) {
                    demoFilterTags.push({tagName: 'Office Workers', tagDescription: 'A significant amount of people work in this area.'});
                } else if (thisListing.filterTags[i].includes('students')) {
                    demoFilterTags.push({tagName: 'Students', tagDescription: 'There is a relatively high concentration of students in the area, compared to most parts of the city.'});
                } else if (thisListing.filterTags[i].includes('highIncomeArea')) {
                    demoFilterTags.push({tagName: 'High Income Area', tagDescription: 'The average income levels and/or volume of people making higher incomes are higher here than most areas in the city.'});
                } else if (thisListing.filterTags[i].includes('affordableRE')) {
                    demoFilterTags.push({tagName: 'Affordable Living', tagDescription: 'Residential real estate prices in this area are typically more affordable than other neighborhoods in the city.'});
                } else if (thisListing.filterTags[i].includes('expensiveRE')) {
                    demoFilterTags.push({tagName: 'Expensive Living', tagDescription: 'Residential real estate prices in this area are typically more expensive than other neighborhoods in the city.'});
                } else if (thisListing.filterTags[i].includes('genZ')) {
                    demoFilterTags.push({tagName: 'Gen Z', tagDescription: 'There is a Gen Z population in this area.'});
                } else if (thisListing.filterTags[i].includes('millennialsTo34')) {
                    demoFilterTags.push({tagName: 'Young Millennials', tagDescription: 'There are young Millennials between 25-34 living here.'});
                } else if (thisListing.filterTags[i].includes('millennialsTo44')) {
                    demoFilterTags.push({tagName: 'Older Millennials', tagDescription: 'You can find older Millennials between 35-44 in this neighborhood.'});
                } else if (thisListing.filterTags[i].includes('genX')) {
                    demoFilterTags.push({tagName: 'Gen X', tagDescription: 'You can find more Gen X living here than many other neighborhoods.'});
                } else if (thisListing.filterTags[i].includes('boomers')) {
                    demoFilterTags.push({tagName: 'Boomers', tagDescription: 'There is a sizable population of Baby Boomers living here.'});
                } else if (thisListing.filterTags[i].includes('areasWithChildren')) {
                    demoFilterTags.push({tagName: 'Families with Children', tagDescription: 'You can find more families with children living here than many other neighborhoods.'});
                }
            }
        }

        const propFilterTags = [];

        if (thisListing.propertyFeatures) {
            for (let i = 0; i < thisListing.propertyFeatures.length; i ++) {
                
                if (thisListing.propertyFeatures[i].includes('cornerLocation')) {
                    propFilterTags.push({tagName: 'Corner Location', tagDescription: "This space is on a corner."});
                } else if (thisListing.propertyFeatures[i].includes('bigFrontage')) {
                    propFilterTags.push({tagName: 'Big Frontage', tagDescription: "This property has a relatively large frontage."});
                } else if (thisListing.propertyFeatures[i].includes('divisible')) {
                    propFilterTags.push({tagName: 'Divisible', tagDescription: "This space can be divided."});
                } else if (thisListing.propertyFeatures[i].includes('multiLevel')) {
                    propFilterTags.push({tagName: 'Multi Level', tagDescription: "This space has multiple floors."});
                } else if (thisListing.propertyFeatures[i].includes('newConstruction')) {
                    propFilterTags.push({tagName: 'New Construction', tagDescription: "This is a newly constructed space."});
                } else if (thisListing.propertyFeatures[i].includes('outdoorSpace')) {
                    propFilterTags.push({tagName: 'Outdoor Space', tagDescription: "This location has outdoor space."});
                }
            }
        }

        // this creates an array of all the tags which can be put together to create a paragraph that gives a description of the area in writing
        const notableCharacteristics = [...trafficFilterTags, ...compFilterTags, ...demoFilterTags, ...propFilterTags];


        return (
            <Container style={{ height: '100%', minWidth: 1500, margin: 'auto', paddingBottom: 100 }}>
                <div style={{ width: '100%', height: '100%', display: 'grid', gridTemplateColumns: '4fr 3fr 4fr', gap: 10 }}>
                    <div style={{ width: '95%', height: '100%', marginLeft: 10 }}>
                        <h5 style={{ margin: '10px 0px 10px 0px', paddingBottom: 10 }}>This space at a glance:</h5>
                        {notableCharacteristics.length ? notableCharacteristics.map(tag => (
                            tag.tagDescription !== undefined ? <span className='summaryDescription'>{`${tag.tagDescription} `}</span> : null
                        )) : (<p className='summaryDescription'>Tags represent certain characteristics that can be found in this area/property. For example, a "Busy Nightlife" tag indicates that the nightlife in the area highlights a big part of the area's overall dynamic and activity.</p>)}
                        <h6 style={{ margin: '30px 0px 10px 0px', paddingTop: 25 }}>Traffic:</h6>
                        <div className='trafficSummaryTagsArea'>
                            {trafficFilterTags.length ? trafficFilterTags.map(tag => (
                                <span><Fab
                                variant="extended"
                                size="small"
                                style={{ backgroundColor: 'rgb(52, 181, 122)', fontSize: 12, color: 'whitesmoke', marginLeft: 10, marginBottom: 10, outline: 'none', padding: 15 }}
                                >
                                {tag.tagName}
                                </Fab></span>
                            )) : (<p className='summaryDescription'>None</p>)}
                        </div>
                        <h6 style={{ margin: '10px 0px 10px 0px' }}>Demographics:</h6>
                        <div className='demographicsSummaryTagsArea'>
                            {demoFilterTags.length ? demoFilterTags.map(tag => (
                                <span><Fab
                                variant="extended"
                                size="small"
                                style={{ backgroundColor: 'rgb(52, 181, 122)', fontSize: 12, color: 'whitesmoke', marginLeft: 10, marginBottom: 10, outline: 'none', padding: 15 }}
                                >
                                {tag.tagName}
                                </Fab></span>
                            )) : (<p className='summaryDescription'>None</p>)}
                        </div>
                        <h6 style={{ margin: '10px 0px 10px 0px' }}>Competition:</h6>
                        <div className='competitionSummaryTagsArea'>
                            {compFilterTags.length ? compFilterTags.map(tag => (
                                <span><Fab
                                variant="extended"
                                size="small"
                                style={{ backgroundColor: 'rgb(52, 181, 122)', fontSize: 12, color: 'whitesmoke', marginLeft: 10, marginBottom: 10, outline: 'none', padding: 15 }}
                                >
                                {tag.tagName}
                                </Fab></span>
                            )) : (<p className='summaryDescription'>None</p>)}
                        </div>
                        <h6 style={{ margin: '10px 0px 10px 0px' }}>Property:</h6>
                        <div className='propertySummaryTagsArea'>
                            {propFilterTags.length ? propFilterTags.map(tag => (
                                <span><Fab
                                variant="extended"
                                size="small"
                                style={{ backgroundColor: 'rgb(52, 181, 122)', fontSize: 12, color: 'whitesmoke', marginLeft: 10, marginBottom: 10, outline: 'none', padding: 15 }}
                                >
                                {tag.tagName}
                                </Fab></span>
                            )) : (<p className='summaryDescription'>None</p>)}
                        </div>
                    </div>
                    <div style={{ width: '100%', height: '100%', margin: 'auto' }}>
                        <h5 style={{ margin: '10px 0px 10px 0px', paddingBottom: 10 }}>Scores:</h5>
                        <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto auto' }}>
                            <Card style={{ padding: '15px 35px', width: 200, height: 100 }}>
                                <Row style={{ height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <p className='summaryScoreTitle'>Fourcast Score</p>
                                </Row>
                                <Row style={{ height: 35, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h1 className='summaryCatScore'>{scores.fourcastScore}</h1>
                                </Row>
                            </Card>
                        </div>
                        <div style={{ width: '100%', margin: 'auto', display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                            <div style={{ height: 250 }}>
                                <div>
                                    <Card style={{ padding: '15px 30px', width: 175, height: 90, margin: '15px auto' }}>
                                        <Row style={{ height: 25, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <p className='summaryScoreTitle'>Traffic Score</p>
                                        </Row>
                                        <Row style={{ height: 35, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <h3 className='summaryCatScore'>{scores.trafficScore}</h3>
                                        </Row>
                                    </Card>
                                </div>
                                <div>
                                    <Card style={{ padding: '15px 30px', width: 175, height: 90, margin: '15px auto' }}>
                                        <Row style={{ height: 25, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <p className='summaryScoreTitle'>Demographics Score</p>
                                        </Row>
                                        <Row style={{ height: 35, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <h3 className='summaryCatScore'>{scores.demographicsScore}</h3>
                                        </Row>
                                    </Card>
                                </div>
                            </div>
                            <div style={{ height: 250 }}>
                                <div>
                                <Card style={{ padding: '15px 30px', width: 175, height: 90, margin: '15px auto' }}>
                                        <Row style={{ height: 25, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <p className='summaryScoreTitle'>Competition Score</p>
                                        </Row>
                                        <Row style={{ height: 35, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <h3 className='summaryCatScore'>{scores.competitionScore}</h3>
                                        </Row>
                                    </Card>
                                </div>
                                <div>
                                    <Card style={{ padding: '15px 30px', width: 175, height: 90, margin: '15px auto' }}>
                                        <Row style={{ height: 25, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <p className='summaryScoreTitle'>Property Score</p>
                                        </Row>
                                        <Row style={{ height: 35, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <h3 className='summaryCatScore'>{scores.propertyScore}</h3>
                                        </Row>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                        <h5 style={{ margin: '10px 0px 10px 0px', paddingBottom: 10 }}>Area of analysis:</h5>
                        <div className="staticMapSummary">
                            <img alt='static google map on summary page' src={`https://maps.googleapis.com/maps/api/staticmap?center=${thisListing.latitude},${thisListing.longitude}&zoom=16&scale=1&size=500x500&maptype=terrain&markers=color:eb2741cb%7C${thisListing.latitude},${thisListing.longitude}&markers=color:purple%7Clabel:1%7C${poi1Lat},${poi1Lon}&markers=color:purple%7Clabel:2%7C${poi2Lat},${poi2Lon}&markers=color:purple%7Clabel:3%7C${poi3Lat},${poi3Lon}&style=feature:poi.business|visibility:off&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`} />
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}

export default Summary;