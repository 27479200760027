import React, { Component } from 'react';
import { Paper, Table, List, ListItem } from '@mui/material';



class SummaryComparison extends Component {
    

    render() { 

        const { listings } = this.props;

        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.scores.fourcastScore - a.scores.fourcastScore});
        
        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }

        

        let thisListingsTags = (thisListing) => {
            const trafficFilterTags = [];
            const compFilterTags = [];
            const demoFilterTags = [];
            const propFilterTags = [];
            for (let i = 0; i < thisListing.filterTags.length; i ++) {
                if (thisListing.filterTags[i].includes('nearAttraction')) {
                    trafficFilterTags.push({tagName: 'Near Major Attraction'});
                    demoFilterTags.push({tagName: 'Tourist Area'});
                } else if (thisListing.filterTags[i].includes('busyDayTime')) {
                    trafficFilterTags.push({tagName: 'Busy Daytime'});
                } else if (thisListing.filterTags[i].includes('busyMonThruFri')) {
                    trafficFilterTags.push({tagName: 'Busy Weekdays'});
                } else if (thisListing.filterTags[i].includes('busyWeekend')) {
                    trafficFilterTags.push({tagName: 'Busy Weekends'});
                } else if (thisListing.filterTags[i].includes('busyNightLife')) {
                    trafficFilterTags.push({tagName: 'Busy Nightlife'});
                } else if (thisListing.filterTags[i].includes('seasonal')) {
                    trafficFilterTags.push({tagName: 'Seasonal'});
                } else if (thisListing.filterTags[i].includes('volatileTraffic')) {
                    trafficFilterTags.push({tagName: 'Volatile Traffic'});
                } else if (thisListing.filterTags[i].includes('highEndBiz')) {
                    compFilterTags.push({tagName: 'High End Businesses'});
                } else if (thisListing.filterTags[i].includes('lowEndBiz')) {
                    compFilterTags.push({tagName: 'Affordable Businesses'});
                } else if (thisListing.filterTags[i].includes('highBizDensity')) {
                    compFilterTags.push({tagName: 'High Business Density'});
                } else if (thisListing.filterTags[i].includes('lowBizDensity')) {
                    compFilterTags.push({tagName: 'Low Business Density'});
                } else if (thisListing.filterTags[i].includes('localResidents')) {
                    demoFilterTags.push({tagName: 'Mostly Residential Area'});
                } else if (thisListing.filterTags[i].includes('officeWorkers')) {
                    demoFilterTags.push({tagName: 'Office Workers'});
                } else if (thisListing.filterTags[i].includes('students')) {
                    demoFilterTags.push({tagName: 'Students'});
                } else if (thisListing.filterTags[i].includes('highIncomeArea')) {
                    demoFilterTags.push({tagName: 'High Income Area'});
                } else if (thisListing.filterTags[i].includes('affordableRE')) {
                    demoFilterTags.push({tagName: 'Affordable Living'});
                } else if (thisListing.filterTags[i].includes('expensiveRE')) {
                    demoFilterTags.push({tagName: 'Expensive Living'});
                } else if (thisListing.filterTags[i].includes('genZ')) {
                    demoFilterTags.push({tagName: 'Gen Z'});
                } else if (thisListing.filterTags[i].includes('millennialsTo34')) {
                    demoFilterTags.push({tagName: 'Young Millennials'});
                } else if (thisListing.filterTags[i].includes('millennialsTo44')) {
                    demoFilterTags.push({tagName: 'Older Millennials'});
                } else if (thisListing.filterTags[i].includes('genX')) {
                    demoFilterTags.push({tagName: 'Gen X'});
                } else if (thisListing.filterTags[i].includes('boomers')) {
                    demoFilterTags.push({tagName: 'Boomers'});
                } else if (thisListing.filterTags[i].includes('areasWithChildren')) {
                    demoFilterTags.push({tagName: 'Families with Children'});
                }
            }
            
            
            for (let i = 0; i < thisListing.propertyFeatures.length; i ++) {
                
                if (thisListing.propertyFeatures[i].includes('cornerLocation')) {
                    propFilterTags.push({tagName: 'Corner Location'});
                } else if (thisListing.propertyFeatures[i].includes('bigFrontage')) {
                    propFilterTags.push({tagName: 'Big Frontage'});
                } else if (thisListing.propertyFeatures[i].includes('divisible')) {
                    propFilterTags.push({tagName: 'Divisible'});
                } else if (thisListing.propertyFeatures[i].includes('multiLevel')) {
                    propFilterTags.push({tagName: 'Multi Level'});
                } else if (thisListing.propertyFeatures[i].includes('newConstruction')) {
                    propFilterTags.push({tagName: 'New Construction'});
                } else if (thisListing.propertyFeatures[i].includes('outdoorSpace')) {
                    propFilterTags.push({tagName: 'Outdoor Space'});
                }
            }

            let tagsObj = {traff: trafficFilterTags, demo: demoFilterTags, comp: compFilterTags, prop: propFilterTags};
            return tagsObj;
        }

        
        let displayFilterTags = (tags) => {
            return (
                <>
                    {tags.length ? tags.map(tag => 
                        (<List style={{ padding: 0 }}>
                            <ListItem style={{ padding: 0 }}>
                                -{tag.tagName}
                            </ListItem>
                        </List>)) 
                    : (<ListItem>None</ListItem>)}
                </>
            )
        }
        
        let filteredListingNeighborhood = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            return (
                <td style={{ fontSize: 14, padding: 20 }}>
                    {listing && listing.neighborhood}
                </td>
            )
        });
        let filteredListingSizeData = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            return (
                <td style={{ fontSize: 14, padding: 20 }}>
                    {(listing.squareFeet.length > 1) ? `${listing.squareFeet[0]} - ${listing.squareFeet[1]}` : `${listing.squareFeet}`}
                </td>
            )
        });
        let filteredListingPriceData = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            return (
                <td style={{ fontSize: 14, padding: 20 }}>
                    {listing.pricePerSquareFoot !== -1 ? `$${listing.pricePerSquareFoot} / yr` : 'Undisclosed'}
                </td>
            )
        });
        let filteredListingTrafficTags = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsTraffTags = thisListingsTags(listing);
            return (
                <td style={{ fontSize: 14, padding: 20 }}>
                    {thisListingsTraffTags && thisListingsTraffTags.traff && displayFilterTags(thisListingsTraffTags.traff)}
                </td>
            )
        });
        let filteredListingDemoTags = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsDemoTags = thisListingsTags(listing);
            return (
                <td style={{ fontSize: 14, padding: 20 }}>
                    {thisListingsDemoTags && thisListingsDemoTags.demo && displayFilterTags(thisListingsDemoTags.demo)}
                </td>
            )
        });
        let filteredListingCompTags = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsCompTags = thisListingsTags(listing);
            return (
                <td style={{ fontSize: 14, padding: 20 }}>
                    {thisListingsCompTags && thisListingsCompTags.comp && displayFilterTags(thisListingsCompTags.comp)}
                </td>
            )
        });
        let filteredListingPropTags = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsPropTags = thisListingsTags(listing);
            return (
                <td style={{ fontSize: 14, padding: 20 }}>
                    {thisListingsPropTags && thisListingsPropTags.prop && displayFilterTags(thisListingsPropTags.prop)}
                </td>
            )
        });
        
        
        return (
            <div style={{ position: 'relative', width: '98%' }}>
                <div>
                    <div>
                        <Table style={{ tableLayout: 'fixed' }}>
                            <thead>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th className='categoryCol'></th>
                                    {sortedByFourcastScore && sortedByFourcastScore.map(listing => {return (<th style={{ padding: 20, color: 'darkgreen' }}>{addressStyle(listing.listingAddress)}</th>)})}
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th className='categoryCol' style={{ fontSize: 14 }}>
                                        Neighborhood
                                    </th>
                                    {filteredListingNeighborhood}
                                </tr>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th className='categoryCol' style={{ fontSize: 14 }}>
                                        Square Feet
                                    </th>
                                    {filteredListingSizeData}
                                </tr>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th className='categoryCol' style={{ fontSize: 14 }}>
                                        Price
                                    </th>
                                    {filteredListingPriceData}
                                </tr>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th className='categoryCol' style={{ fontSize: 14 }}>
                                        Traffic Tags
                                    </th>
                                    {filteredListingTrafficTags}
                                </tr>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th className='categoryCol' style={{ fontSize: 14 }}>
                                        Demographics Tags
                                    </th>
                                    {filteredListingDemoTags}
                                </tr>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th className='categoryCol' style={{ fontSize: 14 }}>
                                        Competition Tags
                                    </th>
                                    {filteredListingCompTags}
                                </tr>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th className='categoryCol' style={{ fontSize: 14 }}>
                                        Property Features
                                    </th>
                                    {filteredListingPropTags}
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}

export default SummaryComparison;