import React, { Component } from 'react';
import 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import { Table } from '@mui/material';


class ScoreComparison extends Component {
    

    render() { 

        const { listings } = this.props;

        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.scores.fourcastScore - a.scores.fourcastScore});
        
        let listing1 = sortedByFourcastScore && sortedByFourcastScore.length && (sortedByFourcastScore[0] !== undefined) ? sortedByFourcastScore[0] : null;
        let listing2 = sortedByFourcastScore && sortedByFourcastScore.length && (sortedByFourcastScore[1] !== undefined) ? sortedByFourcastScore[1] : null;
        let listing3 = sortedByFourcastScore && sortedByFourcastScore.length && (sortedByFourcastScore[2] !== undefined) ? sortedByFourcastScore[2] : null;
        let listing4 = sortedByFourcastScore && sortedByFourcastScore.length && (sortedByFourcastScore[3] !== undefined) ? sortedByFourcastScore[3] : null;
        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }
        

        const thisData = ((listing1 !== null) && (listing2 !== null) && (listing3 === null) && (listing4 === null)) ? {
                        labels: [
                          "Fourcast Score",
                          "Traffic",
                          "Demographics",
                          "Competition",
                          "Property"
                        ],
                        datasets: [
                          {
                            label: `${listing1 && addressStyle(listing1.listingAddress)}`,
                            backgroundColor: "rgb(52, 181, 122)",
                            data: [
                                `${listing1 && listing1.scores && listing1.scores.fourcastScore}`,
                                `${listing1 && listing1.scores && listing1.scores.trafficScore}`,
                                `${listing1 && listing1.scores && listing1.scores.demographicsScore}`,
                                `${listing1 && listing1.scores && listing1.scores.competitionScore}`,
                                `${listing1 && listing1.scores && listing1.scores.propertyScore}`,
                            ]
                          },
                          {
                            label: `${listing2 && addressStyle(listing2.listingAddress)}`,
                            backgroundColor: "rgba(223, 82, 69, .8)",
                            data: [
                                `${listing2 && listing2.scores && listing2.scores.fourcastScore}`,
                                `${listing2 && listing2.scores && listing2.scores.trafficScore}`,
                                `${listing2 && listing2.scores && listing2.scores.demographicsScore}`,
                                `${listing2 && listing2.scores && listing2.scores.competitionScore}`,
                                `${listing2 && listing2.scores && listing2.scores.propertyScore}`,
                            ]
                          }
                        ]
                    } : ((listing1 !== null) && (listing2 !== null) && (listing3 !== null) && (listing4 === null)) ? {
                        labels: [
                          "Fourcast Score",
                          "Traffic",
                          "Demographics",
                          "Competition",
                          "Property"
                        ],
                        datasets: [
                          {
                            label: `${listing1 && addressStyle(listing1.listingAddress)}`,
                            backgroundColor: "rgb(52, 181, 122)",
                            data: [
                                `${listing1 && listing1.scores && listing1.scores.fourcastScore}`,
                                `${listing1 && listing1.scores && listing1.scores.trafficScore}`,
                                `${listing1 && listing1.scores && listing1.scores.demographicsScore}`,
                                `${listing1 && listing1.scores && listing1.scores.competitionScore}`,
                                `${listing1 && listing1.scores && listing1.scores.propertyScore}`,
                            ]
                          },
                          {
                            label: `${listing2 && addressStyle(listing2.listingAddress)}`,
                            backgroundColor: "rgba(223, 82, 69, .8)",
                            data: [
                                `${listing2 && listing2.scores && listing2.scores.fourcastScore}`,
                                `${listing2 && listing2.scores && listing2.scores.trafficScore}`,
                                `${listing2 && listing2.scores && listing2.scores.demographicsScore}`,
                                `${listing2 && listing2.scores && listing2.scores.competitionScore}`,
                                `${listing2 && listing2.scores && listing2.scores.propertyScore}`,
                            ]
                          },
                          {
                            label: `${listing3 && addressStyle(listing3.listingAddress)}`,
                            backgroundColor: "rgba(250, 204, 68, .8)",
                            data: [
                                `${listing3 && listing3.scores && listing3.scores.fourcastScore}`,
                                `${listing3 && listing3.scores && listing3.scores.trafficScore}`,
                                `${listing3 && listing3.scores && listing3.scores.demographicsScore}`,
                                `${listing3 && listing3.scores && listing3.scores.competitionScore}`,
                                `${listing3 && listing3.scores && listing3.scores.propertyScore}`,
                            ]
                          }
                        ]
                    } : ((listing1 !== null) && (listing2 !== null) && (listing3 !== null) && (listing4 !== null)) ? {
                        labels: [
                          "Fourcast Score",
                          "Traffic",
                          "Demographics",
                          "Competition",
                          "Property"
                        ],
                        datasets: [
                          {
                            label: `${listing1 && addressStyle(listing1.listingAddress)}`,
                            backgroundColor: "rgb(52, 181, 122)",
                            data: [
                                `${listing1 && listing1.scores && listing1.scores.fourcastScore}`,
                                `${listing1 && listing1.scores && listing1.scores.trafficScore}`,
                                `${listing1 && listing1.scores && listing1.scores.demographicsScore}`,
                                `${listing1 && listing1.scores && listing1.scores.competitionScore}`,
                                `${listing1 && listing1.scores && listing1.scores.propertyScore}`,
                            ]
                          },
                          {
                            label: `${listing2 && addressStyle(listing2.listingAddress)}`,
                            backgroundColor: "rgba(223, 82, 69, .8)",
                            data: [
                                `${listing2 && listing2.scores && listing2.scores.fourcastScore}`,
                                `${listing2 && listing2.scores && listing2.scores.trafficScore}`,
                                `${listing2 && listing2.scores && listing2.scores.demographicsScore}`,
                                `${listing2 && listing2.scores && listing2.scores.competitionScore}`,
                                `${listing2 && listing2.scores && listing2.scores.propertyScore}`,
                            ]
                          },
                          {
                            label: `${listing3 && addressStyle(listing3.listingAddress)}`,
                            backgroundColor: "rgba(250, 204, 68, .8)",
                            data: [
                                `${listing3 && listing3.scores && listing3.scores.fourcastScore}`,
                                `${listing3 && listing3.scores && listing3.scores.trafficScore}`,
                                `${listing3 && listing3.scores && listing3.scores.demographicsScore}`,
                                `${listing3 && listing3.scores && listing3.scores.competitionScore}`,
                                `${listing3 && listing3.scores && listing3.scores.propertyScore}`,
                            ]
                          },
                          {
                            label: `${listing4 && addressStyle(listing4.listingAddress)}`,
                            backgroundColor: "rgba(30, 144, 255, .8)",
                            data: [
                                `${listing4 && listing4.scores && listing4.scores.fourcastScore}`,
                                `${listing4 && listing4.scores && listing4.scores.trafficScore}`,
                                `${listing4 && listing4.scores && listing4.scores.demographicsScore}`,
                                `${listing4 && listing4.scores && listing4.scores.competitionScore}`,
                                `${listing4 && listing4.scores && listing4.scores.propertyScore}`,
                            ]
                          }
                        ]
                    } : {
                        labels: [
                          "Fourcast Score",
                          "Traffic",
                          "Demographics",
                          "Competition",
                          "Property"
                        ],
                        datasets: [
                          {
                            label: `${listing1 && addressStyle(listing1.listingAddress)}`,
                            backgroundColor: "rgb(52, 181, 122)",
                            data: [
                                `${listing1 && listing1.scores && listing1.scores.fourcastScore}`,
                                `${listing1 && listing1.scores && listing1.scores.trafficScore}`,
                                `${listing1 && listing1.scores && listing1.scores.demographicsScore}`,
                                `${listing1 && listing1.scores && listing1.scores.competitionScore}`,
                                `${listing1 && listing1.scores && listing1.scores.propertyScore}`,
                            ]
                          }
                        ]
                    }
        if (listing1) {listing1.color = 'rgba(52, 181, 122, .2)'};
        if (listing2) {listing2.color = 'rgba(223, 82, 69, .2)'};
        if (listing3) {listing3.color = 'rgba(250, 204, 68, .2)'};
        if (listing4) {listing4.color = 'rgba(30, 144, 255, .2)'};

        return (
            <div style={{ height: 300, width: '98%' }}>
              <div style={{ width: '90%', margin: 'auto auto', paddingTop: 50, paddingBottom: 100 }}>
                    <Table style={{ tableLayout: 'fixed' }}>
                        <tbody>
                            {sortedByFourcastScore && sortedByFourcastScore.map(listing => 
                                <tr style={{ border: '1px solid lightgray', textAlign: 'center' }}>
                                    <td style={{ fontSize: 18, padding: 20, backgroundColor: listing.color }}>
                                        {listing && addressStyle(listing && listing.listingAddress)}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
                <Bar
                    data={thisData}
                    options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                          datalabels: {
                            display: false
                          }
                        },
                        legend: {
                          position: "top"
                        },
                        title: {
                          display: true,
                          text: "Chart.js Bar Chart"
                        },
                        layout: {
                            padding: {
                                top: 10
                            }
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    suggestedMin: 50,
                                    max: 100
                                }
                            }],
                            y : {
                                min: 50,
                                max: 100
                            }
                        }
                    }}
                />
            </div>
        );
    }
}

export default ScoreComparison;