import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getListings, getListing } from '../../actions/listingActions';
import { loadUser, updateUser } from '../../actions/authActions';
import PropTypes from 'prop-types';
import './createReport.css';
import CreateReportWelcomePage from './createReportWelcome';
import CreateReportInput from './createReportInput';
import CreateReportOutput from './createReportOutput';
import SkeletonDetailsPage from '../skeletons/skeletonDetailsPage';


class CreateReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            selected: [],
            selectedListingNum: 0,
            allListingInfo: [],
            coverPage: true,
            summaryPage: true,
            traffAddOns: ['mostVisitedPlacesData', 'busyTimesOfDay', 'accessibilityData', 'engagementData'],
            demoAddOns: ['incomeData', 'populationData', 'residentialREData'],
            compAddOns: ['businessDensity', 'highEndData', 'commonBizTypes', 'popularBizData'],
            requestedListing: [],
            streetViewImages: []
        }
    }
    
    async componentDidMount() {
        this.props.getLocation(this.props.location.pathname); 
        await this.props.loadUser(); 

        const { user } = this.props;
        
        if (user && this.props.location.state) {
            const { requestedListing } = this.props.location.state;
            if (requestedListing) {
                this.setState({ step: 1, requestedListing: [requestedListing] });
            }
        }
    }

    async componentDidUpdate(prevState, prevProps) {
        
        const { selected, allListingInfo, coverPage, streetViewImages } = this.state;
        const { thisListing } = this.props;
        
        
        if (selected.length !== allListingInfo.length) {
            
            if (!thisListing._id) {
                if (selected.length !== allListingInfo.length) {
                    this.getSelectedListings(selected);
                }
            }

            if (thisListing && thisListing._id) {
                if (selected.some(selectListing => selectListing.value === thisListing._id)) {
                    allListingInfo.push(thisListing);
                } else {
                    this.getSelectedListings(selected);
                }
            }
        }

        if (selected.length && allListingInfo.length && (selected.length === allListingInfo.length)) {
            if (coverPage && (coverPage === true)) {
                if (allListingInfo.length && (allListingInfo.length !== streetViewImages.length)) {
                    this.getStreetViewImages(allListingInfo)
                }
            }
        }
    }

    // FOURCAST REPORTS WELCOME PAGE - CHOOSE BETWEEN LISTING COMPARE OPTION AND LISTING DATA OPTION

    // Proceed to next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({ step: step + 1 });
    }

    // Go back to previous step
    prevStep = () => {
        const { step } = this.state;
        this.setState({ step: step - 1, selected: [], selectedListingNum: 0, allListingInfo: [] });
    }

    // Proceed to next listing
    nextListing = () => {
        const { selectedListingNum } = this.state;
        this.setState({ selectedListingNum: selectedListingNum + 1 });
    }

    // Go back to previous listing
    prevListing = () => {
        const { selectedListingNum } = this.state;
        this.setState({ selectedListingNum: selectedListingNum - 1 });
    }

    // Data fields selected to be in the report
    setReportComponents = (components) => {
        this.setState({ coverPage: components.coverPage, summaryPage: components.summaryPage, traffAddOns: components.traffAddOns, demoAddOns: components.demoAddOns, compAddOns: components.compAddOns });
    }

    // Select the listings to generate reports for
    setSelected = (selectedListings) => {
        this.setState({ selected: selectedListings });
    }

    // Get the selected listing's data
    getSelectedListings = async (selected) => {
    
        if (selected.length) {
            for (let i = 0; i < selected.length; i++) {
                try {
                    let listingId;
                    listingId = selected[i].value;
                    await this.props.getListing(listingId);
                } catch (err) {
                    console.log(err);
                }
            }
        }
    }

    getStreetViewImages = async (listingInfo) => {
        const { streetViewImages } = this.state;
        if (listingInfo.length) {
            for (let i = 0; i < listingInfo.length; i++) {
                let lat = listingInfo[i].latitude;
                let lon = listingInfo[i].longitude;
                const streetViewPic = {id: listingInfo[i]._id, url: `https://maps.googleapis.com/maps/api/streetview?size=550x400&location=${lat},${lon}&source=outdoor&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`};
                streetViewImages.push(streetViewPic);
            }
        }
    }

    
    render() {
        const { isAuthenticated } = this.props;
        const { allListingInfo, step, selected, selectedListingNum, coverPage, summaryPage, traffAddOns, compAddOns, demoAddOns, requestedListing, streetViewImages } = this.state;
        const components = { coverPage, selected, summaryPage, traffAddOns, compAddOns, demoAddOns };
        const theListing = allListingInfo.length && allListingInfo[selectedListingNum];
        const scores = theListing && theListing.scores;
        const filterables = theListing && theListing.filterables;

        switch(step) {
            case 0:
                return (
                    <CreateReportWelcomePage 
                        nextStep={this.nextStep}
                    />
                )
            case 1:
                return (
                    <CreateReportInput 
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        setReportComponents={this.setReportComponents}
                        setSelected={this.setSelected}
                        components={components}
                        requestedListing={requestedListing}
                    />
                )
            case 2:
                return (
                    <>{(isAuthenticated && (allListingInfo.length === selected.length) && ((coverPage === false) || (allListingInfo.length === streetViewImages.length))) ? <CreateReportOutput 
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        nextListing={this.nextListing}
                        prevListing={this.prevListing}
                        selectedListingNum={selectedListingNum}
                        components={components}
                        selected={selected}
                        allListingInfo={allListingInfo}
                        filterables={filterables}
                        thisListing={theListing}
                        scores={scores}
                        streetViewImages={streetViewImages}
                    /> : (<div style={{ height: 700, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                        <SkeletonDetailsPage />
                    </div>)}</>
                )
            default:
                return (
                    <CreateReportInput 
                        nextStep={this.nextStep}
                    />
                )
        }
    }
}

CreateReport.propTypes = {
    getListings: PropTypes.func.isRequired,
    listings: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    listings: state.listing.listings,
    thisListing: state.listing.thisListing,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});

export default connect(mapStateToProps, { getListings, getListing, loadUser, updateUser })(CreateReport);