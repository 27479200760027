import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getListings, getListing } from '../../actions/listingActions';
import { loadUser, updateUser } from '../../actions/authActions';
import PropTypes from 'prop-types';
import './compareListings.css';
import CompareListingsList from './compareListingsList';
import CompareListingsContent from './compareListingsContent';
import { MdAddchart } from 'react-icons/md';
import { LinearProgress } from '@material-ui/core';


class CompareListings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selected: [],
            selectedListingNum: 0,
            allListingInfo: [],
            totalGatherListingsNum: 0,
            loading: true
        }
    }
    
    componentDidMount() {
        const { selected, allListingInfo } = this.props;
        this.setState({ selected: selected, allListingInfo: allListingInfo });
    }

    async componentDidUpdate(prevProps, prevState) {
        
        const { selected, allListingInfo } = this.state;
        const { thisListing } = this.props;

        const selectedAddys = selected && selected.length && selected.map(selectedListing => selectedListing.label).sort();
        const gatheredAddys = allListingInfo && allListingInfo.length && allListingInfo.map(gatheredListing => gatheredListing.listingAddress).sort();
        
        if ((selected.length !== allListingInfo.length) || (JSON.stringify(selectedAddys) !== JSON.stringify(gatheredAddys))) {
            if ((!thisListing._id) || (JSON.stringify(selectedAddys) !== JSON.stringify(gatheredAddys))) {
                if (selected.length !== allListingInfo.length) {
                    this.getSelectedListings(selected);
                }
            }

            if (thisListing && thisListing._id) {
                if (selected.some(selectListing => selectListing.value === thisListing._id)) {
                    if (allListingInfo.some(gatheredListing => gatheredListing._id === thisListing._id)) {
                        return null;
                    } else { 
                        allListingInfo.push(thisListing);
                    }
                } else {
                    await this.getSelectedListings(selected);
                }
            }
        }

        if (selected && selected.length) {
            if (allListingInfo && allListingInfo.length) {
                if (allListingInfo.length !== selected.length) {
                    if (this.state.loading === true) {
                        return null;
                    } else {
                        this.setState({ loading: true });
                    }
                } else {
                    if (this.state.loading === false) {
                        return null;
                    } else {
                        this.setState({ loading: false });
                    }
                }
            }
        }
        
    }


    // Select the listings to generate comparison for
    setSelected = (selectedListings) => {
        const { allListingInfo } = this.state;
        const selectedListingAddys = selectedListings && selectedListings.length && selectedListings.map(selectedList => selectedList.label).sort();
        const filterAllListingInfo = allListingInfo && allListingInfo.length && allListingInfo.filter(gatheredListing => selectedListingAddys && selectedListingAddys.includes(gatheredListing.listingAddress));

        if (filterAllListingInfo && (filterAllListingInfo.length === allListingInfo.length)) {
            this.setState({ selected: selectedListings });
        } else {
            this.setState({ selected: selectedListings, allListingInfo: [] });
        }
    }

    // Get the selected listing's data
    getSelectedListings = async (selected) => {
    
        if (selected.length) {
            for (let i = 0; i < selected.length; i++) {
                try {
                    let listingId;
                    listingId = selected[i].value;
                    await this.props.getListing(listingId);
                } catch (err) {
                    console.log(err);
                }
            }
        }
    }

    
    render() {
        const { isAuthenticated, user } = this.props;
        const { allListingInfo, selected, selectedListingNum, loading } = this.state;
        const components = { selected };
        const theListing = allListingInfo.length && allListingInfo[selectedListingNum];
        const scores = theListing && theListing.scores;
        const filterables = theListing && theListing.filterables;

        return (
            <>{(isAuthenticated && user) ? 
                (<div>
                    <CompareListingsList 
                        setSelected={this.setSelected}
                        user={user}
                        isAuthenticated={isAuthenticated}
                        components={components}
                    />
                    {(!loading && (allListingInfo && allListingInfo.length)) ? (
                        <CompareListingsContent 
                            selectedListingNum={selectedListingNum}
                            setSelected={this.setSelected}
                            components={components}
                            selected={selected}
                            allListingInfo={allListingInfo}
                            filterables={filterables}
                            thisListing={theListing}
                            scores={scores}
                        />
                    ) : (selected && selected.length) ? <LinearProgress /> : <div style={{ height: '100vh', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><MdAddchart style={{ color: 'lightgray', height: 100, width: 100 }} /></div>
                                <div style={{ fontSize: 42, color: 'lightgray', textAlign: 'center' }}>No Listings Selected</div>
                            </div>
                        </div>}
                </div>) : <div>You Must Be Logged In</div>}</>
        )
    }
}

CompareListings.propTypes = {
    getListings: PropTypes.func.isRequired,
    listings: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    listings: state.listing.listings,
    thisListing: state.listing.thisListing,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});

export default connect(mapStateToProps, { getListings, getListing, loadUser, updateUser })(CompareListings);
