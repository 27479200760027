import React, { Component } from 'react';
import { Container, Col, Row, Image } from 'react-bootstrap';
import { Card, CardContent, Fab } from '@material-ui/core';
import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import MostCommonBusiness from './commonBusinessGraph';
import TotalBusinesses from './totalBusinessesGraph';
import './competition.css';
import PopularBusinessTable from './popularBusinessTable';
import PopularBusinessModal from './popularBusinessModal';
import PriceGauge from '../../../Assets/priceGauge.png';
import PriceGaugeModal from './priceGaugeModal';
import SkeletonDetailsPage from '../../skeletons/skeletonDetailsPage';
import yelplogo from '../../../Assets/yelplogo.png';

class Competition extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            thisListing: props.thisListing,
            yelpData: props.yelpData,
            commonChartData: {},
            totalChartData: {},
            totalGaugeNeedle: 0,
            thisListingsAveragePrice: 0,
            thisListingsPriceVolume: [],
            globalAveragePrices: [],
            globalPriceVolume: [],
            priceGaugeNeedle: 0,
            percentageScore: 0,
        }
    }

    
    render() { 

        const { thisListing, yelpData, scores } = this.props;
        const { filterables: { bizPricesIndex: bizPricesIndex } = {}} = this.props;
        const { yelpData: { total: yelpTotal } = {}} = this.props;
        const { yelpData: { businesses: yelpBusinesses } = {}} = this.props;
        
        
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!TOTAL BUSINESS GRAPH!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!        
        
        // gauges how the # of total biz's for this particular listing compares to every other listing
        const { scores: { bizDensity: totalBusinessesGauge } = {}} = this.props;
        let totalGaugeNeedle = 0;
        let totalChartData = {};
        
        
        if (totalBusinessesGauge < 55) {
                totalGaugeNeedle = -80;
                totalChartData = {
                    labels: ['Low Density', 'Average Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        } else if (totalBusinessesGauge < 60) {
                totalGaugeNeedle = -58;
                totalChartData = {
                    labels: ['Low Density', 'Average Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        } else if (totalBusinessesGauge < 65) {
                totalGaugeNeedle = -38;
                totalChartData = {
                    labels: ['Low Density', 'Average Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        } else if (totalBusinessesGauge < 70) {
                totalGaugeNeedle = -18;
                totalChartData = {
                    labels: ['Low Density', 'Average Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        } else if (totalBusinessesGauge === 75) {
                totalGaugeNeedle = 2;
                totalChartData = {
                    labels: ['Low Density', 'Average Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        } else if (totalBusinessesGauge < 80) {
                totalGaugeNeedle = 18;
                totalChartData = {
                    labels: ['Low Density', 'Average Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        } else if (totalBusinessesGauge <= 85) {
                totalGaugeNeedle = 38;
                totalChartData = {
                    labels: ['Low Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        } else if (totalBusinessesGauge <= 90) {
                totalGaugeNeedle = 58;
                totalChartData = {
                    labels: ['Low Density', 'Average Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        } else if (totalBusinessesGauge <= 95) {
                totalGaugeNeedle = 78;
                totalChartData = {
                    labels: ['Low Density', 'Average Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        } else {
                totalGaugeNeedle = 85;
                totalChartData = {
                    labels: ['Low Density', 'Average Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        }


        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!MOST COMMON BUSINESS TYPES !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        
        //gets the category Titles of the businesses in the area of this each listing
        const yelpBusinessCategories = yelpBusinesses.map((business) => (business.categories).map((category) => category.title));
        const allCategories = yelpBusinessCategories.map((category) => category[0]);
        
        //creates an array of key/values that contains a count of each business type in the area
        const count = allCategories =>
            allCategories.reduce((a, b) => ({ ...a,
                [b]: (a[b] || 0) + 1
            }), {})

        const sortable = Object.fromEntries(
            Object.entries(count(allCategories)).sort(([,a],[,b]) => b-a)
        );
        
        const mostCommonBusinesses = Object.entries(sortable);
        let commonChartData = {};

        
        commonChartData = {
            labels: 
            [
                `${mostCommonBusinesses[0][0]}`, 
                `${mostCommonBusinesses[1][0]}`, 
                `${mostCommonBusinesses[2][0]}`, 
                `${mostCommonBusinesses[3][0]}`,
                `${mostCommonBusinesses[4][0]}`,
                `${mostCommonBusinesses[5][0]}`
            ],
            datasets: [
                {
                    label: 'Number of Businesses',
                    indexAxis: 'y',
                    data: [
                        `${mostCommonBusinesses[0][1]}`,
                        `${mostCommonBusinesses[1][1]}`,
                        `${mostCommonBusinesses[2][1]}`,
                        `${mostCommonBusinesses[3][1]}`,
                        `${mostCommonBusinesses[4][1]}`,
                        `${mostCommonBusinesses[5][1]}`
                    ],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.8)',
                        'rgba(54, 162, 235, 0.8)',
                        'rgba(255, 206, 86, 0.8)',
                        'rgba(75, 192, 192, 0.8)',
                        'rgba(150, 142, 62, 0.8)',
                        'rgba(105, 172, 100, 0.8)'
                    ]
                }
            ]
        }

        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! PRICE LEVEL GAUGES !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        
        const percentageScore = bizPricesIndex.percentageScore;
        const thisListingsPriceVolume = bizPricesIndex.priceLevels;

        let priceGaugeNeedle = 0;
        
        if (percentageScore < 25) {
            priceGaugeNeedle = -65;
        } else if (percentageScore === 25) {
            priceGaugeNeedle = -45;
        } else if (percentageScore < 50) {
            priceGaugeNeedle = -25;
        } else if (percentageScore === 50) {
            priceGaugeNeedle = 0;
        } else if (percentageScore < 75) {
            priceGaugeNeedle = 25;
        } else if (percentageScore === 75) {
            priceGaugeNeedle = 45;
        } else if (percentageScore < 100) {
            priceGaugeNeedle = 65;
        } else {
            priceGaugeNeedle = 80;
        }

        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!! FILTER TAGS AND SET POINTERS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

        const compFilterTags = [];

        if (thisListing.filterTags) {
            for (let i = 0; i < thisListing.filterTags.length; i ++) {
                
                if (thisListing.filterTags[i].includes('highEndBiz')) {
                    compFilterTags.push({tagName: 'High End Businesses', tagDescription: 'There are numerous high-end businesses nearby.'});
                } else if (thisListing.filterTags[i].includes('lowEndBiz')) {
                    compFilterTags.push({tagName: 'Affordable Businesses', tagDescription: 'There are mostly businesses with affordable product prices nearby.'});
                } else if (thisListing.filterTags[i].includes('highBizDensity')) {
                    compFilterTags.push({tagName: 'High Business Density', tagDescription: 'There is a high concentration of businesses in this area, which typically attracts more shoppers to the area.'});
                } else if (thisListing.filterTags[i].includes('lowBizDensity')) {
                    compFilterTags.push({tagName: 'Low Business Density', tagDescription: "There is a low concentration of businesses in this area, which typically won't attract as many shoppers to the area."});
                }
            }
        }

        const setPriceGauge = (gauge) => { 
            setTimeout(() => {
                const priceGauge = document.querySelector('.priceGaugeNeedle');
                let thisRotation = gauge;
                priceGauge && priceGauge.style.setProperty('--rotation', thisRotation);
            }, 200, { once: true });
        }

        const setTotalBizCountPointer = (gauge) => { 
            setTimeout(() => {
                const totalGaugeNeedle = document.querySelector('.totalGaugeNeedle');
                let thisRotation = gauge;
                totalGaugeNeedle && totalGaugeNeedle.style.setProperty('--rotation', thisRotation);
            }, 200, { once: true });
        }

        setPriceGauge(priceGaugeNeedle);
        setTotalBizCountPointer(totalGaugeNeedle);

        const FilterTagTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} />
            ))(({ theme }) => ({
                [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: 'snow',
                color: 'black',
                fontWeight: 'bold',
                maxWidth: 300,
                fontSize: theme.typography.pxToRem(14),
                padding: 25,
                border: '2px solid rgb(52, 181, 122)',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                },
        }));

        let skeletonLength = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

        return (
            <>
            {(!yelpData && !yelpData.total) ? skeletonLength.map((n) => <SkeletonDetailsPage key={n} />) : (
            <Container>
                <div className='competitionTagsArea'>
                    <div style={{ height: '100%', alignItems: 'center', display: 'grid', gridTemplateColumns: '2fr 1fr', gap: 5 }}>
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <h5>Competition Score:</h5>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <h3 style={{ textShadow: '.5px .5px 1px rgb(21, 134, 84)', border: '2px solid green', borderRadius: '50%', width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>{scores.competitionScore}</h3>
                        </div>
                    </div>
                    <div style={{ height: '100%', display: 'grid', gridTemplateColumns: '1fr 5fr', gap: 5 }}>
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <h5>Tags:</h5>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                            {compFilterTags.length ? compFilterTags.map(tag => (
                                <span>
                                    <FilterTagTooltip arrow
                                        title={
                                            <>
                                                <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>{tag.tagDescription}</p>
                                            </>
                                        }
                                    >
                                        <Fab
                                            variant="extended"
                                            size="small"
                                            style={{ backgroundColor: 'rgb(52, 181, 122)', fontSize: 12, color: 'whitesmoke', marginLeft: 10, outline: 'none', padding: 15, lineHeight: 1.25 }}
                                            >
                                            {tag.tagName}
                                        </Fab>
                                    </FilterTagTooltip>
                                </span>
                            )) : (<p className='summaryDescription'>None</p>)}
                        </div>
                    </div>
                </div>
                <hr />
                <div style={{ margin: 'auto', width: 1150 }}>
                    <Row style={{ height: 305, width: '100%' }}>
                        <Col sm={5}>
                            <Card style={{ height: 275, width: 475 }}>
                                <CardContent>
                                    <div className='totalBizArea'>
                                        <TotalBusinesses totalChartData={totalChartData} />
                                        <div className='totalGaugeNeedle'></div>
                                        <h2 className='totalBusinessNumber'>{yelpTotal}</h2>
                                        <div className='totalBusinessText'>Other Businesses in the Area</div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Col>
                        <Col sm={7}>
                            <Card style={{ height: 830, width: 645, marginLeft: 40 }}>
                                <CardContent>
                                    <div className='popularBizArea'>
                                        <h4 style={{ fontWeight: 'bold', marginBottom: 75, paddingTop: 30, paddingLeft: 20, color: 'black', letterSpacing: 1, textShadow: '1px 1px 1px white' }}>Popular Businesses Nearby</h4>
                                        <img className='yelpLogoTraffic' src={yelplogo} alt='' />
                                        <div className='popularBusinessModal'>
                                            <PopularBusinessModal yelpData={yelpData} thisListing={thisListing} />
                                        </div>
                                        <div>
                                            <PopularBusinessTable thisListing={thisListing} yelpData={yelpData} />
                                        </div>
                                        <div style={{ marginLeft: 190, marginTop: 65, marginBottom: 15, paddingBottom: 10 }}>
                                            <PopularBusinessModal yelpData={yelpData} thisListing={thisListing} />
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </Col>
                    </Row>    
                    <Row style={{ height: 175, width: '100%' }}>
                        <Col sm={6}>
                            <Card style={{ height: 145, width: 475 }}>
                                <CardContent>
                                    <div className='priceGaugeHighlight'>
                                        <div className='highEndGaugeTitle'>High-End Gauge</div>
                                        <div className='priceData'>
                                            <Image className='priceGauge' src={PriceGauge} alt='' />
                                            <div className='priceGaugeNeedle'></div>
                                        </div>
                                        <div className='priceModalBtn'>
                                            <PriceGaugeModal thisListingsPriceVolume={thisListingsPriceVolume} percentage={percentageScore} />
                                        </div>
                                    </div>
                                </CardContent>            
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{ height: 405, width: '100%' }}>
                        <Col sm={6}>
                            <Card style={{ height: 350, width: 475 }}>
                                <CardContent>
                                    <img className='yelpLogoTraffic' style={{ left: '75%', top: '2%' }} src={yelplogo} alt='' />
                                    <div className='commonBizArea'>
                                        <MostCommonBusiness commonChartData={commonChartData} thisListing={thisListing} />
                                    </div>
                                </CardContent>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
            )}</>
        );
    }
}

export default Competition;