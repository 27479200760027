import React, { Component } from 'react';
import { Paper, Table, List, ListItem } from '@mui/material';
import GreenArrow from '../../../Assets/greenArrow.png';
import RedArrow from '../../../Assets/redArrow.png';

const greenArrow = <img className='greenArrow' src={GreenArrow} alt='' />;
const redArrow = <img className='redArrow' src={RedArrow} alt='' />;

class CommonPeopleComparison extends Component {
    

    render() { 

        const { listings, screenWidth } = this.props;

        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.scores.fourcastScore - a.scores.fourcastScore});
        
        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }


        let thisListingsTags = (thisListing) => {
                        
            // get current geoIdV4 array
            const geoIdV4 = thisListing && thisListing.attomData && thisListing.attomData.geoIdV4 && thisListing.attomData.geoIdV4;
            const poi1 = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi1 && thisListing.scores.POI.poi1;
            const poi2 = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi2 && thisListing.scores.POI.poi2;
            const poi3 = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi3 && thisListing.scores.POI.poi3;

                // types of people
            const getStudentsPop = [];
            const getWorkersPop = [];
            const getDayPop = [];
            const getPopulation = [];

                // pop trends
            const popChanges = [];
            const popForecasts = [];
            
            if (geoIdV4 && geoIdV4.length) {

                for (let g = 0; g < geoIdV4.length; g++) {

                    // POPULATION DATA
                    const totalPopulation = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population);
                    const thisDayPop = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Daytime);
                                    
                    getDayPop.push(thisDayPop);
                    getPopulation.push(totalPopulation);
                    
                    let totalWorkersPop = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.occupation_Soc_Cnt);
                    let whiteCollarPct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.occupation_White_Collar_Pct);
                    
                    let studentsNum = (geoIdV4 && geoIdV4[g].commV4Data && (parseFloat(geoIdV4[g].commV4Data.enrollment_Public_Undergraduate_College_Pct) + parseFloat(geoIdV4[g].commV4Data.enrollment_Private_Undergraduate_College_Pct) + parseFloat(geoIdV4[g].commV4Data.enrollment_Private_Graduate_Or_Professional_School_Pct) + parseFloat(geoIdV4[g].commV4Data.enrollment_Public_Graduate_Or_Professional_School_Pct)));
                    
                    getWorkersPop.push((totalWorkersPop * (whiteCollarPct * .01)));
                    getStudentsPop.push((totalPopulation * (studentsNum * .01)));

                    
                    // POP CHANGES
                    const popChangeSince2010 = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Chg_Pct_2010);
                    let popChangeWeightedValue = (geoIdV4 && (geoIdV4.length > 1)) ? (geoIdV4 && (geoIdV4[g].type !== 'N4')) ? (popChangeSince2010 * .4) : (popChangeSince2010 * .6) : popChangeSince2010;
                    let popForecast5YearNum = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_5_Yr_Projection);

                    popChanges.push(popChangeWeightedValue);
                    popForecasts.push(popForecast5YearNum);


                }
            }

            // MOST COMMON PEOPLE 
            const currentPop = getPopulation.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }); 
            const daytimePop = getDayPop.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });
            const employeePop = getWorkersPop.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });
            const thisStudentPop = getStudentsPop.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });

            const studentPop = thisListing && thisListing.filterTags && thisListing.filterTags.includes('students') ? thisStudentPop : 0;

            const demoFilterTags = [];

            if (thisListing.filterTags) {
                for (let i = 0; i < thisListing.filterTags.length; i ++) {
                    
                    if (thisListing.filterTags[i].includes('localResidents')) {
                        demoFilterTags.push({tagName: 'Mostly Residential Area', tagDescription: 'This is a largely residential area.'});
                    } else if (thisListing.filterTags[i].includes('officeWorkers')) {
                        demoFilterTags.push({tagName: 'Office Workers', tagDescription: 'A significant amount of people work in this area.'});
                    } else if (thisListing.filterTags[i].includes('nearAttraction')) {
                        demoFilterTags.push({tagName: 'Tourist Area', tagDescription: 'This space is within a 250 meter radius (~5 min walk) of a major attraction, which can generate traffic and tourists in the area.'});
                    } else if (thisListing.filterTags[i].includes('students')) {
                        demoFilterTags.push({tagName: 'Students', tagDescription: 'There is a relatively high concentration of students in the area, compared to most parts of the city.'});
                    } else if (thisListing.filterTags[i].includes('highIncomeArea')) {
                        demoFilterTags.push({tagName: 'High Income Area', tagDescription: 'The average income levels and/or volume of people making higher incomes are higher here than most areas in the city.'});
                    } else if (thisListing.filterTags[i].includes('affordableRE')) {
                        demoFilterTags.push({tagName: 'Affordable Living', tagDescription: 'Residential real estate prices in this area are typically more affordable than other neighborhoods in the city.'});
                    } else if (thisListing.filterTags[i].includes('expensiveRE')) {
                        demoFilterTags.push({tagName: 'Expensive Living', tagDescription: 'Residential real estate prices in this area are typically more expensive than other neighborhoods in the city.'});
                    } else if (thisListing.filterTags[i].includes('genZ')) {
                        demoFilterTags.push({tagName: 'Gen Z', tagDescription: 'There is a Gen Z population in this area.'});
                    } else if (thisListing.filterTags[i].includes('millennialsTo34')) {
                        demoFilterTags.push({tagName: 'Young Millennials', tagDescription: 'There are young Millennials between 25-34 living here.'});
                    } else if (thisListing.filterTags[i].includes('millennialsTo44')) {
                        demoFilterTags.push({tagName: 'Older Millennials', tagDescription: 'You can find older Millennials between 35-44 in this neighborhood.'});
                    } else if (thisListing.filterTags[i].includes('genX')) {
                        demoFilterTags.push({tagName: 'Gen X', tagDescription: 'You can find more Gen X living here than many other neighborhoods.'});
                    } else if (thisListing.filterTags[i].includes('boomers')) {
                        demoFilterTags.push({tagName: 'Boomers', tagDescription: 'There is a sizable population of Baby Boomers living here.'});
                    } else if (thisListing.filterTags[i].includes('areasWithChildren')) {
                        demoFilterTags.push({tagName: 'Families with Children', tagDescription: 'You can find more families with children living here than many other neighborhoods.'});
                    }
                }
            };

            // GET THIS LISTINGS POPS

            const rawDaytimePop = parseFloat(daytimePop);
            const rawEmployeePop = parseFloat(employeePop);
            const rawCurrentPop = parseFloat(currentPop);
            const rawStudentPop = parseFloat(studentPop);

            const residentGauge = ((rawCurrentPop / rawDaytimePop) + 1);
            const workersGauge = ((rawEmployeePop / rawDaytimePop) + 1);
            let touristGauge = 0;
            const studentsGauge = [];

            // SET TOURIST GAUGE 

            const checkNearbyAttractions = (poi) => {
                if (poi.majorAttraction === true) {
                    touristGauge += 1;
                    if (poi.distance.elements[0].distance.value <= 150) {
                        touristGauge += 1;
                    } else if (poi.distance.elements[0].distance.value <= 200) {
                        touristGauge += .75;
                    } else if (poi.distance.elements[0].distance.value <= 250) {
                        touristGauge += .5;
                    } else if (poi.distance.elements[0].distance.value <= 300) {
                        touristGauge += .25;
                    } else {
                        touristGauge += .1;
                    }
                }    
                touristGauge += 0;
            };

            checkNearbyAttractions(poi1);
            checkNearbyAttractions(poi2);
            checkNearbyAttractions(poi3);

            
            // SET STUDENT GAUGE

            const studentPercentage = ((rawStudentPop / rawCurrentPop) * 100);

            const checkStudentsNearby = (studentsParam) => {
                if (studentsParam >= 5) {
                    studentsGauge.push(1);
                }
                studentsGauge.push(0);
            };

            checkStudentsNearby(studentPercentage);

            if (studentsGauge[0] === 1) {
                studentsGauge[0] += ((rawStudentPop / rawCurrentPop));
            };

            const studentGauge = studentsGauge[0];

            const peopleTypeArr = [{type: 'Residents', val: residentGauge}, {type: 'Workers', val: workersGauge}, {type: 'Tourists / Visitors', val: touristGauge}, {type: 'Students', val: studentGauge}];
            const filteredPeopleTypeArr = peopleTypeArr && peopleTypeArr.filter(peopleType => (peopleType.val > 0));

            const topPeople = filteredPeopleTypeArr.sort(function(a, b){return b.val-a.val});

            
            // POP CHANGES
            const popChange2010 = popChanges.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });
            const pop5YearForecastTotal = popForecasts.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });
            
            const popChange = parseFloat(popChange2010).toFixed(0);
            const thisCurrentPop = parseFloat(currentPop);
            const fiveYearPop = parseFloat(pop5YearForecastTotal);
            const popForecast5 = ((fiveYearPop - thisCurrentPop)/fiveYearPop * 100).toFixed(1);

            let tagsObj = {address: thisListing.listingAddress, topPeople: topPeople, popChange: popChange, popForecast: popForecast5};
            return tagsObj;
        }

        const filteredListingsPopulation = sortedByFourcastScore && sortedByFourcastScore.map(listing => thisListingsTags(listing));

        
        let displayFilterTags = (tags) => {
            return (
                <>
                    {tags.length ? tags.map(tag => 
                        (<List style={{ padding: 0 }}>
                            <ListItem style={{ padding: 0 }}>
                                {tag.type}
                            </ListItem>
                        </List>)) 
                    : (<ListItem>None</ListItem>)}
                </>
            )
        }
        
        
        let filteredListingPeopleTypes = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsPeopleTypes = thisListingsTags(listing);
            return (
                <td style={{ fontSize: 14, padding: 20 }}>
                    {thisListingsPeopleTypes && thisListingsPeopleTypes.topPeople && displayFilterTags(thisListingsPeopleTypes.topPeople)}
                </td>
            )
        });


        let displayPopChangeSince2010 = (listing) => {
            return (
                <>
                    {(listing && listing.popChange) ? ( 
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 100, marginLeft: 'auto', marginRight: 'auto' }}>
                            <div className='incomeChangeValue'>{`${listing.popChange}%`}</div>
                            <div className='incomeArrows'>
                                {(listing && (listing.popChange > 0)) ? greenArrow : (listing && (listing.popChange < 0)) ? redArrow : '' }
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }
        let displayPopForecast = (listing) => {
            return (
                <>
                    {(listing && listing.popForecast) ? ( 
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 100, marginLeft: 'auto', marginRight: 'auto' }}>
                            <div className='incomeChangeValue'>{`${listing.popForecast}%`}</div>
                            <div className='incomeArrows'>
                                {(listing && (listing.popForecast > 0)) ? greenArrow : (listing && (listing.popForecast < 0)) ? redArrow : '' }
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }

        let filteredListingPopSince2010 = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsPop = thisListingsTags(listing);
            return (
                <td style={{ fontSize: 14, padding: 10 }}>
                    {thisListingsPop && displayPopChangeSince2010(thisListingsPop)}
                </td>
            )
        });
        let filteredListingsPopForecast = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsPop = thisListingsTags(listing);
            return (
                <td style={{ fontSize: 14, padding: 10 }}>
                    {thisListingsPop && displayPopForecast(thisListingsPop)}
                </td>
            )
        });
        
        
        return (
            <div style={{ position: 'relative', width: '98%' }}>
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Common People Types</div>
                </div>
                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', paddingTop: 20, paddingBottom: 40 }}>
                    <div>
                        <Table style={{ tableLayout: 'fixed' }}>
                            <thead>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    {sortedByFourcastScore && sortedByFourcastScore.map(listing => {return (<th style={{ padding: 20, color: 'darkgreen' }}>{addressStyle(listing.listingAddress)}</th>)})}
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    {filteredListingPeopleTypes}
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                    <div style={{ fontSize: 24, fontWeight: 500, marginTop: 50 }}>Local Population</div>
                </div>
                <div style={{ paddingTop: 20 }}>
                    <div>
                        <Table style={{ tableLayout: 'fixed' }}>
                            <thead>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '20%' }}></th>
                                    {filteredListingsPopulation && filteredListingsPopulation.map(listing => {return (<th style={{ padding: 10, color: 'darkgreen' }}>{addressStyle(listing.address)}</th>)})}
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '20%' }}>
                                       Population Since 2010
                                    </th>
                                    {filteredListingPopSince2010}
                                </tr>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '20%' }}>
                                        Population 5 Year Forecast
                                    </th>
                                    {filteredListingsPopForecast}
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        );
    }
}

export default CommonPeopleComparison;