import { FORGOT_PASSWORD_SENT, FORGOT_PASSWORD_FAIL, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL } from '../actions/types';

const initialState = {
    emailSent: false,
    passwordReset: false,
    error: null
}

export default function(state = initialState, action) {
    switch (action.type) {
        case FORGOT_PASSWORD_SENT:
            return { emailSent: action.payload.success };
        case FORGOT_PASSWORD_FAIL:
            return { emailSent: false, error: action.payload };
        case RESET_PASSWORD_SUCCESS:
            return { passwordReset: action.payload.success };
        case RESET_PASSWORD_FAIL:
            return { passwordReset: false, error: action.payload };
        default: 
            return state
    }
}

