import React, { useState } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import fourcastMatchLogo from '../../Assets/fourcastMatchLogo.png';
import moment from 'moment';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Row, Col } from 'reactstrap';
import { MdFileDownload } from 'react-icons/md';
import { AiOutlineCheckCircle } from 'react-icons/ai';

  
const FourcastMatchPDF = ({selected, lead, rootElementId, downloadFileName, user}) => {
    const [downloaded, setDownloaded] = useState(false);
    
    const filterLabels = (label) => {    
    
        switch(label) {
            case "nearAttraction":
                return (
                    "Near Major Attraction"
                )
            case "seasonal":
                return ( 
                    "Seasonal"
                )
            case "busyDayTime":
                return ( 
                    "Heavy Daytime Traffic"
                )
            case "busyMonThruFri":
                return (
                    "Heavy Mon-Fri Traffic"
                )
            case "busyWeekend":
                return ( 
                    "Heavy Weekend Traffic"
                )
            case "busyNightLife":
                return ( 
                    "Heavy Nightlife"
                )
            case "localResidents":
                return (
                    "Mostly Local Resident"
                )
            case "officeWorkers":
                return ( 
                    "Mostly People at Work"
                )
            case "students":
                return (
                    "Students"
                )
            case "highIncomeArea":
                return ( 
                    "High Income Area"
                )
            case "genZ":
                return ( 
                    "Gen Z"
                )
            case "millennialsTo34":
                return ( 
                    "Young Millennials"
                )
            case "millennialsTo44":
                return ( 
                    "Older Millennials"
                )
            case "genX":
                return ( 
                    "Gen X"
                )
            case "boomers":
                return ( 
                    "Boomers"
                )
            case "areasWithChildren":
                return ( 
                    "Areas WITH Children"
                )
            case "affordableRE":
                return ( 
                    "Affordable"
                )
            case "expensiveRE":
                return ( 
                    "Expensive"
                )
            case "highEndBiz":
                return ( 
                    "$$$$"
    
                )
            case "lowEndBiz":
                return ( 
                    "$"
    
                )
            case "highBizDensity":
                return ( 
                    "High Biz Density"
    
                )
            case "lowBizDensity" :
                return ( 
                    "Low Biz Density"
    
                )
            case "cornerLocation":
                return ( 
                    "Corner Location"
    
                )
            case "bigFrontage":
                return ( 
                    "Big Frontage"
                )
            case "divisible":
                return ( 
                    "Divisible"
                )
            case "multiLevel":
                return ( 
                    "Multi Level"
    
                )
            case "newConstruction":
                return ( 
                    "New Construction"
    
                )
            case "outdoorSpace":
                return ( 
                    "Outdoor Space"
    
                )
            default:
                return null
        }
    }

    let leadMinSize = lead && lead.fourcastMatch && parseFloat(lead.fourcastMatch.minSize);
    let leadMaxSize = lead && lead.fourcastMatch && parseFloat(lead.fourcastMatch.maxSize);
    let leadBudget = lead && lead.fourcastMatch && parseFloat(lead.fourcastMatch.budget);
    let yearlyPriceOfSpace = selected && Math.ceil(selected.squareFeet * selected.pricePerSquareFoot);

    const downloadPdfDocument = () => {
        const input = document.getElementById("fourcastPDFViewDown");
        
        html2canvas(input, {
            ignoreElements: function( element ) {
                // Remove action buttons at bottom of custom search info window
                if ( 'customSearchActionButtonsArea' === element.id ) {
                    return true;
                }
            }
        })
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();

            let logoX = ((pdf.internal.pageSize.getWidth() - 100) / 2);
            let resultsX = ((pdf.internal.pageSize.getWidth() - 130) / 2);
            let rightNow = moment().format('MMMM Do YYYY, h:mm:ss a');
            let textX = ((pdf.internal.pageSize.getWidth() - pdf.getTextWidth(rightNow)) / 5);
            let footerY = (pdf.internal.pageSize.getHeight() - 30);

            pdf.addImage(fourcastMatchLogo, 'JPEG', logoX, 5, 100, 20);
            pdf.addImage(imgData, 'JPEG', resultsX, 35, 130, 195);
            pdf.text(`${user.firstName} ${user.lastName} | ${user.email}`, textX, (footerY - 10));
            pdf.text(`${rightNow} | ${user._id}`, textX, footerY);
            pdf.save(`${downloadFileName}.pdf`);
        })
        setDownloaded(true);
    }
    return (
        <div style={{ overflow: 'hidden', maxHeight: 550, maxWidth: 525 }}>
            <div style={{ position: 'absolute',left: 0, top: -730, width: 450, margin: 'auto', paddingLeft: 15 }} id="fourcastPDFViewDown">
                <h4 style={{ marginBottom: 20, fontFamily: 'sans-serif', textAlign: 'center', color: 'darkgreen' }}>{lead && lead.sender && lead.sender.company}</h4>
                <p style={{ marginBottom: 20, fontSize: 16, textAlign: 'center', fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold' }}>{selected.listingAddress}</p>
                <Row style={{ width: 450, textAlign: 'center' }}>
                    <Col sm={12}>
                        <p style={{ fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 'bold', color: 'darkgreen' }}>Fourcast Match</p>
                    </Col>
                </Row>
                <Row style={{ width: 450, textAlign: 'center', marginBottom: 20 }}>
                    <Col sm={12}>
                        <h3 style={{ color: 'darkgreen', fontWeight: 'bold' }}>{lead && lead.fourcastMatch.score}</h3>
                    </Col>
                </Row>
                <Row style={{ width: 425, textAlign: 'center' }}>
                    <div style={{ width: '95%', textAlign: 'left' }}>
                        <p style={{ fontSize: 14, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}>Applicant Background: </p>
                        <Col sm={12}>
                            <span style={{ fontSize: 14, fontWeight: 'bold', fontFamily: 'sans-serif' }}>Business Type: </span><span style={{ fontSize: 12, fontFamily: 'sans-serif', color: 'black' }}> {lead && lead.fourcastMatch.filters.userBizTypes.type2}</span>
                        </Col>
                    </div>
                </Row>
                <Row style={{ width: 425, marginTop: 30 }}>
                    <div style={{ fontSize: 13, paddingTop: 5 }} className='customSearchInfoBoxGoodBizArea'>
                        <span style={{ fontSize: 14, fontWeight: 'bold', color: 'darkgreen' }}>Good Businesses: </span>
                        {lead && lead.fourcastMatch.filters.goodBiz.types.map(biz => (
                        <span className='customListingTags'>{((lead && lead.fourcastMatch.filters.goodBiz.types.indexOf(biz)) === (lead && lead.fourcastMatch.filters.goodBiz.types.length - 1)) ? ` ${biz.label}` : ` ${biz.label},`}</span>
                        ))}
                    </div>
                </Row>
                <Row style={{ width: 425 }}>
                    <div style={{ fontSize: 13, paddingTop: 5 }} className='customSearchInfoBoxBadBizArea'>
                        <span style={{ fontSize: 14, fontWeight: 'bold', color: 'darkred' }}>Bad Businesses: </span>
                        {lead && lead.fourcastMatch.filters.badBiz.types.map(biz => (
                        <span className='customListingTags'>{((lead.fourcastMatch.filters.badBiz.types.indexOf(biz)) === (lead.fourcastMatch.filters.badBiz.types.length - 1)) ? ` ${biz.label}` : ` ${biz.label},`}</span>
                        ))}
                    </div>
                </Row>
                <Row style={{ width: 400, marginTop: 20, marginLeft: 'auto', marginRight: 'auto' }}>
                    <Col sm={6}>
                        <p style={{ marginTop: 10, textAlign: 'center', fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}>Good</p>
                        <div style={{ width: '97%', textAlign: 'left' }}>
                        <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif', marginBottom: 30 }}><span>+ Found <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}>{lead && lead.fourcastMatch.filters.goodBiz.goodBizList.length}</span> Good Businesses Nearby</span></p>
                            <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}>{selected.squareFeet && (selected.squareFeet.some(sqFt => sqFt >= leadMinSize)) ? <span>+ MIN: <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}>{leadMinSize}</span> sq.ft.</span> : null}</p>
                            <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}>{selected.squareFeet && (selected.squareFeet.some(sqFt => sqFt <= leadMaxSize)) ? <span>+ MAX: <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}>{leadMaxSize}</span> sq.ft.</span> : null}</p>
                            <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}>{yearlyPriceOfSpace && (yearlyPriceOfSpace <= leadBudget) ? <span>+ BUDGET: <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}>${leadBudget}</span> / year</span> : null}</p>
                            {lead && lead.fourcastMatch.filters.trueTags.length > 0 ? lead.fourcastMatch.filters.trueTags.map(tag => (
                            <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}><span>+ <span style={{ fontSize: 14, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}> {filterLabels(tag)}</span></span></p>
                            )) : null}
                        </div>
                    </Col>
                    <Col sm={6}>
                        <p style={{ marginTop: 10, textAlign: 'center', fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkred' }}>Bad</p>
                        <div style={{ width: '97%', textAlign: 'right' }}>
                            <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif', marginBottom: 30 }}><span>- Found <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkred' }}>{lead && lead.fourcastMatch.filters.badBiz.badBizList.length}</span> Bad Businesses Nearby</span></p>
                            <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}>{selected.squareFeet && ((selected.squareFeet[0] < leadMinSize) && (selected.squareFeet[selected.squareFeet.length - 1] < leadMinSize)) ? <span>- MIN: <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkred' }}>{leadMinSize}</span> sq.ft.</span> : null}</p>
                            <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}>{selected.squareFeet && ((selected.squareFeet[0] > leadMaxSize) && (selected.squareFeet[selected.squareFeet.length - 1] > leadMaxSize)) ? <span>- MAX: <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkred' }}>{leadMaxSize}</span> sq.ft.</span> : null}</p>
                            <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}>{yearlyPriceOfSpace && (yearlyPriceOfSpace > leadBudget) ? <span>- BUDGET: <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkred' }}>${leadBudget}</span> / year</span> : null}</p>
                            {lead && lead.fourcastMatch.filters.falseTags.length > 0 ? lead.fourcastMatch.filters.falseTags.map(tag => (
                            <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}><span>- <span style={{ fontSize: 14, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkred' }}> {filterLabels(tag)}</span></span></p>
                            )) : null}
                        </div>
                    </Col>
                </Row>
            </div>
            <Button style={{ backgroundColor: 'rgb(35, 126, 85)', color: 'white', width: 275, outline: 'none', marginRight: 10, marginTop: 20 }} onClick={downloadPdfDocument} startIcon={!downloaded ? <MdFileDownload /> : <AiOutlineCheckCircle />}>Download Fourcast Match Results</Button>
        </div>
    )
}


const LeavingPageDownloadPDFAlert = ({user, selected, lead}) => {
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <>
            <Button style={{ outline: 'none', color: 'white', textDecoration: 'none', backgroundColor: '#007FFF', width: 200, marginTop: 10 }} onClick={handleClickOpen}>
                Leave and go to listing
            </Button>
            <Dialog
                fullWidth={true}
                maxWidth={"md"}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>Leave Fourcast?</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    By following the link you will be leaving Fourcast and will lose your Fourcast Match Results. You can download a PDF version of your Fourcast Match Results below:
                </DialogContentText>
                <Box
                    noValidate
                    component="form"
                    sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    m: 'auto',
                    width: 'fit-content',
                    }}
                >
                    <FourcastMatchPDF selected={selected} lead={lead} user={user} downloadFileName="FourcastMatchResults" rootElementId="fourcastPDFViewDown" />
                    <Button style={{ backgroundColor: '#007FFF', width: 200, marginLeft: 10, marginTop: 20 }}>
                        <a style={{ color: 'white', textDecoration: 'none' }} href={selected.link} target="_blank">Leave and go to listing</a>
                    </Button>
                </Box>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default LeavingPageDownloadPDFAlert;