import React, { Component } from 'react';
import { Paper } from '@mui/material';
import AgeGraph from '../listingDetailsLayouts/demographics/ageGraph';
import FamilyStatusGraph from '../listingDetailsLayouts/demographics/familyStatusGraph';
import CommonPeople from '../listingDetailsLayouts/demographics/commonPeople';
import fourcastLogo from '../../Assets/fourcast_alt_logo_green.png';
import './createReport.css';
import '../listingDetailsLayouts/traffic/traffic.css';
import '../listingDetailsLayouts/demographics/demographics.css';
import '../listingDetailsLayouts/summary/summary.css';

const greenArrow = <div className='greenArrowReport'></div>;
const redArrow = <div className='redArrowReport'></div>;


class PopulationReport extends Component {
    
    
    render() { 

        const { thisListing, scores, thisListing: { listingAddress: getListingAddress } = {}} = this.props;
        
        // get current geoIdV4 array
        const { attomData: { geoIdV4 } = {}} = this.props;

        const getPopulation = [];
        const getDayPop = [];
    
        // AGES 
        const elevenAndUnderArr = [];
        const twelveToSeventeenArr = [];
        const eighteenTo24Arr = [];
        const twentyfiveTo34Arr = [];
        const thirtyfiveTo44Arr = [];
        const fortyfiveTo54Arr = [];
        const fiftyfiveTo64Arr = [];
        const sixtyfiveToSeventyFourArr = [];
        const seventyFiveAndUpArr = [];

        // POP TRENDS
        const popChanges = [];
        const popForecasts = [];

        // FAMILY STATUS
        const getFamilyHouseholds = [];
        const getNonFamilyHouseholds = [];

        // CHILDREN POP
        const getHouseholdsWithChildren = [];

        // TYPES OF PEOPLE
        const getStudentsPop = [];
        const getWorkersPop = [];
        
        if (geoIdV4 && geoIdV4.length) {
            
            for (let n = 0; n < geoIdV4.length; n++) {
                
                const totalPopulation = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.population);
                const thisDayPop = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.population_Daytime);
                
                const zeroToFivePct = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.population_Aged_0_5_Pct);
                const sixTo11Pct = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.population_Aged_6_11_Pct);
                const twelveTo17Pct = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.population_Aged_12_17_Pct);
                const e18To24Pct = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.population_Aged_18_24_Pct);
                const t25To34Pct = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.population_Aged_25_34_Pct);
                const t35To44Pct = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.population_Aged_35_44_Pct);
                const f45To54Pct = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.population_Aged_45_54_Pct);
                const f55To64Pct = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.population_Aged_55_64_Pct);
                const s65To74Pct = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.population_Aged_65_74_Pct);
                const s75To84Pct = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.population_Aged_75_84_Pct);
                
                getPopulation.push(totalPopulation);
                getDayPop.push(thisDayPop);
                elevenAndUnderArr.push((totalPopulation * (zeroToFivePct * .01)) + (totalPopulation * (sixTo11Pct * .01)));
                twelveToSeventeenArr.push(totalPopulation * (twelveTo17Pct * .01));
                eighteenTo24Arr.push(totalPopulation * (e18To24Pct * .01));
                twentyfiveTo34Arr.push(totalPopulation * (t25To34Pct * .01));
                thirtyfiveTo44Arr.push(totalPopulation * (t35To44Pct * .01));
                fortyfiveTo54Arr.push(totalPopulation * (f45To54Pct * .01));
                fiftyfiveTo64Arr.push(totalPopulation * (f55To64Pct * .01));
                sixtyfiveToSeventyFourArr.push(totalPopulation * (s65To74Pct * .01));
                seventyFiveAndUpArr.push(totalPopulation * (s75To84Pct * .01));


                const popChangeSince2010 = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.population_Chg_Pct_2010);
                let popChangeWeightedValue = (geoIdV4 && (geoIdV4.length > 1)) ? (geoIdV4 && (geoIdV4[n].type !== 'N4')) ? (popChangeSince2010 * .4) : (popChangeSince2010 * .6) : popChangeSince2010;
                let popForecast5YearNum = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.population_5_Yr_Projection);

                popChanges.push(popChangeWeightedValue);
                popForecasts.push(popForecast5YearNum);

                const famHouseNum = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.households_Family);
                getFamilyHouseholds.push(famHouseNum);
                const nonFamHouseNum = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.households_Non_Family);
                getNonFamilyHouseholds.push(nonFamHouseNum);

                const totalHouseholdsNum = (famHouseNum + nonFamHouseNum);
                const famHouseWithChildPct = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.households_Family_W_Children_Pct);
                const famHouseWithChildNum = (totalHouseholdsNum * (famHouseWithChildPct * .01));
                const nonFamHouseWithChildPct = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.households_Non_Family_W_Children_Pct);
                const nonFamHouseWithChildNum = (totalHouseholdsNum * (nonFamHouseWithChildPct * .01));
                const householdsWChildrenNum = (famHouseWithChildNum + nonFamHouseWithChildNum);
                getHouseholdsWithChildren.push(householdsWChildrenNum);

                
                let totalWorkersPop = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.occupation_Soc_Cnt);
                let whiteCollarPct = geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.occupation_White_Collar_Pct);
                
                let studentsNum = (geoIdV4 && geoIdV4[n].commV4Data && (parseFloat(geoIdV4[n].commV4Data.enrollment_Public_Undergraduate_College_Pct) + parseFloat(geoIdV4[n].commV4Data.enrollment_Private_Undergraduate_College_Pct) + parseFloat(geoIdV4[n].commV4Data.enrollment_Private_Graduate_Or_Professional_School_Pct) + parseFloat(geoIdV4[n].commV4Data.enrollment_Public_Graduate_Or_Professional_School_Pct)));
                
                getWorkersPop.push((totalWorkersPop * (whiteCollarPct * .01)));
                getStudentsPop.push((totalPopulation * (studentsNum * .01)));
            }
        }

        const currentPop = getPopulation.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }); 
    
        // AGE GRAPH DATA

        const elevenAndUnder = elevenAndUnderArr.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const twelveToSeventeen = twelveToSeventeenArr.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const eighteenTo24 = eighteenTo24Arr.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const twentyfiveTo34 = twentyfiveTo34Arr.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const thirtyfiveTo44 = thirtyfiveTo44Arr.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const fortyfiveTo54 = fortyfiveTo54Arr.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const fiftyfiveTo64 = fiftyfiveTo64Arr.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const sixtyfiveToSeventyFour = sixtyfiveToSeventyFourArr.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const seventyFiveAndUp = seventyFiveAndUpArr.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);


        const thisAgeData = {
            ageData: {
                labels: [['0-11'], ['12-17'], ['18-24'], ['25-34'], ['35-44'], ['45-54'], ['55-64'], ['65-74'], ['75+']],
                datasets: [
                    {
                        label: 'Number of People Living in Area',
                        backgroundColor: [
                            'rgb(42, 207, 219)',
                            'rgb(255, 105, 180)',
                            'rgb(255, 105, 180)',
                            'rgb(52, 181, 122)',
                            'rgb(52, 181, 122)',
                            'rgb(219, 136, 42)',
                            'rgb(0, 0, 128)',
                            'rgb(0, 0, 128)',
                            'rgb(42, 207, 219)',
                        ],
                        data: [elevenAndUnder, twelveToSeventeen, eighteenTo24, twentyfiveTo34, thirtyfiveTo44, fortyfiveTo54, fiftyfiveTo64, sixtyfiveToSeventyFour, seventyFiveAndUp]
                    },
                ]
            }
        };


        // POP CHANGES

        const popChange2010 = popChanges.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });
        const pop5YearForecastTotal = popForecasts.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });
        
        const popChange = parseFloat(popChange2010).toFixed(0);
        const thisCurrentPop = parseFloat(currentPop);
        const fiveYearPop = parseFloat(pop5YearForecastTotal);
        const popForecast5 = ((fiveYearPop - thisCurrentPop)/fiveYearPop * 100);



        // FAMILY GRAPH DATA

        const familyHouseholds = getFamilyHouseholds.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });
        const nonFamilyHouseholds = getNonFamilyHouseholds.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });

        const thisFamilyData = {
            familyData: {
                labels: ['Family Households', 'Non-Family Households'],
                datasets: [
                    {
                        label: 'Number of Households',
                        backgroundColor: [
                            'rgb(187, 236, 205)',
                            'rgb(52, 181, 122)'
                        ],
                        data: [familyHouseholds, nonFamilyHouseholds]
                    },
                ]
            }
        };


        // HOUSEHOLDS WITH CHILDREN 

        const householdsWithChildren = getHouseholdsWithChildren.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const { filterables: { childrenHousesGauge: thisHousesWithChildrenGauge } = {}} = this.props;


        // SET NEEDLE ANGLE FROM 0 - 150

        let housesWithChildrenNeedle = 0;

        if (thisHousesWithChildrenGauge >= 95) {
            housesWithChildrenNeedle = 0;
        } else if (thisHousesWithChildrenGauge >= 80) {
            housesWithChildrenNeedle = 20;
        } else if (thisHousesWithChildrenGauge >= 70) {
            housesWithChildrenNeedle = 40;
        } else if (thisHousesWithChildrenGauge >= 60) {
            housesWithChildrenNeedle = 60;
        } else if (thisHousesWithChildrenGauge >= 50) {
            housesWithChildrenNeedle = 80;
        } else if (thisHousesWithChildrenGauge >= 40) {
            housesWithChildrenNeedle = 100;
        } else if (thisHousesWithChildrenGauge >= 30) {
            housesWithChildrenNeedle = 115;
        } else if (thisHousesWithChildrenGauge >= 20) {
            housesWithChildrenNeedle = 130;
        } else if (thisHousesWithChildrenGauge >= 10) {
            housesWithChildrenNeedle = 150;
        } else {
            housesWithChildrenNeedle = 74;
        }
        

        const setChildrenHousesGauge = (gauge) => {
            setTimeout(() => {
                const childrenHouseGauge = document.querySelector('.reportChildHouseCountPointer');
                let childrenHouseRotation = gauge;
                childrenHouseGauge && childrenHouseGauge.style.setProperty('--bottomChild', childrenHouseRotation);
            }, 200, { once: true });
        }
        
        thisHousesWithChildrenGauge && setChildrenHousesGauge(housesWithChildrenNeedle);


        // MOST COMMON PEOPLE 
        const { thisListing: { filterTags } = {}} = this.props;

        const daytimePop = getDayPop.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });
        const employeePop = getWorkersPop.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });
        const thisStudentPop = getStudentsPop.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });

        const studentPop = filterTags && filterTags.includes('students') ? thisStudentPop : 0;

        const commaNumber = (inc) => {
            let newNum = Number(inc);
            let numString = newNum.toLocaleString("en-US");
            return numString;
        }
        

        let thisAddressArray = [];
        thisAddressArray.push(getListingAddress);
        let addressString = thisAddressArray.toString();
        let thisListingAddress = addressString.split(',');
        

        return (
            <Paper id="populationReportPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 15, position: 'relative' }}>
                <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '6fr 1fr' }}>
                        <div className='reportAddress'>
                            <h2>{thisListingAddress[0]}</h2>
                        </div>
                        <div className='reportFourcastLogoArea'>
                            <img className='reportFourcastLogo' src={fourcastLogo} alt='Fourcast Reports Logo' />
                        </div>
                    </div>
                    <hr />
                    <div style={{ backgroundColor: 'rgba(21, 134, 84, 0.1)', marginTop: 25, height: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                            <h5 style={{ margin: '15px 0px 10px 20px' }}>Population Change:</h5>
                        </div>
                        <div style={{ height: 250, width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                            <div style={{ margin: 'auto', fontSize: 12, fontFamily: 'sans-serif', fontWeight: 'bold', textAlign: 'center' }}>
                                <h5>Since 2010</h5>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: 125, marginTop: 'auto', marginBottom: 'auto' }}>
                                    <h1 style={popChange > 0 ? { display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(21, 134, 84)' } : { display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(228, 114, 103)' }}>{`${popChange}%`}</h1>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 5 }}>
                                        {popChange > 0 ? greenArrow : popChange < 0 ? redArrow : '' }
                                    </div>
                                </div>
                            </div>
                            <div style={{ margin: 'auto', fontSize: 12, fontFamily: 'sans-serif', fontWeight: 'bold', textAlign: 'center' }}>
                                <h5>5 Year Forecast</h5>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: 125, margin: 'auto', height: '50%' }}>
                                    <h1 style={popForecast5 > 0 ? { display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(21, 134, 84)' } : { display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(228, 114, 103)' }}>{`${popForecast5.toFixed(0)}%`}</h1>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 5 }}>
                                        {popForecast5 > 0 ? greenArrow : popForecast5 < 0 ? redArrow : '' }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div style={{ width: '100%', height: 325, position: 'relative' }}>
                        <div style={{ height: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <p>Age Breakdown</p>
                        </div>
                        <div style={{ height: '95%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ position: 'relative', width: '100%', display: 'grid', gridTemplateColumns: '2fr 3fr', gap: 10 }}>
                                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                    <div style={{ width: '100%' }}>
                                        <div style={{ height: 50, backgroundColor: 'rgb(255, 105, 180, .1)', display: 'flex', justifyContent: 'right', alignItems: 'center', fontSize: 12, width: '100%', paddingRight: 15, fontWeight: 'bolder' }}>Gen Z</div>
                                        <div style={{ height: 50, backgroundColor: 'rgb(52, 181, 122, .1)', display: 'flex', justifyContent: 'right', alignItems: 'center', fontSize: 12, width: '100%', paddingRight: 15, fontWeight: 'bolder' }}>Millennials</div>
                                        <div style={{ height: 30, backgroundColor: 'rgb(219, 136, 42, .1)', display: 'flex', justifyContent: 'right', alignItems: 'center', fontSize: 12, width: '100%', paddingRight: 15, fontWeight: 'bolder' }}>Gen X</div>
                                        <div style={{ height: 50, backgroundColor: 'rgb(0, 0, 128, .1)', display: 'flex', justifyContent: 'right', alignItems: 'center', fontSize: 12, width: '100%', paddingRight: 15, fontWeight: 'bolder' }}>Baby Boomers</div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                    <div>
                                        <AgeGraph width={500} height={400} thisCurrentPop={thisCurrentPop} populationReport thisAgeData={thisAgeData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div style={{ backgroundColor: 'rgba(21, 134, 84, 0.1)', marginTop: 25, height: '10%', display: 'grid', gridTemplateColumns: '1fr 2fr', position: 'relative' }}>
                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                            <h5 style={{ margin: '15px 0px 10px 20px' }}>Common People In The Area:</h5>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 45 }}>
                            <div style={{ position: 'relative', width: '99%' }}>
                                <CommonPeople scores={scores} studentPop={studentPop} currentPop={currentPop} daytimePop={daytimePop} employeePop={employeePop} />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div style={{ width: '100%', height: 350, marginTop: 25, display: 'grid', gridTemplateColumns: '1fr 1fr', position: 'relative' }}>
                        <div style={{ height: '100%' }}>
                            <div style={{ position: 'relative', width: '99%' }}>
                                <div style={{ height: 325, position: 'relative' }}>
                                    <div style={{ height: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <p>Families vs Singles</p>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '78%' }}>
                                        <FamilyStatusGraph thisFamilyData={thisFamilyData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: '100%' }}>
                            <div style={{ position: 'relative', width: '99%' }}>
                                <div style={{ height: 325 }}>
                                    <div style={{ height: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <p>Number of Households WITH Children</p>
                                    </div>
                                    <div style={{ height: '78%', display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                            <h2>{commaNumber(householdsWithChildren)}</h2>
                                        </div>
                                        <div style={{ position: 'relative' }}>
                                            <div className='reportChildHouseCountHigh'>High</div>
                                            <div className='reportChildHouseCountGauge'>
                                                <div className='reportChildHouseCountPointer'></div>
                                            </div>
                                            <div className='reportChildHouseCountLow'>Low</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: 10, position: 'absolute', bottom: '1%', right: '1%', fontWeight: 800, color: 'darkgreen' }}>fourcastscore.com</div>
            </Paper>
        );
    }
}

export default PopulationReport;