import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import { Table } from '@mui/material';
import GreenArrow from '../../../Assets/greenArrow.png';
import RedArrow from '../../../Assets/redArrow.png';
import '../../listingDetailsLayouts/traffic/traffic.css';
import '../compareListings.css';

const greenArrow = <img className='greenArrow' src={GreenArrow} alt='' />;
const redArrow = <img className='redArrow' src={RedArrow} alt='' />;

class IncomeComparison extends Component {
    
    
    render() {

        const { listings } = this.props;

        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.scores.fourcastScore - a.scores.fourcastScore});
        
        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }

        let getThisListingsIncome = (thisListing) => {

            // INCOME GAUGE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            const thisListingsIncomeGauge = thisListing && thisListing.scores && thisListing.scores.incomeGauge && thisListing.scores.incomeGauge;

            let incomeNeedle = (thisListingsIncomeGauge && (thisListingsIncomeGauge > 0)) ? thisListingsIncomeGauge : 50;


            // INCOME GRAPH DATA !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            // get current geoIdV4 array
            const attomData = thisListing && thisListing.attomData && thisListing.attomData;
            const geoIdV4 = thisListing && thisListing.attomData && thisListing.attomData.geoIdV4 && thisListing.attomData.geoIdV4;
        
            let averageHouseholdIncome = 0;
            let averageIndividualIncome = 0;
            const getTotalNumOfHouseholds = [];

                // number of people in each income bracket
            let householdIncomeFifteenOrLess = 0;
            let householdIncomeFifteenToTwentyFive = 0;
            let householdIncomeTwentyFiveToThirtyFive = 0;
            let householdIncomeThirtyFiveToFifty = 0;
            let householdIncomeFiftyToSeventyFive = 0;
            let householdIncomeSeventyFiveToOneHundred = 0;
            let householdIncomeOneHundredToOneHundredTwentyFive = 0;
            let householdIncomeOneHundredTwentyFiveToOneHundredFifty = 0;
            let householdIncomeOneHundredFiftyToTwoHundred = 0;
            let householdIncomeTwoHundredAndMore = 0;
            
            if (geoIdV4 && geoIdV4.length) {
            
                // avg income numbers
                let primaryIndIncArea = null;
                let secondaryIndIncArea = null;
                let primaryHouseIncArea = null;
                let secondaryHouseIncArea = null;
    
                
                for (let g = 0; g < geoIdV4.length; g++) {
                    
                    // INCOME DATA
                    if (geoIdV4 && (geoIdV4[g].type === 'N4')) {
                        // income data
                        primaryHouseIncArea = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.avg_Household_Income;
                        primaryIndIncArea = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.household_Income_Per_Capita;
                        const getGlobalTotalHouseholdsFamNum = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Non_Family;
                        const getGlobalTotalHouseholdsNonFamNum = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Family;
                        const globalTotalHouseholds = (getGlobalTotalHouseholdsFamNum + getGlobalTotalHouseholdsNonFamNum);
                        getTotalNumOfHouseholds.push(globalTotalHouseholds);
                        householdIncomeFifteenOrLess += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_Less_Than_15000_Pct * .01));
                        householdIncomeFifteenToTwentyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_15000_24999_Pct * .01));
                        householdIncomeTwentyFiveToThirtyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_25000_34999_Pct * .01));
                        householdIncomeThirtyFiveToFifty += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_35000_49999_Pct * .01));
                        householdIncomeFiftyToSeventyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_50000_74999_Pct * .01));
                        householdIncomeSeventyFiveToOneHundred += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_75000_99999_Pct * .01));
                        householdIncomeOneHundredToOneHundredTwentyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_100000_124999_Pct * .01));
                        householdIncomeOneHundredTwentyFiveToOneHundredFifty += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_125000_149999_Pct * .01));
                        householdIncomeOneHundredFiftyToTwoHundred += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_150000_199999_Pct * .01));
                        householdIncomeTwoHundredAndMore += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_200000_And_Over_Pct * .01));
                    } else {
                        // income data
                        secondaryHouseIncArea = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.avg_Household_Income;
                        secondaryIndIncArea = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.household_Income_Per_Capita;
                        const getGlobalTotalHouseholdsFamNum = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Non_Family;
                        const getGlobalTotalHouseholdsNonFamNum = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Family;
                        const globalTotalHouseholds = (getGlobalTotalHouseholdsFamNum + getGlobalTotalHouseholdsNonFamNum);
                        getTotalNumOfHouseholds.push(globalTotalHouseholds);
                        householdIncomeFifteenOrLess += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_Less_Than_15000_Pct * .01));
                        householdIncomeFifteenToTwentyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_15000_24999_Pct * .01));
                        householdIncomeTwentyFiveToThirtyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_25000_34999_Pct * .01));
                        householdIncomeThirtyFiveToFifty += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_35000_49999_Pct * .01));
                        householdIncomeFiftyToSeventyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_50000_74999_Pct * .01));
                        householdIncomeSeventyFiveToOneHundred += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_75000_99999_Pct * .01));
                        householdIncomeOneHundredToOneHundredTwentyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_100000_124999_Pct * .01));
                        householdIncomeOneHundredTwentyFiveToOneHundredFifty += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_125000_149999_Pct * .01));
                        householdIncomeOneHundredFiftyToTwoHundred += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_150000_199999_Pct * .01));
                        householdIncomeTwoHundredAndMore += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_200000_And_Over_Pct * .01));
                    }
    
                }
                
                let avgHouseInc = (primaryHouseIncArea === null) ? secondaryHouseIncArea : parseFloat(Math.ceil((primaryHouseIncArea * .6) + (secondaryHouseIncArea * .4)).toFixed(0));
                let avgIndInc = (primaryIndIncArea === null) ? secondaryIndIncArea : parseFloat(Math.ceil((primaryIndIncArea * .6) + (secondaryIndIncArea * .4)).toFixed(0));
                averageIndividualIncome = avgIndInc;
                averageHouseholdIncome = avgHouseInc;
            }
    
            // number of people in each income bracket
            const graphIncomeUnderSeventyFive = [householdIncomeFifteenOrLess, householdIncomeFifteenToTwentyFive, householdIncomeTwentyFiveToThirtyFive, householdIncomeThirtyFiveToFifty, householdIncomeFiftyToSeventyFive];
            const graphIncomeSeventyFiveToOneFifty = [householdIncomeSeventyFiveToOneHundred, householdIncomeOneHundredToOneHundredTwentyFive, householdIncomeOneHundredTwentyFiveToOneHundredFifty];
            
            const underSeventyFiveSum = graphIncomeUnderSeventyFive.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const seventyFiveToOneFiftySum = graphIncomeSeventyFiveToOneFifty.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const oneFiftyToTwoHundredSum = parseFloat(householdIncomeOneHundredFiftyToTwoHundred).toFixed(0);
            const overTwoHundredSum = parseFloat(householdIncomeTwoHundredAndMore).toFixed(0);
    
            const thisIncomeData = {
                incomeData: {
                    labels: [' Under $75k', '$75k-$150k', '$150k-$200k', 'Over $200k'],
                    datasets: [
                        {
                            backgroundColor: ['rgb(187, 236, 205)', 'rgb(124, 218, 158)', 'rgb(60, 200, 112)', 'rgb(21, 76, 41)'],
                            data: [underSeventyFiveSum, seventyFiveToOneFiftySum, oneFiftyToTwoHundredSum, overTwoHundredSum]
                        }
                    ],
                }
            };
            
            // Income Trends Data
            // Old commData average income for change analyses and historical datapoints
            const totalNumOfHouseholds = getTotalNumOfHouseholds.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });
            const thisOldCommData = thisListing && thisListing.oldCommData && thisListing.oldCommData.sort(function(a, b){return a.year-b.year});
            
            const hhdNum = thisOldCommData && thisOldCommData.length && thisOldCommData[0].oldCommData && thisOldCommData[0].oldCommData.hhd;
            const averageHouseholdIncomeIn2021 = thisOldCommData && thisOldCommData.length && thisOldCommData[0].oldCommData && thisOldCommData[0].oldCommData.inccyavehh;
            
            // insert logic eventually to get the year from the "year" value in the oldCommData object. Right now it is just using 2021, however, the more more "old" neighborhoods being created as the years go on, eventually the year from the created date will have to be extracted.
            //const previousCommDataYear = thisOldCommData && thisOldCommData.length && thisOldCommData[0].year && thisOldCommData[0].year;
            
            const previousCommDataYear = (attomData && attomData.oldCommData) ? attomData.oldCommData.map(oldCommDataObj => oldCommDataObj.year) : '2021';
            const incomeChangeSince2021 = parseFloat(((averageHouseholdIncome - averageHouseholdIncomeIn2021) / averageHouseholdIncomeIn2021) * 100).toFixed(0);
            const hhdChangeSince2021 = parseFloat(((totalNumOfHouseholds - hhdNum) / hhdNum) * 100).toFixed(0);
            
            const incomeObj = {address: thisListing.listingAddress, incomeGaugePointer: incomeNeedle, thisIncomeData: thisIncomeData, previousCommDataYear: previousCommDataYear, incomeChangeSince2021: incomeChangeSince2021, hhdChangeSince2021: hhdChangeSince2021, averageIndividualIncome: averageIndividualIncome, averageHouseholdIncome: averageHouseholdIncome};
            return incomeObj;
        }


        // SORT BY REVIEW COUNT
        const sortedByIncome = sortedByFourcastScore && sortedByFourcastScore.map(listing => getThisListingsIncome(listing)).sort(function(a, b){return b.averageHouseholdIncome - a.averageHouseholdIncome});
        
        let listing1 = (sortedByIncome && sortedByIncome.length && (sortedByIncome[0] !== undefined)) ? sortedByIncome[0] : null;
        let listing2 = (sortedByIncome && sortedByIncome.length && (sortedByIncome[1] !== undefined)) ? sortedByIncome[1] : null;
        let listing3 = (sortedByIncome && sortedByIncome.length && (sortedByIncome[2] !== undefined)) ? sortedByIncome[2] : null;
        let listing4 = (sortedByIncome && sortedByIncome.length && (sortedByIncome[3] !== undefined)) ? sortedByIncome[3] : null;
        
        const setIncomeGaugePointer = async (gauge, el) => {
            setTimeout(async () => {
                const incomeGauge = await document.querySelector(el);
                let incomeRotation = gauge;                
                incomeGauge && await incomeGauge.style.setProperty('--bottomIncome', incomeRotation);
            }, 100, { once: true });
        }

        (listing1 !== null) && listing1.incomeGaugePointer && setIncomeGaugePointer(listing1.incomeGaugePointer, '.incomeComparisonPointer1');
        (listing2 !== null) && listing2.incomeGaugePointer && setIncomeGaugePointer(listing2.incomeGaugePointer, '.incomeComparisonPointer2');
        (listing3 !== null) && listing3.incomeGaugePointer && setIncomeGaugePointer(listing3.incomeGaugePointer, '.incomeComparisonPointer3');
        (listing4 !== null) && listing4.incomeGaugePointer && setIncomeGaugePointer(listing4.incomeGaugePointer, '.incomeComparisonPointer4');
        
        if (listing1) {listing1.color = 'rgba(52, 181, 122, .2)'};
        if (listing2) {listing2.color = 'rgba(223, 82, 69, .2)'};
        if (listing3) {listing3.color = 'rgba(250, 204, 68, .2)'};
        if (listing4) {listing4.color = 'rgba(30, 144, 255, .2)'};

        const commaNumber = (num) => {
            let newNum = Number(num);
            let numString = newNum.toLocaleString("en-US");
            return numString;
        }

        let displayIndIncome = (listing) => {
            return (
                <>
                    {(listing && listing.averageIndividualIncome) ? ( 
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ fontWeight: 800, marginLeft: 5 }}>{`$${listing && commaNumber(listing.averageIndividualIncome)}`}</div>
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }
        let displayHouseIncome = (listing) => {
            return (
                <>
                    {(listing && listing.averageHouseholdIncome) ? ( 
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ fontWeight: 800, marginLeft: 5 }}>{`$${listing && commaNumber(listing.averageHouseholdIncome)}`}</div>
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }
        let displayIncomeChange = (listing) => {
            return (
                <>
                    {(listing && listing.incomeChangeSince2021) ? ( 
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 100, marginLeft: 'auto', marginRight: 'auto' }}>
                            <div className='incomeChangeValue'>{`${listing.incomeChangeSince2021}%`}</div>
                            <div className='incomeArrows'>
                                {(listing && (listing.incomeChangeSince2021 > 0)) ? greenArrow : (listing && (listing.incomeChangeSince2021 < 0)) ? redArrow : '' }
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }
        let displayHouseChange = (listing) => {
            return (
                <>
                    {(listing && listing.hhdChangeSince2021) ? ( 
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 100, marginLeft: 'auto', marginRight: 'auto' }}>
                            <div className='incomeChangeValue'>{`${listing.hhdChangeSince2021}%`}</div>
                            <div className='incomeArrows'>
                                {(listing && (listing.hhdChangeSince2021 > 0)) ? greenArrow : (listing && (listing.hhdChangeSince2021 < 0)) ? redArrow : '' }
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }

        let filteredListingsIndIncome = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsIndInc = getThisListingsIncome(listing);
            return (
                <td style={{ fontSize: 14, padding: 10 }}>
                    {thisListingsIndInc && displayIndIncome(thisListingsIndInc)}
                </td>
            )
        });
        let filteredListingsHouseIncome = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsHouseInc = getThisListingsIncome(listing);
            return (
                <td style={{ fontSize: 14, padding: 10 }}>
                    {thisListingsHouseInc && displayHouseIncome(thisListingsHouseInc)}
                </td>
            )
        });
        let filteredListingsIncomeChange = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsInc = getThisListingsIncome(listing);
            return (
                <td style={{ fontSize: 14, padding: 10 }}>
                    {thisListingsInc && displayIncomeChange(thisListingsInc)}
                </td>
            )
        });
        let filteredListingsHouseChange = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsHouse = getThisListingsIncome(listing);
            return (
                <td style={{ fontSize: 14, padding: 10 }}>
                    {thisListingsHouse && displayHouseChange(thisListingsHouse)}
                </td>
            )
        });

        const previousCommDataYear = sortedByFourcastScore && sortedByFourcastScore.length && sortedByFourcastScore[0] && sortedByFourcastScore[0].previousCommDataYear && sortedByFourcastScore[0].previousCommDataYear;
        
        return (
            <div style={{ position: 'relative', width: '98%' }}>
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Local Income</div>
                </div>
                <div style={{ marginTop: 20 }}>
                    <div>
                        <Table style={{ tableLayout: 'fixed' }}>
                            <thead>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '20%' }}></th>
                                    {sortedByIncome && sortedByIncome.map(listing => {return (<th style={{ padding: 20, color: 'darkgreen' }}>{addressStyle(listing.address)}</th>)})}
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '20%' }}>
                                       Individual Income
                                    </th>
                                    {filteredListingsIndIncome}
                                </tr>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '20%' }}>
                                        Household Income
                                    </th>
                                    {filteredListingsHouseIncome}
                                </tr>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '20%' }}>
                                        {`Income Change Since ${(previousCommDataYear !== undefined ? previousCommDataYear : '2021')}`}
                                    </th>
                                    {filteredListingsIncomeChange}
                                </tr>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '20%' }}>
                                        {`Households Change Since ${(previousCommDataYear !== undefined ? previousCommDataYear : '2021')}`}
                                    </th>
                                    {filteredListingsHouseChange}
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'left', marginTop: 20 }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Average Income Gauge</div>
                </div>
                <div className='busyTimesCompareGaugeArea' style={{ position: 'relative', display: 'flex', marginTop: 10 }}>
                    <div style={{ flex: 1 }}>
                        <div style={{ width: '90%', margin: 'auto auto' }}>
                            <Table style={{ tableLayout: 'fixed' }}>
                                <tbody>
                                    {sortedByIncome && sortedByIncome.map(listing => 
                                        <tr style={{ border: '1px solid lightgray', textAlign: 'center' }}>
                                            <td style={{ fontSize: 14, padding: 20, backgroundColor: listing.color }}>
                                                {listing && addressStyle(listing && listing.address)}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        <div className='traffGaugeArea' style={{ flex: 1, width: '50%', height: 100, position: 'relative', marginLeft: 'auto', marginRight: 'auto' }}>
                            <div className='dayTrafficComparisonGauge'></div>
                            {((listing1 !== null) && (listing1.incomeGaugePointer)) ? <div className='incomeComparisonPointer1'></div> : null}
                            {((listing2 !== null) && (listing2.incomeGaugePointer)) ? <div className='incomeComparisonPointer2'></div> : null}
                            {((listing3 !== null) && (listing3.incomeGaugePointer)) ? <div className='incomeComparisonPointer3'></div> : null}
                            {((listing4 !== null) && (listing4.incomeGaugePointer)) ? <div className='incomeComparisonPointer4'></div> : null}
                            <div className='dayTrafficComparisonGaugeHigh'>High</div>
                            <div className='dayTrafficComparisonGaugeLow'>Low</div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'left', marginTop: 20 }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Local Income Breakdown</div>
                </div>
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', marginTop: 10, justifyContent: 'space-around' }}>
                    {listing1 !== null ? (<div style={{ position: 'relative', height: 175, width: '25%' }}>
                        <Doughnut
                            data={listing1.thisIncomeData.incomeData}
                            height={175}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Household Income in the Area',
                                    fontSize: 14,
                                    color: 'black'
                                },
                                plugins: {
                                    datalabels: {
                                    display: false,
                                    },
                                    legend: {
                                        display: true,
                                        position: 'top',
                                    }
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false
                                        },
                                        ticks: {
                                            display: false
                                        }
                                    },
                                },
                            }}
                        />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing1.address)}</div>
                    </div>) : null}
                    {listing2 !== null ? (<div style={{ position: 'relative', height: 175, width: '25%' }}>
                        <Doughnut
                            data={listing2.thisIncomeData.incomeData}
                            height={175}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Household Income in the Area',
                                    fontSize: 14,
                                    color: 'black'
                                },
                                plugins: {
                                    datalabels: {
                                    display: false,
                                    },
                                    legend: {
                                        display: true,
                                        position: 'top',
                                    }
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false
                                        },
                                        ticks: {
                                            display: false
                                        }
                                    },
                                },
                            }}
                        />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing2.address)}</div>
                    </div>) : null}
                    {listing3 !== null ? (<div style={{ position: 'relative', height: 175, width: '25%' }}>
                        <Doughnut
                            data={listing3.thisIncomeData.incomeData}
                            height={175}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Household Income in the Area',
                                    fontSize: 14,
                                    color: 'black'
                                },
                                plugins: {
                                    datalabels: {
                                    display: false,
                                    },
                                    legend: {
                                        display: true,
                                        position: 'top',
                                    }
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false
                                        },
                                        ticks: {
                                            display: false
                                        }
                                    },
                                },
                            }}
                        />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing3.address)}</div>
                    </div>) : null}
                    {listing4 !== null ? (<div style={{ position: 'relative', height: 175, width: '25%' }}>
                        <Doughnut
                            data={listing4.thisIncomeData.incomeData}
                            height={175}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Household Income in the Area',
                                    fontSize: 14,
                                    color: 'black'
                                },
                                plugins: {
                                    datalabels: {
                                    display: false,
                                    },
                                    legend: {
                                        display: true,
                                        position: 'top',
                                    }
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false
                                        },
                                        ticks: {
                                            display: false
                                        }
                                    },
                                },
                            }}
                        />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing4.address)}</div>
                    </div>) : null}
                </div>
            </div>
        );
    }
}

export default IncomeComparison;