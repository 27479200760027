import React, { Component } from 'react';
import { Card } from '@material-ui/core';
import { Container, Row, Col } from 'reactstrap';
import amdfStorefront from '../../Assets/amdfStorefront.png';
import './description.css';

class AMDFHighlight extends Component {
    

    render() { 
        return ( 
            <Container style={{ height: 900, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }} fluid>
                <div style={{ width: '100%', height: 650, margin: 'auto' }}>
                    <div style={{ marginTop: 50, display: 'grid', gridTemplateColumns: '2fr 5fr' }}>
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center', position: 'relative' }}>
                            <div className="clientQuoteBox"></div>
                            <div style={{ width: '70%' }}>
                                <Row style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, fontWeight: 'bold', fontStyle: 'italic', marginTop: 40, width: '95%' }}>
                                    “I was really surprised and impressed with the space Fourcast matched us with during our search for a second NYC location - it is literally across the street from a space that we tried to get during our previous search...”
                                </Row>
                                <Row style={{ marginTop: 20, position: 'relative' }}>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontStyle: 'italic', fontWeight: 'bold', fontSize: 14 }}>Antoine</div>
                                </Row>
                                <Row>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 12 }}>Aux Merveilleux de Fred</div>
                                </Row>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                            <Card id="clientPic">
                                <img id="amdfStorefront" src={amdfStorefront} alt="amdfStore" />
                            </Card>
                        </div>
                    </div>
                </div>
            </Container>
         );
    }
}
 
export default AMDFHighlight;