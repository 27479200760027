import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';



export default function Media(props) {
  const { loading = false } = props;

  return (
    <Grid container wrap="nowrap">
      {(loading ? Array.from(new Array(3)) : null).map((item, index) => (
        <Box key={index} sx={{ width: 300, mx: 'auto', my: 15 }}>
            <Skeleton variant="rectangular" width={300} height={208} />
            <Box sx={{ pt: 1 }}>
              <Skeleton />
              <Skeleton width="60%" />
              <Skeleton width="60%" />
            </Box>
        </Box>
      ))}
    </Grid>
  );
}

Media.propTypes = {
  loading: PropTypes.bool,
};


