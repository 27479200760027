import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Box, Tabs, Tab, Container } from '@mui/material';
import manhattanSkyline from '../../Assets/manhattan_skyline.png';
import fourcastLogoMobile from '../../Assets/fourcast_alt_logo_green.png';
import MobileNavbar from './mobileNavbar';
import './welcome.css';

class MobileWelcome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            screenWidth: window.screen.width,
            mobile: true,
            goToPage: 'listings',
            line1FontSize: 24,
            line2FontSize: 28
        }
    }

    componentDidMount() {
        window && window.addEventListener('resize', this.mobileMode.bind(this));
        window && window.addEventListener('resize', this.setFontSizes.bind(this));
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth });
        } else {
            this.setState({ mobile: false });
        }

        if (screenWidth && (screenWidth > 900)) {
            this.setState({ line1FontSize: 44, line2FontSize: 48 })
        } else if (screenWidth && (screenWidth > 800)) {
            this.setState({ line1FontSize: 40, line2FontSize: 44 })
        } else if (screenWidth && (screenWidth > 700)) {
            this.setState({ line1FontSize: 36, line2FontSize: 40 })
        } else if (screenWidth && (screenWidth > 600)) {
            this.setState({ line1FontSize: 32, line2FontSize: 36 })
        } else if (screenWidth && (screenWidth > 500)) {
            this.setState({ line1FontSize: 28, line2FontSize: 32 })
        } else {
            this.setState({ line1FontSize: 24, line2FontSize: 28 })
        }
    }

    componentWillUnmount() {
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
        window && window.removeEventListener('resize', this.setFontSizes.bind(this));
    }

    mobileMode() {
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth, mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }

    setFontSizes() {
        let screenWidth = window.screen.width;
        if (screenWidth && (screenWidth > 900)) {
            this.setState({ line1FontSize: 44, line2FontSize: 48 })
        } else if (screenWidth && (screenWidth > 800)) {
            this.setState({ line1FontSize: 40, line2FontSize: 44 })
        } else if (screenWidth && (screenWidth > 700)) {
            this.setState({ line1FontSize: 36, line2FontSize: 40 })
        } else if (screenWidth && (screenWidth > 600)) {
            this.setState({ line1FontSize: 32, line2FontSize: 36 })
        } else if (screenWidth && (screenWidth > 500)) {
            this.setState({ line1FontSize: 28, line2FontSize: 32 })
        } else {
            this.setState({ line1FontSize: 24, line2FontSize: 28 })
        }
    }

    render() { 

        const { screenWidth, mobile, goToPage, line1FontSize, line2FontSize } = this.state;
        const backgroundPicHeight = (screenWidth / .8);

        const tabClick = (e, value) => this.setState({ goToPage: value });

        return (
            <>
            <Container style={{ width: screenWidth, height: backgroundPicHeight, position: 'relative', overflow: 'hidden' }} fluid>
                <div>
                    <img alt='welcome to fourcast background' src={manhattanSkyline} id='landingPageMobileBackgroundPic' />
                </div>
                <div style={{ position: 'absolute', left: '50%', top: '15%', transform: 'translate(-50%, -15%)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img style={{ height: '100%', width: '100%' }} src={fourcastLogoMobile} alt='welcome to fourcast mobile logo'  />
                </div>
                <div style={{ height: '75%', width: '100%' }}>
                    <div style={{ width: '100%', height: '50%', marginTop: '15%', display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '90%' }}>
                            <div style={{ height: '100%', width: '100%', zIndex: 2 }}>
                                <div style={{ textAlign: 'center', fontFamily: 'Arial, Helvetica, sans-serif', zIndex: 2, fontSize: line1FontSize, fontWeight: 'bold' }}>Find Retail Space</div>
                                <div style={{ textAlign: 'center', fontFamily: 'Arial, Helvetica, sans-serif', zIndex: 2, fontSize: line2FontSize, fontWeight: 'bold' }}>Easier. Faster. Smarter.</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: '40%' }}>
                        <div style={{ height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Tabs
                                    value={goToPage}
                                    onChange={tabClick}
                                    textColor="primary"
                                    indicatorColor="primary"
                                    aria-label="secondary tabs example"
                                    TabIndicatorProps={{
                                        style: {
                                          background: "rgb(52, 181, 122)",
                                        }
                                      }}
                                >
                                    <Tab style={{ marginLeft: 10, marginRight: 10, fontSize: 14, fontWeight: 600, outline: 'none' }} value="listings" label="Browse" />
                                    <Tab style={{ marginLeft: 10, marginRight: 10, fontSize: 14, fontWeight: 600, outline: 'none' }} value="customSearch" label="Match" />
                                    <Tab style={{ marginLeft: 10, marginRight: 10, fontSize: 14, fontWeight: 600, outline: 'none' }} value="reports" label="Reports" />
                                </Tabs>
                            </Box>
                        </div>
                        <div style={{ height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Link to={`/${goToPage}`} style={{ textDecoration: 'none' }}>
                                <Button id="explorePropertiesBtn">
                                    Get Started
                                </Button>
                            </Link>
                        </div>
                    </div>
                </div>
            </Container>
            </>
        );
    }
}

export default MobileWelcome;