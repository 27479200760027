import axios from 'axios';
import { GET_PRICES, STRIPE_LOADING, PRICES_ERROR } from './types';
import { returnErrors } from './errorActions';

// Pointing Axios baseURL towards AWS API Gateway URL
// Will most likely be changed again at some point because of the /dev
const axiosAWS = axios.create({
    baseURL: 'https://bgeg45v1tk.execute-api.us-east-1.amazonaws.com/dev'
});

export const getPrices = () => (dispatch, getState) => {
    dispatch(setStripeLoading());
    const tokenConfig = getState => {
    
        // Get Token from localStorage
        const token = getState().auth.token;
    
        // Headers 
        const config = {
            headers: {
                "Content-type": "application/json"
            }
        }
    
        // If token, add to headers
        if (token) {
            config.headers['Authorization'] = token;
        }
    
        return config;
    }
    axiosAWS
        .get('/api/subscriptions/prices', tokenConfig(getState))
        .then(res => 
            dispatch({
                type: GET_PRICES,
                payload: res.data
            })
        ).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, 'PRICES_ERROR'));
            dispatch({
                type: PRICES_ERROR
            })
        })
};



export const setStripeLoading = () => {
    return {
        type: STRIPE_LOADING
    }
};