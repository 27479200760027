import React, { Component } from 'react';
import './traffic.css';
import { Col, Row } from 'react-bootstrap';

class BusyRoadTraffic extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            thisListing: props.thisListing,
        }
    }
    
    render() {
        const orangeRoads = [
            '1st Ave', '2nd Ave', '3rd Ave', 'Lexington Ave', 'Park Ave', 'Madison Ave', '5th Ave', '6th Ave', '7th Ave', 'Broadway', 
            '8th Ave', '9th Ave', '10th Ave', 'South St', 'State St', 'Water St', 'Pearl St', 'West St', 'Saint James Place', 'Bowery',
            'Pike St', 'Allen St', 'Canal St', 'Laight St', 'Beach St', 'Lafayette St', 'Broome St', 'Watts St', 'Varick St',
            'Grand St', 'Norfolk St', 'Kenmare St', 'Delancey St', 'Essex St', 'Houston St', '14th St', 'Cooper Square', 'West 30th St',
            'Dyer Ave', '34th St', '42nd St', '57th St', 'Columbus Ave', 'Amsterdam Ave', 'Adam Clayton Powell Jr. Boulevard',
            '125th St', 'West 155th St', 'West 181st St', 'Nagle Ave', 'Dyckman St', 'West 207th St', 'First Ave', 'Second Ave', 'Third Ave', 'Fifth Ave', 'Sixth Ave', 'Avenue of the Americas', 'Seventh Ave', 'Eighth Ave', 'Ninth Ave', 'Tenth Ave'
        ];
        
        const yellowRoads = [
            'Greenwich St', 'Battery Place', 'Trinity Place', 'Maiden Lane', 'Vesey St', 'Park Row', 'Barclay St', 'Murray St', 'Centre St', 'Frankfort St', 'Chambers St', 
            'Reade St', 'Church St', 'West Broadway', 'Hudson St', 'Madison St', 'East Broadway', 'Division St', 'Forsyth St', 'Montgomery St',
            'Grand St', 'Chrystie St', 'Clarkson St', 'Greenwich Ave', 'West 8th St', 'Ave A', 'Ave B', 'Ave C', 'Ave D',
            'West 15th St', 'E 23rd St', 'East 23rd St', '11th Ave', '36th St', '37th St', '45th St', '46th St', '49th St', '50th St', '53rd St', '54th St',
            'East 59th St', 'Central Park South', 'West 62nd St', 'East 60th St', '65th St', '66th St', 'York Ave', '72nd St', 'West End Ave', '79th St',
            'West 81st St', '86th St', '96th St', '106th St', 'Riverside Drive', 'Cathedral Parkway', 'Central Park North', 'Malcolm X Boulevard', 'Saint Nicholas Ave',
            'Frederick Douglas Boulevard', 'West 145th St', 'West 158th St', 'Fort Washington Ave', 'West 165th St', 'West 178th St', 'West 179th St', 'Fort George', 'Sherman Ave', 'Eleventh Ave'
        ];
        
        const specialSideStreets = [
            'Wall St', 'Bleecker', 'Mott St', 'Mulberry St', 'Hester St', 'Price St', 'Spring St', 'Christopher St',
            'MacDougal St', 'Thompson St', 'West 3rd St', 'West 4th St', 'Elizabeth St'
        ];

        const { screenWidth, thisListing: { listingAddress: getListingAddress } = {}} = this.props;
        let thisAddressArray = [];
        thisAddressArray.push(getListingAddress);
        let addressString = thisAddressArray.toString();
        let thisListingAddress = addressString.split(", ");
        let thisStreetName = '';
        
        if (thisListingAddress.length === 3) {
            thisStreetName = `${thisListingAddress[0]} ${thisListingAddress[1]} ${thisListingAddress[2]}`;
        } else {
            thisStreetName = `${thisListingAddress[0]} ${thisListingAddress[1]}`;
        }
        
        let thisListingsStreetName = thisStreetName.toString();
        
        const orangeRoad = [];
        const yellowRoad = [];
        const specialSideStreet = [];

        for (let i = 0; i < orangeRoads.length; i++) {
            if (thisListingsStreetName.includes(orangeRoads[i])) {
                orangeRoad.push(orangeRoads[i]);
            }
        }

        for (let i = 0; i < yellowRoads.length; i++) {
            if (thisListingsStreetName.includes(yellowRoads[i])) {
                yellowRoad.push(yellowRoads[i]);
            }
        }

        for (let i = 0; i < specialSideStreets.length; i++) {
            if (thisListingsStreetName.includes(specialSideStreets[i])) {
                specialSideStreet.push(specialSideStreets[i]);
            }
        }

        let selectedRoad = null;

        if (orangeRoad.length === 1) {
            selectedRoad = 'orange';
        } else if (yellowRoad.length === 1) {
            selectedRoad = 'yellow';
        } else if (specialSideStreet.length === 1) {
            selectedRoad = 'busySide';
        } else {
            selectedRoad = 'side';
        }

        const roadSelectionBox = <div className='roadSelectionBox'></div>;

        return (
            <div>
                {((screenWidth > 1000) || (screenWidth === undefined)) ? (<Row style={{ width: '310px', marginTop: 10, marginLeft: '.5px', paddingTop: '20px', textAlign: 'center' }}>
                    <Col sm={3} className="busyRoadVisibilityIcon">
                        <Row className="roadIcon">
                           <div className="orangeRoadIcon1"></div>
                           <div className="orangeRoadIcon2"></div>
                           <div className="orangeRoadIcon3"></div>
                        </Row>
                        <Row className="roadDescription">
                            <p>Very Busy Road</p>
                            {selectedRoad === 'orange' ? roadSelectionBox : null}
                        </Row>
                    </Col>
                    <Col sm={3} className="busyRoadVisibilityIcon">
                        <Row className="roadIcon">
                            <div className="yellowRoadIcon1"></div>
                            <div className="yellowRoadIcon2"></div>
                        </Row>
                        <Row className="roadDescription2">
                            <p>Busy Road</p>
                            {selectedRoad === 'yellow' ? roadSelectionBox : null}
                        </Row>
                    </Col>
                    <Col sm={3} className="busyRoadVisibilityIcon">
                        <Row className="roadIcon">
                            <div className="specialSideStreetIcon"></div>
                        </Row>
                        <Row className="roadDescription">
                            <p>Busy Side Street</p>
                            {selectedRoad === 'busySide' ? roadSelectionBox : null}
                        </Row>
                    </Col>
                    <Col sm={3} className="busyRoadVisibilityIcon">
                        <Row className="roadIcon">
                            <div className="sideStreetIcon"></div>
                        </Row>
                        <Row className="roadDescription2">
                            <p>Side Street</p>
                            {selectedRoad === 'side' ? roadSelectionBox : null}
                        </Row>
                    </Col>
                </Row>) : (<div style={{ maxWidth: screenWidth, marginTop: 10, marginLeft: '.5px', paddingTop: '20px', textAlign: 'center', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', position: 'relative' }}>
                    <div className="busyRoadVisibilityIcon">
                        <div className="roadIcon">
                           <div className="orangeRoadIcon1"></div>
                           <div className="orangeRoadIcon2"></div>
                           <div className="orangeRoadIcon3"></div>
                        </div>
                        <div className="roadDescription">
                            <p>Very Busy Road</p>
                            {selectedRoad === 'orange' ? roadSelectionBox : null}
                        </div>
                    </div>
                    <div className="busyRoadVisibilityIcon">
                        <div className="roadIcon">
                            <div className="yellowRoadIcon1"></div>
                            <div className="yellowRoadIcon2"></div>
                        </div>
                        <div className="roadDescription2">
                            <p>Busy Road</p>
                            {selectedRoad === 'yellow' ? roadSelectionBox : null}
                        </div>
                    </div>
                    <div className="busyRoadVisibilityIcon">
                        <div className="roadIcon">
                            <div className="specialSideStreetIcon"></div>
                        </div>
                        <div className="roadDescription">
                            <p>Busy Side Street</p>
                            {selectedRoad === 'busySide' ? roadSelectionBox : null}
                        </div>
                    </div>
                    <div className="busyRoadVisibilityIcon">
                        <div className="roadIcon">
                            <div className="sideStreetIcon"></div>
                        </div>
                        <div className="roadDescription2">
                            <p>Side Street</p>
                            {selectedRoad === 'side' ? roadSelectionBox : null}
                        </div>
                    </div>
                </div>)}
            </div>
        )
    }
}

export default BusyRoadTraffic;