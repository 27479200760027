import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

class IncomeGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thisListing: props.thisListing,
            incomeData: props.thisIncomeData,
        }
    }

    static defaultProps = {
        displayTitle: true,
    }
    
    render() {
        
        const { thisIncomeData: { incomeData: incomeGraphData } = {}} = this.props;
        return (
            <div className="income-graph">
                <Doughnut
                data={incomeGraphData}
                height={175}
                options={{
                    title: {
                        display: this.props.displayTitle,
                        text: 'Household Income in the Area',
                        fontSize: 14,
                        color: 'black'
                    },
                    plugins: {
                        datalabels: {
                           display: false,
                        },
                        legend: {
                            display: true,
                            position: 'right',
                        },
                    },
                    scales: {
                        xAxes: [{
                            gridLines: {
                                display: false
                            },
                            ticks: {
                                display: false
                            }
                        }],
                    },
                }}
                />
            </div>
        );
    }
}

export default IncomeGraph;