import React, { Component } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import './competition.css';

class TotalBusinesses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thisListing: props.thisListing,
            yelpData: props.yelpData,
            totalChartData: props.totalChartData
        }
    }

    static defaultProps = {
        displayTitle: true,
    }
    
    render() {

        const { screenWidth } = this.props;

        return (
            <div className="totalGaugeChart">
                <Doughnut
                data={this.state.totalChartData}
                options={{
                    title: {
                        display: this.props.displayTitle,
                        text: 'Business Density in Area',
                        fontSize: 22,
                        color: 'black'
                    },
                    circumference: 59 * Math.PI,
                    rotation: 85 * Math.PI,
                    plugins: {
                        datalabels: {
                           display: false,
                        },
                        legend: {
                            display: false
                        }
                    },
                    cutout: 75,
                    layout: {
                        padding: {
                            bottom: 100
                        }
                    },
                    scales: {
                        x: [{
                            grid: {
                                display: false
                            }
                        }],
                    },
                }}
                />
                {((screenWidth > 1000) || (screenWidth === undefined)) ? (<div className="lowDensityLabel">Low Density</div>) : (<div className="mobileLowDensityLabel">Low Density</div>)}
                {((screenWidth > 1000) || (screenWidth === undefined)) ? (<div className="highDensityLabel">High Density</div>) : (<div className="mobileHighDensityLabel">High Density</div>)}
            </div>
        );
    }
}

export default TotalBusinesses;