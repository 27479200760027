import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { 
    Modal, 
    ModalHeader, 
    ModalBody, 
    Row,
    Alert } 
from 'reactstrap';
import fourcastCompLogo from '../../Assets/fourcast_comp_logo_black.png';
import daytimeTraffComp from '../../Assets/daytimeTraffComp.png';
import infoWindowCompBtn from '../../Assets/infoWindowCompBtn.png';
import { Button, Badge, Checkbox, Divider, Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { connect } from 'react-redux';
import { loadUser, updateUser } from '../../actions/authActions';
import { addLead } from '../../actions/leadActions';
import PropTypes from 'prop-types';
import { FaChartBar } from 'react-icons/fa';
import { MdRadioButtonUnchecked, MdRadioButtonChecked } from 'react-icons/md';
import './compareListings.css';
import CustomLoginRegisterModal from '../CustomSearch/customLoginRegisterModal';


class CompareListingsModal extends Component {
    
    constructor(props) {
        
        super(props);
        this.state = {
            compareListingsModal: false,
            theseCompareListings: props.compareListings,
            theseSavedListings: [],
            successAlert: false,
            compareListingsNum: 0,
            compareListingsChange: false,
            finalCompareListings: [],
            tooManyAlert: false,
            doubleChecked: false
        }
    }

    componentDidMount() {
        
        this.props.loadUser();

        const { compareListings } = this.props;

        if (compareListings !== null) {
            this.setState({ theseCompareListings: compareListings });
        }

    }

    async componentDidUpdate(prevProps, prevState) {
        
        const { savedListings, compareListings, user } = this.props;
        let { theseSavedListings, theseCompareListings, compareListingsNum, doubleChecked } = this.state;

        const getUserSubData = user && user.stripeSubData;

        if (!getUserSubData) {
            if (user && (doubleChecked === false)) {
                await this.checkUserAgain();
            }
        }

        const savedAddys = savedListings && savedListings.length && savedListings.filter(listing => listing !== null).map(savedListing => savedListing && savedListing.label).sort();
        const gatheredSavedAddys = theseSavedListings && theseSavedListings.length && theseSavedListings.map(gatheredListing => gatheredListing && gatheredListing.label);
        const gatheredAddys = gatheredSavedAddys && gatheredSavedAddys.length ? gatheredSavedAddys.sort() : [];
        
        if (((savedAddys && savedAddys.length) !== (gatheredAddys && gatheredAddys.length)) && (JSON.stringify(savedAddys) !== JSON.stringify(gatheredAddys))) {
            if (savedListings && savedListings.length) {
                let filteredSavedListings = savedListings && savedListings.filter(savedListing => savedListing !== null);
                this.setState({ theseSavedListings: filteredSavedListings });
            }
        }

        const compareAddys = compareListings && compareListings.length && compareListings.filter(listing => listing !== null).map(savedListing => savedListing && savedListing.label).sort();
        const gatheredCompareAddys = theseCompareListings && theseCompareListings.length && theseCompareListings.map(gatheredListing => gatheredListing && gatheredListing.label).sort();

        if (((compareAddys && compareAddys.length) !== (gatheredCompareAddys && gatheredCompareAddys.length)) && (JSON.stringify(compareAddys) !== JSON.stringify(gatheredCompareAddys))) {
            let filteredGatheredListings = compareListings && compareListings.filter(compareListing => compareListing !== null);
            let listingsLength = filteredGatheredListings && filteredGatheredListings.length;
            this.setState({ theseCompareListings: filteredGatheredListings, compareListingsNum: listingsLength });
        }

        if (prevState.compareListingsNum !== compareListingsNum) {
            if (this.state.compareListingsChange === false) {
                this.setState({ compareListingsChange: true });
            }
        }
    }

    checkUserAgain = async () => {
        await this.props.loadUser();
        this.setState({ doubleChecked: true });
    }

    toggle = () => {
        this.setState({
            compareListingsModal: !this.state.compareListingsModal,
        });
    }


    render() {

        const { user, isAuthenticated, compareListings } = this.props;
        const { theseCompareListings, theseSavedListings, finalCompareListings } = this.state;

        const getUserSubData = user && user.stripeSubData;
        const stripeSubStatus = getUserSubData && getUserSubData.status;
        const stripeSubType = getUserSubData && getUserSubData.subType;

        const theseCompareLength = theseCompareListings && theseCompareListings.length;
        const theseSavedLength = theseSavedListings && theseSavedListings.length;
        const totalCompareNum = (theseCompareLength + theseSavedLength);
        const finalCompareListingsNum = finalCompareListings && finalCompareListings.length;
        
        return (
            <div>
                <><Badge color="warning" badgeContent={totalCompareNum} max={4}>
                    <Button
                        variant="contained"
                        className="compareListingsMapButton"
                        id={(this.state.compareListingsChange === true) ? "compareBtnShake" : null}
                        onAnimationEnd={() => this.setState({ compareListingsChange: false })}
                        onClick={(e) => this.setState({ compareListingsModal: true })}
                        style={{ backgroundColor: 'white', height: 45, width: 190, textAlign: 'center', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', borderRadius: '5px', outline: 'none' }}
                    >
                        <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 10 }}>
                            <img style={{ width: 174.5, height: 45 }} alt='Try Fourcast Comps' src={fourcastCompLogo} />
                        </div>
                    </Button>
                </Badge></>
                <Modal style={{ width: 600 }} isOpen={this.state.compareListingsModal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Compare Listings</ModalHeader>
                    <ModalBody style={{ width: 500, margin: 'auto', overflowY: 'scroll', maxHeight: 600, position: 'relative', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ position: 'sticky', top: 0, zIndex: 1004, width: '100%', height: 175 }}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img style={{ width: 289, height: 80.5 }} alt='Try Fourcast Comps' src={fourcastCompLogo} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 16, fontWeight: 500, height: 75 }}><span>Compare up to</span><span style={{ color: 'darkgreen', marginLeft: 5, marginRight: 5, fontWeight: 800 }}>4</span><span>listings</span></div>
                        </div>
                        {isAuthenticated ? ((theseCompareListings && theseCompareListings.length) || (theseSavedListings && theseSavedListings.length)) ? (
                            <div style={{ width: '100%', textAlign: 'center', marginTop: 10, zIndex: 10, paddingTop: 10, paddingBottom: 10, overflowY: 'scroll', height: 350 }}>
                                <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'scroll', zIndex: 10 }}>
                                    {theseCompareListings && theseCompareListings.length ? theseCompareListings.map(listing => (
                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                            <Checkbox 
                                                checked={this.state.finalCompareListings.some(finalListing => finalListing && finalListing.value === listing.value)}
                                                value={listing}
                                                style={{ width: 75 }}
                                                onChange={() => {
                                                    let isChecked = this.state.finalCompareListings.some(finalListing => finalListing && finalListing.value === listing.value);
                                                    let thisList = isChecked ? this.state.finalCompareListings.filter((checkedListing) => checkedListing.value !== listing.value) : [...this.state.finalCompareListings, listing]
                                                    this.setState({ finalCompareListings: thisList });

                                                    if (thisList && thisList.length > 4) {
                                                        this.setState({ tooManyAlert: true });
                                                    }
                                                }}
                                                icon={<MdRadioButtonUnchecked style={{ width: 25, height: 25, color: 'lightgray' }} />}
                                                checkedIcon={<MdRadioButtonChecked style={{ width: 25, height: 25, color: 'darkgreen' }} />}
                                            />
                                            <div style={{ marginLeft: 10, maxWidth: '75%', overflowX: 'scroll' }}>{listing && listing.label}</div>
                                        </div>
                                    )) : null}
                                    {theseSavedListings && theseSavedListings.length ? (<Divider textAlign="middle" style={{ marginTop: 20, paddingTop: 10, paddingBottom: 10 }}>Saved Listings</Divider>) : null}
                                    {theseSavedListings && theseSavedListings.length ? theseSavedListings.map(listing => (
                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                            <Checkbox 
                                                checked={this.state.finalCompareListings.some(finalListing => finalListing && finalListing.value === listing.value)}
                                                value={listing}
                                                style={{ width: 75 }}
                                                onChange={() => {
                                                    let isChecked = this.state.finalCompareListings.some(finalListing => finalListing && finalListing.value === listing.value);
                                                    let thisList = isChecked ? this.state.finalCompareListings.filter((checkedListing) => checkedListing.value !== listing.value) : [...this.state.finalCompareListings, listing]
                                                    this.setState({ finalCompareListings: thisList });

                                                    if (thisList && thisList.length > 4) {
                                                        this.setState({ tooManyAlert: true });
                                                    }
                                                }}
                                                icon={<MdRadioButtonUnchecked style={{ width: 25, height: 25, color: 'lightgray' }} />}
                                                checkedIcon={<MdRadioButtonChecked style={{ width: 25, height: 25, color: 'darkgreen' }} />}
                                            />
                                            <div style={{ marginLeft: 10, maxWidth: '75%', overflowX: 'scroll' }}>{listing && listing.label}</div>
                                        </div>
                                    )) : null}
                                </div>
                            </div>) : (
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                <Divider textAlign="middle" style={{ paddingTop: 10, paddingBottom: 10, color: 'darkred', fontWeight: 500 }}>You Have Not Selected Any Listings</Divider>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', marginTop: 10 }}>
                                    <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img style={{ width: 190, height: 189, boxShadow: `0 1px 3px rgba(0, 0, 0, 0.3)` }} alt='Daytime Traffic Comparison' src={daytimeTraffComp} />
                                    </div>
                                    <div style={{ width: '50%', display: 'flex', flexWrap: 'wrap', textAlign: 'left', alignItems: 'center', fontSize: 14, fontWeight: 700 }}>Get instant, side by side comparisons.</div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                    <div style={{ width: '50%', display: 'flex', flexWrap: 'wrap', textAlign: 'right', alignItems: 'center', fontSize: 14, fontWeight: 700 }}>Select any listing on the map and click the COMPARE button.</div>
                                    <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img style={{ width: 186, height: 157, boxShadow: `0 1px 3px rgba(0, 0, 0, 0.3)` }} alt='InfoWindow Comp Button' src={infoWindowCompBtn} />
                                    </div>
                                </div>
                            </div>
                            ) : compareListings && compareListings.length ? (
                            <div style={{ width: '100%', textAlign: 'center', marginTop: 10, zIndex: 10, paddingTop: 10, paddingBottom: 25, overflowY: 'scroll' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', overflowY: 'scroll', zIndex: 10 }}>
                                    {theseCompareListings && theseCompareListings.length ? theseCompareListings.map(listing => (
                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                            <Checkbox 
                                                checked={this.state.finalCompareListings.some(finalListing => finalListing && finalListing.value === listing.value)}
                                                value={listing}
                                                style={{ width: 75 }}
                                                onChange={() => {
                                                    let isChecked = this.state.finalCompareListings.some(finalListing => finalListing && finalListing.value === listing.value);
                                                    let thisList = isChecked ? this.state.finalCompareListings.filter((checkedListing) => checkedListing.value !== listing.value) : [...this.state.finalCompareListings, listing]
                                                    this.setState({ finalCompareListings: thisList });

                                                    if (thisList && thisList.length > 4) {
                                                        this.setState({ tooManyAlert: true });
                                                    }
                                                }}
                                                icon={<MdRadioButtonUnchecked style={{ width: 25, height: 25, color: 'lightgray' }} />}
                                                checkedIcon={<MdRadioButtonChecked style={{ width: 25, height: 25, color: 'darkgreen' }} />}
                                            />
                                            <div style={{ marginLeft: 10, maxWidth: '75%', overflowX: 'scroll' }}>{listing && listing.label}</div>
                                        </div>
                                    )) : null}
                                </div>
                            </div>) : (
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                <Divider textAlign="middle" style={{ paddingTop: 10, paddingBottom: 10, color: 'darkred', fontWeight: 500 }}>You Have Not Selected Any Listings</Divider>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', marginTop: 10 }}>
                                    <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img style={{ width: 190, height: 189, boxShadow: `0 1px 3px rgba(0, 0, 0, 0.3)` }} alt='Daytime Traffic Comparison' src={daytimeTraffComp} />
                                    </div>
                                    <div style={{ width: '50%', display: 'flex', flexWrap: 'wrap', textAlign: 'left', alignItems: 'center', fontSize: 14, fontWeight: 700 }}>Get instant, side by side comparisons.</div>
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                    <div style={{ width: '50%', display: 'flex', flexWrap: 'wrap', textAlign: 'right', alignItems: 'center', fontSize: 14, fontWeight: 700 }}>Select any listing on the map and click the COMPARE button.</div>
                                    <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img style={{ width: 186, height: 157, boxShadow: `0 1px 3px rgba(0, 0, 0, 0.3)` }} alt='InfoWindow Comp Button' src={infoWindowCompBtn} />
                                    </div>
                                </div>
                            </div>)
                        }
                        <div style={{ marginTop: 25, height: 75, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingBottom: 25 }}>
                            {((theseSavedListings && theseSavedListings.length) || (theseCompareListings && theseCompareListings.length)) ? isAuthenticated ? (
                                (((user.accountType !== 'basic') && ((stripeSubStatus && stripeSubStatus === 'active') || (stripeSubStatus && stripeSubStatus === 'trialing')) && (stripeSubType && stripeSubType !== 'basic')) || (user.accountType === 'admin')) ? (
                                    ((finalCompareListings.length < 2) || (finalCompareListings.length > 4)) ? 
                                        (<div>
                                            <Button disabled={((finalCompareListings.length < 2) || (finalCompareListings.length > 4))} style={{ width: 200, backgroundColor: 'lightgray' }}>
                                                <div style={{ color: 'white', textDecoration: 'none' }}>{`Compare ${finalCompareListingsNum && finalCompareListingsNum} Listings!`}</div>
                                            </Button>
                                        </div>) : 
                                        (<Link 
                                            style={{ textDecoration: "none" }}
                                            to={{
                                                pathname: '/compare-listings',
                                                state: {
                                                    listingIds: finalCompareListings
                                                }
                                            }}
                                        >
                                            <Button disabled={((finalCompareListings.length < 2) || (finalCompareListings.length > 4))} className="whatIsThisButton" style={{ width: 200, backgroundColor: 'rgb(21, 134, 84)' }}>
                                                <div style={{ color: 'white', textDecoration: 'none' }}>{`Compare ${finalCompareListingsNum && finalCompareListingsNum} Listings!`}</div>
                                            </Button>
                                        </Link>)
                                ) : (user && user.engagement.searches > 1) ? 
                                (
                                    <Link style={{ marginTop: 20, marginRight: 'auto', marginLeft: 'auto', textDecoration: 'none' }} to={`/user/${user._id}`}>
                                        <Button style={{ width: 200, backgroundColor: 'rgb(21, 134, 84)' }} variant="contained">Upgrade To Continue</Button>
                                    </Link>
                                ) : 
                                (
                                    ((finalCompareListings.length < 2) || (finalCompareListings.length > 4)) ? (
                                    <div>
                                        <Button disabled={((finalCompareListings.length < 2) || (finalCompareListings.length > 4))} style={{ width: 200, backgroundColor: 'lightgray' }}>
                                            <div style={{ color: 'white', textDecoration: 'none' }}>{`Compare ${finalCompareListingsNum && finalCompareListingsNum} Listings!`}</div>
                                        </Button>
                                    </div>) : (
                                    <Link 
                                        style={{ textDecoration: "none" }}
                                        to={{
                                            pathname: '/compare-listings',
                                            state: {
                                                listingIds: finalCompareListings
                                            }
                                        }}
                                    >
                                        <Button disabled={((finalCompareListings.length < 2) || (finalCompareListings.length > 4))} className="whatIsThisButton" style={{ width: 200, backgroundColor: 'rgb(21, 134, 84)' }}>
                                            <div style={{ color: 'white', textDecoration: 'none' }}>{`Compare ${finalCompareListingsNum && finalCompareListingsNum} Listings!`}</div>
                                        </Button>
                                    </Link>)
                                )
                            ) : (
                                <div style={{ marginLeft: 'auto', marginRight: 'auto' }}><CustomLoginRegisterModal /></div>
                            ) : null}
                            <Dialog
                                open={this.state.tooManyAlert}
                                onClose={() => this.setState({ tooManyAlert: false })}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description" style={{ padding: 10, fontWeight: 'bolder' }}>
                                        Maximum (4) listings.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => this.setState({ tooManyAlert: false })}>
                                        Okay
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

CompareListingsModal.propTypes = {
    loadUser: PropTypes.func.isRequired,
    addLead: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});
 
export default connect(mapStateToProps, { loadUser, addLead, updateUser })(CompareListingsModal);