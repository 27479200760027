import { GET_LEADS, ADD_LEAD, LEADS_LOADING, GET_LEAD, UPDATE_LEAD } from '../actions/types';

const initialState = {
    loading: false,
    leads: [],
    thisLead: []
}

export default function(state = initialState, action) {
    switch(action.type) {
        case GET_LEADS:
            return {
                ...state,
                leads: action.payload,
                loading: false
            };
        case GET_LEAD:
            return {
                ...state,
                thisLead: action.payload,
                loading: false
            }
        case UPDATE_LEAD:
            return {
                ...state,
                thisLead: action.payload,
            }
        case ADD_LEAD:
            return {
                ...state,
                leads: [action.payload, ...state.leads]
            };
        case LEADS_LOADING:
            return {
                ...state,
                loading: true
            };
        default:
            return state
    }
};