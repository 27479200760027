import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Button, Card } from '@mui/material';
import { MdLocationPin, MdPeopleOutline, MdExposureNeg2, MdTravelExplore, MdFileDownload } from 'react-icons/md';
import productFeaturesLaptop from '../../Assets/productFeatures_laptop.png';
import fourcastLogo from '../../Assets/fourcastLogo.png';
import productMapScreen from '../../Assets/productFeatures_mapHighlight.png';
import productMapSquare from '../../Assets/productFeatures_mapHighlightSquare.png';
import productScoreSquare from '../../Assets/productFeatures_score.png';
import productFourcastMatchHome from '../../Assets/productFeatures_matchHome.png';
import productFourcastMatchBannerButton from '../../Assets/productFeatures_matchBannerButton.png';
import productFourcastMatchPriceSize from '../../Assets/productFeatures_priceSize.png';
import productFourcastMatchTraffFilts from '../../Assets/productFeatures_traffFilts.png';
import productReportsResult from '../../Assets/productFeatures_reportsResult.png';
import productReportsSummary from '../../Assets/productFeatures_reportsSummary.png';
import productReportsDemoData from '../../Assets/productFeatures_reportsDemoData.png';
import productReportsCompData from '../../Assets/productFeatures_reportsCompData.png';
import MobileDescription from './mobileDescription';
import '../welcome/description.css';

class Description extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            logo: false,
            mobile: false,
            comp1: false,
            comp2: false,
            comp3: false,
            comp4: false,
            comp5: false
        }
    }

    componentDidMount() {
        window && window.addEventListener('scroll', this.fadeInCircles.bind(this));
        window && window.addEventListener('resize', this.mobileMode.bind(this));
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }

    componentWillUnmount() {
        window && window.removeEventListener('scroll', this.fadeInCircles.bind(this));
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
    }

    mobileMode() {
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }

    fadeInCircles() {
        if (window && (window.scrollY > 10) && (window.scrollY < 3000)) {
            this.setState({ comp1: true, comp2: false, comp3: false, comp4: false, comp5: false })
        } else if (window && (window.scrollY > 3000) && (window.scrollY < 3600)) {
            this.setState({ comp1: false, comp2: true, comp3: false, comp4: false, comp5: false })
        } else if (window && (window.scrollY > 3600) && (window.scrollY < 4200)) {
            this.setState({ comp1: false, comp2: false, comp3: true, comp4: false, comp5: false })
        } else if (window && (window.scrollY > 4200) && (window.scrollY < 4800)) {
            this.setState({ comp1: false, comp2: false, comp3: false, comp4: true, comp5: false })
        } else if (window && (window.scrollY > 4800) && (window.scrollY < 5400)) {
            this.setState({ comp1: false, comp2: false, comp3: false, comp4: false, comp5: true })
        } else {
            this.setState({ logo: false })
        }
    }

    render() { 

        const { mobile, comp1, comp2, comp3, comp4, comp5 } = this.state;

        return ( 
            <Container fluid>
                <div className='perfectSpace' style={{ height: 200, display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                    <h2>Retail and Restaurant Space for Rent</h2>
                </div>
                {mobile ? <MobileDescription /> : (
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr', paddingBottom: 200 }}>
                    <div style={{ height: 2900 }}>
                        <div style={{ height: 650, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '80%' }}>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 22, paddingLeft: 2, marginTop: 20, color: 'gray' }}>Inside the app</div>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 36, marginTop: 10 }}><span style={{ fontStyle: 'italic', color: 'rgb(21, 134, 84)' }}>Automate</span><span> the </span><span style={{ fontStyle: 'italic' }}>overwhelming</span><span>,</span><span style={{ fontStyle: 'italic' }}> tedious</span><span>,</span><span style={{ fontStyle: 'italic' }}> time-consuming</span><span> tasks</span></div>
                                <div style={{ height: 100, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                    <Link to="/about" style={{ textDecoration: 'none' }}>
                                        <Button id="explorePropertiesBtn" style={{ width: 215, fontSize: 15, textShadow: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                                            Explore Features
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 650, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '80%' }}>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 42, color: 'rgb(21, 134, 84)', fontWeight: 'bolder' }}>Browse</div>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16, paddingLeft: 2, marginTop: 20 }}>Discover and explore active* retail space listings. Each space gets an overall Fourcast Score which is the average of four sub-category scores.</div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <img src={productScoreSquare} style={{ height: '100%', width: '100%' }} alt='product features score square' />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Fourcast Score</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdPeopleOutline style={{ height: '100%', width: '100%', color: 'dodgerblue' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Advanced filtering</div>
                                </div>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 12, paddingLeft: 2, marginTop: 20 }}>* Listings available in select cities.</div>
                                <div style={{ height: 100, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                    <Link to="/listings" style={{ textDecoration: 'none' }}>
                                        <Button id="explorePropertiesBtn" style={{ width: 180, fontSize: 14, textShadow: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                                            Browse Listings
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 650, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '80%' }}>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 42, color: 'rgb(21, 134, 84)', fontWeight: 'bolder' }}>Match</div>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16, paddingLeft: 2, marginTop: 20 }}>Personalized searching tool. Set specific characteristics of the ideal space and area you are looking for. Get instantly matched.</div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdExposureNeg2 style={{ height: '100%', width: '100%', color: 'rgb(223, 82, 69)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10, width: '80%' }}>Adjusted scores reflect compatibility</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdTravelExplore style={{ height: '100%', width: '100%', color: 'rgb(21, 134, 84)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10, width: '80%' }}>Custom matched spaces</div>
                                </div>
                                <div style={{ height: 100, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                    <Link to="/customSearch" style={{ textDecoration: 'none' }}>
                                        <Button id="explorePropertiesBtn" style={{ width: 180, fontSize: 14, textShadow: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                                            Fourcast Match
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 650, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '80%' }}>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 42, color: 'rgb(21, 134, 84)', fontWeight: 'bolder' }}>Reports</div>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16, paddingLeft: 2, marginTop: 20 }}>Generate customized, printable property reports.</div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdLocationPin style={{ height: '100%', width: '100%', color: 'dodgerblue' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10, width: '80%' }}>Select space(s) & report components</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdFileDownload style={{ height: '100%', width: '100%', color: 'rgb(21, 134, 84)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10, width: '80%' }}>Immediately download and print valuable reports for one or multiple properties.</div>
                                </div>
                                <div style={{ height: 100, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                    <Link to="/reports" style={{ textDecoration: 'none' }}>
                                        <Button id="explorePropertiesBtn" style={{ width: 180, fontSize: 14, textShadow: 'none' }} onClick={() => window.scrollTo(0, 0)}>
                                            Fourcast Reports
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', position: 'sticky', top: 100, alignSelf: 'start', paddingTop: 100 }}>
                        {comp1 ? <div style={{ position: 'relative' }}>
                            <img src={productFeaturesLaptop} alt="product features laptop" />
                            <img src={productMapScreen} style={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-49.5%, -46%)', maxHeight: '77%', maxWidth: '77%' }} alt='product features map' />
                            <img src={fourcastLogo} style={{ position: 'absolute', top: "8%", left: "50%", transform: 'translate(-49.5%, -8%)', height: 40, width: 128, zIndex: 999 }} alt='product features map' />
                            <div style={{ position: 'absolute', top: "7%", left: "51.5%", transform: 'translate(-51.5%, -7%)', backgroundColor: 'rgb(21, 134, 84)', width: '77%', height: '10%' }}></div>
                        </div> : null}
                        {comp2 ? <div style={{ position: 'relative' }}>
                            <img src={productFeaturesLaptop} style={{ zIndex: 999 }} alt="product features laptop" />
                            <img src={productMapScreen} style={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-49.5%, -46%)', maxHeight: '77%', maxWidth: '77%' }} alt='product features map' />
                            <img src={fourcastLogo} style={{ position: 'absolute', top: "8%", left: "50%", transform: 'translate(-49.5%, -8%)', height: 40, width: 128, zIndex: 998 }} alt='product features map' />
                            <div style={{ position: 'absolute', top: "7%", left: "51.5%", transform: 'translate(-51.5%, -7%)', backgroundColor: 'rgb(21, 134, 84)', width: '77%', height: '10%' }}></div>
                            <div id='prodFeaturesBlock1'></div>
                            <div id='prodFeaturesBlock2'></div>
                        </div> : null}
                        {comp3 ? <div style={{ position: 'relative' }}>
                            <img src={productFeaturesLaptop} alt="product features laptop" />
                            <img src={productMapScreen} style={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-49.5%, -46%)', maxHeight: '77%', maxWidth: '77%' }} alt='product features map' />
                            <img src={fourcastLogo} style={{ position: 'absolute', top: "8%", left: "50%", transform: 'translate(-49.5%, -8%)', height: 40, width: 128, zIndex: 999 }} alt='product features map' />
                            <div style={{ position: 'absolute', top: "7%", left: "51.5%", transform: 'translate(-51.5%, -7%)', backgroundColor: 'rgb(21, 134, 84)', width: '77%', height: '10%' }}></div>
                            <div id='prodFeaturesBlock3'></div>
                            <div id='prodFeaturesBlock4'></div>
                            <div id='mapWhiteoutSquare'></div>
                            <Card id='prodFeaturesMapSquareCard' style={{ left: '25%', top: '25%', zIndex: 1000 }}>
                                <div style={{ position: 'relative' }}>
                                    <img src={productMapSquare} style={{ height: '100%', width: '100%' }} alt='product features map square' />
                                </div>
                            </Card>
                            <Card id='prodFeaturesScoreCard'>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <img src={productScoreSquare} style={{ height: '50%', width: '50%' }} alt='product features score square' />
                                </div>
                            </Card>
                        </div> : null}
                        {comp4 ? <div style={{ position: 'relative' }}>
                            <img src={productFeaturesLaptop} alt="product features laptop" />
                            <img src={fourcastLogo} style={{ position: 'absolute', top: "8%", left: "50%", transform: 'translate(-49.5%, -8%)', height: 40, width: 128, zIndex: 999, opacity: .5 }} alt='product features map' />
                            <div style={{ position: 'absolute', top: "7%", left: "51.5%", transform: 'translate(-51.5%, -7%)', backgroundColor: 'rgba(21, 134, 84, .5)', width: '77%', height: '9.25%' }}></div>
                            <img id='prodFeaturesMatchHomepage2' src={productFourcastMatchHome} alt='product features fourcast match home screen' />
                            <Card id='prodFeaturesPriceSizeCard' style={{ top: '37%' }}>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productFourcastMatchPriceSize} style={{ height: '95%', width: '100%' }} alt='product features fourcast match price and size' />
                                </div>
                            </Card>
                            <Card id='prodFeaturesTraffFiltsCard'>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productFourcastMatchTraffFilts} style={{ height: '95%', width: '95%' }} alt='product features fourcast match traffic filters' />
                                </div>
                            </Card>
                            <div id='matchBannerButtonGreenSquare'></div>
                            <Card id='prodFeaturesMatchBannerButton'>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productFourcastMatchBannerButton} style={{ height: '75%', width: '75%', zIndex: 10001 }} alt='product features fourcast match banner button' />
                                </div>
                            </Card>
                            <div id='prodFeaturesBlock7' style={{ left: '35%' }}></div>
                            <div id='matchHomeWhiteoutSquare'></div>
                        </div> : null}
                        {comp5 ? <div style={{ position: 'relative' }}>
                            <img src={productFeaturesLaptop} alt="product features laptop" />
                            <img src={fourcastLogo} style={{ position: 'absolute', top: "8%", left: "50%", transform: 'translate(-49.5%, -8%)', height: 40, width: 128, zIndex: 999 }} alt='product features map' />
                            <img className='productMapFullScreen' src={productReportsResult} style={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-49.5%, -46%)', maxHeight: '77%', maxWidth: '77%' }} alt='product features map' />
                            <div style={{ position: 'absolute', top: "7%", left: "51.5%", transform: 'translate(-51.5%, -7%)', backgroundColor: 'rgb(21, 134, 84)', width: '77%', height: '9.5%' }}></div>
                            <Card id='prodFeaturesPriceSizeCard'>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productReportsSummary} style={{ height: '95%', width: '100%' }} alt='product features fourcast match price and size' />
                                </div>
                            </Card>
                            <Card id='prodFeaturesPriceSizeCard' style={{ left: '80%', top: '0%', zIndex: 1002 }}>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productReportsDemoData} style={{ height: '95%', width: '100%' }} alt='product features reports address input' />
                                </div>
                            </Card>
                            <Card id='prodFeaturesTraffFiltsCard' style={{ left: '50%', top: '95%', zIndex: 1002 }}>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productReportsCompData} style={{ height: '95%', width: '95%' }} alt='product features reports component input' />
                                </div>
                            </Card>
                            <div id='prodFeaturesBlock5'></div>
                            <div id='prodFeaturesBlock6' style={{ backgroundColor: 'dodgerblue', top: '48%' }}></div>
                            <div id='prodFeaturesBlock7' style={{ left: '45%', backgroundColor: 'salmon' }}></div>
                        </div> : null}
                    </div>
                </div>)}
            </Container>
        );
    }
}
 
export default Description;
