import React, { Component } from 'react';
import './traffic.css';
import { Col, Row } from 'react-bootstrap';
import bike from '../../../Assets/bike.png';
import walking from '../../../Assets/walking.png';
import transit from '../../../Assets/train.png';

class WalkScoreData extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            walkScoreData: props.walkScoreData,
        }
    }
    
    render() { 
        const { walkScoreData, screenWidth } = this.props;
        const { walkScoreData: { bike: { score: bikeScore } = {}} = {}} = this.props;
        const { walkScoreData: { bike: { description: bikeDescription } = {}} = {}} = this.props;
        const { walkScoreData: { transit: { score: transitScore } = {}} = {}} = this.props;
        const { walkScoreData: { transit: { description: transitDescription } = {}} = {}} = this.props;

        return ( 
            <div>
                {this.props.reportMode ? null : <p className="accessTitle">Accessibility</p>}
                {((screenWidth > 1000) || (screenWidth === undefined)) ? (<Row  style={{ width: '420px' }}>
                    <Col sm={4} className="walkscore-columns">
                        <Row className="icon">
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <img className='walkPic' src={walking} alt='' />
                            </div>
                        </Row>
                        <Row className="score">
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                {walkScoreData.walkscore}
                            </div>
                        </Row>
                        <Row className="description">
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                {walkScoreData.description}
                            </div>
                        </Row>
                    </Col>
                    <Col sm={4} className="walkscore-columns">
                        <Row className="icon">
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <img src={transit} alt='' />
                            </div>
                        </Row>
                        <Row className="score">
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                {transitScore !== null ? transitScore : 'N/A'}
                            </div>
                        </Row>
                        <Row className="description">
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                {transitDescription !== null ? transitDescription : 'N/A'}
                            </div>
                        </Row>
                    </Col>
                    <Col sm={4} className="walkscore-columns">
                        <Row className="icon">
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <img src={bike} alt='' />
                            </div>
                        </Row>
                        <Row className="score">
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                {bikeScore}
                            </div>
                        </Row>
                        <Row className="description">
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                {bikeDescription}
                            </div>
                        </Row>
                    </Col>
                </Row>) : (<div  style={{ width: screenWidth, display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
                    <div className="walkscore-columns">
                        <Row className="icon">
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <img className='mobileWalkPic' src={walking} alt='' />
                            </div>
                        </Row>
                        <Row className="score">
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                {walkScoreData.walkscore}
                            </div>
                        </Row>
                        <Row className="description">
                            <div style={{ width: '100%', textAlign: 'center', fontSize: 12 }}>
                                {walkScoreData.description}
                            </div>
                        </Row>
                    </div>
                    <div className="walkscore-columns">
                        <Row className="icon">
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <img src={transit} alt='' />
                            </div>
                        </Row>
                        <Row className="score">
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                {transitScore !== null ? transitScore : 'N/A'}
                            </div>
                        </Row>
                        <Row className="description">
                            <div style={{ width: '100%', textAlign: 'center', fontSize: 12 }}>
                                {transitDescription !== null ? transitDescription : 'N/A'}
                            </div>
                        </Row>
                    </div>
                    <div className="walkscore-columns">
                        <Row className="icon">
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <img src={bike} alt='' />
                            </div>
                        </Row>
                        <Row className="score">
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                {bikeScore}
                            </div>
                        </Row>
                        <Row className="description">
                            <div style={{ width: '100%', textAlign: 'center', fontSize: 12 }}>
                                {bikeDescription}
                            </div>
                        </Row>
                    </div>
                </div>)}
            </div>
        );
    }
}
 
export default WalkScoreData;