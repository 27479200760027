import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button } from '@material-ui/core';
import { Row } from 'reactstrap';
import { connect } from 'react-redux';
import { loadUser } from '../../actions/authActions';
import { FaChartBar, FaChartPie, FaChartLine } from 'react-icons/fa';
import PropTypes from 'prop-types';
import CustomLoginRegisterModal from '../CustomSearch/customLoginRegisterModal';
import fourcastCompLogo from '../../Assets/fourcast_comp_logo_black.png';
import '../welcome/description.css';
import NotMobileFriendly from '../welcome/notMobileFriendly';


class CompareListingsWelcomePage extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            doubleChecked: false,
            mobile: false,
            screenWidth: null
        }
    }

    componentDidMount() {

        window && window.addEventListener('resize', this.mobileMode.bind(this));
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth });
        } else {
            this.setState({ mobile: false });
        }
    }

    componentWillUnmount() {
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
    }

    mobileMode() {
        let screenWidth = window.screen.width;
        
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth, mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }
    
    
    continue = e => {
        this.props.nextStep();
    }



    render() { 
        
        const { mobile, screenWidth } = this.state;
        const { isAuthenticated, user } = this.props;

        const getUserSubData = user && user.stripeSubData;
        const stripeSubStatus = getUserSubData && getUserSubData.status;
        const stripeSubType = getUserSubData && getUserSubData.subType;
        
        return (
            <>{(mobile || (screenWidth && (screenWidth < 1000))) ? <NotMobileFriendly /> : (<div style={{ width: '100%', height: 775, backgroundColor: 'whitesmoke', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card style={{ width: '70%', height: 600 }}>
                    <div style={{ height: 400, display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', marginTop: 25 }}>
                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img  alt='Try Fourcast Comps' src={fourcastCompLogo} />
                        </div>
                        <div style={{ height: '100%', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Row style={{ marginLeft: 'auto', marginRight: 'auto', height: 350 }}>
                                <Card id='reportWelcomePageGraphic' elevation={5}>
                                    <div style={{ height: '100%' }}>
                                        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <FaChartPie style={{ width: 50, height: 50, color: 'rgb(52, 181, 122)' }} />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <FaChartBar style={{ width: 50, height: 50, color: 'rgb(250, 204, 68)' }} />
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <FaChartLine style={{ width: 50, height: 50, color: 'rgb(250, 80, 122)' }} />
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </Row>
                        </div>
                    </div>
                    <Row style={{ margin: '15px auto', height: 25, display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                        <p>By Clicking Below I Agree To Terms & Conditions</p>
                    </Row>
                    <Row style={{ textAlign: 'center', width: '70%', marginLeft: 'auto', marginRight: 'auto', height: 100 }}>
                        {isAuthenticated ? (((user.accountType !== 'basic') && ((stripeSubStatus && stripeSubStatus === 'active') || (stripeSubStatus && stripeSubStatus === 'trialing')) && (stripeSubType && stripeSubType !== 'basic')) || (user.accountType === 'admin')) ? 
                        (
                            <Button onClick={() => this.continue()} style={{ marginTop: 20, marginRight: 'auto', marginLeft: 'auto', backgroundColor: "#007FFF", color: 'white', height: 50 }} variant="contained" size="large">Continue</Button>
                        ) : (user && user.engagement.searches > 1) ? 
                        (
                            <Link style={{ marginTop: 20, marginRight: 'auto', marginLeft: 'auto', textDecoration: 'none' }} to={`/user/${user._id}`}>
                                <Button style={{ color: 'white', backgroundColor: '#007FFF' }} variant="contained" size="large">Upgrade To Continue</Button>
                            </Link>
                        ) : 
                        (
                            <Button onClick={() => this.continue()} style={{ marginTop: 20, marginRight: 'auto', marginLeft: 'auto', backgroundColor: '#007FFF', color: 'white', height: 50 }} variant="contained" size="large">Continue</Button>
                        ) : 
                        (<CustomLoginRegisterModal />)}
                    </Row>
                </Card>
            </div>)}</>
        );
    }
}

CompareListingsWelcomePage.propTypes = {
    loadUser: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});
 

export default connect(mapStateToProps, { loadUser })(CompareListingsWelcomePage);