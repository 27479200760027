import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';



export default function Page(props) {
  const { loading = false } = props;

  return (    
    <Grid container wrap="nowrap">
        <Skeleton sx={{ mx: 'auto', my: 15 }} variant="rectangular" width={225} height={50} />
        {(loading ? Array.from(new Array(2)) : null).map((item, index) => (
            <Box key={index} sx={{ width: 300, mx: 'auto', my: 15 }}>
                <Skeleton variant="rectangular" width={300} height={208} />
                <Box sx={{ pt: 1 }}>
                <Skeleton />
                <Skeleton width="60%" />
                </Box>
            </Box>
        ))}
    </Grid>
  );
}

Page.propTypes = {
  loading: PropTypes.bool,
};