import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Alert, NavLink } from 'reactstrap';
import { Button, CircularProgress } from '@mui/material';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import RegisterModal from './registerModal';

const registerBodyLinkStyle = { fontSize: 14, color: 'darkblue', fontWeight: 'bold', padding: 0 };
const navbarLinkStyle = { textDecoration: 'none', color: 'white', fontSize: 13, padding: 0 };

class LoginModal extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            modal: false,
            email: '',
            password: '',
            msg: null,
            loading: false
        }
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        error: PropTypes.object.isRequired,
        login: PropTypes.func.isRequired,
        clearErrors: PropTypes.func.isRequired
    }

    componentDidMount() {
        if (this.props.modal) {
            this.setState({ modal: this.props.modal });
        }
    }

    componentDidUpdate(prevProps) {
        const { error, isAuthenticated } = this.props;

        if (error !== prevProps.error) {
            
            //Check for Login Error
            if (error.id === 'LOGIN_FAIL') {
                this.setState({ msg: error.msg.msg })
            } else {
                this.setState({ msg: null });
            }
        }

        // If Authenticated Close Modal
        if (this.state.modal) {
            if (isAuthenticated) {
                this.toggle();
            }
        }
    }

    toggle = () => {
        
        // Clear Errors
        this.props.clearErrors();
        
        this.setState({ 
            modal: !this.state.modal,
            loading: false
        })
    };

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value, loading: false });
    };

    onSubmit = e => {
        e.preventDefault();

        this.setState({ loading: true })

        const { email, password } = this.state;

        const thisUser = {
            email, 
            password
        };

        // Attempt to Login
        this.props.login(thisUser);

    }

    render() { 

        return ( 
            <div>
                <NavLink style={this.props.linkText ? this.props.navbarText ? navbarLinkStyle : { fontFamily: 'Arial, Helvetica, sans-serif', color: 'black', padding: 0, fontSize: 13, textDecoration: 'none' } : { color: 'white', fontSize: 13 }} onClick={this.toggle} href='#'>
                    {this.props.linkText ? this.props.linkText : 'LOG IN'}
                </NavLink>

                <Modal style={{ marginTop: 125, minWidth: 300, minHeight: 450, position: 'relative' }} isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Log In</ModalHeader>
                    <ModalBody style={{ minWidth: '95%' }}>
                        { this.state.msg ? (
                        <Alert color='danger'>{this.state.msg}</Alert>
                        ) : null}
                        <Form onSubmit={this.onSubmit} style={{ marginTop: '1rem' }}>
                            <FormGroup>
                                <Label for='email' style={{ fontSize: 14, fontWeight: 'bold' }}>Email</Label>
                                <Input 
                                    type='email'
                                    name='email'
                                    id='email'
                                    className='mb-3'
                                    onChange={this.onChange}
                                />

                                <Label for='password' style={{ fontSize: 14, fontWeight: 'bold' }}>Password</Label>
                                <Input 
                                    type='password'
                                    name='password'
                                    id='password'
                                    className='mb-3'
                                    onChange={this.onChange}
                                />
                            </FormGroup>
                            <Button onClick={this.onSubmit} endIcon={(this.state.loading && !this.state.msg) ? <CircularProgress style={{ color: 'white' }} size={20} thickness={3} /> : null} style={{ width: '100%', marginTop: '2rem', backgroundColor: 'rgb(35, 126, 85)', color: 'white', outline: 'none' }}>
                                <div style={{ marginLeft: 10, paddingRight: 10 }}>Submit</div>
                            </Button>
                        </Form>
                        <div style={{ textAlign: 'right', marginTop: 15 }}>
                            <Link onClick={this.toggle} style={{ fontFamily: 'Arial, Helvetica, sans-serif', color: 'darkblue', fontSize: 12, textDecoration: 'none', marginRight: 15 }} to='/forgot-password'>
                                Forgot Password?
                            </Link>
                        </div>
                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', alignItems: 'baseline', width: '39%', fontSize: 12, marginTop: 10, marginLeft: 15 }}>
                            <div>Not registered?</div>
                            <div><RegisterModal style={registerBodyLinkStyle} /></div>
                        </div>
                        <div style={{ marginLeft: 15, fontSize: 12, paddingBottom: '1rem' }}>By signing in you agree to Fourcast's Terms of Use and Privacy Policy.</div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    error: state.error,
});

export default connect(mapStateToProps, { login, clearErrors })(LoginModal);