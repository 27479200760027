import React, { Component } from 'react';
import { Table } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import GreenArrow from '../../../Assets/greenArrow.png';
import RedArrow from '../../../Assets/redArrow.png';
import 'chartjs-plugin-datalabels';
import '../../listingDetailsLayouts/demographics/demographics.css';
import '../compareListings.css';

Chart.register(ChartDataLabels);

const greenArrow = <img className='greenArrow' src={GreenArrow} alt='' />;
const redArrow = <img className='redArrow' src={RedArrow} alt='' />;

class PopulationComparison extends Component {
    
    
    render() {

        const { listings } = this.props;

        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.scores.fourcastScore - a.scores.fourcastScore});
        
        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }

        let getThisListingsPopulation = (thisListing) => {
            
            const geoIdV4 = thisListing && thisListing.attomData && thisListing.attomData.geoIdV4 && thisListing.attomData.geoIdV4;

            // POPULATION DATA
            const getPopulation = [];
            const getDayPop = [];
        
                // ages 
            const elevenAndUnderArr = [];
            const twelveToSeventeenArr = [];
            const eighteenTo24Arr = [];
            const twentyfiveTo34Arr = [];
            const thirtyfiveTo44Arr = [];
            const fortyfiveTo54Arr = [];
            const fiftyfiveTo64Arr = [];
            const sixtyfiveToSeventyFourArr = [];
            const seventyFiveAndUpArr = [];

                // pop trends
            const popChanges = [];
            const popForecasts = [];

            
            if (geoIdV4 && geoIdV4.length) {

                
                for (let g = 0; g < geoIdV4.length; g++) {
                    
                    // POPULATION DATA
                    const totalPopulation = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population);
                    const thisDayPop = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Daytime);
                    
                    const zeroToFivePct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_0_5_Pct);
                    const sixTo11Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_6_11_Pct);
                    const twelveTo17Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_12_17_Pct);
                    const e18To24Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_18_24_Pct);
                    const t25To34Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_25_34_Pct);
                    const t35To44Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_35_44_Pct);
                    const f45To54Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_45_54_Pct);
                    const f55To64Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_55_64_Pct);
                    const s65To74Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_65_74_Pct);
                    const s75To84Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_75_84_Pct);
                    
                    getPopulation.push(totalPopulation);
                    getDayPop.push(thisDayPop);
                    elevenAndUnderArr.push((totalPopulation * (zeroToFivePct * .01)) + (totalPopulation * (sixTo11Pct * .01)));
                    twelveToSeventeenArr.push(totalPopulation * (twelveTo17Pct * .01));
                    eighteenTo24Arr.push(totalPopulation * (e18To24Pct * .01));
                    twentyfiveTo34Arr.push(totalPopulation * (t25To34Pct * .01));
                    thirtyfiveTo44Arr.push(totalPopulation * (t35To44Pct * .01));
                    fortyfiveTo54Arr.push(totalPopulation * (f45To54Pct * .01));
                    fiftyfiveTo64Arr.push(totalPopulation * (f55To64Pct * .01));
                    sixtyfiveToSeventyFourArr.push(totalPopulation * (s65To74Pct * .01));
                    seventyFiveAndUpArr.push(totalPopulation * (s75To84Pct * .01));


                    const popChangeSince2010 = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Chg_Pct_2010);
                    let popChangeWeightedValue = (geoIdV4 && (geoIdV4.length > 1)) ? (geoIdV4 && (geoIdV4[g].type !== 'N4')) ? (popChangeSince2010 * .4) : (popChangeSince2010 * .6) : popChangeSince2010;
                    let popForecast5YearNum = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_5_Yr_Projection);

                    popChanges.push(popChangeWeightedValue);
                    popForecasts.push(popForecast5YearNum);

                }
                
            }

            // POPULATION DATA
            const currentPop = getPopulation.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }); 
        
            // AGE GRAPH DATA

            const elevenAndUnder = elevenAndUnderArr.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const twelveToSeventeen = twelveToSeventeenArr.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const eighteenTo24 = eighteenTo24Arr.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const twentyfiveTo34 = twentyfiveTo34Arr.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const thirtyfiveTo44 = thirtyfiveTo44Arr.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const fortyfiveTo54 = fortyfiveTo54Arr.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const fiftyfiveTo64 = fiftyfiveTo64Arr.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const sixtyfiveToSeventyFour = sixtyfiveToSeventyFourArr.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const seventyFiveAndUp = seventyFiveAndUpArr.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);


            const thisAgeData = {
                ageData: {
                    labels: [['0-11'], ['12-17'], ['18-24'], ['25-34'], ['35-44'], ['45-54'], ['55-64'], ['65-74'], ['75+']],
                    datasets: [
                        {
                            label: 'Percentage of Population',
                            backgroundColor: [
                                'rgb(42, 207, 219)',
                                'rgb(255, 105, 180)',
                                'rgb(255, 105, 180)',
                                'rgb(52, 181, 122)',
                                'rgb(52, 181, 122)',
                                'rgb(219, 136, 42)',
                                'rgb(0, 0, 128)',
                                'rgb(0, 0, 128)',
                                'rgb(42, 207, 219)',
                            ],
                            data: [elevenAndUnder, twelveToSeventeen, eighteenTo24, twentyfiveTo34, thirtyfiveTo44, fortyfiveTo54, fiftyfiveTo64, sixtyfiveToSeventyFour, seventyFiveAndUp]
                        },
                    ]
                }
            };


            // POP CHANGES

            const popChange2010 = popChanges.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });
            const pop5YearForecastTotal = popForecasts.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });
            
            const popChange = parseFloat(popChange2010).toFixed(0);
            const thisCurrentPop = parseFloat(currentPop);
            const fiveYearPop = parseFloat(pop5YearForecastTotal);
            const popForecast5 = ((fiveYearPop - thisCurrentPop)/fiveYearPop * 100).toFixed(1);


            
            
            const populationObj = {address: thisListing.listingAddress, thisAgeData: thisAgeData, totalPopulation: thisCurrentPop, popChange: popChange, popForecast: popForecast5};
            return populationObj;
        }


        // SORT BY LISTING
        const filteredListingsPopulation = sortedByFourcastScore && sortedByFourcastScore.map(listing => getThisListingsPopulation(listing));
        
        let listing1 = (filteredListingsPopulation && filteredListingsPopulation.length && (filteredListingsPopulation[0] !== undefined)) ? filteredListingsPopulation[0] : null;
        let listing2 = (filteredListingsPopulation && filteredListingsPopulation.length && (filteredListingsPopulation[1] !== undefined)) ? filteredListingsPopulation[1] : null;
        let listing3 = (filteredListingsPopulation && filteredListingsPopulation.length && (filteredListingsPopulation[2] !== undefined)) ? filteredListingsPopulation[2] : null;
        let listing4 = (filteredListingsPopulation && filteredListingsPopulation.length && (filteredListingsPopulation[3] !== undefined)) ? filteredListingsPopulation[3] : null;
        
        const width= 300;
        const height= 300;
        const populationReport = false;

        let displayPopChangeSince2010 = (listing) => {
            return (
                <>
                    {(listing && listing.popChange) ? ( 
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 100, marginLeft: 'auto', marginRight: 'auto' }}>
                            <div className='incomeChangeValue'>{`${listing.popChange}%`}</div>
                            <div className='incomeArrows'>
                                {(listing && (listing.popChange > 0)) ? greenArrow : (listing && (listing.popChange < 0)) ? redArrow : '' }
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }
        let displayPopForecast = (listing) => {
            return (
                <>
                    {(listing && listing.popForecast) ? ( 
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 100, marginLeft: 'auto', marginRight: 'auto' }}>
                            <div className='incomeChangeValue'>{`${listing.popForecast}%`}</div>
                            <div className='incomeArrows'>
                                {(listing && (listing.popForecast > 0)) ? greenArrow : (listing && (listing.popForecast < 0)) ? redArrow : '' }
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }

        let filteredListingPopSince2010 = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsPop = getThisListingsPopulation(listing);
            return (
                <td style={{ fontSize: 14, padding: 10 }}>
                    {thisListingsPop && displayPopChangeSince2010(thisListingsPop)}
                </td>
            )
        });
        let filteredListingsPopForecast = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsPop = getThisListingsPopulation(listing);
            return (
                <td style={{ fontSize: 14, padding: 10 }}>
                    {thisListingsPop && displayPopForecast(thisListingsPop)}
                </td>
            )
        });


        return (
            <div style={{ position: 'relative', width: '98%' }}>
                <div style={{ display: 'flex', justifyContent: 'left', paddingBottom: 20 }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Age Breakdown of People in the Area</div>
                </div>
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', marginTop: 40, justifyContent: 'space-around' }}>
                    {listing1 !== null ? (<div style={{ position: 'relative', maxWidth: 350 }}>
                    <Bar
                        data={listing1.thisAgeData.ageData}
                        height={height ? height : 200}
                        width={width ? width : 350}
                        options={{
                            title: {
                                display: true,
                                text: 'Population By Age',
                                fontSize: 14,
                                color: 'black'
                            },
                            layout: {
                                padding: {
                                    top: 20
                                }
                            },
                            plugins: {
                                datalabels: {
                                    display: true,
                                    color: "black",
                                    anchor: "end",
                                    offset: -20,
                                    align: "start",
                                    font: {
                                        weight: 'bold',
                                        size: 12,
                                    },
                                    formatter: function(value, context) {
                                        return Math.floor((value / listing1.totalPopulation) * 100) + '%';
                                    },
                                },
                                legend: {
                                    display: true,
                                    position: "bottom"
                                }
                            },
                            indexAxis: `${populationReport ? 'y' : 'x'}`,
                            scales: {
                                x: populationReport ? {
                                    ticks: {
                                        display: false,
                                        beginAtZero: true,
                                    },
                                    grid: {
                                        display: false
                                    },
                                } : {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                    grid: {
                                        display: false
                                    },
                                    barPercentage: .95
                                },
                                y: populationReport ? {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                    grid: {
                                        display: false
                                    },
                                    barPercentage: .95
                                } : {
                                    ticks: {
                                        display: false,
                                        beginAtZero: true,
                                    },
                                    grid: {
                                        display: false
                                    },
                                },
                            }
                        }} 
                    />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing1.address)}</div>
                    </div>) : null}
                    {listing2 !== null ? (<div style={{ position: 'relative', maxWidth: 350 }}>
                        <Bar
                            data={listing2.thisAgeData.ageData}
                            height={height ? height : 200}
                            width={width ? width : 325}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Population By Age',
                                    fontSize: 14,
                                    color: 'black'
                                },
                                layout: {
                                    padding: {
                                        top: 20
                                    }
                                },
                                plugins: {
                                    datalabels: {
                                        display: true,
                                        color: "black",
                                        anchor: "end",
                                        offset: -20,
                                        align: "start",
                                        font: {
                                            weight: 'bold',
                                            size: 12,
                                        },
                                        formatter: function(value, context) {
                                            return Math.floor((value / listing2.totalPopulation) * 100) + '%';
                                        },
                                    },
                                    legend: {
                                        display: true,
                                        position: "bottom"
                                    }
                                },
                                indexAxis: `${populationReport ? 'y' : 'x'}`,
                                scales: {
                                    x: populationReport ? {
                                        ticks: {
                                            display: false,
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                    } : {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                        barPercentage: .95
                                    },
                                    y: populationReport ? {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                        barPercentage: .95
                                    } : {
                                        ticks: {
                                            display: false,
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                    },
                                }
                            }} 
                        />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing2.address)}</div>
                    </div>) : null}
                    {listing3 !== null ? (<div style={{ position: 'relative', maxWidth: 350 }}>
                        <Bar
                            data={listing3.thisAgeData.ageData}
                            height={height ? height : 200}
                            width={width ? width : 325}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Population By Age',
                                    fontSize: 14,
                                    color: 'black'
                                },
                                layout: {
                                    padding: {
                                        top: 20
                                    }
                                },
                                plugins: {
                                    datalabels: {
                                        display: true,
                                        color: "black",
                                        anchor: "end",
                                        offset: -20,
                                        align: "start",
                                        font: {
                                            weight: 'bold',
                                            size: 12,
                                        },
                                        formatter: function(value, context) {
                                            return Math.floor((value / listing3.totalPopulation) * 100) + '%';
                                        },
                                    },
                                    legend: {
                                        display: true,
                                        position: "bottom"
                                    }
                                },
                                indexAxis: `${populationReport ? 'y' : 'x'}`,
                                scales: {
                                    x: populationReport ? {
                                        ticks: {
                                            display: false,
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                    } : {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                        barPercentage: .95
                                    },
                                    y: populationReport ? {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                        barPercentage: .95
                                    } : {
                                        ticks: {
                                            display: false,
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                    },
                                }
                            }} 
                        />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing3.address)}</div>
                    </div>) : null}
                    {listing4 !== null ? (<div style={{ position: 'relative', maxWidth: 350 }}>
                        <Bar
                            data={listing4.thisAgeData.ageData}
                            height={height ? height : 200}
                            width={width ? width : 325}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Population By Age',
                                    fontSize: 14,
                                    color: 'black'
                                },
                                layout: {
                                    padding: {
                                        top: 20
                                    }
                                },
                                plugins: {
                                    datalabels: {
                                        display: true,
                                        color: "black",
                                        anchor: "end",
                                        offset: -20,
                                        align: "start",
                                        font: {
                                            weight: 'bold',
                                            size: 12,
                                        },
                                        formatter: function(value, context) {
                                            return Math.floor((value / listing4.totalPopulation) * 100) + '%';
                                        },
                                    },
                                    legend: {
                                        display: true,
                                        position: "bottom"
                                    }
                                },
                                indexAxis: `${populationReport ? 'y' : 'x'}`,
                                scales: {
                                    x: populationReport ? {
                                        ticks: {
                                            display: false,
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                    } : {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                        barPercentage: .95
                                    },
                                    y: populationReport ? {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                        barPercentage: .95
                                    } : {
                                        ticks: {
                                            display: false,
                                            beginAtZero: true,
                                        },
                                        grid: {
                                            display: false
                                        },
                                    },
                                }
                            }} 
                        />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing4.address)}</div>
                    </div>) : null}
                </div>
            </div>
        );
    }
}

export default PopulationComparison;