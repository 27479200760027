import React, { Component } from 'react';
import { Card, CardActions, CardContent, Button, Fab } from '@material-ui/core';
import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Col, Row } from 'reactstrap';

class DemoFilters extends Component {
    
    state = { 
        demoFilters: this.props.values.demoFilters,
    }
    
    continue = e => {
        this.props.nextStep();
        this.props.setFilters('demoFilters', this.state.demoFilters);
    }

    back = e => {
        this.props.prevStep();
    }

    render() { 

        // FILTER TAG DESCRIPTIONS
        const FilterTagTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} />
            ))(({ theme }) => ({
                [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: 'snow',
                color: 'black',
                fontWeight: 'bold',
                maxWidth: 300,
                fontSize: theme.typography.pxToRem(14),
                padding: 25,
                border: '2px solid rgb(52, 181, 122)',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                },
        }));
        
        return ( 
            <div style={{ backgroundColor: 'whitesmoke', height: '100vh', paddingTop: 50 }}>
                <Card className='customCardBackgroundColor' style={{ width: '75vw', height: '75vh', margin: 'auto', overflowY: 'scroll' }}>
                    <CardContent>
                        <h2 style={{ textAlign: 'center', marginTop: 25, marginBottom: 55 }}>Demographic Filters</h2>
                        <Row style={{ width: 850, height: 115, marginLeft: 'auto', marginRight: 'auto', marginTop: 100 }}>
                            <Col sm={3}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <FilterTagTooltip arrow placement="top"
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in largely residential areas.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                onClick={(e) => {
                                                    const value = 'localResidents';
                                                    const checked = this.state.demoFilters.includes('localResidents');
                                                    let filterTag = checked ? this.state.demoFilters.filter((tags) => tags !== value) : [...this.state.demoFilters, value]
                                                    this.setState({ demoFilters: filterTag });
                                                }}
                                                style={ this.state.demoFilters.includes('localResidents') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 180, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 180, fontSize: 12, outline: 'none' }}
                                            >
                                            Mostly Local Residents
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={3}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <FilterTagTooltip arrow placement="top"
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings where a significant amount of people work in the area.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                category='officeWorkers'
                                                checked={this.state.demoFilters.includes('officeWorkers')}
                                                onClick={(e) => {
                                                    const value = 'officeWorkers';
                                                    const checked = this.state.demoFilters.includes('officeWorkers');
                                                    let filterTag = checked ? this.state.demoFilters.filter((tags) => tags !== value) : [...this.state.demoFilters, value]
                                                    this.setState({ demoFilters: filterTag });
                                                }}
                                                style={ this.state.demoFilters.includes('officeWorkers') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 180, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 180, fontSize: 12, outline: 'none' }}
                                            >
                                            People Working
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={3}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <FilterTagTooltip arrow placement="top"
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas with known tourist populations.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                onClick={(e) => {
                                                    const value = 'nearAttraction';
                                                    const checked = this.state.demoFilters.includes('nearAttraction');
                                                    let filterTag = checked ? this.state.demoFilters.filter((tags) => tags !== value) : [...this.state.demoFilters, value]
                                                    this.setState({ demoFilters: filterTag });
                                                }}
                                                style={ this.state.demoFilters.includes('nearAttraction') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 180, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 180, fontSize: 12, outline: 'none' }}
                                            >
                                            Tourists
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={3}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <FilterTagTooltip arrow placement="top"
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas with the highest concentration of students either living, or attending school in the area.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                category='students'
                                                checked={this.state.demoFilters.includes('students')}
                                                onClick={(e) => {
                                                    const value = 'students';
                                                    const checked = this.state.demoFilters.includes('students');
                                                    let filterTag = checked ? this.state.demoFilters.filter((tags) => tags !== value) : [...this.state.demoFilters, value]
                                                    this.setState({ demoFilters: filterTag });
                                                }}
                                                style={ this.state.demoFilters.includes('students') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 180, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 180, fontSize: 12, outline: 'none' }}
                                            >
                                            Students
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ width: '90%', height: 100, marginLeft: 'auto', marginRight: 'auto' }}>
                            <Col sm={3}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <FilterTagTooltip arrow
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings where you can find more families with children living here than many other neighborhoods.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                category='areasWithChildren'
                                                checked={this.state.demoFilters.includes('areasWithChildren')}
                                                onClick={(e) => {
                                                    const value = 'areasWithChildren';
                                                    const checked = this.state.demoFilters.includes('areasWithChildren');
                                                    let filterTag = checked ? this.state.demoFilters.filter((tags) => tags !== value) : [...this.state.demoFilters, value]
                                                    this.setState({ demoFilters: filterTag });
                                                }}
                                                style={ this.state.demoFilters.includes('areasWithChildren') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 180, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 180, fontSize: 12, outline: 'none' }}
                                            >
                                            Areas With Children
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={3}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <FilterTagTooltip arrow placement="bottom"
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas with Gen Zs.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                onClick={(e) => {
                                                    const value = 'genZ';
                                                    const checked = this.state.demoFilters.includes('genZ');
                                                    let filterTag = checked ? this.state.demoFilters.filter((tags) => tags !== value) : [...this.state.demoFilters, value]
                                                    this.setState({ demoFilters: filterTag });
                                                }}
                                                style={ this.state.demoFilters.includes('genZ') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 180, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 180, fontSize: 12, outline: 'none' }}
                                            >
                                            Gen Z
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={3}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <FilterTagTooltip arrow placement="bottom"
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas with people aged 25-34.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                onClick={(e) => {
                                                    const value = 'millennialsTo34';
                                                    const checked = this.state.demoFilters.includes('millennialsTo34');
                                                    let filterTag = checked ? this.state.demoFilters.filter((tags) => tags !== value) : [...this.state.demoFilters, value]
                                                    this.setState({ demoFilters: filterTag });
                                                }}
                                                style={ this.state.demoFilters.includes('millennialsTo34') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 180, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 180, fontSize: 12, outline: 'none' }}
                                            >
                                            (24-35) Millennials
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={3}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <FilterTagTooltip arrow
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas with people aged 35-44.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                category='millennialsTo44'
                                                checked={this.state.demoFilters.includes('millennialsTo44')}
                                                onClick={(e) => {
                                                    const value = 'millennialsTo44';
                                                    const checked = this.state.demoFilters.includes('millennialsTo44');
                                                    let filterTag = checked ? this.state.demoFilters.filter((tags) => tags !== value) : [...this.state.demoFilters, value]
                                                    this.setState({ demoFilters: filterTag });
                                                }}
                                                style={ this.state.demoFilters.includes('millennialsTo44') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 180, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 180, fontSize: 12, outline: 'none' }}
                                            >
                                            (35-44) Millennials
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={3}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <FilterTagTooltip arrow placement="bottom"
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas with Gen Xs.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                onClick={(e) => {
                                                    const value = 'genX';
                                                    const checked = this.state.demoFilters.includes('genX');
                                                    let filterTag = checked ? this.state.demoFilters.filter((tags) => tags !== value) : [...this.state.demoFilters, value]
                                                    this.setState({ demoFilters: filterTag });
                                                }}
                                                style={ this.state.demoFilters.includes('genX') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 180, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 180, fontSize: 12, outline: 'none' }}
                                            >
                                            Gen X
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={3}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <FilterTagTooltip arrow
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas with Baby Boomers.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                category='boomers'
                                                checked={this.state.demoFilters.includes('boomers')}
                                                onClick={(e) => {
                                                    const value = 'boomers';
                                                    const checked = this.state.demoFilters.includes('boomers');
                                                    let filterTag = checked ? this.state.demoFilters.filter((tags) => tags !== value) : [...this.state.demoFilters, value]
                                                    this.setState({ demoFilters: filterTag });
                                                }}
                                                style={ this.state.demoFilters.includes('boomers') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 180, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 180, fontSize: 12, outline: 'none' }}
                                            >
                                            Boomers
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={3}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <FilterTagTooltip arrow placement="bottom"
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas where the average income levels and/or volume of people making higher incomes are higher here than most areas in the city.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                category='highIncomeArea'
                                                checked={this.state.demoFilters.includes('highIncomeArea')}
                                                onClick={(e) => {
                                                    const value = 'highIncomeArea';
                                                    const checked = this.state.demoFilters.includes('highIncomeArea');
                                                    let filterTag = checked ? this.state.demoFilters.filter((tags) => tags !== value) : [...this.state.demoFilters, value]
                                                    this.setState({ demoFilters: filterTag });
                                                }}
                                                style={ this.state.demoFilters.includes('highIncomeArea') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 180, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 180, fontSize: 12, outline: 'none' }}
                                            >
                                            Higher Income Area
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={3}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <FilterTagTooltip arrow placement="bottom"
                                            title={
                                                <>
                                                    <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas where residential real estate prices are typically more affordable than other neighborhoods in the city.</p>
                                                </>
                                            }
                                        >
                                            <Fab
                                                variant="extended"
                                                size="large"
                                                category='affordableRE'
                                                checked={this.state.demoFilters.includes('affordableRE')}
                                                onClick={(e) => {
                                                    const value = 'affordableRE';
                                                    const checked = this.state.demoFilters.includes('affordableRE');
                                                    let filterTag = checked ? this.state.demoFilters.filter((tags) => tags !== value) : [...this.state.demoFilters, value]
                                                    this.setState({ demoFilters: filterTag });
                                                }}
                                                style={ this.state.demoFilters.includes('affordableRE') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 180, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 180, fontSize: 12, outline: 'none' }}
                                            >
                                            Affordable Area
                                            </Fab>
                                        </FilterTagTooltip>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                        <CardActions>
                            <Button style={{ marginTop: 50, marginRight: 20, marginLeft: 'auto' }} variant="contained" color="default" size="medium" onClick={() => this.back()}>Back</Button>
                            <Button style={{ marginTop: 50, marginRight: 'auto', marginLeft: 30, backgroundColor: '#007FFF', color: 'white' }} variant="contained" size="medium" onClick={() => this.continue()}>Continue</Button>
                        </CardActions>
                    </CardContent>
                </Card>
            </div>
        );
    }
}
 
export default DemoFilters;