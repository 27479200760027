import React, { Component } from 'react';
import 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import '../../listingDetailsLayouts/demographics/demographics.css';
import './matchComparison.css';

class WeekdayWeekendMatchComparison extends Component {
    
    
    render() {

        const { listings, inputValueTitle } = this.props;
        
        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.fourcastScore - a.fourcastScore});

        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        };


        let getThisListingsOpeningTimes = (thisListing) => {
            
            const { inputValueTitle } = this.props;
            
            const thisOpeningTimes = thisListing && thisListing.filterables && thisListing.filterables.busyHours && thisListing.filterables.busyHours.openingTimes && thisListing.filterables.busyHours.openingTimes;            
            
            const weekdayVolume = thisOpeningTimes ? (thisOpeningTimes.monday + thisOpeningTimes.tuesday + thisOpeningTimes.wednesday + thisOpeningTimes.thursday + thisOpeningTimes.friday) : 0;
            const weekendVolume = thisOpeningTimes ? (thisOpeningTimes.saturday + thisOpeningTimes.sunday) : 0;

            const weekdayAvg = Math.ceil(weekdayVolume / 5);
            const weekendAvg = Math.ceil(weekendVolume / 2);

            let thisPercentageChange = (inputValueTitle && (inputValueTitle === 'Weekend')) ? Math.ceil(((weekendAvg - weekdayAvg) / weekendAvg) * 100) : Math.ceil(((weekdayAvg - weekendAvg) / weekdayAvg) * 100);
            
            const openingTimesObj = {address: thisListing.listingAddress, openingTimes: thisOpeningTimes, percentageChange: thisPercentageChange};
            
            return openingTimesObj;
        };
        

        // SORT BY RES PRICES
        const getFilteredListingsOpeningTimes = sortedByFourcastScore && sortedByFourcastScore.map(listing => getThisListingsOpeningTimes(listing));
        const filteredListingsOpeningTimes = getFilteredListingsOpeningTimes && getFilteredListingsOpeningTimes.length && getFilteredListingsOpeningTimes.sort(function(a, b){return b.percentageChange - a.percentageChange});

        const setWeekdayWeekendChart = (openingTimes) => {
            const { inputValueTitle } = this.props;
            return (
                <div>
                    <Bar
                        data={{
                            labels: [
                                'Sunday',
                                'Monday',
                                'Tuesday', 
                                'Wednesday',
                                'Thursday',
                                'Friday',
                                'Saturday',
                            ],
                            datasets: [
                                {
                                    label: 'Volume of Businesses Open Nearby',
                                    data: [
                                        `${openingTimes.sunday}`,
                                        `${openingTimes.monday}`,
                                        `${openingTimes.tuesday}`,
                                        `${openingTimes.wednesday}`,
                                        `${openingTimes.thursday}`,
                                        `${openingTimes.friday}`,
                                        `${openingTimes.saturday}`,
                                    ],
                                    backgroundColor: [
                                        (inputValueTitle === 'Weekend') ? 'rgba(0, 132, 0, 0.8)' : 'rgba(0, 132, 0, 0.2)',
                                        (inputValueTitle === 'Weekend') ? 'rgba(0, 132, 0, 0.2)' : 'rgba(0, 132, 0, 0.8)',
                                        (inputValueTitle === 'Weekend') ? 'rgba(0, 132, 0, 0.2)' : 'rgba(0, 132, 0, 0.8)',
                                        (inputValueTitle === 'Weekend') ? 'rgba(0, 132, 0, 0.2)' : 'rgba(0, 132, 0, 0.8)',
                                        (inputValueTitle === 'Weekend') ? 'rgba(0, 132, 0, 0.2)' : 'rgba(0, 132, 0, 0.8)',
                                        (inputValueTitle === 'Weekend') ? 'rgba(0, 132, 0, 0.2)' : 'rgba(0, 132, 0, 0.8)',
                                        (inputValueTitle === 'Weekend') ? 'rgba(0, 132, 0, 0.8)' : 'rgba(0, 132, 0, 0.2)',
                                    ]
                                },
                            ]
                        }}
                        height={200}
                        width={275}
                        options={{
                            title: {
                                display: true,
                                text: 'Weekday / Weekend Traffic',
                                fontSize: 10,
                                color: 'black'
                            },
                            plugins: {
                                datalabels: {
                                display: false,
                                color: 'black'
                                }
                            },
                            scales: {
                                x: {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                    gridLines: {
                                        display: false
                                    },
                                    barPercentage: .95
                                },
                                y: {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                    gridLines: {
                                        display: false
                                    },
                                },
                            },
                        }} 
                    />
                </div>
            )
        }
        
        
        return (
            <div style={{ position: 'relative', width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                    <div style={{ fontSize: 24, fontWeight: 700, color: 'darkgreen' }}>{inputValueTitle}</div>
                    <div style={{ fontSize: 22, fontWeight: 500, marginLeft: 5 }}>Traffic:</div>
                </div>
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', justifyContent: 'space-around' }}>
                    {filteredListingsOpeningTimes && filteredListingsOpeningTimes.length && filteredListingsOpeningTimes.map((listing) => 
                        <div style={{ position: 'relative', marginTop: 25, width: 325 }}>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>{filteredListingsOpeningTimes && filteredListingsOpeningTimes.length && (filteredListingsOpeningTimes.indexOf(listing) + 1)}</div>
                                <div style={{ marginLeft: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing.address)}</div>
                                {filteredListingsOpeningTimes && filteredListingsOpeningTimes.length && filteredListingsOpeningTimes.indexOf(listing) === 0 ? <div style={{ display: 'flex', fontSize: 12, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', color: 'rgb(52, 181, 122)', marginLeft: 10, marginTop: 1 }}>Best</div> : null}
                                <div style={{ fontSize: 16, fontWeight: 800, marginLeft: 15, color: (listing && listing.percentageChange && (listing.percentageChange < 0)) ? 'darkred' : 'darkgreen' }}>{`${(listing && listing.percentageChange && (listing.percentageChange < 0)) ? '' : '+'}${listing && listing.percentageChange && listing.percentageChange}%`}</div>
                                <div style={{ fontSize: 14, fontWeight: 600, marginLeft: 5, marginTop: 1 }}>Change</div>
                            </div>
                            <div style={{ padding: 5, width: 275, height: 200, marginLeft: '50%', transform: 'translate(-50%, 0)' }}>
                                {(listing !== null) && listing.openingTimes && setWeekdayWeekendChart(listing.openingTimes)}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default WeekdayWeekendMatchComparison;