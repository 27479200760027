import React, { Component } from 'react';
import { Paper } from '@mui/material';
import PopularBusinessTable from '../listingDetailsLayouts/competition/popularBusinessTable';
import fourcastLogo from '../../Assets/fourcast_alt_logo_green.png';
import '../listingDetailsLayouts/competition/competition.css';



class TopBizNearbyReport extends Component {
    
    
    render() { 

        const { yelpData, thisListing, thisListing: { listingAddress: getListingAddress } = {}} = this.props;
        

        let thisAddressArray = [];
        thisAddressArray.push(getListingAddress);
        let addressString = thisAddressArray.toString();
        let thisListingAddress = addressString.split(',');
        

        return (
            <Paper id="trafficReportPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 15, position: 'relative' }}>
                <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '6fr 1fr' }}>
                        <div className='reportAddress'>
                            <h2>{thisListingAddress[0]}</h2>
                        </div>
                        <div className='reportFourcastLogoArea'>
                            <img className='reportFourcastLogo' src={fourcastLogo} alt='Fourcast Reports Logo' />
                        </div>
                    </div>
                    <hr />
                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 50 }}>
                                <h2 style={{ textAlign: 'center', width: '100%' }}>Top Businesses Nearby</h2>
                            </div>  
                            <div style={{ marginTop: 50, height: 750, width: 675 }}>
                                <PopularBusinessTable thisListing={thisListing} yelpData={yelpData} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: 10, position: 'absolute', bottom: '1%', right: '1%', fontWeight: 800, color: 'darkgreen' }}>fourcastscore.com</div>
            </Paper>
        );
    }
}

export default TopBizNearbyReport;