import { combineReducers } from 'redux';
import listingReducer from './listingReducer';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import leadReducer from './leadReducer';
import userUpdateReducer from './authReducer';
import forgotPasswordReducer from './passwordResetReducer';
import stripeReducer from './stripeReducer';


export default combineReducers({
    listing: listingReducer,
    error: errorReducer,
    auth: authReducer,
    lead: leadReducer,
    userUpdate: userUpdateReducer,
    forgotPassword: forgotPasswordReducer,
    stripe: stripeReducer
});