import React, { Component } from 'react';
import { ImArrowRight } from 'react-icons/im';
import './demographics.css';

class ChildrenHousesGauge extends Component {
    
    
    render() { 

        const { screenWidth, filterables: { childrenHousesGauge: thisHousesWithChildrenGauge } = {}} = this.props;


        // SET NEEDLE ANGLE FROM -7 - 77

        let housesWithChildrenNeedle = 0;

        if (thisHousesWithChildrenGauge >= 95) {
            housesWithChildrenNeedle = -4;
        } else if (thisHousesWithChildrenGauge >= 80) {
            housesWithChildrenNeedle = 0;
        } else if (thisHousesWithChildrenGauge >= 70) {
            housesWithChildrenNeedle = 10;
        } else if (thisHousesWithChildrenGauge >= 60) {
            housesWithChildrenNeedle = 20;
        } else if (thisHousesWithChildrenGauge >= 50) {
            housesWithChildrenNeedle = 30;
        } else if (thisHousesWithChildrenGauge >= 40) {
            housesWithChildrenNeedle = 40;
        } else if (thisHousesWithChildrenGauge >= 30) {
            housesWithChildrenNeedle = 50;
        } else if (thisHousesWithChildrenGauge >= 20) {
            housesWithChildrenNeedle = 60;
        } else if (thisHousesWithChildrenGauge >= 10) {
            housesWithChildrenNeedle = 70;
        } else {
            housesWithChildrenNeedle = 74;
        }
        

        const setChildrenHousesGauge = (gauge) => {
            setTimeout(() => {
                const childrenHouseGauge = document.querySelector('.childHouseCountPointer');
                let childrenHouseRotation = gauge;                
                childrenHouseGauge && childrenHouseGauge.style.setProperty('--bottom', childrenHouseRotation);
            }, 200, { once: true });
        }
        
        thisHousesWithChildrenGauge && setChildrenHousesGauge(housesWithChildrenNeedle);

        return ( 
            <>{((screenWidth > 1000) || (screenWidth === undefined)) ? (<div>
                <div className='childHouseCountHigh'>High</div>
                <div className='childHouseCountGauge'>
                    <ImArrowRight className='childHouseCountPointer' />
                </div>
                <div className='childHouseCountLow'>Low</div>
            </div>) : (<div>
                <div className='mobileChildHouseCountHigh'>High</div>
                <div className='mobileChildHouseCountGauge'>
                    <ImArrowRight className='childHouseCountPointer' />
                </div>
                <div className='mobileChildHouseCountLow'>Low</div>
            </div>)}</>
        );
    }
}
 
export default ChildrenHousesGauge;