import React, { Component } from 'react';
import 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import {Chart} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

class AgeGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thisAgeData: props.thisAgeData
        }
    }

    static defaultProps = {
        displayTitle: true,
    }
    
    render() {

        const { thisAgeData: { ageData: ageGraphData } = {}} = this.props;
        const { width, height, populationReport, thisCurrentPop } = this.props;
        
        return (
            <div className="ageGraph">
                <Bar
                    data={ageGraphData}
                    height={height ? height : 200}
                    width={width ? width : 325}
                    options={{
                        title: {
                            display: true,
                            text: 'Population By Age',
                            fontSize: 14,
                            color: 'black'
                        },
                        plugins: {
                            datalabels: {
                                display: true,
                                color: "black",
                                anchor: "end",
                                offset: -30,
                                align: "start",
                                font: {
                                    weight: 'bold',
                                    size: 12,
                                },
                                formatter: function(value, context) {
                                    return Math.floor((value / thisCurrentPop) * 100) + '%';
                                },
                            },
                            legend: {
                                display: false,
                                position: "bottom"
                            }
                        },
                        indexAxis: `${populationReport ? 'y' : 'x'}`,
                        scales: {
                            x: populationReport ? {
                                ticks: {
                                    display: false,
                                    beginAtZero: true,
                                },
                                grid: {
                                    display: false
                                },
                            } : {
                                ticks: {
                                    beginAtZero: true,
                                },
                                grid: {
                                    display: false
                                },
                                barPercentage: .95
                            },
                            y: populationReport ? {
                                ticks: {
                                    beginAtZero: true,
                                },
                                grid: {
                                    display: false
                                },
                                barPercentage: .95
                            } : {
                                ticks: {
                                    display: false,
                                    beginAtZero: true,
                                },
                                grid: {
                                    display: false
                                },
                            },
                        },
                    }} 
                />
            </div>
        );
    }
}

export default AgeGraph;
