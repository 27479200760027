import React, { Component } from 'react';
import { Form, FormGroup, Label, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { Card, CardActions, CardContent, Button } from '@material-ui/core';
import Select from 'react-select';
import { Row } from 'reactstrap';


class PriceAndSize extends Component {
    

    continue = e => {
        this.props.nextStep();
    }

    back = e => {
        this.props.prevStep();
    }

    

    render() { 
        
        const { values, setMinSize, setMaxSize, setMaxPrice, setBudgetType, budgetTypes } = this.props;
        
        return (
            <div style={{ backgroundColor: 'whitesmoke', height: '100vh', paddingTop: 50 }}>
                <Card className='customCardBackgroundColor' style={{ width: '75vw', height: '75vh', margin: 'auto', overflowY: 'scroll' }}>
                    <CardContent>
                        <h2 style={{ textAlign: 'center', marginTop: 20, marginBottom: 100 }}>Price & Size</h2>
                        <Form style={{ width: 750, margin: 'auto' }}>
                            <div style={{ display: 'grid', gridTemplateColumns: '2fr 2fr 3fr' }}>
                                <div>
                                    <FormGroup style={{ width: 150, marginLeft: 'auto', marginRight: 'auto' }}>
                                        <InputGroup>
                                        <Input 
                                            type='number'
                                            category='minSize'
                                            placeholder='0'
                                            onChange={(e) => setMinSize(e.target.value)}
                                            defaultValue={values.minSize}
                                        />
                                        <InputGroupAddon addonType="append">
                                        <InputGroupText>Sqft</InputGroupText>
                                        </InputGroupAddon>
                                        </InputGroup>
                                        <Label style={{ fontSize: 12, color: 'black' }} for='squareFeet'>Min Size</Label>
                                    </FormGroup>
                                </div>
                                <div>
                                    <FormGroup style={{ width: 150, marginLeft: 'auto', marginRight: 'auto' }}>
                                        <InputGroup>
                                        <Input 
                                            type='number'
                                            category='maxSize'
                                            placeholder='9999'
                                            onChange={(e) => setMaxSize(e.target.value)}
                                            defaultValue={values.maxSize}
                                        />
                                        <InputGroupAddon addonType="append">
                                        <InputGroupText>Sqft</InputGroupText>
                                        </InputGroupAddon>
                                        </InputGroup>
                                        <Label style={{ fontSize: 12, color: 'black' }} for='squareFeet'>Max Size</Label>
                                    </FormGroup>
                                </div>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                                    <div>
                                        <FormGroup style={{ width: 175, marginLeft: 'auto', marginRight: 'auto' }}>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                <InputGroupText>$</InputGroupText>
                                                </InputGroupAddon>
                                                    <Input 
                                                        type='text'
                                                        category='maxPrice'
                                                        placeholder='0'
                                                        onChange={(e) => setMaxPrice(e.target.value)}
                                                        defaultValue={values.price}
                                                    />
                                            </InputGroup>
                                            <Label style={{ fontSize: 12, color: 'black' }} for='price'>Max Budget</Label>
                                            <div style={{ fontSize: 12, color: 'black' }} for='price'>{(values.price !== null && values.price > 0) ? `$${parseFloat(values.price).toLocaleString("en-US")} ${(values.budgetType === 1) ? 'total / YEAR' : 'per sqft. / YEAR'}` : `$0 ${(values.budgetType === 1) ? 'total / YEAR' : 'per sqft. / YEAR'}`}</div>
                                        </FormGroup>
                                    </div>
                                    <div>
                                        <Select 
                                            options={budgetTypes}
                                            placeholder="total / year"
                                            name="/ year"
                                            onChange={setBudgetType}
                                            defaultValue={values.budgetType}
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                width: 130,
                                                fontSize: 11
                                                }),
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                        <CardActions>
                            <Button style={{ marginTop: 100, marginRight: 20, marginLeft: 'auto' }} variant="contained" color="default" size="medium" onClick={() => this.back()}>Back</Button>
                            {((values.minSize !== null) && (values.maxSize !== null) && (values.price !== null)) ? <Button style={{ marginTop: 100, marginRight: 'auto', marginLeft: 30, backgroundColor: '#007FFF', color: 'white' }} variant="contained" size="medium" onClick={() => this.continue()}>Continue</Button> : <Button style={{ marginTop: 100, marginRight: 'auto', marginLeft: 30 }} variant="contained" color="primary" size="medium" disabled>Continue</Button>}
                        </CardActions>
                    </CardContent>
                </Card>
            </div>
        );
    }
}
 

export default PriceAndSize;