import React, { Component } from 'react';
import { Paper } from '@mui/material';
import PriceGauge from '../../Assets/priceGauge.png';
import 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import fourcastLogo from '../../Assets/fourcast_alt_logo_green.png';
import '../listingDetailsLayouts/traffic/traffic.css';
import '../listingDetailsLayouts/demographics/demographics.css';
import '../listingDetailsLayouts/competition/competition.css';



class HighEndBizReport extends Component {
    
    
    render() { 

        const { thisListing: { listingAddress: getListingAddress } = {}} = this.props;
        const { filterables: { bizPricesIndex: bizPricesIndex } = {}} = this.props;
    
        
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! PRICE LEVEL GAUGES !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        
        const percentageScore = bizPricesIndex.percentageScore;
        const thisListingsPriceVolume = bizPricesIndex.priceLevels;

        let priceGaugeNeedle = 0;
        
        if (percentageScore < 25) {
            priceGaugeNeedle = -65;
        } else if (percentageScore === 25) {
            priceGaugeNeedle = -45;
        } else if (percentageScore < 50) {
            priceGaugeNeedle = -25;
        } else if (percentageScore === 50) {
            priceGaugeNeedle = 0;
        } else if (percentageScore < 75) {
            priceGaugeNeedle = 25;
        } else if (percentageScore === 75) {
            priceGaugeNeedle = 45;
        } else if (percentageScore < 100) {
            priceGaugeNeedle = 65;
        } else {
            priceGaugeNeedle = 80;
        }


        const setPriceGauge = (gauge) => { 
            setTimeout(() => {
                const priceGauge = document.querySelector('.priceGaugeNeedleReport');
                let thisRotation = gauge;
                priceGauge && priceGauge.style.setProperty('--rotation', thisRotation);
            }, 200, { once: true });
        }
        
        percentageScore && setPriceGauge(priceGaugeNeedle);
        


        const commaNumber = (inc) => {
            let newNum = Number(inc);
            let numString = newNum.toLocaleString("en-US");
            return numString;
        }

        let thisAddressArray = [];
        thisAddressArray.push(getListingAddress);
        let addressString = thisAddressArray.toString();
        let thisListingAddress = addressString.split(',');
        

        return (
            <Paper id="trafficReportPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 15, position: 'relative' }}>
                <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '6fr 1fr' }}>
                        <div className='reportAddress'>
                            <h2>{thisListingAddress[0]}</h2>
                        </div>
                        <div className='reportFourcastLogoArea'>
                            <img className='reportFourcastLogo' src={fourcastLogo} alt='Fourcast Reports Logo' />
                        </div>
                    </div>
                    <hr />
                    <div style={{ marginTop: 15, height: '30%', display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div>
                                <div style={{ height: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <p>High-End Area Gauge:</p>
                                </div>
                                <div style={{ width: 350, height: 170, marginRight: 30 }}>
                                    <div style={{ height: 170, position: 'relative', margin: 'auto' }}>
                                        <div className='priceDataReport'>
                                            <img src={PriceGauge} style={{ height: 125, width: 300 }} alt='High End Gauge Report' />
                                            <div className='priceGaugeNeedleReport'></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div>
                                <div style={{ textAlign: 'center', fontSize: 14, fontWeight: 'bold', width: "55%", marginLeft: 'auto', marginRight: 'auto' }}>
                                    <span>This Listing Has A Higher</span><span style={{ fontStyle: 'italic', color: 'green' }}> Price-Level Average</span><span> or More</span><span style={{ color: 'green' }}> $$$</span><span> &</span><span style={{ color: 'green' }}> $$$$</span><span> Businesses Nearby Than:</span>
                                </div>
                                <div style={{ textAlign: 'center', marginTop: 25, marginBottom: 25, color: 'darkgreen' }}>
                                    <h1>{percentageScore}%</h1>
                                </div>
                                <div style={{ textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>
                                    of Other Listings in This City
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div style={{ marginTop: 25, height: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                        <div style={{ height: 250, width: '100%', display: 'grid', gridTemplateColumns: '3fr 4fr 4fr' }}>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <h5 style={{ margin: '15px 0px 10px 20px' }}>High-End Gauge analyzes:</h5>
                            </div>
                            <div style={{ height: 250, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ margin: 'auto', fontSize: 12, fontFamily: 'sans-serif', fontWeight: 'bold', textAlign: 'center' }}>
                                    <div style={{ width: 125, margin: 'auto', height: '50%' }}>
                                        <h5 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(21, 134, 84)' }}>Average Price Level of Businesses in the Area</h5>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: 250, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ margin: 'auto', fontSize: 12, fontFamily: 'sans-serif', fontWeight: 'bold', textAlign: 'center' }}>
                                    <div style={{ width: 125, margin: 'auto', height: '50%' }}>
                                        <h5 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(21, 134, 84)' }}>Volume of High-End Businesses in the Area</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div style={{ height: '30%', width: '100%', marginTop: 50 }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div>
                                <div style={{ height: '15%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <p>Volume of businesses by average price level:</p>
                                </div>
                                <div style={{ width: 500, height: 250, marginTop: 25 }}>
                                    <div className="priceChart" style={{ paddingRight: 10 }}>
                                        <Bar
                                        data={{
                                            labels: ['$$$$', '$$$', '$$', '$'],
                                            datasets: [
                                                {
                                                    label: 'Number of Businesses',
                                                    data: [
                                                        `${thisListingsPriceVolume.priceLevel4}`,
                                                        `${thisListingsPriceVolume.priceLevel3}`,
                                                        `${thisListingsPriceVolume.priceLevel2}`,
                                                        `${thisListingsPriceVolume.priceLevel1}`
                                                    ],
                                                    backgroundColor: [
                                                        'rgba(255, 99, 132, 0.8)',
                                                        'rgba(54, 162, 235, 0.8)',
                                                        'rgba(255, 206, 86, 0.8)',
                                                        'rgba(75, 192, 192, 0.8)',
                                                    ]
                                                }
                                            ]
                                        }}
                                        height={150}
                                        options={{
                                            padding: 20,
                                            title: {
                                                display: true,
                                                text: 'Price Level Distribution',
                                                fontSize: 18,
                                                color: 'black'
                                            },
                                            plugins: {
                                                datalabels: {
                                                display: true,
                                                color: 'black'
                                                }
                                            },
                                            scales: {
                                                xAxes: [{
                                                    ticks: {
                                                        beginAtZero: true
                                                    },
                                                    gridLines: {
                                                        display: false
                                                    }
                                                }],
                                                yAxes: [{
                                                    stacked: true,
                                                    ticks: {
                                                        fontSize: 14,
                                                    },
                                                    gridLines: {
                                                        display: false
                                                    },
                                                }],
                                            },
                                        }} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: 10, position: 'absolute', bottom: '1%', right: '1%', fontWeight: 800, color: 'darkgreen' }}>fourcastscore.com</div>
            </Paper>
        );
    }
}

export default HighEndBizReport;