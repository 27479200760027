import React, { Component } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Card, CardContent, Fab } from '@material-ui/core';
import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { BsPeopleFill } from 'react-icons/bs';
import { GiHouse } from 'react-icons/gi';
import { FaRegMoneyBillAlt, FaChild } from 'react-icons/fa';
import { ImManWoman } from 'react-icons/im';
import { MdChildFriendly } from 'react-icons/md';
import './demographics.css';
import IncomeGraph from './incomeGraph';
import RentOwnGraph from './rentOwnGraph';
import AgeGraph from './ageGraph';
import FamilyStatusGraph from './familyStatusGraph';
import CommonPeople from './commonPeople';
import ResPriceGauge from './resPriceGauge';
import IncomeGauge from './incomeGauge';
import ChildrenHousesGauge from './childrenHouses';
import GreenArrow from '../../../Assets/greenArrow.png';
import RedArrow from '../../../Assets/redArrow.png';

const greenArrow = <img className='greenArrow' src={GreenArrow} alt='' />;
const redArrow = <img className='redArrow' src={RedArrow} alt='' />;

class Demographics extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            thisListing: props.thisListing,
        }
    }

    render() { 

        const { thisListing, filterables, scores, attomData } = this.props;
        
        // INCOME GRAPH DATA

            // get current geoIdV4 array
        const { attomData: { geoIdV4 } = {}} = this.props;
    
        let averageHouseholdIncome = 0;
        let averageIndividualIncome = 0;
        const getTotalNumOfHouseholds = [];

            // number of people in each income bracket
        let householdIncomeFifteenOrLess = 0;
        let householdIncomeFifteenToTwentyFive = 0;
        let householdIncomeTwentyFiveToThirtyFive = 0;
        let householdIncomeThirtyFiveToFifty = 0;
        let householdIncomeFiftyToSeventyFive = 0;
        let householdIncomeSeventyFiveToOneHundred = 0;
        let householdIncomeOneHundredToOneHundredTwentyFive = 0;
        let householdIncomeOneHundredTwentyFiveToOneHundredFifty = 0;
        let householdIncomeOneHundredFiftyToTwoHundred = 0;
        let householdIncomeTwoHundredAndMore = 0;

        const getOwnerDwellings = [];
        const getRenterDwellings = [];

        // RES REAL ESTATE DATA
        let thisListingsPrimaryNeighbRent = {};
        let thisListingsSecondaryNeighbRent = {};
        let thisListingsPrimaryNeighbSalePrice = {};
        let thisListingsSecondaryNeighbSalePrice = {};

        // POPULATION DATA
        const getPopulation = [];
        const getDayPop = [];
    
            // ages 
        const elevenAndUnderArr = [];
        const twelveToSeventeenArr = [];
        const eighteenTo24Arr = [];
        const twentyfiveTo34Arr = [];
        const thirtyfiveTo44Arr = [];
        const fortyfiveTo54Arr = [];
        const fiftyfiveTo64Arr = [];
        const sixtyfiveToSeventyFourArr = [];
        const seventyFiveAndUpArr = [];

            // pop trends
        const popChanges = [];
        const popForecasts = [];

            // family status
        const getFamilyHouseholds = [];
        const getNonFamilyHouseholds = [];

            // children pop
        const getHouseholdsWithChildren = [];

            // types of people
        const getStudentsPop = [];
        const getWorkersPop = [];
        
        if (geoIdV4 && geoIdV4.length) {
            
            // avg income numbers
            let primaryIndIncArea = null;
            let secondaryIndIncArea = null;
            let primaryHouseIncArea = null;
            let secondaryHouseIncArea = null;

            
            for (let g = 0; g < geoIdV4.length; g++) {
                
                // INCOME DATA
                if (geoIdV4 && (geoIdV4[g].type === 'N4')) {
                    // income data
                    primaryHouseIncArea = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.avg_Household_Income;
                    primaryIndIncArea = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.household_Income_Per_Capita;
                    const getGlobalTotalHouseholdsFamNum = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Non_Family;
                    const getGlobalTotalHouseholdsNonFamNum = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Family;
                    const globalTotalHouseholds = (getGlobalTotalHouseholdsFamNum + getGlobalTotalHouseholdsNonFamNum);
                    getTotalNumOfHouseholds.push(globalTotalHouseholds);
                    householdIncomeFifteenOrLess += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_Less_Than_15000_Pct * .01));
                    householdIncomeFifteenToTwentyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_15000_24999_Pct * .01));
                    householdIncomeTwentyFiveToThirtyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_25000_34999_Pct * .01));
                    householdIncomeThirtyFiveToFifty += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_35000_49999_Pct * .01));
                    householdIncomeFiftyToSeventyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_50000_74999_Pct * .01));
                    householdIncomeSeventyFiveToOneHundred += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_75000_99999_Pct * .01));
                    householdIncomeOneHundredToOneHundredTwentyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_100000_124999_Pct * .01));
                    householdIncomeOneHundredTwentyFiveToOneHundredFifty += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_125000_149999_Pct * .01));
                    householdIncomeOneHundredFiftyToTwoHundred += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_150000_199999_Pct * .01));
                    householdIncomeTwoHundredAndMore += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_200000_And_Over_Pct * .01));
                } else {
                    // income data
                    secondaryHouseIncArea = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.avg_Household_Income;
                    secondaryIndIncArea = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.household_Income_Per_Capita;
                    const getGlobalTotalHouseholdsFamNum = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Non_Family;
                    const getGlobalTotalHouseholdsNonFamNum = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Family;
                    const globalTotalHouseholds = (getGlobalTotalHouseholdsFamNum + getGlobalTotalHouseholdsNonFamNum);
                    getTotalNumOfHouseholds.push(globalTotalHouseholds);
                    householdIncomeFifteenOrLess += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_Less_Than_15000_Pct * .01));
                    householdIncomeFifteenToTwentyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_15000_24999_Pct * .01));
                    householdIncomeTwentyFiveToThirtyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_25000_34999_Pct * .01));
                    householdIncomeThirtyFiveToFifty += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_35000_49999_Pct * .01));
                    householdIncomeFiftyToSeventyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_50000_74999_Pct * .01));
                    householdIncomeSeventyFiveToOneHundred += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_75000_99999_Pct * .01));
                    householdIncomeOneHundredToOneHundredTwentyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_100000_124999_Pct * .01));
                    householdIncomeOneHundredTwentyFiveToOneHundredFifty += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_125000_149999_Pct * .01));
                    householdIncomeOneHundredFiftyToTwoHundred += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_150000_199999_Pct * .01));
                    householdIncomeTwoHundredAndMore += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_200000_And_Over_Pct * .01));
                }

                // RES RE DATA
                const thisAreaType = geoIdV4 && geoIdV4[g] && geoIdV4[g].type;
                const thisListingsRent = (geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.housing_Median_Rent));
                const thisListingsSalePrice = (geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.housing_Owner_Households_Median_Value));

                if (geoIdV4 && (geoIdV4.length > 1)) {
                    if (thisAreaType === 'N4') {    
                        thisListingsPrimaryNeighbRent = {medRent: thisListingsRent, areaType: thisAreaType}
                    } else {
                        thisListingsSecondaryNeighbRent = {medRent: thisListingsRent, areaType: thisAreaType}
                    }
                } else {
                    thisListingsPrimaryNeighbRent = {medRent: thisListingsRent, areaType: thisAreaType}
                }

                if (geoIdV4 && (geoIdV4.length > 1)) {
                    if (thisAreaType === 'N4') {    
                        thisListingsPrimaryNeighbSalePrice = {medSalePrice: thisListingsSalePrice, areaType: thisAreaType}
                    } else {
                        thisListingsSecondaryNeighbSalePrice = {medSalePrice: thisListingsSalePrice, areaType: thisAreaType}
                    }
                } else {
                    thisListingsPrimaryNeighbSalePrice = {medSalePrice: thisListingsSalePrice, areaType: thisAreaType}
                }

                const theseOwnerDwellings = (geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.housing_Units_Owner_Occupied));
                const theseRenterDwellings = (geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.housing_Units_Renter_Occupied));
                getOwnerDwellings.push(theseOwnerDwellings);
                getRenterDwellings.push(theseRenterDwellings);


                // POPULATION DATA
                const totalPopulation = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population);
                const thisDayPop = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Daytime);
                
                const zeroToFivePct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_0_5_Pct);
                const sixTo11Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_6_11_Pct);
                const twelveTo17Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_12_17_Pct);
                const e18To24Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_18_24_Pct);
                const t25To34Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_25_34_Pct);
                const t35To44Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_35_44_Pct);
                const f45To54Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_45_54_Pct);
                const f55To64Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_55_64_Pct);
                const s65To74Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_65_74_Pct);
                const s75To84Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_75_84_Pct);
                
                getPopulation.push(totalPopulation);
                getDayPop.push(thisDayPop);
                elevenAndUnderArr.push((totalPopulation * (zeroToFivePct * .01)) + (totalPopulation * (sixTo11Pct * .01)));
                twelveToSeventeenArr.push(totalPopulation * (twelveTo17Pct * .01));
                eighteenTo24Arr.push(totalPopulation * (e18To24Pct * .01));
                twentyfiveTo34Arr.push(totalPopulation * (t25To34Pct * .01));
                thirtyfiveTo44Arr.push(totalPopulation * (t35To44Pct * .01));
                fortyfiveTo54Arr.push(totalPopulation * (f45To54Pct * .01));
                fiftyfiveTo64Arr.push(totalPopulation * (f55To64Pct * .01));
                sixtyfiveToSeventyFourArr.push(totalPopulation * (s65To74Pct * .01));
                seventyFiveAndUpArr.push(totalPopulation * (s75To84Pct * .01));


                const popChangeSince2010 = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Chg_Pct_2010);
                let popChangeWeightedValue = (geoIdV4 && (geoIdV4.length > 1)) ? (geoIdV4 && (geoIdV4[g].type !== 'N4')) ? (popChangeSince2010 * .4) : (popChangeSince2010 * .6) : popChangeSince2010;
                let popForecast5YearNum = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_5_Yr_Projection);

                popChanges.push(popChangeWeightedValue);
                popForecasts.push(popForecast5YearNum);

                const famHouseNum = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.households_Family);
                getFamilyHouseholds.push(famHouseNum);
                const nonFamHouseNum = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.households_Non_Family);
                getNonFamilyHouseholds.push(nonFamHouseNum);

                const totalHouseholdsNum = (famHouseNum + nonFamHouseNum);
                const famHouseWithChildPct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.households_Family_W_Children_Pct);
                const famHouseWithChildNum = (totalHouseholdsNum * (famHouseWithChildPct * .01));
                const nonFamHouseWithChildPct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.households_Non_Family_W_Children_Pct);
                const nonFamHouseWithChildNum = (totalHouseholdsNum * (nonFamHouseWithChildPct * .01));
                const householdsWChildrenNum = (famHouseWithChildNum + nonFamHouseWithChildNum);
                getHouseholdsWithChildren.push(householdsWChildrenNum);

                
                let totalWorkersPop = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.occupation_Soc_Cnt);
                let whiteCollarPct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.occupation_White_Collar_Pct);
                
                let studentsNum = (geoIdV4 && geoIdV4[g].commV4Data && (parseFloat(geoIdV4[g].commV4Data.enrollment_Public_Undergraduate_College_Pct) + parseFloat(geoIdV4[g].commV4Data.enrollment_Private_Undergraduate_College_Pct) + parseFloat(geoIdV4[g].commV4Data.enrollment_Private_Graduate_Or_Professional_School_Pct) + parseFloat(geoIdV4[g].commV4Data.enrollment_Public_Graduate_Or_Professional_School_Pct)));
                
                getWorkersPop.push((totalWorkersPop * (whiteCollarPct * .01)));
                getStudentsPop.push((totalPopulation * (studentsNum * .01)));


            }
            
            let avgHouseInc = (primaryHouseIncArea === null) ? secondaryHouseIncArea : parseFloat(Math.ceil((primaryHouseIncArea * .6) + (secondaryHouseIncArea * .4)).toFixed(0));
            let avgIndInc = (primaryIndIncArea === null) ? secondaryIndIncArea : parseFloat(Math.ceil((primaryIndIncArea * .6) + (secondaryIndIncArea * .4)).toFixed(0));
            averageIndividualIncome = avgIndInc;
            averageHouseholdIncome = avgHouseInc;
        }

        // number of people in each income bracket
        const graphIncomeUnderSeventyFive = [householdIncomeFifteenOrLess, householdIncomeFifteenToTwentyFive, householdIncomeTwentyFiveToThirtyFive, householdIncomeThirtyFiveToFifty, householdIncomeFiftyToSeventyFive];
        const graphIncomeSeventyFiveToOneFifty = [householdIncomeSeventyFiveToOneHundred, householdIncomeOneHundredToOneHundredTwentyFive, householdIncomeOneHundredTwentyFiveToOneHundredFifty];
        
        const underSeventyFiveSum = graphIncomeUnderSeventyFive.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const seventyFiveToOneFiftySum = graphIncomeSeventyFiveToOneFifty.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const oneFiftyToTwoHundredSum = parseFloat(householdIncomeOneHundredFiftyToTwoHundred).toFixed(0);
        const overTwoHundredSum = parseFloat(householdIncomeTwoHundredAndMore).toFixed(0);

        const thisIncomeData = {
            incomeData: {
                labels: [' Under $75k', '$75k-$150k', '$150k-$200k', 'Over $200k'],
                datasets: [
                    {
                        backgroundColor: ['rgb(187, 236, 205)', 'rgb(124, 218, 158)', 'rgb(60, 200, 112)', 'rgb(21, 76, 41)'],
                        data: [underSeventyFiveSum, seventyFiveToOneFiftySum, oneFiftyToTwoHundredSum, overTwoHundredSum]
                    }
                ],
            }
        };
        
        // Income Trends Data
        // Old commData average income for change analyses and historical datapoints
        const totalNumOfHouseholds = getTotalNumOfHouseholds.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });
        const thisOldCommData = thisListing && thisListing.oldCommData && thisListing.oldCommData.sort(function(a, b){return a.year-b.year});
        
        const hhdNum = thisOldCommData && thisOldCommData.length && thisOldCommData[0].oldCommData && thisOldCommData[0].oldCommData.hhd;
        const averageHouseholdIncomeIn2021 = thisOldCommData && thisOldCommData.length && thisOldCommData[0].oldCommData && thisOldCommData[0].oldCommData.inccyavehh;
        
        // insert logic eventually to get the year from the "year" value in the oldCommData object. Right now it is just using 2021, however, the more more "old" neighborhoods being created as the years go on, eventually the year from the created date will have to be extracted.
        //const previousCommDataYear = thisOldCommData && thisOldCommData.length && thisOldCommData[0].year && thisOldCommData[0].year;
        
        const previousCommDataYear = (attomData && attomData.oldCommData) ? attomData.oldCommData.map(oldCommDataObj => oldCommDataObj.year) : '2021';
        const incomeChangeSince2021 = parseFloat(((averageHouseholdIncome - averageHouseholdIncomeIn2021) / averageHouseholdIncomeIn2021) * 100).toFixed(0);
        const hhdChangeSince2021 = parseFloat(((totalNumOfHouseholds - hhdNum) / hhdNum) * 100).toFixed(0);
        


        // RES REAL ESTATE DATA !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

        // find avg rent by weighing each N-type (if there are multiple) => .6 for primary N4 && .4 for secondary N2 or N3
        const primaryRent = thisListingsPrimaryNeighbRent.medRent;
        const secondaryRent = thisListingsSecondaryNeighbRent && thisListingsSecondaryNeighbRent.medRent ? thisListingsSecondaryNeighbRent.medRent : null;
        const secondaryRentAreaType = thisListingsSecondaryNeighbRent && thisListingsSecondaryNeighbRent.areaType ? thisListingsSecondaryNeighbRent.areaType : null;

        let medRent = 'N/A';

        if ((secondaryRent !== null) && (secondaryRentAreaType !== null)) {
            let primaryVal = (primaryRent * .6);
            let secondaryVal = (secondaryRent * .4);
            medRent = Math.ceil(primaryVal + secondaryVal);
        } else {
            medRent = Math.ceil(primaryRent);
        }

        // find median sale price by weighing each N-type (if there are multiple) => .6 for primary N4 && .4 for secondary N2 or N3
        const primarySalePrice = thisListingsPrimaryNeighbSalePrice.medSalePrice;
        const secondarySalePrice = thisListingsSecondaryNeighbSalePrice && thisListingsSecondaryNeighbSalePrice.medSalePrice ? thisListingsSecondaryNeighbSalePrice.medSalePrice : null;
        const secondarySalePriceAreaType = thisListingsSecondaryNeighbSalePrice && thisListingsSecondaryNeighbSalePrice.areaType ? thisListingsSecondaryNeighbSalePrice.areaType : null;

        let medSalePrice = 'N/A';

        if ((secondarySalePrice !== null) && (secondarySalePriceAreaType !== null)) {
            let primaryVal = (primarySalePrice * .6);
            let secondaryVal = (secondarySalePrice * .4);
            medSalePrice = Math.ceil(primaryVal + secondaryVal);
        } else {
            medSalePrice = Math.ceil(primarySalePrice);
        }

        const medianSalePrice = medSalePrice;
        const medianRentPrice = medRent;
        

        // RENT VS OWN GRAPH DATA
        const renterDwellings = getRenterDwellings.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });
        const ownerDwellings = getOwnerDwellings.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });

        const thisRentOwnData = {
            rentOwnData: {
                labels: ['Renters', 'Owners'],
                datasets: [
                    {
                        backgroundColor: ['rgb(52, 181, 122)', 'rgb(187, 236, 205)'],
                        data: [renterDwellings, ownerDwellings]
                    }
                ],
            }
        };

        // POPULATION DATA

        const currentPop = getPopulation.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }); 
    
        // AGE GRAPH DATA

        const elevenAndUnder = elevenAndUnderArr.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const twelveToSeventeen = twelveToSeventeenArr.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const eighteenTo24 = eighteenTo24Arr.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const twentyfiveTo34 = twentyfiveTo34Arr.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const thirtyfiveTo44 = thirtyfiveTo44Arr.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const fortyfiveTo54 = fortyfiveTo54Arr.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const fiftyfiveTo64 = fiftyfiveTo64Arr.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const sixtyfiveToSeventyFour = sixtyfiveToSeventyFourArr.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const seventyFiveAndUp = seventyFiveAndUpArr.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);


        const thisAgeData = {
            ageData: {
                labels: [['0-11'], ['12-17'], ['18-24'], ['25-34'], ['35-44'], ['45-54'], ['55-64'], ['65-74'], ['75+']],
                datasets: [
                    {
                        label: 'Number of People Living in Area',
                        backgroundColor: [
                            'rgb(42, 207, 219)',
                            'rgb(255, 105, 180)',
                            'rgb(255, 105, 180)',
                            'rgb(52, 181, 122)',
                            'rgb(52, 181, 122)',
                            'rgb(219, 136, 42)',
                            'rgb(0, 0, 128)',
                            'rgb(0, 0, 128)',
                            'rgb(42, 207, 219)',
                        ],
                        data: [elevenAndUnder, twelveToSeventeen, eighteenTo24, twentyfiveTo34, thirtyfiveTo44, fortyfiveTo54, fiftyfiveTo64, sixtyfiveToSeventyFour, seventyFiveAndUp]
                    },
                ]
            }
        };


        // POP CHANGES

        const popChange2010 = popChanges.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });
        const pop5YearForecastTotal = popForecasts.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });
        
        const popChange = parseFloat(popChange2010).toFixed(0);
        const thisCurrentPop = parseFloat(currentPop);
        const fiveYearPop = parseFloat(pop5YearForecastTotal);
        const popForecast5 = ((fiveYearPop - thisCurrentPop)/fiveYearPop * 100);



        // FAMILY GRAPH DATA

        const familyHouseholds = getFamilyHouseholds.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });
        const nonFamilyHouseholds = getNonFamilyHouseholds.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });

        const thisFamilyData = {
            familyData: {
                labels: ['Family Households', 'Non-Family Households'],
                datasets: [
                    {
                        label: 'Number of Households',
                        backgroundColor: [
                            'rgb(187, 236, 205)',
                            'rgb(52, 181, 122)'
                        ],
                        data: [familyHouseholds, nonFamilyHouseholds]
                    },
                ]
            }
        };


        // HOUSEHOLDS WITH CHILDREN 

        const householdsWithChildren = getHouseholdsWithChildren.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        }).toFixed(0);
        const { filterables: { childrenHousesGauge: thisHousesWithChildrenGauge } = {}} = this.props;


        // MOST COMMON PEOPLE 

        // MOST COMMON PEOPLE 
        const daytimePop = getDayPop.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });
        const employeePop = getWorkersPop.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });
        const thisStudentPop = getStudentsPop.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });

        const studentPop = thisListing && thisListing.filterTags && thisListing.filterTags.includes('students') ? thisStudentPop : 0;

        const demoFilterTags = [];

        if (thisListing.filterTags) {
            for (let i = 0; i < thisListing.filterTags.length; i ++) {
                
                if (thisListing.filterTags[i].includes('localResidents')) {
                    demoFilterTags.push({tagName: 'Mostly Residential Area', tagDescription: 'This is a largely residential area.'});
                } else if (thisListing.filterTags[i].includes('officeWorkers')) {
                    demoFilterTags.push({tagName: 'Office Workers', tagDescription: 'A significant amount of people work in this area.'});
                } else if (thisListing.filterTags[i].includes('nearAttraction')) {
                    demoFilterTags.push({tagName: 'Tourist Area', tagDescription: 'This space is within a 250 meter radius (~5 min walk) of a major attraction, which can generate traffic and tourists in the area.'});
                } else if (thisListing.filterTags[i].includes('students')) {
                    demoFilterTags.push({tagName: 'Students', tagDescription: 'There is a relatively high concentration of students in the area, compared to most parts of the city.'});
                } else if (thisListing.filterTags[i].includes('highIncomeArea')) {
                    demoFilterTags.push({tagName: 'High Income Area', tagDescription: 'The average income levels and/or volume of people making higher incomes are higher here than most areas in the city.'});
                } else if (thisListing.filterTags[i].includes('affordableRE')) {
                    demoFilterTags.push({tagName: 'Affordable Living', tagDescription: 'Residential real estate prices in this area are typically more affordable than other neighborhoods in the city.'});
                } else if (thisListing.filterTags[i].includes('expensiveRE')) {
                    demoFilterTags.push({tagName: 'Expensive Living', tagDescription: 'Residential real estate prices in this area are typically more expensive than other neighborhoods in the city.'});
                } else if (thisListing.filterTags[i].includes('genZ')) {
                    demoFilterTags.push({tagName: 'Gen Z', tagDescription: 'There is a Gen Z population in this area.'});
                } else if (thisListing.filterTags[i].includes('millennialsTo34')) {
                    demoFilterTags.push({tagName: 'Young Millennials', tagDescription: 'There are young Millennials between 25-34 living here.'});
                } else if (thisListing.filterTags[i].includes('millennialsTo44')) {
                    demoFilterTags.push({tagName: 'Older Millennials', tagDescription: 'You can find older Millennials between 35-44 in this neighborhood.'});
                } else if (thisListing.filterTags[i].includes('genX')) {
                    demoFilterTags.push({tagName: 'Gen X', tagDescription: 'You can find more Gen X living here than many other neighborhoods.'});
                } else if (thisListing.filterTags[i].includes('boomers')) {
                    demoFilterTags.push({tagName: 'Boomers', tagDescription: 'There is a sizable population of Baby Boomers living here.'});
                } else if (thisListing.filterTags[i].includes('areasWithChildren')) {
                    demoFilterTags.push({tagName: 'Families with Children', tagDescription: 'You can find more families with children living here than many other neighborhoods.'});
                }
            }
        };

        const commaNumber = (inc) => {
            let newNum = Number(inc);
            let numString = newNum.toLocaleString("en-US");
            return numString;
        }

        const FilterTagTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} />
            ))(({ theme }) => ({
                [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: 'snow',
                color: 'black',
                fontWeight: 'bold',
                maxWidth: 300,
                fontSize: theme.typography.pxToRem(14),
                padding: 25,
                border: '2px solid rgb(52, 181, 122)',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                },
        }));
        
        return (
            <Container>
                <div className='demographicsTagsArea'>
                    <div style={{ height: '100%', alignItems: 'center', display: 'grid', gridTemplateColumns: '2fr 1fr', gap: 5 }}>
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <h5>Demographics Score:</h5>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <h3 style={{ textShadow: '.5px .5px 1px rgb(21, 134, 84)', border: '2px solid green', borderRadius: '50%', width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', padding: 25 }}>{scores.demographicsScore}</h3>
                        </div>
                    </div>
                    <div style={{ height: '100%', display: 'grid', gridTemplateColumns: '1fr 5fr', gap: 5 }}>
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <h5>Tags:</h5>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', overflowX: 'scroll' }}>
                            {demoFilterTags.length ? demoFilterTags.map(tag => (
                                <span>
                                    <FilterTagTooltip arrow
                                        title={
                                            <>
                                                <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>{tag.tagDescription}</p>
                                            </>
                                        }
                                    >
                                        <Fab
                                            variant="extended"
                                            size="small"
                                            style={{ backgroundColor: 'rgb(52, 181, 122)', fontSize: 12, color: 'whitesmoke', marginLeft: 10, outline: 'none', padding: 15, lineHeight: 1.25 }}
                                            >
                                            {tag.tagName}
                                        </Fab>
                                    </FilterTagTooltip>
                                </span>
                            )) : (<p className='summaryDescription'>None</p>)}
                        </div>
                    </div>
                </div>
                <hr />
                <div>
                    <Row style={{ height: 150, width: 1100, margin: 'auto' }}>
                        <Col sm={4}>
                            <Card className='keyPointsData'>
                                <CardContent>
                                    <div className='keyPointsContent'>
                                        <BsPeopleFill id='popIconHighlight' />
                                        <div id='topPeopleTitle'>Common People In The Area</div>
                                        <CommonPeople scores={scores} studentPop={studentPop} currentPop={currentPop} daytimePop={daytimePop} employeePop={employeePop} />
                                    </div>
                                </CardContent>
                            </Card>
                        </Col>
                        <Col sm={4}>
                            <Card className='keyPointsData'>
                                <CardContent>
                                    <div className='keyPointsContent'>                
                                        <GiHouse id='resIconHighlight' />
                                        <div id='resHighlightTitle'>Residential Price Gauge</div>
                                        <ResPriceGauge scores={scores} />
                                    </div>
                                </CardContent>
                            </Card>
                        </Col>
                        <Col sm={4}>
                            <Card className='keyPointsData'>
                                <CardContent>
                                    <div className='keyPointsContent'>
                                        <FaRegMoneyBillAlt id='incomeIconHighlight' />
                                        <div id='incomeHighlightTitle'>Local Income Gauge</div>
                                        <div id='averageIncomeTitle'>Individual Income</div>
                                        <div className='individualIncomeHighlight'>
                                            <span id='individualIncomeHighlightValue'>{`$${commaNumber(averageIndividualIncome)}`}</span>
                                            <span id='indAvg'>/ Avg</span>
                                        </div>
                                        <div id='householdIncomeTitle'>Household Income</div>
                                        <div className='householdIncomeHighlight'>
                                            <span id='householdIncomeHighlightValue'>{`$${commaNumber(averageHouseholdIncome)}`}</span>
                                            <span id='houseAvg'>/ Avg</span>
                                        </div>
                                        <IncomeGauge scores={scores} />
                                    </div>
                                </CardContent>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 25 }}>
                        <div className='demoContent'>
                            <div>
                                <Card className='incomeDataSection'>
                                    <CardContent>
                                        <div className='incomeTitle'>Income</div>
                                        <FaRegMoneyBillAlt className='incomeIcon' />
                                        <div className='trendCategoryTitle'>{`Avg. Household Income Since ${previousCommDataYear}:`}</div>
                                        <div className='incomeChange2010'>
                                            <div className='since2010Title'>Income</div>
                                            <div className='incomeChangeValue'>{`${incomeChangeSince2021}%`}</div>
                                            <div className='incomeArrows'>
                                                {incomeChangeSince2021 > 0 ? greenArrow : incomeChangeSince2021 < 0 ? redArrow : '' }
                                            </div>
                                        </div>
                                        <div className='fiveForecast'>
                                            <div className='fiveYearForecastTitle'>Number of Households</div>
                                            <div className='incomeChangeValue'>{`${hhdChangeSince2021}%`}</div>
                                            <div className='incomeArrows'>
                                                {hhdChangeSince2021 > 0 ? greenArrow : hhdChangeSince2021 < 0 ? redArrow : '' }
                                            </div>
                                        </div>
                                        <hr style={{ marginTop: 100 }} />
                                        <div className='incomeGraph'>
                                            <IncomeGraph thisIncomeData={thisIncomeData} />
                                        </div>
                                    </CardContent>
                                </Card>
                                <Card className='resDataSection'>
                                    <CardContent>
                                        <div className='resTitle'>Residential Market</div>
                                        <GiHouse className='resIcon' />
                                        <div className='medSalePrice' style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', paddingLeft: 25 }}>
                                            <div>
                                                <div className='resPricesMedianSales'>Median Sale Price</div>
                                                <div id='medianSalePrice'>{`$${commaNumber(medianSalePrice)}`}</div>
                                            </div>
                                            <div style={{ paddingLeft: 50 }}>
                                                <div className='resPricesMedianSales'>Median Rent</div>
                                                <div id='medianSalePrice'>{`$${commaNumber(medianRentPrice)}`}</div>
                                            </div>
                                        </div>
                                        <hr style={{ marginTop: 100 }}/>
                                        <div className='rentOwn'>
                                            <RentOwnGraph thisRentOwnData={thisRentOwnData} />
                                        </div>
                                    </CardContent>
                                </Card>
                            </div>
                            <div>
                                <Card className='populationDataSection'>
                                    <CardContent>
                                    <div className='populationTitle'>Population</div>
                                    <BsPeopleFill className='populationIcon' />
                                    <div className='trendCategoryTitle' id='popTrendTitle'>Population in Area:</div>
                                    <div id='popChange2010'>
                                        <div className='since2010Title'>Since 2010</div>
                                        <div className='popChangeValue'>{`${popChange}%`}</div>
                                        <div className='popArrows'>
                                            {popChange > 0 ? greenArrow : popChange < 0 ? redArrow : '' }
                                        </div>
                                    </div>
                                    <div id='popFiveForecast'>
                                        <div className='fiveYearForecastTitle'>5 Year Forecast</div>
                                        <div className='popChangeValue'>{`${popForecast5.toFixed(0)}%`}</div>
                                        <div className='popArrows'>
                                            {popForecast5 > 0 ? greenArrow : popForecast5 < 0 ? redArrow : '' }
                                        </div>
                                    </div>
                                    <hr style={{ marginTop: 100 }} />
                                    <div style={{ width: '100%', height: 325, position: 'relative' }}>
                                        <div style={{ height: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div className='resPricesMedianSales' style={{ paddingTop: 50 }}>Age Breakdown</div>
                                        </div>
                                        <div style={{ height: '95%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 25 }}>
                                            <div style={{ position: 'relative', width: '100%', display: 'grid', gridTemplateColumns: '2fr 3fr', gap: 10 }}>
                                                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                                    <div style={{ width: '100%' }}>
                                                        <div style={{ height: 50, backgroundColor: 'rgb(255, 105, 180, .1)', display: 'flex', justifyContent: 'right', alignItems: 'center', fontSize: 12, width: '100%', paddingRight: 15, fontWeight: 'bolder' }}>Gen Z</div>
                                                        <div style={{ height: 50, backgroundColor: 'rgb(52, 181, 122, .1)', display: 'flex', justifyContent: 'right', alignItems: 'center', fontSize: 12, width: '100%', paddingRight: 15, fontWeight: 'bolder' }}>Millennials</div>
                                                        <div style={{ height: 30, backgroundColor: 'rgb(219, 136, 42, .1)', display: 'flex', justifyContent: 'right', alignItems: 'center', fontSize: 12, width: '100%', paddingRight: 15, fontWeight: 'bolder' }}>Gen X</div>
                                                        <div style={{ height: 50, backgroundColor: 'rgb(0, 0, 128, .1)', display: 'flex', justifyContent: 'right', alignItems: 'center', fontSize: 12, width: '100%', paddingRight: 15, fontWeight: 'bolder' }}>Baby Boomers</div>
                                                    </div>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                                    <div>
                                                        <AgeGraph width={500} height={400} thisCurrentPop={thisCurrentPop} populationReport thisAgeData={thisAgeData} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className='familyStatusSection'>
                                        <div className='familyGraph'>
                                            <FamilyStatusGraph thisFamilyData={thisFamilyData} />
                                        </div>
                                        <div className='childrenHouseTitle'>Number of Households WITH Children</div>
                                        <ImManWoman className='famChildren' />
                                        <FaChild className='child' />
                                        <MdChildFriendly className='stroller' />
                                        <div className='childHouseCountNumber'>{householdsWithChildren}</div>
                                        <ChildrenHousesGauge filterables={filterables} />
                                    </div>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </Row>
                </div>
            </Container>
        );
    }
}

export default Demographics;
