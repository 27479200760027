import { USER_LOADING, USER_LOADED, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS, REGISTER_SUCCESS, REGISTER_FAIL, USER_UPDATE_REQUEST, USER_UPDATE_SUCCESS, USER_UPDATE_FAIL } from '../actions/types';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: null,
    paymentInfoNeeded: null,
    isLoading: false,
    user: null,
    error: null
}

export const userUpdateReducer = ( state = initialState, action ) => {
    switch (action.type) {
        case USER_UPDATE_REQUEST:
            return { ...state, isLoading: true };
        case USER_UPDATE_SUCCESS:
            return { ...state, isLoading: false, success: true, paymentInfoNeeded: action.payload.paymentInfoNeeded };
        case USER_UPDATE_FAIL:
            return { ...state, isLoading: false, error: action.payload };
        default: 
            return { ...state }
    }
}

export default function(state = initialState, action) {
    switch(action.type) {
        case USER_LOADING: {
            return {
                ...state,
                isLoading: true
            }
        }
        case USER_LOADED: {
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: action.payload
            }
        }
        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS: {
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                paymentInfoNeeded: action.payload.paymentInfoNeeded,
                isLoading: false,
            }
        }
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT_SUCCESS:
        case REGISTER_FAIL:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                user: null,
                isAuthenticated: false,
                isLoading: false
            }
        default:
            return state;
    }
}

