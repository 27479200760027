import React, { Component } from 'react';
import { Card, CardActions, CardContent, Button, Fab } from '@material-ui/core';
import { Col, Row } from 'reactstrap';

class PropertyFilters extends Component {
    
    state = { 
        propertyFilters: this.props.values.propertyFilters,
    }
    
    continue = e => {
        this.props.nextStep();
        this.props.setFilters('propertyFilters', this.state.propertyFilters);
    }

    back = e => {
        this.props.prevStep();
    }

    render() { 


        return ( 
            <div style={{ backgroundColor: 'whitesmoke', height: '100vh', paddingTop: 50 }}>
                <Card className='customCardBackgroundColor' style={{ width: '75vw', height: '75vh', margin: 'auto', overflowY: 'scroll' }}>
                    <CardContent>
                        <h2 style={{ textAlign: 'center', marginTop: 25, marginBottom: 60 }}>Property Filters</h2>
                        <Row style={{ width: 850, height: 115, marginLeft: 'auto', marginRight: 'auto', marginTop: 100 }}>
                            <Col sm={4}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <Fab
                                            variant="extended"
                                            size="large"
                                            onClick={(e) => {
                                                const value = 'cornerLocation';
                                                const checked = this.state.propertyFilters.includes('cornerLocation');
                                                let filterTag = checked ? this.state.propertyFilters.filter((tags) => tags !== value) : [...this.state.propertyFilters, value]
                                                this.setState({ propertyFilters: filterTag });
                                            }}
                                            style={ this.state.propertyFilters.includes('cornerLocation') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 200, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 200, fontSize: 12, outline: 'none' }}
                                        >
                                        Corner Location
                                        </Fab>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={4}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <Fab
                                            variant="extended"
                                            size="large"
                                            category='bigFrontage'
                                            checked={this.state.propertyFilters.includes('bigFrontage')}
                                            onClick={(e) => {
                                                const value = 'bigFrontage';
                                                const checked = this.state.propertyFilters.includes('bigFrontage');
                                                let filterTag = checked ? this.state.propertyFilters.filter((tags) => tags !== value) : [...this.state.propertyFilters, value]
                                                this.setState({ propertyFilters: filterTag });
                                            }}
                                            style={ this.state.propertyFilters.includes('bigFrontage') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 200, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 200, fontSize: 12, outline: 'none' }}
                                        >
                                        Big Frontage
                                        </Fab>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={4}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <Fab
                                            variant="extended"
                                            size="large"
                                            onClick={(e) => {
                                                const value = 'divisible';
                                                const checked = this.state.propertyFilters.includes('divisible');
                                                let filterTag = checked ? this.state.propertyFilters.filter((tags) => tags !== value) : [...this.state.propertyFilters, value]
                                                this.setState({ propertyFilters: filterTag });
                                            }}
                                            style={ this.state.propertyFilters.includes('divisible') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 200, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 200, fontSize: 12, outline: 'none' }}
                                        >
                                        Divisible
                                        </Fab>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{ width: '80%', height: 100, marginLeft: 'auto', marginRight: 'auto' }}>
                            <Col sm={4}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <Fab
                                            variant="extended"
                                            size="large"
                                            category='multiLevel'
                                            checked={this.state.propertyFilters.includes('multiLevel')}
                                            onClick={(e) => {
                                                const value = 'multiLevel';
                                                const checked = this.state.propertyFilters.includes('multiLevel');
                                                let filterTag = checked ? this.state.propertyFilters.filter((tags) => tags !== value) : [...this.state.propertyFilters, value]
                                                this.setState({ propertyFilters: filterTag });
                                            }}
                                            style={ this.state.propertyFilters.includes('multiLevel') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 200, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 200, fontSize: 12, outline: 'none' }}
                                        >
                                        Multi Level
                                        </Fab>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={4}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <Fab
                                            variant="extended"
                                            size="large"
                                            onClick={(e) => {
                                                const value = 'newConstruction';
                                                const checked = this.state.propertyFilters.includes('newConstruction');
                                                let filterTag = checked ? this.state.propertyFilters.filter((tags) => tags !== value) : [...this.state.propertyFilters, value]
                                                this.setState({ propertyFilters: filterTag });
                                            }}
                                            style={ this.state.propertyFilters.includes('newConstruction') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 200, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 200, fontSize: 12, outline: 'none' }}
                                        >
                                        New Construction
                                        </Fab>
                                    </div>
                                </Row>
                            </Col>
                            <Col sm={4}>
                                <Row style={{ height: 75, justifyContent: 'center' }}>
                                    <div>
                                        <Fab
                                            variant="extended"
                                            size="large"
                                            category='outdoorSpace'
                                            checked={this.state.propertyFilters.includes('outdoorSpace')}
                                            onClick={(e) => {
                                                const value = 'outdoorSpace';
                                                const checked = this.state.propertyFilters.includes('outdoorSpace');
                                                let filterTag = checked ? this.state.propertyFilters.filter((tags) => tags !== value) : [...this.state.propertyFilters, value]
                                                this.setState({ propertyFilters: filterTag });
                                            }}
                                            style={ this.state.propertyFilters.includes('outdoorSpace') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 200, fontSize: 12, outline: 'none' } : { backgroundColor: 'white', width: 200, fontSize: 12, outline: 'none' }}
                                        >
                                        Outdoor Space
                                        </Fab>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                        <CardActions>
                            <Button style={{ marginTop: 50, marginRight: 20, marginLeft: 'auto' }} variant="contained" color="default" size="medium" onClick={() => this.back()}>Back</Button>
                            <Button style={{ marginTop: 50, marginRight: 'auto', marginLeft: 30, backgroundColor: '#007FFF', color: 'white' }} variant="contained" size="medium" onClick={() => this.continue()}>Continue</Button>
                        </CardActions>
                    </CardContent>
                </Card>
            </div>
        );
    }
}
 
export default PropertyFilters;