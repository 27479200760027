import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

class RentOwnGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            thisListing: props.thisListing,
            rentOwnData: props.thisRentOwnData,
        }
    }

    static defaultProps = {
        displayTitle: true,
    }
    
    render() {
        
        const { thisRentOwnData: { rentOwnData: rentOwnGraphData } = {}} = this.props;

        return (
            <div className="rentOwn-graph">
                <Pie
                data={rentOwnGraphData}
                height={175}
                options={{
                    title: {
                        display: this.props.displayTitle,
                        text: 'Rent vs Own',
                        fontSize: 14,
                        color: 'black'
                    },
                    plugins: {
                        datalabels: {
                           display: false,
                        },
                        legend: {
                            display: true,
                            position: 'left',
                        }
                    },
                    scales: {
                        x: {
                            grid: {
                                display: false
                            },
                            ticks: {
                                display: false
                            }
                        },
                    },
                }}
                />
            </div>
        );
    }
}

export default RentOwnGraph;