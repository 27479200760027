import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { Button } from '@material-ui/core';
import mapScreenshot from '../../Assets/productFeatures_mapHighlight.png';
import laptop from '../../Assets/productFeatures_laptop.png';
import './description.css';

class UXDescription extends Component {
    

    render() { 
        return ( 
            <Container style={{ backgroundColor: 'whitesmoke', height: 750, display: 'flex', justifyContent: 'center', alignItems: 'center' }} fluid>
                <div style={{ backgroundColor: 'white', width: '76%', height: 600, margin: 'auto', overflow: 'hidden', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                    <div style={{ height: '100%', display: 'grid', gridTemplateColumns: '1fr 2fr' }}>
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <div className='bottomGetStarted'>
                                <Row>
                                    <h1>Streamline Online Property Searching</h1>
                                </Row>
                                <Row style={{ marginTop: 20 }}>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 18, width: '90%' }}>Narrow your property search and expedite the site-selection process by getting a better understanding of each available space and the area it's in. Easily eliminate unviable options and discover potential matches all without requiring you to physically visit any.</div>
                                </Row>
                                <Row style={{ marginTop: 30 }}>
                                    <Link to="/listings" style={{ textDecoration: 'none' }}>
                                        <Button size="medium" style={{ backgroundColor: 'rgb(35, 126, 85)', color: 'white', width: 200 }}>Get Started</Button>
                                    </Link>
                                </Row>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div id="mapCard">
                                <img id="laptop" src={laptop} alt="laptop mockup" />
                                <img id="mapScreenshot" src={mapScreenshot} alt="mapScreenshot" />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
         );
    }
}
 
export default UXDescription;