import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { Row, Container } from 'reactstrap';
import fourcastLogo from '../../Assets/fourcast_alt_logo_green.png';
import './welcome.css';

class Welcome extends Component {
    render() { 
        return (
            <Container className='landingPageTop' fluid>
                <div className='welcomeArea'>
                    <div style={{ width: 900, height: 450 }}>
                        <Row style={{ marginTop: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img className='logo' alt='welcomePageFourcastLogo' src={fourcastLogo} id='welcomeFourcastLogo' />
                        </Row>
                        <Row style={{ marginTop: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div id="welcomeMotto"><span style={{ fontSize: 40, wordSpacing: 3 }}>Find Retail Space </span><span style={{ fontSize: 44, fontStyle: 'italic' }}>Easier. Faster. Smarter.</span></div>
                        </Row>
                        <Row style={{ marginTop: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Link to="/listings" style={{ textDecoration: 'none' }}>
                                <Button id="explorePropertiesBtn">
                                    Get Started
                                </Button>
                            </Link>
                        </Row>
                    </div>
                </div>
            </Container>
        );
    }
}

export default Welcome;