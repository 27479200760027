import React, { Component } from 'react';
import { Paper } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { FaCloudMoon, FaCloudSun } from 'react-icons/fa';
import 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import fourcastLogo from '../../Assets/fourcast_alt_logo_green.png';
import '../listingDetailsLayouts/traffic/traffic.css';
import '../listingDetailsLayouts/summary/summary.css';



class BusyTimesReport extends Component {
    
    
    render() { 
        const { thisListing, thisListing: { yelpData: { businesses } = {}} = {}} = this.props;
        const { thisListing: { listingAddress: getListingAddress } = {}} = this.props;

        //const { walkScoreData, yelpData, thisListing, scores, filterables, thisListing: { listingAddress: getListingAddress } = {}} = this.props;


        const { filterables: { busyHours: { openingTimes } = {}} = {}} = this.props;
        const { filterables: { busyHours: { closingTimes } = {}} = {}} = this.props;
    

        const { filterables: { busyHours: { openingTimes: { openFiveAm: thisOpenFiveAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { openingTimes: { openFiveThirtyAm: thisOpenFiveThirtyAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { openingTimes: { openSixAm: thisOpenSixAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { openingTimes: { openSixThirtyAm: thisOpenSixThirtyAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { openingTimes: { openSevenAm: thisOpenSevenAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { openingTimes: { openSevenThirtyAm: thisOpenSevenThirtyAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { openingTimes: { openEightAm: thisOpenEightAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { openingTimes: { openEightThirtyAm: thisOpenEightThirtyAm } = {}} = {}} = {}} = this.props;

        const { filterables: { busyHours: { closingTimes: { closeMidnight: thisCloseMidnight } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { closingTimes: { closeMidnightThirty: thisCloseMidnightThirty } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { closingTimes: { closeOneAm: thisCloseOneAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { closingTimes: { closeOneThirtyAm: thisCloseOneThirtyAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { closingTimes: { closeTwoAm: thisCloseTwoAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { closingTimes: { closeTwoThirtyAm: thisCloseTwoThirtyAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { closingTimes: { closeThreeAm: thisCloseThreeAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { closingTimes: { closeThreeThirtyAm: thisCloseThreeThirtyAm } = {}} = {}} = {}} = this.props;
        const { filterables: { busyHours: { closingTimes: { closeFourAm: thisCloseFourAm } = {}} = {}} = {}} = this.props;

        
        let earlyMorningCount = ((thisOpenFiveAm + thisOpenFiveThirtyAm + thisOpenSixAm + thisOpenSixThirtyAm + thisOpenSevenAm + thisOpenSevenThirtyAm + thisOpenEightAm + thisOpenEightThirtyAm) * .25);
        
        if (earlyMorningCount) {
            
            let dayTrafficGaugeScore = 0;

            // Calculate Daytime Traffic Gauge Score !!!!!!!!!!!!!!!!!!!!!

            // These figures were taken from the Traffic Volatility Function in "tasks"
            
            const globalHighestDayPopChange = 4818;
            const dayPopChangeMedian = 60;
            const globalLowestDayPopChange = 3;
            
            // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            
            const secondaryNeighb = thisListing && thisListing.attomData && thisListing.attomData.geoIdV4 && thisListing.attomData.geoIdV4.filter(neighb => ((neighb.type === 'N2') || (neighb.type === 'N3')));
            const thisDayPop = parseFloat(secondaryNeighb && secondaryNeighb[0].commV4Data && secondaryNeighb[0].commV4Data.population_Daytime);
            const thisPopCy = parseFloat(secondaryNeighb && secondaryNeighb[0].commV4Data && secondaryNeighb[0].commV4Data.population);
            const dayPopChange = ((thisPopCy > 0) && (thisDayPop > 0)) ? (thisPopCy < thisDayPop) ? (((thisDayPop - thisPopCy)/thisPopCy) * 100) : (((thisPopCy - thisDayPop)/thisPopCy) * -100) : 0;

            if (dayPopChange > dayPopChangeMedian) {
                dayTrafficGaugeScore = earlyMorningCount + Math.ceil((((dayPopChange - dayPopChangeMedian) / (globalHighestDayPopChange - dayPopChangeMedian)) * 50) + 50);
            } else if (dayPopChange < dayPopChangeMedian) {
                dayTrafficGaugeScore = earlyMorningCount + Math.ceil((((dayPopChange - dayPopChangeMedian) / (globalLowestDayPopChange - dayPopChangeMedian)) * -50) + 50);
            } else {
                dayTrafficGaugeScore = earlyMorningCount + 50;
            }

            if (dayTrafficGaugeScore > 100) {
                dayTrafficGaugeScore = 100;
            } else if (dayTrafficGaugeScore < 5) {
                dayTrafficGaugeScore = 5;
            }

            const setDayTrafficGauge = (score) => {
                setTimeout(() => {
                    const dayTrafficGauge = document.querySelector('.dayTrafficGaugePointerReport');
                    let dayRotation = score;                
                    dayTrafficGauge && dayTrafficGauge.style.setProperty('--bottomDay', dayRotation);
                }, 100, { once: true });
            }
    
            dayTrafficGaugeScore && setDayTrafficGauge(dayTrafficGaugeScore);

        }



        // Calculate Nightlife Gauge Score !!!!!!!!!!!!!!!!!!!!!!!!!!!
        
        const nightLifeCount = [thisCloseMidnight, thisCloseMidnightThirty, thisCloseOneAm, thisCloseOneThirtyAm, thisCloseTwoAm, thisCloseTwoThirtyAm, thisCloseThreeAm, thisCloseThreeThirtyAm, thisCloseFourAm];
        let nightlifeBusinesses = [];

        if (nightLifeCount) {
            
            let nightlifeGaugeScore = 0;

            const nightlifeCategories = ["bars", "adultentertainment", "barcrawl", "beergardens", "clubcrawl", "comedyclubs", "countrydancehalls", "danceclubs", "dancerestaurants", "fasil", "jazzandblues", "karaoke", "musicvenues", "pianobars", "poolhalls"];

            // pushes businesses with a nightlife "category" aka bars, clubs, lounge, etc.
            for (let biz of businesses) {
                for (let category of biz.categories) {
                    if (nightlifeCategories.some(nightCategory => category.alias === nightCategory)) {
                        nightlifeBusinesses.push(biz);
                    };
                }
            }

            nightlifeBusinesses = [...new Set([...nightlifeBusinesses])];
            
            // for businesses open til midnight or later, increase the points given the later they are open til
            let multiplier = .75;
            for (let i = 0; i < nightLifeCount.length; i++) {
                nightlifeGaugeScore += nightLifeCount[i] * multiplier;
                multiplier += .25;
            }
            
            // award .5 points for every 100 reviews of nightlife businesses
            for (let nightlifeBiz of nightlifeBusinesses) {
                nightlifeGaugeScore += ((nightlifeBiz.review_count / 100) * .5);
            }

            if (nightlifeGaugeScore > 100) {
                nightlifeGaugeScore = 100;
            } else if (nightlifeGaugeScore < 5) {
                nightlifeGaugeScore = 5;
            }

            const setNightlifeGauge = (score) => {
                setTimeout(() => {
                    const nightlifeGauge = document.querySelector('.nightlifeGaugePointerReport');
                    let nightRotation = score;                
                    nightlifeGauge && nightlifeGauge.style.setProperty('--bottomNight', nightRotation);
                }, 100, { once: true });
            }
            
            nightLifeCount && setNightlifeGauge(nightlifeGaugeScore);
        }

        let thisAddressArray = [];
        thisAddressArray.push(getListingAddress);
        let addressString = thisAddressArray.toString();
        let thisListingAddress = addressString.split(',');
        

        return (
            <Paper id="trafficReportPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 15, position: 'relative' }}>
                <div style={{ width: '100%', height: '100%' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '6fr 1fr' }}>
                        <div className='reportAddress'>
                            <h2>{thisListingAddress[0]}</h2>
                        </div>
                        <div className='reportFourcastLogoArea'>
                            <img className='reportFourcastLogo' src={fourcastLogo} alt='Fourcast Reports Logo' />
                        </div>
                    </div>
                    <hr />
                    <div style={{ marginTop: 15, height: 250 }}>
                        <div className="popularTimesReport" id="busyTimesOfDayReport" style={{ position: 'relative', width: '100%' }}>
                            <div id='busyHoursDataReport' style={{ width: '100%', height: 225 }}>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'start', paddingBottom: 25 }}>
                                    <h5 style={{ margin: '15px 0px 10px 20px', paddingBottom: 10 }}>Daytime Traffic & Nightlife:</h5>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Row style={{ width: 420, height: 180 }}>
                                        <Col sm={4}>
                                            <div id='daytimeTrafficTitleReport'>Daytime Traffic</div>
                                            <div id='nightlifeTitleReport'>Nightlife</div>
                                            <FaCloudMoon className='moonReport' />
                                            <FaCloudSun className='sunReport' />
                                        </Col>
                                        <Col sm={8}>
                                            <div className='dayTrafficGaugeReport' id='dayGaugeReport'></div>
                                            <div className='dayTrafficGaugePointerReport' id='dayGaugePointerReport'></div>
                                            <div className='dayTrafficGaugeHighReport'>High</div>
                                            <div className='dayTrafficGaugeLowReport'>Low</div>
                                            <div className='nightlifeGaugeHighReport'>High</div>
                                            <div className='nightlifeGaugeReport'></div>
                                            <div className='nightlifeGaugePointerReport'></div>
                                            <div className='nightlifeGaugeLowReport'>Low</div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div style={{ marginTop: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '85%', height: 350 }}>
                            <Bar
                            data={{
                                labels: [
                                    '5 AM',
                                    '5:30 AM', 
                                    '6 AM',
                                    '6:30 AM',
                                    '7 AM',
                                    '7:30 AM',
                                    '8 AM',
                                    '8:30 AM', 
                                    '9 AM',
                                    '9:30 AM', 
                                    '10 AM',
                                    '10:30 AM', 
                                    '11 AM',
                                    '11:30 AM',
                                    '12 PM',
                                    '12:30 PM',
                                    '1 PM',
                                    '1:30 PM',
                                    '2 PM',
                                    '2:30 PM',
                                    '3 PM',
                                    '3:30 PM',
                                    '4 PM',
                                    '4:30 PM',
                                    '5 PM',
                                    '5:30 PM',
                                    '6 PM',
                                    '6:30 PM',
                                    '7 PM',
                                    '7:30 PM',
                                    '8 PM',
                                    '8:30 PM',
                                    '9 PM',
                                    '9:30 PM',
                                    '10 PM',
                                    '10:30 PM',
                                    '11 PM',
                                    '11:30 PM',
                                    '12 AM',
                                    '12:30 AM',
                                    '1 AM',
                                    '1:30 AM',
                                    '2 AM',
                                    '2:30 AM',
                                    '3 AM',
                                    '3:30 AM',
                                    '4 AM',
                                ],
                                datasets: [
                                    {
                                        label: 'Opening Times of Businesses Nearby',
                                        data: [
                                            `${openingTimes.openFiveAm}`,
                                            `${openingTimes.openfiveThirtyAm}`,
                                            `${openingTimes.openSixAm}`,
                                            `${openingTimes.openSixThirtyAm}`,
                                            `${openingTimes.openSevenAm}`,
                                            `${openingTimes.openSevenThirtyAm}`,
                                            `${openingTimes.openEightAm}`,
                                            `${openingTimes.openEightThirtyAm}`,
                                            `${openingTimes.openNineAm}`,
                                            `${openingTimes.openNineThirtyAm}`,
                                            `${openingTimes.openTenAm}`,
                                            `${openingTimes.openTenThirtyAm}`,
                                            `${openingTimes.openElevenAm}`,
                                            `${openingTimes.openElevenThirtyAm}`,
                                            `${openingTimes.openNoon}`,
                                            `${openingTimes.openNoonThirtyPm}`,
                                            `${openingTimes.openOnePm}`,
                                            `${openingTimes.openOneThirtyPm}`,
                                            `${openingTimes.openTwoPm}`,
                                            `${openingTimes.openTwoThirtyPm}`,
                                            `${openingTimes.openThreePm}`,
                                            `${openingTimes.openThreeThirtyPm}`,
                                            `${openingTimes.openFourPm}`,
                                            `${openingTimes.openFourThirtyPm}`,
                                            `${openingTimes.openFivePm}`,
                                            `${openingTimes.openFiveThirtyPm}`,
                                            `${openingTimes.openSixPm}`,
                                            `${openingTimes.openSixThirtyPm}`,
                                            `${openingTimes.openSevenPm}`,
                                            `${openingTimes.openSevenThirtyPm}`,
                                            `${openingTimes.openEightPm}`,
                                            `${openingTimes.openEightThirtyPm}`,
                                            `${openingTimes.openNinePm}`,
                                            `${openingTimes.openNineThirtyPm}`,
                                            `${openingTimes.openTenPm}`,
                                            `${openingTimes.openTenThirtyPm}`,
                                            `${openingTimes.openElevenPm}`,
                                            `${openingTimes.openElevenThirtyPm}`,
                                            `${openingTimes.openMidnight}`,
                                            `${openingTimes.openMidnightThirty}`,
                                            `${openingTimes.openOneAm}`,
                                            `${openingTimes.openOneThirtyAm}`,
                                            `${openingTimes.openTwoAm}`,
                                            `${openingTimes.openTwoThirtyAm}`,
                                            `${openingTimes.openThreeAm}`,
                                            `${openingTimes.openThreeThirtyAm}`,
                                            `${openingTimes.openFourAm}`,
                                        ],
                                        backgroundColor: [
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                        ]
                                    },
                                    {
                                        label: 'Closing Times of Businesses Nearby',
                                        data: [
                                            `${closingTimes.closeFiveAm}`,
                                            `${closingTimes.closeFiveThirtyAm}`,
                                            `${closingTimes.closeSixAm}`,
                                            `${closingTimes.closeSixThirtyAm}`,
                                            `${closingTimes.closeSevenAm}`,
                                            `${closingTimes.closeSevenThirtyAm}`,
                                            `${closingTimes.closeEightAm}`,
                                            `${closingTimes.closeEightThirtyAm}`,
                                            `${closingTimes.closeNineAm}`,
                                            `${closingTimes.closeNineThirtyAm}`,
                                            `${closingTimes.closeTenAm}`,
                                            `${closingTimes.closeTenThirtyAm}`,
                                            `${closingTimes.closeElevenAm}`,
                                            `${closingTimes.closeElevenThirtyAm}`,
                                            `${closingTimes.closeNoon}`,
                                            `${closingTimes.closeNoonThirtyPm}`,
                                            `${closingTimes.closeOnePm}`,
                                            `${closingTimes.closeOneThirtyPm}`,
                                            `${closingTimes.closeTwoPm}`,
                                            `${closingTimes.closeTwoThirtyPm}`,
                                            `${closingTimes.closeThreePm}`,
                                            `${closingTimes.closeThreeThirtyPm}`,
                                            `${closingTimes.closeFourPm}`,
                                            `${closingTimes.closeFourThirtyPm}`,
                                            `${closingTimes.closeFivePm}`,
                                            `${closingTimes.closeFiveThirtyPm}`,
                                            `${closingTimes.closeSixPm}`,
                                            `${closingTimes.closeSixThirtyPm}`,
                                            `${closingTimes.closeSevenPm}`,
                                            `${closingTimes.closeSevenThirtyPm}`,
                                            `${closingTimes.closeEightPm}`,
                                            `${closingTimes.closeEightThirtyPm}`,
                                            `${closingTimes.closeNinePm}`,
                                            `${closingTimes.closeNineThirtyPm}`,
                                            `${closingTimes.closeTenPm}`,
                                            `${closingTimes.closeTenThirtyPm}`,
                                            `${closingTimes.closeElevenPm}`,
                                            `${closingTimes.closeElevenThirtyPm}`,
                                            `${closingTimes.closeMidnight}`,
                                            `${closingTimes.closeMidnightThirty}`,
                                            `${closingTimes.closeOneAm}`,
                                            `${closingTimes.closeOneThirtyAm}`,
                                            `${closingTimes.closeTwoAm}`,
                                            `${closingTimes.closeTwoThirtyAm}`,
                                            `${closingTimes.closeThreeAm}`,
                                            `${closingTimes.closeThreeThirtyAm}`,
                                            `${closingTimes.closeFourAm}`,
                                        ],
                                        backgroundColor: [
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                        ]
                                    }
                                ]
                            }}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Open / Closing Times',
                                    fontSize: 18,
                                    color: 'black'
                                },
                                plugins: {
                                    datalabels: {
                                    display: false,
                                    color: 'black'
                                    }
                                },
                                scales: {
                                    xAxes: [{
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                        gridLines: {
                                            display: false
                                        },
                                    }],
                                    yAxes: [{
                                        ticks: {
                                            display: false,
                                            
                                        },
                                        gridLines: {
                                            display: false
                                        },
                                    }],
                                },
                            }} 
                            />
                        </div>
                    </div>
                    <hr />
                    <div style={{ marginTop: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ heigth: 300, width: '75%' }}>
                            <Bar
                            data={{
                                labels: [
                                    'Sunday',
                                    'Monday',
                                    'Tuesday', 
                                    'Wednesday',
                                    'Thursday',
                                    'Friday',
                                    'Saturday',
                                ],
                                datasets: [
                                    {
                                        label: 'Volume of Businesses Open Nearby',
                                        data: [
                                            `${openingTimes.sunday}`,
                                            `${openingTimes.monday}`,
                                            `${openingTimes.tuesday}`,
                                            `${openingTimes.wednesday}`,
                                            `${openingTimes.thursday}`,
                                            `${openingTimes.friday}`,
                                            `${openingTimes.saturday}`,
                                        ],
                                        backgroundColor: [
                                            'rgba(21, 134, 84, 0.8)',
                                            'rgba(21, 134, 84, 0.8)',
                                            'rgba(21, 134, 84, 0.8)',
                                            'rgba(21, 134, 84, 0.8)',
                                            'rgba(21, 134, 84, 0.8)',
                                            'rgba(21, 134, 84, 0.8)',
                                            'rgba(21, 134, 84, 0.8)',
                                        ]
                                    },
                                ]
                            }}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Weekday / Weekend Traffic',
                                    fontSize: 18,
                                    color: 'black'
                                },
                                plugins: {
                                    datalabels: {
                                    display: false,
                                    color: 'black'
                                    }
                                },
                                scales: {
                                    xAxes: [{
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                        gridLines: {
                                            display: false
                                        },
                                        barPercentage: .95
                                    }],
                                    yAxes: [{
                                        ticks: {
                                            display: false,
                                            beginAtZero: true,
                                        },
                                        gridLines: {
                                            display: false
                                        },
                                    }],
                                },
                            }} 
                            />
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: 10, position: 'absolute', bottom: '1%', right: '1%', fontWeight: 800, color: 'darkgreen' }}>fourcastscore.com</div>
            </Paper>
        );
    }
}

export default BusyTimesReport;