import React, { Component } from 'react';
import './demographics.css';

class IncomeGauge extends Component {
    
    
    render() { 

        const { scores: { incomeGauge: thisListingsIncomeGauge } = {}} = this.props;

        let incomeNeedle;

        // SET NEEDLE ANGLE FROM 0 - 10
        
        if (thisListingsIncomeGauge >= 95) {
            incomeNeedle = 100;
        } else if (thisListingsIncomeGauge >= 90) {
            incomeNeedle = 90;
        } else if (thisListingsIncomeGauge >= 85) {
            incomeNeedle = 80;
        } else if (thisListingsIncomeGauge >= 80) {
            incomeNeedle = 70;
        } else if (thisListingsIncomeGauge >= 75) {
            incomeNeedle = 60;
        } else if (thisListingsIncomeGauge >= 70) {
            incomeNeedle = 50;
        } else if (thisListingsIncomeGauge >= 65) {
            incomeNeedle = 40;
        } else if (thisListingsIncomeGauge >= 60) {
            incomeNeedle = 30;
        } else if (thisListingsIncomeGauge >= 55) {
            incomeNeedle = 20;
        } else {
            incomeNeedle = 60;
        }

        const setIncomeGauge = (gauge) => {
            setTimeout(() => {
                let incomeGauge = document.querySelector('.incomeGaugePointer');
                let incomeRotation = gauge;                
                incomeGauge && incomeGauge.style.setProperty('--bottomIncome', incomeRotation);
            }, 200, { once: true });
        }
        
        thisListingsIncomeGauge && setIncomeGauge(incomeNeedle);

        return ( 
            <div>
                <div className='incomeGaugeHigh'>High</div>
                <div className='incomeGauge'></div>
                <div className='incomeGaugePointer'></div>
                <div className='incomeGaugeLow'>Low</div>
            </div>
        );
    }
}
 
export default IncomeGauge;