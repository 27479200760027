import React, { Component } from 'react';
import { Paper } from '@mui/material';
import RentOwnGraph from '../listingDetailsLayouts/demographics/rentOwnGraph';
import PriceGauge from '../../Assets/priceGauge.png';
import fourcastLogo from '../../Assets/fourcast_alt_logo_green.png';
import '../listingDetailsLayouts/traffic/traffic.css';
import '../listingDetailsLayouts/demographics/demographics.css';
import '../listingDetailsLayouts/summary/summary.css';

const greenArrow = <div className='greenArrowReport'></div>;
const redArrow = <div className='redArrowReport'></div>;


class ResidentialREReport extends Component {
    
    
    render() { 

        const { thisListing: { listingAddress: getListingAddress } = {}} = this.props;
        const { scores: { residentialPricesIndex: resPriceIndex } = {}} = this.props;
        // get current geoIdV4 array
        const { attomData: { geoIdV4 } = {}} = this.props;
        
        // RES REAL ESTATE DATA
        let thisListingsPrimaryNeighbRent = {};
        let thisListingsSecondaryNeighbRent = {};
        let thisListingsPrimaryNeighbSalePrice = {};
        let thisListingsSecondaryNeighbSalePrice = {};

        // owner renter dwellings
        const getOwnerDwellings = [];
        const getRenterDwellings = [];

        if (geoIdV4 && geoIdV4.length) {
            
            for (let n = 0; n < geoIdV4.length; n++) {
                                
                const thisAreaType = geoIdV4 && geoIdV4[n] && geoIdV4[n].type;
                
                
                const thisListingsRent = (geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.housing_Median_Rent));

                if (geoIdV4 && (geoIdV4.length > 1)) {
                    if (thisAreaType === 'N4') {    
                        thisListingsPrimaryNeighbRent = {medRent: thisListingsRent, areaType: thisAreaType}
                    } else {
                        thisListingsSecondaryNeighbRent = {medRent: thisListingsRent, areaType: thisAreaType}
                    }
                } else {
                    thisListingsPrimaryNeighbRent = {medRent: thisListingsRent, areaType: thisAreaType}
                }


                const thisListingsSalePrice = (geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.housing_Owner_Households_Median_Value));

                if (geoIdV4 && (geoIdV4.length > 1)) {
                    if (thisAreaType === 'N4') {    
                        thisListingsPrimaryNeighbSalePrice = {medSalePrice: thisListingsSalePrice, areaType: thisAreaType}
                    } else {
                        thisListingsSecondaryNeighbSalePrice = {medSalePrice: thisListingsSalePrice, areaType: thisAreaType}
                    }
                } else {
                    thisListingsPrimaryNeighbSalePrice = {medSalePrice: thisListingsSalePrice, areaType: thisAreaType}
                }

                const theseOwnerDwellings = (geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.housing_Units_Owner_Occupied));
                const theseRenterDwellings = (geoIdV4 && geoIdV4[n].commV4Data && parseFloat(geoIdV4[n].commV4Data.housing_Units_Renter_Occupied));
                getOwnerDwellings.push(theseOwnerDwellings);
                getRenterDwellings.push(theseRenterDwellings);
            }
        }

        // find avg rent by weighing each N-type (if there are multiple) => .6 for primary N4 && .4 for secondary N2 or N3
        const primaryRent = thisListingsPrimaryNeighbRent.medRent;
        const secondaryRent = thisListingsSecondaryNeighbRent && thisListingsSecondaryNeighbRent.medRent ? thisListingsSecondaryNeighbRent.medRent : null;
        const secondaryRentAreaType = thisListingsSecondaryNeighbRent && thisListingsSecondaryNeighbRent.areaType ? thisListingsSecondaryNeighbRent.areaType : null;

        let medRent = 'N/A';

        if ((secondaryRent !== null) && (secondaryRentAreaType !== null)) {
            let primaryVal = (primaryRent * .6);
            let secondaryVal = (secondaryRent * .4);
            medRent = Math.ceil(primaryVal + secondaryVal);
        } else {
            medRent = Math.ceil(primaryRent);
        }

        // find median sale price by weighing each N-type (if there are multiple) => .6 for primary N4 && .4 for secondary N2 or N3
        const primarySalePrice = thisListingsPrimaryNeighbSalePrice.medSalePrice;
        const secondarySalePrice = thisListingsSecondaryNeighbSalePrice && thisListingsSecondaryNeighbSalePrice.medSalePrice ? thisListingsSecondaryNeighbSalePrice.medSalePrice : null;
        const secondarySalePriceAreaType = thisListingsSecondaryNeighbSalePrice && thisListingsSecondaryNeighbSalePrice.areaType ? thisListingsSecondaryNeighbSalePrice.areaType : null;

        let medSalePrice = 'N/A';

        if ((secondarySalePrice !== null) && (secondarySalePriceAreaType !== null)) {
            let primaryVal = (primarySalePrice * .6);
            let secondaryVal = (secondarySalePrice * .4);
            medSalePrice = Math.ceil(primaryVal + secondaryVal);
        } else {
            medSalePrice = Math.ceil(primarySalePrice);
        }

        const medianSalePrice = medSalePrice;
        const medianRent = medRent;
    

        // RENT VS OWN GRAPH DATA
        const renterDwellings = getRenterDwellings.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });
        const ownerDwellings = getOwnerDwellings.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });

        const thisRentOwnData = {
            rentOwnData: {
                labels: ['Renters', 'Owners'],
                datasets: [
                    {
                        backgroundColor: ['rgb(52, 181, 122)', 'rgb(187, 236, 205)'],
                        data: [renterDwellings, ownerDwellings]
                    }
                ],
            }
        };

        
        let resPriceNeedle;
        
        if (resPriceIndex >= 95) {
            resPriceNeedle = 85;
        } else if (resPriceIndex >= 90) {
            resPriceNeedle = 75;
        } else if (resPriceIndex >= 85) {
            resPriceNeedle = 65;
        } else if (resPriceIndex >= 80) {
            resPriceNeedle = 45;
        } else if (resPriceIndex >= 75) {
            resPriceNeedle = 30;
        } else if (resPriceIndex >= 70) {
            resPriceNeedle = 20;
        } else if (resPriceIndex >= 65) {
            resPriceNeedle = 10;
        } else if (resPriceIndex >= 60) {
            resPriceNeedle = -10;
        } else if (resPriceIndex >= 55) {
            resPriceNeedle = -20;
        } else if (resPriceIndex >= 50) {
            resPriceNeedle = -30;
        } else if (resPriceIndex >= 45) {
            resPriceNeedle = -45;
        } else if (resPriceIndex >= 40) {
            resPriceNeedle = -65;
        } else if (resPriceIndex >= 35) {
            resPriceNeedle = -75;
        } else if (resPriceIndex >= 30) {
            resPriceNeedle = -85;
        } else {
            resPriceNeedle = 0;
        }

        const setResPricesGauge = (gauge) => {
            setTimeout(() => {
                let resPricesGauge = document.querySelector('.resPriceNeedleReport');
                let resPricesRotation = gauge;                
                resPricesGauge && resPricesGauge.style.setProperty('--rotation', resPricesRotation);
            }, 200, { once: true });
        }
        
        resPriceIndex && setResPricesGauge(resPriceNeedle);

        const commaNumber = (inc) => {
            let newNum = Number(inc);
            let numString = newNum.toLocaleString("en-US");
            return numString;
        }
        

        let thisAddressArray = [];
        thisAddressArray.push(getListingAddress);
        let addressString = thisAddressArray.toString();
        let thisListingAddress = addressString.split(',');
        

        return (
            <Paper id="trafficReportPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 15, position: 'relative' }}>
                <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '6fr 1fr' }}>
                        <div className='reportAddress'>
                            <h2>{thisListingAddress[0]}</h2>
                        </div>
                        <div className='reportFourcastLogoArea'>
                            <img className='reportFourcastLogo' src={fourcastLogo} alt='Fourcast Reports Logo' />
                        </div>
                    </div>
                    <hr />
                    <div style={{ marginTop: 15, height: '20%', display: 'grid', gridTemplateColumns: '4fr 5fr' }}>
                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', backgroundColor: 'rgb(21, 134, 84)' }}>
                            <h5 style={{ margin: '15px 0px 10px 20px', color: 'white' }}>Residential Real Estate Prices:</h5>
                        </div>
                        <div style={{ width: 350, height: 170, marginLeft: 'auto', marginRight: 'auto', marginTop: 25 }}>
                            <div style={{ height: 170, position: 'relative', margin: 'auto' }}>
                                <div className='resPriceGaugeReport'>
                                    <img src={PriceGauge} style={{ height: 125, width: 300 }} alt='Res Prices Gauge Report' />
                                    <div className='resPriceNeedleReport'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div style={{ marginTop: 25, height: '33%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                        <div style={{ height: 250, width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                            <div style={{ margin: 'auto', fontSize: 12, fontFamily: 'sans-serif', fontWeight: 'bold', textAlign: 'center' }}>
                                <div>
                                    <h5>Median Sale Price</h5>
                                </div>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: 125, marginTop: 'auto', marginBottom: 'auto' }}>
                                    <div style={{ width: 125, margin: 'auto', height: '50%' }}>
                                        <h1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(21, 134, 84)' }}>{`$${commaNumber(medianSalePrice)}`}</h1>
                                    </div>
                                </div>
                            </div>
                            <div style={{ margin: 'auto', fontSize: 12, fontFamily: 'sans-serif', fontWeight: 'bold', textAlign: 'center' }}>
                                <div>
                                    <h5>Median Rent</h5>
                                </div>
                                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', width: 125, margin: 'auto', height: '50%' }}>
                                    <div style={{ width: 125, margin: 'auto', height: '50%' }}>
                                        <h1 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'rgb(21, 134, 84)' }}>{`$${commaNumber(medianRent)}`}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div style={{ height: '30%', display: 'grid', gridTemplateColumns: '4fr 5fr' }}>
                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', backgroundColor: 'rgb(21, 134, 84)' }}>
                            <h5 style={{ margin: '15px 0px 10px 20px', color: 'white' }}>Rent vs Own:</h5>
                        </div>
                        <div style={{ maxWidth: 350, marginLeft: 25 }}>
                            <RentOwnGraph thisRentOwnData={thisRentOwnData} />
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: 10, position: 'absolute', bottom: '1%', right: '1%', fontWeight: 800, color: 'darkgreen' }}>fourcastscore.com</div>
            </Paper>
        );
    }
}

export default ResidentialREReport;