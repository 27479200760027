import React, { Component } from 'react';
import { 
    Modal, 
    ModalHeader, 
    ModalBody, 
    Button, 
    Table } 
from 'reactstrap';
import '../listingDetailsLayouts/competition/competition.css';
import YelpStarRating from '../listingDetailsLayouts/competition/yelpStarRating';

class BizListModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            goodOrBad: props.goodOrBad
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
            category: ''
        });
    }


    render() {
        const { bizList } = this.props;
        
        let businesses = bizList.sort((a, b) => {
            return b.review_count - a.review_count
        });
        
        let filteredBusinesses = businesses.map((business) => {
            return (
                <tr>
                    <th style={{ fontSize: 14 }}>
                        {business.name}
                    </th>
                    <td>
                        <YelpStarRating business={business} />
                    </td>
                    <td style={{ fontSize: 14 }}>
                        {business.review_count}
                    </td>
                    <td style={{ fontSize: 14 }}>
                        {business.price}
                    </td>
                    <td style={{ fontSize: 14 }}>
                        {business.categories[0].title}
                    </td>
                    <td style={{ fontSize: 14 }}>
                        {(business.distance * 3.2808).toFixed(0)} (feet)
                    </td>
                </tr>
            )
        });
        
        return (
            <span>
                <Button
                    color='link'
                    id="bizListModalBtn"
                    style={this.state.goodOrBad === 'bad' ? { color: 'darkred', fontSize: 18, fontWeight: 'bold', height: 32, verticalAlign: 1, textDecoration: 'underline', textUnderlineOffset: 3 } : { color: 'darkgreen', fontSize: 18, fontWeight: 'bold', height: 32, verticalAlign: 1, textDecoration: 'underline', textUnderlineOffset: 3 }}
                    onClick={this.toggle}
                >
                    {bizList.length}
                </Button>
                <Modal style={{ minWidth: 900 }} isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Businesses Nearby</ModalHeader>
                    <ModalBody style={{ width: 900, margin: 'auto' }}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Rating</th>
                                    <th>Reviews</th>
                                    <th>Price</th>
                                    <th>Category</th>
                                    <th>Distance</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredBusinesses}
                            </tbody>
                        </Table>
                    </ModalBody>
                </Modal>
            </span>
        );
    }
}
 
export default BizListModal;