import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { 
    Modal, 
    ModalHeader, 
    ModalBody, 
    Row,
    Alert } 
from 'reactstrap';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import fourcastMatchLogo from '../../Assets/fourcast_match_logo_green.png';
import YelpStarRating from '../listingDetailsLayouts/competition/yelpStarRating';
import BizListModal from './bizListModal';
import { Button, CircularProgress, LinearProgress, Stepper, Step, StepLabel, Divider, Dialog, DialogContent, DialogContentText, DialogActions, FormControl, Input, InputAdornment, Paper, Table, DialogTitle } from '@mui/material';
import { connect } from 'react-redux';
import { getListing } from '../../actions/listingActions';
import PropTypes from 'prop-types';
import { MdFiberManualRecord, MdFileDownload } from 'react-icons/md';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { FaEdit } from "react-icons/fa";
import '../CompareListings/compareListings.css';
import HighEndMatchComparison from './MatchComparisons/highEndMatchComparison';
import BizDensityMatchComparison from './MatchComparisons/bizDensityMatchComparison';
import MajorAttractionMatchComparison from './MatchComparisons/majorAttractionMatchComparison';
import WeekdayWeekendMatchComparison from './MatchComparisons/weekdayWeekendMatchComparison';
import PopularTimesMatchComparison from './MatchComparisons/popularTimesMatchComparison';
import CommonPeopleMatchComparison from './MatchComparisons/commonPeopleMatchComparison';
import AreasWithChildrenMatchComparison from './MatchComparisons/areasWithChildrenMatchComparison';
import AgesMatchComparison from './MatchComparisons/agesMatchComparison';
import IncomeMatchComparison from './MatchComparisons/incomeMatchComparison';
import ResPricesMatchComparison from './MatchComparisons/resPricesMatchComparison';


class FourcastMatchResultComparisonModal extends Component {

    
    constructor(props) {
        super(props);
        this.state = {
            fourcastMatchResultComparisonModal: false,
            compareListingsChange: false,
            selected: [],
            downloaded: [],
            editMode: false,
            notConfirmedError: false,
            selectedListingNum: 0,
            allListingInfo: [],
            totalGatherListingsNum: 0,
            loading: false,
            customPrices: [],
            stateTopListingsByFilter: [],
            stateTopByFourcastScore: [],
            selectedForEdit: [],
            editedPrice: 0
        }
    }

    async componentDidMount() {
        const { fourcastMatchResultComparisonModal, listings } = this.props;
        if (fourcastMatchResultComparisonModal === true) {
            await this.getSelectedListings(listings);
        }
    }


    async componentDidUpdate(prevProps, prevState) {
        const { selected, allListingInfo, customPrices, loading } = this.state;
        const { thisListing, listings } = this.props;
        
        const { fourcastMatchResultComparisonModal } = this.props;

        if (this.state.fourcastMatchResultComparisonModal !== fourcastMatchResultComparisonModal) {
            this.setState({ fourcastMatchResultComparisonModal: fourcastMatchResultComparisonModal });
        }

        if (fourcastMatchResultComparisonModal === true) {
            const selectedAddys = listings && listings.length && listings.map(selectedListing => selectedListing.listingAddress).sort();
            const gatheredAddys = allListingInfo && allListingInfo.length && allListingInfo.map(gatheredListing => gatheredListing.listingAddress).sort();
            
            
            if ((listings.length !== allListingInfo.length) || (JSON.stringify(selectedAddys) !== JSON.stringify(gatheredAddys))) {

                if (prevProps.listings !== listings) {
                    if (prevState.allListingInfo === allListingInfo) {
                        const filterGatheredListings = allListingInfo.filter(gatheredListing => listings.some(selectedListing => selectedListing._id === gatheredListing._id));
                        this.setState({ allListingInfo: filterGatheredListings });
                    }
                }

                if ((!thisListing._id) || (JSON.stringify(selectedAddys) !== JSON.stringify(gatheredAddys))) {
                    if (listings.length !== allListingInfo.length) {
                        await this.getSelectedListings(listings);
                    }
                }
                
                if (thisListing && thisListing._id) {
                    if (listings.some(selectListing => selectListing._id === thisListing._id)) {
                        if (allListingInfo.some(gatheredListing => gatheredListing._id === thisListing._id)) {
                            return null;
                        } else { 
                            allListingInfo.push(thisListing);
                        }
                    } else {
                        await this.getSelectedListings(listings);
                    }
                }
            }
            
        }
            
        if ((prevState.customPrices !== customPrices) || (customPrices && customPrices.length && (prevProps.listings !== this.props.listings))) {
            const { customSearchFilters, listings, customSearchObjects } = this.props;
            this.setState({ loading: true });
            
            // rerun the remaining functions with manually entered prices
            let getStateTopListingsByFilter = (listings, customSearchObjects) => {
        
                const stateTopByMinSize = [];
                const stateTopByMaxSize = [];
                const stateTopByPrice = [];
    
    
                // NOTE : color values are intentionally incomplete, see ("rgb(253, 127, 111-" instead of "rgb(253, 127, 111)") because the opacity level is set inline. 
                // Should be only 3 or 4 instances. In the key up top there's no opacity. In the actual min/max/price rankings the color opacity is .3
                // Can be found at listing.color
                const listingColorsState = ["rgb(254, 39, 18", "rgb(102, 176, 50", "rgb(251, 153, 2", "rgb(52, 124, 152", "rgb(254, 254, 51", "rgb(128, 0, 0", "rgb(2, 71, 254", "rgb(134, 1, 175"];
    
        
                for (let i = 0; i < listings.length; i++) {
                    
                    const [thisCustomSearchStateObj] = customSearchObjects && customSearchObjects.filter(obj => obj && obj.listingID === listings[i]._id);
                    
                    if (listings[i].squareFeet) {
                        
                        let listingObj = listings[i];
    
                        let listingColor = listingColorsState[i];
                        listingObj.color = listingColor;
                        
                        // minSize DIFF
                        let listingsMinSF = (listings[i].squareFeet && listings[i].squareFeet.length && (listings[i].squareFeet.length > 1)) ? listings[i].squareFeet[1] : listings[i].squareFeet[0];
                        let minSize = customSearchFilters && customSearchFilters.minSize && parseFloat(customSearchFilters.minSize);
                        let minSizeDiff = Math.floor(listingsMinSF - minSize);
                        listingObj.minSizeDiff = minSizeDiff;
                        stateTopByMinSize.push(listingObj);
    
                        // maxSize DIFF
                        let listingsMaxSF = listings[i].squareFeet && listings[i].squareFeet.length && listings[i].squareFeet[0];
                        let maxSize = customSearchFilters && customSearchFilters.maxSize && parseFloat(customSearchFilters.maxSize);
                        let maxSizeDiff = Math.floor(maxSize - listingsMaxSF);
                        listingObj.maxSizeDiff = maxSizeDiff;
                        stateTopByMaxSize.push(listingObj);
    
                        // price DIFF
                        // convert listing's price to a total YEARLY amount by multiplying by square feet
                        const thisStateBudgetType = parseFloat(customSearchFilters.budgetType);
                        let [customPriceObj] = this.state.customPrices.filter(customPriceListing => customPriceListing._id === listings[i]._id);
                        let customPrice = customPriceObj && customPriceObj.price ? customPriceObj.price : null;
                        let totalYearlyPrice = (listings[i].squareFeet.length > 1) ? (customPrice === null) ? [Math.ceil(listings[i].pricePerSquareFoot * listings[i].squareFeet[0]), Math.ceil(listings[i].pricePerSquareFoot * listings[i].squareFeet[1])] : [Math.ceil(customPrice * listings[i].squareFeet[0]), Math.ceil(customPrice * listings[i].squareFeet[1])] : (customPrice === null) ? [Math.ceil(listings[i].pricePerSquareFoot * listings[i].squareFeet[0])] : [Math.ceil(customPrice * listings[i].squareFeet[0])];
                        let listingsPrice = (customPrice !== null) ? customPrice : listings[i].pricePerSquareFoot && listings[i].pricePerSquareFoot;
                        let budget = customSearchFilters && customSearchFilters.price && parseFloat(customSearchFilters.price);
                        let priceDiff;
                        if (listingsPrice && (listingsPrice !== -1)) {
                            if (thisStateBudgetType === 1) {
                                // total amount
                                let getPriceDiff = (totalYearlyPrice && totalYearlyPrice.length && (totalYearlyPrice.length > 1)) ? [Math.floor(budget - totalYearlyPrice[0]), Math.floor(budget - totalYearlyPrice[1])] : [Math.floor(budget - totalYearlyPrice[0])];
                                let sortedPriceDiffs = getPriceDiff.sort(function(a, b){return b-a});
                                priceDiff = sortedPriceDiffs && sortedPriceDiffs.length && sortedPriceDiffs[0];
                            } else {
                                priceDiff = Math.floor(budget - listingsPrice);
                            }
                        } else {
                            priceDiff = null;
                        }
                        listingObj.priceDiff = priceDiff;
                        listingObj.customPrice = (customPrice !== null) ? listingsPrice : null;
                        stateTopByPrice.push(listingObj);
                        
                    }
                }
                const sortedByMinSize = stateTopByMinSize.sort(function(a, b){return b.minSizeDiff-a.minSizeDiff});
                const sortedByMaxSize = stateTopByMaxSize.sort(function(a, b){return b.maxSizeDiff-a.maxSizeDiff});
                const sortedByPrice = stateTopByPrice.sort(function(a, b){return (b.priceDiff !== null ? b.priceDiff : -Infinity) - (a.priceDiff !== null ? a.priceDiff : -Infinity)});
    
                const topByFilterObj = {topByMinSF: sortedByMinSize, topByMaxSF: sortedByMaxSize, topByPrice: sortedByPrice};
                return topByFilterObj;
                
                
            };

            // rescore each listing with manually entered prices
            let getStateTopCustomSearchListings = (listings, customSearchObjects) => {
        
                const topStateResults = [];
        
                for (let i = 0; i < listings.length; i++) {
                    
                    if (listings[i].scores) {
                        
                        let listingObj = listings[i];

                        let customScore = Math.floor(listingObj.scores.fourcastScore + this.props.findCustomSearchPoints(listingObj, customSearchObjects));
                        listingObj.customScore = customScore;
                        
                        if (listingObj && listingObj.customPrice && listingObj.customPrice) {
                            
                            const thisStateMaxPrice = parseFloat(customSearchFilters.price);
                            // Budget Types: 1 = total amount per year, 2 = per square foot per year
                            const thisStateBudgetType = parseFloat(customSearchFilters.budgetType);
                            const theseDealBreakers = customSearchFilters && customSearchFilters.dealBreakers && customSearchFilters.dealBreakers;
                            const dealBreakers = {
                                price: (theseDealBreakers && theseDealBreakers.length && theseDealBreakers.some(dealBreaker => dealBreaker && dealBreaker.dealBreakTitle && dealBreaker.dealBreakTitle === 'price')) ? theseDealBreakers && theseDealBreakers.filter(dealBreaker => dealBreaker && dealBreaker.dealBreakTitle === 'price') : null,
                            };
                            let customPrice = listingObj && listingObj.customPrice && listingObj.customPrice;
                            let totalYearlyPrice = (listings[i].squareFeet.length > 1) ? (customPrice === null) ? [Math.ceil(listings[i].pricePerSquareFoot * listings[i].squareFeet[0]), Math.ceil(listings[i].pricePerSquareFoot * listings[i].squareFeet[1])] : [Math.ceil(customPrice * listings[i].squareFeet[0]), Math.ceil(customPrice * listings[i].squareFeet[1])] : (customPrice === null) ? [Math.ceil(listings[i].pricePerSquareFoot * listings[i].squareFeet[0])] : [Math.ceil(customPrice * listings[i].squareFeet[0])];
                            let listingsPrice = (customPrice === null) ? listings[i].pricePerSquareFoot && listings[i].pricePerSquareFoot : customPrice;

                            let pricePoints = 0;

                            if (thisStateBudgetType === 1) {
                                if (totalYearlyPrice.some(yearlyPrice => yearlyPrice < thisStateMaxPrice)) {
                                    pricePoints += 5;
                                    if (dealBreakers.price !== null) {
                                        pricePoints += 2.5;
                                    }
                                } else if ((totalYearlyPrice[0] > (thisStateMaxPrice * 1.5)) && (totalYearlyPrice[totalYearlyPrice.length - 1] > (thisStateMaxPrice * 1.5))) {
                                    pricePoints -= 30;
                                    if (dealBreakers.price !== null) {
                                        pricePoints *= 2;
                                    }
                                } else if ((totalYearlyPrice[0] > thisStateMaxPrice) && (totalYearlyPrice[totalYearlyPrice.length - 1] > thisStateMaxPrice)) {
                                    pricePoints -= 20;
                                    if (dealBreakers.price !== null) {
                                        pricePoints *= 3;
                                    }
                                } else if (totalYearlyPrice <= 0) {
                                    pricePoints += 0;
                                }
                            } else {
                                if (listingsPrice < thisStateMaxPrice) {
                                    pricePoints += 5;
                                    if (dealBreakers.price !== null) {
                                        pricePoints += 2.5;
                                    }
                                } else if (listingsPrice > (thisStateMaxPrice * 1.5)) {
                                    pricePoints -= 30;
                                    if (dealBreakers.price !== null) {
                                        pricePoints *= 2;
                                    }
                                } else if (listingsPrice > thisStateMaxPrice) {
                                    pricePoints -= 20;
                                    if (dealBreakers.price !== null) {
                                        pricePoints *= 3;
                                    }
                                } else if (listingsPrice <= 0) {
                                    pricePoints += 0;
                                }
                            }

                            const customPriceAdjustedMatchScore = Math.floor(customScore + pricePoints);
                            listingObj.customScore = customPriceAdjustedMatchScore;
                        }
                        
                        listingObj.listingNum = (i + 1);
                        topStateResults.push(listingObj);
                    }
                }
        
                return topStateResults.sort(function(a, b){return b.customScore-a.customScore});
                
            };

            
            let topStateListingsByFilter = getStateTopListingsByFilter(listings, customSearchObjects);
            let sortedByStateFourcastScore = getStateTopCustomSearchListings(listings, customSearchObjects);
            this.setState({ stateTopListingsByFilter: [topStateListingsByFilter], stateTopByFourcastScore: sortedByStateFourcastScore });
        }
                
    }

    // Select the listings to generate comparison for
    setSelected = async (selectedListings) => {
        const { allListingInfo } = this.state;
        const selectedListingAddys = selectedListings && selectedListings.length && selectedListings.map(selectedList => selectedList.listingAddress).sort();
        const filterAllListingInfo = allListingInfo && allListingInfo.length && allListingInfo.filter(gatheredListing => selectedListingAddys && selectedListingAddys.includes(gatheredListing.listingAddress));

        if (filterAllListingInfo && (filterAllListingInfo.length === allListingInfo.length)) {
            this.setState({ selected: selectedListings });
        } else {
            this.setState({ selected: selectedListings, allListingInfo: [] });
        }
    }

    // Get the selected listing's data
    getSelectedListings = async (selected) => {
    
        if (selected.length) {
            for (let i = 0; i < selected.length; i++) {
                try {
                    let listingId;
                    listingId = selected[i]._id;
                    await this.props.getListing(listingId);
                } catch (err) {
                    console.log(err);
                }
            }
        }
    }

    setDownloaded () {
        const { downloaded, selectedListingNum } = this.state;
        this.setState({ downloaded: [...downloaded, selectedListingNum] });
    }

    setEditMode = (status, listingToEdit) => {
        const { editMode } = this.state;
        if (editMode !== status) {
            this.setState({ editMode: status, selectedForEdit: listingToEdit });
        }
    }

    handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
          }
          this.setState({ notConfirmedError: false });
    }

    setNotConfirmedError = (event) => {
        this.setState({ notConfirmedError: true });
    }


    render() {

        const { isAuthenticated, user, customSearchFilters, fourcastMatchResultComparisonModal, listings, customSearchObjects, findCustomSearchPoints, scoreIcon, customSearchScoreIcon } = this.props;
        const { allListingInfo, downloaded, selectedListingNum } = this.state;

        const theseCustomSearchObjs = customSearchObjects && customSearchObjects.filter(obj => {return obj && listings && listings.some(listing => listing._id === obj.listingID )});

        let getTopCustomSearchListings = (listings, customSearchObjects) => {
        
            const topResults = [];
    
            for (let i = 0; i < listings.length; i++) {
                
                if (listings[i].scores) {
                    let customScore = Math.floor(listings[i].scores.fourcastScore + findCustomSearchPoints(listings[i], customSearchObjects));
                    let listingObj = listings[i];
                    listingObj.customScore = customScore;
                    listingObj.listingNum = (i + 1);
                    topResults.push(listingObj);
                }
            }
    
            return topResults.sort(function(a, b){return b.customScore-a.customScore});
            
        };

        let getTopListingsByFilter = (listings, customSearchObjects) => {
        
            const topByMinSize = [];
            const topByMaxSize = [];
            const topByPrice = [];


            // NOTE : color values are intentionally incomplete, see ("rgb(253, 127, 111-" instead of "rgb(253, 127, 111)") because the opacity level is set inline. 
            // Should be only 3 or 4 instances. In the key up top there's no opacity. In the actual min/max/price rankings the color opacity is .3
            // Can be found at listing.color
            const listingColors = ["rgb(254, 39, 18", "rgb(102, 176, 50", "rgb(251, 153, 2", "rgb(52, 124, 152", "rgb(254, 254, 51", "rgb(128, 0, 0", "rgb(2, 71, 254", "rgb(134, 1, 175"];

            
            for (let i = 0; i < listings.length; i++) {
                
                
                if (listings[i].squareFeet) {
                    
                    let listingObj = listings[i];

                    let listingColor = listingColors[i];
                    listingObj.color = listingColor;
                    
                    // minSize DIFF
                    let listingsMinSF = (listings[i].squareFeet && listings[i].squareFeet.length && (listings[i].squareFeet.length > 1)) ? listings[i].squareFeet[1] : listings[i].squareFeet[0];
                    let minSize = customSearchFilters && customSearchFilters.minSize && parseFloat(customSearchFilters.minSize);
                    let minSizeDiff = Math.floor(listingsMinSF - minSize);
                    listingObj.minSizeDiff = minSizeDiff;
                    topByMinSize.push(listingObj);

                    // maxSize DIFF
                    let listingsMaxSF = listings[i].squareFeet && listings[i].squareFeet.length && listings[i].squareFeet[0];
                    let maxSize = customSearchFilters && customSearchFilters.maxSize && parseFloat(customSearchFilters.maxSize);
                    let maxSizeDiff = Math.floor(maxSize - listingsMaxSF);
                    listingObj.maxSizeDiff = maxSizeDiff;
                    topByMaxSize.push(listingObj);

                    // price DIFF
                    // convert listing's price to a total YEARLY amount by multiplying by square feet
                    const thisBudgetType = parseFloat(customSearchFilters.budgetType);
                    let [customPriceObj] = this.state.customPrices.filter(customPriceListing => customPriceListing._id === listings[i]._id);
                    let customPrice = customPriceObj && customPriceObj.price ? customPriceObj.price : null;
                    let totalYearlyPrice = (listings[i].squareFeet.length > 1) ? (customPrice === null) ? [Math.ceil(listings[i].pricePerSquareFoot * listings[i].squareFeet[0]), Math.ceil(listings[i].pricePerSquareFoot * listings[i].squareFeet[1])] : [Math.ceil(customPrice * listings[i].squareFeet[0]), Math.ceil(customPrice * listings[i].squareFeet[1])] : (customPrice === null) ? [Math.ceil(listings[i].pricePerSquareFoot * listings[i].squareFeet[0])] : [Math.ceil(customPrice * listings[i].squareFeet[0])];
                    let listingsPrice = (customPrice === null) ? listings[i].pricePerSquareFoot && listings[i].pricePerSquareFoot : customPrice;
                    let budget = customSearchFilters && customSearchFilters.price && parseFloat(customSearchFilters.price);
                    let priceDiff;
                    if (listingsPrice && (listingsPrice !== -1)) {
                        if (thisBudgetType === 1) {
                            // total amount
                            let getPriceDiff = (totalYearlyPrice && totalYearlyPrice.length && (totalYearlyPrice.length > 1)) ? [Math.floor(budget - totalYearlyPrice[0]), Math.floor(budget - totalYearlyPrice[1])] : [Math.floor(budget - totalYearlyPrice[0])];
                            let sortedPriceDiffs = getPriceDiff.sort(function(a, b){return b-a});
                            priceDiff = sortedPriceDiffs && sortedPriceDiffs.length && sortedPriceDiffs[0];
                        } else {
                            priceDiff = Math.floor(budget - listingsPrice);
                        }
                    } else {
                        priceDiff = null;
                    }
                    listingObj.priceDiff = priceDiff;
                    topByPrice.push(listingObj);
                }
            }

            const sortedByMinSize = topByMinSize.sort(function(a, b){return b.minSizeDiff-a.minSizeDiff});
            const sortedByMaxSize = topByMaxSize.sort(function(a, b){return b.maxSizeDiff-a.maxSizeDiff});
            const sortedByPrice = topByPrice.sort(function(a, b){return (b.priceDiff !== null ? b.priceDiff : -Infinity) - (a.priceDiff !== null ? a.priceDiff : -Infinity)});

            const topByFilterObj = {topByMinSF: sortedByMinSize, topByMaxSF: sortedByMaxSize, topByPrice: sortedByPrice};
            return topByFilterObj;
            
        };
    
        const sortedByFourcastScore = this.state.stateTopByFourcastScore && this.state.stateTopByFourcastScore.length ? this.state.stateTopByFourcastScore : getTopCustomSearchListings(listings, customSearchObjects);
        let topListingsByFilter = this.state.stateTopListingsByFilter && this.state.stateTopListingsByFilter.length ? this.state.stateTopListingsByFilter[0] : getTopListingsByFilter(listings, customSearchObjects);

        if (this.state.loading === true) {
            setTimeout(() => {
                this.setState({ loading: false });
            }, 1000);
            
        }
        
        const sortedByBadBizDeduction = theseCustomSearchObjs && theseCustomSearchObjs.sort((a, b) => a.badBiz.badBizDeduction - b.badBiz.badBizDeduction).map(listing => listing);
        const sortedByGoodBizAddition = theseCustomSearchObjs && theseCustomSearchObjs.sort((a, b) => b.goodBiz.goodBizAddition - a.goodBiz.goodBizAddition).map(listing => listing);
        
        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        };

        const scoreColor = (score) => {
            if (score < 60) {
                return '#B00238';
            } else if ((score >= 60) && (score < 70)) {
                return '#E82F2A';
            } else if ((score >= 70) && (score < 80)) {
                return '#F0B240';
            } else if ((score >= 80) && (score < 90)) {
                return '#6AAA30';
            } else {
                return '#366621';
            }
        };

        const reportElems =  ['sizeAndPriceMatchComparison', 'badBizMatchComparison', 'goodBizMatchComparison'];

        if (customSearchFilters && customSearchFilters.compFilters && (customSearchFilters.compFilters.includes('lowEndBiz') || customSearchFilters.compFilters.includes('highEndBiz'))) {
            reportElems.push('highEndBizAreaMatchComparison')
        }
        if (customSearchFilters && customSearchFilters.compFilters && (customSearchFilters.compFilters.includes('lowBizDensity') || customSearchFilters.compFilters.includes('highBizDensity'))) {
            reportElems.push('bizDensityAreaMatchComparison')
        }
        if (customSearchFilters && customSearchFilters.trafficFilters && customSearchFilters.demoFilters && (customSearchFilters.trafficFilters.includes('nearAttraction') || customSearchFilters.demoFilters.includes('nearAttraction'))) {
            reportElems.push('majorAttractionMatchComparison')
        }
        if (customSearchFilters && customSearchFilters.trafficFilters && (customSearchFilters.trafficFilters.includes('busyWeekend'))) {
            reportElems.push('weekdayWeekendMatchComparison')
        }
        if (customSearchFilters && customSearchFilters.trafficFilters && (customSearchFilters.trafficFilters.includes('busyNightLife'))) {
            reportElems.push('busyNightLifeMatchComparison')
        }
        if (customSearchFilters && customSearchFilters.trafficFilters && (customSearchFilters.trafficFilters.includes('busyDayTime'))) {
            reportElems.push('busyDayTimeMatchComparison')
        }
        if (customSearchFilters && customSearchFilters.demoFilters && (customSearchFilters.demoFilters.includes('students'))) {
            reportElems.push('studentsMatchComparison')
        }
        if (customSearchFilters && customSearchFilters.demoFilters && (customSearchFilters.demoFilters.includes('officeWorkers'))) {
            reportElems.push('officeWorkersMatchComparison')
        }
        if (customSearchFilters && customSearchFilters.demoFilters && (customSearchFilters.demoFilters.includes('localResidents'))) {
            reportElems.push('localResidentsMatchComparison')
        }
        if (customSearchFilters && customSearchFilters.demoFilters && (customSearchFilters.demoFilters.includes('areasWithChildren'))) {
            reportElems.push('areasWithChildrenMatchComparison')
        }
        if (customSearchFilters && customSearchFilters.demoFilters && (customSearchFilters.demoFilters.includes('genZ'))) {
            reportElems.push('genZMatchComparison')
        }
        if (customSearchFilters && customSearchFilters.demoFilters && (customSearchFilters.demoFilters.includes('millennialsTo34'))) {
            reportElems.push('millennialsTo34MatchComparison')
        }
        if (customSearchFilters && customSearchFilters.demoFilters && (customSearchFilters.demoFilters.includes('millennialsTo44'))) {
            reportElems.push('millennialsTo44MatchComparison')
        }
        if (customSearchFilters && customSearchFilters.demoFilters && (customSearchFilters.demoFilters.includes('genX'))) {
            reportElems.push('genXMatchComparison')
        }
        if (customSearchFilters && customSearchFilters.demoFilters && (customSearchFilters.demoFilters.includes('boomers'))) {
            reportElems.push('boomersMatchComparison')
        }
        if (customSearchFilters && customSearchFilters.demoFilters && (customSearchFilters.demoFilters.includes('highIncomeArea'))) {
            reportElems.push('highIncomeAreaMatchComparisonPage1', 'highIncomeAreaMatchComparisonPage2');
        }
        if (customSearchFilters && customSearchFilters.demoFilters && (customSearchFilters.demoFilters.includes('affordableRE'))) {
            reportElems.push('affordableREMatchComparison')
        }
        

        
        const getCanvasData = element => {
            
            return new Promise(async (resolve, reject) => {
              await html2canvas(element, {logging: true, letterRendering: 1, useCORS: true})
                .then(function(canvas) {
                  resolve(canvas.toDataURL("image/jpeg"));
                })
                .catch(function(error) {
                  reject(
                    "Error while creating canvas for element with ID: " + element.id
                  );
                });
            });
        };
        
        const downloadPdfDocument = async () => {
            
            var pdf = new jsPDF({
              orientation: "p",
              unit: "mm",
              format: "a4",
              compression: true,
            });
        
            let pageIds = reportElems;
            let promises = [];
            
            await pageIds.forEach(page => {
                promises.push(getCanvasData(document.getElementById(page)));
                this.setDownloaded();
            });
        
            Promise.all(promises).then(dataUrls => {
                dataUrls.forEach((dataUrl, i) => {
                    const imgWidth = pdf.internal.pageSize.getWidth() * 1;
                    const imgHeight = pdf.internal.pageSize.getHeight();
                    const imgX = ((pdf.internal.pageSize.getWidth() - imgWidth) / 1);
                    pdf.addImage(dataUrl, "JPEG", imgX+10.20, 0, imgWidth-20, imgHeight, undefined, "FAST");
                    
                    if (i !== reportElems.length - 1) {
                        pdf.addPage();
                    }
                });
                pdf.save(`Fourcast_Match_Comparison.pdf`);
            });
        };


        // REGULAR SCORES TO FOURCAST MATCH SCORES !!!!!!!!!!!!!!!!!!!!!!
        let filteredListingFourcastScores = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            return (
                <td key={listing._id} style={{ fontSize: `${(sortedByFourcastScore && sortedByFourcastScore.length && (sortedByFourcastScore.length > 5)) ? '12px' : '14px'}`, padding: 10 }}>
                    <div style={{ fontWeight: 800, fontSize: 16, display: 'flex', justifyContent: 'center', alignItems: 'center', color: scoreColor(listing && listing.scores && listing.scores.fourcastScore && listing.scores.fourcastScore) }}>
                        {listing && listing.scores && listing.scores.fourcastScore && listing.scores.fourcastScore}
                    </div>
                </td>
            )
        });
        let filteredListingFourcastMatchScores = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            return (
                <td key={listing._id} style={{ fontSize: `${(sortedByFourcastScore && sortedByFourcastScore.length && (sortedByFourcastScore.length > 5)) ? '12px' : '14px'}`, padding: 10 }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {this.state.stateTopByFourcastScore && this.state.stateTopByFourcastScore.length ? <img style={{ height: 30, width: 30 }} src={scoreIcon(listing && listing.customScore && listing.customScore)} alt='Fourcast Match Score icon' /> : <img style={{ height: 30, width: 30 }} src={customSearchScoreIcon(listing && listing.scores && listing.scores.fourcastScore && listing.scores.fourcastScore, findCustomSearchPoints(listing, customSearchObjects))} alt='Fourcast Match Score icon' />}
                    </div>
                </td>
            )
        });


        // LISTING MIN SIZE !!!!!!!!!!!!!!!!!!!!!
        let filteredListingMinSize = topListingsByFilter && topListingsByFilter.topByMinSF && topListingsByFilter.topByMinSF.map((listing) => {
            return (
                <tr style={{ height: 40 }}>
                    <th style={{ width: '100%', backgroundColor: `${listing && listing.color})` }}></th>
                    <td style={{ fontSize: 14, textAlign: 'center', backgroundColor: 'whitesmoke' }}>
                        <div style={{ fontSize: 10, width: '98%', overflowY: 'scroll' }}>
                            {addressStyle(listing.listingAddress)}
                        </div>
                    </td>
                    <td style={{ fontSize: 12, textAlign: 'center', fontWeight: 600 }}>
                        {(listing.squareFeet && listing.squareFeet.length > 1) ? `${listing.squareFeet[0]} - ${listing.squareFeet[1]}` : `${listing.squareFeet}`}
                    </td>
                </tr>
            )
        });


        // LISTING MAX SIZE !!!!!!!!!!!!!!!!!!!!!
        let filteredListingMaxSize = topListingsByFilter && topListingsByFilter.topByMaxSF && topListingsByFilter.topByMaxSF.map((listing) => {
            return (
                <tr style={{ height: 40 }}>
                    <th style={{ width: '100%', backgroundColor: `${listing && listing.color})` }}></th>
                    <td style={{ fontSize: 14, textAlign: 'center', backgroundColor: 'whitesmoke' }}>
                        <div style={{ fontSize: 10, width: '98%', overflowX: 'scroll' }}>
                            {addressStyle(listing.listingAddress)}
                        </div>
                    </td>
                    <td style={{ fontSize: 12, textAlign: 'center', fontWeight: 600 }}>
                        {(listing.squareFeet && listing.squareFeet.length > 1) ? `${listing.squareFeet[0]} - ${listing.squareFeet[1]}` : `${listing.squareFeet}`}
                    </td>
                </tr>
            )
        });


        // LISTING PRICE !!!!!!!!!!!!!!!!!!!!!
        let filteredListingPrice = topListingsByFilter && topListingsByFilter.topByPrice && topListingsByFilter.topByPrice.map((listing) => {
            return (
                <tr key={listing._id} style={{ height: 40 }}>
                    <th style={{ width: '100%', backgroundColor: `${listing && listing.color})` }}></th>
                    <td style={{ fontSize: 14, textAlign: 'center', backgroundColor: 'whitesmoke' }}>
                        <div style={{ fontSize: 10, width: '98%', overflowX: 'scroll' }}>
                            {addressStyle(listing.listingAddress)}
                        </div>
                    </td>
                    <td style={{ fontSize: (listing.pricePerSquareFoot !== -1) ? 12 : 11, fontWeight: 600 }}>
                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {(listing.pricePerSquareFoot !== -1) ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{`$${listing.pricePerSquareFoot} / yr`}</div>
                            ) : listing.customPrice ? (
                                <Button
                                    variant='link'
                                    onClick={(e) => this.setEditMode(true, listing)}
                                    endIcon={<FaEdit style={{ color: 'dodgerblue', height: 10, width: 10, marginRight: 2, marginBottom: 1 }} data-html2canvas-ignore="true" />}
                                    style={{ outline: 'none', textDecoration: 'none', fontWeight: 550, fontSize: 12, textTransform: 'none', width: '100%' }}
                                >
                                    {`$${listing.customPrice} / yr`}
                                </Button>
                            ) : (
                            <Button
                                variant='link'
                                onClick={(e) => this.setEditMode(true, listing)}
                                endIcon={<FaEdit style={{ color: 'dodgerblue', height: 10, width: 10, marginRight: 2, marginBottom: 1 }} data-html2canvas-ignore="true" />}
                                style={{ outline: 'none', textDecoration: 'none', fontWeight: 600, fontSize: 10 }}
                            >
                                Undisclosed
                            </Button>
                            )}
                            <Dialog
                                open={(this.state.editMode === true)}
                                onClose={(e) => this.setEditMode(false)}
                            >
                                <DialogTitle>Edit Price</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        The price for this listing is not disclosed. Enter a price manually to see how it compares to other listings.
                                    </DialogContentText>
                                    <DialogContentText style={{ marginTop: 10, fontSize: 12 }}>
                                        *price per sq.ft. / YR
                                    </DialogContentText>
                                    <DialogContentText style={{ fontSize: 11, marginTop: 5, display: 'flex' }}>
                                        <div>**New manually entered price will impact the</div>
                                        <div style={{ marginLeft: 5, marginTop: 1 }}><img style={{ width: 80 }} alt='FourcastMatchLogo' src={fourcastMatchLogo} /></div>
                                        <div style={{ marginLeft: 5 }}>score.</div>
                                    </DialogContentText>
                                    <FormControl style={{ width: '90%' }}>
                                        <Input 
                                            startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                            type='number'
                                            category='customPrice'
                                            placeholder='Price per sq.ft. / yr'
                                            style={{ marginTop: 30, marginBottom: 30, width: '100%' }}
                                            onChange={(e) => {
                                                e.target.value === '' ? this.setState({ editedPrice: null }) : this.setState({ editedPrice: e.target.value });
                                            }}
                                        />
                                    </FormControl>
                                </DialogContent>
                                <DialogActions>
                                    <Button style={{ color: 'gray' }} onClick={(e) => this.setEditMode(false)}>Cancel</Button>
                                    <Button style={{ color: 'darkred' }} onClick={(e) => {
                                        const listingToEdit = this.state.selectedForEdit;
                                        let existingCustomPriceObjects = this.state.customPrices;
                                        let otherListingsExistingCustomPriceObj = existingCustomPriceObjects && existingCustomPriceObjects.length && existingCustomPriceObjects.filter(priceObj => priceObj._id !== listingToEdit._id);
                                        this.setState({ customPrices: existingCustomPriceObjects && existingCustomPriceObjects.length ? [...otherListingsExistingCustomPriceObj] : [] });
                                        this.setEditMode(false);
                                    }}>Discard Changes</Button>
                                    <Button onClick={(e) => {
                                        const price = parseFloat(this.state.editedPrice);
                                        const listingToEdit = this.state.selectedForEdit;
                                        let newCustomPriceObject = {_id: listingToEdit._id, price: price};
                                        let existingCustomPriceObjects = this.state.customPrices;
                                        let otherListingsExistingCustomPriceObj = existingCustomPriceObjects && existingCustomPriceObjects.length && existingCustomPriceObjects.filter(priceObj => priceObj._id !== listingToEdit._id);
                                        this.setState({ customPrices: existingCustomPriceObjects && existingCustomPriceObjects.length ? [...otherListingsExistingCustomPriceObj, newCustomPriceObject] : [newCustomPriceObject] });
                                        this.setEditMode(false);
                                    }}>Set price</Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    </td>
                </tr>
            )
        });


        // BAD BIZ RANKINGS !!!!!!!!!!!!!!!!!!!
        let filteredListingBadBiz = sortedByBadBizDeduction && sortedByBadBizDeduction.length && sortedByBadBizDeduction.map((listing) => {
            
            let filteredListingBadBizListByReviews = listing && listing.badBiz && listing.badBiz.badBizList && listing.badBiz.badBizList.sort((a, b) => {
                return b.review_count - a.review_count;
            }).map(biz => biz);
            let filteredListingBadBizListByDistance = listing && listing.badBiz && listing.badBiz.badBizList && listing.badBiz.badBizList.sort((a, b) => {
                return a.distance - b.distance;
            }).map(biz => biz);
            
            let desiredBadBizList = [...new Set(new Array(filteredListingBadBizListByDistance[0], filteredListingBadBizListByReviews[0]))];
            
            let filteredListingBadBizList = desiredBadBizList && desiredBadBizList.length && desiredBadBizList.map((badBiz) => {
                return (
                    <tr>
                        <td style={{ fontSize: 12, width: '33%', overflow: 'scroll' }}>
                            {badBiz && badBiz.name ? badBiz.name : <div style={{ textDecoration: 'italic' }}>None</div>}
                        </td>
                        <td>
                            {badBiz && badBiz.review_count && <YelpStarRating business={badBiz} />}
                        </td>
                        <td style={{ fontSize: 12 }}>
                            {badBiz && badBiz.review_count && badBiz.review_count}
                        </td>
                        <td style={{ fontSize: 12 }}>
                            {badBiz && badBiz.price && badBiz.price}
                        </td>
                        <td style={{ fontSize: 12 }}>
                            {badBiz && badBiz.categories && badBiz.categories.length && badBiz.categories[0].title && badBiz.categories[0].title}
                        </td>
                        <td style={{ fontSize: 12 }}>
                            {badBiz && badBiz.distance ? `${(badBiz.distance * 3.2808).toFixed(0)} (feet)` : null}
                        </td>
                    </tr>
                )
            });

            let totalBadBizNumber = listing && listing.badBiz && listing.badBiz.badBizList && listing.badBiz.badBizList.length;
            let badBizRanking = (listing, sortedByBadBizDeduction) => {
                let rank = 0;
                let deductionAmount = listing && listing.badBiz && listing.badBiz.badBizDeduction && listing.badBiz.badBizDeduction;
                let listingId = listing && listing.listingID && listing.listingID;
                let listOfDeductionAmounts = sortedByBadBizDeduction && sortedByBadBizDeduction.map(list => list && list.badBiz && list.badBiz.badBizDeduction && list.badBiz.badBizDeduction);
                let listOfIds = sortedByBadBizDeduction && sortedByBadBizDeduction.map(list => list && list.listingID && list.listingID);
                let indexNum = listOfDeductionAmounts && listOfDeductionAmounts.indexOf(deductionAmount);
                let indexIdNum = listOfIds && listOfIds.indexOf(listingId);
                let previousIndexAmt = listOfDeductionAmounts && listOfDeductionAmounts.length && listOfDeductionAmounts[indexNum - 1];

                if (indexNum === 0) {
                    if (indexIdNum === 0) {
                        rank = 1;
                    } else {
                        rank = '-';
                    }
                } else if (deductionAmount > previousIndexAmt) {
                    rank = indexNum + 1;
                } else {
                    rank = '-';
                }
                return rank;
            }

            return (
                <div style={{ position: 'relative', width: '98%', marginTop: 20 }}>
                    <div style={{ marginLeft: 10, display: 'flex', alignItems: 'end', width: '100%' }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>{listing && badBizRanking(listing, sortedByBadBizDeduction)}</div>
                        <div style={{ marginLeft: 10, fontSize: 14, fontWeight: 700, overflowY: 'scroll', width: '40%', display: 'flex' }}>
                            {listing && addressStyle(listing.address)}
                            {sortedByBadBizDeduction && sortedByBadBizDeduction.length && sortedByBadBizDeduction.indexOf(listing) === 0 ? <div style={{ display: 'flex', fontSize: 12, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', color: 'rgb(52, 181, 122)', marginLeft: 10 }}>Best</div> : null}
                        </div>
                        <div style={{ fontWeight: 700, fontSize: 16, color: 'darkred' }}><BizListModal bizList={listing.badBiz.badBizList} goodOrBad='bad' /></div>
                        <div style={{ marginLeft: -5, fontSize: 14, paddingBottom: 1 }}>bad businesses nearby</div>
                    </div>
                    {filteredListingBadBizList && filteredListingBadBizList.length ? (
                    <div style={{ fontSize: 12, padding: 10, width: '98%' }}>
                        <div style={{ paddingtop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Rating</th>
                                        <th>Reviews</th>
                                        <th>Price</th>
                                        <th>Category</th>
                                        <th>Distance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredListingBadBizList}
                                </tbody>
                            </Table>
                        </div>
                    </div>) : (
                    <div style={{ fontSize: 12, padding: 10, width: '98%' }}>
                        <div style={{ paddingtop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Rating</th>
                                        <th>Reviews</th>
                                        <th>Price</th>
                                        <th>Category</th>
                                        <th>Distance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        None
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>)}
                </div>
            )
        });


        // GOOD BIZ RANKINGS !!!!!!!!!!!!!!!!!!!
        let filteredListingGoodBiz = sortedByGoodBizAddition && sortedByGoodBizAddition.length && sortedByGoodBizAddition.map((listing) => {
            
            let filteredListingGoodBizListByReviews = listing && listing.goodBiz && listing.goodBiz.goodBizList && listing.goodBiz.goodBizList.sort((a, b) => {
                return b.review_count - a.review_count;
            }).map(biz => biz);
            let filteredListingGoodBizListByDistance = listing && listing.goodBiz && listing.goodBiz.goodBizList && listing.goodBiz.goodBizList.sort((a, b) => {
                return a.distance - b.distance;
            }).map(biz => biz);
            
            let desiredGoodBizList = [...new Set(new Array(filteredListingGoodBizListByDistance[0], filteredListingGoodBizListByReviews[0]))];
            
            let filteredListingGoodBizList = desiredGoodBizList && desiredGoodBizList.length && desiredGoodBizList.map((goodBiz) => {
                return (
                    <tr>
                        <td style={{ fontSize: 12, width: '33%', overflow: 'scroll' }}>
                            {goodBiz && goodBiz.name ? goodBiz.name : <div style={{ textDecoration: 'italic' }}>None</div>}
                        </td>
                        <td>
                            {goodBiz && goodBiz.review_count && <YelpStarRating business={goodBiz} />}
                        </td>
                        <td style={{ fontSize: 12 }}>
                            {goodBiz && goodBiz.review_count && goodBiz.review_count}
                        </td>
                        <td style={{ fontSize: 12 }}>
                            {goodBiz && goodBiz.price && goodBiz.price}
                        </td>
                        <td style={{ fontSize: 12 }}>
                            {goodBiz && goodBiz.categories && goodBiz.categories.length && goodBiz.categories[0].title && goodBiz.categories[0].title}
                        </td>
                        <td style={{ fontSize: 12 }}>
                            {goodBiz && goodBiz.distance ? `${(goodBiz.distance * 3.2808).toFixed(0)} (feet)` : null}
                        </td>
                    </tr>
                )
            });

            let totalGoodBizNumber = listing && listing.goodBiz && listing.goodBiz.goodBizList && listing.goodBiz.goodBizList.length;
            let goodBizRanking = (listing, sortedByGoodBizAddition) => {
                let rank = 0;
                let additionAmount = listing && listing.goodBiz && listing.goodBiz.goodBizAddition && listing.goodBiz.goodBizAddition;
                let listOfAdditionAmounts = sortedByGoodBizAddition && [...new Set(sortedByGoodBizAddition.map(list => list && list.goodBiz && list.goodBiz.goodBizAddition && list.goodBiz.goodBizAddition))];
                let indexNum = listOfAdditionAmounts && listOfAdditionAmounts.indexOf(additionAmount);
                let previousIndexAmt = listOfAdditionAmounts && listOfAdditionAmounts.length && listOfAdditionAmounts[indexNum - 1];

                if (indexNum === 0) {
                    rank = 1;
                } else if (additionAmount < previousIndexAmt) {
                    rank = indexNum + 1;
                } else {
                    rank = '-';
                }
                return rank;
            }

            return (
                <div style={{ position: 'relative', width: '98%', marginTop: 20 }}>
                    <div style={{ marginLeft: 10, display: 'flex', alignItems: 'end', width: '100%' }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>{listing && goodBizRanking(listing, sortedByGoodBizAddition)}</div>
                        <div style={{ marginLeft: 10, fontSize: 14, fontWeight: 700, overflowY: 'scroll', width: '40%', display: 'flex' }}>
                            {listing && addressStyle(listing.address)}
                            {sortedByGoodBizAddition && sortedByGoodBizAddition.length && sortedByGoodBizAddition.indexOf(listing) === 0 ? <div style={{ display: 'flex', fontSize: 12, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', color: 'rgb(52, 181, 122)', marginLeft: 10 }}>Best</div> : null}
                        </div>
                        <div style={{ fontWeight: 700, fontSize: 16, color: 'darkgreen' }}><BizListModal bizList={listing.goodBiz.goodBizList} goodOrBad='good' /></div>
                        <div style={{ marginLeft: -5, fontSize: 14, paddingBottom: 1 }}>good businesses nearby</div>
                    </div>
                    {filteredListingGoodBizList && filteredListingGoodBizList.length ? (
                    <div style={{ fontSize: 12, padding: 10, width: '98%' }}>
                        <div style={{ paddingtop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Rating</th>
                                        <th>Reviews</th>
                                        <th>Price</th>
                                        <th>Category</th>
                                        <th>Distance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredListingGoodBizList}
                                </tbody>
                            </Table>
                        </div>
                    </div>) : (
                    <div style={{ fontSize: 12, padding: 10, width: '98%' }}>
                        <div style={{ paddingtop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Rating</th>
                                        <th>Reviews</th>
                                        <th>Price</th>
                                        <th>Category</th>
                                        <th>Distance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        None
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>)}
                </div>
            )
        });


        // LISTINGS WITH FILTERABLES !!!!!!!!!!!!!!!!!!!!!
        let fullListingsByFilter = allListingInfo && allListingInfo.length && topListingsByFilter && topListingsByFilter.topByPrice && topListingsByFilter.topByPrice.length && topListingsByFilter.topByPrice.map((listing) => {
            const { allListingInfo } = this.state;
            const [thisFullListing] = allListingInfo && allListingInfo.length && allListingInfo.filter(gatheredListing => gatheredListing._id === listing._id);
            listing.attomData = thisFullListing && thisFullListing.attomData && thisFullListing.attomData;
            listing.oldCommData = thisFullListing && thisFullListing.oldCommData && thisFullListing.oldCommData;
            listing.scores.POI = thisFullListing && thisFullListing.scores && thisFullListing.scores.POI && thisFullListing.scores.POI;
            listing.nearbyPlaces = thisFullListing && thisFullListing.nearbyPlaces && thisFullListing.nearbyPlaces;
            listing.filterables = thisFullListing && thisFullListing.filterables && thisFullListing.filterables;
            listing.yelpBusinesses.totalBizCount = thisFullListing && thisFullListing.yelpData && thisFullListing.yelpData.total && thisFullListing.yelpData.total;
            return listing;
        });
        
        
        return (
            <div>
                <Modal style={{ minWidth: 'min-content' }} isOpen={((fourcastMatchResultComparisonModal === true))} toggle={this.props.setToggle}>
                    <ModalHeader toggle={this.toggle}>
                        <div style={{ display: 'flex' }}>
                            <div>Compare Fourcast Match Results</div>
                            {(allListingInfo && allListingInfo.length && (allListingInfo.length === listings.length)) ? 
                                (<div style={{ marginLeft: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 100 }}>
                                    <Button className='downloadReportBtn' style={{ backgroundColor: 'rgb(43, 150, 102)', color: 'white', width: 250, outline: 'none', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)' }} onClick={downloadPdfDocument} key={selectedListingNum} startIcon={downloaded.includes(selectedListingNum) ? <AiOutlineCheckCircle /> : <MdFileDownload />}>{downloaded.includes(selectedListingNum) ? 'Downloaded' : 'Download Comp'}</Button>
                                </div>
                            ) : null}
                        </div>
                    </ModalHeader>
                    <ModalBody style={{ minWidth: 'min-content', margin: 'auto', overflowY: 'scroll', maxHeight: 700, position: 'relative', display: 'flex', flexDirection: 'column', paddingBottom: 25, backgroundColor: 'whitesmoke' }}>
                    {(allListingInfo && allListingInfo.length && (allListingInfo.length === listings.length)) ? (
                        <>
                        <div id='sizeAndPriceMatchComparison'>
                            <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 50, position: 'relative' }}>
                                <div style={{ position: 'absolute', top: '2%', left: '2.5%' }}><img style={{ width: 125 }} alt='FourcastMatchLogo' src={fourcastMatchLogo} /></div>
                                <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                                <div style={{ marginTop: 15, display: 'flex', justifyContent: 'center' }}>
                                    <div style={{ position: 'relative', width: '98%' }}>
                                        <div>
                                            <div>
                                                {this.state.loading ? (<CircularProgress size={20} thickness={3} />) : (<Table style={{ tableLayout: 'fixed', marginTop: 50 }}>
                                                    <thead>
                                                        <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                                            <th style={{ width: '10%' }}></th>
                                                            {sortedByFourcastScore && sortedByFourcastScore.map(listing => {return (<th key={listing._id} style={{ padding: 10, color: 'darkgreen', position: 'relative' }}><div style={{ fontSize: 11, paddingBottom: 5, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{listing && listing.neighborhood}</div><Divider variant="middle" /><div style={{ fontSize: 12, paddingTop: 10, height: 50, display: 'flex', justifyContent: 'center', alignItems: 'start', overflowY: 'scroll' }}>{addressStyle(listing.listingAddress)}</div></th>)})}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                                            <th style={{ fontSize: 12, fontWeight: 800, width: '10%', color: 'rgb(21, 134, 84)' }}>
                                                                Fourcast Match
                                                            </th>
                                                            {filteredListingFourcastMatchScores}
                                                        </tr>
                                                        <tr style={{ border: '1px solid #96D4D4', textAlign: 'center', backgroundColor: 'whitesmoke' }}>
                                                            <th style={{ fontSize: 11, width: '10%' }}>
                                                                Regular Score
                                                            </th>
                                                            {filteredListingFourcastScores}
                                                        </tr>
                                                    </tbody>
                                                </Table>)}
                                            </div>
                                            <Divider variant="middle" style={{ marginTop: 100 }}>Price & Size</Divider>
                                            <div style={{ display: 'flex', marginTop: 75, justifyContent: 'space-around' }}>
                                                <div>
                                                    <div style={{ width: '95%' }}>
                                                        <div style={{ marginTop: 15, marginBottom: 10, marginLeft: 10, display: 'flex', alignItems: 'center' }}>
                                                            <div style={{ fontSize: 14, fontWeight: 500 }}>
                                                                MIN sqft.
                                                            </div>
                                                            <div style={{ marginLeft: 5, fontSize: 16, fontWeight: 600, color: 'darkgreen' }}>
                                                                {customSearchFilters && customSearchFilters.minSize && customSearchFilters.minSize}
                                                            </div>
                                                        </div>
                                                        <div style={{ maxWidth: 250, height: 100, paddingLeft: 10 }}>
                                                            <Stepper orientation="vertical">
                                                                <Step key={1} completed={true} style={{ fontSize: 11, height: 35 }}>
                                                                    <StepLabel 
                                                                        icon={<MdFiberManualRecord style={{ height: 15, width: 15, marginLeft: 5, color: 'darkgreen' }} />}
                                                                        optional={
                                                                            <div style={{ display: 'flex' }}>
                                                                                <div style={{ fontSize: 11, fontWeight: 600 }}>largest</div>
                                                                                <div style={{ fontSize: 11, fontWeight: 600, marginLeft: 5, color: 'darkgreen' }}>(best)</div>
                                                                            </div>
                                                                        }
                                                                    >
                                                                    </StepLabel>
                                                                </Step>
                                                                <Step key={2} disabled={true} style={{ fontSize: 11, height: 35 }}>
                                                                    <StepLabel 
                                                                        icon={<MdFiberManualRecord style={{ height: 15, width: 15, marginLeft: 5 }} />}
                                                                        optional={
                                                                            <div style={{ display: 'flex' }}>
                                                                                <div style={{ fontSize: 11, fontWeight: 600 }}>smallest</div>
                                                                            </div>
                                                                        }
                                                                    >
                                                                    </StepLabel>
                                                                </Step>
                                                            </Stepper>
                                                        </div>
                                                        <Table style={{ tableLayout: 'fixed', border: '1px solid gray', marginLeft: 'auto', marginRight: 'auto' }}>
                                                            <thead>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th style={{ width: '5%' }}></th>
                                                                    <th style={{ width: '55%' }}></th>
                                                                    <th style={{ width: '40%', fontSize: 11  }}>sq. ft.</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {filteredListingMinSize}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div style={{ width: '95%' }}>
                                                        <div style={{ marginTop: 15, marginBottom: 10, marginLeft: 10, display: 'flex', alignItems: 'center' }}>
                                                            <div style={{ fontSize: 14, fontWeight: 500 }}>
                                                                MAX sqft.
                                                            </div>
                                                            <div style={{ marginLeft: 5, fontSize: 16, fontWeight: 600, color: 'darkgreen' }}>
                                                                {customSearchFilters && customSearchFilters.maxSize && customSearchFilters.maxSize}
                                                            </div>
                                                        </div>
                                                        <div style={{ maxWidth: 250, height: 100, paddingLeft: 10 }}>
                                                            <Stepper orientation="vertical">
                                                                <Step key={1} completed={true} style={{ fontSize: 11, height: 35 }}>
                                                                    <StepLabel 
                                                                        icon={<MdFiberManualRecord style={{ height: 15, width: 15, marginLeft: 5, color: 'darkgreen' }} />}
                                                                        optional={
                                                                            <div style={{ display: 'flex' }}>
                                                                                <div style={{ fontSize: 11, fontWeight: 600 }}>smallest</div>
                                                                                <div style={{ fontSize: 11, fontWeight: 600, marginLeft: 5, color: 'darkgreen' }}>(best)</div>
                                                                            </div>
                                                                        }
                                                                    >
                                                                    </StepLabel>
                                                                </Step>
                                                                <Step key={2} disabled={true} style={{ fontSize: 11, height: 35 }}>
                                                                    <StepLabel 
                                                                        icon={<MdFiberManualRecord style={{ height: 15, width: 15, marginLeft: 5 }} />}
                                                                        optional={
                                                                            <div style={{ display: 'flex' }}>
                                                                                <div style={{ fontSize: 11, fontWeight: 600 }}>largest</div>
                                                                            </div>
                                                                        }
                                                                    >
                                                                    </StepLabel>
                                                                </Step>
                                                            </Stepper>
                                                        </div>
                                                        <Table style={{ tableLayout: 'fixed', border: '1px solid gray', marginLeft: 'auto', marginRight: 'auto' }}>
                                                            <thead>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th style={{ width: '5%' }}></th>
                                                                    <th style={{ width: '55%' }}></th>
                                                                    <th style={{ width: '40%', fontSize: 11  }}>sq. ft.</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {filteredListingMaxSize}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div style={{ width: '95%' }}>
                                                        <div style={{ marginTop: 15, marginBottom: 10, marginLeft: 10, display: 'flex', alignItems: 'center' }}>
                                                            <div style={{ fontSize: 14, fontWeight: 500 }}>
                                                                BUDGET
                                                            </div>
                                                            <div style={{ display: 'flex' }}>
                                                                <div style={{ marginLeft: 5, fontSize: 16, fontWeight: 600, color: 'darkgreen' }}>
                                                                    {`$${parseFloat(customSearchFilters && customSearchFilters.price && customSearchFilters.price).toLocaleString("en-US")}`}
                                                                </div>
                                                                <div style={{ marginLeft: 5, marginTop: 2, fontSize: 14, fontWeight: 600 }}>
                                                                    {customSearchFilters && customSearchFilters.budgetType && (customSearchFilters.budgetType === 1) ? 'total / yr' : 'per sqft / yr'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div style={{ maxWidth: 250, height: 100, paddingLeft: 10 }}>
                                                            <Stepper orientation="vertical">
                                                                <Step key={1} completed={true} style={{ fontSize: 11, height: 35 }}>
                                                                    <StepLabel 
                                                                        icon={<MdFiberManualRecord style={{ height: 15, width: 15, marginLeft: 5, color: 'darkgreen' }} />}
                                                                        optional={
                                                                            <div style={{ display: 'flex' }}>
                                                                                <div style={{ fontSize: 11, fontWeight: 600 }}>low</div>
                                                                                <div style={{ fontSize: 11, fontWeight: 600, marginLeft: 5, color: 'darkgreen' }}>(best)</div>
                                                                            </div>
                                                                        }
                                                                    >
                                                                    </StepLabel>
                                                                </Step>
                                                                <Step key={2} disabled={true} style={{ fontSize: 11, height: 35 }}>
                                                                    <StepLabel 
                                                                        icon={<MdFiberManualRecord style={{ height: 15, width: 15, marginLeft: 5 }} />}
                                                                        optional={
                                                                            <div style={{ display: 'flex' }}>
                                                                                <div style={{ fontSize: 11, fontWeight: 600 }}>high</div>
                                                                            </div>
                                                                        }
                                                                    >
                                                                    </StepLabel>
                                                                </Step>
                                                            </Stepper>
                                                        </div>
                                                        <Table style={{ tableLayout: 'fixed', border: '1px solid gray', marginLeft: 'auto', marginRight: 'auto' }}>
                                                            <thead>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th style={{ width: '5%' }}></th>
                                                                    <th style={{ width: '50%' }}></th>
                                                                    <th style={{ width: '45%', fontSize: 11  }}>$ / sqft</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.loading ? (<CircularProgress size={20} thickness={3} />) : filteredListingPrice}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                        </div>
                        <div id='badBizMatchComparison' style={{ marginTop: 25 }}>
                            <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                                <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ position: 'relative', width: '98%' }}>
                                        <div>
                                            <div style={{ marginLeft: 20, display: 'flex', alignItems: 'center' }}>
                                                <div style={{ fontSize: 14, paddingRight: 5 }}>
                                                    BAD neighboring businesses:
                                                </div>
                                                {customSearchFilters && customSearchFilters.badBiz && customSearchFilters.badBiz.map(badBizType => (
                                                    <div style={{ marginLeft: 5, fontSize: 16, fontWeight: 600, color: 'darkred' }}>{((customSearchFilters.badBiz && customSearchFilters.badBiz.indexOf(badBizType)) === (customSearchFilters.badBiz.length - 1)) ? ` ${badBizType.label}` : ` ${badBizType.label},`}</div>
                                                ))}
                                            </div>
                                            <Divider variant="middle"></Divider>
                                            <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '100%', marginTop: -19, paddingBottom: 5 }}>
                                                {filteredListingBadBiz}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                        </div>
                        <div id='goodBizMatchComparison' style={{ marginTop: 25 }}>
                            <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                                <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ position: 'relative', width: '98%' }}>
                                        <div>
                                            <div style={{ marginLeft: 20, display: 'flex', alignItems: 'center' }}>
                                                <div style={{ fontSize: 14, paddingRight: 5 }}>
                                                    GOOD neighboring businesses:
                                                </div>
                                                {customSearchFilters && customSearchFilters.goodBiz && customSearchFilters.goodBiz.map(goodBizType => (
                                                    <div style={{ marginLeft: 5, fontSize: 16, fontWeight: 600, color: 'darkgreen' }}>{((customSearchFilters.goodBiz && customSearchFilters.goodBiz.indexOf(goodBizType)) === (customSearchFilters.goodBiz.length - 1)) ? ` ${goodBizType.label}` : ` ${goodBizType.label},`}</div>
                                                ))}
                                            </div>
                                            <Divider variant="middle"></Divider>
                                            <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', width: '100%', marginTop: -10, paddingBottom: 5 }}>
                                                {filteredListingGoodBiz}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Paper>
                        </div>
                        {customSearchFilters && customSearchFilters.compFilters && customSearchFilters.compFilters.length && (customSearchFilters.compFilters.includes('lowEndBiz') || customSearchFilters.compFilters.includes('highEndBiz')) ? (
                            <div id='highEndBizAreaMatchComparison' style={{ marginTop: 25 }}>
                                <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                                    <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', width: '98%' }}>
                                            {this.state.loading ? 'Loading' : <HighEndMatchComparison listings={fullListingsByFilter} inputValueTitle={customSearchFilters && customSearchFilters.compFilters && customSearchFilters.compFilters.length && customSearchFilters.compFilters.includes('lowEndBiz') ? '$' : '$$$$'} />}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        ) : null}
                        {customSearchFilters && customSearchFilters.compFilters && customSearchFilters.compFilters.length && (customSearchFilters.compFilters.includes('lowBizDensity') || customSearchFilters.compFilters.includes('highBizDensity')) ? (
                            <div id='bizDensityAreaMatchComparison' style={{ marginTop: 25 }}>
                                <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                                    <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', width: '98%' }}>
                                            {this.state.loading ? 'Loading' : <BizDensityMatchComparison listings={fullListingsByFilter} inputValueTitle={customSearchFilters && customSearchFilters.compFilters && customSearchFilters.compFilters.length && customSearchFilters.compFilters.includes('lowBizDensity') ? 'Low' : 'High'} />}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        ) : null}
                        {customSearchFilters && customSearchFilters.trafficFilters && customSearchFilters.trafficFilters.length && (customSearchFilters.trafficFilters.includes('nearAttraction') || customSearchFilters.demoFilters.includes('nearAttraction')) ? (
                            <div id='majorAttractionMatchComparison' style={{ marginTop: 25 }}>
                                <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                                    <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', width: '98%' }}>
                                            {this.state.loading ? 'Loading' : <MajorAttractionMatchComparison listings={fullListingsByFilter} />}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        ) : null}
                        {customSearchFilters && customSearchFilters.trafficFilters && customSearchFilters.trafficFilters.length && (customSearchFilters.trafficFilters.includes('busyWeekend') || customSearchFilters.trafficFilters.includes('busyMonThruFri')) ? (
                            <div id='weekdayWeekendMatchComparison' style={{ marginTop: 25 }}>
                                <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                                    <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', width: '98%' }}>
                                            {this.state.loading ? 'Loading' : <WeekdayWeekendMatchComparison listings={fullListingsByFilter} inputValueTitle={customSearchFilters && customSearchFilters.trafficFilters && customSearchFilters.trafficFilters.length && customSearchFilters.trafficFilters.includes('busyWeekend') ? 'Weekend' : 'Mon-Fri'} />}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        ) : null}
                        {customSearchFilters && customSearchFilters.trafficFilters && customSearchFilters.trafficFilters.length && (customSearchFilters.trafficFilters.includes('busyNightLife')) ? (
                            <div id='busyNightLifeMatchComparison' style={{ marginTop: 25 }}>
                                <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                                    <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', width: '98%' }}>
                                            {this.state.loading ? 'Loading' : <PopularTimesMatchComparison listings={fullListingsByFilter} inputValueTitle={customSearchFilters && customSearchFilters.trafficFilters && customSearchFilters.trafficFilters.length && customSearchFilters.trafficFilters.includes('busyNightLife') ? 'Nightlife' : 'Daytime'} />}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        ) : null}
                        {customSearchFilters && customSearchFilters.trafficFilters && customSearchFilters.trafficFilters.length && (customSearchFilters.trafficFilters.includes('busyDayTime')) ? (
                            <div id='busyDayTimeMatchComparison' style={{ marginTop: 25 }}>
                                <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                                    <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', width: '98%' }}>
                                            {this.state.loading ? 'Loading' : <PopularTimesMatchComparison listings={fullListingsByFilter} inputValueTitle={customSearchFilters && customSearchFilters.trafficFilters && customSearchFilters.trafficFilters.length && customSearchFilters.trafficFilters.includes('busyDayTime') ? 'Daytime' : 'Nightlife'} />}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        ) : null}
                        {customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && (customSearchFilters.demoFilters.includes('students')) ? (
                            <div id='studentsMatchComparison' style={{ marginTop: 25 }}>
                                <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative', overflowY: 'scroll' }}>
                                    <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', width: '98%' }}>
                                            {this.state.loading ? 'Loading' : <CommonPeopleMatchComparison listings={fullListingsByFilter} inputValueTitle={customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && customSearchFilters.demoFilters.includes('students') ? 'Students' : null} />}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        ) : null}
                        {customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && (customSearchFilters.demoFilters.includes('officeWorkers')) ? (
                            <div id='officeWorkersMatchComparison' style={{ marginTop: 25 }}>
                                <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative', overflowY: 'scroll' }}>
                                    <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', width: '98%' }}>
                                            {this.state.loading ? 'Loading' : <CommonPeopleMatchComparison listings={fullListingsByFilter} inputValueTitle={customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && customSearchFilters.demoFilters.includes('officeWorkers') ? 'Workers' : null} />}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        ) : null}
                        {customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && (customSearchFilters.demoFilters.includes('localResidents')) ? (
                            <div id='localResidentsMatchComparison' style={{ marginTop: 25 }}>
                                <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative', overflowY: 'scroll' }}>
                                    <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', width: '98%' }}>
                                            {this.state.loading ? 'Loading' : <CommonPeopleMatchComparison listings={fullListingsByFilter} inputValueTitle={customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && customSearchFilters.demoFilters.includes('localResidents') ? 'Residents' : null} />}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        ) : null}
                        {customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && (customSearchFilters.demoFilters.includes('areasWithChildren')) ? (
                            <div id='areasWithChildrenMatchComparison' style={{ marginTop: 25 }}>
                                <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative', overflowY: 'scroll' }}>
                                    <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', width: '98%' }}>
                                            {this.state.loading ? 'Loading' : <AreasWithChildrenMatchComparison listings={fullListingsByFilter} inputValueTitle={customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && customSearchFilters.demoFilters.includes('areasWithChildren') ? 'Families With Children' : null} />}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        ) : null}
                        {customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && (customSearchFilters.demoFilters.includes('genZ')) ? (
                            <div id='genZMatchComparison' style={{ marginTop: 25 }}>
                                <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative', overflowY: 'scroll' }}>
                                    <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', width: '98%' }}>
                                            {this.state.loading ? 'Loading' : <AgesMatchComparison listings={fullListingsByFilter} inputValueTitle={customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && customSearchFilters.demoFilters.includes('genZ') ? 'Gen Z' : null} />}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        ) : null}
                        {customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && (customSearchFilters.demoFilters.includes('millennialsTo34')) ? (
                            <div id='millennialsTo34MatchComparison' style={{ marginTop: 25 }}>
                                <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative', overflowY: 'scroll' }}>
                                    <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', width: '98%' }}>
                                            {this.state.loading ? 'Loading' : <AgesMatchComparison listings={fullListingsByFilter} inputValueTitle={customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && customSearchFilters.demoFilters.includes('millennialsTo34') ? 'Young Millennials' : null} />}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        ) : null}
                        {customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && (customSearchFilters.demoFilters.includes('millennialsTo44')) ? (
                            <div id='millennialsTo44MatchComparison' style={{ marginTop: 25 }}>
                                <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative', overflowY: 'scroll' }}>
                                    <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', width: '98%' }}>
                                            {this.state.loading ? 'Loading' : <AgesMatchComparison listings={fullListingsByFilter} inputValueTitle={customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && customSearchFilters.demoFilters.includes('millennialsTo44') ? 'Older Millennials' : null} />}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        ) : null}
                        {customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && (customSearchFilters.demoFilters.includes('genX')) ? (
                            <div id='genXMatchComparison' style={{ marginTop: 25 }}>
                                <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative', overflowY: 'scroll' }}>
                                    <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', width: '98%' }}>
                                            {this.state.loading ? 'Loading' : <AgesMatchComparison listings={fullListingsByFilter} inputValueTitle={customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && customSearchFilters.demoFilters.includes('genX') ? 'Gen X' : null} />}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        ) : null}
                        {customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && (customSearchFilters.demoFilters.includes('boomers')) ? (
                            <div id='boomersMatchComparison' style={{ marginTop: 25 }}>
                                <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative', overflowY: 'scroll' }}>
                                    <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', width: '98%' }}>
                                            {this.state.loading ? 'Loading' : <AgesMatchComparison listings={fullListingsByFilter} inputValueTitle={customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && customSearchFilters.demoFilters.includes('boomers') ? 'Boomers' : null} />}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        ) : null}
                        {customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && (customSearchFilters.demoFilters.includes('highIncomeArea')) ? (
                            <div id='highIncomeAreaMatchComparison' style={{ marginTop: 25, width: '98%' }}>
                                <IncomeMatchComparison loading={this.state.loading ? true : false} listings={fullListingsByFilter} inputValueTitle={customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && customSearchFilters.demoFilters.includes('highIncomeArea') ? 'Higher Income Area' : null} />
                            </div>
                        ) : null}
                        {customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && (customSearchFilters.demoFilters.includes('affordableRE')) ? (
                            <div id='affordableREMatchComparison' style={{ marginTop: 25 }}>
                                <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative', overflowY: 'scroll' }}>
                                    <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', width: '98%' }}>
                                            {this.state.loading ? 'Loading' : <ResPricesMatchComparison listings={fullListingsByFilter} inputValueTitle={customSearchFilters && customSearchFilters.demoFilters && customSearchFilters.demoFilters.length && customSearchFilters.demoFilters.includes('affordableRE') ? 'Affordable Area' : null} />}
                                        </div>
                                    </div>
                                </Paper>
                            </div>
                        ) : null}
                        </>
                        ) : <div style={{ width: '210mm', height: '297mm' }}><LinearProgress /></div>}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

FourcastMatchResultComparisonModal.propTypes = {
    getListing: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    thisListing: state.listing.thisListing,
});

export default connect(mapStateToProps, { getListing })(FourcastMatchResultComparisonModal);
