import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Image } from 'react-bootstrap';
import { Button, Menu, MenuItem, Container, AppBar, Box, Toolbar, Divider } from '@mui/material';
import { AiOutlineMenu } from 'react-icons/ai';
import { styled, alpha } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import fourcastShortLogo from '../../Assets/fourcast_short_logo_white.png';
import fourcastMatchLogo from '../../Assets/fourcast_match_logo_black.png';
import Logout from '../auth/logout';
import LoginModal from '../auth/loginModal';
import RegisterModal from '../auth/registerModal';
import './navbar.css';
import SavedModal from '../savedProperties/savedModal';


 
class MobileNavbar extends Component { 
    
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null
        }
    }
    
    static propTypes = {
        auth: PropTypes.object.isRequired
    }
    
    render() { 

      const { user, isAuthenticated, paymentInfoNeeded } = this.props.auth;
      const { auth } = this.props;
      

      const MobileStyledMenu = styled((props) => (
          <Menu
            elevation={0}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            {...props}
          />
        ))(({ theme }) => ({
          '& .MuiPaper-root': {
            borderRadius: 2,
            marginTop: theme.spacing(2.5),
            minWidth: '100%',
            minHeight: '50%',
            textAlign: 'right',
            backgroundColor: 'whitesmoke',
            color:
              theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
            boxShadow:
              'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
            '& .MuiMenu-list': {
              padding: '4px 0',
            },
            '& .MuiMenuItem-root': {
              '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(0),
              },
              '&:active': {
                backgroundColor: alpha(
                  theme.palette.primary.main,
                  theme.palette.action.selectedOpacity,
                ),
              },
            },
          },
      }));

      const mobileOpen = Boolean(this.state.mobileAnchorEl);
      const { mobileAnchorEl } = this.state;
      const handleMobileClick = (event) => {
          this.setState({ mobileAnchorEl: event.currentTarget });
      };
      const handleMobileClose = () => {
          this.setState({ mobileAnchorEl: false });
      };


      return ( 
        <div> 
            <AppBar position="static">
              <Container id='mobileNavbarBackground' maxWidth="xl">
                <Toolbar disableGutters>
                  <div className='navbar-fourcast-logo'>
                      <Link to='/' className='router-link-exact-active router-link-active'>
                          <Image src={fourcastShortLogo} style={{ height: 40 }} />
                      </Link>
                  </div>

                  <Box sx={{ flexGrow: 0, marginLeft: 'auto' }}>
                    <div>
                        <div style={{ justifyContent: 'center', display: 'flex' }}>
                            <Button
                                id="basic-button"
                                aria-controls={mobileOpen ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={mobileOpen ? 'true' : undefined}
                                onClick={handleMobileClick}
                                variant='link'
                                style={{ color: 'white', outline: 'none', textDecoration: 'none', fontSize: 13, marginTop: 2.5 }}
                            >
                                <AiOutlineMenu style={{ width: '90%', height: '90%' }} />
                            </Button>
                            <MobileStyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                                }}
                                anchorEl={mobileAnchorEl}
                                open={mobileOpen}
                                onClose={handleMobileClose}
                                style={{ width: '90%', zIndex: 4 }}
                            >
                                <MenuItem style={{ width: '100%', height: 50, fontWeight: 'bold' }}>
                                  <div className='navbar-item' style={{ justifyContent: 'center', display: 'flex', color: 'black', outline: 'none', textDecoration: 'none', fontSize: 13, marginTop: 2.5 }}>
                                    <Link style={{ textDecoration: 'none', color: 'black', fontSize: 13 }} to={`/listings`}>Browse Listings</Link>
                                  </div>
                                </MenuItem>
                                <Divider variant="middle" />
                                <MenuItem style={{ width: '100%', height: 50, fontWeight: 'bold' }}>
                                  <div style={{ justifyContent: 'center', display: 'flex', color: 'black', outline: 'none', textDecoration: 'none', fontSize: 13, marginTop: 2.5 }}>
                                    <Link to='/customSearch' style={{ textDecoration: 'none', color: 'black', fontWeight: 'bolder', fontSize: 13 }}><Image src={fourcastMatchLogo} style={{ height: 25, marginTop: 5 }} /></Link>
                                  </div>
                                </MenuItem>
                                <Divider variant="middle" />
                                <MenuItem style={{ width: '100%', height: 50, fontWeight: 'bold' }}>
                                  <div style={{ justifyContent: 'center', display: 'flex', color: 'black', outline: 'none', textDecoration: 'none', fontSize: 13, marginTop: 2.5 }}>
                                    <Link style={{ textDecoration: 'none', color: 'black', fontSize: 13 }} to={`/reports`}>Reports</Link>
                                  </div>
                                </MenuItem>
                                <Divider variant="middle" />
                                <MenuItem style={{ width: '100%', height: 50, fontWeight: 'bold', color: 'black' }}>
                                  <div style={{ justifyContent: 'center', display: 'flex', color: 'black', outline: 'none', textDecoration: 'none', fontSize: 13, marginTop: 2.5 }}>
                                      {user ? 
                                          <Link style={{ textDecoration: 'none', color: 'black', fontSize: 13 }} to={`/user/${user._id}`}>
                                              <SavedModal />
                                          </Link> : (
                                          <Link style={{ textDecoration: 'none', color: 'black', fontSize: 13 }}>
                                              <LoginModal linkText='Saved' />
                                          </Link>
                                      )}
                                  </div>
                                </MenuItem>
                                <Divider variant="middle" />
                                <MenuItem style={{ width: '100%', height: 50, fontWeight: 'bold' }}>
                                <div style={{ justifyContent: 'center', display: 'flex', color: 'black', outline: 'none', textDecoration: 'none', fontSize: 13, marginTop: 2.5 }}>
                                      {user ? 
                                          <Link style={{ textDecoration: 'none', color: 'black', fontWeight: 'bolder', fontSize: 13 }} to={`/user/${user._id}`}>
                                              SUBMIT LISTINGS
                                          </Link> : (
                                          <Link style={{ textDecoration: 'none', color: 'black', fontWeight: 'bolder', fontSize: 13 }}>
                                              <LoginModal linkText='Submit Listings' />
                                          </Link>
                                      )}
                                  </div>
                                </MenuItem>
                                <Divider variant="middle" />
                                <MenuItem style={{ width: '100%', height: 50, fontWeight: 'bold' }}>
                                  { isAuthenticated ? (
                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
                                      {user ? 
                                        <Link style={{ color: 'black', textDecoration: 'none', fontSize: 13 }} to={user.id !== undefined ? `/user/${user.id}` : `/user/${user._id}`}>
                                            My Account
                                        </Link> : null}
                                      
                                    </div>) : 
                                    (<Link style={{ textDecoration: 'none', color: 'black', fontSize: 13 }}>
                                        <RegisterModal style={{ color: 'black' }} />
                                    </Link>
                                  )}
                                </MenuItem>
                                <Divider variant="middle" />
                                <MenuItem style={{ width: '100%', height: 50, fontWeight: 'bold' }} autoFocus>
                                  { isAuthenticated ?
                                    (<div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 2, justifyContent: 'center', alignItems: 'center' }}>
                                      {user ? 
                                        (<Link style={{ textDecoration: 'none', color: 'black', fontSize: 13 }}>
                                          <Logout />
                                        </Link>) : null}
                                    </div>) : 
                                    (<Link style={{ textDecoration: 'none', color: 'black', fontSize: 13 }}>
                                        <LoginModal linkText='LOG IN' />
                                      </Link>
                                    )
                                  }
                                </MenuItem>
                            </MobileStyledMenu>
                        </div>
                    </div>
                  </Box>
                </Toolbar>
              </Container>
            </AppBar>
            {(isAuthenticated && paymentInfoNeeded) ? <Redirect to={{
                pathname: '/registration/payment-method',
                state: { token: auth.token }
            }} /> : null}
        </div>
      ); 
    }
}

const mapStateToProps = state => ({
    auth: state.auth
})
 
export default connect(mapStateToProps, null)(MobileNavbar);