import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { 
    Container,
    Table,
    Row, 
    Label, 
    Col, 
    Form, 
    FormGroup, 
    Input } 
from 'reactstrap';
import { ImCheckmark, ImCross } from 'react-icons/im';
import { Button } from '@material-ui/core';


class ListingsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            address: null,
            selected: null,
        }
    }

    onChange = (e) => {
        let thisAddress = e.target.value;
        this.setState({ address: thisAddress });
    }

    render() {
        
        const { thisUsersListings, loading } = this.props;
        
        let filteredListings = thisUsersListings && thisUsersListings.filter((listing) => {
            if (this.state.address == null) {
                return listing
            } else if (listing.listingAddress.toLowerCase().includes(this.state.address.toLowerCase())) {
                return listing
            } 
        }).map((listing) => {
            return (
                <tr>
                    <th style={{ fontSize: 12 }}>
                    {listing.listingAddress}
                    </th>
                    <td style={{ fontSize: 12 }}>
                        {listing.createdAt ? moment(listing.createdAt).fromNow() : 'null'}
                    </td>
                    <td style={{ fontSize: 12 }}>
                        {moment(listing.updatedAt).fromNow()}
                    </td>
                    <td style={{ fontSize: 12 }}>
                        {listing.squareFeet.length > 1 ? `${listing.squareFeet[0]} - ${listing.squareFeet[1]}` : `${listing.squareFeet}`}
                    </td>
                    <td style={{ fontSize: 12 }}>
                        {listing.pricePerSquareFoot !== -1 ? `$${listing.pricePerSquareFoot}` : 'Undisclosed'}
                    </td>
                    <td style={{ fontSize: 12, textAlign: 'center' }}>
                        {listing.active ? <ImCheckmark style={{ color: 'darkgreen' }} /> : <ImCross style={{ color: 'darkred' }} />}
                    </td>
                    <td>
                        <Link style={{ textDecoration: 'none' }} to={`/user/editListing/${listing._id}`}>
                            <Button variant="contained" size="small" style={{ color: 'white', backgroundColor: 'rgb(52, 129, 181)', outline: 'none' }}>Manage</Button>
                        </Link>
                    </td>
                </tr>
            )
        });
        
        return (
            <Container>
                <Row>
                    <Col sm={2}>
                        <Form>
                            <FormGroup style={{ position: 'fixed', marginLeft: -50, marginTop: 30 }}>
                                <Label style={{ fontSize: 18, fontWeight: 'bold', color: 'green' }} for='address'>Search By Address</Label>
                                <Input 
                                    type='text'
                                    category='address'
                                    placeholder='Find...'
                                    onChange={this.onChange}
                                />
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col sm={10}>
                        <Table style={{ marginTop: 20 }}>
                            <thead>
                                <tr>
                                    <th>Listing Address</th>
                                    <th>Created</th>
                                    <th>Last Updated</th>
                                    <th>Size</th>
                                    <th>Price</th>
                                    <th>Active</th>
                                    <th> </th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? 'Loading...' : thisUsersListings.length > 0 ? filteredListings : (<div style={{ paddingTop: 10, fontSize: 14 }}>You Have Not Submitted Any Listings</div>)}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        );
    }

}


export default ListingsList;