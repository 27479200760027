import React, { Component } from 'react';
import { 
    Label,
    Row,
    Col, 
    Form, 
    FormGroup }
from 'reactstrap';
import { Card, Button } from '@material-ui/core';
import { FormControl, InputLabel, MenuItem, Alert, TextField, Select, OutlinedInput, InputAdornment, FormHelperText } from '@mui/material';
import './createListing.css';

class CreateListingInput extends Component {
    
    state = { 
        streetAddress: this.props.values.streetAddress,
        city: this.props.values.city,
        state: this.props.values.state,
        zip: this.props.values.zip,
        squareFeet1: this.props.values.squareFeet1,
        squareFeet2: this.props.values.squareFeet2,
        pricePerSquareFoot: this.props.values.pricePerSquareFoot,
        frontage: this.props.values.frontage,
        propertyFeatures: this.props.values.propertyFeatures,
        images: this.props.values.images,
        addRange: false,
        sfError: false
    }

    continue = e => {
        
        const { values } = this.props;

        const checkSfError = (values.squareFeet1 === null || values.squareFeet1.length === 0) ? this.setState({ sfError: true }) : this.props.nextStep();
        const checkDivisible = ((values.squareFeet1 !== null && values.squareFeet1.length > 0) && (values.squareFeet2 !== null && values.squareFeet2.length > 0) && (!this.state.propertyFeatures.includes('divisible'))) ? values.propertyFeatures.push('divisible') : null;

        const setFeaturesNow = !this.state.sfError ? this.props.setFeatures(this.state.propertyFeatures) : null;
        const setImagesNow = !this.state.sfError ? this.props.setImages(this.state.images) : null;
    }

    render() {
        
        const { values, stateAbbr, cityAbbr, selectChange, onChange } = this.props;


        return(
            <>
            <Card className="addListing" style={{ textAlign: 'center' }}>
                { this.state.msg ? (
                    <Alert severity='error' variant='outlined'>{this.state.msg}</Alert>
                ) : null}
                <div style={{ width: '100%', margin: '0px auto' }}>
                    <Form>
                        <FormGroup style={{ width: '90%', margin: '10px auto' }} row>
                            <TextField required sx={{ width: '100%' }} variant='outlined' name="streetAddress" helperText='E.g. 123 Main Street' label='Street Address' onChange={onChange} value={this.state.streetAddress} />
                        </FormGroup>
                        <Row style={{ width: '90%', margin: '40px auto' }}>
                            <div style={{ display: 'grid', gridTemplateColumns: '2fr 2fr 2fr', gap: 15 }}>
                                <div style={{ textAlign: 'left' }}>
                                    <FormControl required sx={{ m: 'auto', minWidth: 150 }}>
                                        <InputLabel id="citySelection">City</InputLabel>
                                        <Select
                                            name="city"
                                            label="City"
                                            defaultValue={this.state.city}
                                            onChange={selectChange("city")}
                                        >
                                            {cityAbbr.map(city => <MenuItem value={city.value}>{`${city.label}`}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <FormControl required sx={{ m: 'auto', minWidth: 150 }}>
                                        <InputLabel id="stateSelection">State</InputLabel>
                                        <Select
                                            name="state"
                                            label="State"
                                            defaultValue={this.state.state}
                                            onChange={selectChange("state")}
                                        >
                                            {stateAbbr.map(state => <MenuItem value={state.value}>{`${state.label}`}</MenuItem>)}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div style={{ textAlign: 'left' }}>
                                    <FormGroup style={{ width: '100%', margin: 'auto' }} row>
                                        <TextField required type="number" variant='outlined' name="zip" label='Zip Code' onChange={onChange} value={this.state.zip} />
                                    </FormGroup>
                                </div>
                            </div>
                        </Row>
                        <Row style={{ width: '90%', margin: '50px auto' }}>
                            <div style={{ display: 'grid', gridTemplateColumns: '2fr 2fr', gap: 50 }}>
                                <div style={{ textAlign: 'left' }}>
                                    <FormControl sx={{ width: 200 }} variant="outlined">
                                        <InputLabel htmlFor="squareFeet1">Square Feet</InputLabel>
                                        <OutlinedInput required type='number' id='squareFeet1' name="squareFeet1" variant='outlined' label='Square Feet' endAdornment={<InputAdornment position="end">sq. ft.</InputAdornment>} onChange={onChange} value={this.state.squareFeet1} />
                                    </FormControl>
                                </div>
                                {(this.state.addRange) ? (
                                <div>
                                    <FormControl sx={{ width: 200 }} variant="outlined">
                                        <InputLabel htmlFor="squareFeet2">To</InputLabel>
                                        <OutlinedInput required type='number' name="squareFeet2" variant='outlined' label='To' endAdornment={<InputAdornment position="end">sq. ft.</InputAdornment>} onChange={onChange} value={this.state.squareFeet2} />
                                    </FormControl>
                                </div>
                                ) : (
                                <div style={{ textAlign: 'left' }}>
                                    <Button variant='text' size='small' onClick={e => this.setState({ addRange: true })} style={{ fontSize: 14, color: 'dodgerblue', paddingTop: 20 }}>
                                        + Add Range
                                    </Button>
                                </div>)}
                            </div>
                        </Row>
                        <Row style={{ width: '90%', margin: '40px auto' }}>
                            <FormControl sx={{ width: 200 }} variant="outlined">
                                <InputLabel htmlFor="inputPricePerSquareFoot">Price per Square Foot</InputLabel>
                                <OutlinedInput required type='number' id='inputPricePerSquareFoot' name="pricePerSquareFoot" variant='outlined' label='Price per Square Foot' startAdornment={<InputAdornment position="start">$</InputAdornment>} onChange={onChange} value={this.state.pricePerSquareFoot} />
                                <FormHelperText id="inputPricePerSquareFoot-helper-text">PER YEAR</FormHelperText>
                            </FormControl>
                        </Row>
                        <Row style={{ width: '90%', marginTop: 0, marginBottom: 40, marginLeft: 'auto', marginRight: 'auto' }}>
                            <FormControl sx={{ width: 200 }} variant="outlined">
                                <InputLabel htmlFor="inputFrontage">Frontage</InputLabel>
                                <OutlinedInput required type='number' id='inputFrontage' name="frontage" variant='outlined' label='Frontage' endAdornment={<InputAdornment position="end">ft.</InputAdornment>} onChange={onChange} value={this.state.frontage} />
                            </FormControl>
                        </Row>
                        <FormGroup row style={{ margin: '30px auto' }}>
                            <Col sm={4}>
                                <Label for='propertyFeatures'>Property Features:</Label>
                                <div className='selectAllThatApply'>(Select All That Apply)</div>
                            </Col>
                                <Col sm={8} style={{ textAlign: 'left' }}>
                                    <div className='selectPropFeatureSection'>
                                        <Button
                                            variant='contained'
                                            style={this.state.propertyFeatures.includes('cornerLocation') ? { color: 'white', outline: 'none', backgroundColor: 'rgb(14, 150, 45)', fontSize: 11, margin: '5px 5px 5px 5px' } : { color: 'black', backgroundColor: '#e9ecef', outline: 'none', fontSize: 11, margin: '5px 5px 5px 5px' }}
                                            category='cornerLocation'
                                            checked={this.state.propertyFeatures.includes('cornerLocation')}
                                            onClick={(e) => {
                                                const value = 'cornerLocation';
                                                const checked = this.state.propertyFeatures.includes('cornerLocation');
                                                let propertyFeature = checked ? this.state.propertyFeatures.filter((tags) => tags !== value) : [...this.state.propertyFeatures, value]
                                                this.setState({ propertyFeatures: propertyFeature });
                                            }}
                                        >
                                        Corner Location
                                        </Button>
                                        <Button
                                            variant='contained'
                                            style={this.state.propertyFeatures.includes('outdoorSpace') ? { color: 'white', outline: 'none', backgroundColor: 'rgb(14, 150, 45)', fontSize: 11, margin: '5px 5px 5px 5px' } : { color: 'black', backgroundColor: '#e9ecef', outline: 'none', fontSize: 11, margin: '5px 5px 5px 5px' }}
                                            category='outdoorSpace'
                                            checked={this.state.propertyFeatures.includes('outdoorSpace')}
                                            onClick={(e) => {
                                                const value = 'outdoorSpace';
                                                const checked = this.state.propertyFeatures.includes('outdoorSpace');
                                                let propertyFeature = checked ? this.state.propertyFeatures.filter((tags) => tags !== value) : [...this.state.propertyFeatures, value]
                                                this.setState({ propertyFeatures: propertyFeature });
                                            }}
                                        >
                                        Outdoor Space
                                        </Button>
                                        <Button
                                            variant='contained'
                                            style={this.state.propertyFeatures.includes('multiLevel') ? { color: 'white', outline: 'none', backgroundColor: 'rgb(14, 150, 45)', fontSize: 11, margin: '5px 5px 5px 5px' } : { color: 'black', backgroundColor: '#e9ecef', outline: 'none', fontSize: 11, margin: '5px 5px 5px 5px' }}
                                            category='multiLevel'
                                            checked={this.state.propertyFeatures.includes('multiLevel')}
                                            onClick={(e) => {
                                                const value = 'multiLevel';
                                                const checked = this.state.propertyFeatures.includes('multiLevel');
                                                let propertyFeature = checked ? this.state.propertyFeatures.filter((tags) => tags !== value) : [...this.state.propertyFeatures, value]
                                                this.setState({ propertyFeatures: propertyFeature });
                                            }}
                                        >
                                        Multi-Level
                                        </Button>
                                        <Button
                                            variant='contained'
                                            style={(this.state.propertyFeatures.includes('divisible') || (values.squareFeet2 !== null && values.squareFeet2.length > 0)) ? { color: 'white', outline: 'none', backgroundColor: 'rgb(14, 150, 45)', fontSize: 11, margin: '5px 5px 5px 5px' } : { color: 'black', backgroundColor: '#e9ecef', outline: 'none', fontSize: 11, margin: '5px 5px 5px 5px' }}
                                            category='divisible'
                                            checked={(this.state.propertyFeatures.includes('divisible') || (!this.state.sfError && values.squareFeet2 !== null && values.squareFeet2.length > 0))}
                                            onClick={(e) => {
                                                const value = 'divisible';
                                                const checked = this.state.propertyFeatures.includes('divisible');
                                                let propertyFeature = checked ? this.state.propertyFeatures.filter((tags) => tags !== value) : [...this.state.propertyFeatures, value]
                                                this.setState({ propertyFeatures: propertyFeature });
                                            }}
                                        >
                                        Divisible
                                        </Button>
                                        <Button
                                            variant="contained"
                                            style={this.state.propertyFeatures.includes('newConstruction') ? { color: 'white', outline: 'none', backgroundColor: 'rgb(14, 150, 45)', fontSize: 11, margin: '5px 5px 5px 5px' } : { color: 'black', backgroundColor: '#e9ecef', outline: 'none', fontSize: 11, margin: '5px 5px 5px 5px' }}
                                            category='newConstruction'
                                            checked={this.state.propertyFeatures.includes('newConstruction')}
                                            onClick={(e) => {
                                                const value = 'newConstruction';
                                                const checked = this.state.propertyFeatures.includes('newConstruction');
                                                let propertyFeature = checked ? this.state.propertyFeatures.filter((tags) => tags !== value) : [...this.state.propertyFeatures, value]
                                                this.setState({ propertyFeatures: propertyFeature });
                                            }}
                                        >
                                        New Construction
                                        </Button>
                                    </div>
                                </Col>
                        </FormGroup>
                    </Form>
                </div>
                <Row style={{ width: '90%', marginTop: 50, marginBottom: 20, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                    <Button variant='contained' size='large' style={{ backgroundColor: 'rgb(35, 126, 85)', color: 'white', outline: 'none', margin: '0 auto' }} onClick={() => this.continue()} disabled={values.streetAddress === null || values.streetAddress.length === 0}>Continue</Button>
                </Row>
            </Card>
            </>
        )
    }
}

export default CreateListingInput;