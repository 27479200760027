import React, { Component } from 'react';
import { Container } from 'reactstrap';
import roadMap from '../../Assets/roadmap_map.png';
import './description.css';

class UserRoadmap extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            demoVidArea: false,
            mobile: false,
            circle1: false,
            circle2: false,
            circle3: false,
            circle4: false,
            circle5: false,
            circle6: false,
        }
    }

    componentDidMount() {
        window && window.addEventListener('scroll', this.setContainerBackgroundColor.bind(this));
        window && window.addEventListener('scroll', this.fadeInCircles.bind(this));
        window && window.addEventListener('resize', this.mobileMode.bind(this));
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }

    componentWillUnmount() {
        window && window.removeEventListener('scroll', this.setContainerBackgroundColor.bind(this));
        window && window.removeEventListener('scroll', this.fadeInCircles.bind(this));
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
    }

    setContainerBackgroundColor() {
        if (window && (window.scrollY > 300)) {
            this.setState({ demoVidArea: true })
        } else {
            this.setState({ demoVidArea: false })
        }
    }

    fadeInCircles() {
        if (window && (window.scrollY > 300)) {
            
            setTimeout(() => {
                this.setState({ circle1: true })
            }, 250);
            setTimeout(() => {
                this.setState({ circle2: true })
            }, 500);
            setTimeout(() => {
                this.setState({ circle3: true })
            }, 750);
            setTimeout(() => {
                this.setState({ circle4: true })
            }, 1000);
            setTimeout(() => {
                this.setState({ circle5: true })
            }, 1250);
            setTimeout(() => {
                this.setState({ circle6: true })
            }, 1500);
                
        } else {
            this.setState({ demoVidArea: false })
        }
    }

    mobileMode() {
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }

    render() { 

        const { demoVidArea, circle1, circle2, circle3, circle4, circle5, circle6, mobile } = this.state;

        return ( 
            <Container className={(demoVidArea === true) ? 'demoVidAreaContainer' : 'descContainer'} fluid>
                <div className='description'>
                    <div className='perfectSpace'>
                        <h2 className={(demoVidArea === true) ? 'descPageAreaTitle' : 'descPageAreaTitleBlack'}>Find The Perfect Space For Your Business </h2>
                    </div>
                    <div className='scoreTypes' style={{ width: '85%', margin: 'auto', paddingTop: 20, textAlign: 'center', position: 'relative' }}>
                        {circle1 === true ? <div className='roadmapCircle' style={{ position: 'absolute', left: '0%', top: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '75%' }}>
                                <div style={{ fontSize: 24 }}>1.</div>
                                <div style={{ fontSize: 14 }}>Spaces are scored based on the quality of the space AND the area it's in.</div>
                            </div>
                        </div> : null}
                        {circle2 === true ? <div className='roadmapCircle' style={{ position: 'absolute', left: '26%', top: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '75%' }}>
                                <div style={{ fontSize: 24 }}>2.</div>
                                <div style={{ fontSize: 14 }}>Get transparent and unbiased listing evaluations.</div>
                            </div>
                        </div> : null}
                        {circle3 === true ? <div className='roadmapCircle' style={{ position: 'absolute', left: '52%', top: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '75%' }}>
                                <div style={{ fontSize: 24 }}>3.</div>
                                <div style={{ fontSize: 14 }}>Avoid having to manually gather and compare location data for every space.</div>
                            </div>
                        </div> : null}
                        {circle4 === true ? <div className='roadmapCircle' style={{ position: 'absolute', left: '44%', top: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '75%' }}>
                                <div style={{ fontSize: 24 }}>4.</div>
                                <div style={{ fontSize: 14 }}>Get personalized scores for each listing using Fourcast Match.</div>
                            </div>
                        </div> : null}
                        {circle5 === true ? <div className='roadmapCircle' style={{ position: 'absolute', left: '67%', top: '53%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '75%' }}>
                                <div style={{ fontSize: 24 }}>5.</div>
                                <div style={{ fontSize: 14 }}>Instantly generate printable reports containing rich and unique location data.</div>
                            </div>
                        </div> : null}
                        {circle6 === true ? <div className='roadmapCircle' style={{ position: 'absolute', left: '84%', top: '36%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '75%' }}>
                                <div style={{ fontSize: 24 }}>6.</div>
                                <div style={{ fontSize: 14 }}>Save time, money,  and effort finding the perfect space for your business.</div>
                            </div>
                        </div> : null}
                        <img src={roadMap} style={{ width: '100%' }} alt="welcome page roadmap" />
                    </div>
                </div>
            </Container>
         );
    }
}
 
export default UserRoadmap;

/* <div style={{ height: 850, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div>
                        <div style={{ width: '100%', textAlign: 'center', marginBottom: 40 }}>
                            <h2 className={(demoVidArea === true) ? 'descPageAreaTitle' : 'descPageAreaTitleBlack'}>Explore Property Searching Capabilities</h2>
                        </div>
                        <div>
                            <iframe src="https://player.vimeo.com/video/793545350?h=21f3d8d835&title=0&byline=0&portrait=0" width="960" height="540" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}></iframe>
                        </div>
                    </div>
                </div>*/