import React, { Component } from 'react';
import { Paper } from '@mui/material';
import TotalBusinesses from '../listingDetailsLayouts/competition/totalBusinessesGraph';
import MostCommonBusiness from '../listingDetailsLayouts/competition/commonBusinessGraph';
import fourcastLogo from '../../Assets/fourcast_alt_logo_green.png';
import '../listingDetailsLayouts/traffic/traffic.css';
import '../listingDetailsLayouts/competition/competition.css';



class BizDensityAndCommonBizTypesReport extends Component {
    
    
    render() { 
        const { thisListing, thisListing: { listingAddress: getListingAddress } = {}} = this.props;
        const { yelpData: { total: yelpTotal } = {}} = this.props;
        const { yelpData: { businesses: yelpBusinesses } = {}} = this.props;
        
        
        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!TOTAL BUSINESS GRAPH!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!        
        
        // gauges how the # of total biz's for this particular listing compares to every other listing
        const { scores: { bizDensity: totalBusinessesGauge } = {}} = this.props;
        let totalGaugeNeedle = 0;
        let totalChartData = {};
        
        
        if (totalBusinessesGauge < 55) {
                totalGaugeNeedle = -80;
                totalChartData = {
                    labels: ['Low Density', 'Average Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        } else if (totalBusinessesGauge < 60) {
                totalGaugeNeedle = -58;
                totalChartData = {
                    labels: ['Low Density', 'Average Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        } else if (totalBusinessesGauge < 65) {
                totalGaugeNeedle = -38;
                totalChartData = {
                    labels: ['Low Density', 'Average Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        } else if (totalBusinessesGauge < 70) {
                totalGaugeNeedle = -18;
                totalChartData = {
                    labels: ['Low Density', 'Average Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        } else if (totalBusinessesGauge === 75) {
                totalGaugeNeedle = 2;
                totalChartData = {
                    labels: ['Low Density', 'Average Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        } else if (totalBusinessesGauge < 80) {
                totalGaugeNeedle = 18;
                totalChartData = {
                    labels: ['Low Density', 'Average Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        } else if (totalBusinessesGauge <= 85) {
                totalGaugeNeedle = 38;
                totalChartData = {
                    labels: ['Low Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        } else if (totalBusinessesGauge <= 90) {
                totalGaugeNeedle = 58;
                totalChartData = {
                    labels: ['Low Density', 'Average Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        } else if (totalBusinessesGauge <= 95) {
                totalGaugeNeedle = 78;
                totalChartData = {
                    labels: ['Low Density', 'Average Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        } else {
                totalGaugeNeedle = 85;
                totalChartData = {
                    labels: ['Low Density', 'Average Density', 'High Density'],
                    datasets: [
                        {
                            label: 'Business Density in Area',
                            backgroundColor: ['rgb(228, 114, 103)', 'rgb(250, 204, 68)', 'rgb(52, 181, 122)'],
                            data: [30, 40, 30]
                        }
                    ],
                }
        }


        // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!MOST COMMON BUSINESS TYPES !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        
        //gets the category Titles of the businesses in the area of this each listing
        const yelpBusinessCategories = yelpBusinesses.map((business) => (business.categories).map((category) => category.title));
        const allCategories = yelpBusinessCategories.map((category) => category[0]);
        
        //creates an array of key/values that contains a count of each business type in the area
        const count = allCategories =>
            allCategories.reduce((a, b) => ({ ...a,
                [b]: (a[b] || 0) + 1
            }), {})

        const sortable = Object.fromEntries(
            Object.entries(count(allCategories)).sort(([,a],[,b]) => b-a)
        );
        
        const mostCommonBusinesses = Object.entries(sortable);
        let commonChartData = {};

        
        commonChartData = {
            labels: 
            [
                `${mostCommonBusinesses[0][0]}`, 
                `${mostCommonBusinesses[1][0]}`, 
                `${mostCommonBusinesses[2][0]}`, 
                `${mostCommonBusinesses[3][0]}`,
                `${mostCommonBusinesses[4][0]}`,
                `${mostCommonBusinesses[5][0]}`
            ],
            datasets: [
                {
                    label: 'Number of Businesses',
                    indexAxis: 'y',
                    data: [
                        `${mostCommonBusinesses[0][1]}`,
                        `${mostCommonBusinesses[1][1]}`,
                        `${mostCommonBusinesses[2][1]}`,
                        `${mostCommonBusinesses[3][1]}`,
                        `${mostCommonBusinesses[4][1]}`,
                        `${mostCommonBusinesses[5][1]}`
                    ],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.8)',
                        'rgba(54, 162, 235, 0.8)',
                        'rgba(255, 206, 86, 0.8)',
                        'rgba(75, 192, 192, 0.8)',
                        'rgba(150, 142, 62, 0.8)',
                        'rgba(105, 172, 100, 0.8)'
                    ]
                }
            ]
        }

        const setTotalBizCountPointer = (gauge) => { 
            setTimeout(() => {
                const totalGaugeNeedle = document.querySelector('.totalGaugeNeedle');
                let thisRotation = gauge;
                totalGaugeNeedle && totalGaugeNeedle.style.setProperty('--rotation', thisRotation);
            }, 200, { once: true });
        }
        
        totalBusinessesGauge && setTotalBizCountPointer(totalGaugeNeedle);

        let thisAddressArray = [];
        thisAddressArray.push(getListingAddress);
        let addressString = thisAddressArray.toString();
        let thisListingAddress = addressString.split(',');
        

        return (
            <Paper id="trafficReportPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 15, position: 'relative' }}>
                <div style={{ width: '100%', height: '100%' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '6fr 1fr' }}>
                        <div className='reportAddress'>
                            <h2>{thisListingAddress[0]}</h2>
                        </div>
                        <div className='reportFourcastLogoArea'>
                            <img className='reportFourcastLogo' src={fourcastLogo} alt='Fourcast Reports Logo' />
                        </div>
                    </div>
                    <hr />
                    <div style={{ marginTop: 15, height: '40%' }}>
                        <div className="popularTimesReport" id="busyTimesOfDayReport" style={{ position: 'relative', width: '100%' }}>
                            <div id='busyHoursDataReport' style={{ width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h2 style={{ textAlign: 'center', width: 200 }}>Total Businesses In The Area</h2>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 50 }}>
                                    <div style={{ height: 305, width: '100%' }}>
                                        <div style={{ height: 275, width: 475, position: 'relative' }}>
                                            <div className='totalBizArea'>
                                                <TotalBusinesses totalChartData={totalChartData} />
                                                <div className='totalGaugeNeedle'></div>
                                                <h2 className='totalBusinessNumber'>{yelpTotal}</h2>
                                                <div className='totalBusinessText'>Other Busineses in the Area</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div style={{ height: '45%', marginTop: 25 }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 75 }}>
                            <h2 style={{ textAlign: 'center', width: '100%' }}>Common Business Types Nearby</h2>
                        </div>
                        <div style={{ marginTop: 25, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ height: 350, width: 475, position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div className='commonBizArea'>
                                    <MostCommonBusiness commonChartData={commonChartData} thisListing={thisListing} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: 10, position: 'absolute', bottom: '1%', right: '1%', fontWeight: 800, color: 'darkgreen' }}>fourcastscore.com</div>
            </Paper>
        );
    }
}

export default BizDensityAndCommonBizTypesReport;