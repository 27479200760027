import React, { Component } from 'react';
import yelplogo from '../../../Assets/yelplogo.png';
import { Table } from '@mui/material';
import '../../listingDetailsLayouts/traffic/traffic.css';
import '../compareListings.css';

class EngagementComparison extends Component {
    
    
    render() {

        const { listings } = this.props;

        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.scores.fourcastScore - a.scores.fourcastScore});
        
        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }

        let getThisListingsReviewCount = (thisListing) => {

            const scores = thisListing && thisListing.scores && thisListing.scores;
            const theseBusinesses = thisListing && thisListing.yelpData && thisListing.yelpData.businesses && thisListing.yelpData.businesses;

            let thisListingsReviewCount = 0;
            let reviewCountGaugePointer = 0;
            
            if (theseBusinesses) {
                for (let i = 0; i < theseBusinesses.length; i++) {
                    thisListingsReviewCount += theseBusinesses[i].review_count;
                }
            }

            if (scores) {
                if (scores.totalReviewsGauge < 55) {
                    reviewCountGaugePointer = 3;
                } else if (scores.totalReviewsGauge >= 55 && scores.totalReviewsGauge < 59) {
                    reviewCountGaugePointer = 10;
                } else if (scores.totalReviewsGauge >= 59 && scores.totalReviewsGauge < 63) {
                    reviewCountGaugePointer = 18;
                } else if (scores.totalReviewsGauge >= 63 && scores.totalReviewsGauge < 67) {
                    reviewCountGaugePointer = 27;
                } else if (scores.totalReviewsGauge >= 67 && scores.totalReviewsGauge < 71) {
                    reviewCountGaugePointer = 37;
                } else if (scores.totalReviewsGauge >= 71 && scores.totalReviewsGauge < 75) {
                    reviewCountGaugePointer = 48;
                } else if (scores.totalReviewsGauge >= 75 && scores.totalReviewsGauge < 79) {
                    reviewCountGaugePointer = 58;
                } else if (scores.totalReviewsGauge >= 79 && scores.totalReviewsGauge < 83) {
                    reviewCountGaugePointer = 67;
                } else if (scores.totalReviewsGauge >= 83 && scores.totalReviewsGauge < 87) {
                    reviewCountGaugePointer = 75;
                } else if (scores.totalReviewsGauge >= 87 && scores.totalReviewsGauge < 91) {
                    reviewCountGaugePointer = 82;
                } else if (scores.totalReviewsGauge >= 91 && scores.totalReviewsGauge < 95) {
                    reviewCountGaugePointer = 88;
                } else {
                    reviewCountGaugePointer = 95;
                }
            }

            const reviewCountObj = {address: thisListing.listingAddress, reviewCount: thisListingsReviewCount, reviewCountGaugePointer: reviewCountGaugePointer};
            
            return reviewCountObj;
        }


        // SORT BY REVIEW COUNT
        const sortedByReviewCount = sortedByFourcastScore && sortedByFourcastScore.map(listing => getThisListingsReviewCount(listing)).sort(function(a, b){return b.reviewCount - a.reviewCount});
        
        let listing1 = (sortedByReviewCount && sortedByReviewCount.length && (sortedByReviewCount[0] !== undefined)) ? sortedByReviewCount[0] : null;
        let listing2 = (sortedByReviewCount && sortedByReviewCount.length && (sortedByReviewCount[1] !== undefined)) ? sortedByReviewCount[1] : null;
        let listing3 = (sortedByReviewCount && sortedByReviewCount.length && (sortedByReviewCount[2] !== undefined)) ? sortedByReviewCount[2] : null;
        let listing4 = (sortedByReviewCount && sortedByReviewCount.length && (sortedByReviewCount[3] !== undefined)) ? sortedByReviewCount[3] : null;
        
        const setRevCountGaugePointer = async (gauge, el) => {
            setTimeout(async () => {
                const revCountGauge = await document.querySelector(el);
                let thisBottom = gauge;                
                revCountGauge && await revCountGauge.style.setProperty('--bottomReview', thisBottom);
            }, 100, { once: true });
        }

        (listing1 !== null) && listing1.reviewCountGaugePointer && setRevCountGaugePointer(listing1.reviewCountGaugePointer, '.reviewCountComparisonPointer1');
        (listing2 !== null) && listing2.reviewCountGaugePointer && setRevCountGaugePointer(listing2.reviewCountGaugePointer, '.reviewCountComparisonPointer2');
        (listing3 !== null) && listing3.reviewCountGaugePointer && setRevCountGaugePointer(listing3.reviewCountGaugePointer, '.reviewCountComparisonPointer3');
        (listing4 !== null) && listing4.reviewCountGaugePointer && setRevCountGaugePointer(listing4.reviewCountGaugePointer, '.reviewCountComparisonPointer4');
        
        if (listing1) {listing1.color = 'rgba(52, 181, 122, .2)'};
        if (listing2) {listing2.color = 'rgba(223, 82, 69, .2)'};
        if (listing3) {listing3.color = 'rgba(250, 204, 68, .2)'};
        if (listing4) {listing4.color = 'rgba(30, 144, 255, .2)'};

        const commaNumber = (num) => {
            let newNum = Number(num);
            let numString = newNum.toLocaleString("en-US");
            return numString;
        }
        
        let listing1Backup = ((listing1 !== null) && (listing1.reviewCountGaugePointer && ((listing2 && listing2.reviewCountGaugePointer && (listing1.reviewCountGaugePointer === listing2.reviewCountGaugePointer)) || (listing3 && listing3.reviewCountGaugePointer && (listing1.reviewCountGaugePointer === listing3.reviewCountGaugePointer)) || (listing4 && listing4.reviewCountGaugePointer && (listing1.reviewCountGaugePointer === listing4.reviewCountGaugePointer))))) ? true : false;
        let listing2Backup = ((listing2 !== null) && (listing2.reviewCountGaugePointer && ((listing3 && listing3.reviewCountGaugePointer && (listing2.reviewCountGaugePointer === listing3.reviewCountGaugePointer)) || (listing4 && listing4.reviewCountGaugePointer && (listing2.reviewCountGaugePointer === listing4.reviewCountGaugePointer))))) ? true : false;
        let listing3Backup = ((listing3 !== null) && (listing3.reviewCountGaugePointer && ((listing4 && listing4.reviewCountGaugePointer && (listing3.reviewCountGaugePointer === listing4.reviewCountGaugePointer))))) ? true : false;

        if (listing1Backup === true) {
            setRevCountGaugePointer(listing1.reviewCountGaugePointer, '.reviewCountComparisonBackup1');
        }
        if (listing2Backup === true) {
            setRevCountGaugePointer(listing2.reviewCountGaugePointer, '.reviewCountComparisonBackup2');
        }
        if (listing3Backup === true) {
            setRevCountGaugePointer(listing3.reviewCountGaugePointer, '.reviewCountComparisonBackup3');
        }

        return (
            <div style={{ position: 'relative', width: '98%' }}>
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Engagement</div>
                    <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}>
                        <img style={{ width: 50, height: 25 }} src={yelplogo} alt='' />
                    </div>
                </div>
                <div className='busyTimesCompareGaugeArea' style={{ position: 'relative', display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                        <div style={{ width: '90%', display: 'flex', justifyContent: 'center', paddingTop: 30, marginLeft: 'auto', marginRight: 'auto', paddingBottom: 10 }}>
                            <div style={{ fontWeight: 500, fontSize: 20 }}>People Talking About This Area</div>
                        </div>
                        <div style={{ width: '90%', margin: 'auto auto' }}>
                            <Table style={{ tableLayout: 'fixed' }}>
                                <tbody>
                                    {sortedByReviewCount && sortedByReviewCount.map(listing => 
                                        <tr style={{ border: '1px solid lightgray', textAlign: 'center' }}>
                                            <th style={{ fontSize: 18, padding: 20, backgroundColor: listing.color }}>
                                                {listing && addressStyle(listing && listing.address)}
                                            </th>
                                            <td style={{ fontSize: 14, padding: 20, backgroundColor: listing.color }}>
                                                {`${listing && commaNumber(listing && listing.reviewCount)} reviews`}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        <div className='traffGaugeArea' style={{ flex: 1, width: '50%', height: 100, position: 'relative', marginLeft: 'auto', marginRight: 'auto' }}>
                            <div className='dayTrafficComparisonGauge'></div>
                            {((listing1 !== null) && (listing1.reviewCountGaugePointer)) ? <div className='reviewCountComparisonPointer1'></div> : null}
                            {(listing1Backup === true) ? <div className='reviewCountComparisonBackup1'></div> : null}
                            {((listing2 !== null) && (listing2.reviewCountGaugePointer)) ? <div className='reviewCountComparisonPointer2'></div> : null}
                            {(listing2Backup === true) ? <div className='reviewCountComparisonBackup2'></div> : null}
                            {((listing3 !== null) && (listing3.reviewCountGaugePointer)) ? <div className='reviewCountComparisonPointer3'></div> : null}
                            {(listing3Backup === true) ? <div className='reviewCountComparisonBackup3'></div> : null}
                            {((listing4 !== null) && (listing4.reviewCountGaugePointer)) ? <div className='reviewCountComparisonPointer4'></div> : null}
                            <div className='dayTrafficComparisonGaugeHigh'>High</div>
                            <div className='dayTrafficComparisonGaugeLow'>Low</div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        );
    }
}

export default EngagementComparison;