import axios from 'axios';
import { returnErrors } from './errorActions';
import { USER_LOADING, USER_LOADED, AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS, REGISTER_SUCCESS, REGISTER_FAIL, USER_UPDATE_SUCCESS, FORGOT_PASSWORD_SENT, FORGOT_PASSWORD_FAIL, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAIL } from './types';


// Pointing Axios baseURL towards AWS API Gateway URL
// Will most likely be changed again at some point because of the /dev
const axiosAWS = axios.create({
    baseURL: 'https://bgeg45v1tk.execute-api.us-east-1.amazonaws.com/dev'
});

// Check Token and Load User
export const loadUser = () => (dispatch, getState) => {
    
    // User Loading
    dispatch({ type: USER_LOADING });

    axiosAWS.get('/api/auth/user', tokenConfig(getState))
        .then(res => dispatch({
            type: USER_LOADED,
            payload: res.data
        }))
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type: AUTH_ERROR
            });
        });
}


// Register User
export const register = ({ firstName, lastName, email, password, confirmPassword, company, accountType, engagement }) => dispatch => {

    //Headers 
    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    };

    // Request body
    const body = JSON.stringify({ firstName, lastName, email, password, confirmPassword, company, accountType, engagement });
    
    axiosAWS.post('/api/users', body, config)
        .then(res => {
            dispatch({ 
                type: REGISTER_SUCCESS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, 'REGISTER_FAIL'));
            dispatch({
                type: REGISTER_FAIL
            })
        })
}


// Login User
export const login = ({ email, password }) => dispatch => {

    //Headers 
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    // Request body
    const body = JSON.stringify({ email, password });
    
    axiosAWS.post('/api/auth', body, config)
        .then(res => {
            dispatch({ 
                type: LOGIN_SUCCESS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, 'LOGIN_FAIL'));
            dispatch({
                type: LOGIN_FAIL
            })
        })
}


// Logout User
export const logout = () => {
    return {
        type: LOGOUT_SUCCESS
    };
};


// Update User
export const updateUser = updatedUser => (dispatch, getState) => {
    if (updatedUser.password) {
        axiosAWS
            .patch(`/api/users/${updatedUser.userId}`, { 
                accountType: updatedUser.accountType,
                password: updatedUser.password,
                engagement: updatedUser.engagement
            }, tokenConfig(getState))
            .then(res => 
                dispatch({
                    type: USER_UPDATE_SUCCESS,
                    payload: res.data
                })
            ).catch(err => 
                dispatch(returnErrors(err.response.data, err.response.status))
            );
    } else {
        axiosAWS
            .patch(`/api/users/${updatedUser.userId}`, { 
                accountType: updatedUser.accountType,
                engagement: updatedUser.engagement
            }, tokenConfig(getState))
            .then(res => 
                dispatch({
                    type: USER_UPDATE_SUCCESS,
                    payload: res.data
                })
            ).catch(err => 
                dispatch(returnErrors(err.response.data, err.response.status))
            );
    }
};



// FORGOT & RESET PASSWORD CAN BE FOUND IN THE RESET PASSWORD REDUCER!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// Forgot Password
export const forgotPassword = email => dispatch => {
    
    //Headers 
    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    };

    axiosAWS.post('/api/users/forgot-password', {
            email: email
        }, config)
        .then(res => {
            dispatch({ 
                type: FORGOT_PASSWORD_SENT,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, 'FORGOT_PASSWORD_FAIL'));
            dispatch({
                type: FORGOT_PASSWORD_FAIL
            })
        })
}


// Reset Password
export const resetPassword = updatedPassword => dispatch => {
    
    //Headers 
    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    };

    axiosAWS.patch(`/api/users/forgot-password/${updatedPassword.resetToken}`, {
            password: updatedPassword.password
        }, config)
        .then(res => {
            dispatch({ 
                type: RESET_PASSWORD_SUCCESS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, 'RESET_PASSWORD_FAIL'));
            dispatch({
                type: RESET_PASSWORD_FAIL
            })
        })
}


// Setup config/headers and token
export const tokenConfig = getState => {
    
    // Get Token from localStorage
    const token = getState().auth.token;

    // Headers 
    const config = {
        headers: {
            "Content-type": "application/json"
        }
    }

    // If token, add to headers
    if (token) {
        config.headers['x-auth-token'] = token;
    }

    return config;
}
