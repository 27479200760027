import React, { Component } from 'react';
import moment from 'moment';
import { 
    Container,
    Table,
    Row, 
    Label, 
    Col, 
    Form, 
    FormGroup, 
    Input } 
from 'reactstrap';
import LeadsModal from './leadsModal';


class LeadsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            address: null,
            selected: null,
        }
    }

    onChange = (e) => {
        let thisAddress = e.target.value;
        this.setState({ address: thisAddress });
    }

    render() {
        
        const { thisUsersLeads, user } = this.props;

        const uniqueLeadsByAddress = Array.from(new Set(thisUsersLeads.map(l => l.listing._id))).map(_id => {
            return thisUsersLeads.find(l => l.listing._id === _id)
        });
        
        const allLeadsListingIds = thisUsersLeads.map((lead) => lead.listing._id);        

        //creates an array of key/values that contains a count of leads per listing
        const count = (allLeadsListingIds) =>
            allLeadsListingIds.reduce((a, b) => ({ ...a,
                [b]: (a[b] || 0) + 1
            }), {})

        const sortable = Object.fromEntries(
            Object.entries(count(allLeadsListingIds))
        );
        
        
        let filteredLeads = uniqueLeadsByAddress && uniqueLeadsByAddress.filter((lead) => {
            
            if (this.state.address == null) {
                return lead
            } else if (lead.listing.listingAddress.toLowerCase().includes(this.state.address.toLowerCase())) {
                return lead
            } 
        }).map((lead) => {
            return (
                <tr>
                    <th style={{ fontSize: 14 }}>
                        {sortable[lead.listing._id]}
                    </th>
                    <th style={{ fontSize: 14 }}>
                        {lead.listing.listingAddress}
                    </th>
                    <td style={{ fontSize: 14 }}>
                        {lead.createdAt ? moment(lead.createdAt).fromNow() : 'N/A'}
                    </td>
                    <td>
                        {lead.sender._id === user._id ? null : <LeadsModal user={user} thisUsersLeads={thisUsersLeads} thisListing={lead.listing} />}
                    </td>
                </tr>
            )
        });
        
        return (
            <Container>
                <Row>
                    <Col sm={2}>
                        <Form>
                            <FormGroup style={{ position: 'fixed', marginLeft: -50, marginTop: 30 }}>
                                <Label style={{ fontSize: 18, fontWeight: 'bold', color: 'green' }} for='address'>Search By Address</Label>
                                <Input 
                                    type='text'
                                    category='address'
                                    placeholder='Find...'
                                    onChange={this.onChange}
                                />
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col sm={10}>
                        <Table style={{ marginTop: 20 }}>
                            <thead>
                                <tr>
                                    <th>Total Leads</th>
                                    <th>Listing Address</th>
                                    <th>Most Recent</th>
                                    <th> </th>
                                </tr>
                            </thead>
                            <tbody>
                                {thisUsersLeads.length > 0 ? filteredLeads : (<div style={{ paddingTop: 10, fontSize: 14 }}>You Do Not Have Any Leads</div>)}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        );
    }

}


export default LeadsList;