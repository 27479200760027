import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button } from '@material-ui/core';
import { Row } from 'reactstrap';
import { connect } from 'react-redux';
import { loadUser } from '../../actions/authActions';
import FourcastMatchGif from '../../Assets/FourcastMatchGIF.mp4';
import PropTypes from 'prop-types';
import CustomLoginRegisterModal from './customLoginRegisterModal';
import fourcastMatchLogo from '../../Assets/fourcast_match_logo_green.png';
import './customSearch.css';
import '../welcome/description.css';


class FourcastMatchWelcomePage extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            doubleChecked: false,
            loaded: false,
            showDesc: false,
            showGif: false,
            showButtons: false,
            screenWidth: null
        }
    }

    componentDidMount() {
        this.props.loadUser();
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
        // setting mobile views
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth });
        }
        this.setLoaded();
    }

    async componentDidUpdate(prevProps, prevState) {
        const { user } = this.props;
        const { doubleChecked } = this.state;
        const getUserSubData = user && user.stripeSubData;

        if (!getUserSubData) {
            if (user && (doubleChecked === false)) {
                await this.checkUserAgain();
            }
        }

        
    }

    componentWillUnmount() {
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
    }

    mobileMode() {
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth });
        }
    }

    checkUserAgain = async () => {
        await this.props.loadUser();
        this.setState({ doubleChecked: true });
    }
    
    setLoaded() {
        setTimeout(() => {
            this.setState({ loaded: true })
        }, 1750);
        setTimeout(() => {
            this.setState({ showDesc: true });
        }, 3250);
        setTimeout(() => {
            this.setState({ showGif: true });
        }, 3750);
        setTimeout(() => {
            this.setState({ showButtons: true });
        }, 3250);
    }
    
    continue = e => {
        this.props.nextStep();
    }



    render() { 
        
        const { isAuthenticated, user } = this.props;
        const { loaded, showDesc, showGif, showButtons, screenWidth } = this.state;

        const getUserSubData = user && user.stripeSubData;
        const stripeSubStatus = getUserSubData && getUserSubData.status;
        const stripeSubType = getUserSubData && getUserSubData.subType;
        
        return (
            <div style={{ width: '100%', height: 775, backgroundColor: 'whitesmoke', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card id={loaded ? 'customSearchWelcomePage' : 'customSearchWelcomePageLoading'}>
                    <div>
                        <div id={loaded ? 'fourcastMatchLogoWelcomePage' : "fourcastMatchLogoWelcomePageLoading"} style={{ width: `${((screenWidth !== null) && (screenWidth < 1000 )) ? screenWidth : 500}`, marginLeft: 'auto', marginRight: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {((screenWidth !== null) && (screenWidth < 1000 )) ? <img id='welcomePageMatchLogo' style={{ width: 200 }} alt='Try Fourcast Match' src={fourcastMatchLogo} /> : <img id='welcomePageMatchLogo' style={{ width: 400 }} alt='Try Fourcast Match' src={fourcastMatchLogo} />}
                        </div>
                        {((screenWidth === null) || (screenWidth > 1000 )) ? (<div style={{ display: 'grid', gridTemplateColumns: '2fr 3fr', marginTop: 25, height: 500 }}>
                            <div style={{ marginTop: 'auto', marginBottom: 'auto', height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div>
                                    {showDesc === true ? <div>
                                        <Row className='fourcastMatchWelcomePageDesc' style={{ marginLeft: 'auto', marginRight: 'auto', height: 150, display: 'grid', justifyContent: 'center', alignItems: 'top' }}>
                                            <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '85%', textAlign: 'center', fontSize: 24, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif' }}>Get instantly matched with spaces and areas that fit exactly what you're looking for.</div>
                                        </Row>
                                    </div> : null}
                                    {showButtons === true ? <div className='fourcastMatchWelcomePageButtons'>
                                        <Row style={{ margin: 'auto auto', fontSize: 12, height: 25, display: 'grid', justifyContent: 'center', alignItems: 'center' }}>
                                            <div>By Clicking Below I Agree To Terms & Conditions</div>
                                        </Row>
                                        <Row style={{ textAlign: 'center', width: '80%', marginLeft: 'auto', marginRight: 'auto', height: 75 }}>
                                            {isAuthenticated ? (((user.accountType !== 'basic') && ((stripeSubStatus && stripeSubStatus === 'active') || (stripeSubStatus && stripeSubStatus === 'trialing')) && (stripeSubType && stripeSubType !== 'basic')) || (user.accountType === 'admin')) ? 
                                            (
                                                <Button onClick={() => this.continue()} style={{ marginTop: 20, marginRight: 'auto', marginLeft: 'auto', backgroundColor: "#007FFF", color: 'white', height: 50 }} variant="contained" size="large">Continue</Button>
                                            ) : (user && user.engagement.searches > 1) ? 
                                            (
                                                <Link style={{ marginTop: 20, marginRight: 'auto', marginLeft: 'auto', textDecoration: 'none' }} to={`/user/${user._id}`}>
                                                    <Button style={{ color: 'white', backgroundColor: '#007FFF' }} variant="contained" size="large">Upgrade To Continue</Button>
                                                </Link>
                                            ) : 
                                            (
                                                <Button onClick={() => this.continue()} style={{ marginTop: 20, marginRight: 'auto', marginLeft: 'auto', backgroundColor: '#007FFF', color: 'white', height: 50 }} variant="contained" size="large">Continue</Button>
                                            ) : 
                                            (<CustomLoginRegisterModal />)}
                                        </Row>
                                    </div> : null}
                                </div>
                            </div>
                            <div>
                                {showGif === true ? <div className='fourcastMatchWelcomePageGif' style={{ marginLeft: 'auto', marginRight: 'auto', height: 500 }}>
                                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <iframe width="560" height="315" src="https://www.youtube.com/embed/znIPgwh9GQw?si=OkqO6b-DQFyvBbSU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                                    </div>
                                </div> : null}
                            </div>
                        </div>) : (<div style={{ marginTop: 25 }}>
                            <div style={{ marginTop: 'auto', marginBottom: 'auto', height: 450, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ height: '100%' }}>
                                    {showDesc === true ? <div>
                                        <Row className='fourcastMatchWelcomePageDesc' style={{ marginLeft: 'auto', marginRight: 'auto', height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '85%', textAlign: 'center', fontSize: 24, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif' }}>Get instantly matched with spaces and areas that fit exactly what you're looking for.</div>
                                        </Row>
                                    </div> : null}
                                    {showButtons === true ? <div className='fourcastMatchWelcomePageButtons'>
                                        <Row style={{ margin: 'auto auto', fontSize: 12, height: 50 }}>
                                            <div style={{ textAlign: 'center' }}>By Clicking Below I Agree To Terms & Conditions</div>
                                        </Row>
                                        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '80%', marginLeft: 'auto', marginRight: 'auto', height: 100 }}>
                                            {isAuthenticated ? (((user.accountType !== 'basic') && ((stripeSubStatus && stripeSubStatus === 'active') || (stripeSubStatus && stripeSubStatus === 'trialing')) && (stripeSubType && stripeSubType !== 'basic')) || (user.accountType === 'admin')) ? 
                                            (
                                                <Button onClick={() => this.continue()} style={{ marginTop: 20, marginRight: 'auto', marginLeft: 'auto', backgroundColor: "#007FFF", color: 'white', height: 50 }} variant="contained" size="large">Continue</Button>
                                            ) : (user && user.engagement.searches > 1) ? 
                                            (
                                                <Link style={{ marginTop: 20, marginRight: 'auto', marginLeft: 'auto', textDecoration: 'none' }} to={`/user/${user._id}`}>
                                                    <Button style={{ color: 'white', backgroundColor: '#007FFF' }} variant="contained" size="large">Upgrade To Continue</Button>
                                                </Link>
                                            ) : 
                                            (
                                                <Button onClick={() => this.continue()} style={{ marginTop: 20, marginRight: 'auto', marginLeft: 'auto', backgroundColor: '#007FFF', color: 'white', height: 50 }} variant="contained" size="large">Continue</Button>
                                            ) : 
                                            (<CustomLoginRegisterModal />)}
                                        </Row>
                                    </div> : null}
                                </div>
                            </div>
                        </div>)}
                    </div>
                </Card>
            </div>
        );
    }
}

FourcastMatchWelcomePage.propTypes = {
    loadUser: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});
 

export default connect(mapStateToProps, { loadUser })(FourcastMatchWelcomePage);