import React, { Component } from 'react';
import { 
    Modal, 
    ModalHeader, 
    ModalBody, 
    Button, 
    Table, 
    Row, 
    Label, 
    Col, 
    Form, 
    FormGroup, 
    Input } 
from 'reactstrap';
import './competition.css';
import YelpStarRating from './yelpStarRating';

class PopularBusinessModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            yelpData: props.yelpData,
            category: null,
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
            category: ''
        });
    }

    onChange = (e) => {
        let thisCategory = e.target.value;
        this.setState({ category: thisCategory });
    }

    render() {
        const { yelpData: { businesses: yelpBusinesses } = {}} = this.props;
        let topBusinesses = yelpBusinesses.sort((a, b) => {
            return b.review_count - a.review_count
        });
        
        let filteredBusinesses = topBusinesses.filter((business) => {
            for (let i = 0; i < business.categories.length; i++) {
                if (this.state.category == null) {
                    return business
                } else if (business.categories[i].title.toLowerCase().includes(this.state.category.toLowerCase()) || business.categories[i].alias.toLowerCase().includes(this.state.category.toLowerCase()) || business.name.toLowerCase().includes(this.state.category.toLowerCase())) {
                    return business
                }
            }
        }).map((business) => {
            return (
                <tr>
                    <th style={{ fontSize: 14, width: '35%' }}>
                        {business.name}
                    </th>
                    <td>
                        <YelpStarRating business={business} />
                    </td>
                    <td style={{ fontSize: 14 }}>
                        {business.review_count}
                    </td>
                    <td style={{ fontSize: 14 }}>
                        {business.price}
                    </td>
                    <td style={{ fontSize: 14, width: '20%' }}>
                        {business.categories[0].title}
                    </td>
                    <td style={{ fontSize: 14 }}>
                        {(business.distance * 3.2808).toFixed(0)} (feet)
                    </td>
                </tr>
            )
        });
        
        return (
            <div style={{ position: 'relative' }}>
                <Button
                    color='info'
                    onClick={this.toggle}
                    style={{ boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}
                >
                    {`See All (${filteredBusinesses.length}) Businesses Nearby`}
                </Button>
                <Modal style={{ minWidth: 1100 }} isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle} style={{ height: 125, zIndex: 50, position: 'sticky', top: 0, backgroundColor: 'white' }}>
                        <div style={{ height: 95, backgroundColor: 'white', width: 1025, display: 'grid', gridTemplateColumns: '1fr 2fr' }}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h5>Businesses Nearby</h5>
                            </div>
                            <div>
                                <Form>
                                    <FormGroup>
                                        <Label style={{ fontSize: 16, fontWeight: 'bold', color: 'green' }} for='category'>Search By Category</Label>
                                        <Input 
                                            style={{ width: '100%' }}
                                            type='text'
                                            category='category'
                                            placeholder='E.g. Bakeries'
                                            onChange={this.onChange}
                                        />
                                    </FormGroup>
                                </Form>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody style={{ width: 1000, margin: 'auto', paddingBottom: 50, zIndex: 20 }}>
                    <Row style={{ width: 1100 }}>
                        <Col sm={12}>
                            <Table style={{ maxWidth: 950, marginLeft: 15 }}>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Rating</th>
                                        <th>Reviews</th>
                                        <th>Price</th>
                                        <th>Category</th>
                                        <th>Distance</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredBusinesses}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
 
export default PopularBusinessModal;