import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadUser } from '../../actions/authActions';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { FaRegCopyright } from 'react-icons/fa';
import LoginModal from '../auth/loginModal';

class MobileFooter extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: window.screen.width,
            mobile: true,
        }
    }

    componentDidMount() {
        window && window.addEventListener('resize', this.mobileMode.bind(this));
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth });
        } else {
            this.setState({ mobile: false });
        }        
    }

    componentWillUnmount() {
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
    }

    mobileMode() {
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth, mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }

    
    render() { 
        
        const { screenWidth } = this.state;

        const { user, isAuthenticated } = this.props;
        
        const authLinks = (
            <Row>
                {user ? <Link style={{ fontFamily: 'Arial, Helvetica, sans-serif', color: 'black', fontSize: 16, textDecoration: 'none' }} to={`/user/${user._id}`}>
                    Submit Your Listing
                </Link> : null}
            </Row>
        );

        const guestLinks = (
            <Row>
                <Link style={{ fontFamily: 'Arial, Helvetica, sans-serif', color: 'black', fontSize: 16, textDecoration: 'none' }}>
                    <LoginModal linkText='Submit Your Listing' />
                </Link>
            </Row>
        );
        
        return ( 
            <Container style={{ width: screenWidth, height: 225, position: 'relative' }} fluid>
                <div style={{ width: '100%', height: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', width: '100%', height: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                            <div>
                                <Row style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 18, fontWeight: 'bold', paddingBottom: 10 }}>Company</Row>
                                <Link to="/about" style={{ textDecoration: 'none', color: 'black' }}>
                                    <Row style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16 }}>About</Row>
                                </Link>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                            <div>
                                <Row style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 18, fontWeight: 'bold', paddingBottom: 10 }}>Resources</Row>
                                <Link to="/customSearch" style={{ textDecoration: 'none', color: 'black' }}>
                                    <Row style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16 }}>Match</Row>
                                </Link>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                            <div>
                                <Row style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 18, fontWeight: 'bold', paddingBottom: 10 }}>Connect</Row>
                                <Link to="/contact" style={{ textDecoration: 'none', color: 'black' }}>
                                    <Row style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16, paddingBottom: 10 }}>Contact</Row>
                                </Link>
                                { isAuthenticated ? authLinks : guestLinks}
                            </div>
                        </div>
                    </div>
                </div>
                <hr />
                <div style={{ width: '100%', height: '20%', display: 'grid', gridTemplateColumns: '2fr 2fr 1fr 1fr' }}>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                        <div>
                            <span><FaRegCopyright style={{ height: 12, width: 12 }} /></span><span style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 12 }}> 2023 Fourcast</span>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                        <span style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 12 }}>All rights reserved</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                        <span style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 12 }}>Terms</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                        <span style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 12 }}>Privacy</span>
                    </div>
                </div>
            </Container>
        );
    }
}

MobileFooter.propTypes = {
    loadUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});
 

export default connect(mapStateToProps, { loadUser })(MobileFooter);