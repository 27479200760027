import React, { Component } from 'react';
import { Table, List, ListItem } from '@mui/material';
import poweredByGoogle from '../../../Assets/powered_by_google_on_white.png';


class NearbyPlacesComparison extends Component {
    

    render() { 

        const { listings } = this.props;

        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.scores.fourcastScore - a.scores.fourcastScore});
        
        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }

        

        let thisListingsMostVisitedPlaces = (thisListing) => {
            
            
            const poi1Name = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi1 && thisListing.scores.POI.poi1.name && thisListing.scores.POI.poi1.name;
            const poi2Name = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi2 && thisListing.scores.POI.poi2.name && thisListing.scores.POI.poi2.name;
            const poi3Name = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi3 && thisListing.scores.POI.poi3.name && thisListing.scores.POI.poi3.name;
        
            const poi1Icon = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi1 && thisListing.scores.POI.poi1.icon && thisListing.scores.POI.poi1.icon;
            const poi2Icon = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi2 && thisListing.scores.POI.poi2.icon && thisListing.scores.POI.poi2.icon;
            const poi3Icon = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi3 && thisListing.scores.POI.poi3.icon && thisListing.scores.POI.poi3.icon;

            const poi1Major = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi1 && thisListing.scores.POI.poi1.majorAttraction && thisListing.scores.POI.poi1.majorAttraction;
            const poi2Major = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi2 && thisListing.scores.POI.poi2.majorAttraction && thisListing.scores.POI.poi2.majorAttraction;
            const poi3Major = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi3 && thisListing.scores.POI.poi3.majorAttraction && thisListing.scores.POI.poi3.majorAttraction;
        
            const distance1Elem = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi1 && thisListing.scores.POI.poi1.distance && thisListing.scores.POI.poi1.distance.elements && thisListing.scores.POI.poi1.distance.elements;
            const distance2Elem = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi2 && thisListing.scores.POI.poi2.distance && thisListing.scores.POI.poi2.distance.elements && thisListing.scores.POI.poi2.distance.elements;
            const distance3Elem = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi3 && thisListing.scores.POI.poi3.distance && thisListing.scores.POI.poi3.distance.elements && thisListing.scores.POI.poi3.distance.elements;
        
            let poi1Distance = '';
            let poi2Distance = '';
            let poi3Distance = '';

            if (distance1Elem) {
                for (let i = 0; i < distance1Elem.length; i++ ) {
                    poi1Distance = distance1Elem[i].duration && distance1Elem[i].duration.text;
                }
            }

            if (distance2Elem) {
                for (let i = 0; i < distance2Elem.length; i++ ) {
                    poi2Distance = distance2Elem[i].duration && distance2Elem[i].duration.text;
                }
            }

            if (distance3Elem) {
                for (let i = 0; i < distance3Elem.length; i++ ) {
                    poi3Distance = distance3Elem[i].duration && distance3Elem[i].duration.text;
                }
            }

            const poi1Obj = {name: poi1Name, icon: poi1Icon, major: poi1Major, distance: poi1Distance};
            const poi2Obj = {name: poi2Name, icon: poi2Icon, major: poi2Major, distance: poi2Distance};
            const poi3Obj = {name: poi3Name, icon: poi3Icon, major: poi3Major, distance: poi3Distance};

            const placesArr = [poi1Obj, poi2Obj, poi3Obj];
            return placesArr;
        }

        
        let displayFilterTags = (places) => {
            return (
                <>
                    {places.length ? places.map(place => 
                        (<List style={{ padding: 0 }}>
                            <ListItem style={{ padding: 10 }}>
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'start' }}>
                                        <div>{(place && place.major && (place.major === true)) ? <img alt='major attraction star desc' style={{ height: 25, width: 25 }} src="https://img.icons8.com/fluent/48/000000/star.png"/> : null}</div>
                                        <div style={{ fontWeight: 800, marginLeft: 5 }}>{place && place.name && place.name}</div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'left', marginLeft: 5 }}>
                                        <div>{`${place && place.distance && place.distance} walk`}</div>
                                        <div style={{ marginLeft: 10 }}>
                                            <img style={{ height: 20, width: 20 }} src={place && place.icon && place.icon} />
                                        </div>
                                    </div>
                                </div>
                            </ListItem>
                        </List>)) 
                    : (<ListItem>None</ListItem>)}
                </>
            )
        }
        
        
        
        let filteredListingMostVisitedPlaces = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsPois = thisListingsMostVisitedPlaces(listing);
            return (
                <td style={{ fontSize: 14, padding: 20 }}>
                    {thisListingsPois && displayFilterTags(thisListingsPois)}
                </td>
            )
        });
        
        
        
        return (
            <div style={{ position: 'relative', width: '98%' }}>
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Nearby Places</div>
                    <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}>
                        <img src={poweredByGoogle} style={{ background: 'transparent' }} alt='POI Powered By Google' />
                    </div>
                </div>
                <div style={{ marginTop: 10 }}>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                        <div>
                            <img alt='major attraction star desc' style={{ height: 20, width: 20 }} src="https://img.icons8.com/fluent/48/000000/star.png"/>
                        </div>
                        <div style={{ fontStyle: 'italic', marginLeft: 5, fontSize: 14, paddingTop: 5 }}>Major Attraction</div>
                    </div>
                    <div style={{ marginTop: 5 }}>
                        <Table style={{ tableLayout: 'fixed' }}>
                            <thead>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    {sortedByFourcastScore && sortedByFourcastScore.map(listing => {return (<th style={{ padding: 20, color: 'darkgreen' }}>{addressStyle(listing.listingAddress)}</th>)})}
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    {filteredListingMostVisitedPlaces}
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
                <hr />
            </div>
        );
    }
}

export default NearbyPlacesComparison;