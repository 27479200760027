import React, { Component } from 'react';
import { GoogleMap, LoadScript, StreetViewPanorama } from '@react-google-maps/api';
import { Container, Col, Row } from 'react-bootstrap';
import { Card, CardContent, Fab } from '@material-ui/core';
import { Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FaStore } from 'react-icons/fa';
import './property.css';
import BusyRoadTraffic from '../traffic/busyRoadTraffic';

class Property extends Component {
    
    render() { 
        const { thisListing, scores } = this.props;

        const mapContainerStyle = {
            height: "450px",
            width: "750px"
        };

        const center = {
            lat: thisListing.latitude,
            lng: thisListing.longitude
        };
        

        const propFilterTags = [];

        if (thisListing.propertyFeatures) {
            for (let i = 0; i < thisListing.propertyFeatures.length; i ++) {
                
                if (thisListing.propertyFeatures[i].includes('cornerLocation')) {
                    propFilterTags.push({tagName: 'Corner Location', tagDescription: "This space is on a corner."});
                } else if (thisListing.propertyFeatures[i].includes('bigFrontage')) {
                    propFilterTags.push({tagName: 'Big Frontage', tagDescription: "This property has a relatively large frontage."});
                } else if (thisListing.propertyFeatures[i].includes('divisible')) {
                    propFilterTags.push({tagName: 'Divisible', tagDescription: "This space can be divided."});
                } else if (thisListing.propertyFeatures[i].includes('multiLevel')) {
                    propFilterTags.push({tagName: 'Multi Level', tagDescription: "This space has multiple floors."});
                } else if (thisListing.propertyFeatures[i].includes('newConstruction')) {
                    propFilterTags.push({tagName: 'New Construction', tagDescription: "This is a newly constructed space."});
                } else if (thisListing.propertyFeatures[i].includes('outdoorSpace')) {
                    propFilterTags.push({tagName: 'Outdoor Space', tagDescription: "This location has outdoor space."});
                }
            }
        }

        const featureNames = ['Big Frontage', 'Corner Location', 'Divisible', 'Multi Level', 'New Construction', 'Outdoor Space'];

        const FilterTagTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} />
            ))(({ theme }) => ({
                [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: 'snow',
                color: 'black',
                fontWeight: 'bold',
                maxWidth: 300,
                fontSize: theme.typography.pxToRem(14),
                padding: 25,
                border: '2px solid rgb(52, 181, 122)',
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                },
        }));
        
        
        return (
            <Container>
                <div className='propertyTagsArea'>
                    <div style={{ height: '100%', alignItems: 'center', display: 'grid', gridTemplateColumns: '2fr 1fr', gap: 5 }}>
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <h5>Property Score:</h5>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <h3 style={{ textShadow: '.5px .5px 1px rgb(21, 134, 84)', border: '2px solid green', borderRadius: '50%', width: 60, height: 60, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>{scores.propertyScore}</h3>
                        </div>
                    </div>
                    <div style={{ height: '100%', display: 'grid', gridTemplateColumns: '1fr 5fr', gap: 5 }}>
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <h5>Tags:</h5>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                            {propFilterTags.length ? propFilterTags.map(tag => (
                                <span>
                                    <FilterTagTooltip arrow
                                        title={
                                            <>
                                                <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>{tag.tagDescription}</p>
                                            </>
                                        }
                                    >
                                        <Fab
                                            variant="extended"
                                            size="small"
                                            style={{ backgroundColor: 'rgb(52, 181, 122)', fontSize: 12, color: 'whitesmoke', marginLeft: 10, outline: 'none', padding: 15, lineHeight: 1.25 }}
                                            >
                                            {tag.tagName}
                                        </Fab>
                                    </FilterTagTooltip>
                                </span>
                            )) : (<p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'left', marginLeft: 15, marginTop: 10 }}>None</p>)}
                        </div>
                    </div>
                </div>
                <hr />
                <div style={{ marginTop: 25 }}>
                    <Row style={{ height: 800, width: 1200 }}>
                        <Col sm={8}>
                            <div style={{ height: 20, fontSize: 11 }}>Warning: Street View may not be pointing directly at the space listed.</div>
                            <div style={{ marginTop: 10 }}>
                                <LoadScript
                                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                >
                                    <GoogleMap
                                        id="property-page-streetview"
                                        mapContainerStyle={mapContainerStyle}
                                        zoom={8}
                                        center={center}
                                    >
                                    <StreetViewPanorama
                                        position={center}
                                        visible={true}
                                    />
                                    </GoogleMap>
                                </LoadScript>
                            </div>
                        </Col>
                        <Col sm={4}>
                            <div className='propertyContent'>
                                <Row style={{ height: 375 }}>
                                    <Card className="propertyCharacteristics" style={{ height: 725, width: 400 }}>
                                        <CardContent>
                                            <div className='attributesTitle'>Property Attributes:</div>
                                            <div className="propertyHighlights">
                                                <Row style={{ height: 60 }}>
                                                    <Col sm={5}>
                                                        <div className='attributeName'>Square Feet:</div>
                                                    </Col>
                                                    <Col sm={7}>
                                                        <div className='attributeValue'>{thisListing.squareFeet.length > 1 ? `${thisListing.squareFeet[0]} - ${thisListing.squareFeet[1]}` : `${thisListing.squareFeet}`}</div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ height: 60 }}>
                                                    <Col sm={5}>
                                                        <div className='attributeName'>Price Per Sqft:</div>
                                                    </Col>
                                                    <Col sm={7}>
                                                        <div className='attributeValue'>{thisListing.pricePerSquareFoot !== -1 ? `$${thisListing.pricePerSquareFoot}` : 'Undisclosed'}</div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6}>
                                                        <div className='attributeName'>Property Features:</div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ height: 125, paddingTop: 20 }}>
                                                    {featureNames.map(feature => (
                                                        <span><Fab
                                                            variant="extended"
                                                            disabled={!propFilterTags.includes(feature)}
                                                            size="small"
                                                            style={propFilterTags.includes(feature) ? { backgroundColor: 'rgba(14, 119, 45, 0.9)', fontSize: 10, color: 'whitesmoke', marginLeft: 5, outline: 'none', padding: 10 } : { fontSize: 10, marginLeft: 5, outline: 'none', padding: 10 }}
                                                            >
                                                            {feature}
                                                        </Fab></span>
                                                    ))}
                                                </Row>
                                                <hr />
                                                <Row style={{ height: 20 }}>
                                                    <Col sm={5}>
                                                        <div className='attributeName'>Frontage</div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ height: 130 }}>
                                                    <div className='frontageData'>
                                                        <FaStore className='frontageIcon' />
                                                        <div className="frontageMeasure"></div>
                                                        <div className='frontageValue'>{thisListing.frontage ? `${thisListing.frontage} ft` : 'n/a'}*</div>
                                                    </div>
                                                    <div style={{ fontSize: 8, fontWeight: 600 }}>*not confirmed</div>
                                                </Row>
                                                <hr />
                                                <Row style={{ height: 20 }}>
                                                    <Col sm={5}>
                                                        <div className='attributeName'>Visibility</div>
                                                    </Col>
                                                </Row>
                                                <Row style={{ height: 150, marginTop: -15 }}>
                                                    <div className='visibility'>
                                                        <BusyRoadTraffic thisListing={thisListing} />
                                                    </div>
                                                </Row>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        );
    }
}

export default Property;

