import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { Button } from '@material-ui/core';
import mapScreenshot from '../../Assets/productFeatures_mapHighlight.png';
import laptop from '../../Assets/productFeatures_laptop.png';
import './description.css';
import { display } from '@mui/system';

class MobileUXDescription extends Component {
    

    constructor(props) {
        super(props);
        this.state = {
            screenWidth: window.screen.width,
            mobile: true,
            lineFontSize: 20
        }
    }

    componentDidMount() {
        window && window.addEventListener('resize', this.mobileMode.bind(this));
        window && window.addEventListener('resize', this.setFontSizes.bind(this));
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth });
        } else {
            this.setState({ mobile: false });
        }

        if (screenWidth && (screenWidth > 900)) {
            this.setState({ lineFontSize: 40 })
        } else if (screenWidth && (screenWidth > 800)) {
            this.setState({ lineFontSize: 36 })
        } else if (screenWidth && (screenWidth > 700)) {
            this.setState({ lineFontSize: 32 })
        } else if (screenWidth && (screenWidth > 600)) {
            this.setState({ lineFontSize: 28 })
        } else if (screenWidth && (screenWidth > 500)) {
            this.setState({ lineFontSize: 24 })
        } else {
            this.setState({ lineFontSize: 20 })
        }
    }

    componentWillUnmount() {
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
        window && window.removeEventListener('resize', this.setFontSizes.bind(this));
    }

    mobileMode() {
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth, mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }

    setFontSizes() {
        let screenWidth = window.screen.width;
        if (screenWidth && (screenWidth > 900)) {
            this.setState({ lineFontSize: 44 })
        } else if (screenWidth && (screenWidth > 800)) {
            this.setState({ lineFontSize: 40 })
        } else if (screenWidth && (screenWidth > 700)) {
            this.setState({ lineFontSize: 36 })
        } else if (screenWidth && (screenWidth > 600)) {
            this.setState({ lineFontSize: 32 })
        } else if (screenWidth && (screenWidth > 500)) {
            this.setState({ lineFontSize: 28 })
        } else {
            this.setState({ lineFontSize: 24 })
        }
    }

    
    render() { 
        
        const { screenWidth, lineFontSize } = this.state;

        return ( 
            <Container style={{ width: screenWidth, position: 'relative', paddingTop: 100, paddingBottom: 50 }} fluid>
                <div style={{ width: '100%', height: '95%', margin: 'auto' }}>
                    <div className='perfectSpace' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '15%' }}>
                        <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                            <h2>Streamline Online Property Searching</h2>
                        </div>
                    </div>
                    <div style={{ height: '35%' }}>
                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '90%' }}>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: lineFontSize, paddingLeft: 2, marginTop: 20 }}>Narrow your property search and expedite the site-selection process by getting a better understanding of each available space and the area it's in. Easily eliminate unviable options and discover potential matches all without requiring you to physically visit any.</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: '50%', paddingTop: 50, paddingBottom: 50 }}>
                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '100%' }}>
                                <div style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Link to='/listings' style={{ textDecoration: 'none' }}>
                                        <Button id="explorePropertiesBtn" style={{ width: 250, fontSize: 16 }}>
                                            Get Started
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
         );
    }
}
 
export default MobileUXDescription;