import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import '../../listingDetailsLayouts/demographics/demographics.css';
import '../compareListings.css';

class RentOwnComparison extends Component {
    
    
    render() {

        const { listings } = this.props;

        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.scores.fourcastScore - a.scores.fourcastScore});
        
        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }

        let getThisListingsRentOwn = (thisListing) => {
            
            const geoIdV4 = thisListing && thisListing.attomData && thisListing.attomData.geoIdV4 && thisListing.attomData.geoIdV4;

            const getOwnerDwellings = [];
            const getRenterDwellings = [];

            if (geoIdV4 && geoIdV4.length) {
    
                for (let g = 0; g < geoIdV4.length; g++) {
                    
                    const theseOwnerDwellings = (geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.housing_Units_Owner_Occupied));
                    const theseRenterDwellings = (geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.housing_Units_Renter_Occupied));
                    getOwnerDwellings.push(theseOwnerDwellings);
                    getRenterDwellings.push(theseRenterDwellings);
    
                }
                
            }

            // RENT VS OWN GRAPH DATA
            const renterDwellings = getRenterDwellings && getRenterDwellings.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });
            const ownerDwellings = getOwnerDwellings && getOwnerDwellings.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });

            const thisRentOwnData = {
                rentOwnData: {
                    labels: ['Renters', 'Owners'],
                    datasets: [
                        {
                            backgroundColor: ['rgb(52, 181, 122)', 'rgb(187, 236, 205)'],
                            data: [renterDwellings, ownerDwellings]
                        }
                    ],
                }
            };

            
            const rentOwnObj = {address: thisListing.listingAddress, thisRentOwnData: thisRentOwnData};
            return rentOwnObj;
        }


        // SORT BY LISTING
        const filteredListingsRentOwn = sortedByFourcastScore && sortedByFourcastScore.map(listing => getThisListingsRentOwn(listing));
        
        let listing1 = (filteredListingsRentOwn && filteredListingsRentOwn.length && (filteredListingsRentOwn[0] !== undefined)) ? filteredListingsRentOwn[0] : null;
        let listing2 = (filteredListingsRentOwn && filteredListingsRentOwn.length && (filteredListingsRentOwn[1] !== undefined)) ? filteredListingsRentOwn[1] : null;
        let listing3 = (filteredListingsRentOwn && filteredListingsRentOwn.length && (filteredListingsRentOwn[2] !== undefined)) ? filteredListingsRentOwn[2] : null;
        let listing4 = (filteredListingsRentOwn && filteredListingsRentOwn.length && (filteredListingsRentOwn[3] !== undefined)) ? filteredListingsRentOwn[3] : null;
        

        return (
            <div style={{ position: 'relative', width: '98%' }}>
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', marginTop: 25, paddingBottom: 40, justifyContent: 'space-around' }}>
                    {listing1 !== null ? (<div style={{ position: 'relative', height: 175, width: '25%' }}>
                        <Pie
                            data={listing1.thisRentOwnData.rentOwnData}
                            height={175}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Rent vs Own',
                                    fontSize: 14,
                                    color: 'black'
                                },
                                plugins: {
                                    datalabels: {
                                    display: false,
                                    },
                                    legend: {
                                        display: true,
                                        position: 'top',
                                    }
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false
                                        },
                                        ticks: {
                                            display: false
                                        }
                                    },
                                },
                            }}
                        />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing1.address)}</div>
                    </div>) : null}
                    {listing2 !== null ? (<div style={{ position: 'relative', height: 175, width: '25%' }}>
                        <Pie
                            data={listing2.thisRentOwnData.rentOwnData}
                            height={175}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Rent vs Own',
                                    fontSize: 14,
                                    color: 'black'
                                },
                                plugins: {
                                    datalabels: {
                                    display: false,
                                    },
                                    legend: {
                                        display: true,
                                        position: 'top',
                                    }
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false
                                        },
                                        ticks: {
                                            display: false
                                        }
                                    },
                                },
                            }}
                        />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing2.address)}</div>
                    </div>) : null}
                    {listing3 !== null ? (<div style={{ position: 'relative', height: 175, width: '25%' }}>
                        <Pie
                            data={listing3.thisRentOwnData.rentOwnData}
                            height={175}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Rent vs Own',
                                    fontSize: 14,
                                    color: 'black'
                                },
                                plugins: {
                                    datalabels: {
                                    display: false,
                                    },
                                    legend: {
                                        display: true,
                                        position: 'top',
                                    }
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false
                                        },
                                        ticks: {
                                            display: false
                                        }
                                    },
                                },
                            }}
                        />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing3.address)}</div>
                    </div>) : null}
                    {listing4 !== null ? (<div style={{ position: 'relative', height: 175, width: '25%' }}>
                        <Pie
                            data={listing4.thisRentOwnData.rentOwnData}
                            height={175}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Rent vs Own',
                                    fontSize: 14,
                                    color: 'black'
                                },
                                plugins: {
                                    datalabels: {
                                    display: false,
                                    },
                                    legend: {
                                        display: true,
                                        position: 'top',
                                    }
                                },
                                responsive: true,
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        grid: {
                                            display: false
                                        },
                                        ticks: {
                                            display: false
                                        }
                                    },
                                },
                            }}
                        />
                        <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing4.address)}</div>
                    </div>) : null}
                </div>
            </div>
        );
    }
}

export default RentOwnComparison;