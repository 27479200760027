import React, { Component } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Card, CardContent, Fab } from '@material-ui/core';
import { LinearProgress } from '@mui/material';
import { BsPeopleFill } from 'react-icons/bs';
import { GiHouse } from 'react-icons/gi';
import { FaRegMoneyBillAlt, FaChild } from 'react-icons/fa';
import { ImManWoman } from 'react-icons/im';
import { MdChildFriendly } from 'react-icons/md';
import '../listingDetailsLayouts/demographics/demographics.css';
import IncomeGraph from '../listingDetailsLayouts/demographics/incomeGraph';
import RentOwnGraph from '../listingDetailsLayouts/demographics/rentOwnGraph';
import AgeGraph from '../listingDetailsLayouts/demographics/ageGraph';
import FamilyStatusGraph from '../listingDetailsLayouts/demographics/familyStatusGraph';
import CommonPeople from '../listingDetailsLayouts/demographics/commonPeople';
import ResPriceGauge from '../listingDetailsLayouts/demographics/resPriceGauge';
import IncomeGauge from '../listingDetailsLayouts/demographics/incomeGauge';
import ChildrenHousesGauge from '../listingDetailsLayouts/demographics/childrenHouses';
import GreenArrow from '../../Assets/greenArrow.png';
import RedArrow from '../../Assets/redArrow.png';
const greenArrow = <img className='greenArrow' src={GreenArrow} alt='' />;
const redArrow = <img className='redArrow' src={RedArrow} alt='' />;


class SearchAddressDemographics extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        }
    }

    componentDidMount() {
        const { loaded } = this.state;
        if (loaded === false) {
            const timer = setTimeout(() => {
                this.setState({ loaded: true })
            }, 2000);
            return () => clearTimeout(timer);
        }
    }

    render() { 

        const { filterables, scores, filterTags, screenWidth } = this.props;
        const attomData = this.props.attomData.commId ? {commV4Data: this.props.attomData.commData} : this.props.attomData;
        
        // INCOME GRAPH DATA

        let averageHouseholdIncome = 0;
        let averageIndividualIncome = 0;
        const getTotalNumOfHouseholds = [];

        // number of people in each income bracket
        let householdIncomeFifteenOrLess = 0;
        let householdIncomeFifteenToTwentyFive = 0;
        let householdIncomeTwentyFiveToThirtyFive = 0;
        let householdIncomeThirtyFiveToFifty = 0;
        let householdIncomeFiftyToSeventyFive = 0;
        let householdIncomeSeventyFiveToOneHundred = 0;
        let householdIncomeOneHundredToOneHundredTwentyFive = 0;
        let householdIncomeOneHundredTwentyFiveToOneHundredFifty = 0;
        let householdIncomeOneHundredFiftyToTwoHundred = 0;
        let householdIncomeTwoHundredAndMore = 0;
        
            
        // avg income numbers
        let primaryIndIncArea = null;
        let primaryHouseIncArea = null;
        
       
        primaryHouseIncArea = attomData && attomData.commV4Data && attomData.commV4Data.avg_Household_Income;
        primaryIndIncArea = attomData && attomData.commV4Data && attomData.commV4Data.household_Income_Per_Capita;
        // income bracket percentages
        const getGlobalTotalHouseholdsFamNum = attomData && attomData.commV4Data && attomData.commV4Data.households_Non_Family;
        const getGlobalTotalHouseholdsNonFamNum = attomData && attomData.commV4Data && attomData.commV4Data.households_Family;
        const globalTotalHouseholds = (getGlobalTotalHouseholdsFamNum + getGlobalTotalHouseholdsNonFamNum);
        getTotalNumOfHouseholds.push(globalTotalHouseholds);
        householdIncomeFifteenOrLess += Math.ceil(globalTotalHouseholds * (attomData && attomData.commV4Data && attomData.commV4Data.households_Income_Less_Than_15000_Pct * .01));
        householdIncomeFifteenToTwentyFive += Math.ceil(globalTotalHouseholds * (attomData && attomData.commV4Data && attomData.commV4Data.households_Income_15000_24999_Pct * .01));
        householdIncomeTwentyFiveToThirtyFive += Math.ceil(globalTotalHouseholds * (attomData && attomData.commV4Data && attomData.commV4Data.households_Income_25000_34999_Pct * .01));
        householdIncomeThirtyFiveToFifty += Math.ceil(globalTotalHouseholds * (attomData && attomData.commV4Data && attomData.commV4Data.households_Income_35000_49999_Pct * .01));
        householdIncomeFiftyToSeventyFive += Math.ceil(globalTotalHouseholds * (attomData && attomData.commV4Data && attomData.commV4Data.households_Income_50000_74999_Pct * .01));
        householdIncomeSeventyFiveToOneHundred += Math.ceil(globalTotalHouseholds * (attomData && attomData.commV4Data && attomData.commV4Data.households_Income_75000_99999_Pct * .01));
        householdIncomeOneHundredToOneHundredTwentyFive += Math.ceil(globalTotalHouseholds * (attomData && attomData.commV4Data && attomData.commV4Data.households_Income_100000_124999_Pct * .01));
        householdIncomeOneHundredTwentyFiveToOneHundredFifty += Math.ceil(globalTotalHouseholds * (attomData && attomData.commV4Data && attomData.commV4Data.households_Income_125000_149999_Pct * .01));
        householdIncomeOneHundredFiftyToTwoHundred += Math.ceil(globalTotalHouseholds * (attomData && attomData.commV4Data && attomData.commV4Data.households_Income_150000_199999_Pct * .01));
        householdIncomeTwoHundredAndMore += Math.ceil(globalTotalHouseholds * (attomData && attomData.commV4Data && attomData.commV4Data.households_Income_200000_And_Over_Pct * .01));
        
        
        let avgHouseInc = primaryHouseIncArea;
        let avgIndInc = primaryIndIncArea;
        averageIndividualIncome = avgIndInc;
        averageHouseholdIncome = avgHouseInc;

        // number of people in each income bracket
        const graphIncomeUnderSeventyFive = [householdIncomeFifteenOrLess, householdIncomeFifteenToTwentyFive, householdIncomeTwentyFiveToThirtyFive, householdIncomeThirtyFiveToFifty, householdIncomeFiftyToSeventyFive];
        const graphIncomeSeventyFiveToOneFifty = [householdIncomeSeventyFiveToOneHundred, householdIncomeOneHundredToOneHundredTwentyFive, householdIncomeOneHundredTwentyFiveToOneHundredFifty];
        
        const underSeventyFiveSum = graphIncomeUnderSeventyFive.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });
        const seventyFiveToOneFiftySum = graphIncomeSeventyFiveToOneFifty.reduce(function (accumulator, current) {
            return (parseFloat(accumulator) + parseFloat(current));
        });
        const oneFiftyToTwoHundredSum = parseFloat(householdIncomeOneHundredFiftyToTwoHundred);
        const overTwoHundredSum = parseFloat(householdIncomeTwoHundredAndMore);

        const thisIncomeData = {
            incomeData: {
                labels: [' Under $75k', '$75k-$150k', '$150k-$200k', 'Over $200k'],
                datasets: [
                    {
                        backgroundColor: ['rgb(187, 236, 205)', 'rgb(124, 218, 158)', 'rgb(60, 200, 112)', 'rgb(21, 76, 41)'],
                        data: [underSeventyFiveSum, seventyFiveToOneFiftySum, oneFiftyToTwoHundredSum, overTwoHundredSum]
                    }
                ],
            }
        };

        // REAL ESTATE DATA

        const medianSalePrice = attomData && attomData.commV4Data && attomData.commV4Data.housing_Owner_Households_Median_Value;
        const medianRentPrice = attomData && attomData.commV4Data && attomData.commV4Data.housing_Median_Rent;
    

        // RENT VS OWN GRAPH DATA

        const ownerDwellings = attomData && attomData.commV4Data && attomData.commV4Data.housing_Units_Owner_Occupied;
        const renterDwellings = attomData && attomData.commV4Data && attomData.commV4Data.housing_Units_Renter_Occupied;

        const thisRentOwnData = {
            rentOwnData: {
                labels: ['Renters', 'Owners'],
                datasets: [
                    {
                        backgroundColor: ['rgb(52, 181, 122)', 'rgb(187, 236, 205)'],
                        data: [renterDwellings, ownerDwellings]
                    }
                ],
            }
        };
        
                
        const totalPopulation = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.population);
        const thisDayPop = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.population_Daytime);
        
        const zeroToFivePct = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.population_Aged_0_5_Pct);
        const sixTo11Pct = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.population_Aged_6_11_Pct);
        const twelveTo17Pct = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.population_Aged_12_17_Pct);
        const e18To24Pct = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.population_Aged_18_24_Pct);
        const t25To34Pct = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.population_Aged_25_34_Pct);
        const t35To44Pct = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.population_Aged_35_44_Pct);
        const f45To54Pct = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.population_Aged_45_54_Pct);
        const f55To64Pct = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.population_Aged_55_64_Pct);
        const s65To74Pct = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.population_Aged_65_74_Pct);
        const s75To84Pct = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.population_Aged_75_84_Pct);
        
        const currentPop = (totalPopulation);
        const daytimePop = (thisDayPop);
        const elevenAndUnder = ((totalPopulation * (zeroToFivePct * .01)) + (totalPopulation * (sixTo11Pct * .01))).toFixed(0);
        const twelveToSeventeen = (totalPopulation * (twelveTo17Pct * .01)).toFixed(0);
        const eighteenTo24 = (totalPopulation * (e18To24Pct * .01)).toFixed(0);
        const twentyfiveTo34 = (totalPopulation * (t25To34Pct * .01)).toFixed(0);
        const thirtyfiveTo44 = (totalPopulation * (t35To44Pct * .01)).toFixed(0);
        const fortyfiveTo54 = (totalPopulation * (f45To54Pct * .01)).toFixed(0);
        const fiftyfiveTo64 = (totalPopulation * (f55To64Pct * .01)).toFixed(0);
        const sixtyfiveToSeventyFour = (totalPopulation * (s65To74Pct * .01)).toFixed(0);
        const seventyFiveAndUp = (totalPopulation * (s75To84Pct * .01)).toFixed(0);


        const thisAgeData = {
            ageData: {
                labels: [['0-11'], ['12-17'], ['18-24'], ['25-34'], ['35-44'], ['45-54'], ['55-64'], ['65-74'], ['75+']],
                datasets: [
                    {
                        label: 'Number of People Living in Area',
                        backgroundColor: [
                            'rgb(42, 207, 219)',
                            'rgb(255, 105, 180)',
                            'rgb(255, 105, 180)',
                            'rgb(52, 181, 122)',
                            'rgb(52, 181, 122)',
                            'rgb(219, 136, 42)',
                            'rgb(0, 0, 128)',
                            'rgb(0, 0, 128)',
                            'rgb(42, 207, 219)',
                        ],
                        data: [elevenAndUnder, twelveToSeventeen, eighteenTo24, twentyfiveTo34, thirtyfiveTo44, fortyfiveTo54, fiftyfiveTo64, sixtyfiveToSeventyFour, seventyFiveAndUp]
                    },
                ]
            }
        };


        // POP CHANGES

        const popChange = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.population_Chg_Pct_2010).toFixed(0);
        const pop5YearForecastTotal = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.population_5_Yr_Projection);
        
        const thisCurrentPop = parseFloat(currentPop);
        const fiveYearPop = parseFloat(pop5YearForecastTotal);
        const popForecast5 = ((fiveYearPop - thisCurrentPop)/fiveYearPop * 100);




        // FAMILY GRAPH DATA

        const familyHouseholds = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.households_Family);
        const nonFamilyHouseholds = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.households_Non_Family);

        const thisFamilyData = {
            familyData: {
                labels: ['Family Households', 'Non-Family Households'],
                datasets: [
                    {
                        label: 'Number of Households',
                        backgroundColor: [
                            'rgb(187, 236, 205)',
                            'rgb(52, 181, 122)'
                        ],
                        data: [familyHouseholds, nonFamilyHouseholds]
                    },
                ]
            }
        };


        // HOUSEHOLDS WITH CHILDREN 

        const totalHouseholdsNum = (familyHouseholds + nonFamilyHouseholds);
        const famHouseWithChildPct = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.households_Family_W_Children_Pct);
        const famHouseWithChildNum = (totalHouseholdsNum * (famHouseWithChildPct * .01));
        const nonFamHouseWithChildPct = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.households_Non_Family_W_Children_Pct);
        const nonFamHouseWithChildNum = (totalHouseholdsNum * (nonFamHouseWithChildPct * .01));
        const householdsWChildrenNum = (famHouseWithChildNum + nonFamHouseWithChildNum);
        const householdsWithChildren = Math.floor(householdsWChildrenNum);


        // MOST COMMON PEOPLE 

        let totalWorkersPop = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.occupation_Soc_Cnt);
        let whiteCollarPct = attomData && attomData.commV4Data && parseFloat(attomData.commV4Data.occupation_White_Collar_Pct);
        let studentsNum = (attomData && attomData.commV4Data && (parseFloat(attomData.commV4Data.enrollment_Public_Undergraduate_College_Pct) + parseFloat(attomData.commV4Data.enrollment_Private_Undergraduate_College_Pct) + parseFloat(attomData.commV4Data.enrollment_Private_Graduate_Or_Professional_School_Pct) + parseFloat(attomData.commV4Data.enrollment_Public_Graduate_Or_Professional_School_Pct)));
        const employeePop = (totalWorkersPop * (whiteCollarPct * .01));
        const thisStudentPop = (totalPopulation * (studentsNum * .01));

        const studentPop = filterTags && filterTags.includes('students') ? thisStudentPop : 0;


        const commaNumber = (inc) => {
            let newNum = Number(inc);
            let numString = newNum.toLocaleString("en-US");
            return numString;
        }
        
        return (
            <>{((screenWidth === undefined) || (screenWidth > 1000)) ? (<Container>
                {((this.state.loaded === true) && (attomData !== undefined)) ? (<div>
                    <Row style={{ height: 150, width: 1100, margin: 'auto' }}>
                        <Col sm={4}>
                            <Card className='keyPointsData'>
                                <CardContent>
                                    {(scores && (scores !== null) && (this.state.loaded === true)) ? <div className='keyPointsContent'>
                                        <BsPeopleFill id='popIconHighlight' />
                                        <div id='topPeopleTitle'>Common People In The Area</div>
                                        <CommonPeople scores={scores} studentPop={studentPop} currentPop={currentPop} daytimePop={daytimePop} employeePop={employeePop} />
                                    </div> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: 12, fontWeight: 'bold' }}>No Common People Chart Available</div>}
                                </CardContent>
                            </Card>
                        </Col>
                        <Col sm={4}>
                            <Card className='keyPointsData'>
                                <CardContent>
                                {(scores && (scores !== null) && (this.state.loaded === true)) ? <div className='keyPointsContent'>
                                        <GiHouse id='resIconHighlight' />
                                        <div id='resHighlightTitle'>Residential Price Gauge</div>
                                        <ResPriceGauge scores={scores} />
                                    </div> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: 12, fontWeight: 'bold' }}>No Residential Prices Gauge Available</div>}
                                </CardContent>
                            </Card>
                        </Col>
                        <Col sm={4}>
                            <Card className='keyPointsData'>
                                <CardContent>
                                    <div className='keyPointsContent'>
                                        <FaRegMoneyBillAlt id='incomeIconHighlight' />
                                        <div id='incomeHighlightTitle'>Local Income Gauge</div>
                                        <div id='averageIncomeTitle'>Individual Income</div>
                                        <div className='individualIncomeHighlight'>
                                            <span id='individualIncomeHighlightValue'>{`$${commaNumber(averageIndividualIncome)}`}</span>
                                            <span id='indAvg'>/ Avg</span>
                                        </div>
                                        <div id='householdIncomeTitle'>Household Income</div>
                                        <div className='householdIncomeHighlight'>
                                            <span id='householdIncomeHighlightValue'>{`$${commaNumber(averageHouseholdIncome)}`}</span>
                                            <span id='houseAvg'>/ Avg</span>
                                        </div>
                                        {(scores && (scores !== null) && (this.state.loaded === true)) ? <IncomeGauge scores={scores} /> : null}
                                    </div>
                                </CardContent>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: 25 }}>
                        <div className='demoContent'>
                            <Card className='incomeDataSection'>
                                <CardContent>
                                    <div className='incomeTitle'>Income</div>
                                    <FaRegMoneyBillAlt className='incomeIcon' />
                                    <div className='trendCategoryTitle'>Average Household Income</div>
                                    <div className='incomeChange2010'>
                                        <div className='since2010Title'>Since 2021</div>
                                        <div className='incomeChangeValue'>-</div>
                                        <div className='incomeArrows'>
                                            
                                        </div>
                                    </div>
                                    <div className='fiveForecast'>
                                        <div className='fiveYearForecastTitle'>5 Year Forecast</div>
                                        <div className='incomeChangeValue'>-</div>
                                        <div className='incomeArrows'>
                                            
                                        </div>
                                    </div>
                                    <hr style={{ marginTop: 100 }} />
                                    <div className='incomeGraph'>
                                        <IncomeGraph thisIncomeData={thisIncomeData} />
                                    </div>
                                </CardContent>
                            </Card>
                            <Card style={{ position: 'relative', zIndex: 13, width: 525, height: 340 }}>
                                <CardContent>
                                    <div className='resTitle'>Residential Market</div>
                                    <GiHouse className='resIcon' />
                                    <div className='medSalePrice' style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', paddingLeft: 25 }}>
                                        <div>
                                            <div className='resPricesMedianSales'>Median Sale Price</div>
                                            <div id='medianSalePrice'>{`$${commaNumber(medianSalePrice)}`}</div>
                                        </div>
                                        <div style={{ paddingLeft: 50 }}>
                                            <div className='resPricesMedianSales'>Median Rent</div>
                                            <div id='medianSalePrice'>{`$${commaNumber(medianRentPrice)}`}</div>
                                        </div>
                                    </div>
                                    <hr style={{ marginTop: 100 }}/>
                                    <div className='rentOwn'>
                                        <RentOwnGraph thisRentOwnData={thisRentOwnData} />
                                    </div>
                                </CardContent>
                            </Card>
                            <Card className='populationDataSection' style={{ height: 800, position: 'relative', zIndex: 13, width: 525, marginTop: 25 }}>
                                <CardContent>
                                <div className='populationTitle'>Population</div>
                                <BsPeopleFill className='populationIcon' />
                                <div className='trendCategoryTitle' id='popTrendTitle'>Population in Area</div>
                                <div id='popChange2010'>
                                    <div className='since2010Title'>Since 2010</div>
                                    <div className='popChangeValue'>{`${popChange}%`}</div>
                                    <div className='popArrows' style={{ paddingLeft: 5 }}>
                                        {popChange > 0 ? greenArrow : popChange < 0 ? redArrow : '' }
                                    </div>
                                </div>
                                <div id='popFiveForecast' style={{ paddingLeft: 25 }}>
                                    <div className='fiveYearForecastTitle'>5 Year Forecast</div>
                                    <div className='popChangeValue'>{`${popForecast5.toFixed(0)}%`}</div>
                                    <div className='popArrows' style={{ paddingLeft: 20 }}>
                                        {popForecast5 > 0 ? greenArrow : popForecast5 < 0 ? redArrow : '' }
                                    </div>
                                </div>
                                <hr style={{ marginTop: 100 }} />
                                <div style={{ width: '100%', height: 325, position: 'relative' }}>
                                    <div style={{ height: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className='resPricesMedianSales' style={{ paddingTop: 50 }}>Age Breakdown</div>
                                    </div>
                                    <div style={{ height: '95%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 25 }}>
                                        <div style={{ position: 'relative', width: '100%', display: 'grid', gridTemplateColumns: '2fr 3fr', gap: 10 }}>
                                            <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                                                <div style={{ width: '100%', paddingTop: 20 }}>
                                                    <div style={{ height: 50, backgroundColor: 'rgb(255, 105, 180, .1)', display: 'flex', justifyContent: 'right', alignItems: 'center', fontSize: 12, width: '100%', paddingRight: 15, fontWeight: 'bolder' }}>Gen Z</div>
                                                    <div style={{ height: 50, backgroundColor: 'rgb(52, 181, 122, .1)', display: 'flex', justifyContent: 'right', alignItems: 'center', fontSize: 12, width: '100%', paddingRight: 15, fontWeight: 'bolder' }}>Millennials</div>
                                                    <div style={{ height: 30, backgroundColor: 'rgb(219, 136, 42, .1)', display: 'flex', justifyContent: 'right', alignItems: 'center', fontSize: 12, width: '100%', paddingRight: 15, fontWeight: 'bolder' }}>Gen X</div>
                                                    <div style={{ height: 50, backgroundColor: 'rgb(0, 0, 128, .1)', display: 'flex', justifyContent: 'right', alignItems: 'center', fontSize: 12, width: '100%', paddingRight: 15, fontWeight: 'bolder' }}>Baby Boomers</div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                                <div>
                                                    <AgeGraph width={500} height={400} thisCurrentPop={thisCurrentPop} populationReport thisAgeData={thisAgeData} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className='familyStatusSection'>
                                    <div className='familyGraph'>
                                        <FamilyStatusGraph thisFamilyData={thisFamilyData} />
                                    </div>
                                    <div className='childrenHouseTitle'>Number of Households WITH Children</div>
                                    <ImManWoman className='famChildren' />
                                    <FaChild className='child' />
                                    <MdChildFriendly className='stroller' />
                                    <div className='childHouseCountNumber'>{householdsWithChildren}</div>
                                    {(filterables && (filterables !== null) && (this.state.loaded === true)) ? <ChildrenHousesGauge filterables={filterables} /> : null}
                                </div>
                                </CardContent>
                            </Card>
                        </div>
                    </Row>
                </div>) : <LinearProgress />}
            </Container>) : (<Container style={{ width: screenWidth, marginLeft: -15 }}>
                {((this.state.loaded === true) && (attomData !== undefined)) ? (<div style={{ width: '100%', paddingBottom: 100, paddingTop: 20, position: 'relative' }}>
                    <div style={{ postion: 'relative', paddingBottom: 20 }}>
                        <Card style={{ width: '100%', height: 170, position: 'relative' }}>
                            <CardContent>
                                {(scores && (scores !== null) && (this.state.loaded === true)) ? <div className='keyPointsContent'>
                                    <BsPeopleFill id='popIconHighlight' />
                                    <div id='topPeopleTitle'>Common People In The Area</div>
                                    <CommonPeople scores={scores} studentPop={studentPop} currentPop={currentPop} daytimePop={daytimePop} employeePop={employeePop} screenWidth={screenWidth} />
                                </div> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: 12, fontWeight: 'bold' }}>No Common People Chart Available</div>}
                            </CardContent>
                        </Card>
                    </div>
                    <div style={{ postion: 'relative', paddingBottom: 20 }}>
                        <Card style={{ width: '100%', height: 170, position: 'relative' }}>
                            <CardContent>
                                {(scores && (scores !== null) && (this.state.loaded === true)) ? <div className='keyPointsContent'>
                                    <GiHouse id='resIconHighlight' />
                                    <div id='resHighlightTitle'>Residential Price Gauge</div>
                                    <ResPriceGauge scores={scores} />
                                </div> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: 12, fontWeight: 'bold' }}>No Residential Prices Gauge Available</div>}
                            </CardContent>
                        </Card>
                    </div>
                    <div style={{ postion: 'relative' }}>
                        <Card style={{ width: '100%', height: 170, position: 'relative' }}>
                            <CardContent>
                                <div className='keyPointsContent'>
                                    <FaRegMoneyBillAlt id='incomeIconHighlight' />
                                    <div id='incomeHighlightTitle'>Local Income Gauge</div>
                                    <div id='averageIncomeTitle'>Individual Income</div>
                                    <div className='individualIncomeHighlight'>
                                        <span id='individualIncomeHighlightValue'>{`$${commaNumber(averageIndividualIncome)}`}</span>
                                        <span id='indAvg'>/ Avg</span>
                                    </div>
                                    <div id='householdIncomeTitle'>Household Income</div>
                                    <div className='householdIncomeHighlight'>
                                        <span id='householdIncomeHighlightValue'>{`$${commaNumber(averageHouseholdIncome)}`}</span>
                                        <span id='houseAvg'>/ Avg</span>
                                    </div>
                                    {(scores && (scores !== null) && (this.state.loaded === true)) ? <IncomeGauge scores={scores} /> : null}
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                    <hr />
                    <div style={{ paddingBottom: 20 }}>
                        <Card style={{ position: 'relative', height: 340, width: '100%' }}>
                            <CardContent>
                                <div className='incomeTitle'>Income</div>
                                <FaRegMoneyBillAlt className='incomeIcon' />
                                <div className='trendCategoryTitle'>Average Household Income</div>
                                <div className='incomeChange2010'>
                                    <div className='since2010Title'>Since 2021</div>
                                    <div className='incomeChangeValue'>-</div>
                                    <div className='incomeArrows'>
                                        
                                    </div>
                                </div>
                                <div className='fiveForecast'>
                                    <div className='fiveYearForecastTitle'>5 Year Forecast</div>
                                    <div className='incomeChangeValue'>-</div>
                                    <div className='incomeArrows'>
                                        
                                    </div>
                                </div>
                                <hr style={{ marginTop: 100 }} />
                                <div style={{ maxWidth: 325, position: 'absolute', top: '20%', left: '10%' }}>
                                    <IncomeGraph thisIncomeData={thisIncomeData} />
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                    <div style={{ paddingBottom: 20 }}>
                        <Card style={{ position: 'relative', height: 340, width: '100%' }}>
                            <CardContent>
                                <div className='resTitle'>Residential Market</div>
                                <GiHouse className='resIcon' />
                                <div className='medSalePrice' style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', paddingLeft: 25 }}>
                                    <div>
                                        <div className='resPricesMedianSales'>Median Sale Price</div>
                                        <div id='medianSalePrice'>{`$${commaNumber(medianSalePrice)}`}</div>
                                    </div>
                                    <div style={{ paddingLeft: 10 }}>
                                        <div className='resPricesMedianSales'>Median Rent</div>
                                        <div id='medianSalePrice'>{`$${commaNumber(medianRentPrice)}`}</div>
                                    </div>
                                </div>
                                <hr style={{ marginTop: 100 }}/>
                                <div style={{ maxWidth: 300, position: 'absolute', left: '10%', top: '25%' }}>
                                    <RentOwnGraph thisRentOwnData={thisRentOwnData} />
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                    <div style={{ paddingBottom: 20 }}>
                        <Card style={{ position: 'relative', zIndex: 13, height: 575, width: '100%' }}>
                            <CardContent>
                                <div style={{ position: 'absolute', zIndex: 12, color: 'black', fontFamily: 'sans-serif', fontWeight: 'bold', fontSize: 16, top: '12%', left: '5%', letterSpacing: 1, textShadow: '1px 1px 1px white' }}>Population</div>
                                <BsPeopleFill style={{ position: 'absolute', left: '8%', top: '3%', height: 36, width: 52 }} />
                                <div className='trendCategoryTitle' style={{ textAlign: 'right', position: 'absolute', bottom: '83%', left: '40%', width: '10%' }}>Population in Area:</div>
                                <div style={{ position: 'absolute', bottom: '82%', left: '60%', fontSize: 12, fontFamily: 'sans-serif', fontWeight: 'bold', textAlign: 'center', width: '15%' }}>
                                    <div className='since2010Title'>Since 2010</div>
                                    <div className='popChangeValue'>{`${popChange}%`}</div>
                                    <div className='popArrows'>
                                        {popChange > 0 ? greenArrow : popChange < 0 ? redArrow : '' }
                                    </div>
                                </div>
                                <div style={{ position: 'absolute', bottom: '82%', left: '80%', fontSize: 12, fontFamily: 'sans-serif', fontWeight: 'bold', textAlign: 'center', width: '15%' }}>
                                    <div className='fiveYearForecastTitle'>5 Year Forecast</div>
                                    <div className='popChangeValue'>{`${popForecast5.toFixed(0)}%`}</div>
                                    <div className='popArrows'>
                                        {popForecast5 > 0 ? greenArrow : popForecast5 < 0 ? redArrow : '' }
                                    </div>
                                </div>
                                <hr style={{ marginTop: 100 }} />
                                <div style={{ width: '100%', position: 'relative' }}>
                                    <div style={{ height: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div className='resPricesMedianSales'>Age Breakdown</div>
                                    </div>
                                    <div style={{ height: '95%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ position: 'relative', width: '95%' }}>
                                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                                <div>
                                                    <AgeGraph width={screenWidth} height={Math.floor(screenWidth - 75)} thisCurrentPop={thisCurrentPop} populationReport thisAgeData={thisAgeData} />
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div style={{ width: '100%', paddingTop: 20, display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr' }}>
                                                    <div style={{ height: 50, backgroundColor: 'rgb(255, 105, 180, .1)', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, width: '100%', fontWeight: 'bolder', textAlign: 'center' }}>Gen Z</div>
                                                    <div style={{ height: 50, backgroundColor: 'rgb(52, 181, 122, .1)', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, width: '100%', fontWeight: 'bolder', textAlign: 'center' }}>Millennials</div>
                                                    <div style={{ height: 50, backgroundColor: 'rgb(219, 136, 42, .1)', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, width: '100%', fontWeight: 'bolder', textAlign: 'center' }}>Gen X</div>
                                                    <div style={{ height: 50, backgroundColor: 'rgb(0, 0, 128, .1)', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, width: '100%', fontWeight: 'bolder', textAlign: 'center' }}>Baby Boomers</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                    <div style={{ paddingBottom: 20 }}>
                        <Card style={{ position: 'relative', zIndex: 13, height: 350, width: '100%' }}>
                            <CardContent>
                                <div>
                                    <div className='mobileChildrenHouseTitle'>Number of Households WITH Children</div>
                                    <div className='mobileChildHouseCountNumber'>{householdsWithChildren}</div>
                                    {(filterables && (filterables !== null) && (this.state.loaded === true)) ? <ChildrenHousesGauge filterables={filterables} screenWidth /> : null}
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                    <div style={{ paddingBottom: 20 }}>
                        <Card style={{ position: 'relative', zIndex: 13, height: 250, width: '100%' }}>
                            <CardContent>
                                <div className='familyStatusSection'>
                                    <div style={{ maxWidth: 285, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                        <FamilyStatusGraph thisFamilyData={thisFamilyData} />
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                </div>) : <LinearProgress />}
            </Container>)}</>
        );
    }
}

export default SearchAddressDemographics;