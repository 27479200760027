import React, { Component } from 'react';
import { Table } from '@mui/material';
import bike from '../../../Assets/bike.png';
import walking from '../../../Assets/walking.png';
import transit from '../../../Assets/train.png';


class AccessibilityComparison extends Component {
    

    render() { 

        const { listings } = this.props;

        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.scores.fourcastScore - a.scores.fourcastScore});
        
        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }


        let thisListingsAccessibility = (thisListing) => {
            
            
            const walkScore = thisListing && thisListing.walkScoreData && thisListing.walkScoreData.walkscore && thisListing.walkScoreData.walkscore;
            const transitScore = thisListing && thisListing.walkScoreData && thisListing.walkScoreData.transit && thisListing.walkScoreData.transit.score && thisListing.walkScoreData.transit.score;
            const bikeScore = thisListing && thisListing.walkScoreData && thisListing.walkScoreData.bike && thisListing.walkScoreData.bike.score && thisListing.walkScoreData.bike.score;
        
            const walkScoreDesc = thisListing && thisListing.walkScoreData && thisListing.walkScoreData.description && thisListing.walkScoreData.description;
            const transitScoreDesc = thisListing && thisListing.walkScoreData && thisListing.walkScoreData.transit && thisListing.walkScoreData.transit.description && thisListing.walkScoreData.transit.description;
            const bikeScoreDesc = thisListing && thisListing.walkScoreData && thisListing.walkScoreData.bike && thisListing.walkScoreData.bike.description && thisListing.walkScoreData.bike.description;

            const walkObj = {walk: walkScore, transit: transitScore, bike: bikeScore, walkDesc: walkScoreDesc, transitDesc: transitScoreDesc, bikeDesc: bikeScoreDesc};
            return walkObj;
        }

        
        let displayWalkScore = (listing) => {
            return (
                <>
                    {(listing && listing.walk) ? ( 
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'start' }}>
                                <div style={{ fontWeight: 800, marginLeft: 5 }}>{listing && listing.walk && listing.walk}</div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'left', marginLeft: 5 }}>
                                <div>{listing && listing.walkDesc && listing.walkDesc}</div>
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }
        let displayTransitScore = (listing) => {
            return (
                <>
                    {(listing && listing.walk) ? ( 
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'start' }}>
                                <div style={{ fontWeight: 800, marginLeft: 5 }}>{listing && listing.transit && listing.transit}</div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'left', marginLeft: 5 }}>
                                <div>{listing && listing.transitDesc && listing.transitDesc}</div>
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }
        let displayBikeScore = (listing) => {
            return (
                <>
                    {(listing && listing.walk) ? ( 
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'start' }}>
                                <div style={{ fontWeight: 800, marginLeft: 5 }}>{listing && listing.bike && listing.bike}</div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'left', marginLeft: 5 }}>
                                <div>{listing && listing.bikeDesc && listing.bikeDesc}</div>
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }

        let filteredListingsWalkScore = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsWalk = thisListingsAccessibility(listing);
            return (
                <td style={{ fontSize: 14, padding: 20 }}>
                    {thisListingsWalk && displayWalkScore(thisListingsWalk)}
                </td>
            )
        });
        let filteredListingsTransitScore = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsTransit = thisListingsAccessibility(listing);
            return (
                <td style={{ fontSize: 14, padding: 20 }}>
                    {thisListingsTransit && displayTransitScore(thisListingsTransit)}
                </td>
            )
        });
        let filteredListingsBikeScore = sortedByFourcastScore && sortedByFourcastScore.map((listing) => {
            let thisListingsBike = thisListingsAccessibility(listing);
            return (
                <td style={{ fontSize: 14, padding: 20 }}>
                    {thisListingsBike && displayBikeScore(thisListingsBike)}
                </td>
            )
        });

        const walkScoreLogo = sortedByFourcastScore && sortedByFourcastScore.length && sortedByFourcastScore[0] && sortedByFourcastScore[0].walkScoreData && sortedByFourcastScore[0].walkScoreData.logo_url && sortedByFourcastScore[0].walkScoreData.logo_url;
        
        
        return (
            <div style={{ position: 'relative', width: '98%' }}>
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Accessibility</div>
                    <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}>
                        <img style={{ width: 75, height: 15 }} src={walkScoreLogo} alt='' />
                    </div>
                </div>
                <div style={{ marginTop: 10 }}>
                    <div>
                        <Table style={{ tableLayout: 'fixed' }}>
                            <thead>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '10%' }}></th>
                                    {sortedByFourcastScore && sortedByFourcastScore.map(listing => {return (<th style={{ padding: 20, color: 'darkgreen' }}>{addressStyle(listing.listingAddress)}</th>)})}
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '10%' }}>
                                        <img className='walkPic' src={walking} alt='' />
                                    </th>
                                    {filteredListingsWalkScore}
                                </tr>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '10%' }}>
                                        <img src={transit} alt='' />
                                    </th>
                                    {filteredListingsTransitScore}
                                </tr>
                                <tr style={{ border: '1px solid #96D4D4', textAlign: 'center' }}>
                                    <th style={{ width: '10%' }}>
                                        <img src={bike} alt='' />
                                    </th>
                                    {filteredListingsBikeScore}
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
                <hr />
            </div>
        );
    }
}

export default AccessibilityComparison;