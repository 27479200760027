import React, { Component } from 'react';
import Select from 'react-select';
import { Form, FormGroup } from 'reactstrap';
import { FormHelperText, Card, CardActions, CardContent, Button } from '@material-ui/core';


class BadBizTypes extends Component {
    

    continue = e => {
        this.props.nextStep();
    }

    back = e => {
        this.props.prevStep();
    }

    

    render() { 
        
        const { values, addToState, foodBevTypes, isMulti, medicalHealthTypes, shoppingTypes, localServicesTypes, artsEntertainmentTypes, fitnessInstructionsTypes, educationTypes, beautySpaTypes } = this.props;


        const categoryOptions = [
            { label: 'Arts/Entertainment', options: artsEntertainmentTypes },
            { label: "Beauty/Spas", options: beautySpaTypes },
            { label: 'Education', options: educationTypes },
            { label: 'Fitness/Instructions', options: fitnessInstructionsTypes },
            { label: 'Health/Medical', options: medicalHealthTypes },
            { label: 'Local Services', options: localServicesTypes },
            { label: 'Restaurants/Food/Beverage', options: foodBevTypes },
            { label: "Shopping", options: shoppingTypes },
        ];

        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          };
          const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: '#172B4D',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
          };
          
          const formatGroupLabel = data => (
            <div style={groupStyles}>
              <span>{data.label}</span>
              <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
          );


        return (
            <div style={{ backgroundColor: 'whitesmoke', height: '100vh', paddingTop: 50 }}>
                <Card className='customCardBackgroundColor' style={{ width: '75vw', height: '75vh', margin: 'auto', overflowY: 'scroll' }}>
                    <CardContent>
                        <h2 style={{ textAlign: 'center', marginTop: 50 }}><span>List Any Business Types You</span><span style={{ color: 'darkred', fontWeight: 'bold' }}> WOULD NOT</span><span> Want To Be Next Door To</span></h2>
                        <p style={{ fontStyle: 'italic', marginTop: 10, textAlign: 'center' }}>E.g. I am opening a Toy Store and I do NOT want to be next to another Toy Store...</p>
                        <Form style={{ width: '100%', margin: 'auto' }}>
                            <FormGroup style={{ margin: 'auto', width: 800, marginTop: 75 }}>
                                <Select 
                                    options={categoryOptions}
                                    formatGroupLabel={formatGroupLabel}
                                    isMulti={isMulti}
                                    placeholder="Search & Select All That Apply..."
                                    onChange={addToState('badBiz')}
                                    defaultValue={values.badBiz}
                                />
                                <FormHelperText>Business Type</FormHelperText>
                            </FormGroup>
                        </Form>
                        <CardActions>
                            <Button style={{ marginTop: 100, marginRight: 20, marginLeft: 'auto' }} variant="contained" color="default" size="medium" onClick={() => this.back()}>Back</Button>
                            <Button style={{ marginTop: 100, marginRight: 'auto', marginLeft: 30, backgroundColor: '#007FFF', color: 'white' }} variant="contained" size="medium" onClick={() => this.continue()}>Continue</Button>
                        </CardActions>
                    </CardContent>
                </Card>
            </div>
        );
    }
}
 

export default BadBizTypes;