import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { 
    Container,
    Form, 
    FormGroup,
    Label,
    Input } 
from 'reactstrap';
import { Button, Card } from '@material-ui/core';
import { ImCheckmark, ImCross } from 'react-icons/im';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadUser, updateUser } from '../../actions/authActions';
import './userProfile.css';
import axios from 'axios';


// Pointing Axios baseURL towards AWS API Gateway URL
// Will most likely be changed again at some point because of the /dev
const axiosAWS = axios.create({
    baseURL: 'https://bgeg45v1tk.execute-api.us-east-1.amazonaws.com/dev'
});


class AccountSettings extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            accountType: props.user.accountType,
            newPassword: null,
            confirmPassword: null,
            successAlert: false,
            changePassword: false,
            changeMade: false,
            changeAccountType: false,
            prices: props.prices
        }
    }

    async componentDidMount() {
        const { prices } = this.props;

        if (prices && prices.length) {
            this.setState({ prices: prices });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { prices } = this.props;

        if ((prevProps.prices !== prices) && (prices.length) && (prices.length !== this.state.prices.length)) {
            if (!this.state.prices.length) {
                this.setState({ prices: prices })
            }
        }
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    }
    
    onSubmit = e => {
        e.preventDefault();
        
        
        const { user } = this.props;
        const { newPassword, confirmPassword } = this.state;
        
        let updatedUser = {
            userId: user._id,
            accountType: this.state.accountType === null ? user.accountType : this.state.accountType,
            engagement: user.engagement
        };

        if ((newPassword !== null && newPassword.length > 0) && (confirmPassword !== null && confirmPassword.length > 0) && (newPassword === confirmPassword)) {
            updatedUser.password = confirmPassword
        } else if ((newPassword !== null) && (confirmPassword !== null) && (newPassword !== confirmPassword)) {
            alert('Passwords Do Not Match');
        }
    
        updatedUser && updatedUser ? this.props.updateUser(updatedUser) : console.log('Could Not Update User');

        this.setState({ successAlert: true, changePassword: false });

    }

    updatePaymentInfo = async e => {

        const { user, auth } = this.props;

        let tokenConfig = thisToken => {
    
            // Get Token from localStorage
            const token = thisToken;
        
            // Headers 
            const config = {
                headers: {
                    "Content-type": "application/json"
                }
            }
        
            // If token, add to headers
            if (token) {
                config.headers['Authorization'] = token;
            }
        
            return config;
        }

        await axiosAWS.post('/api/subscriptions/customer-portal', { user }, tokenConfig(auth.token))
            .then(res => {
                const { url } = res.data;
                window.location.href = url && url;
            })
            .catch(err => {
                console.log(err);
            });
    }


    render() {
        
        const { user, auth } = this.props;
        const { prices } = this.state;

        const proPriceData = prices && prices.filter(price => {return price.nickname === "Pro"});
        const brokerPriceData = prices && prices.filter(price => {return price.nickname === "Broker"});

        let proPrice = proPriceData.length > 0 && proPriceData[0].unit_amount;
        proPrice *= .01;
        proPrice = proPrice.toFixed(2);

        let brokerPrice = brokerPriceData.length > 0 && brokerPriceData[0].unit_amount;
        brokerPrice *= .01;

        let fiftyPercDiscount = ((brokerPrice - (brokerPrice * .5)) - .001).toFixed(2);

        const stripeSubData = user && user.stripeSubData;
        const stripeSubStatus = stripeSubData ? (stripeSubData.status !== null) ? stripeSubData.status : 'not active' : 'not active';
        
        const getUserAccountType = user && user.accountType;
        const userAccountType = getUserAccountType[0].toUpperCase() + getUserAccountType.substring(1);

        const thisUserSubPrice = (accountType) => {
            switch(accountType) {
                case "basic":
                    return (
                        <h1 style={{ marginTop: 10 }}>Free</h1>
                    )
                case "pro":
                    return (
                        <>
                            <h1 style={{ marginTop: 10 }}>{`$${proPrice}`}</h1>
                            <p>per month</p>
                        </>
                    )
                case "broker":
                    return (
                        <>
                            <h1 style={{ marginTop: 10 }}>{`$${brokerPrice}`}</h1>
                            <p>per listing / month</p>
                        </>
                    )
                default:
                    return (
                        <h1 style={{ marginTop: 10 }}>Free</h1>
                    )
            }
        }

        const invalidSubStatuses = ['incomplete', 'incomplete_expired', 'past_due', 'canceled', 'unpaid', 'not active'];
        
        return (
            <>
            {prices && prices.length ? 
            <Container style={{ height: 600, backgroundColor: 'whitesmoke', paddingTop: 10 }} fluid>
                <div style={{ width: '100%', marginTop: 15 }}><span style={{ paddingTop: 10, width: '100%', textDecoration: 'underline', textUnderlineOffset: 4, fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif' }}>Account Type:</span>{(!this.state.successAlert && this.state.changeMade) ? <span style={{ marginLeft: 25 }}><Button variant="contained" style={{ color: 'white', textDecoration: 'none', backgroundColor: 'rgb(52, 181, 122)', outline: 'none' }} onClick={this.onSubmit}>Save Changes</Button></span> : this.state.successAlert ? <span style={{ marginLeft: 25, color: 'darkgreen', fontWeight: 'bold' }}><ImCheckmark style={{ color: 'darkgreen' }} /> Changes Saved!</span> : null}</div>
                {(this.state.changeAccountType === false) ? (
                <div style={{ marginTop: '1rem', height: 250, display: 'grid', gridTemplateColumns: '1fr 3fr', gap: 10 }}>
                    <div>
                        <span>
                        <Button 
                            variant="contained" 
                            style={{ color: 'white', textDecoration: 'none', backgroundColor: 'rgb(52, 123, 181)', outline: 'none' }}
                            onClick={(e) => this.setState({ changeAccountType: true })}
                        >
                            Change Account Type
                        </Button>
                        </span>
                        {this.state.successAlert ? <span style={{ marginLeft: 25, color: 'darkgreen', fontWeight: 'bold' }}><ImCheckmark style={{ color: 'darkgreen', fontStyle: 'bold' }} /> Changes Saved!</span> : null}
                    </div>
                    <div style={{ height: '100%', marginTop: 20, display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 20, textAlign: 'center' }}>
                        <div>
                            <Card style={{ width: 400, height: 250, marginLeft: 'auto', marginRight: 'auto' }}>
                                <div>
                                    <p style={{ marginTop: 15, fontSize: 13 }}>Current Account Type:</p>
                                    <h1 style={{ marginTop: 10 }}>{userAccountType}</h1>
                                </div>
                                <div style={{ marginLeft: 75, width: 300, marginTop: 30 }}>
                                    <div className="accountTypeDesc">
                                        <div><ImCheckmark style={{ color: 'darkgreen' }} /></div>
                                        <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Browse Listings</p>
                                    </div>
                                    <div className="accountTypeDesc">
                                        <div>{(getUserAccountType === 'pro' || getUserAccountType === 'broker' || getUserAccountType === 'admin') ? <ImCheckmark style={{ color: 'darkgreen' }} /> : <ImCross style={{ color: 'darkred' }} />}</div>
                                        <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Fourcast Match</p>
                                    </div>
                                    <div className="accountTypeDesc">
                                        <div>{(getUserAccountType === 'broker' || getUserAccountType === 'admin') ? <ImCheckmark style={{ color: 'darkgreen' }} /> : <ImCross style={{ color: 'darkred' }} />}</div>
                                        <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Submit & Advertise Listings</p>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div>
                            <Card style={(stripeSubStatus && (invalidSubStatuses.includes(stripeSubStatus)) && (getUserAccountType !== 'admin')) ? { width: 400, height: 250, marginLeft: 'auto', marginRight: 'auto', border: '1px solid rgb(207, 83, 72)' } : { width: 400, height: 250, marginLeft: 'auto', marginRight: 'auto' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <span><p style={{ marginTop: 15, fontSize: 14 }}>Status: </p></span><span><p style={(stripeSubStatus && (invalidSubStatuses.includes(stripeSubStatus)) && (getUserAccountType !== 'admin')) ? { marginTop: 15, fontSize: 14, fontWeight: 'bold', marginLeft: 10, color: 'darkred' } : { marginTop: 15, fontSize: 14, color: 'darkgreen', fontWeight: 'bold', marginLeft: 10 }}>{(getUserAccountType !== 'admin') ? stripeSubStatus : 'active'}</p></span>
                                </div>
                                <div>
                                    {thisUserSubPrice(getUserAccountType)}
                                </div>
                                <div style={{ marginLeft: 'auto', marginRight: 'auto', width: 300, marginTop: 40 }}>
                                    {(stripeSubData && (stripeSubData.status !== null) && (!invalidSubStatuses.includes(stripeSubStatus))) ? (
                                    <Button 
                                        variant="contained"
                                        style={{ color: 'white', textDecoration: 'none', backgroundColor: 'rgb(52, 123, 181)', outline: 'none' }}
                                        onClick={(e) => this.updatePaymentInfo()}
                                    >
                                        Manage Payments
                                    </Button>
                                    ) : (
                                    <Link auth={auth} style={{ textDecoration: 'none' }} to='/registration/payment-method'>
                                        <Button 
                                            variant="contained"
                                            style={{ color: 'white', textDecoration: 'none', backgroundColor: 'rgb(52, 123, 181)', outline: 'none' }}
                                        >
                                            Manage Subscription
                                        </Button>
                                    </Link>
                                    )}
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
                )
                : (<><div className="accountTypeArea">
                    <div>
                    <Button 
                        variant="contained"
                        size="large"
                        style={this.state.accountType === 'basic' ? { outline: 'none', color: 'black', backgroundColor: 'white', border: '5px solid rgb(52, 181, 122)', fontSize: 12, width: 300, height: 150 } : { backgroundColor: 'white', color: 'black', fontSize: 12, width: 300, height: 150 }}
                        category='basic'
                        checked={this.state.accountType === 'basic'}
                        onClick={(e) => {
                            this.setState({ accountType: 'basic', successAlert: false, changeMade: true });
                        }}
                    >
                    <div>
                        <h4 style={{ marginTop: 15 }}>Basic</h4>
                        <p style={{ marginTop: 10, fontSize: 13 }}>Free</p>
                    </div>
                    </Button>
                    </div>
                    <div>
                    <Button 
                        variant="contained"
                        size="large"
                        style={this.state.accountType === 'pro' ? { outline: 'none', color: 'black', backgroundColor: 'white', border: '5px solid rgb(52, 181, 122)', fontSize: 12, width: 300, height: 150 } : { backgroundColor: 'white', color: 'black', fontSize: 12, width: 300, height: 150 }}
                        category='pro'
                        checked={this.state.accountType === 'pro'}
                        onClick={(e) => {
                            this.setState({ accountType: 'pro', successAlert: false, changeMade: true });
                        }}
                    >
                    <div>
                        <h4 style={{ marginTop: 15 }}>Pro</h4>
                        <p style={{ marginTop: 10, fontSize: 13, fontWeight: 'bold' }}>{`$${proPrice} / month`}</p>
                    </div>
                    </Button>
                    </div>
                    <div>
                    <Button 
                        variant="contained"
                        size="large"
                        style={this.state.accountType === 'broker' ? { outline: 'none', color: 'black', backgroundColor: 'white', border: '5px solid rgb(52, 181, 122)', fontSize: 12, width: 300, height: 150 } : { backgroundColor: 'white', color: 'black', fontSize: 12, width: 300, height: 150 }}
                        category='broker'
                        checked={this.state.accountType === 'broker'}
                        onClick={(e) => {
                            this.setState({ accountType: 'broker', successAlert: false, changeMade: true });
                        }}
                    >
                    <div>
                        <h4 style={{ marginTop: 15 }}>Broker</h4>
                        <p style={{ marginTop: 10, fontSize: 12, fontWeight: 'bold' }}>{`1 : $${brokerPrice} per listing / month`}</p>
                        <p style={{ marginTop: -10, fontSize: 11, fontWeight: 'bold' }}>{`2+ : $${fiftyPercDiscount} per listing / month`}</p>
                    </div>
                    </Button>
                    </div>
                </div>
                <div className="accountTypeDescArea">
                    <div style={{ marginLeft: 100 }}>
                        <div className="accountTypeDesc">
                            <div><ImCheckmark style={{ color: 'darkgreen' }} /></div>
                            <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Browse Listings</p>
                        </div>
                        <div className="accountTypeDesc">
                            <div><ImCross style={{ color: 'darkred' }} /></div>
                            <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Fourcast Match</p>
                        </div>
                        <div className="accountTypeDesc">
                            <div><ImCross style={{ color: 'darkred' }} /></div>
                            <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Submit & Advertise Listings</p>
                        </div>
                    </div>
                    <div style={{ marginLeft: 100 }}>
                        <div className="accountTypeDesc">
                            <div><ImCheckmark style={{ color: 'darkgreen' }} /></div>
                            <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Browse Listings</p>
                        </div>
                        <div className="accountTypeDesc">
                            <div><ImCheckmark style={{ color: 'darkgreen' }} /></div>
                            <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Fourcast Match</p>
                        </div>
                        <div className="accountTypeDesc">
                            <div><ImCross style={{ color: 'darkred' }} /></div>
                            <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Submit & Advertise Listings</p>
                        </div>
                    </div>
                    <div style={{ marginLeft: 100 }}>
                        <div className="accountTypeDesc">
                            <div><ImCheckmark style={{ color: 'darkgreen' }} /></div>
                            <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Browse Listings</p>
                        </div>
                        <div className="accountTypeDesc">
                            <div><ImCheckmark style={{ color: 'darkgreen' }} /></div>
                            <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Fourcast Match</p>
                        </div>
                        <div className="accountTypeDesc">
                            <div><ImCheckmark style={{ color: 'darkgreen' }} /></div>
                            <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Submit & Advertise Listings</p>
                        </div>
                    </div>
                </div></>)}
                <div style={{ marginTop: '3rem', paddingBottom: '1rem', width: '100%', textDecoration: 'underline', textUnderlineOffset: 4, fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif' }}>Password:</div>
                {this.state.changePassword === false ? (
                <div>
                <span>
                <Button 
                    variant="contained" 
                    style={{ color: 'white', textDecoration: 'none', backgroundColor: 'rgb(52, 123, 181)', outline: 'none' }}
                    onClick={(e) => this.setState({ changePassword: true })}
                >
                    Change Password
                </Button>
                </span>
                {this.state.successAlert ? <span style={{ marginLeft: 25, color: 'darkgreen', fontWeight: 'bold' }}><ImCheckmark style={{ color: 'darkgreen', fontStyle: 'bold' }} /> Changes Saved!</span> : null}
                </div>
                )
                :
                (
                <div className="passwordChangeArea">
                    <Form onSubmit={this.onSubmit}>
                        <FormGroup className="passwordForm">
                            <div>
                            <Label for='newPassword' style={{ fontSize: 14, fontWeight: 'bold' }}>New Password</Label>
                            <Input 
                                type='password'
                                name='newPassword'
                                id='newPassword'
                                className='mb-3'
                                onChange={this.onChange}
                            />
                            </div>
                            <div>
                            <Label for='confirmPassword' style={{ fontSize: 14, fontWeight: 'bold' }}>Confirm Password</Label>
                            <Input 
                                type='password'
                                name='confirmPassword'
                                id='confirmPassword'
                                className='mb-3'
                                onChange={this.onChange}
                            />
                            </div>
                            <div>
                                {((this.state.newPassword && this.state.newPassword.length > 0) && (this.state.newPassword === this.state.confirmPassword)) ? <Button variant="contained" style={{ color: 'white', textDecoration: 'none', backgroundColor: 'rgb(52, 181, 122)', outline: 'none', marginTop: 32, marginLeft: 10 }} onClick={this.onSubmit}>Save Changes</Button> : null}
                            </div>
                        </FormGroup>
                    </Form>
                </div>)}
            </Container> : null}
            </>
        )
    }
}

AccountSettings.propTypes = {
    loadUser: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});

export default connect(mapStateToProps, { loadUser, updateUser })(AccountSettings);
