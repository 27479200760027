import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Form, FormGroup, Input, Alert } from 'reactstrap';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { resetPassword } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';


class ResetPassword extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            password: null,
            confirmPassword: null,
            msg: null,
            successAlert: false
        }
    }

    static propTypes = {
        passwordReset: PropTypes.bool,
        error: PropTypes.object.isRequired,
        clearErrors: PropTypes.func.isRequired,
        resetPassword: PropTypes.func.isRequired
    }

    async componentDidMount() {
        await this.props.getLocation(this.props.location.pathname);
    }

    componentDidUpdate(prevProps) {
        const { error, passwordReset } = this.props;

        if (error !== prevProps.error) {
            
            //Check for Login Error
            if (error.id === 'RESET_PASSWORD_FAIL') {
                this.setState({ msg: error.msg.msg })
            } else {
                this.setState({ msg: null });
            }
        }

        if (passwordReset !== prevProps.passwordReset) {
            this.setState({ successAlert: true });
            
            // Clear errors if password is reset successfully
            this.props.clearErrors();
        }
    }

    

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmit = e => {
        e.preventDefault();

        const { password } = this.state;
        const resetToken = this.props.match.params.resetToken;

        const updatedPassword = {
            password,
            resetToken
        }

        // Attempt to Reset Password
        this.props.resetPassword(updatedPassword);

    }

    render() { 

        return ( 
            <Container style={{ marginTop: 125, minWidth: 600, minHeight: 450 }}>
                <div style={{ width: 500, marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                    <h2 style={{ width: '100%', textAlign: 'center' }}>Enter New Password</h2>
                    { this.state.msg ? (
                    <Alert style={{ marginTop: '3rem' }} color='danger'>{this.state.msg}</Alert>
                    ) : this.state.successAlert ? (
                        <Alert style={{ marginTop: '3rem', paddingBottom : '4rem' }} color='success'>New password set! Try logging in</Alert>
                    ) : null}
                    {!this.state.successAlert ?
                    (<Form onSubmit={this.onSubmit} style={{ marginTop: '4rem' }}>
                        <FormGroup>
                            <Input 
                                type='password'
                                name='password'
                                id='password'
                                placeholder='Password'
                                className='mb-3'
                                onChange={this.onChange}
                            />

                            <Input 
                                type='password'
                                name='confirmPassword'
                                id='confirmPassword'
                                placeholder='Confirm Password'
                                className='mb-3'
                                onChange={this.onChange}
                            />
                        </FormGroup>
                        <Button onClick={this.onSubmit} style={{ width: '100%', marginRight: 'auto', marginLeft: 'auto', marginTop: '2rem', backgroundColor: 'rgb(35, 126, 85)', color: 'white', outline: 'none' }} disabled={this.state.password !== this.state.confirmPassword}>
                            Set New Password
                        </Button>
                    </Form>) 
                    : (
                    <Link style={{ fontFamily: 'Arial, Helvetica, sans-serif', color: 'darkblue', fontSize: 18, textDecoration: 'none' }} to='/'>
                        Back to Home
                    </Link>
                    )}
                </div>
            </Container>
        );
    }
}


const mapStateToProps = (state) => ({
    passwordReset: state.forgotPassword.passwordReset,
    error: state.error,
});

export default connect(mapStateToProps, { resetPassword, clearErrors })(ResetPassword);