import React, { Component } from 'react';
import { Table } from '@mui/material';
import 'chartjs-plugin-datalabels';
import GreenArrow from '../../../Assets/greenArrow.png';
import RedArrow from '../../../Assets/redArrow.png';
import { Bar } from 'react-chartjs-2';
import '../../listingDetailsLayouts/demographics/demographics.css';
import './matchComparison.css';
const greenArrow = <img className='greenArrow' src={GreenArrow} alt='' />;
const redArrow = <img className='redArrow' src={RedArrow} alt='' />;

class CommonPeopleMatchComparison extends Component {
    
    
    render() {

        const { listings, inputValueTitle } = this.props;
        
        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.fourcastScore - a.fourcastScore});

        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        };


        let thisListingsCommonPeople = (thisListing) => {
                        
            // get current geoIdV4 array
            const geoIdV4 = thisListing && thisListing.attomData && thisListing.attomData.geoIdV4 && thisListing.attomData.geoIdV4;
            const poi1 = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi1 && thisListing.scores.POI.poi1;
            const poi2 = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi2 && thisListing.scores.POI.poi2;
            const poi3 = thisListing && thisListing.scores && thisListing.scores.POI && thisListing.scores.POI.poi3 && thisListing.scores.POI.poi3;
            const nearbyPlaces = thisListing && thisListing.nearbyPlaces && thisListing.nearbyPlaces.places && thisListing.nearbyPlaces.places;

                // types of people
            const getStudentsPop = [];
            const getWorkersPop = [];
            const getWhiteCollarPct = [];
            const getDayPop = [];
            const getPopulation = [];

                // pop trends
            const popChanges = [];
            const popForecasts = [];
            
            if (geoIdV4 && geoIdV4.length) {

                for (let g = 0; g < geoIdV4.length; g++) {

                    // POPULATION DATA
                    const totalPopulation = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population);
                    const thisDayPop = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Daytime);
                                    
                    getDayPop.push(thisDayPop);
                    getPopulation.push(totalPopulation);
                    
                    let totalWorkersPop = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.occupation_Soc_Cnt);
                    let thisWhiteCollarPct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.occupation_White_Collar_Pct);
                    
                    let studentsNum = (geoIdV4 && geoIdV4[g].commV4Data && (parseFloat(geoIdV4[g].commV4Data.enrollment_Public_Undergraduate_College_Pct) + parseFloat(geoIdV4[g].commV4Data.enrollment_Private_Undergraduate_College_Pct) + parseFloat(geoIdV4[g].commV4Data.enrollment_Private_Graduate_Or_Professional_School_Pct) + parseFloat(geoIdV4[g].commV4Data.enrollment_Public_Graduate_Or_Professional_School_Pct)));
                    
                    getWorkersPop.push(totalWorkersPop);
                    getWhiteCollarPct.push(thisWhiteCollarPct);
                    getStudentsPop.push((totalPopulation * (studentsNum * .01)));

                    
                    // POP CHANGES
                    const popChangeSince2010 = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Chg_Pct_2010);
                    let popChangeWeightedValue = (geoIdV4 && (geoIdV4.length > 1)) ? (geoIdV4 && (geoIdV4[g].type !== 'N4')) ? (popChangeSince2010 * .4) : (popChangeSince2010 * .6) : popChangeSince2010;
                    let popForecast5YearNum = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_5_Yr_Projection);

                    popChanges.push(popChangeWeightedValue);
                    popForecasts.push(popForecast5YearNum);


                }
            }

            // MOST COMMON PEOPLE 
            const currentPop = getPopulation && getPopulation.length && getPopulation.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }); 
            const daytimePop = getDayPop && getDayPop.length && getDayPop.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });
            const employeePop = getWorkersPop && getWorkersPop.length && getWorkersPop.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });
            const whiteCollarPct = getWhiteCollarPct && getWhiteCollarPct.length && (getWhiteCollarPct.length > 1) ? Math.ceil((parseFloat(getWhiteCollarPct[0]) + parseFloat(getWhiteCollarPct[1])) / 2) : parseFloat(getWhiteCollarPct[0]);
            const thisStudentPop = getStudentsPop && getStudentsPop.length && getStudentsPop.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });

            const getSchoolCount = []
            const getSchools = [];

            // checking nearby places for schools
            if (inputValueTitle === 'Students') {
                if (nearbyPlaces && nearbyPlaces.length) {
                    for (let p = 0; p < nearbyPlaces.length; p++) {
                        let placeName = nearbyPlaces[p] && nearbyPlaces[p].name && nearbyPlaces[p].name.toLowerCase();
                        let placeTypes = nearbyPlaces[p] && nearbyPlaces[p].types && nearbyPlaces[p].types;
                        if (placeTypes && placeTypes.length && (placeTypes.includes('school') || placeTypes.includes('university') || placeName.includes('school') || placeName.includes('college') || placeName.includes('university'))) {
                            const schoolRatingsTotal = nearbyPlaces[p] && nearbyPlaces[p].user_ratings_total && nearbyPlaces[p].user_ratings_total;
                            const schoolEngagementVal = Math.ceil(schoolRatingsTotal * .001);
                            getSchoolCount.push(schoolEngagementVal);
                            getSchools.push(nearbyPlaces[p]);
                        } else {
                            getSchoolCount.push(0);
                        }
                    }
                }
            }
            const schoolCount = getSchoolCount && getSchoolCount.length && getSchoolCount.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });

            // GET THIS LISTINGS POPS

            const rawDaytimePop = parseFloat(daytimePop);
            const rawEmployeePop = parseFloat(employeePop);
            const rawCurrentPop = parseFloat(currentPop);
            const rawStudentPop = parseFloat(thisStudentPop);

            const residentsPctChange = Math.ceil(((rawCurrentPop - rawEmployeePop) / rawCurrentPop) * 100);
            const workersPctChange = Math.ceil(((rawEmployeePop - rawCurrentPop) / rawCurrentPop) * 100);
            let touristGauge = 0;

            
            // SET TOURIST GAUGE 
            const checkNearbyAttractions = (poi) => {
                if (poi.majorAttraction === true) {
                    touristGauge += 1;
                    if (poi.distance.elements[0].distance.value <= 150) {
                        touristGauge += 1;
                    } else if (poi.distance.elements[0].distance.value <= 200) {
                        touristGauge += .75;
                    } else if (poi.distance.elements[0].distance.value <= 250) {
                        touristGauge += .5;
                    } else if (poi.distance.elements[0].distance.value <= 300) {
                        touristGauge += .25;
                    } else {
                        touristGauge += .1;
                    }
                } else {
                    touristGauge += (poi.user_ratings_total * .00001);
                }
            };

            poi1 && checkNearbyAttractions(poi1);
            poi2 && checkNearbyAttractions(poi2);
            poi3 && checkNearbyAttractions(poi3);
            
            // SET STUDENT GAUGE
            const studentGauge = ((rawStudentPop / rawCurrentPop) + schoolCount);

            const neighborhood = thisListing && thisListing.neighborhood && thisListing.neighborhood; 
            const peopleTypeArr = [{type: 'Residents', val: residentsPctChange, workerPop: rawEmployeePop, residentPop: rawCurrentPop, neighborhood: neighborhood}, {type: 'Workers', val: workersPctChange, whiteCollarPct: whiteCollarPct, workerPop: rawEmployeePop, residentPop: rawCurrentPop, neighborhood: neighborhood}, {type: 'Tourists / Visitors', val: touristGauge, neighborhood: neighborhood}, {type: 'Students', val: studentGauge, schools: getSchools, neighborhood: neighborhood}];
            const filteredPeopleTypeArr = peopleTypeArr && ((inputValueTitle === 'Workers') || (inputValueTitle === 'Residents')) ? peopleTypeArr : peopleTypeArr.filter(peopleType => (peopleType.val > 0));
            const topPeople = filteredPeopleTypeArr.sort(function(a, b){return b.val-a.val});
            let tagsObj = {address: thisListing.listingAddress, topPeople: topPeople, neighborhood: neighborhood};
            return tagsObj;
        }

        const findListingsPeopleTypes = sortedByFourcastScore && sortedByFourcastScore.map((listing) => thisListingsCommonPeople(listing));
        
        let sortedListingByPeopleTypeVal = findListingsPeopleTypes && findListingsPeopleTypes.length && findListingsPeopleTypes.sort((a, b) => {
            let requestedPeopleTypeA = a && a.topPeople && a.topPeople.length && a.topPeople.filter(people => people.type === inputValueTitle);
            let requestedPeopleTypeB = b && b.topPeople && b.topPeople.length && b.topPeople.filter(people => people.type === inputValueTitle);
            a.filteredPeopleObj = requestedPeopleTypeA && requestedPeopleTypeA.length && requestedPeopleTypeA[0];
            b.filteredPeopleObj = requestedPeopleTypeB && requestedPeopleTypeB.length && requestedPeopleTypeB[0];
            return b.filteredPeopleObj.val - a.filteredPeopleObj.val;
        });



        // STUDENTS TAG !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        let filteredListingsStudents = sortedListingByPeopleTypeVal && sortedListingByPeopleTypeVal.length && sortedListingByPeopleTypeVal.map((listing) => {
            
            let thisListingsRequestedPeopleType = listing && listing.filteredPeopleObj && listing.filteredPeopleObj;
            
            let sortedSchools = thisListingsRequestedPeopleType && thisListingsRequestedPeopleType.schools && thisListingsRequestedPeopleType.schools.length && thisListingsRequestedPeopleType.schools.sort((a, b) => {return b.user_ratings_total - a.user_ratings_total});
            
            let filteredListingSchoolList = sortedSchools && sortedSchools.length && sortedSchools.map((school) => {
                return (
                    <tr>
                        <td>
                            <div style={{ width: '100%' }}>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                    <div style={{ height: '100%', color: 'navy', fontWeight: 800, marginLeft: 5, overflowY: 'scroll', display: 'flex', alignItems: 'center', fontSize: school && school.user_ratings_total && (school.user_ratings_total > 750) ? 16 : (school.user_ratings_total > 250) ? 14 : (school.user_ratings_total > 75) ? 12 : 10 }}>{school && school.name && school.name}</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            });

            return (
                <div style={{ position: 'relative', width: '100%', marginTop: 25 }}>
                    <div style={{ marginLeft: 10, display: 'flex', alignItems: 'end', width: '100%' }}>
                        <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>{sortedListingByPeopleTypeVal && sortedListingByPeopleTypeVal.length && (sortedListingByPeopleTypeVal.indexOf(listing) + 1)}</div>
                        <div style={{ marginLeft: 10, fontSize: 14, fontWeight: 600, overflowY: 'scroll', width: '40%', display: 'flex' }}>
                            {listing && addressStyle(listing.address)}
                            {sortedListingByPeopleTypeVal && sortedListingByPeopleTypeVal.length && sortedListingByPeopleTypeVal.indexOf(listing) === 0 ? <div style={{ display: 'flex', fontSize: 12, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', color: 'rgb(52, 181, 122)', marginLeft: 10 }}>Best</div> : null}
                        </div>
                    </div>
                    {filteredListingSchoolList && filteredListingSchoolList.length ? (
                    <div style={{ fontSize: 12, width: '99%', padding: 5, marginTop: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Table>
                                <thead></thead>
                                <tbody>
                                    {filteredListingSchoolList}
                                </tbody>
                            </Table>
                        </div>
                    </div>) : (
                    <div style={{ fontSize: 12, width: '99%', padding: 5, marginTop: 5 }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Table>
                                <thead></thead>
                                <tbody>
                                    <div style={{ marginLeft: 5, height: '100%', overflowY: 'scroll', display: 'flex', alignItems: 'center' }}>No schools in immediate area - ranked by student population</div>
                                </tbody>
                            </Table>
                        </div>
                    </div>)}
                </div>
            )
        });


        // WORKERS & RESIDENTS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        let filteredListingsWorkersAndResidents = sortedListingByPeopleTypeVal && sortedListingByPeopleTypeVal.length && sortedListingByPeopleTypeVal.map((listing) => {
            
            let getListingsWorkersVal = listing && listing.topPeople && listing.topPeople.length && listing.topPeople.filter(people => people.type === 'Workers');
            let listingsWorkersVal = getListingsWorkersVal && getListingsWorkersVal.length && getListingsWorkersVal[0];

            let getListingsResidentsVal = listing && listing.topPeople && listing.topPeople.length && listing.topPeople.filter(people => people.type === 'Residents');
            let listingsResidentsVal = getListingsResidentsVal && getListingsResidentsVal.length && getListingsResidentsVal[0];

            const commaNumber = (num) => {
                let newNum = Number(num);
                let numString = newNum.toLocaleString("en-US");
                return numString;
            }
    
            const setWorkersAndResidentsGraph = (population) => {
                return (
                    <div style={{ position: 'relative', height: 95, width: '100%' }}>
                        <Bar
                            data={{
                                labels: [
                                    'Workers',
                                    'Residents',
                                ],
                                datasets: [
                                    {
                                        label: 'Population Nearby',
                                        data: [
                                            `${population.workerPop}`,
                                            `${population.residentPop}`,
                                        ],
                                        backgroundColor: [
                                            inputValueTitle === 'Workers' ? 'rgb(0, 132, 0)' : 'dodgerblue',
                                            inputValueTitle === 'Workers' ? 'dodgerblue' : 'rgb(0, 132, 0)',
                                        ],
                                        barPercentage: .3
                                    },
                                ]
                            }}
                            height={95}
                            options={{
                                title: {
                                    display: false,
                                    text: 'Population in the Area',
                                    fontSize: 10,
                                    color: 'black'
                                },
                                plugins: {
                                    datalabels: {
                                    display: false,
                                    color: 'black'
                                    },
                                    legend: {
                                        display: false,
                                    }
                                },
                                scales: {
                                    x: {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                        gridLines: {
                                            display: false
                                        }
                                    },
                                    y: {
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                        gridLines: {
                                            display: false
                                        },
                                    },
                                },
                            }} 
                        />
                    </div>
                )
            }
            let displayWorkerPopDiff = (listing) => {
                return (
                    <>
                        {(listing && listing.val) ? ( 
                            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                                <div style={{ fontWeight: 800, color: (listing && listing.val && (listing.val < 0)) ? 'darkred' : 'darkgreen' }}>{`${listing && listing.val && listing.val}%`}</div>
                                <div className='incomeArrows' style={{ marginLeft: 5 }}>
                                    {(listing && listing.val && (listing.val > 0)) ? greenArrow : (listing && listing.val && (listing.val < 0)) ? redArrow : '' }
                                </div>
                            </div>)
                        : (<div>None</div>)}
                    </>
                )
            }
            let displayWorkerPop = (listing) => {
                return (
                    <>
                        {(listing && listing.workerPop) ? ( 
                            <div>
                                <div style={{ fontWeight: 800 }}>{`${listing && commaNumber(listing.workerPop)}`}</div>
                            </div>)
                        : (<div>None</div>)}
                    </>
                )
            }
            let displayResidentPop = (listing) => {
                return (
                    <>
                        {(listing && listing.residentPop) ? ( 
                            <div>
                                <div style={{ fontWeight: 800 }}>{`${listing && commaNumber(listing.residentPop)}`}</div>
                            </div>)
                        : (<div>None</div>)}
                    </>
                )
            }
            let displayNeighborhood = (listing) => {
                return (
                    <>
                        {(listing && listing.neighborhood) ? ( 
                            <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ position: 'relative', width: '80%', fontWeight: 800 }}>{listing && listing.neighborhood}</div>
                            </div>)
                        : (<div>N/A</div>)}
                    </>
                )
            }
            
            
            const setWorkersAndResidentsPopChart = (listing) => {
                return (
                    <div style={{ fontSize: 12, padding: 5, width: '100%', height: 95 }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', height: 95 }}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: 'center', width: '25%' }}>{inputValueTitle === 'Workers' ? 'Workers Difference' : 'Residents Difference'}</th>
                                        <th style={{ textAlign: 'center', width: '25%' }}>Workers</th>
                                        <th style={{ textAlign: 'center', width: '25%' }}>Residents</th>
                                        <th style={{ textAlign: 'center', width: '25%' }}>Neighborhood</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{ marginTop: 10 }}>
                                        <td style={{ fontSize: 12, textAlign: 'center', paddingTop: 10 }}>
                                            {listing && displayWorkerPopDiff(listing)}
                                        </td>
                                        <td style={{ fontSize: 12, textAlign: 'center', paddingTop: 10, color: 'darkgreen' }}>
                                            {listing && displayWorkerPop(listing)}
                                        </td>
                                        <td style={{ fontSize: 12, textAlign: 'center', paddingTop: 10, color: 'darkgreen' }}>
                                            {listing && displayResidentPop(listing)}
                                        </td>
                                        <td style={{ fontSize: 12, textAlign: 'center', paddingTop: 10, color: 'darkgreen' }}>
                                            {listing && displayNeighborhood(listing)}
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                )
            }
            
            return (
                <div style={{ position: 'relative', width: '98%' }}>
                    <div style={{ position: 'relative', width: '100%' }}>
                        {((inputValueTitle === 'Workers') && (listingsWorkersVal !== null)) ? (<div style={{ position: 'relative' }}>
                            <div style={{ display: 'flex', justifyContent: 'left', width: '100%', marginTop: 5 }}>
                                <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>{sortedListingByPeopleTypeVal && sortedListingByPeopleTypeVal.length && (sortedListingByPeopleTypeVal.indexOf(listing) + 1)}</div>
                                <div style={{ marginLeft: 10, fontSize: 14, fontWeight: 700, overflowY: 'scroll', width: '40%', display: 'flex' }}>
                                    {listing && addressStyle(listing.address)}
                                    {sortedListingByPeopleTypeVal && sortedListingByPeopleTypeVal.length && sortedListingByPeopleTypeVal.indexOf(listing) === 0 ? <div style={{ display: 'flex', fontSize: 12, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', color: 'rgb(52, 181, 122)', marginLeft: 10 }}>Best</div> : null}
                                </div>
                            </div>
                            <div style={{ width: '100%', display: 'flex', marginTop: 5, marginLeft: 10 }}>
                                <div style={{ width: '40%' }}>
                                    {(listingsWorkersVal !== null) && setWorkersAndResidentsGraph(listingsWorkersVal)}
                                </div>
                                <div style={{ width: '60%' }}>
                                    {(listingsWorkersVal !== null) && setWorkersAndResidentsPopChart(listingsWorkersVal)}
                                </div>
                            </div>
                        </div>) : null}
                        {((inputValueTitle === 'Residents') && (listingsResidentsVal !== null)) ? (<div style={{ position: 'relative' }}>
                            <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                                <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>{sortedListingByPeopleTypeVal && sortedListingByPeopleTypeVal.length && (sortedListingByPeopleTypeVal.indexOf(listing) + 1)}</div>
                                <div style={{ marginLeft: 10, fontSize: 14, fontWeight: 700, overflowY: 'scroll', width: '40%', display: 'flex' }}>
                                    {listing && addressStyle(listing.address)}
                                    {sortedListingByPeopleTypeVal && sortedListingByPeopleTypeVal.length && sortedListingByPeopleTypeVal.indexOf(listing) === 0 ? <div style={{ display: 'flex', fontSize: 12, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', color: 'rgb(52, 181, 122)', marginLeft: 10 }}>Best</div> : null}
                                </div>
                            </div>
                            <div style={{ width: '100%', display: 'flex', marginTop: 5, marginLeft: 10 }}>
                                <div style={{ width: '40%' }}>
                                    {(listingsResidentsVal !== null) && setWorkersAndResidentsGraph(listingsResidentsVal)}
                                </div>
                                <div style={{ width: '60%' }}>
                                    {(listingsResidentsVal !== null) && setWorkersAndResidentsPopChart(listingsResidentsVal)}
                                </div>
                            </div>
                        </div>) : null}
                    </div>
                </div>
            )
        });

        

        return (
            <div style={{ position: 'relative', width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'left' }}>
                    <div style={{ marginLeft: 10, fontSize: 24, fontWeight: 700, color: 'darkgreen' }}>{inputValueTitle}</div>
                </div>
                {(inputValueTitle === 'Students') ? (
                    <div style={{ marginTop: 50 }}>
                        {filteredListingsStudents}
                    </div>
                ) : null}
                {(inputValueTitle === 'Workers') ? (
                    <div style={{ marginTop: 20 }}>
                        {filteredListingsWorkersAndResidents}
                    </div>
                ) : null}
                {(inputValueTitle === 'Residents') ? (
                    <div style={{ marginTop: 20 }}>
                        {filteredListingsWorkersAndResidents}
                    </div>
                ) : null}
            </div>
        );
    }
}

export default CommonPeopleMatchComparison;