import React, { Component } from 'react';
import { FaBabyCarriage } from "react-icons/fa";
import { Table } from '@mui/material';
import FourcastLogo from '../../../Assets/fourcast_alt_logo_green.png';
import '../../listingDetailsLayouts/traffic/traffic.css';
import './matchComparison.css';

class AreasWithChildrenMatchComparison extends Component {
    
    
    render() {

        const { listings, inputValueTitle } = this.props;

        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.scores.fourcastScore - a.scores.fourcastScore});
        
        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }

        let getThisListingsPopulation = (thisListing) => {
            
            const geoIdV4 = thisListing && thisListing.attomData && thisListing.attomData.geoIdV4 && thisListing.attomData.geoIdV4;

                // family status
            const getFamilyHouseholds = [];
            const getNonFamilyHouseholds = [];

                // children pop
            const getHouseholdsWithChildren = [];

            
            if (geoIdV4 && geoIdV4.length) {

                
                for (let g = 0; g < geoIdV4.length; g++) {

                    const famHouseNum = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.households_Family);
                    getFamilyHouseholds.push(famHouseNum);
                    const nonFamHouseNum = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.households_Non_Family);
                    getNonFamilyHouseholds.push(nonFamHouseNum);

                    const totalHouseholdsNum = (famHouseNum + nonFamHouseNum);
                    const famHouseWithChildPct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.households_Family_W_Children_Pct);
                    const famHouseWithChildNum = (totalHouseholdsNum * (famHouseWithChildPct * .01));
                    const nonFamHouseWithChildPct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.households_Non_Family_W_Children_Pct);
                    const nonFamHouseWithChildNum = (totalHouseholdsNum * (nonFamHouseWithChildPct * .01));
                    const householdsWChildrenNum = (famHouseWithChildNum + nonFamHouseWithChildNum);
                    getHouseholdsWithChildren.push(householdsWChildrenNum);

                }
                
            }


            // HOUSEHOLDS WITH CHILDREN
            const householdsWithChildren = getHouseholdsWithChildren && getHouseholdsWithChildren.length && getHouseholdsWithChildren.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const thisHousesWithChildrenGauge = thisListing && thisListing.filterables && thisListing.filterables.childrenHousesGauge && thisListing.filterables.childrenHousesGauge;
            let childrenHousesNeedle = (thisHousesWithChildrenGauge && (thisHousesWithChildrenGauge > 0)) ? thisHousesWithChildrenGauge : 50;

            
            const populationObj = {address: thisListing.listingAddress, childrenHousesNum: householdsWithChildren, childrenHousesGauge: childrenHousesNeedle};
            return populationObj;
        }


        // SORT BY DAYTIME TRAFFIC
        const sortedByChildrenHouses = sortedByFourcastScore && sortedByFourcastScore.map(listing => getThisListingsPopulation(listing)).sort(function(a, b){return b.childrenHousesGauge - a.childrenHousesGauge});
        
        let listing1 = (sortedByChildrenHouses && sortedByChildrenHouses.length && (sortedByChildrenHouses[0] !== undefined)) ? sortedByChildrenHouses[0] : null;
        let listing2 = (sortedByChildrenHouses && sortedByChildrenHouses.length && (sortedByChildrenHouses[1] !== undefined)) ? sortedByChildrenHouses[1] : null;
        let listing3 = (sortedByChildrenHouses && sortedByChildrenHouses.length && (sortedByChildrenHouses[2] !== undefined)) ? sortedByChildrenHouses[2] : null;
        let listing4 = (sortedByChildrenHouses && sortedByChildrenHouses.length && (sortedByChildrenHouses[3] !== undefined)) ? sortedByChildrenHouses[3] : null;
        let listing5 = (sortedByChildrenHouses && sortedByChildrenHouses.length && (sortedByChildrenHouses[4] !== undefined)) ? sortedByChildrenHouses[4] : null;
        let listing6 = (sortedByChildrenHouses && sortedByChildrenHouses.length && (sortedByChildrenHouses[5] !== undefined)) ? sortedByChildrenHouses[5] : null;
        let listing7 = (sortedByChildrenHouses && sortedByChildrenHouses.length && (sortedByChildrenHouses[6] !== undefined)) ? sortedByChildrenHouses[6] : null;
        let listing8 = (sortedByChildrenHouses && sortedByChildrenHouses.length && (sortedByChildrenHouses[7] !== undefined)) ? sortedByChildrenHouses[7] : null;

        const setChdHousesGaugePointer = async (gauge, el) => {
            setTimeout(async () => {
                const childHouses = await document.querySelector(el);
                let chdHousesRotation = gauge;                
                childHouses && await childHouses.style.setProperty('--bottomChdHouses', chdHousesRotation);
            }, 100, { once: true });
        }

        (listing1 !== null) && listing1.childrenHousesGauge && setChdHousesGaugePointer(listing1.childrenHousesGauge, '.matchComparisonChildHousesComparisonPointer1');
        (listing2 !== null) && listing2.childrenHousesGauge && setChdHousesGaugePointer(listing2.childrenHousesGauge, '.matchComparisonChildHousesComparisonPointer2');
        (listing3 !== null) && listing3.childrenHousesGauge && setChdHousesGaugePointer(listing3.childrenHousesGauge, '.matchComparisonChildHousesComparisonPointer3');
        (listing4 !== null) && listing4.childrenHousesGauge && setChdHousesGaugePointer(listing4.childrenHousesGauge, '.matchComparisonChildHousesComparisonPointer4');
        (listing5 !== null) && listing5.childrenHousesGauge && setChdHousesGaugePointer(listing5.childrenHousesGauge, '.matchComparisonChildHousesComparisonPointer5');
        (listing6 !== null) && listing6.childrenHousesGauge && setChdHousesGaugePointer(listing6.childrenHousesGauge, '.matchComparisonChildHousesComparisonPointer6');
        (listing7 !== null) && listing7.childrenHousesGauge && setChdHousesGaugePointer(listing7.childrenHousesGauge, '.matchComparisonChildHousesComparisonPointer7');
        (listing8 !== null) && listing8.childrenHousesGauge && setChdHousesGaugePointer(listing8.childrenHousesGauge, '.matchComparisonChildHousesComparisonPointer8');

        if (listing1) {listing1.color = "rgba(254, 39, 18, 0.2)"};
        if (listing2) {listing2.color = "rgba(102, 176, 50, 0.2)"};
        if (listing3) {listing3.color = "rgba(251, 153, 2, 0.2)"};
        if (listing4) {listing4.color = "rgba(52, 124, 152, 0.2)"};
        if (listing5) {listing5.color = "rgb(254, 240, 51, 0.2)"};
        if (listing6) {listing6.color = "rgba(128, 0, 0, 0.2)"};
        if (listing7) {listing7.color = "rgba(2, 71, 254, 0.2)"};
        if (listing8) {listing8.color = "rgba(134, 1, 175, 0.2)"};
        
        const commaNumber = (num) => {
            let newNum = Number(num);
            let numString = newNum.toLocaleString("en-US");
            return numString;
        }

        return (
            <div style={{ position: 'relative', width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                    <div style={{ fontSize: 24, fontWeight: 700, color: 'darkgreen' }}>{inputValueTitle}</div>
                </div>
                <div className='areasWithChildrenCompareGaugeArea' style={{ position: 'relative', display: 'flex', marginTop: 100 }}>
                    <div style={{ flex: 1 }}>
                        <div style={{ width: '90%', margin: 'auto auto' }}>
                            <Table style={{ tableLayout: 'fixed' }}>
                                <thead>
                                    <tr style={{ textAlign: 'center' }}>
                                        <th style={{ width: '15%' }}></th>
                                        <th></th>
                                        <th style={{ width: '25%' }}>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img alt="Fourcast Households With Children Gauge Logo" src={FourcastLogo} style={{ width: 80 }} /></div>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, fontWeight: 700, padding: 5, color: 'rgb(52, 181, 122)' }}>Households with Children Gauge</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedByChildrenHouses && sortedByChildrenHouses.map(listing => 
                                        <tr style={{ textAlign: 'center' }}>
                                            <th style={{ width: '15%' }}>
                                                {sortedByChildrenHouses && sortedByChildrenHouses.length && sortedByChildrenHouses.indexOf(listing) === 0 ? <div style={{ display: 'flex', fontSize: 14, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', color: 'rgb(52, 181, 122)', marginLeft: 10, marginTop: 1 }}>Best</div> : <div style={{ display: 'flex', fontSize: 13, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', marginLeft: 10, marginTop: 1 }}>{(sortedByChildrenHouses.indexOf(listing) + 1)}</div>}
                                            </th>
                                            <td style={{ border: '1px solid lightgray', fontSize: 14, padding: 10, backgroundColor: listing.color }}>
                                                {listing && addressStyle(listing && listing.address)}
                                            </td>
                                            <td style={{ width: '25%', border: '1px solid lightgray', fontSize: 14, padding: 10, backgroundColor: listing.color }}>
                                                {listing && listing.childrenHousesGauge && `${listing.childrenHousesGauge.toFixed(0)}%`}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        <div style={{ width: '90%', margin: 'auto auto', marginTop: 150 }}>
                            <Table style={{ tableLayout: 'fixed' }}>
                                <tbody>
                                    <tr style={{ textAlign: 'center' }}>
                                        <th style={{ width: '20%' }}>
                                            <div style={{ display: 'flex', fontSize: 14, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', marginLeft: 10, marginTop: 1 }}>Citywide Gauge</div>
                                        </th>
                                        <td>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                <div style={{ fontWeight: 500, fontSize: 20 }}>Families With Children</div>
                                                <div style={{ marginLeft: 10 }}><FaBabyCarriage style={{ height: 25, width: 25, color: 'skyblue' }} /></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className='traffGaugeArea' style={{ flex: 1, width: '100%', height: 100, position: 'relative', marginTop: 25 }}>
                            <div className='matchComparisonNightlifeComparisonGauge'></div>
                            {((listing1 !== null) && (listing1.childrenHousesGauge)) ? <div className='matchComparisonChildHousesComparisonPointer1'></div> : null}
                            {((listing2 !== null) && (listing2.childrenHousesGauge)) ? <div className='matchComparisonChildHousesComparisonPointer2'></div> : null}
                            {((listing3 !== null) && (listing3.childrenHousesGauge)) ? <div className='matchComparisonChildHousesComparisonPointer3'></div> : null}
                            {((listing4 !== null) && (listing4.childrenHousesGauge)) ? <div className='matchComparisonChildHousesComparisonPointer4'></div> : null}
                            {((listing5 !== null) && (listing5.childrenHousesGauge)) ? <div className='matchComparisonChildHousesComparisonPointer5'></div> : null}
                            {((listing6 !== null) && (listing6.childrenHousesGauge)) ? <div className='matchComparisonChildHousesComparisonPointer6'></div> : null}
                            {((listing7 !== null) && (listing7.childrenHousesGauge)) ? <div className='matchComparisonChildHousesComparisonPointer7'></div> : null}
                            {((listing8 !== null) && (listing8.childrenHousesGauge)) ? <div className='matchComparisonChildHousesComparisonPointer8'></div> : null}
                            <div className='matchComparisonNightlifeComparisonGaugeHigh'>High</div>
                            <div className='matchComparisonNightlifeComparisonGaugeLow'>Low</div>
                        </div>
                    </div>
                </div>
                <hr />
            </div>
        );
    }
}

export default AreasWithChildrenMatchComparison;