import React, { Component } from 'react';
import 'chartjs-plugin-datalabels';
import { Table, Card } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import PriceGauge from '../../../Assets/priceGauge.png';
import '../../listingDetailsLayouts/demographics/demographics.css';
import './matchComparison.css';



class ResPricesMatchComparison extends Component {
    
    
    render() {

        const { listings, inputValueTitle } = this.props;
        
        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.fourcastScore - a.fourcastScore});

        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        };


        let getThisListingsResPrices = (thisListing) => {
            
            const resPriceIndex = thisListing && thisListing.scores && thisListing.scores.residentialPricesIndex && thisListing.scores.residentialPricesIndex;
        
            let resPriceNeedle; 
            
            if (resPriceIndex >= 95) {
                resPriceNeedle = 85;
            } else if (resPriceIndex >= 90) {
                resPriceNeedle = 75;
            } else if (resPriceIndex >= 85) {
                resPriceNeedle = 65;
            } else if (resPriceIndex >= 80) {
                resPriceNeedle = 45;
            } else if (resPriceIndex >= 75) {
                resPriceNeedle = 30;
            } else if (resPriceIndex >= 70) {
                resPriceNeedle = 20;
            } else if (resPriceIndex >= 65) {
                resPriceNeedle = 10;
            } else if (resPriceIndex >= 60) {
                resPriceNeedle = -10;
            } else if (resPriceIndex >= 55) {
                resPriceNeedle = -20;
            } else if (resPriceIndex >= 50) {
                resPriceNeedle = -30;
            } else if (resPriceIndex >= 45) {
                resPriceNeedle = -45;
            } else if (resPriceIndex >= 40) {
                resPriceNeedle = -65;
            } else if (resPriceIndex >= 35) {
                resPriceNeedle = -75;
            } else if (resPriceIndex >= 0) {
                resPriceNeedle = -85;
            } else {
                resPriceNeedle = 0;
            }

            const geoIdV4 = thisListing && thisListing.attomData && thisListing.attomData.geoIdV4 && thisListing.attomData.geoIdV4;

            // RES REAL ESTATE DATA
            let thisListingsPrimaryNeighbRent = {};
            let thisListingsSecondaryNeighbRent = {};
            let thisListingsPrimaryNeighbSalePrice = {};
            let thisListingsSecondaryNeighbSalePrice = {};
            const getOwnerDwellings = [];
            const getRenterDwellings = [];


            if (geoIdV4 && geoIdV4.length) {
    
                for (let g = 0; g < geoIdV4.length; g++) {

                    const thisAreaType = geoIdV4 && geoIdV4[g] && geoIdV4[g].type;
                    const thisListingsRent = (geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.housing_Median_Rent));
                    const thisListingsSalePrice = (geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.housing_Owner_Households_Median_Value));

                    if (geoIdV4 && (geoIdV4.length > 1)) {
                        if (thisAreaType === 'N4') {    
                            thisListingsPrimaryNeighbRent = {medRent: thisListingsRent, areaType: thisAreaType}
                        } else {
                            thisListingsSecondaryNeighbRent = {medRent: thisListingsRent, areaType: thisAreaType}
                        }
                    } else {
                        thisListingsPrimaryNeighbRent = {medRent: thisListingsRent, areaType: thisAreaType}
                    }

                    if (geoIdV4 && (geoIdV4.length > 1)) {
                        if (thisAreaType === 'N4') {    
                            thisListingsPrimaryNeighbSalePrice = {medSalePrice: thisListingsSalePrice, areaType: thisAreaType}
                        } else {
                            thisListingsSecondaryNeighbSalePrice = {medSalePrice: thisListingsSalePrice, areaType: thisAreaType}
                        }
                    } else {
                        thisListingsPrimaryNeighbSalePrice = {medSalePrice: thisListingsSalePrice, areaType: thisAreaType}
                    }

                    const theseOwnerDwellings = (geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.housing_Units_Owner_Occupied));
                    const theseRenterDwellings = (geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.housing_Units_Renter_Occupied));
                    getOwnerDwellings.push(theseOwnerDwellings);
                    getRenterDwellings.push(theseRenterDwellings);
    
                }
                
            }


            // RENT VS OWN GRAPH DATA
            const renterDwellings = getRenterDwellings && getRenterDwellings.length && getRenterDwellings.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });
            const ownerDwellings = getOwnerDwellings && getOwnerDwellings.length && getOwnerDwellings.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });

            const thisRentOwnData = {
                rentOwnData: {
                    labels: ['Renters', 'Owners'],
                    datasets: [
                        {
                            backgroundColor: ['rgb(52, 181, 122)', 'rgb(187, 236, 205)'],
                            data: [renterDwellings, ownerDwellings]
                        }
                    ],
                }
            };

            // find avg rent by weighing each N-type (if there are multiple) => .6 for primary N4 && .4 for secondary N2 or N3
            const primaryRent = thisListingsPrimaryNeighbRent.medRent;
            const secondaryRent = thisListingsSecondaryNeighbRent && thisListingsSecondaryNeighbRent.medRent ? thisListingsSecondaryNeighbRent.medRent : null;
            const secondaryRentAreaType = thisListingsSecondaryNeighbRent && thisListingsSecondaryNeighbRent.areaType ? thisListingsSecondaryNeighbRent.areaType : null;

            let medRent = 'N/A';

            if ((secondaryRent !== null) && (secondaryRentAreaType !== null)) {
                let primaryVal = (primaryRent * .6);
                let secondaryVal = (secondaryRent * .4);
                medRent = Math.ceil(primaryVal + secondaryVal);
            } else {
                medRent = Math.ceil(primaryRent);
            }

            // find median sale price by weighing each N-type (if there are multiple) => .6 for primary N4 && .4 for secondary N2 or N3
            const primarySalePrice = thisListingsPrimaryNeighbSalePrice.medSalePrice;
            const secondarySalePrice = thisListingsSecondaryNeighbSalePrice && thisListingsSecondaryNeighbSalePrice.medSalePrice ? thisListingsSecondaryNeighbSalePrice.medSalePrice : null;
            const secondarySalePriceAreaType = thisListingsSecondaryNeighbSalePrice && thisListingsSecondaryNeighbSalePrice.areaType ? thisListingsSecondaryNeighbSalePrice.areaType : null;

            let medSalePrice = 'N/A';

            if ((secondarySalePrice !== null) && (secondarySalePriceAreaType !== null)) {
                let primaryVal = (primarySalePrice * .6);
                let secondaryVal = (secondarySalePrice * .4);
                medSalePrice = Math.ceil(primaryVal + secondaryVal);
            } else {
                medSalePrice = Math.ceil(primarySalePrice);
            }

            const medianSalePrice = medSalePrice;
            const medianRentPrice = medRent;

            
            const resPricesObj = {address: thisListing.listingAddress, resPriceNeedle: resPriceNeedle, medianSale: medianSalePrice, medianRent: medianRentPrice, thisRentOwnData: thisRentOwnData};
            return resPricesObj;
        };
        
        

        // SORT BY REVIEW COUNT
        const sortedByResPrices = sortedByFourcastScore && sortedByFourcastScore.map((listing) => getThisListingsResPrices(listing)).sort(function(a, b){return a.resPriceNeedle - b.resPriceNeedle});
        
        let listing1 = (sortedByResPrices && sortedByResPrices.length && (sortedByResPrices[0] !== undefined)) ? sortedByResPrices[0] : null;
        let listing2 = (sortedByResPrices && sortedByResPrices.length && (sortedByResPrices[1] !== undefined)) ? sortedByResPrices[1] : null;
        let listing3 = (sortedByResPrices && sortedByResPrices.length && (sortedByResPrices[2] !== undefined)) ? sortedByResPrices[2] : null;
        let listing4 = (sortedByResPrices && sortedByResPrices.length && (sortedByResPrices[3] !== undefined)) ? sortedByResPrices[3] : null;
        let listing5 = (sortedByResPrices && sortedByResPrices.length && (sortedByResPrices[4] !== undefined)) ? sortedByResPrices[4] : null;
        let listing6 = (sortedByResPrices && sortedByResPrices.length && (sortedByResPrices[5] !== undefined)) ? sortedByResPrices[5] : null;
        let listing7 = (sortedByResPrices && sortedByResPrices.length && (sortedByResPrices[6] !== undefined)) ? sortedByResPrices[6] : null;
        let listing8 = (sortedByResPrices && sortedByResPrices.length && (sortedByResPrices[7] !== undefined)) ? sortedByResPrices[7] : null;

        const commaNumber = (num) => {
            let newNum = Number(num);
            let numString = newNum.toLocaleString("en-US");
            return numString;
        }

        let displaySalePrice = (listing) => {
            return (
                <>
                    {(listing && listing.medianSale) ? ( 
                        <div>
                            <div style={{ fontWeight: 800 }}>{`$${listing && commaNumber(listing.medianSale)}`}</div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }
        let displayRent = (listing) => {
            return (
                <>
                    {(listing && listing.medianRent) ? ( 
                        <div>
                            <div style={{ fontWeight: 800 }}>{`$${listing && commaNumber(listing.medianRent)}`}</div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }

    
        

        const setResPricesChart = (listing) => {
            return (
                <div style={{ fontSize: 12, padding: 5, width: '100%', height: 85 }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', height: 85, paddingTop: 10 }}>
                        <Table>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center', width: '50%' }}>Median Sale Price</th>
                                    <th style={{ textAlign: 'center', width: '50%' }}>Median Rent</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ fontSize: 12, textAlign: 'center', paddingTop: 10, color: 'darkgreen' }}>
                                        {listing && displaySalePrice(listing)}
                                    </td>
                                    <td style={{ fontSize: 12, textAlign: 'center', paddingTop: 10, color: 'darkgreen' }}>
                                        {listing && displayRent(listing)}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            )
        }

        const setOwnRentChart = (listing) => {
            return (
                <div style={{ position: 'relative', height: 95, width: '100%', padding: 10 }}>
                    <Pie
                        data={listing.thisRentOwnData.rentOwnData}
                        height={95}
                        options={{
                            title: {
                                display: true,
                                text: 'Rent vs Own',
                                fontSize: 10,
                                color: 'black'
                            },
                            plugins: {
                                datalabels: {
                                display: false,
                                },
                                legend: {
                                    display: true,
                                    position: 'right',
                                    labels: {
                                        boxWidth: 15,
                                        font: {
                                            size: 10
                                        },
                                    },
                                    align: "center",
                                }
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    grid: {
                                        display: false
                                    },
                                    ticks: {
                                        display: false
                                    }
                                },
                            },
                        }}
                    />
                </div>
            )
        }


        const setResPricesNeedle = async (gauge, el) => {
            setTimeout(async () => {
                const resPricesGauge = await document.querySelector(el);
                let resPricesRotation = gauge;                
                resPricesGauge && await resPricesGauge.style.setProperty('--rotation', resPricesRotation);
            }, 100, { once: true });
        }

        (listing1 !== null) && listing1.resPriceNeedle && setResPricesNeedle(listing1.resPriceNeedle, '.matchComparisonResPriceComparisonNeedle1');
        (listing2 !== null) && listing2.resPriceNeedle && setResPricesNeedle(listing2.resPriceNeedle, '.matchComparisonResPriceComparisonNeedle2');
        (listing3 !== null) && listing3.resPriceNeedle && setResPricesNeedle(listing3.resPriceNeedle, '.matchComparisonResPriceComparisonNeedle3');
        (listing4 !== null) && listing4.resPriceNeedle && setResPricesNeedle(listing4.resPriceNeedle, '.matchComparisonResPriceComparisonNeedle4');
        (listing5 !== null) && listing5.resPriceNeedle && setResPricesNeedle(listing5.resPriceNeedle, '.matchComparisonResPriceComparisonNeedle5');
        (listing6 !== null) && listing6.resPriceNeedle && setResPricesNeedle(listing6.resPriceNeedle, '.matchComparisonResPriceComparisonNeedle6');
        (listing7 !== null) && listing7.resPriceNeedle && setResPricesNeedle(listing7.resPriceNeedle, '.matchComparisonResPriceComparisonNeedle7');
        (listing8 !== null) && listing8.resPriceNeedle && setResPricesNeedle(listing8.resPriceNeedle, '.matchComparisonResPriceComparisonNeedle8');
        
        

        
        return (
            <div style={{ position: 'relative', width: '98%' }}>
                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'end', paddingBottom: 10 }}>
                    <div style={{ fontSize: 24, fontWeight: 700, marginLeft: 5, color: 'darkgreen' }}>{inputValueTitle}</div>
                    <div style={{ fontSize: 22, fontWeight: 500, marginLeft: 5 }}>- Residential Real Estate Gauge</div>
                </div>
                <div style={{ position: 'relative', width: '100%' }}>
                    {listing1 !== null ? (<div style={{ position: 'relative' }}>
                        <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                            <div style={{ marginLeft: 5, width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>1</div>
                            <div style={{ marginLeft: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing1.address)}</div>
                            <div style={{ marginLeft: 10, display: 'flex', fontSize: 12, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', color: 'rgb(52, 181, 122)', fontWeight: 700 }}>Most Affordable</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ width: '35%', position: 'relative' }}>
                                <div style={{ height: 95, width: '100%' }} className='matchComparisonResPriceGaugeComparison'>
                                    <img src={PriceGauge} alt='' />
                                    <div className='matchComparisonResPriceComparisonNeedle1'></div>
                                </div>
                            </div>
                            <div style={{ width: '35%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {(listing1 !== null) && setResPricesChart(listing1)}
                            </div>
                            <div style={{ width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'end', paddingTop: 15 }}>
                                {(listing1 !== null) && setOwnRentChart(listing1)}
                            </div>
                        </div>
                    </div>) : null}
                    {listing2 !== null ? (<div style={{ position: 'relative', marginTop: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                            <div style={{ marginLeft: 5, width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>2</div>
                            <div style={{ marginLeft: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing2.address)}</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ width: '35%', position: 'relative' }}>
                                <div style={{ height: 95, width: '100%' }} className='matchComparisonResPriceGaugeComparison'>
                                    <img src={PriceGauge} alt='' />
                                    <div className='matchComparisonResPriceComparisonNeedle2'></div>
                                </div>
                            </div>
                            <div style={{ width: '35%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {(listing2 !== null) && setResPricesChart(listing2)}
                            </div>
                            <div style={{ width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'end', paddingTop: 15 }}>
                                {(listing2 !== null) && setOwnRentChart(listing2)}
                            </div>
                        </div>
                    </div>) : null}
                    {listing3 !== null ? (<div style={{ position: 'relative', marginTop: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                            <div style={{ marginLeft: 5, width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>3</div>
                            <div style={{ marginLeft: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing3.address)}</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ width: '35%', position: 'relative' }}>
                                <div style={{ height: 95, width: '100%' }} className='matchComparisonResPriceGaugeComparison'>
                                    <img src={PriceGauge} alt='' />
                                    <div className='matchComparisonResPriceComparisonNeedle3'></div>
                                </div>
                            </div>
                            <div style={{ width: '35%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {(listing3 !== null) && setResPricesChart(listing3)}
                            </div>
                            <div style={{ width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'end', paddingTop: 15 }}>
                                {(listing3 !== null) && setOwnRentChart(listing3)}
                            </div>
                        </div>
                    </div>) : null}
                    {listing4 !== null ? (<div style={{ position: 'relative', marginTop: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                            <div style={{ marginLeft: 5, width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>4</div>
                            <div style={{ marginLeft: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing4.address)}</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ width: '35%', position: 'relative' }}>
                                <div style={{ height: 95, width: '100%' }} className='matchComparisonResPriceGaugeComparison'>
                                    <img src={PriceGauge} alt='' />
                                    <div className='matchComparisonResPriceComparisonNeedle4'></div>
                                </div>
                            </div>
                            <div style={{ width: '35%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {(listing4 !== null) && setResPricesChart(listing4)}
                            </div>
                            <div style={{ width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'end', paddingTop: 15 }}>
                                {(listing4 !== null) && setOwnRentChart(listing4)}
                            </div>
                        </div>
                    </div>) : null}
                    {listing5 !== null ? (<div style={{ position: 'relative', marginTop: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                            <div style={{ marginLeft: 5, width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>5</div>
                            <div style={{ marginLeft: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing5.address)}</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ width: '35%', position: 'relative' }}>
                                <div style={{ height: 95, width: '100%' }} className='matchComparisonResPriceGaugeComparison'>
                                    <img src={PriceGauge} alt='' />
                                    <div className='matchComparisonResPriceComparisonNeedle5'></div>
                                </div>
                            </div>
                            <div style={{ width: '35%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {(listing5 !== null) && setResPricesChart(listing5)}
                            </div>
                            <div style={{ width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'end', paddingTop: 15 }}>
                                {(listing5 !== null) && setOwnRentChart(listing5)}
                            </div>
                        </div>
                    </div>) : null}
                    {listing6 !== null ? (<div style={{ position: 'relative', marginTop: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                            <div style={{ marginLeft: 5, width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>6</div>
                            <div style={{ marginLeft: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing6.address)}</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ width: '35%', position: 'relative' }}>
                                <div style={{ height: 95, width: '100%' }} className='matchComparisonResPriceGaugeComparison'>
                                    <img src={PriceGauge} alt='' />
                                    <div className='matchComparisonResPriceComparisonNeedle6'></div>
                                </div>
                            </div>
                            <div style={{ width: '35%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {(listing6 !== null) && setResPricesChart(listing6)}
                            </div>
                            <div style={{ width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'end', paddingTop: 15 }}>
                                {(listing6 !== null) && setOwnRentChart(listing6)}
                            </div>
                        </div>
                    </div>) : null}
                    {listing7 !== null ? (<div style={{ position: 'relative', marginTop: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                            <div style={{ marginLeft: 5, width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>7</div>
                            <div style={{ marginLeft: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing7.address)}</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ width: '35%', position: 'relative' }}>
                                <div style={{ height: 95, width: '100%' }} className='matchComparisonResPriceGaugeComparison'>
                                    <img src={PriceGauge} alt='' />
                                    <div className='matchComparisonResPriceComparisonNeedle7'></div>
                                </div>
                            </div>
                            <div style={{ width: '35%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {(listing7 !== null) && setResPricesChart(listing7)}
                            </div>
                            <div style={{ width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'end', paddingTop: 15 }}>
                                {(listing7 !== null) && setOwnRentChart(listing7)}
                            </div>
                        </div>
                    </div>) : null}
                    {listing8 !== null ? (<div style={{ position: 'relative', marginTop: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                            <div style={{ marginLeft: 5, width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>8</div>
                            <div style={{ marginLeft: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing8.address)}</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ width: '35%', position: 'relative' }}>
                                <div style={{ height: 95, width: '100%' }} className='matchComparisonResPriceGaugeComparison'>
                                    <img src={PriceGauge} alt='' />
                                    <div className='matchComparisonResPriceComparisonNeedle8'></div>
                                </div>
                            </div>
                            <div style={{ width: '35%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {(listing8 !== null) && setResPricesChart(listing8)}
                            </div>
                            <div style={{ width: '30%', display: 'flex', justifyContent: 'center', alignItems: 'end', paddingTop: 15 }}>
                                {(listing8 !== null) && setOwnRentChart(listing8)}
                            </div>
                        </div>
                    </div>) : null}
                </div>
            </div>
        );
    }
}

export default ResPricesMatchComparison;