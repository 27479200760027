import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Row } from 'reactstrap';
import { Button } from '@mui/material';


class LeavingPageAlert extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        }
    }

    toggle = () => {
                
        this.setState({ 
            modal: !this.state.modal
        })
    };


    render() { 
        
        const { thisListing, listings } = this.props;
        
        return ( 
            <div>
                <Button
                    variant="contained"
                    size="small"
                    onClick={this.toggle}
                    style={{ marginTop: 25, marginLeft: 45, backgroundColor: 'gray' }}
                >
                    Listing Page
                </Button>

                <Modal style={{ minWidth: 600 }} isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Are You Sure You Want To Leave?</ModalHeader>
                    <ModalBody style={{ minWidth: 550, minHeight: 500 }}>
                        <Row style={{ height: 200 }}>
                            <div style={{ height: 50, textAlign: 'center', margin: 'auto', width: '80%' }}>
                                <h3 style={{ alignText: 'center' }}>Warning</h3>
                                <p style={{ alignText: 'center', marginTop: 15, width: '80%' }}>To include your Fourcast Match results in an inquiry, you have to inquire from this page, otherwise you'll lose your results and have to re-do your search.</p>
                            </div>
                        </Row>
                        <Row style={{ height: 200, paddingTop: 50 }}>
                            <span>
                                <Button onClick={this.toggle} size="medium" variant="contained" style={{ marginLeft: 100, backgroundColor: 'rgb(35, 126, 85)', color: 'white' }}>
                                    Stay On This Page
                                </Button>
                            </span>
                            <span>
                                <Link to={`/listings/${thisListing._id}`} target="_blank" listings={listings} style={{ textDecoration: 'none' }}>
                                    <Button 
                                        size="medium" 
                                        variant="contained"
                                        style={{ marginLeft: 50, backgroundColor: 'gray', color: 'white' }}
                                    >
                                        Go To Listing Page
                                    </Button>
                                </Link>
                            </span>
                        </Row>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}


export default LeavingPageAlert;