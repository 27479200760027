import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getListings } from '../../actions/listingActions';
import PropTypes from 'prop-types';
import { GoogleMap, LoadScript, StreetViewPanorama } from '@react-google-maps/api';
import { Container, Row } from 'react-bootstrap';
import { ImCheckmark } from 'react-icons/im';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Card } from '@material-ui/core';
import { Table, Fab, Modal, LinearProgress } from '@mui/material';
import YelpStarRating from '../listingDetailsLayouts/competition/yelpStarRating';
import Select from 'react-select';
import SearchAddressDemographics from './searchedAddressDemographics';
import '../listingDetailsLayouts/traffic/traffic.css';
import './searchedAddressSummary.css';


class SearchedAddressSummary extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
            categoryName: 'summary',
            selected: [],
            nearbyListings: [],
            nearbyListingsModal: false,
            estimatedFourcastScores: null,
            selectedNeighborhood: null,
            changeNeighborhood: false,
            screenWidth: null
        }
    }

    toggle = () => {
        this.setState({
            nearbyListingsModal: !this.state.nearbyListingsModal,
        });
    }

    componentDidMount() {
        this.props.getLocation(this.props.location.pathname);
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
        // setting mobile views
        let screenWidth = window.screen.width;
        this.setState({ screenWidth: screenWidth });
        if (this.props.location.state) {
            
            const { selected, nearbyListings, estimatedFourcastScores } = this.props.location.state;
            this.setState({ selected: selected, nearbyListings: nearbyListings, estimatedFourcastScores: estimatedFourcastScores });
            
            let page;
            page = { path: "searchedAddress", filters: { nearbyListings: nearbyListings } };
            
            this.props.getListings(page);

        }
    }

    componentWillUnmount() {
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
    }

    mobileMode() {
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth });
        }
    }

    render() { 
        
        const { selectedNeighborhood, nearbyListings, estimatedFourcastScores, screenWidth } = this.state;
        const thisSearchedAddress = this.state.selected;
        const { listings: { listings } = {}} = this.props;
        const { listings: { neighborhoods } = {}} = this.props;
        const { listings: { backupListings } = {}} = this.props;

        
        // ADD COMMA TO "NY 101010" => "NY, 101010"
        const splitStreet = thisSearchedAddress && thisSearchedAddress.address && thisSearchedAddress.address.split(", ");
        const thisStreetArr = splitStreet && splitStreet.length && splitStreet.slice(0, -1);
        const thisStateZip = splitStreet && splitStreet[splitStreet.length - 1].split(" ");
        const newAddressArr = ((thisStreetArr && thisStreetArr.length) && (thisStateZip && thisStateZip)) && [...thisStreetArr, ...thisStateZip];
        const newStreetAddress = newAddressArr && newAddressArr.length && newAddressArr.join(", ");

        const activeListingHere = listings && listings.length && listings.filter(listing => listing.listingAddress && listing.listingAddress.includes(newStreetAddress))

        // STREET VIEW STYLES
        const mapContainerStyle = {
            height: "450px",
            width: "750px",
        };

        const center = {
            lat: thisSearchedAddress.lat,
            lng: thisSearchedAddress.lng
        };

        // ESTIMATED SCORES
        const thisEstimatedFourcastScore = estimatedFourcastScores && estimatedFourcastScores.estimatedFourcastScore;
        const thisEstimatedTrafficScore = estimatedFourcastScores && estimatedFourcastScores.estimatedTrafficScore;
        const thisEstimatedDemoScore = estimatedFourcastScores && estimatedFourcastScores.estimatedDemoScore;
        const thisEstimatedCompScore = estimatedFourcastScores && estimatedFourcastScores.estimatedCompScore;
        const thisEstimatedPropScore = estimatedFourcastScores && estimatedFourcastScores.estimatedPropScore;
        
        // NEARBY BUSINESSES
        const getNearbyListingsYelpData = listings && listings.length && listings.map(listing => listing.yelpData && listing.yelpData.businesses && listing.yelpData.businesses);
        const getNearbyListingsBusinesses = getNearbyListingsYelpData && getNearbyListingsYelpData.length && getNearbyListingsYelpData.flatMap(bizList => bizList);
        const allNearbyListingsBusinesses = getNearbyListingsBusinesses && getNearbyListingsBusinesses.length && [...new Map(getNearbyListingsBusinesses.map(v => [JSON.stringify(v && v.alias), v])).values()];
        const topTenBusinesses = allNearbyListingsBusinesses && allNearbyListingsBusinesses.length && allNearbyListingsBusinesses.sort((a, b) => b.review_count - a.review_count).slice(0, 9);
        
        // NEARBY POIs
        const getNearbyListingsPOI = listings && listings.length && listings.map(listing => listing.scores && listing.scores.POI && [listing.scores.POI.poi1, listing.scores.POI.poi2, listing.scores.POI.poi3]);
        const getNearbyListingsPOIs = getNearbyListingsPOI && getNearbyListingsPOI.length && getNearbyListingsPOI.flatMap(place => place);
        const uniquePOIs = getNearbyListingsPOIs && getNearbyListingsPOIs.length && [...new Map(getNearbyListingsPOIs.map(v => [JSON.stringify(v && v.place_id && v.place_id), v])).values()];
        const topNearbyPOIs = uniquePOIs && uniquePOIs.length && uniquePOIs.sort((a, b) => b.user_ratings_total - a.user_ratings_total).slice(0, 3);
        const poi1Lat = topNearbyPOIs && topNearbyPOIs.length && topNearbyPOIs[0] && topNearbyPOIs[0].latitude && topNearbyPOIs[0].latitude;
        const poi1Lon = topNearbyPOIs && topNearbyPOIs.length && topNearbyPOIs[0] && topNearbyPOIs[0].longitude && topNearbyPOIs[0].longitude;
        const poi2Lat = topNearbyPOIs && topNearbyPOIs.length && topNearbyPOIs[1] && topNearbyPOIs[1].latitude && topNearbyPOIs[1].latitude;
        const poi2Lon = topNearbyPOIs && topNearbyPOIs.length && topNearbyPOIs[1] && topNearbyPOIs[1].longitude && topNearbyPOIs[1].longitude;
        const poi3Lat = topNearbyPOIs && topNearbyPOIs.length && topNearbyPOIs[2] && topNearbyPOIs[2].latitude && topNearbyPOIs[2].latitude;
        const poi3Lon = topNearbyPOIs && topNearbyPOIs.length && topNearbyPOIs[2] && topNearbyPOIs[2].longitude && topNearbyPOIs[2].longitude;

        // NEARBY FILTER TAGS
        const getNearbyListingsFilterTags = listings && listings.length && listings.map(listing => listing.filterTags && listing.filterTags.length && [...listing.filterTags]);
        const cleanNearbyListingsFilterTags = getNearbyListingsFilterTags && getNearbyListingsFilterTags.length && getNearbyListingsFilterTags.flatMap(tag => tag);
        const uniqueFilterTags = cleanNearbyListingsFilterTags && cleanNearbyListingsFilterTags.length && [...new Map(cleanNearbyListingsFilterTags.map(v => [JSON.stringify(v), v])).values()];
        
        const trafficFilterTags = [];
        const compFilterTags = [];
        const demoFilterTags = [];

        if (uniqueFilterTags && uniqueFilterTags.length) {
            for (let i = 0; i < uniqueFilterTags.length; i ++) {
                
                if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('nearAttraction')) {
                    trafficFilterTags.push({tagName: 'Near Major Attraction', tagDescription: 'This space is within a 250 meter radius (~5 min walk) of a major attraction, which can generate traffic and tourists in the area.'});
                    demoFilterTags.push({tagName: 'Tourist Area'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('busyDayTime')) {
                    trafficFilterTags.push({tagName: 'Busy Daytime', tagDescription: 'Traffic is high during the day, and drops at night.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('busyMonThruFri')) {
                    trafficFilterTags.push({tagName: 'Busy Weekdays', tagDescription: 'Most traffic occurs during the weekdays, Monday-Friday.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('busyWeekend')) {
                    trafficFilterTags.push({tagName: 'Busy Weekends', tagDescription: 'Traffic is high during the weekends, Friday-Sunday.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('busyNightLife')) {
                    trafficFilterTags.push({tagName: 'Busy Nightlife', tagDescription: 'There are numerous nightlife spots nearby that are open late.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('seasonal')) {
                    trafficFilterTags.push({tagName: 'Seasonal', tagDescription: 'Traffic here fluctuates depending on the season or time of year.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('volatileTraffic')) {
                    trafficFilterTags.push({tagName: 'Volatile Traffic', tagDescription: 'Traffic increases and decreases dramatically depending on the time and day.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('highEndBiz')) {
                    compFilterTags.push({tagName: 'High End Businesses', tagDescription: 'There are numerous high-end businesses nearby.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('lowEndBiz')) {
                    compFilterTags.push({tagName: 'Affordable Businesses', tagDescription: 'There are mostly businesses with affordable product prices nearby.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('highBizDensity')) {
                    compFilterTags.push({tagName: 'High Business Density', tagDescription: 'There is a high concentration of businesses in this area, which typically attracts more shoppers to the area.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('lowBizDensity')) {
                    compFilterTags.push({tagName: 'Low Business Density', tagDescription: "There is a low concentration of businesses in this area, which typically won't attract as many shoppers to the area."});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('localResidents')) {
                    demoFilterTags.push({tagName: 'Mostly Residential Area', tagDescription: 'This is a largely residential area.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('officeWorkers')) {
                    demoFilterTags.push({tagName: 'Office Workers', tagDescription: 'A significant amount of people work in this area.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('students')) {
                    demoFilterTags.push({tagName: 'Students', tagDescription: 'There is a relatively high concentration of students in the area, compared to most parts of the city.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('highIncomeArea')) {
                    demoFilterTags.push({tagName: 'High Income Area', tagDescription: 'The average income levels and/or volume of people making higher incomes are higher here than most areas in the city.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('affordableRE')) {
                    demoFilterTags.push({tagName: 'Affordable Living', tagDescription: 'Residential real estate prices in this area are typically more affordable than other neighborhoods in the city.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('expensiveRE')) {
                    demoFilterTags.push({tagName: 'Expensive Living', tagDescription: 'Residential real estate prices in this area are typically more expensive than other neighborhoods in the city.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('genZ')) {
                    demoFilterTags.push({tagName: 'Gen Z', tagDescription: 'There is a Gen Z population in this area.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('millennialsTo34')) {
                    demoFilterTags.push({tagName: 'Young Millennials', tagDescription: 'There are young Millennials between 25-34 living here.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('millennialsTo44')) {
                    demoFilterTags.push({tagName: 'Older Millennials', tagDescription: 'You can find older Millennials between 35-44 in this neighborhood.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('genX')) {
                    demoFilterTags.push({tagName: 'Gen X', tagDescription: 'You can find more Gen X living here than many other neighborhoods.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('boomers')) {
                    demoFilterTags.push({tagName: 'Boomers', tagDescription: 'There is a sizable population of Baby Boomers living here.'});
                } else if (uniqueFilterTags[i] && uniqueFilterTags[i].includes('areasWithChildren')) {
                    demoFilterTags.push({tagName: 'Families with Children', tagDescription: 'You can find more families with children living here than many other neighborhoods.'});
                }
            }
        }

        // this creates an array of all the tags which can be put together to create a paragraph that gives a description of the area in writing
        const notableCharacteristics = [...trafficFilterTags, ...compFilterTags, ...demoFilterTags];

        // NEARBY NEIGHBORHOODS
        const getAttomList = listings && listings.length && listings.map(listing => {
            const nearestListingId = nearbyListings && nearbyListings.length && nearbyListings[0] && nearbyListings[0].listingData && nearbyListings[0].listingData._id && nearbyListings[0].listingData._id;
            const nearestListingDist = nearbyListings && nearbyListings.length && nearbyListings[0] && nearbyListings[0].dist && nearbyListings[0].dist;
            const listingId = (listing && listing._id);
            let nextdoorListing = false;
            if (listingId === nearestListingId) {
                if (nearestListingDist <= 55) {
                    nextdoorListing = true;
                }
            }
            const validNeighbs = listing && listing.attomData && listing.attomData.geoIdV4 && listing.attomData.geoIdV4.length && listing.attomData.geoIdV4.filter(geoArea => (((geoArea.type === 'N2') || (geoArea.type === 'N3')) || ((listingId === nearestListingId) && (nextdoorListing === true))));
            const validAttomObjs = [];
            for (let a = 0; a < validNeighbs.length; a++) {
                const attomObj = {};
                const neighbName =  validNeighbs && validNeighbs[a] && validNeighbs[a].name && validNeighbs[a].name;
                const areaId = validNeighbs && validNeighbs[a] && validNeighbs[a].geoIdV4 && validNeighbs[a].geoIdV4;
                const allAttomData = validNeighbs && validNeighbs[a];
                attomObj.name = neighbName;
                attomObj.areaId = areaId;
                attomObj.attomData = allAttomData;
                validAttomObjs.push(attomObj);
            }
            return validAttomObjs;
        });
        const attomList = getAttomList && getAttomList.flatMap(list => list);
        
        const majorNeighbs = neighborhoods && neighborhoods.length && neighborhoods.filter(neighb => neighb.area && neighb.area.areaData && ((neighb.area.areaData.type === 'N2') || (neighb.area.areaData.type === 'N3')));
        const otherNeighbs = majorNeighbs && majorNeighbs.length && majorNeighbs.map(neighb => {
            const attomObj = {};
            const neighbName = neighb && neighb.area && neighb.area.areaData && neighb.area.areaData.name && neighb.area.areaData.name;
            const areaId =  neighb && neighb.area && neighb.area.geoIdV4 && neighb.area.geoIdV4;
            const allAttomData = neighb && neighb.comm && neighb.comm;
            attomObj.name = neighbName;
            attomObj.areaId = areaId;
            attomObj.attomData = allAttomData;
            return attomObj;
        });

        const getUniqueNeighborhoods = attomList && attomList.length && attomList.flatMap(neighb => neighb);
        const uniqueNeighborhoods = getUniqueNeighborhoods && getUniqueNeighborhoods.length && [...new Map(getUniqueNeighborhoods.map(v => [JSON.stringify(v), v])).values()];
        
        const neighbList = uniqueNeighborhoods && uniqueNeighborhoods.length && uniqueNeighborhoods.map(attomObj => {return {label: attomObj && attomObj.name && attomObj.name, value: attomObj && attomObj.attomData && attomObj.attomData}}).filter(neighb => ((neighb.label !== undefined) || neighb.value !== undefined));
        const otherNeighbList = otherNeighbs && otherNeighbs.length && otherNeighbs.map(attomObj => {return {label: attomObj && attomObj.name && attomObj.name, value: attomObj && attomObj.attomData && attomObj.attomData}});
        const finalNearbyNeighborhoods = neighbList && neighbList.length && neighbList.sort((a, b) => a && a.label && a.label.toLowerCase().localeCompare(b && b.label && b.label.toLowerCase()));
        const finalOtherNeighborhoods = otherNeighbList && otherNeighbList.length && otherNeighbList.sort((a, b) => a && a.label && a.label.toLowerCase().localeCompare(b && b.label && b.label.toLowerCase()));

        const neighbOptions = [
            { label: "Nearby Neighborhoods", options: finalNearbyNeighborhoods },
            { label: "More Neighborhoods ", options: finalOtherNeighborhoods },
        ];

        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        };

        const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: 'black',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
            overflowY: 'scroll'
        };
          
        const formatGroupLabel = data => (
            <div style={groupStyles}>
                <span>{data.label}</span>
                <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
        );

        
        // NEARBY DEMOGRAPHICS DATA
        // Get any listing in the neighborhood selected for demographics data
        // Use the listings attom/demo data for the searched address demographics section
        const getNearestListing = listings && listings.length && listings.filter(listing => {
            const nearestListingNeighb = nearbyListings && nearbyListings.length && nearbyListings[0] && nearbyListings[0].listingData && nearbyListings[0].listingData.neighborhood && nearbyListings[0].listingData.neighborhood;
            const listingNeighb = (listing && listing.neighborhood);
            if (listingNeighb === nearestListingNeighb) {
                if ((listing && listing.scores && listing.scores.POI) && (listing && listing.scores && listing.scores.incomeGauge) && (listing && listing.scores && listing.scores.residentialPricesIndex)) {
                    return listing;
                }
            }
        });

        const getNearestBackupListing = backupListings && backupListings.length && backupListings.filter(listing => {
            const nearestListingNeighb = nearbyListings && nearbyListings.length && nearbyListings[0] && nearbyListings[0].listingData && nearbyListings[0].listingData.neighborhood && nearbyListings[0].listingData.neighborhood;
            const listingNeighb = (listing && listing.neighborhood);
            if (listingNeighb === nearestListingNeighb) {
                if ((listing && listing.scores && listing.scores.POI) && (listing && listing.scores && listing.scores.incomeGauge) && (listing && listing.scores && listing.scores.residentialPricesIndex)) {
                    return listing;
                }
            }
        });
        
        const nearbyListingInSelectedNeighborhood = listings && listings.length && listings.filter(listing => {
            const selectedNeighborhoodCommId = (selectedNeighborhood && selectedNeighborhood.length && selectedNeighborhood[0] && selectedNeighborhood[0].value && selectedNeighborhood[0].value.commId);
            const selectedNeighborhoodAreaId = (selectedNeighborhood && selectedNeighborhood.length && selectedNeighborhood[0] && selectedNeighborhood[0].value && selectedNeighborhood[0].value.geoIdV4);
            let listingAreaId1 = listing.attomData && listing.attomData.geoIdV4 && listing.attomData.geoIdV4.length && listing.attomData.geoIdV4[0].geoIdV4;
            let listingAreaId2;
            if (listing.attomData && listing.attomData.geoIdV4 && (listing.attomData.geoIdV4.length > 1)) {
                listingAreaId2 = listing.attomData.geoIdV4[1].geoIdV4;
            }
            if (((listingAreaId1 !== undefined) && (listingAreaId1 === selectedNeighborhoodCommId)) || ((listingAreaId1 !== undefined) && (listingAreaId1 === selectedNeighborhoodAreaId)) || ((listingAreaId2 !== undefined) && (listingAreaId2 === selectedNeighborhoodCommId)) || ((listingAreaId2 !== undefined) && (listingAreaId2 === selectedNeighborhoodAreaId))) {
                if ((listing && listing.scores && listing.scores.POI) && (listing && listing.scores && listing.scores.incomeGauge) && (listing && listing.scores && listing.scores.residentialPricesIndex) && (listing && listing.filterables && listing.filterables.childrenHousesGauge)) {
                    return listing;
                }
            }
        });

        const nearbyBackupListingInSelectedNeighborhood = backupListings && backupListings.length && backupListings.filter(listing => {
            const selectedNeighborhoodCommId = (selectedNeighborhood && selectedNeighborhood.length && selectedNeighborhood[0] && selectedNeighborhood[0].value && selectedNeighborhood[0].value.commId);
            const selectedNeighborhoodAreaId = (selectedNeighborhood && selectedNeighborhood.length && selectedNeighborhood[0] && selectedNeighborhood[0].value && selectedNeighborhood[0].value.geoIdV4);
            let listingAreaId1 = listing.attomData && listing.attomData.geoIdV4 && listing.attomData.geoIdV4.length && listing.attomData.geoIdV4[0].geoIdV4;
            let listingAreaId2;
            if (listing.attomData && listing.attomData.geoIdV4 && (listing.attomData.geoIdV4.length > 1)) {
                listingAreaId2 = listing.attomData.geoIdV4[1].geoIdV4;
            }
            if ((listingAreaId1 === selectedNeighborhoodCommId) || (listingAreaId1 === selectedNeighborhoodAreaId) || (listingAreaId2 === selectedNeighborhoodCommId) || (listingAreaId2 === selectedNeighborhoodAreaId)) {
                if ((listing && listing.scores && listing.scores.POI) && (listing && listing.scores && listing.scores.incomeGauge) && (listing && listing.scores && listing.scores.residentialPricesIndex) && (listing && listing.filterables && listing.filterables.childrenHousesGauge)) {
                    return listing;
                }
            }
        });
        // THIS FUNCTION GETS THE DESIRED NESTED GEOIDV4 AREA WHEN THE NEARBY LISTING BEING USED HAS BOTH OF THE VALID NEIGHBORHOODS
        const getDesiredAttomData = (thisAttomData, selectedNeighb) => {
            let theAttomData = thisAttomData && thisAttomData.geoIdV4 && thisAttomData.geoIdV4.filter(geoArea => geoArea.name === (selectedNeighb && selectedNeighb[0].label));
            let geoIdV4Data = theAttomData && theAttomData[0];
            return geoIdV4Data;
        }

        const nearbyListingToBeUsedForAttomData = (nearbyListingInSelectedNeighborhood && nearbyListingInSelectedNeighborhood.length && nearbyListingInSelectedNeighborhood[0]) ? nearbyListingInSelectedNeighborhood[0] : (nearbyBackupListingInSelectedNeighborhood && nearbyBackupListingInSelectedNeighborhood.length && nearbyBackupListingInSelectedNeighborhood[0]) ? nearbyBackupListingInSelectedNeighborhood[0] : null;
        
        const initialAttomData = ((nearbyListingToBeUsedForAttomData === null) && (selectedNeighborhood === null)) ? (getNearestListing && getNearestListing.length) ? (getNearestListing[0] && getNearestListing[0].attomData && getNearestListing[0].attomData.geoIdV4 && getNearestListing[0].attomData.geoIdV4.length && getNearestListing[0].attomData.geoIdV4[0]) : (getNearestBackupListing && getNearestBackupListing.length) ? (getNearestBackupListing && getNearestBackupListing.length && getNearestBackupListing[0].attomData && getNearestBackupListing[0].attomData.geoIdV4 && getNearestBackupListing[0].attomData.geoIdV4.length && getNearestBackupListing[0].attomData.geoIdV4[0]) : (neighbList && neighbList.length && neighbList[0] && neighbList[0].value && {commV4Data: neighbList[0].value.commData}) : null;
        const initialScoresToUse = ((nearbyListingToBeUsedForAttomData === null) && (selectedNeighborhood === null)) ? (getNearestListing && getNearestListing.length) ? (getNearestListing[0] && getNearestListing[0].scores && getNearestListing[0].scores) : (getNearestBackupListing && getNearestBackupListing.length) ? (getNearestBackupListing && getNearestBackupListing.length && getNearestBackupListing[0].scores && getNearestBackupListing[0].scores) : null : null;
        const initialFilterablesToUse = ((nearbyListingToBeUsedForAttomData === null) && (selectedNeighborhood === null)) ? (getNearestListing && getNearestListing.length) ? (getNearestListing[0] && getNearestListing[0].filterables && getNearestListing[0].filterables) : (getNearestBackupListing && getNearestBackupListing.length) ? (getNearestBackupListing && getNearestBackupListing.length && getNearestBackupListing[0].filterables && getNearestBackupListing[0].filterables) : null : null;
        
        const scoresToUse = ((nearbyListingToBeUsedForAttomData === null) && (selectedNeighborhood === null)) ? initialScoresToUse : nearbyListingToBeUsedForAttomData && nearbyListingToBeUsedForAttomData.scores && nearbyListingToBeUsedForAttomData.scores;
        const filterablesToUse = ((nearbyListingToBeUsedForAttomData === null) && (selectedNeighborhood === null)) ? initialFilterablesToUse : nearbyListingToBeUsedForAttomData && nearbyListingToBeUsedForAttomData.filterables && nearbyListingToBeUsedForAttomData.filterables;
        const attomDataToUse = ((nearbyListingToBeUsedForAttomData === null) && (selectedNeighborhood === null)) ? initialAttomData : (nearbyListingToBeUsedForAttomData && nearbyListingToBeUsedForAttomData.attomData) ? (nearbyListingToBeUsedForAttomData.attomData && nearbyListingToBeUsedForAttomData.attomData.geoIdV4 && nearbyListingToBeUsedForAttomData.attomData.geoIdV4.length && getDesiredAttomData(nearbyListingToBeUsedForAttomData.attomData, selectedNeighborhood)) : (selectedNeighborhood && selectedNeighborhood.length && selectedNeighborhood[0] && selectedNeighborhood[0].value);
        const initialNeighborhoodName = neighbList && neighbList.length && neighbList[0] && neighbList[0].label;
        

        return (
            <div style={{ backgroundColor: 'whitesmoke' }}>
            {((listings && listings.length) && (topTenBusinesses && topTenBusinesses.length) && (topNearbyPOIs && topNearbyPOIs.length)) ?
            ((screenWidth === null) || (screenWidth > 1000)) ? 
            (<Container style={{ paddingTop: 50, height: '100%', minWidth: 1500, margin: 'auto', paddingBottom: 400 }}>
                <div style={{ width: '100%', height: '10%', display: 'grid', gridTemplateColumns: '3fr 2fr', gap: 10 }}>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                        <h2>{thisSearchedAddress && thisSearchedAddress.address}</h2>
                        <div style={{ width: 15, height: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: `${(activeListingHere && activeListingHere.length) ? 'rgb(52, 181, 122)' : 'gray'}`, borderRadius: '50%', marginLeft: 20, marginBottom: 5 }}></div>
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr' }}>
                        <div>
                            {(activeListingHere && activeListingHere.length) ? (
                            <Link to={`/listings/${activeListingHere && activeListingHere.length && activeListingHere[0] && activeListingHere[0]._id}`} selected={activeListingHere && activeListingHere.length && activeListingHere[0] && activeListingHere[0]} listings={listings} style={{ textDecoration: 'none', color: 'black' }}>
                                <Fab style={{ border: `${(activeListingHere && activeListingHere.length) ? 'rgb(52, 181, 122)' : 'gray'}`, backgroundColor: 'white', color: `${(activeListingHere && activeListingHere.length) ? 'rgb(52, 181, 122)' : 'gray'}`, fontSize: 12, padding: 10, marginLeft: 5 }} variant="extended" size="small">
                                    <div style={{ width: 15, height: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: `${(activeListingHere && activeListingHere.length) ? 'rgb(52, 181, 122)' : 'gray'}`, borderRadius: '50%', marginRight: 5 }}></div>
                                    {(activeListingHere && activeListingHere.length) ? 'View This Listing' : 'Not Available'}
                                </Fab>
                            </Link>) : (
                            <Fab style={{ border: `${(activeListingHere && activeListingHere.length) ? 'rgb(52, 181, 122)' : 'gray'}`, backgroundColor: 'white', color: `${(activeListingHere && activeListingHere.length) ? 'rgb(52, 181, 122)' : 'gray'}`, fontSize: 12, padding: 10, marginLeft: 5, outline: 'none' }} variant="extended" size="small">
                                <div style={{ width: 15, height: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: `${(activeListingHere && activeListingHere.length) ? 'rgb(52, 181, 122)' : 'gray'}`, borderRadius: '50%', marginRight: 5 }}></div>
                                {(activeListingHere && activeListingHere.length) ? 'View This Listing' : 'Not Available'}
                            </Fab>)}
                        </div>
                        <div>
                            <Fab onClick={() => this.setState({ nearbyListingsModal: true })} style={{ backgroundColor: 'rgb(52, 181, 122)', color: 'white', fontSize: 12, padding: 10, marginLeft: 5 }} variant="extended" size="small">
                                <FaMapMarkerAlt style={{ marginRight: 5 }} />
                                Nearby Listings
                            </Fab>
                        </div>
                    </div>
                    <Modal
                        open={this.state.nearbyListingsModal === true}
                        onClose={() => this.setState({ nearbyListingsModal: false })}
                        onBackdropClick={() => this.setState({ nearbyListingsModal: false })}
                        keepMounted
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                    >
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 450, height: 500, backgroundColor: 'whitesmoke', border: '1px solid rgb(52, 181, 122)', boxShadow: 24, p: 4, overflowY: 'scroll', paddingBottom: 40 }}>
                            <div style={{ paddingLeft: 15, paddingTop: 15 }}><h5>Active Listings Nearby</h5></div>
                            {nearbyListings && nearbyListings.length ? nearbyListings.sort(function(a, b){return a.dist-b.dist}).map(listing => 
                                (<Link to={`/listings/${listing.listingData._id}`} selected={listing.listingData} listings={listings} style={{ textDecoration: 'none', color: 'black' }}>
                                    <div style={{ border: '1px solid lightgray', borderRadius: 5, backgroundColor: 'white', height: 80, width: '75%', marginLeft: 'auto', marginRight: 'auto', marginTop: 20, padding: 2.5, boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1.5px 5px 0 rgba(0, 0, 0, 0.19)' }}>
                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', height: '50%' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div className='nearbyListingModalScore'>
                                                    {(listing && listing.listingData && listing.listingData.scores && listing.listingData.scores.fourcastScore && listing.listingData.scores.fourcastScore) ? listing.listingData.scores.fourcastScore : 'N/A'}
                                                </div>
                                            </div>
                                            <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingRight: 2 }}>
                                                <div style={{ color: 'dodgerblue' }}>
                                                    {listing && listing.dist && `${(listing.dist * 3.2808).toFixed(0)} ft away`}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ fontWeight: 'bold', color: 'black', display: 'flex', alignItems: 'end', height: '50%', paddingLeft: 2, overflow: 'hidden' }}>
                                            {listing && listing.listingData && listing.listingData.listingAddress && listing.listingData.listingAddress.slice(0, -21)}
                                        </div>
                                    </div>
                                </Link>)                                
                            ) : null}
                        </div>
                    </Modal>
                </div>
                <hr style={{ marginTop: 50 }} />
                <div style={{ width: '100%', height: '90%', display: 'grid', gridTemplateColumns: '3fr 2fr', gap: 10, paddingTop: 25 }}>
                    <div style={{ width: '95%', height: '100%' }}>
                        <div style={{ height: 20, fontSize: 11 }}>Warning: Street View may not be pointing directly at the space listed.</div>
                        <div style={{ marginTop: 10, display: 'flex', justifyContent: 'center' }}>
                            <LoadScript
                                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                            >
                                <GoogleMap
                                    id="property-page-streetview"
                                    mapContainerStyle={mapContainerStyle}
                                    zoom={8}
                                    center={center}
                                >
                                <StreetViewPanorama
                                    position={center}
                                    visible={true}
                                />
                                </GoogleMap>
                            </LoadScript>
                        </div>
                    </div>
                    <div style={{ width: '100%', height: '100%', margin: 'auto' }}>
                        <h5 style={{ margin: '10px 0px 10px 0px', paddingBottom: 10 }}>Estimated Scores:</h5>
                        <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto auto' }}>
                            <Card style={{ padding: '15px 35px', width: 200, height: 100 }}>
                                <Row style={{ height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <p className='summaryScoreTitle'>Fourcast Score</p>
                                </Row>
                                <Row style={{ height: 35, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h1 className='summaryCatScore'>{thisEstimatedFourcastScore}</h1>
                                </Row>
                            </Card>
                        </div>
                        <div style={{ width: '100%', margin: 'auto', display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                            <div style={{ height: 250 }}>
                                <div>
                                    <Card style={{ padding: '15px 30px', width: 175, height: 90, margin: '15px auto' }}>
                                        <Row style={{ height: 25, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <p className='summaryScoreTitle'>Traffic Score</p>
                                        </Row>
                                        <Row style={{ height: 35, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <h3 className='summaryCatScore'>{thisEstimatedTrafficScore}</h3>
                                        </Row>
                                    </Card>
                                </div>
                                <div>
                                    <Card style={{ padding: '15px 30px', width: 175, height: 90, margin: '15px auto' }}>
                                        <Row style={{ height: 25, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <p className='summaryScoreTitle'>Demographics Score</p>
                                        </Row>
                                        <Row style={{ height: 35, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <h3 className='summaryCatScore'>{thisEstimatedDemoScore}</h3>
                                        </Row>
                                    </Card>
                                </div>
                            </div>
                            <div style={{ height: 250 }}>
                                <div>
                                    <Card style={{ padding: '15px 30px', width: 175, height: 90, margin: '15px auto' }}>
                                        <Row style={{ height: 25, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <p className='summaryScoreTitle'>Competition Score</p>
                                        </Row>
                                        <Row style={{ height: 35, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <h3 className='summaryCatScore'>{thisEstimatedCompScore}</h3>
                                        </Row>
                                    </Card>
                                </div>
                                <div>
                                    <Card style={{ padding: '15px 30px', width: 175, height: 90, margin: '15px auto' }}>
                                        <Row style={{ height: 25, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <p className='summaryScoreTitle'>Property Score</p>
                                        </Row>
                                        <Row style={{ height: 35, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <h3 className='summaryCatScore'>{thisEstimatedPropScore}</h3>
                                        </Row>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style={{ marginTop: 100 }} />
                <div style={{ width: '95%', height: '100%', marginLeft: 10, paddingTop: 50 }}>
                    <h5 style={{ margin: '10px 0px 10px 0px', paddingBottom: 10 }}>This area at a glance:</h5>
                    {notableCharacteristics.length ? notableCharacteristics.map(tag => (
                        tag.tagDescription !== undefined ? <span className='summaryDescription'>{`${tag.tagDescription} `}</span> : null
                    )) : (<p className='summaryDescription'>Tags represent certain characteristics that can be found in this area/property. For example, a "Busy Nightlife" tag indicates that the nightlife in the area highlights a big part of the area's overall dynamic and activity.</p>)}
                    <h6 style={{ margin: '30px 0px 10px 0px', paddingTop: 25 }}>Traffic:</h6>
                    <div className='trafficSummaryTagsArea'>
                        {trafficFilterTags.length ? trafficFilterTags.map(tag => (
                            <span><Fab
                            variant="extended"
                            size="small"
                            style={{ backgroundColor: 'rgb(52, 181, 122)', fontSize: 12, color: 'whitesmoke', marginLeft: 10, marginBottom: 10, outline: 'none', padding: 15 }}
                            >
                            {tag.tagName}
                            </Fab></span>
                        )) : (<p className='summaryDescription'>None</p>)}
                    </div>
                    <h6 style={{ margin: '10px 0px 10px 0px' }}>Demographics:</h6>
                    <div className='demographicsSummaryTagsArea'>
                        {demoFilterTags.length ? demoFilterTags.map(tag => (
                            <span><Fab
                            variant="extended"
                            size="small"
                            style={{ backgroundColor: 'rgb(52, 181, 122)', fontSize: 12, color: 'whitesmoke', marginLeft: 10, marginBottom: 10, outline: 'none', padding: 15 }}
                            >
                            {tag.tagName}
                            </Fab></span>
                        )) : (<p className='summaryDescription'>None</p>)}
                    </div>
                    <h6 style={{ margin: '10px 0px 10px 0px' }}>Competition:</h6>
                    <div className='competitionSummaryTagsArea'>
                        {compFilterTags.length ? compFilterTags.map(tag => (
                            <span><Fab
                            variant="extended"
                            size="small"
                            style={{ backgroundColor: 'rgb(52, 181, 122)', fontSize: 12, color: 'whitesmoke', marginLeft: 10, marginBottom: 10, outline: 'none', padding: 15 }}
                            >
                            {tag.tagName}
                            </Fab></span>
                        )) : (<p className='summaryDescription'>None</p>)}
                    </div>
                </div>
                <hr style={{ marginTop: 50 }} />
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', paddingTop: 50 }}>
                    <div style={{ width: '90%', height: '100%', position: 'relative' }}>
                        <h5 style={{ margin: '10px 0px 10px 0px', paddingBottom: 10 }}>Area of analysis:</h5>
                        <div className="staticMapSummary">
                            <img alt='static google map on summary page' src={`https://maps.googleapis.com/maps/api/staticmap?center=${thisSearchedAddress.lat},${thisSearchedAddress.lng}&zoom=15&scale=1&size=500x500&maptype=terrain&markers=color:eb2741cb%7C${thisSearchedAddress.lat},${thisSearchedAddress.lng}&markers=color:purple%7Clabel:1%7C${poi1Lat},${poi1Lon}&markers=color:purple%7Clabel:2%7C${poi2Lat},${poi2Lon}&markers=color:purple%7Clabel:3%7C${poi3Lat},${poi3Lon}&style=feature:poi.business|visibility:off&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`} />
                        </div>
                    </div>
                    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                        <div>
                            <h5 style={{ margin: '10px 0px 10px 0px', paddingBottom: 10 }}>Top Places Nearby:</h5>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                            <Card style={{ padding: 10, width: 700, height: 110 }}>
                                <div style={{ height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
                                    <div>
                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr' }}>
                                            <div className='searchedPoiCircle'>1</div>
                                            <div>
                                                <img style={{ height: 25, width: 25 }} src={topNearbyPOIs && topNearbyPOIs[0] && topNearbyPOIs[0].icon && topNearbyPOIs[0].icon} alt='POI 1 Icon' />
                                            </div>
                                        </div>
                                        <div className="searchedPoiName">
                                            {topNearbyPOIs && topNearbyPOIs[0] && topNearbyPOIs[0].name && topNearbyPOIs[0].name}
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr' }}>
                                            <div className='searchedPoiCircle'>2</div>
                                            <div>
                                                <img style={{ height: 25, width: 25 }} src={topNearbyPOIs && topNearbyPOIs[1] && topNearbyPOIs[1].icon && topNearbyPOIs[1].icon} alt='POI 2 Icon' />
                                            </div>
                                        </div>
                                        <div className="searchedPoiName">
                                            {topNearbyPOIs && topNearbyPOIs[1] && topNearbyPOIs[1].name && topNearbyPOIs[1].name}
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr' }}>
                                            <div className='searchedPoiCircle'>3</div>
                                            <div>
                                                <img style={{ height: 25, width: 25 }} src={topNearbyPOIs && topNearbyPOIs[2] && topNearbyPOIs[2].icon && topNearbyPOIs[2].icon} alt='POI 3 Icon' />
                                            </div>
                                        </div>
                                        <div className="searchedPoiName">
                                            {topNearbyPOIs && topNearbyPOIs[2] && topNearbyPOIs[2].name && topNearbyPOIs[2].name}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div style={{ paddingTop: 20 }}>
                            <h5 style={{ margin: '10px 0px 10px 0px', paddingBottom: 5 }}>Top Businesses Nearby:</h5>
                        </div>
                        <div>
                            <Card style={{ padding: 5, width: 700, height: 325 }}>
                                <Table>
                                    <thead style={{ backgroundColor: 'whitesmoke' }}>
                                        <tr>
                                            <th>Name</th>
                                            <th>Rating</th>
                                            <th>Reviews</th>
                                            <th>Price</th>
                                            <th>Category</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {topTenBusinesses && topTenBusinesses.length && topTenBusinesses.map((business) => (
                                        <tr style={{ height: 30 }}>
                                            <th style={{ fontSize: 14 }}>
                                            {business && business.name}
                                            </th>
                                            <td>
                                                <YelpStarRating business={business} />
                                            </td>
                                            <td style={{ fontSize: 14 }}>
                                                {business && business.review_count}
                                            </td>
                                            <td style={{ fontSize: 14 }}>
                                                {business && business.price}
                                            </td>
                                            <td style={{ fontSize: 14 }}>
                                            {business && business.categories[0].title}
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card>
                        </div>
                    </div>
                </div>
                <hr style={{ marginTop: 100 }} />
                <div style={{ paddingTop: 50 }}>
                    {(((selectedNeighborhood && !selectedNeighborhood.length) || (selectedNeighborhood === null)) && (this.state.changeNeighborhood === true)) ? (
                    <div style={{ height: 300 }}>
                        <div style={{ width: '50%', height: '25%', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <h5>Demographics:</h5>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <h3>{`${(selectedNeighborhood && selectedNeighborhood.length && selectedNeighborhood[0] && selectedNeighborhood[0].label) ? selectedNeighborhood[0].label : initialNeighborhoodName}`}</h3>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'left',  alignItems: 'center' }}>
                                <div>
                                    <Fab onClick={() => {
                                        const initialNeighb = neighbList && neighbList.length && neighbList[0] && neighbList[0].label;
                                        const findNeighb = neighbList && neighbList.length && neighbList.filter(neighb => neighb.label === initialNeighb);
                                        const thisNeighb = findNeighb && findNeighb.length && findNeighb[0] && findNeighb[0];
                                        this.setState({ selectedNeighborhood: [thisNeighb], changeNeighborhood: false });
                                    }} style={{ backgroundColor: 'rgb(52, 181, 122)', fontSize: 11, letterSpacing: 1, fontWeight: 'bolder', padding: 20 }} variant="extended" size="small">
                                        <ImCheckmark style={{ color: 'white' }} />
                                    </Fab>
                                </div>
                            </div>
                        </div>
                        <div style={{ paddingTop: 20, width: '50%', height: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>OR</div>
                        <div style={{ height: '50%', width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr', width: '100%' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div>Select a different area:</div>
                                </div>
                                <div>
                                    <Select 
                                        options={neighbOptions}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            width: '95%',
                                            margin: 'auto',
                                            minHeight: 75,
                                            maxHeight: 100,
                                            overflowY: 'scroll',
                                            border: (selectedNeighborhood && !selectedNeighborhood.length) ? '2px solid darkred' : null
                                            }),
                                        }}
                                        formatGroupLabel={formatGroupLabel}
                                        isMulti={true}
                                        placeholder="Select an area..."
                                        onChange={(values) => {      
                                            this.setState({ selectedNeighborhood: values, changeNeighborhood: false });
                                        }}
                                        defaultValue={selectedNeighborhood}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    ) : (
                    <div>
                        <div style={{ width: '75%', display: 'grid', gridTemplateColumns: '1fr 2fr 5fr' }}>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <h5>Demographics:</h5>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h3>{(selectedNeighborhood && selectedNeighborhood.length && selectedNeighborhood[0] && selectedNeighborhood[0].label) ? selectedNeighborhood[0].label : initialNeighborhoodName}</h3>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'start' }}>
                                {(finalNearbyNeighborhoods && (finalNearbyNeighborhoods.length > 1)) ? finalNearbyNeighborhoods.map(thisNeighb =>
                                    <Fab onClick={() => {
                                        this.setState({ selectedNeighborhood: [thisNeighb], changeNeighborhood: false });
                                    }} style={{ backgroundColor: `${attomDataToUse && attomDataToUse.name && attomDataToUse.name.includes(thisNeighb && thisNeighb.label) ? 'rgb(52, 181, 122)' : 'lightgray'}`, color: `${attomDataToUse && attomDataToUse.name && attomDataToUse.name.includes(thisNeighb && thisNeighb.label) ? 'white' : 'black'}`, fontSize: 10, padding: 10, marginLeft: 5 }} variant="extended" size="small">
                                        {thisNeighb && thisNeighb.label}
                                    </Fab>
                                ) : null}
                                <Fab onClick={() => {this.setState({ changeNeighborhood: true, selectedNeighborhood: [] })}} style={{ backgroundColor: 'lightblue', fontSize: 10, padding: 15, marginLeft: 5 }} variant="extended" size="small">
                                    More Options
                                </Fab>
                            </div>
                        </div>
                        {((nearbyListingToBeUsedForAttomData !== null) || (initialAttomData !== null)) ? <div style={{ marginTop: 50 }}><SearchAddressDemographics scores={scoresToUse} attomData={attomDataToUse} filterables={filterablesToUse} /></div> : <div style={{ marginTop: 50 }}><SearchAddressDemographics scores={null} attomData={attomDataToUse} filterables={null} /></div>}
                    </div>
                    )}
                </div>
            </Container>) : (<Container style={{ paddingTop: 50, height: '100%', width: screenWidth, margin: 'auto', paddingBottom: 200, position: 'relative', overflowY: 'scroll' }}>
                <div style={{ width: '100%', height: '10%' }}>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                        <h2 style={{ width: '80%' }}>{thisSearchedAddress && thisSearchedAddress.address}</h2>
                        <div style={{ width: 15, height: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: `${(activeListingHere && activeListingHere.length) ? 'rgb(52, 181, 122)' : 'gray'}`, borderRadius: '50%', marginLeft: 10, marginBottom: 5 }}></div>
                    </div>
                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginTop: 20 }}>
                        <div>
                            {(activeListingHere && activeListingHere.length) ? (
                            <Link to={`/listings/${activeListingHere && activeListingHere.length && activeListingHere[0] && activeListingHere[0]._id}`} selected={activeListingHere && activeListingHere.length && activeListingHere[0] && activeListingHere[0]} listings={listings} style={{ textDecoration: 'none', color: 'black' }}>
                                <Fab style={{ border: `${(activeListingHere && activeListingHere.length) ? 'rgb(52, 181, 122)' : 'gray'}`, backgroundColor: 'white', color: `${(activeListingHere && activeListingHere.length) ? 'rgb(52, 181, 122)' : 'gray'}`, fontSize: 12, padding: 10, marginLeft: 5 }} variant="extended" size="small">
                                    <div style={{ width: 15, height: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: `${(activeListingHere && activeListingHere.length) ? 'rgb(52, 181, 122)' : 'gray'}`, borderRadius: '50%', marginRight: 5 }}></div>
                                    {(activeListingHere && activeListingHere.length) ? 'View This Listing' : 'Not Available'}
                                </Fab>
                            </Link>) : (
                            <Fab style={{ border: `${(activeListingHere && activeListingHere.length) ? 'rgb(52, 181, 122)' : 'gray'}`, backgroundColor: 'white', color: `${(activeListingHere && activeListingHere.length) ? 'rgb(52, 181, 122)' : 'gray'}`, fontSize: 12, padding: 10, marginLeft: 5, outline: 'none' }} variant="extended" size="small">
                                <div style={{ width: 15, height: 15, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: `${(activeListingHere && activeListingHere.length) ? 'rgb(52, 181, 122)' : 'gray'}`, borderRadius: '50%', marginRight: 5 }}></div>
                                {(activeListingHere && activeListingHere.length) ? 'View This Listing' : 'Not Available'}
                            </Fab>)}
                        </div>
                        <div>
                            <Fab onClick={() => this.setState({ nearbyListingsModal: true })} style={{ backgroundColor: 'rgb(52, 181, 122)', color: 'white', fontSize: 12, padding: 10, marginLeft: 5 }} variant="extended" size="small">
                                <FaMapMarkerAlt style={{ marginRight: 5 }} />
                                Nearby Listings
                            </Fab>
                        </div>
                    </div>
                    <Modal
                        open={this.state.nearbyListingsModal === true}
                        onClose={() => this.setState({ nearbyListingsModal: false })}
                        onBackdropClick={() => this.setState({ nearbyListingsModal: false })}
                        keepMounted
                        aria-labelledby="keep-mounted-modal-title"
                        aria-describedby="keep-mounted-modal-description"
                    >
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 350, height: 400, backgroundColor: 'whitesmoke', border: '1px solid rgb(52, 181, 122)', boxShadow: 24, p: 4, overflowY: 'scroll', paddingBottom: 40 }}>
                            <div style={{ paddingLeft: 15, paddingTop: 15 }}><h5>Active Listings Nearby</h5></div>
                            {nearbyListings && nearbyListings.length ? nearbyListings.sort(function(a, b){return a.dist-b.dist}).map(listing => 
                                (<Link to={`/listings/${listing.listingData._id}`} selected={listing.listingData} listings={listings} style={{ textDecoration: 'none', color: 'black' }}>
                                    <div style={{ border: '1px solid lightgray', borderRadius: 5, backgroundColor: 'white', height: 80, width: '75%', marginLeft: 'auto', marginRight: 'auto', marginTop: 20, padding: 2.5, boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 1.5px 5px 0 rgba(0, 0, 0, 0.19)' }}>
                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', height: '50%' }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <div className='nearbyListingModalScore'>
                                                    {(listing && listing.listingData && listing.listingData.scores && listing.listingData.scores.fourcastScore && listing.listingData.scores.fourcastScore) ? listing.listingData.scores.fourcastScore : 'N/A'}
                                                </div>
                                            </div>
                                            <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingRight: 2 }}>
                                                <div style={{ color: 'dodgerblue' }}>
                                                    {listing && listing.dist && `${(listing.dist * 3.2808).toFixed(0)} ft away`}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ fontWeight: 'bold', color: 'black', display: 'flex', alignItems: 'end', height: '50%', paddingLeft: 2, overflow: 'hidden' }}>
                                            {listing && listing.listingData && listing.listingData.listingAddress && listing.listingData.listingAddress.slice(0, -21)}
                                        </div>
                                    </div>
                                </Link>)                                
                            ) : null}
                        </div>
                    </Modal>
                </div>
                <hr style={{ marginTop: 50 }} />
                <div style={{ width: '100%', height: '90%', paddingTop: 25, position: 'relative' }}>
                    <div style={{ width: '95%', height: '100%' }}>
                        <div style={{ height: 20, fontSize: 11 }}>Warning: Street View may not be pointing directly at the space listed.</div>
                        <div style={{ marginTop: 10, display: 'flex', justifyContent: 'center', overflow: 'scroll' }}>
                            <LoadScript
                                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                            >
                                <GoogleMap
                                    id="property-page-streetview"
                                    mapContainerStyle={mapContainerStyle}
                                    zoom={8}
                                    center={center}
                                >
                                <StreetViewPanorama
                                    position={center}
                                    visible={true}
                                />
                                </GoogleMap>
                            </LoadScript>
                        </div>
                    </div>
                    <div style={{ width: '100%', height: '100%', margin: 'auto' }}>
                        <h5 style={{ margin: '10px 0px 10px 0px', paddingBottom: 10 }}>Estimated Scores:</h5>
                        <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto auto' }}>
                            <Card style={{ padding: '15px 35px', width: 200, height: 100 }}>
                                <Row style={{ height: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <p className='summaryScoreTitle'>Fourcast Score</p>
                                </Row>
                                <Row style={{ height: 35, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h1 className='summaryCatScore'>{thisEstimatedFourcastScore}</h1>
                                </Row>
                            </Card>
                        </div>
                        <div style={{ width: '100%', margin: 'auto', display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                            <div style={{ height: 250 }}>
                                <div>
                                    <Card style={{ padding: '15px 30px', width: 175, height: 90, margin: '15px auto' }}>
                                        <Row style={{ height: 25, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <p className='summaryScoreTitle'>Traffic Score</p>
                                        </Row>
                                        <Row style={{ height: 35, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <h3 className='summaryCatScore'>{thisEstimatedTrafficScore}</h3>
                                        </Row>
                                    </Card>
                                </div>
                                <div>
                                    <Card style={{ padding: '15px 30px', width: 175, height: 90, margin: '15px auto' }}>
                                        <Row style={{ height: 25, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <p className='summaryScoreTitle'>Demographics Score</p>
                                        </Row>
                                        <Row style={{ height: 35, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <h3 className='summaryCatScore'>{thisEstimatedDemoScore}</h3>
                                        </Row>
                                    </Card>
                                </div>
                            </div>
                            <div style={{ height: 250 }}>
                                <div>
                                    <Card style={{ padding: '15px 30px', width: 175, height: 90, margin: '15px auto' }}>
                                        <Row style={{ height: 25, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <p className='summaryScoreTitle'>Competition Score</p>
                                        </Row>
                                        <Row style={{ height: 35, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <h3 className='summaryCatScore'>{thisEstimatedCompScore}</h3>
                                        </Row>
                                    </Card>
                                </div>
                                <div>
                                    <Card style={{ padding: '15px 30px', width: 175, height: 90, margin: '15px auto' }}>
                                        <Row style={{ height: 25, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <p className='summaryScoreTitle'>Property Score</p>
                                        </Row>
                                        <Row style={{ height: 35, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                            <h3 className='summaryCatScore'>{thisEstimatedPropScore}</h3>
                                        </Row>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style={{ marginTop: 100 }} />
                <div style={{ width: '95%', height: '100%', marginLeft: 10, paddingTop: 50 }}>
                    <h5 style={{ margin: '10px 0px 10px 0px', paddingBottom: 10 }}>This area at a glance:</h5>
                    {notableCharacteristics.length ? notableCharacteristics.map(tag => (
                        tag.tagDescription !== undefined ? <span className='summaryDescription'>{`${tag.tagDescription} `}</span> : null
                    )) : (<p className='summaryDescription'>Tags represent certain characteristics that can be found in this area/property. For example, a "Busy Nightlife" tag indicates that the nightlife in the area highlights a big part of the area's overall dynamic and activity.</p>)}
                    <h6 style={{ margin: '30px 0px 10px 0px', paddingTop: 25 }}>Traffic:</h6>
                    <div className='trafficSummaryTagsArea'>
                        {trafficFilterTags.length ? trafficFilterTags.map(tag => (
                            <span><Fab
                            variant="extended"
                            size="small"
                            style={{ backgroundColor: 'rgb(52, 181, 122)', fontSize: 12, color: 'whitesmoke', marginLeft: 10, marginBottom: 10, outline: 'none', padding: 15 }}
                            >
                            {tag.tagName}
                            </Fab></span>
                        )) : (<p className='summaryDescription'>None</p>)}
                    </div>
                    <h6 style={{ margin: '10px 0px 10px 0px' }}>Demographics:</h6>
                    <div className='demographicsSummaryTagsArea'>
                        {demoFilterTags.length ? demoFilterTags.map(tag => (
                            <span><Fab
                            variant="extended"
                            size="small"
                            style={{ backgroundColor: 'rgb(52, 181, 122)', fontSize: 12, color: 'whitesmoke', marginLeft: 10, marginBottom: 10, outline: 'none', padding: 15 }}
                            >
                            {tag.tagName}
                            </Fab></span>
                        )) : (<p className='summaryDescription'>None</p>)}
                    </div>
                    <h6 style={{ margin: '10px 0px 10px 0px' }}>Competition:</h6>
                    <div className='competitionSummaryTagsArea'>
                        {compFilterTags.length ? compFilterTags.map(tag => (
                            <span><Fab
                            variant="extended"
                            size="small"
                            style={{ backgroundColor: 'rgb(52, 181, 122)', fontSize: 12, color: 'whitesmoke', marginLeft: 10, marginBottom: 10, outline: 'none', padding: 15 }}
                            >
                            {tag.tagName}
                            </Fab></span>
                        )) : (<p className='summaryDescription'>None</p>)}
                    </div>
                </div>
                <hr style={{ marginTop: 50 }} />
                <div style={{ paddingTop: 50, width: '100%' }}>
                    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                        <h5 style={{ margin: '10px 0px 10px 0px', paddingBottom: 10 }}>Area of analysis:</h5>
                        <div className="staticMapSummary">
                            <img alt='static google map on summary page' src={`https://maps.googleapis.com/maps/api/staticmap?center=${thisSearchedAddress.lat},${thisSearchedAddress.lng}&zoom=15&scale=1&size=${Math.floor(screenWidth - 25)}x${Math.floor(screenWidth - 25)}&maptype=terrain&markers=color:eb2741cb%7C${thisSearchedAddress.lat},${thisSearchedAddress.lng}&markers=color:purple%7Clabel:1%7C${poi1Lat},${poi1Lon}&markers=color:purple%7Clabel:2%7C${poi2Lat},${poi2Lon}&markers=color:purple%7Clabel:3%7C${poi3Lat},${poi3Lon}&style=feature:poi.business|visibility:off&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`} />
                        </div>
                    </div>
                    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                        <div>
                            <h5 style={{ margin: '10px 0px 10px 0px', paddingBottom: 10 }}>Top Places Nearby:</h5>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                            <Card style={{ padding: 10, width: '100%', height: 110 }}>
                                <div style={{ height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr' }}>
                                    <div>
                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr' }}>
                                            <div className='searchedPoiCircle'>1</div>
                                            <div>
                                                <img style={{ height: 25, width: 25, marginLeft: 5 }} src={topNearbyPOIs && topNearbyPOIs[0] && topNearbyPOIs[0].icon && topNearbyPOIs[0].icon} alt='POI 1 Icon' />
                                            </div>
                                        </div>
                                        <div className="searchedPoiName">
                                            {topNearbyPOIs && topNearbyPOIs[0] && topNearbyPOIs[0].name && topNearbyPOIs[0].name}
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr' }}>
                                            <div className='searchedPoiCircle'>2</div>
                                            <div>
                                                <img style={{ height: 25, width: 25, marginLeft: 5 }} src={topNearbyPOIs && topNearbyPOIs[1] && topNearbyPOIs[1].icon && topNearbyPOIs[1].icon} alt='POI 2 Icon' />
                                            </div>
                                        </div>
                                        <div className="searchedPoiName">
                                            {topNearbyPOIs && topNearbyPOIs[1] && topNearbyPOIs[1].name && topNearbyPOIs[1].name}
                                        </div>
                                    </div>
                                    <div>
                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr' }}>
                                            <div className='searchedPoiCircle'>3</div>
                                            <div>
                                                <img style={{ height: 25, width: 25, marginLeft: 5 }} src={topNearbyPOIs && topNearbyPOIs[2] && topNearbyPOIs[2].icon && topNearbyPOIs[2].icon} alt='POI 3 Icon' />
                                            </div>
                                        </div>
                                        <div className="searchedPoiName">
                                            {topNearbyPOIs && topNearbyPOIs[2] && topNearbyPOIs[2].name && topNearbyPOIs[2].name}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </div>
                        <div style={{ paddingTop: 20 }}>
                            <h5 style={{ margin: '10px 0px 10px 0px', paddingBottom: 5 }}>Top Businesses Nearby:</h5>
                        </div>
                        <div style={{ width: '100%' }}>
                            <Card style={{ padding: 5, width: '100%' }}>
                                <Table>
                                    <thead style={{ backgroundColor: 'whitesmoke' }}>
                                        <tr>
                                            <th>Name</th>
                                            <th>Rating</th>
                                            <th>Reviews</th>
                                            <th>Price</th>
                                            <th>Category</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {topTenBusinesses && topTenBusinesses.length && topTenBusinesses.map((business) => (
                                        <tr style={{ height: 30, textAlign: 'center', borderBottom: '1px solid lightgray' }}>
                                            <th style={{ fontSize: 12, textAlign: 'left' }}>
                                            {business && business.name}
                                            </th>
                                            <td>
                                                <YelpStarRating business={business} />
                                            </td>
                                            <td style={{ fontSize: 12 }}>
                                                {business && business.review_count}
                                            </td>
                                            <td style={{ fontSize: 12 }}>
                                                {business && business.price}
                                            </td>
                                            <td style={{ fontSize: 12 }}>
                                            {business && business.categories[0].title}
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card>
                        </div>
                    </div>
                </div>
                <hr style={{ marginTop: 100 }} />
                <div style={{ paddingTop: 50, width: '100%' }}>
                    {(((selectedNeighborhood && !selectedNeighborhood.length) || (selectedNeighborhood === null)) && (this.state.changeNeighborhood === true)) ? (
                    <div style={{ height: 300 }}>
                        <div style={{ width: '100%', height: '25%' }}>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <h5>Demographics:</h5>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <h3>{`${(selectedNeighborhood && selectedNeighborhood.length && selectedNeighborhood[0] && selectedNeighborhood[0].label) ? selectedNeighborhood[0].label : initialNeighborhoodName}`}</h3>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'left',  alignItems: 'center' }}>
                                <div>
                                    <Fab onClick={() => {
                                        const initialNeighb = neighbList && neighbList.length && neighbList[0] && neighbList[0].label;
                                        const findNeighb = neighbList && neighbList.length && neighbList.filter(neighb => neighb.label === initialNeighb);
                                        const thisNeighb = findNeighb && findNeighb.length && findNeighb[0] && findNeighb[0];
                                        this.setState({ selectedNeighborhood: [thisNeighb], changeNeighborhood: false });
                                    }} style={{ backgroundColor: 'rgb(52, 181, 122)', fontSize: 11, letterSpacing: 1, fontWeight: 'bolder', padding: 20 }} variant="extended" size="small">
                                        <ImCheckmark style={{ color: 'white' }} />
                                    </Fab>
                                </div>
                            </div>
                        </div>
                        <div style={{ paddingTop: 20, width: '100%', height: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>OR</div>
                        <div style={{ height: '50%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr', width: '100%' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div>Select a different area:</div>
                                </div>
                                <div>
                                    <Select 
                                        options={neighbOptions}
                                        styles={{
                                            control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            width: '95%',
                                            margin: 'auto',
                                            minHeight: 75,
                                            maxHeight: 100,
                                            overflowY: 'scroll',
                                            border: (selectedNeighborhood && !selectedNeighborhood.length) ? '2px solid darkred' : null
                                            }),
                                        }}
                                        formatGroupLabel={formatGroupLabel}
                                        isMulti={true}
                                        placeholder="Select an area..."
                                        onChange={(values) => {    
                                            this.setState({ selectedNeighborhood: values, changeNeighborhood: false });
                                        }}
                                        defaultValue={selectedNeighborhood}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    ) : (
                    <div style={{ width: '100%' }}>
                        <div style={{ width: '100%' }}>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', paddingLeft: 5, height: 50 }}>
                                <h5>Demographics:</h5>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 50 }}>
                                <h3>{(selectedNeighborhood && selectedNeighborhood.length && selectedNeighborhood[0] && selectedNeighborhood[0].label) ? selectedNeighborhood[0].label : initialNeighborhoodName}</h3>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', height: 50 }}>
                                {(finalNearbyNeighborhoods && (finalNearbyNeighborhoods.length > 1)) ? finalNearbyNeighborhoods.map(thisNeighb =>
                                    <Fab onClick={() => {
                                        this.setState({ selectedNeighborhood: [thisNeighb], changeNeighborhood: false });
                                    }} style={{ backgroundColor: `${attomDataToUse && attomDataToUse.name && attomDataToUse.name.includes(thisNeighb && thisNeighb.label) ? 'rgb(52, 181, 122)' : 'lightgray'}`, color: `${attomDataToUse && attomDataToUse.name && attomDataToUse.name.includes(thisNeighb && thisNeighb.label) ? 'white' : 'black'}`, fontSize: 10, padding: 10, marginLeft: 5 }} variant="extended" size="small">
                                        {thisNeighb && thisNeighb.label}
                                    </Fab>
                                ) : null}
                                <Fab onClick={() => {this.setState({ changeNeighborhood: true, selectedNeighborhood: [] })}} style={{ backgroundColor: 'lightblue', fontSize: 10, padding: 15, marginLeft: 5 }} variant="extended" size="small">
                                    More Options
                                </Fab>
                            </div>
                        </div>
                        {((nearbyListingToBeUsedForAttomData !== null) || (initialAttomData !== null)) ? <div style={{ marginTop: 50 }}><SearchAddressDemographics scores={scoresToUse} attomData={attomDataToUse} filterables={filterablesToUse} screenWidth={((screenWidth === null ) || (screenWidth > 1000)) ? null : screenWidth} /></div> : <div style={{ marginTop: 50 }}><SearchAddressDemographics scores={null} attomData={attomDataToUse} filterables={null} screenWidth={((screenWidth === null ) || (screenWidth > 1000)) ? null : screenWidth} /></div>}
                    </div>
                    )}
                </div>
            </Container>) : <LinearProgress />}
            </div>
        );
    }
}

SearchedAddressSummary.propTypes = {
    getListings: PropTypes.func.isRequired,
    listings: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
    listings: state.listing.listings,
});

export default connect(mapStateToProps, { getListings })(SearchedAddressSummary);

