import React, { Component } from 'react';
import 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import './demographics.css';

class FamilyStatusGraph extends Component {
    constructor(props) {
        super(props);
        this.state = {
            familyGraphData: props.familyGraphData,
        }
    }

    static defaultProps = {
        displayTitle: true,
    }
    
    render() {

        const { thisFamilyData: { familyData: familyGraphData } = {}} = this.props;
        
        return (
            <div className="familyStatusGraph">
                <Bar
                    data={familyGraphData}
                    height={200}
                    width={280}
                    options={{
                        title: {
                            display: true,
                            text: 'Volume of Families in the Area',
                            fontSize: 14,
                            color: 'black'
                        },
                        plugins: {
                            datalabels: {
                            display: false,
                            color: 'black'
                            }
                        },
                        scales: {
                            x: {
                                ticks: {
                                    beginAtZero: true,
                                },
                                grid: {
                                    display: false
                                },
                                barPercentage: .45
                            },
                            y: {
                                ticks: {
                                    display: false,
                                    beginAtZero: true,
                                },
                                grid: {
                                    display: false,
                                },
                            },
                        },
                    }} 
                />
            </div>
        );
    }
}

export default FamilyStatusGraph;