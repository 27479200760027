import React, { Component } from 'react';
import { Button, Card, CardContent, IconButton, Paper, Divider } from '@mui/material';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import moment from 'moment';
import { TiDeleteOutline, TiPlus } from 'react-icons/ti';
import { MdFileDownload } from 'react-icons/md';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import './compareListings.css';
import NearbyPlacesComparison from './TrafficComparisons/nearbyPlacesComparison';
import BusyHoursComparison from './TrafficComparisons/popularTimesComparison';
import AccessibilityComparison from './TrafficComparisons/accessibilityComparison';
import EngagementComparison from './TrafficComparisons/engagementComparison';
import BusyRoadComparison from './TrafficComparisons/busyRoadComparison';
import CommonPeopleComparison from './DemoComparisons/commonPeopleComparison';
import PopulationComparison from './DemoComparisons/populationComparison';
import ResPricesComparison from './DemoComparisons/resPricesComparison';
import RentOwnComparison from './DemoComparisons/rentOwnComparison';
import IncomeComparison from './DemoComparisons/incomeComparison';
import FamilyComparison from './DemoComparisons/familyComparison';
import TotalBusinessesComparison from './CompetitionComparisons/totalBusinessesComparison';
import PopularBizTable from './CompetitionComparisons/popularBizTable';
import CommonBizComparison from './CompetitionComparisons/commonBizComparison';
import HighEndBizComparison from './CompetitionComparisons/highEndBizComparison';
import SummaryComparison from './summaryComparison';
import ScoreComparison from './scoreComparison';


class CompareListingsContentTest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedListingNum: 0,
            downloaded: [],
            totalGatheredListingNum: 0,
            mobile: false
        }
    }

    

    componentDidMount() {
        const { allListingInfo } = this.props;
        const listingNum = allListingInfo && allListingInfo.length;

        this.setState({ totalGatheredListingNum: listingNum });

        window && window.addEventListener('resize', this.mobileMode.bind(this));
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }

    async componentDidUpdate(prevState, prevProps) {
        
        const { totalGatheredListingNum } = this.state;
        const { allListingInfo } = this.props;
        const listingNum = allListingInfo && allListingInfo.length;

        if (prevProps.allListingInfo !== allListingInfo) {
            if (totalGatheredListingNum !== listingNum) {
                this.setState({ totalGatheredListingNum: listingNum });
            }
        }
        
    }

    componentWillUnmount() {
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
    }

    mobileMode() {
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }

    setDownloaded () {
        const { downloaded, selectedListingNum } = this.state;
        this.setState({ downloaded: [...downloaded, selectedListingNum] });
    }

    onSubmit(listingForDeletion) {
        const { selected } = this.props;

        const restOfTheListings = selected && selected.length && selected.filter(select => select.value !== listingForDeletion._id);
        
        if (selected.length) {
            this.props.setSelected(restOfTheListings);
        }
    }

    
    render() { 
        
        const { downloaded, mobile, selectedListingNum } = this.state;
        const { user, selected, allListingInfo } = this.props;
        
        const reportElems =  ['summaryCompTest', 'scoresCompTest', 'busyRoadCompTest', 'nearbyPlacesAndBusyHoursCompTest', 'accessibilityAndEngagementCompTest', 'commonPeopleCompTest', 'populationCompTest', 'familyCompTest', 'resPricesAndRentOwnCompTest', 'incomeCompTest', 'totalBizCompTest', 'popularBizCompTest', 'popularBizCompTestCont', 'commonBizCompTest', 'highEndCompTest'];
        
        const getCanvasData = element => {
            
            return new Promise(async (resolve, reject) => {
              await html2canvas(element, {logging: true, letterRendering: 1, useCORS: true})
                .then(function(canvas) {
                  resolve(canvas.toDataURL("image/jpeg"));
                })
                .catch(function(error) {
                  reject(
                    "Error while creating canvas for element with ID: " + element.id
                  );
                });
            });
        };
        
        const downloadPdfDocument = async () => {
            
            var pdf = new jsPDF({
              orientation: "p",
              unit: "mm",
              format: "a4",
              compression: true,
            });
        
            let pageIds = reportElems;
            let promises = [];
            
            await pageIds.forEach(page => {
                promises.push(getCanvasData(document.getElementById(page)));
                this.setDownloaded();
            });
        
            Promise.all(promises).then(dataUrls => {
                dataUrls.forEach((dataUrl, i) => {
                    const imgWidth = pdf.internal.pageSize.getWidth() * 2;
                    const imgHeight = pdf.internal.pageSize.getHeight();
                    const imgX = ((pdf.internal.pageSize.getWidth() - imgWidth) / 2);
                    pdf.addImage(dataUrl, "JPEG", imgX+10.20, 0, imgWidth-20, imgHeight, undefined, "FAST");
                    
                    if (i !== reportElems.length - 1) {
                        pdf.addPage();
                    }
                });
                pdf.save(`Fourcast_Comp.pdf`);
            });
        };

        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }
        
        const listings = allListingInfo && allListingInfo;
        
        return (
            <div style={{ paddingBottom: 25, zIndex: 19 }}>
                <div style={{ width: '100%', zIndex: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'whitesmoke', paddingTop: 10, paddingBottom: 10, position: 'sticky', top: 0 }}>
                    {(allListingInfo && allListingInfo.length && (allListingInfo.length === selected.length)) ? 
                    (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 100 }}>
                        <Button className='downloadReportBtn' style={{ backgroundColor: 'rgb(43, 150, 102)', color: 'white', width: 250, height: 75, outline: 'none', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)' }} onClick={downloadPdfDocument} key={selectedListingNum} startIcon={downloaded.includes(selectedListingNum) ? <AiOutlineCheckCircle /> : <MdFileDownload />}>{downloaded.includes(selectedListingNum) ? 'Downloaded' : 'Download Comp'}</Button>
                    </div>) : null}
                    {(allListingInfo && allListingInfo.length && (allListingInfo.length === selected.length)) && allListingInfo.map(selectedListing =>
                    <Card style={{ width: 250, height: 75, marginLeft: 10, marginRight: 10, display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 100 }}>
                        <CardContent>
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%' }}>
                                <div style={{ fontSize: 16 }}>{selectedListing && addressStyle(selectedListing.listingAddress)}</div>
                                <IconButton aria-label="delete" onClick={() => this.onSubmit(selectedListing)}>
                                    <TiDeleteOutline color='darkred' />
                                </IconButton>
                            </div>
                        </CardContent>
                    </Card>)}
                    {(allListingInfo && allListingInfo.length && (allListingInfo.length !== 4)) ? (
                    <Card style={{ width: 100, height: 75, marginLeft: 10, marginRight: 10, zIndex: 100 }}>
                        <Button style={{ width: '100%', height: '100%' }} onClick={() => window && window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <TiPlus color='darkgreen' style={{ height: 25, width: 25 }} />
                            </div>
                        </Button> 
                    </Card>) : null}
                </div>
                <div style={{ backgroundColor: 'whitesmoke', paddingTop: 25, paddingBottom: 25 }}>
                    <div id='summaryCompTest'>
                        <Paper className="trafficCompPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 50, position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                            <h4 style={{ width: '100%', textAlign: 'center' }}>Summary</h4>
                            <div style={{ marginTop: 50, display: 'flex', justifyContent: 'center' }}>
                                <SummaryComparison listings={listings} />
                            </div>
                        </Paper>
                    </div>
                    <div id='scoresCompTest' style={{ marginTop: 25 }}>
                        <Paper className="trafficCompPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 50, position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                            <Divider style={{ marginTop: 25 }} variant="middle">
                                <h4>Scores</h4>
                            </Divider>
                            <div style={{ marginTop: 50, display: 'flex', justifyContent: 'center' }}>
                                <ScoreComparison listings={listings} />
                            </div>
                        </Paper>
                    </div>
                    <div id='busyRoadCompTest' style={{ marginTop: 25 }}>
                        <Paper className="trafficCompPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 50, position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                            <Divider style={{ marginTop: 25 }} variant="middle">
                                <h4>Traffic</h4>
                            </Divider>
                            <div style={{ marginTop: 50, display: 'flex', justifyContent: 'center' }}>
                                <BusyRoadComparison listings={listings} />
                            </div>
                        </Paper>
                    </div>
                    <div id='nearbyPlacesAndBusyHoursCompTest' style={{ marginTop: 25 }}>
                        <Paper className="trafficCompPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                            <div style={{ height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <NearbyPlacesComparison listings={listings} />
                            </div>
                            <div style={{ height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <BusyHoursComparison listings={listings} />
                            </div>
                        </Paper>
                    </div>
                    <div id='accessibilityAndEngagementCompTest' style={{ marginTop: 25 }}>
                        <Paper className="trafficCompPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                            <div style={{ height: '40%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <AccessibilityComparison listings={listings} />
                            </div>
                            <div style={{ height: '60%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <EngagementComparison listings={listings} />
                            </div>
                        </Paper>
                    </div>
                    <div id='commonPeopleCompTest' style={{ marginTop: 25 }}>
                        <Paper className="demoCompPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 50, position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                            <Divider style={{ marginTop: 25 }} variant="middle">
                                <h4>Demographics</h4>
                            </Divider>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 40 }}>
                                <CommonPeopleComparison listings={listings} />
                            </div>
                        </Paper>
                    </div>
                    <div id='populationCompTest' style={{ marginTop: 25 }}>
                        <Paper className="demoCompPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <PopulationComparison listings={listings} />
                            </div>
                        </Paper>
                    </div>
                    <div id='familyCompTest' style={{ marginTop: 25 }}>
                        <Paper className="demoCompPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <FamilyComparison listings={listings} />
                            </div>
                        </Paper>
                    </div>
                    <div id='resPricesAndRentOwnCompTest' style={{ marginTop: 25 }}>
                        <Paper className="demoCompPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <ResPricesComparison listings={listings} />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 50 }}>
                                <RentOwnComparison listings={listings} />
                            </div>
                        </Paper>
                    </div>
                    <div id='incomeCompTest' style={{ marginTop: 25 }}>
                        <Paper className="demoCompPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'top', height: '100%' }}>
                                <IncomeComparison listings={listings} />
                            </div>
                        </Paper>
                    </div>
                    <div id='totalBizCompTest' style={{ marginTop: 25 }}>
                        <Paper className="competitionCompPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 50, position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                            <Divider style={{ marginTop: 25 }} variant="middle">
                                <h4>Competition</h4>
                            </Divider>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 40 }}>
                                <TotalBusinessesComparison listings={listings} />
                            </div>
                        </Paper>
                    </div>
                    <div id='popularBizCompTest' style={{ marginTop: 25 }}>
                        <Paper className="competitionCompPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <PopularBizTable listings={listings && listings.slice(0, 2)} />
                            </div>
                        </Paper>
                    </div>
                    {(listings && listings.length && (listings.length > 2)) ? (<div id='popularBizCompTestCont' style={{ marginTop: 25 }}>
                        <Paper className="competitionCompPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <PopularBizTable popBizContinued={true} listings={(listings && listings.length && (listings.length === 4)) ? listings.slice(-2) : listings.slice(-1)} />
                            </div>
                        </Paper>
                    </div>) : null}
                    <div id='commonBizCompTest' style={{ marginTop: 25 }}>
                        <Paper className="competitionCompPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <CommonBizComparison listings={listings} />
                            </div>
                        </Paper>
                    </div>
                    <div id='highEndCompTest' style={{ marginTop: 25 }}>
                        <Paper className="competitionCompPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                            <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <HighEndBizComparison listings={listings} />
                            </div>
                        </Paper>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompareListingsContentTest;