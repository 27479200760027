import React, { Component } from 'react';
import { 
    Modal, 
    ModalHeader, 
    ModalBody,
    Container } 
from 'reactstrap';
import CreateListingInput from './createListingInput';
import CreateListingConfirm from './createListingConfirm';
import { Fab } from '@material-ui/core';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addListing } from '../../actions/listingActions';
import { updateUser } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';
import './createListing.css';

class CreateListing extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            successAlert: false,
            msg: null,
            step: 1,
            streetAddress: null,
            city: null,
            state: null,
            zip: null,
            squareFeet1: null,
            squareFeet2: null,
            pricePerSquareFoot: null,
            frontage: null,
            propertyFeatures: [],
            images: [],
            active: true
        }
    }

    componentDidUpdate(prevProps) {
        const { error } = this.props;

        if (error !== prevProps.error) {
            
            //Check for Create Listing Error
            if (error.id === 'ADD_LISTING_FAIL') {
                this.setState({ msg: error.msg.msg })
            } else {
                this.setState({ msg: null });
            }
        }
    }


    toggle = () => {
        
        // Clear Errors
        this.props.clearErrors();

        // Clear Fields
        this.setState({ successAlert: false, sfError: false, addRange: false, streetAddress: null, city: null, state: null, zip: null, squareFeet1: null, squareFeet2: null, pricePerSquareFoot: null, frontage: null, propertyFeatures: [], images: [] });
        
        this.setState({
            modal: !this.state.modal,
            step: 1
        });
    }

    // Proceed to next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({ step: step + 1 });
    }

    // Go back to previous step
    prevStep = () => {
        const { step } = this.state;
        this.setState({ step: step - 1 });
    }

    selectChange = state => e  => {
        this.setState({ [state]: e.target.value });
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value, successAlert: false, sfError: false });
    }

    setFeatures = (features) => {
        this.setState({ propertyFeatures: features });
    }

    setImages = (allImages) => {
        this.setState({ images: allImages });
    }

    render() {
        
        const { step } = this.state;
        
        const { streetAddress, city, state, zip, squareFeet1, squareFeet2, pricePerSquareFoot, frontage, propertyFeatures, images } = this.state;
        const values = { streetAddress, city, state, zip, squareFeet1, squareFeet2, pricePerSquareFoot, frontage, propertyFeatures, images };
        
        const stateAbbr = [{label: 'NY', value: 'NY'}];
        const cityAbbr = [{label: 'New York', value: 'New York'}];


        return(
            <Container>
                <Fab
                    variant="extended"
                    size="md"
                    onClick={this.toggle}
                    style={{ color: 'white', marginTop: 'auto', marginBottom: 'auto', width: 200, backgroundColor: 'rgb(52, 181, 122)', fontSize: 12, outline: 'none' }}
                >
                + Create New Listing
                </Fab>
                <Modal style={{ minWidth: 1000, marginTop: '5%', color: 'rgb(35, 126, 85)' }} isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Enter Listing Info</ModalHeader>
                    <ModalBody style={{ width: 998, margin: 'auto', paddingBottom: 30, backgroundColor: 'whitesmoke' }}>
                        {step === 1 ? <CreateListingInput values={values} stateAbbr={stateAbbr} cityAbbr={cityAbbr} selectChange={this.selectChange} onChange={this.onChange} nextStep={this.nextStep} setFeatures={this.setFeatures} setImages={this.setImages} /> : <CreateListingConfirm values={values} thisUsersListings={this.props.thisUsersListings} user={this.props.user} auth={this.props.auth} prevStep={this.prevStep} />}
                    </ModalBody>
                </Modal>
            </Container>
        )
    }
}

CreateListing.propTypes = {
    addListing: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    error: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    listing: state,
    error: state.error,
});

export default connect(mapStateToProps, { clearErrors, addListing, updateUser })(CreateListing);