import React, { Component } from 'react';
import './listingMap.css';
import { GoogleMap, LoadScript, Marker, InfoWindow, MarkerClusterer, Autocomplete, Polygon } from '@react-google-maps/api';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { Fab, Button } from '@material-ui/core';
import { List, ListItem, ListItemIcon, Backdrop, Accordion, AccordionSummary, AccordionDetails, Checkbox, CircularProgress, Box, Tooltip, tooltipClasses, Stack, Chip, Divider, IconButton, Slide, Badge, LinearProgress } from '@mui/material';
import { MdExpandMore, MdDraw, MdOutlineDoubleArrow, MdChevronRight, MdChevronLeft, MdOutlineCheckCircle } from 'react-icons/md';
import { FaChartBar } from 'react-icons/fa';
import { ImCheckmark, ImCross } from 'react-icons/im';
import { styled } from '@mui/material/styles';
import mapStyles from './listingDetailsLayouts/maps/mapStyles';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getListings } from '../actions/listingActions';
import { loadUser, updateUser } from '../actions/authActions';
import PropTypes from 'prop-types';
import Select from 'react-select';
import SkeletonListingMap from './skeletons/skeletonListingMap';
import BizListModal from './CustomSearch/bizListModal';
import ContactModal from './contactModal';
import LeavingPageAlert from './CustomSearch/leavingPageAlert';
import fourcastMatchLogo from '../Assets/fourcast_match_logo_green.png';
import Joyride from './skeletons/joyRide';
import fifty from '../Assets/score_circles2/score2Icon50.png';
import fifty_one from '../Assets/score_circles2/score2Icon51.png';
import fifty_two from '../Assets/score_circles2/score2Icon52.png';
import fifty_three from '../Assets/score_circles2/score2Icon53.png';
import fifty_four from '../Assets/score_circles2/score2Icon54.png';
import fifty_five from '../Assets/score_circles2/score2Icon55.png';
import fifty_six from '../Assets/score_circles2/score2Icon56.png';
import fifty_seven from '../Assets/score_circles2/score2Icon57.png';
import fifty_eight from '../Assets/score_circles2/score2Icon58.png';
import fifty_nine from '../Assets/score_circles2/score2Icon59.png';
import sixty from '../Assets/score_circles2/score2Icon60.png';
import sixty_one from '../Assets/score_circles2/score2Icon61.png';
import sixty_two from '../Assets/score_circles2/score2Icon62.png';
import sixty_three from '../Assets/score_circles2/score2Icon63.png';
import sixty_four from '../Assets/score_circles2/score2Icon64.png';
import sixty_five from '../Assets/score_circles2/score2Icon65.png';
import sixty_six from '../Assets/score_circles2/score2Icon66.png';
import sixty_seven from '../Assets/score_circles2/score2Icon67.png';
import sixty_eight from '../Assets/score_circles2/score2Icon68.png';
import sixty_nine from '../Assets/score_circles2/score2Icon69.png';
import seventy from '../Assets/score_circles2/score2Icon70.png';
import seventy_one from '../Assets/score_circles2/score2Icon71.png';
import seventy_two from '../Assets/score_circles2/score2Icon72.png';
import seventy_three from '../Assets/score_circles2/score2Icon73.png';
import seventy_four from '../Assets/score_circles2/score2Icon74.png';
import seventy_five from '../Assets/score_circles2/score2Icon75.png';
import seventy_six from '../Assets/score_circles2/score2Icon76.png';
import seventy_seven from '../Assets/score_circles2/score2Icon77.png';
import seventy_eight from '../Assets/score_circles2/score2Icon78.png';
import seventy_nine from '../Assets/score_circles2/score2Icon79.png';
import eighty from '../Assets/score_circles2/score2Icon80.png';
import eighty_one from '../Assets/score_circles2/score2Icon81.png';
import eighty_two from '../Assets/score_circles2/score2Icon82.png';
import eighty_three from '../Assets/score_circles2/score2Icon83.png';
import eighty_four from '../Assets/score_circles2/score2Icon84.png';
import eighty_five from '../Assets/score_circles2/score2Icon85.png';
import eighty_six from '../Assets/score_circles2/score2Icon86.png';
import eighty_seven from '../Assets/score_circles2/score2Icon87.png';
import eighty_eight from '../Assets/score_circles2/score2Icon88.png';
import eighty_nine from '../Assets/score_circles2/score2Icon89.png';
import ninety from '../Assets/score_circles2/score2Icon90.png';
import ninety_one from '../Assets/score_circles2/score2Icon91.png';
import ninety_two from '../Assets/score_circles2/score2Icon92.png';
import ninety_three from '../Assets/score_circles2/score2Icon93.png';
import ninety_four from '../Assets/score_circles2/score2Icon94.png';
import ninety_five from '../Assets/score_circles2/score2Icon95.png';
import ninety_six from '../Assets/score_circles2/score2Icon96.png';
import ninety_seven from '../Assets/score_circles2/score2Icon97.png';
import ninety_eight from '../Assets/score_circles2/score2Icon98.png';
import ninety_nine from '../Assets/score_circles2/score2Icon99.png';
import checklistStrong from '../Assets/icons8-list.gif';
import checklistWeak from '../Assets/icons8-checklist.gif';
import CustomNavbar from './welcome/navbar';
import MobileNavbar from './welcome/mobileNavbar';
import MobileMapFiltersModal from './mobileMapFiltersModal';
import CompareListingsModal from './CompareListings/compareListingsModal';
import FourcastMatchModal from './CustomSearch/fourcastMatchModal';
import FourcastMatchResultComparisonModal from './CustomSearch/fourcastMatchResultComparisonModal';

const scoreIcon = (fourcastScore) => {
    
    switch(fourcastScore) {
        case 50:
            return (
                fifty
            )
        case 51:
            return ( 
                fifty_one
            )
        case 52:
            return ( 
                fifty_two
            )
        case 53:
            return ( 
                fifty_three
            )
        case 54:
            return ( 
                fifty_four
            )
        case 55:
            return ( 
                fifty_five
            )
        case 56:
            return ( 
                fifty_six
            )
        case 57:
            return ( 
                fifty_seven
            )
        case 58:
            return ( 
                fifty_eight
            )
        case 59:
            return ( 
                fifty_nine
            )
        case 60:
            return ( 
                sixty
            )
        case 61:
            return ( 
                sixty_one
            )
        case 62:
            return ( 
                sixty_two
            )
        case 63:
            return ( 
                sixty_three
            )
        case 64:
            return ( 
                sixty_four
            )
        case 65:
            return ( 
                sixty_five
            )
        case 66:
            return ( 
                sixty_six
            )
        case 67:
            return ( 
                sixty_seven
            )
        case 68:
            return ( 
                sixty_eight
            )
        case 69:
            return ( 
                sixty_nine
            )
        case 70:
            return ( 
                seventy
            )
        case 71:
            return ( 
                seventy_one
            )
        case 72:
            return ( 
                seventy_two
            )
        case 73:
            return ( 
                seventy_three
            )
        case 74:
            return ( 
                seventy_four
            )
        case 75:
            return ( 
                seventy_five
            )
        case 76:
            return ( 
                seventy_six
            )
        case 77:
            return ( 
                seventy_seven
            )
        case 78:
            return ( 
                seventy_eight
            )
        case 79:
            return ( 
                seventy_nine
            )
        case 80:
            return ( 
                eighty
            )
        case 81:
            return ( 
                eighty_one
            )
        case 82:
            return ( 
                eighty_two
            )
        case 83:
            return ( 
                eighty_three
            )
        case 84:
            return ( 
                eighty_four
            )
        case 85:
            return ( 
                eighty_five
            )
        case 86:
            return ( 
                eighty_six
            )
        case 87:
            return ( 
                eighty_seven
            )
        case 88:
            return ( 
                eighty_eight
            )
        case 89:
            return ( 
                eighty_nine
            )
        case 90:
            return ( 
                ninety
            )
        case 91:
            return ( 
                ninety_one
            )
        case 92:
            return ( 
                ninety_two
            )
        case 93:
            return ( 
                ninety_three
            )
        case 94:
            return ( 
                ninety_four
            )
        case 95:
            return ( 
                ninety_five
            )
        case 96:
            return ( 
                ninety_six
            )
        case 97:
            return ( 
                ninety_seven
            )
        case 98:
            return ( 
                ninety_eight
            )
        case 99:
            return ( 
                ninety_nine
            )
        case 100:
            return (
                ninety_nine
            )
        default:
            return (
                fifty
            )
    }
};

const customSearchScoreIcon = (fourcastScore, customSearchPoints) => {
    
    let customSearchScore = (fourcastScore + customSearchPoints);
    
    if (customSearchScore < 50) {
        return fifty;
    } else if (customSearchScore > 99) {
        return ninety_nine;
    } 

    switch(customSearchScore) {
        case 50:
            return (
                fifty
            )
        case 51:
            return ( 
                fifty_one
            )
        case 52:
            return ( 
                fifty_two
            )
        case 53:
            return ( 
                fifty_three
            )
        case 54:
            return ( 
                fifty_four
            )
        case 55:
            return ( 
                fifty_five
            )
        case 56:
            return ( 
                fifty_six
            )
        case 57:
            return ( 
                fifty_seven
            )
        case 58:
            return ( 
                fifty_eight
            )
        case 59:
            return ( 
                fifty_nine
            )
        case 60:
            return ( 
                sixty
            )
        case 61:
            return ( 
                sixty_one
            )
        case 62:
            return ( 
                sixty_two
            )
        case 63:
            return ( 
                sixty_three
            )
        case 64:
            return ( 
                sixty_four
            )
        case 65:
            return ( 
                sixty_five
            )
        case 66:
            return ( 
                sixty_six
            )
        case 67:
            return ( 
                sixty_seven
            )
        case 68:
            return ( 
                sixty_eight
            )
        case 69:
            return ( 
                sixty_nine
            )
        case 70:
            return ( 
                seventy
            )
        case 71:
            return ( 
                seventy_one
            )
        case 72:
            return ( 
                seventy_two
            )
        case 73:
            return ( 
                seventy_three
            )
        case 74:
            return ( 
                seventy_four
            )
        case 75:
            return ( 
                seventy_five
            )
        case 76:
            return ( 
                seventy_six
            )
        case 77:
            return ( 
                seventy_seven
            )
        case 78:
            return ( 
                seventy_eight
            )
        case 79:
            return ( 
                seventy_nine
            )
        case 80:
            return ( 
                eighty
            )
        case 81:
            return ( 
                eighty_one
            )
        case 82:
            return ( 
                eighty_two
            )
        case 83:
            return ( 
                eighty_three
            )
        case 84:
            return ( 
                eighty_four
            )
        case 85:
            return ( 
                eighty_five
            )
        case 86:
            return ( 
                eighty_six
            )
        case 87:
            return ( 
                eighty_seven
            )
        case 88:
            return ( 
                eighty_eight
            )
        case 89:
            return ( 
                eighty_nine
            )
        case 90:
            return ( 
                ninety
            )
        case 91:
            return ( 
                ninety_one
            )
        case 92:
            return ( 
                ninety_two
            )
        case 93:
            return ( 
                ninety_three
            )
        case 94:
            return ( 
                ninety_four
            )
        case 95:
            return ( 
                ninety_five
            )
        case 96:
            return ( 
                ninety_six
            )
        case 97:
            return ( 
                ninety_seven
            )
        case 98:
            return ( 
                ninety_eight
            )
        case 99:
            return ( 
                ninety_nine
            )
        default:
            return (
                fifty
            )
    }
};

const filterLabels = (label) => {    
    
    switch(label) {
        case "nearAttraction":
            return (
                "Near Major Attraction"
            )
        case "seasonal":
            return ( 
                "Seasonal"
            )
        case "busyDayTime":
            return ( 
                "Heavy Daytime Traffic"
            )
        case "busyMonThruFri":
            return (
                "Heavy Mon-Fri Traffic"
            )
        case "busyWeekend":
            return ( 
                "Heavy Weekend Traffic"
            )
        case "busyNightLife":
            return ( 
                "Heavy Nightlife"
            )
        case "localResidents":
            return (
                "Mostly Local Resident"
            )
        case "officeWorkers":
            return ( 
                "Mostly People at Work"
            )
        case "students":
            return (
                "Students"
            )
        case "highIncomeArea":
            return ( 
                "High Income Area"
            )
        case "genZ":
            return ( 
                "Gen Z"
            )
        case "millennialsTo34":
            return ( 
                "Young Millennials"
            )
        case "millennialsTo44":
            return ( 
                "Older Millennials"
            )
        case "genX":
            return ( 
                "Gen X"
            )
        case "boomers":
            return ( 
                "Boomers"
            )
        case "areasWithChildren":
            return ( 
                "Areas WITH Children"
            )
        case "affordableRE":
            return ( 
                "Affordable"
            )
        case "expensiveRE":
            return ( 
                "Expensive"
            )
        case "highEndBiz":
            return ( 
                "$$$$"

            )
        case "lowEndBiz":
            return ( 
                "$"

            )
        case "highBizDensity":
            return ( 
                "High Biz Density"

            )
        case "lowBizDensity" :
            return ( 
                "Low Biz Density"

            )
        case "cornerLocation":
            return ( 
                "Corner Location"

            )
        case "bigFrontage":
            return ( 
                "Big Frontage"
            )
        case "divisible":
            return ( 
                "Divisible"
            )
        case "multiLevel":
            return ( 
                "Multi Level"

            )
        case "newConstruction":
            return ( 
                "New Construction"

            )
        case "outdoorSpace":
            return ( 
                "Outdoor Space"

            )
        default:
            return null
    }
}

// Map Styles /////////////

// Map with Filter Box aka 'fb' aka NOT Fourcast Match
const fbContainerStyle = {
    width: '100%',
    height: '100%',
    minHeight: 750
};

const fbCenter = {
    lat: 40.7781,
    lng: -73.9803
};

const fullContainerStyle = {
    width: '100vw',
    height: '90vh',
    margin: 'auto'
};

const fullCenter = {
    lat: 40.7691,
    lng: -73.9861
};

const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
};

const clusterStyles = [
    {textColor: 'white', url: `https://marker.nanoka.fr/map_cluster-007FFF-56.svg`, height: 56, width: 56},
]
  
const polygonOptions = {
    fillColor: "rgb(173, 216, 230, .6)",
    fillOpacity: 1,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: true,
    draggable: false,
    editable: true,
    geodesic: true,
    zIndex: 1
}

////////////////////////////////////////////////////////////////////



class ListingMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            customSearchMode: false,
            filterBoxOpen: true,
            selected: null,
            score: null,
            minSF: 0,
            maxSF: 19999,
            minPrice: 0,
            maxPrice: 19999,
            filterTags: [],
            customSearchFilters: null,
            showAll: false,
            showUndisclosed: true,
            joyRide: false,
            autocomplete: null,
            neighborhoods: [],
            searchedPlace: [],
            searchedPlaceSelected: null,
            listingsNearSearchedPlace: [],
            drawPolygonMode: false,
            polygonCoords: [],
            mobile: false,
            screenWidth: 390,
            compareListings: [],
            matchResultsComparisonList: [],
            fourcastMatchComparisonListings: [],
            noDataAlert: false,
            compareListingsChange: false,
            fourcastMatchResultComparisonModal: false,
            loadFourcastMatch: false
        }
        this.autocomplete = null
        this.onLoad = this.onLoad.bind(this)
        this.onPlaceChanged = this.onPlaceChanged.bind(this)

        this.polygon = null
        this.polygonOnLoad = this.polygonOnLoad.bind(this)
    }
    
    componentDidMount() {
        const { isAuthenticated } = this.props;
        
        // setting mobile views
        this.props.getLocation(this.props.location.pathname);
        window && window.addEventListener('resize', this.mobileMode.bind(this));
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ mobile: true, screenWidth: screenWidth });
        } else {
            this.setState({ mobile: false });
        }

        //
        
        if (isAuthenticated && this.props.location.state) {
            
            const { customSearchFilters } = this.props.location.state;
            this.setState({ customSearchFilters: customSearchFilters, customSearchMode: true, loadFourcastMatch: true });
            
            let page;
            let userBadBizList = customSearchFilters.badBiz.length > 0 ? customSearchFilters.badBiz.map(badBiz => {return {alias: badBiz.value.toLowerCase(), title: badBiz.label}}) : [{alias: null, title: null}];
            let userGoodBizList = customSearchFilters.goodBiz.length > 0 ? customSearchFilters.goodBiz.map(goodBiz => {return {alias: goodBiz.value.toLowerCase(), title: goodBiz.label}}) : [{alias: null, title: null}];
            page = { path: "customMap", filters: { badBizList: userBadBizList, goodBizList: userGoodBizList } };
            
            this.props.getListings(page);

            this.props.loadUser();
            const { user } = this.props;

            let searchCount = user && user.engagement.searches;
            if (searchCount < 2) {
                this.setState({ joyRide: true })
            }

            searchCount += 1;

            let saveThisSearch = (customSearchFilters && customSearchFilters.saveThisSearch && (customSearchFilters.saveThisSearch === true)) ? true : false;
            const newSearches = [...user.engagement.savedSearches, customSearchFilters];
            const mapNewSearches = newSearches && newSearches.length && newSearches.flatMap(search => search);
            const uniqueSavedSearches = mapNewSearches && mapNewSearches.length && [...new Map(mapNewSearches.map(v => [JSON.stringify(v && v), v])).values()];
            const savedSearches = (saveThisSearch && (saveThisSearch === true)) ? uniqueSavedSearches : user.engagement.savedSearches;
            
            const updatedUser = {
                userId: user._id,
                accountType: user.accountType,
                engagement: {
                    listings: user.engagement.listings,
                    searches: searchCount,
                    savedListings: user.engagement.savedListings,
                    savedSearches: savedSearches
                }
            };
            
            updatedUser && updatedUser ? this.props.updateUser(updatedUser) : console.log('User undefined');
        } else {
            let page;
            page = { path: "listingMap", filters: null };
            this.props.getListings(page);
        }

        const firstTimeJoyride = localStorage.getItem("firstTimeJoyride");
        if (!firstTimeJoyride) {
            const timer = setTimeout(() => {
                this.setState({ joyRide: true })
                localStorage.setItem("firstTimeJoyride", "1");
            }, 6500);
            return () => clearTimeout(timer);
        }

    }

    componentWillUnmount() {
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
    }

    mobileMode() {
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth, mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }

    setMobileFilterModalState = (values) => {
        const minSF = values.minSF;
        const maxSF = values.maxSF;
        const minPrice = values.minPrice;
        const maxPrice = values.maxPrice;
        const filterTags = values.filterTags;
        const showUndisclosed = values.showUndisclosed;
        const neighborhoods = values.neighborhoods;
        const searchedPlace = values.searchedPlace;
        values && this.setState({ minSF, maxSF, minPrice, maxPrice, filterTags, showUndisclosed, neighborhoods, searchedPlace });
    }


    setMinSF = (value) => {
        this.setState({ minSF: value });
    }

    setMaxSF = (value) => {
        this.setState({ maxSF: value });
    }

    setMinPrice = (value) => {
        this.setState({ minPrice: value });
    }

    setMaxPrice = (value) => {
        this.setState({ maxPrice: value });
    }

    setJoyRide = (value) => {
        this.setState({ joyRide: value })
    }

    // AUTOCOMPLETE
    onLoad (autocomplete) {
        console.log('autocomplete: ', autocomplete)
    
        this.autocomplete = autocomplete
        this.autocomplete.setTypes(['geocode']);
        const southwest = { lat: 40.70650, lng: -74.02598 };
        const northeast = { lat: 40.87164, lng: -73.89830 };
        const newBounds = new window.google.maps.LatLngBounds(southwest, northeast);

        this.autocomplete.setBounds(newBounds);
        this.autocomplete.setOptions({ strictBounds: true })
    }
    
    onPlaceChanged () {
        const { neighborhoods, searchedPlace } = this.state;
        if (this.autocomplete !== null) {
            const currentNeighborhoods = neighborhoods;
            const currentSearchedPlace = searchedPlace;
            
            let enteredPlace = this.autocomplete.getPlace();
        
            const thisNeighb = (enteredPlace && enteredPlace.name.length && enteredPlace.types.includes('neighborhood')) ? enteredPlace.address_components[0] : null;
            
            const thisPremisesLat = (enteredPlace && enteredPlace.name.length && !enteredPlace.types.includes('neighborhood')) ? enteredPlace.geometry.location.lat() : null;
            const thisPremisesLng = (enteredPlace && enteredPlace.name.length && !enteredPlace.types.includes('neighborhood')) ? enteredPlace.geometry.location.lng() : null;
            const thisPremisesAddy = (enteredPlace && enteredPlace.name.length && !enteredPlace.types.includes('neighborhood')) ? enteredPlace.formatted_address : null;
            let thisPremisesCleanedAddress = thisPremisesAddy && thisPremisesAddy.length && thisPremisesAddy.slice(0, -5);
            const findPremises = (thisPremisesLat && thisPremisesLng && (thisPremisesAddy && thisPremisesCleanedAddress)) ? {address: thisPremisesCleanedAddress, lat: thisPremisesLat, lng: thisPremisesLng} : null;
            
            const listOfNeighbs = ((thisNeighb !== undefined) && neighborhoods.includes(thisNeighb)) ? currentNeighborhoods : thisNeighb !== undefined ? [...currentNeighborhoods, thisNeighb] : [];
            const placeToPin = ((thisPremisesLat !== null) && findPremises.lat && findPremises.lng && findPremises.address) ? [findPremises] : currentSearchedPlace;
            
            if (thisNeighb) {
                this.setState({ neighborhoods: listOfNeighbs });
            }
            if ((thisPremisesLat !== undefined) && (thisPremisesLng !== undefined) && (thisPremisesAddy !== undefined)) {
                this.setState({ searchedPlace: placeToPin });
            }

        } else {
            console.log('Autocomplete is not loaded yet!')
        }
    }

    autocompleteDelete (deletedNeighborhood) {
        const { neighborhoods, searchedPlace } = this.state;
        const value = deletedNeighborhood;
        let updatedNeighbs = neighborhoods.filter((neighb) => neighb !== value);
        let updatedPlace = searchedPlace.filter((place) => place !== value);
        this.setState({ neighborhoods: updatedNeighbs, searchedPlace: updatedPlace });
    }

    polygonOnLoad = polygon => {
        console.log("polygon: ", polygon);
        const { polygonCoords } = this.state;
        this.polygon = polygon;
        const polyMap = new window.google.maps.Polygon({
            paths: polygonCoords,
            fillColor: "lightblue",
            fillOpacity: 1,
            strokeColor: "red",
            strokeOpacity: 1,
            strokeWeight: 2,
            clickable: true,
            draggable: true,
            editable: true,
            geodesic: true,
            zIndex: 1
        })

        polyMap.setMap(window.google.maps);
    }

    // Select the listings to generate fourcast match comparison for
    setFourcastMatchComparisonListings = (selectedListings) => {
        const { listings } = this.props;
        const selectedListingIds = selectedListings && selectedListings.length && selectedListings.map(selectedList => selectedList.value);
        const filterAllListingInfo = listings && listings.length && listings.filter(gatheredListing => selectedListingIds && selectedListingIds.some(selectedListId => selectedListId && (selectedListId === gatheredListing._id)));
        if (filterAllListingInfo && (filterAllListingInfo.length > 1)) {
            this.setState({ fourcastMatchComparisonListings: filterAllListingInfo });
        }
    }

    toggleFourcastMatchComparisonModal = () => {
        this.setState({
            fourcastMatchResultComparisonModal: !this.state.fourcastMatchResultComparisonModal,
        });
    }
    
    render() {

    const listings = [];
    
    if (this.props.listings && this.props.listings.length) {
        listings.push(...this.props.listings)
    }

    const { customSearchFilters, neighborhoods, searchedPlace, polygonCoords, mobile, screenWidth, minSF, maxSF, minPrice, maxPrice, filterTags, showUndisclosed, compareListings } = this.state;
    const stateFilterValues = { minSF, maxSF, minPrice, maxPrice, filterTags, showUndisclosed, neighborhoods, searchedPlace };

    const customSearchObjects = [];


    // CUSTOM SEARCH SCORE CALCULATION

    
    if (this.state.customSearchMode === true && listings.length > 0) { 

        
        const customFilterPoints = (listing, tags) => {
        
            const trueTagObject = {
                trueTag: 0,
                falseTag: 0,
                trueTags: [],
                falseTags: [],
            }
    
            for (let i = 0; i < tags.length; i++) {
                
                if (listing && listing.filterTags) {
                    for (let j = 0; j < listing.filterTags.length; j++) {
                        if (listing.filterTags[j].includes(tags[i])) {
                            trueTagObject.trueTag += 5;
                            trueTagObject.trueTags.push(tags[i]);
                        }

                    }
                }

                if (!trueTagObject.trueTags.includes(tags[i])) {
                    trueTagObject.falseTag -= 7.5;
                    trueTagObject.falseTags.push(tags[i]);
                }

            }
    
            return trueTagObject;
        }

        const theseDealBreakers = customSearchFilters && customSearchFilters.dealBreakers && customSearchFilters.dealBreakers;
        
        const dealBreakers = {
            minSize: (theseDealBreakers && theseDealBreakers.length && theseDealBreakers.some(dealBreaker => dealBreaker && dealBreaker.dealBreakTitle && dealBreaker.dealBreakTitle === 'minSize')) ? theseDealBreakers && theseDealBreakers.filter(dealBreaker => dealBreaker && dealBreaker.dealBreakTitle === 'minSize') : null,
            maxSize: (theseDealBreakers && theseDealBreakers.length && theseDealBreakers.some(dealBreaker => dealBreaker && dealBreaker.dealBreakTitle && dealBreaker.dealBreakTitle === 'maxSize')) ? theseDealBreakers && theseDealBreakers.filter(dealBreaker => dealBreaker && dealBreaker.dealBreakTitle === 'maxSize') : null,
            price: (theseDealBreakers && theseDealBreakers.length && theseDealBreakers.some(dealBreaker => dealBreaker && dealBreaker.dealBreakTitle && dealBreaker.dealBreakTitle === 'price')) ? theseDealBreakers && theseDealBreakers.filter(dealBreaker => dealBreaker && dealBreaker.dealBreakTitle === 'price') : null,
            goodBiz: (theseDealBreakers && theseDealBreakers.length && theseDealBreakers.some(dealBreaker => dealBreaker && dealBreaker.cat && dealBreaker.cat === 'goodBiz')) ? theseDealBreakers && theseDealBreakers.filter(dealBreaker => dealBreaker && dealBreaker.cat === 'goodBiz') : null,
            badBiz: (theseDealBreakers && theseDealBreakers.length && theseDealBreakers.some(dealBreaker => dealBreaker && dealBreaker.cat && dealBreaker.cat === 'badBiz')) ? theseDealBreakers && theseDealBreakers.filter(dealBreaker => dealBreaker && dealBreaker.cat === 'badBiz') : null,
            additionalFilters: theseDealBreakers && theseDealBreakers.length && theseDealBreakers.filter(dealBreaker => {
                if (dealBreaker && (!dealBreaker.cat) && (dealBreaker.dealBreakTitle !== 'minSize') && (dealBreaker.dealBreakTitle !== 'maxSize') && (dealBreaker.dealBreakTitle !== 'price')) {
                    return dealBreaker;
                }
            })
        }
        
        for (let l = 0; l < listings.length; l++) {
            
            
            const thisMinSize = parseFloat(customSearchFilters.minSize);
            const thisMaxSize = parseFloat(customSearchFilters.maxSize);
            const thisMaxPrice = parseFloat(customSearchFilters.price);
            // Budget Types: 1 = total amount per year, 2 = per square foot per year
            const thisBudgetType = parseFloat(customSearchFilters.budgetType);
            
            const badBizList = listings[l].yelpBusinesses ? listings[l].yelpBusinesses.badBizList.filter(badBiz => badBiz.distance <= 200) : [];

            const goodBizList = listings[l].yelpBusinesses ? listings[l].yelpBusinesses.goodBizList.filter(goodBiz => goodBiz.distance <= 200) : [];
            
            const areaReviews = [];
            areaReviews.push(listings[l].totalReviewCount && listings[l].totalReviewCount.review_count);

            const matchedFiltersTotals = [];
            const matchedTags = [];


            matchedTags.push(customFilterPoints(listings[l], customSearchFilters.trafficFilters));
            matchedTags.push(customFilterPoints(listings[l], customSearchFilters.demoFilters));
            matchedTags.push(customFilterPoints(listings[l], customSearchFilters.compFilters));
            matchedTags.push(customFilterPoints(listings[l], customSearchFilters.propertyFilters));
            
            
            let squareFeetPoints = 0;
            let pricePoints = 0;

            if (listings[l].squareFeet.some(sqFt => (sqFt >= thisMinSize) && (sqFt <= thisMaxSize))) {
                squareFeetPoints += 5;
                if ((dealBreakers.minSize !== null) || (dealBreakers.maxSize !== null)) {
                    squareFeetPoints += 2.5;
                }
            } else if (listings[l].squareFeet.some(sqFt => (sqFt > thisMaxSize * 1.5))) {
                squareFeetPoints -= 15;
                if (dealBreakers.maxSize !== null) {
                    squareFeetPoints *= 2;
                }
            } else if (listings[l].squareFeet.some(sqFt => (sqFt > thisMaxSize))) {
                squareFeetPoints -= 10;
                if (dealBreakers.maxSize !== null) {
                    squareFeetPoints *= 3;
                }
            } else if (listings[l].squareFeet.some(sqFt => ((sqFt * 1.5) < thisMinSize))) {
                squareFeetPoints -= 15;
                if (dealBreakers.minSize !== null) {
                    squareFeetPoints *= 2;
                }
            } else if (listings[l].squareFeet.some(sqFt => (sqFt < thisMinSize))) {
                squareFeetPoints -= 10;
                if (dealBreakers.minSize !== null) {
                    squareFeetPoints *= 3;
                }
            }

            // convert listing's price to a total YEARLY amount by multiplying by square feet
            let totalYearlyPrice = listings[l].squareFeet.length > 1 ? [Math.ceil(listings[l].pricePerSquareFoot * listings[l].squareFeet[0]), Math.ceil(listings[l].pricePerSquareFoot * listings[l].squareFeet[1])] : [Math.ceil(listings[l].pricePerSquareFoot * listings[l].squareFeet[0])];
            
            if (thisBudgetType === 1) {
                if (totalYearlyPrice.some(yearlyPrice => yearlyPrice < thisMaxPrice)) {
                    pricePoints += 5;
                    if (dealBreakers.price !== null) {
                        pricePoints += 2.5;
                    }
                } else if ((totalYearlyPrice[0] > (thisMaxPrice * 1.5)) && (totalYearlyPrice[totalYearlyPrice.length - 1] > (thisMaxPrice * 1.5))) {
                    pricePoints -= 30;
                    if (dealBreakers.price !== null) {
                        pricePoints *= 2;
                    }
                } else if ((totalYearlyPrice[0] > thisMaxPrice) && (totalYearlyPrice[totalYearlyPrice.length - 1] > thisMaxPrice)) {
                    pricePoints -= 20;
                    if (dealBreakers.price !== null) {
                        pricePoints *= 3;
                    }
                } else if (totalYearlyPrice <= 0) {
                    pricePoints += 0;
                }
            } else {
                if (listings[l].pricePerSquareFoot < thisMaxPrice) {
                    pricePoints += 5;
                    if (dealBreakers.price !== null) {
                        pricePoints += 2.5;
                    }
                } else if (listings[l].pricePerSquareFoot > (thisMaxPrice * 1.5)) {
                    pricePoints -= 30;
                    if (dealBreakers.price !== null) {
                        pricePoints *= 2;
                    }
                } else if (listings[l].pricePerSquareFoot > thisMaxPrice) {
                    pricePoints -= 20;
                    if (dealBreakers.price !== null) {
                        pricePoints *= 3;
                    }
                } else if (listings[l].pricePerSquareFoot <= 0) {
                    pricePoints += 0;
                }
            }
            
            
            
            if (matchedTags.length > 0) {
                let matchedTotal = 0;
                const holdMatchTotal = [];

                for (let i = 0; i < matchedTags.length; i++) {
                    matchedTotal = (matchedTags[i].trueTag + matchedTags[i].falseTag);
                    holdMatchTotal.push(matchedTotal);
                }
                
                if (dealBreakers.additionalFilters && dealBreakers.additionalFilters.length) {
                    const allMatchedTrueTags = matchedTags && matchedTags.flatMap(matchedTagCategory => matchedTagCategory.trueTags);
                    const allMatchedFalseTags = matchedTags && matchedTags.flatMap(matchedTagCategory => matchedTagCategory.falseTags);

                    for (let d = 0; d < dealBreakers.additionalFilters.length; d++) {
                        if (allMatchedTrueTags && allMatchedTrueTags.includes(dealBreakers.additionalFilters[d].dealBreakValue)) {
                            holdMatchTotal.push(2);
                        }

                        if (allMatchedFalseTags && allMatchedFalseTags.includes(dealBreakers.additionalFilters[d].dealBreakValue)) {
                            holdMatchTotal.push(-40);
                        }
                    }
                }
                
                const countFiltersTotal = holdMatchTotal.reduce(function (accumulator, current) {
                    return accumulator + current;
                });

                matchedFiltersTotals.push(countFiltersTotal);
            }
            
            
            const matchedFiltersTotal = matchedFiltersTotals[0];

            
            const sortReviews = areaReviews.sort(function(a, b){return b-a});
            const areaHighestReviewCount = sortReviews[0];
            
            const thisDeductionAmount = [];
            const thisAdditionAmount = [];
            
            if (badBizList.length > 0) {
                for (let i = 0; i < badBizList.length; i++) {
                    
                    
                    let badBizDistance = badBizList[i].distance;
                    let badBizReviewCount = badBizList[i].review_count;

                    let distanceDeduction = (15 - ((badBizDistance / 200) * 15));
                    let popularityDeduction = (badBizReviewCount * .01);
                    if (dealBreakers.badBiz !== null) {
                        if (dealBreakers.badBiz && dealBreakers.badBiz.some(badBiz => badBizList[i].categories && badBizList[i].categories.some(category => ((category.title && category.title) === badBiz.dealBreakValue.value) || ((category.alias && category.alias) === badBiz.dealBreakValue.value)))) {
                            distanceDeduction *= 5;
                            popularityDeduction *= 5;
                        }
                    }

                    thisDeductionAmount.push((distanceDeduction + popularityDeduction));
                }
            } else {
                thisDeductionAmount.push(0);
            }


            if (goodBizList.length > 0) {
                for (let i = 0; i < goodBizList.length; i++) {
                    let goodBizDistance = goodBizList[i].distance;
                    let goodBizReviewCount = goodBizList[i].review_count;

                    let distanceAddition = (1 - ((goodBizDistance / 200) * 1));
                    let popularityAddition = (goodBizReviewCount * .001);

                    thisAdditionAmount.push((distanceAddition + popularityAddition));
                }
                
                if (dealBreakers.goodBiz !== null) {

                    const matchingGoodBizListBusinesses = goodBizList && goodBizList.length && goodBizList.filter(goodListBiz => (dealBreakers.goodBiz && dealBreakers.goodBiz.some(goodBiz => goodListBiz.categories && goodListBiz.categories.some(category => ((category.title && category.title) === goodBiz.dealBreakValue.value) || ((category.alias && category.alias) === goodBiz.dealBreakValue.value)))))
                    
                    if (matchingGoodBizListBusinesses && matchingGoodBizListBusinesses.length) {
                        let matchedDealBreakerGoodBizListLength = matchingGoodBizListBusinesses && matchingGoodBizListBusinesses.length;
                        let dealBreakerGoodBizAddition = matchedDealBreakerGoodBizListLength;
                        thisAdditionAmount.push(dealBreakerGoodBizAddition);
                    } else {
                        thisAdditionAmount.push(-30);
                    }
                }
            } else {
                if (dealBreakers.goodBiz !== null) {
                    let goodBizDealBreakerLength = dealBreakers && dealBreakers.goodBiz && dealBreakers.goodBiz.length;
                    thisAdditionAmount.push((goodBizDealBreakerLength *= -30));
                }
                thisAdditionAmount.push(0);
            }
            
            
            const getBadBizDeductionTotal = [];
            const getGoodBizAdditionTotal = [];

            
            if (thisDeductionAmount.length > 0 && thisAdditionAmount.length > 0) {
                
                let badBizDeductTotal = thisDeductionAmount.reduce(function (accumulator, current) {
                    return accumulator + current;
                });

                getBadBizDeductionTotal.push(badBizDeductTotal);

                let goodBizAdditTotal = thisAdditionAmount.reduce(function (accumulator, current) {
                    return accumulator + current;
                });
                
                getGoodBizAdditionTotal.push(goodBizAdditTotal);
                
            }

            const badBizDeductionTotal = getBadBizDeductionTotal[0];
            const goodBizAdditionTotal = getGoodBizAdditionTotal[0] > 20 ? 20 : getGoodBizAdditionTotal[0];
            
            const allFalseTags = [...matchedTags[0].falseTags, ...matchedTags[1].falseTags, ...matchedTags[2].falseTags, ...matchedTags[3].falseTags];
            const allTrueTags = [...matchedTags[0].trueTags, ...matchedTags[1].trueTags, ...matchedTags[2].trueTags, ...matchedTags[3].trueTags];
            
            const totalCustomScore = parseFloat(Math.floor(goodBizAdditionTotal + matchedFiltersTotal + pricePoints + squareFeetPoints - badBizDeductionTotal));
            
            const thisListingsCustomSearchPoints = {
                totalPoints: totalCustomScore,
                badBiz: {
                    types: customSearchFilters.badBiz,
                    badBizList,
                    badBizDeduction: badBizDeductionTotal
                },
                goodBiz: {
                    types: customSearchFilters.goodBiz,
                    goodBizList,
                    goodBizAddition: goodBizAdditionTotal
                },
                trueTags: allTrueTags,
                falseTags: allFalseTags,
                userBizTypes: {
                    type1: customSearchFilters.userBizType1,
                    type2: customSearchFilters.userBizType2
                },
                listingID: listings[l]._id,
                address: listings[l].listingAddress
            };
            
            customSearchObjects.push(thisListingsCustomSearchPoints);
        }
                
    }

    const filteredByNeighborhood = (listing) => neighborhoods.some(hood => (((hood.long_name && hood.long_name.toLowerCase()) === (listing.neighborhood && listing.neighborhood.toLowerCase())) || (hood.short_name && hood.short_name.toLowerCase()) === (listing.neighborhood && listing.neighborhood.toLowerCase())))

    const filteredByPolygon = (listing) => {
        const { polygonCoords } = this.state;
        const listingCoords = { lat: listing.latitude, lng: listing.longitude };
        const drawnPolygon = new window.google.maps.Polygon({ paths: polygonCoords });

        if (window.google.maps.geometry.poly.containsLocation(listingCoords, drawnPolygon)) {
            return true;
        }
    };
    
    const findCustomSearchPoints = (listing, customObjects) => {
            
        let foundObject = '';
        
        for (let i = 0; i < customObjects.length; i++) {
            if (customObjects[i].listingID === listing._id) {
                foundObject = customObjects[i];
            }
        }
        
        return foundObject.totalPoints;      
    }

    let getTopCustomSearchListings = (listings, customSearchObjects) => {
        
        const topResults = [];

        for (let i = 0; i < listings.length; i++) {
            
            if (listings[i].scores) {
                let customScore = Math.floor(listings[i].scores.fourcastScore + findCustomSearchPoints(listings[i], customSearchObjects));
                const getTopListingsById = {id: listings[i]._id, customScore: customScore};
                topResults.push(getTopListingsById);
            }
        }

        return topResults.sort(function(a, b){return b.customScore-a.customScore}).slice(0,10);
        
    };
    const disclosedPriceListings = listings && listings.filter(listing => listing.pricePerSquareFoot !== -1);

    const topCustomSearchListings = getTopCustomSearchListings(listings, customSearchObjects);
    const topDisclosedCustomSearchListings = getTopCustomSearchListings(disclosedPriceListings, customSearchObjects);


    let customSearchListings = listings.filter((listing) => {
        
        if (this.state.showAll === false) {
            if (this.state.showUndisclosed) {
                for (let i = 0; i < topCustomSearchListings.length; i++) {
                    if ((listing.active === true) && 
                        ((listing.pricePerSquareFoot !== -1) || (this.state.showUndisclosed)) && 
                        (neighborhoods.length ? filteredByNeighborhood(listing) : true) &&
                        (topCustomSearchListings[i].id.includes(listing._id))) {
                        return listing;
                    } else if (this.state.matchResultsComparisonList && this.state.matchResultsComparisonList.length && this.state.matchResultsComparisonList.some(addedListings => addedListings.value === listing._id)) {
                        return listing;
                    }
                }
            } else {
                for (let i = 0; i < topDisclosedCustomSearchListings.length; i++) {
                    if ((listing.active) && 
                        (listing.pricePerSquareFoot !== -1) && 
                        (neighborhoods.length ? filteredByNeighborhood(listing) : true) &&
                        (topDisclosedCustomSearchListings[i].id.includes(listing._id))) {
                        return listing;
                    } else if (this.state.matchResultsComparisonList && this.state.matchResultsComparisonList.length && this.state.matchResultsComparisonList.some(addedListings => addedListings.value === listing._id)) {
                        return listing;
                    }
                }
            }
        } else {
            if ((listing.active === true) && ((listing.pricePerSquareFoot !== -1) || (this.state.showUndisclosed)) && (neighborhoods.length ? filteredByNeighborhood(listing) : true)) {
                return listing;
            }
        }
    });

    let customSelected = this.state.customSearchMode === true ? this.state.selected !== null ? customSearchObjects.filter((listingObject) => listingObject.listingID === this.state.selected._id) : null : null;
    
    let selectedListingPrice = this.state.selected ? (customSearchFilters && customSearchFilters.budgetType === 1) ? (this.state.selected.squareFeet.length > 1) ? [(this.state.selected.pricePerSquareFoot * this.state.selected.squareFeet[0]), (this.state.selected.pricePerSquareFoot * this.state.selected.squareFeet[1])] : [(this.state.selected.pricePerSquareFoot * this.state.selected.squareFeet[0])] : this.state.selected.pricePerSquareFoot : null;
    
    const goodPriceCheck = (selectedListingPrice, customSearchFilters) => {
        const { selected } = this.state;
        if (customSearchFilters.budgetType === 1) {
            if ((selectedListingPrice > 0) || (selectedListingPrice.some(price => price > 0))) {
                if (selectedListingPrice.some(selectedPrice => selectedPrice <= customSearchFilters.price)) {
                    return <span>+ <span style={{ fontSize: 14, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}>${selected.pricePerSquareFoot}</span> / SqFt</span>
                }
            } 
        } else {
            if (selectedListingPrice > 0) {
                if (selectedListingPrice <= customSearchFilters.price) {
                    return <span>+ <span style={{ fontSize: 14, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}>${selected.pricePerSquareFoot}</span> / SqFt</span>
                }
            }
        }
    }

    const badPriceCheck = (selectedListingPrice, customSearchFilters) => {
        const { selected } = this.state;
        if (customSearchFilters.budgetType === 1) {
            if ((selectedListingPrice > 0) || (selectedListingPrice.some(price => price > 0))) {
                if ((selectedListingPrice[0] > customSearchFilters.price) && (selectedListingPrice[selectedListingPrice.length - 1] > customSearchFilters.price)) {
                    return <span>- <span style={{ fontSize: 14, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkred' }}>${selected.pricePerSquareFoot}</span> / SqFt</span>
                }
            } else {
                return 'Undisclosed Price'
            }
        } else {
            if (selectedListingPrice > 0) {
                if (selectedListingPrice > customSearchFilters.price) {
                    return <span>- <span style={{ fontSize: 14, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkred' }}>${selected.pricePerSquareFoot}</span> / SqFt</span>
                }
            } else {
                return 'Undisclosed Price'
            }
        }
    }

    const matchScoreStrength = (listing, customSearchFilters, customSelected) => {
        
        let matchStrength = 50;
        let pricePoints = listing && listing.pricePerSquareFoot === -1 ? 0 : 10;
        let badBizPoints = (customSearchFilters.badBiz.length * 5);
        let goodBizPoints = (customSearchFilters.goodBiz.length * 5);
        let otherFiltersPoints = ((customSelected.trueTags.length * 5) + (customSelected.falseTags.length * 5));
        matchStrength += (pricePoints + badBizPoints + goodBizPoints + otherFiltersPoints);

        let matchStrengthTerm = '';

        if (matchStrength < 60) {
            matchStrengthTerm = 'Very Weak';
        } else if (matchStrength < 70) {
            matchStrengthTerm = 'Weak';
        } else if (matchStrength < 80) {
            matchStrengthTerm = 'Average';
        } else if (matchStrength < 90) {
            matchStrengthTerm = 'Strong';
        } else {
            matchStrengthTerm = 'Very Strong';
        }
        
        return matchStrengthTerm;

    }

    const thisListingsMatchScoreStrength = this.state.customSearchMode && this.state.selected && matchScoreStrength(this.state.selected, customSearchFilters, customSelected[0]);

    const colorCodeScoreStrength = (strength) => {
        if (strength === 'Very Weak') {
            return 'rgb(228, 9, 9)';
        } else if (strength === 'Weak') {
            return 'rgb(246, 79, 6)';
        } else if (strength === 'Average') {
            return 'rgb(237, 134, 12)';
        } else if (strength === 'Strong') {
            return 'rgb(99, 157, 7)';
        } else if (strength === 'Very Strong') {
            return 'rgb(81, 130, 2)';
        } else {
            return 'rgb(81, 130, 2)';
        }
    }




    // REGULAR FILTERING TAGS


    const stateFilterTags = this.state.filterTags;
    
    const findFilterTags = (listing, tags) => {
        let trueTag = 0;

        for (let i = 0; i < tags.length; i++) {
            for (let j = 0; j < listing.filterTags.length; j++) {
                if (listing.filterTags[j].includes(tags[i])) {
                    trueTag += 1;
                }
            }

            for (let k = 0; k < listing.propertyFeatures.length; k++) {
                if (listing.propertyFeatures[k].includes(tags[i])) {
                    trueTag += 1;
                }
            }
        }

        if (trueTag === tags.length) {
            return listing;
        }
    }

    let filteredListings = listings && listings.filter((listing) => {
        if ((this.state.drawPolygonMode === false) && 
            (listing.scores && (listing.scores.fourcastScore && listing.scores.trafficScore && listing.scores.demographicsScore && listing.scores.competitionScore && listing.scores.propertyScore)) && 
            (listing.active === true) && 
            (polygonCoords.length ? filteredByPolygon(listing) : true) &&
            (neighborhoods.length ? filteredByNeighborhood(listing) : true) &&
            (listing.squareFeet.some(sqFt => sqFt >= this.state.minSF)) && 
            (listing.squareFeet.some(sqFt => sqFt <= this.state.maxSF)) && 
            (this.state.minPrice.length > 0 ? listing.pricePerSquareFoot >= this.state.minPrice : true) && 
            (this.state.maxPrice.length > 0 ? listing.pricePerSquareFoot <= this.state.maxPrice : true) && 
            ((listing.pricePerSquareFoot !== -1) || (this.state.showUndisclosed)) && 
            (stateFilterTags.length > 0 ? findFilterTags(listing, stateFilterTags) : listing)) {
                return listing;
        }
    });

    const FilterTagTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
        ))(({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'snow',
            color: 'black',
            fontWeight: 'bold',
            maxWidth: 300,
            fontSize: theme.typography.pxToRem(14),
            padding: 25,
            border: '2px solid rgb(52, 181, 122)',
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
            },
    }));

    const scoreColor = (score) => {
        if (score < 60) {
            return '#B00238';
        } else if ((score >= 60) && (score < 70)) {
            return '#E82F2A';
        } else if ((score >= 70) && (score < 80)) {
            return '#F0B240';
        } else if ((score >= 80) && (score < 90)) {
            return '#6AAA30';
        } else {
            return '#366621';
        }
    };


    
    // GETTING ESTIMATED FOURCAST SCORE FOR AN ENTERED ADDRESS !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    const findListingsNearSearchedPlace = () => {
        const { searchedPlaceSelected } = this.state;
        const { listings } = this.props;
        let filteredListings = listings && listings.filter((listing) => {
            if ((listing.scores && listing.scores.fourcastScore) && (listing.active === true)) {
                return listing;
            }
        });
        const nearbyListings = [];
        const searchedPlaceCoords = { lat: searchedPlaceSelected.lat, lng: searchedPlaceSelected.lng };
        for (let n = 0; n < filteredListings.length; n++) {
            const nearbyListingsCoords = { lat: filteredListings[n].latitude, lng: filteredListings[n].longitude };
            const dist = window.google.maps.geometry.spherical.computeDistanceBetween(searchedPlaceCoords, nearbyListingsCoords)
            if (dist < 250) {
                const nearbyListing = { listingData: filteredListings[n], dist: dist };
                nearbyListings.push(nearbyListing);
            }
            
            if (!nearbyListings.length) {
                if (dist < 500) {
                    let nearbyList = { listingData: filteredListings[n], dist: dist };
                    nearbyListings.push(nearbyList);
                }
            }
        }
        return nearbyListings;
    };

    // ADD COMMA TO "NY 101010" => "NY, 101010"
    const splitStreet = searchedPlace && searchedPlace.length && searchedPlace[0] && searchedPlace[0] && searchedPlace[0].address.split(", ");
    const thisStreetArr = splitStreet && splitStreet.length && splitStreet.slice(0, -1);
    const thisStateZip = splitStreet && splitStreet[splitStreet.length - 1].split(" ");
    const newAddressArr = ((thisStreetArr && thisStreetArr.length) && (thisStateZip && thisStateZip)) && [...thisStreetArr, ...thisStateZip];
    const newStreetAddress = newAddressArr && newAddressArr.length && newAddressArr.join(", ");

    const activeListingHere = searchedPlace && searchedPlace.length && listings && listings.length && listings.filter(listing => listing.listingAddress && listing.listingAddress.includes(newStreetAddress));

    const listingsNearSearchedPlace = (this.state.searchedPlaceSelected && (this.state.searchedPlaceSelected !== null)) ? findListingsNearSearchedPlace() : [];

    const getEstimatedFourcastScore = () => {
        
        if (listingsNearSearchedPlace && listingsNearSearchedPlace.length) {

            const busyRoadStatus = [];
            const orangeRoads = [
                '1st Ave', '2nd Ave', '3rd Ave', 'Lexington Ave', 'Park Ave', 'Madison Ave', '5th Ave', '6th Ave', '7th Ave', 'Broadway', 
                '8th Ave', '9th Ave', '10th Ave', 'South St', 'State St', 'Water St', 'Pearl St', 'West St', 'Saint James Place', 'Bowery',
                'Pike St', 'Allen St', 'Canal St', 'Laight St', 'Beach St', 'Lafayette St', 'Broome St', 'Watts St', 'Varick St',
                'Grand St', 'Norfolk St', 'Kenmare St', 'Delancey St', 'Essex St', 'Houston St', '14th St', 'Cooper Square', 'West 30th St',
                'Dyer Ave', '34th St', '42nd St', '57th St', 'Columbus Ave', 'Amsterdam Ave', 'Adam Clayton Powell Jr. Boulevard',
                '125th St', 'West 155th St', 'West 181st St', 'Nagle Ave', 'Dyckman St', 'West 207th St', 'First Ave', 'Second Ave', 'Third Ave', 'Fifth Ave', 'Sixth Ave', 'Avenue of the Americas', 'Seventh Ave', 'Eighth Ave', 'Ninth Ave', 'Tenth Ave'
            ];
            
            const yellowRoads = [
                'Greenwich St', 'Battery Place', 'Trinity Place', 'Maiden Lane', 'Vesey St', 'Park Row', 'Barclay St', 'Murray St', 'Centre St', 'Frankfort St', 'Chambers St', 
                'Reade St', 'Church St', 'West Broadway', 'Hudson St', 'Madison St', 'East Broadway', 'Division St', 'Forsyth St', 'Montgomery St',
                'Grand St', 'Chrystie St', 'Clarkson St', 'Greenwich Ave', 'West 8th St', 'Ave A', 'Ave B', 'Ave C', 'Ave D',
                'West 15th St', 'E 23rd St', 'East 23rd St', '11th Ave', '36th St', '37th St', '45th St', '46th St', '49th St', '50th St', '53rd St', '54th St',
                'East 59th St', 'Central Park South', 'West 62nd St', 'East 60th St', '65th St', '66th St', 'York Ave', '72nd St', 'West End Ave', '79th St',
                'West 81st St', '86th St', '96th St', '106th St', 'Riverside Drive', 'Cathedral Parkway', 'Central Park North', 'Malcolm X Boulevard', 'Saint Nicholas Ave',
                'Frederick Douglas Boulevard', 'West 145th St', 'West 158th St', 'Fort Washington Ave', 'West 165th St', 'West 178th St', 'West 179th St', 'Fort George', 'Sherman Ave', 'Eleventh Ave'
            ];
            
            const specialSideStreets = [
                'Wall St', 'Bleecker', 'Mott St', 'Mulberry St', 'Hester St', 'Price St', 'Spring St', 'Christopher St',
                'MacDougal St', 'Thompson St', 'West 3rd St', 'West 4th St', 'Elizabeth St'
            ];
            if (orangeRoads.some((roadName) => this.state.searchedPlaceSelected && this.state.searchedPlaceSelected.address && this.state.searchedPlaceSelected.address.toLowerCase().includes(roadName.toLowerCase()))) {
                busyRoadStatus.push(90);
            } else if (yellowRoads.some((roadName) => this.state.searchedPlaceSelected && this.state.searchedPlaceSelected.address && this.state.searchedPlaceSelected.address.toLowerCase().includes(roadName.toLowerCase()))) {
                busyRoadStatus.push(85);
            } else if (specialSideStreets.some((roadName) => this.state.searchedPlaceSelected && this.state.searchedPlaceSelected.address && this.state.searchedPlaceSelected.address.toLowerCase().includes(roadName.toLowerCase()))) {
                busyRoadStatus.push(80);
            } else {
                busyRoadStatus.push(65);
            }
            
            const listingsWithFourcastScores = listingsNearSearchedPlace.filter(listing => {
                if (listing.listingData && listing.listingData.scores && ((listing.listingData.scores.fourcastScore) && (listing.listingData.scores.trafficScore) && (listing.listingData.scores.demographicsScore) && (listing.listingData.scores.competitionScore))) {
                    return listing;
                }
            });
            const distances = listingsWithFourcastScores && listingsWithFourcastScores.map(listing => listing.dist);

            const distWeights = [];
            for (let y = 0; y < distances.length; y++) {
                if (distances[y] <= 250) {
                    let distVal = (1 - (distances[y] / 250));
                    distWeights.push(distVal);
                }
                if ((distances[y] > 250) && (distances[y] <= 500)) {
                    let distVal = (1 - (distances[y] / 500));
                    distWeights.push(distVal);
                }
            }
            const weightSum = distWeights.length && distWeights.reduce(function (accumulator, current) {
                return (accumulator + current);
            });
            
            const estimatedScoreObj = {};
            const fourcastVals = [];
            const trafficVals = [];
            const demoVals = [];
            const compVals = [];
            for (let y = 0; y < listingsWithFourcastScores.length; y++) {
                let thisListingsFourcastScore = listingsWithFourcastScores[y].listingData && listingsWithFourcastScores[y].listingData.scores && listingsWithFourcastScores[y].listingData.scores.fourcastScore && listingsWithFourcastScores[y].listingData.scores.fourcastScore;
                let thisListingsTrafficScore = listingsWithFourcastScores[y].listingData && listingsWithFourcastScores[y].listingData.scores && listingsWithFourcastScores[y].listingData.scores.trafficScore && listingsWithFourcastScores[y].listingData.scores.trafficScore;
                let thisListingsDemoScore = listingsWithFourcastScores[y].listingData && listingsWithFourcastScores[y].listingData.scores && listingsWithFourcastScores[y].listingData.scores.demographicsScore && listingsWithFourcastScores[y].listingData.scores.demographicsScore;
                let thisListingsCompScore = listingsWithFourcastScores[y].listingData && listingsWithFourcastScores[y].listingData.scores && listingsWithFourcastScores[y].listingData.scores.competitionScore && listingsWithFourcastScores[y].listingData.scores.competitionScore;
                let distVal = (listingsWithFourcastScores[y].dist <= 250) ? (1 - (listingsWithFourcastScores[y].dist / 250)) : (1 - (listingsWithFourcastScores[y].dist / 500));
                let distanceWeight = (distVal / weightSum);
                let weightedFourcastScore = (thisListingsFourcastScore * distanceWeight);
                let weightedTrafficScore = (thisListingsTrafficScore * distanceWeight);
                let weightedDemoScore = (thisListingsDemoScore * distanceWeight);
                let weightedCompScore = (thisListingsCompScore * distanceWeight);
                fourcastVals.push(weightedFourcastScore);
                trafficVals.push(weightedTrafficScore);
                demoVals.push(weightedDemoScore);
                compVals.push(weightedCompScore);
            }
            
            const weightedFourcastAverage = fourcastVals.length && fourcastVals.reduce(function (accumulator, current) {
                return (accumulator + current);
            });
            const weightedTrafficAverage = trafficVals.length && trafficVals.reduce(function (accumulator, current) {
                return (accumulator + current);
            });
            const weightedDemoAverage = demoVals.length && demoVals.reduce(function (accumulator, current) {
                return (accumulator + current);
            });
            const weightedCompAverage = compVals.length && compVals.reduce(function (accumulator, current) {
                return (accumulator + current);
            });

            const thisEstimatedFourcastScore = Math.ceil(weightedFourcastAverage);
            const thisEstimatedTrafficScore = Math.ceil(weightedTrafficAverage);
            const thisEstimatedDemoScore = Math.ceil(weightedDemoAverage);
            const thisEstimatedCompScore = Math.ceil(weightedCompAverage);
            const thisEstimatedPropScore = busyRoadStatus && busyRoadStatus.length && busyRoadStatus[0] && busyRoadStatus[0];
            estimatedScoreObj.estimatedFourcastScore = thisEstimatedFourcastScore;
            estimatedScoreObj.estimatedTrafficScore = thisEstimatedTrafficScore;
            estimatedScoreObj.estimatedDemoScore = thisEstimatedDemoScore;
            estimatedScoreObj.estimatedCompScore = thisEstimatedCompScore;
            estimatedScoreObj.estimatedPropScore = thisEstimatedPropScore;
            return estimatedScoreObj;
        }
    }

    const estimatedFourcastScores = (((this.state.searchedPlaceSelected && this.state.searchedPlaceSelected !== null) && listingsNearSearchedPlace.length)) ? getEstimatedFourcastScore() : -1;


    // COMPARE LISTINGS DATA
    const { user } = this.props;
    const usersSavedListingIds = user && user.engagement && user.engagement.savedListings && user.engagement.savedListings;
    const usersSavedListings = (listings && (usersSavedListingIds && (usersSavedListingIds !== null))) ? usersSavedListingIds && usersSavedListingIds.map(savedListing => {
        let thisSavedList = listings && listings.filter(listing => listing._id === savedListing);
        let savedAddy = thisSavedList && thisSavedList.length && thisSavedList[0].listingAddress;
        let savedList = savedAddy ? {label: savedAddy, value: savedListing} : null;
        return (savedList !== null) ? savedList : null;
    }) : null;


    const theSavedListings = user && user.engagement && user.engagement.savedListings && user.engagement.savedListings;
    const listingAddresses = listings && listings.map(listing => {return {label: listing.listingAddress, value: listing._id}});
    const userSavedListings = listings && listings.filter(listing => theSavedListings && theSavedListings.some(savedId => savedId === listing._id)).map(listing => {return {label: listing.listingAddress, value: listing._id}});
    
    const { matchResultsComparisonList, noDataAlert } = this.state;

    const listingOptions = [
        { label: 'Saved', options: userSavedListings },
        { label: "Active Listings", options: listingAddresses },
    ];

    const groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    };
    const groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: 'black',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
        overflowY: 'scroll'
    };
      
    const formatGroupLabel = data => (
        <div style={groupStyles}>
          <span>{data.label}</span>
          <span style={groupBadgeStyles}>{data.options.length}</span>
        </div>
    );

    // SKELETON KEY STUFF

    let skeletonLength = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];

    let fourcastMatchLoading = () => {
        const timer = setTimeout(() => {
            this.setState({ loadFourcastMatch: false })
        }, 10000);
        return () => clearTimeout(timer);
    };

    if (this.state.loadFourcastMatch === true) {
        fourcastMatchLoading();
    }


    return (
      <>
      {!window.google ? skeletonLength.map((n) => <SkeletonListingMap key={n} />) : null}
      {mobile ? <MobileNavbar style={{ zIndex: -50 }} /> : <CustomNavbar />}
      {mobile ? window.google ?
        (<div style={{ width: '70%', height: 75, zIndex: 10, position: 'absolute', top: '0%', left: '45%', transform: 'translate(-45%, -0%)' }}>
            <div style={{ height: '100%', width: '100%' }}>
                <Autocomplete
                onLoad={this.onLoad}
                onPlaceChanged={(place) => {
                    place === undefined ? this.onPlaceChanged(null) : this.onPlaceChanged(place);
                }}
                >
                    <input
                        type="text"
                        placeholder="Search Map or Filter By Neighborhood..."
                        
                        style={{
                            boxSizing: `border-box`,
                            border: `1px solid transparent`,
                            width: `100%`,
                            height: `40px`,
                            padding: `15px 15px`,
                            borderRadius: `3px`,
                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                            fontSize: `14px`,
                            outline: `none`,
                            textOverflow: `ellipses`,
                            zIndex: 10,
                            marginTop: 20
                        }}
                    />
                </Autocomplete>
            </div>
        </div>) : null
        : null}
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={["places"]}
      >
        <div style={(mobile === false) ? ((this.state.customSearchMode === false) && (this.state.filterBoxOpen === true)) ? { display: 'grid', gridTemplateColumns: '3fr 2fr', position: 'relative', height: '100%', width: '100%', minWidth: 'max-content' } : { position: 'relative', height: '100%', width: '100%', minWidth: 'max-content' } : { position: 'relative', height: '100%', width: screenWidth }} fluid>
            <div style={{ width: '100%', height: '100%', position: `${mobile ? 'relative' : null}` }}>
                <GoogleMap
                    mapContainerStyle={this.state.customSearchMode ? fullContainerStyle : fbContainerStyle}
                    onClick={(this.state.drawPolygonMode === true) ? (e) => {
                        const { polygonCoords } = this.state;
                        let drawPolyStart = {lat: e.latLng.lat(), lng: e.latLng.lng()};
                        let coords = [...polygonCoords, drawPolyStart];
                        this.setState({ polygonCoords: [...coords] });
                    } : () => this.setState({ selected: null, searchedPlaceSelected: null })}
                    center={searchedPlace.length ? {lat: (searchedPlace[0].lat + .00075), lng: (searchedPlace[0].lng - .0005)} : this.state.customSearchMode ? fullCenter : fbCenter}
                    zoom={searchedPlace.length ? 17 : this.state.customSearchMode ? 12 : 12}
                    options={options}
                >
                    {(this.state.customSearchMode === false) && (this.state.drawPolygonMode === false) && ((listings && listings.length < 1) && (filteredListings && filteredListings.length < 1)) ? (
                        <Box sx={{ border: '3px solid rgb(52, 181, 122)', borderRadius: 3, backgroundColor: 'white', height: 150, width: 300, position: 'absolute', left: "50%", top: "50%", transform: 'translate(-50%, -50%)' }}>
                            <div style={{ height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                                <CircularProgress size={65} thickness={6.5} />
                            </div>
                            <div style={{ height: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <p style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 10 }}>Loading Listings... Taking Too Long? Refresh Page.</p>
                            </div>
                        </Box>
                    ) : null}
                    
                    {((this.state.loadFourcastMatch === true) || (this.state.customSearchMode === true) && (this.state.drawPolygonMode === false) && ((listings && listings.length < 1) && (customSearchListings && customSearchListings.length < 1))) ? (
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={(this.state.loadFourcastMatch === true)}
                        >                      
                            <Box sx={{ border: '1px solid rgb(52, 181, 122)', borderRadius: 2, backgroundColor: 'white', height: 500, width: 750, position: 'absolute', left: "50%", top: "50%", transform: 'translate(-50%, -50%)' }}>
                                <div style={{ height: '19%', display: 'flex', justifyContent: 'center', alignItems: 'end', color: 'black' }}>
                                    <h5 style={{ color: 'rgb(52, 181, 122)', fontFamily: 'Arial, Helvetica, sans-serif', fontWeight: 500 }}><span>Calculating </span><span><img style={{ marginTop: 5, width: 175 }} alt='listingMapFourcastMatchLogo' src={fourcastMatchLogo} /></span><span> Scores...</span></h5>
                                </div>
                                <div style={{ height: '30%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 14, fontWeight: 500, height: '100%', width: '30%', color: 'black', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ width: '45%', textAlign: 'center' }}>Regular Fourcast Score</div>
                                    </div>
                                    <div style={{ height: '100%', width: '20%' }}>
                                        
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 14, fontWeight: 600, height: '100%', width: '40%', color: 'rgb(52, 181, 122)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ textAlign: 'center' }}>New Score</div>
                                        <div style={{ marginTop: 5 }}><img style={{ width: 125 }} alt='listingMapFourcastMatchLogo' src={fourcastMatchLogo} /></div>
                                    </div>
                                </div>
                                <div style={{ height: '45%', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                    <div style={{ height: '100%', width: '20%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'end' }}>
                                        <div style={{ position: 'relative' }}>
                                            <img style={{ height: 25, width: 25, opacity: .7, position: 'absolute', left: '50%', top: '0%', transform: 'translate(-50%, -50%)' }} src={eighty_four} alt='Regular Fourcast Score' />
                                            <img width="50" height="50" src="https://img.icons8.com/isometric/50/shop.png" alt="shop" />
                                        </div>
                                        <div style={{ position: 'relative' }}>
                                            <img style={{ height: 25, width: 25, opacity: .7, position: 'absolute', left: '50%', top: '0%', transform: 'translate(-50%, -50%)' }} src={seventy_seven} alt='Regular Fourcast Score' />
                                            <img width="50" height="50" src="https://img.icons8.com/isometric/50/shop.png" alt="shop" />
                                        </div>
                                    </div>
                                    <div style={{ width: '50%', height: '100%' }}>
                                        <div style={{ position: 'relative', width: '100%', height: '50%', paddingTop: 50, display: 'flex' }}>
                                            <div style={{ width: '100%' }}>
                                                <LinearProgress />
                                            </div>
                                            <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                                                <img src={checklistWeak} style={{ height: 50, width: 50, marginTop: '-30%', border: '1px solid gray', borderRadius: 3 }} />
                                            </div>
                                        </div>
                                        <div style={{ position: 'relative', width: '100%', height: '50%', paddingTop: 50, display: 'flex' }}>
                                            <div style={{ width: '100%' }}>
                                                <LinearProgress />
                                            </div>
                                            <div style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                                                <img src={checklistStrong} style={{ height: 50, width: 50, marginTop: '-30%', border: '1px solid gray', borderRadius: 3 }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ height: '100%', width: '10%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'start' }}>
                                        <div style={{ position: 'relative' }}>
                                            <img style={{ height: 35, width: 35, position: 'absolute', left: '50%', top: '0%', transform: 'translate(-50%, -70%)' }} src={sixty_one} alt='Regular Fourcast Score' />
                                            <img width="75" height="75" src="https://img.icons8.com/isometric/75/shop.png" alt="shop" />
                                        </div>
                                        <div style={{ position: 'relative' }}>
                                            <img style={{ height: 35, width: 35, position: 'absolute', left: '50%', top: '0%', transform: 'translate(-50%, -70%)' }} src={ninety_six} alt='Regular Fourcast Score' />
                                            <img width="75" height="75" src="https://img.icons8.com/isometric/75/shop.png" alt="shop" />
                                        </div>
                                    </div>
                                    <div style={{ height: '100%', width: '20%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'start' }}>
                                        <div style={{ color: 'black', fontWeight: 700, color: 'rgb(228, 114, 103)', fontFamily: 'Arial, Helvetica, sans-serif', }}>
                                            Weak Match...
                                        </div>
                                        <div style={{ color: 'black', fontWeight: 700, color: 'rgb(52, 181, 122)', fontFamily: 'Arial, Helvetica, sans-serif', }}>
                                            Strong Match!
                                        </div>
                                    </div>
                                </div>
                                <div className="fourcastMatchCalculatingExample1" style={{ height: 125, width: '100%', backgroundColor: 'white', position: 'absolute', right: '0%', bottom: '30%' }}></div>
                                <div className="fourcastMatchCalculatingExample2" style={{ height: 100, width: '100%', backgroundColor: 'white', position: 'absolute', right: '0%', bottom: '10%' }}></div>
                                <div style={{ height: '5%', display: 'flex', justifyContent: 'left', alignItems: 'end', fontSize: 8, color: 'gray' }}>
                                    <a  href="https://icons8.com/icon/hhP8h7lp5P1x/shop">Shop</a> icon by <a href="https://icons8.com">Icons8</a>
                                </div>
                            </Box>
                        </Backdrop>
                    ) : null}

                    {mobile ? (
                        <div className='mobileModeMapTopFiltersContainer'>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {((this.state.customSearchMode === false) && (this.state.joyRide === true)) ? (
                                    <>
                                        <Joyride run={this.state.joyRide} page={'mapIntroListing'} timeout={500} setJoyRide={this.setJoyRide} />
                                        <img className='mapIntroListing' src={ninety_one} alt="map intro joyride listing" style={{ height: 40, width: 40, position: 'absolute', left: "30%", top: "50%" }} />
                                    </>
                                ) : this.state.customSearchMode === false && this.state.joyRide === false ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                        <Button
                                            variant="contained"
                                            className="whatIsThisButton"
                                            onClick={(e) => this.setState({ joyRide: true })}
                                            style={{ backgroundColor: 'white', height: 50, width: '95%', padding: '2%', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', borderRadius: '5px', outline: 'none' }}
                                        >
                                            <div style={{ height: '100%', width: '100%', display: 'flex' }}>
                                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                                    <img src={ninety_one} alt="listing marker in how does this work box" style={{ height: 30, width: 30 }} />
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', textAlign: 'center' }}>
                                                    <div style={{ fontSize: 12, fontWeight: 'bolder' }}>What is this?</div>
                                                </div>
                                            </div>
                                        </Button>
                                    </div>
                                ) : null}
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Button
                                    variant="contained"
                                    startIcon={<MdDraw style={{ color: 'white' }} />}
                                    className="drawPolygonBtn"
                                    onClick={(e) => this.setState({ drawPolygonMode: true })}
                                    style={{ height: 50, width: '95%', padding: '10px 20px', fontSize: 12, backgroundColor: 'rgb(25, 118, 210)', textAlign: 'center', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', borderRadius: '2px', outline: 'none' }}
                                >
                                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', letterSpacing: 1  }}>Draw</div>
                                    </div>
                                </Button>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                                <MobileMapFiltersModal values={stateFilterValues} setMobileFilterModalState={this.setMobileFilterModalState} />
                            </div>
                        </div>
                    ) : null}

                    {((this.state.customSearchMode === false) && (this.state.joyRide === true) && (mobile === false)) ? (
                        <>
                            <Joyride run={this.state.joyRide} page={'mapIntroListing'} timeout={500} setJoyRide={this.setJoyRide} />
                            <img className='mapIntroListing' src={ninety_one} alt="map intro joyride listing" style={{ height: 40, width: 40, position: 'absolute', left: "30%", top: "50%" }} />
                        </>
                    ) : ((this.state.customSearchMode === false) && (this.state.joyRide === false) && (mobile === false)) ? (
                        <div style={{ position: 'absolute', top: '3%', left: '2%' }}>
                            <Button
                                variant="contained"
                                className="whatIsThisButton"
                                onClick={(e) => this.setState({ joyRide: true })}
                                style={{ backgroundColor: 'white', height: 45, width: 190, textAlign: 'center', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', borderRadius: '5px', outline: 'none' }}
                            >
                                <div style={{ height: '100%', width: '100%', display: 'grid', gridTemplateColumns: '1fr 2fr' }}>
                                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                        <img src={ninety_one} alt="listing marker in how does this work box" style={{ height: 40, width: 40 }} />
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ fontSize: 12, fontWeight: 'bolder' }}>What is this?</div>
                                    </div>
                                </div>
                            </Button>
                        </div>
                    ) : null}

                    {(this.state.drawPolygonMode === false) ? (!this.state.polygonCoords.length) ? (
                        <div style={{ position: 'absolute', top: `${this.state.filterBoxOpen === true ? '10%' : '10%'}`, right: `${this.state.filterBoxOpen === true ? '12%' : '50%'}`, marginRight: `${this.state.filterBoxOpen === true ? '-6%' : '-25%'}`, display: 'flex', justifyContent: 'right', alignItems: 'center'  }}>
                            <Button
                                variant="contained"
                                startIcon={<MdDraw style={{ color: 'white' }} />}
                                className="drawPolygonBtn"
                                onClick={(e) => this.setState({ drawPolygonMode: true })}
                                style={{ padding: '10px 20px', fontSize: 12, backgroundColor: 'rgb(25, 118, 210)', textAlign: 'center', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', borderRadius: '2px', outline: 'none', display: `${mobile ? 'none' : null}` }}
                            >
                                <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', letterSpacing: 1  }}>Draw</div>
                                </div>
                            </Button>
                        </div>
                    ) : (
                        <div style={{ position: 'absolute', top: `${this.state.filterBoxOpen === true ? '10%' : '3%'}`, right: `${this.state.filterBoxOpen === true ? '12%' : '35%'}`, marginRight: `${this.state.filterBoxOpen === true ? '-6%' : '-17.5%'}`, display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <Button
                                variant="contained"
                                className="drawPolygonBtn"
                                onClick={(e) => this.setState({ drawPolygonMode: false, polygonCoords: [] })}
                                style={{ backgroundColor: 'rgb(228, 114, 103)', marginRight: 10, padding: '10px 20px', textAlign: 'center', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', borderRadius: '2px', outline: 'none' }}
                            >
                                <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>Remove Boundary</div>
                                </div>
                            </Button>
                        </div>
                    ) : (
                        <div style={{ position: 'absolute', top: `${this.state.filterBoxOpen === true ? '10%' : '3%'}`, right: `${this.state.filterBoxOpen === true ? '12%' : '25%'}`, marginRight: `${this.state.filterBoxOpen === true ? '-6%' : '-12.5%'}`, display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <div>
                                <Button
                                    variant="contained"
                                    startIcon={<ImCheckmark style={{ color: 'white' }} />}
                                    className="drawPolygonBtn"
                                    disabled={this.state.polygonCoords.length < 3}
                                    onClick={(e) => this.setState({ drawPolygonMode: false })}
                                    style={{ backgroundColor: `${this.state.polygonCoords.length < 3 ? 'gray' : 'rgb(25, 118, 210)'}`, marginRight: 25, fontSize: 12, padding: '10px 20px', textAlign: 'center', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', borderRadius: '2px', outline: 'none' }}
                                >
                                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', letterSpacing: 1 }}>Confirm</div>
                                    </div>
                                </Button>
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    startIcon={<ImCross style={{ color: 'white' }} />}
                                    className="drawPolygonBtn"
                                    onClick={(e) => this.setState({ drawPolygonMode: false, polygonCoords: [] })}
                                    style={{ backgroundColor: 'rgb(228, 114, 103)', marginRight: 10, fontSize: 12, padding: '10px 20px', textAlign: 'center', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', borderRadius: '2px', outline: 'none' }}
                                >
                                    <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>Cancel</div>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    )}
                    
                        <Polygon
                            onLoad={this.polygonOnLoad}
                            paths={polygonCoords}
                            options={polygonOptions}
                            onMouseUp={(e) => {
                                let currentPolygon = this.polygon;
                                let pathss = currentPolygon.getPath();
                                let newPaths = [];
                                let newCoords = pathss.forEach(path => newPaths.push({lat: path.lat(), lng: path.lng()}))
                                this.setState({ polygonCoords: newPaths });
                            }}
                        />

                        {(mobile || (this.state.customSearchMode === true)) ? null : (
                        <div style={{ position: 'absolute', top: '12%', left: '2%' }}>
                            <div>
                                <CompareListingsModal compareListings={compareListings} savedListings={usersSavedListings} />
                            </div>
                            <div style={{ marginTop: 25 }}>
                                <FourcastMatchModal />
                            </div>
                        </div>)}
                    
                        {mobile ? null : (<Autocomplete
                            onLoad={this.onLoad}
                            onPlaceChanged={(place) => {
                                place === undefined ? this.onPlaceChanged(null) : this.onPlaceChanged(place);
                            }}
                        >
                            <input
                                type="text"
                                placeholder="Search Map or Filter By Neighborhood..."
                                
                                style={{
                                    boxSizing: `border-box`,
                                    border: `1px solid transparent`,
                                    width: `600px`,
                                    height: `40px`,
                                    padding: `15px 15px`,
                                    borderRadius: `3px`,
                                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                    fontSize: `14px`,
                                    outline: `none`,
                                    textOverflow: `ellipses`,
                                    position: "absolute",
                                    top: '3%',
                                    left: '40%',
                                    transform: 'translate(-20%, 0%)',
                                    zIndex: 10,
                                    display: `${mobile ? 'none' : null}`
                                }}
                            />
                        </Autocomplete>)}
                        {mobile ? null : (<div style={{ zIndex: 10, overflowX: 'scroll', display: (neighborhoods.length || searchedPlace.length) ? 'flex' : 'none', justifyContent: 'left', alignItems: 'center', width: `300px`, height: `40px`, padding: `15px 15px`, borderRadius: `3px`, fontSize: `14px`, outline: `none`, textOverflow: `ellipses`, marginLeft: 250, position: "absolute", top: '3%', left: '40%', transform: 'translate(-20%, 0%)' }}>
                            <Stack className="filteredNeighborhoodChips" style={{ overflowX: 'scroll' }} direction="row" spacing={1}>
                                {neighborhoods.map(neighb => (
                                    <Chip
                                        label={neighb.short_name}
                                        style={{ borderRadius: '2rem', backgroundColor: 'whitesmoke' }}
                                        key={neighb.short_name}
                                        onDelete={(e) => {
                                            const value = neighb;
                                            let updatedNeighbs = neighborhoods.filter((thisNeighb) => thisNeighb !== value);
                                            this.setState({ neighborhoods: updatedNeighbs });
                                        }} 
                                    />
                                ))}
                                {searchedPlace.map(place => (
                                    <Chip
                                        label={place.address}
                                        style={{ borderRadius: '2rem', backgroundColor: 'whitesmoke', maxWidth: 100 }}
                                        key={place.address}
                                        onDelete={(e) => {
                                            const value = place;
                                            let updatedPlace = searchedPlace.filter((thisPlace) => thisPlace !== value);
                                            this.setState({ searchedPlace: updatedPlace });
                                        }} 
                                    />
                                ))}
                            </Stack>
                        </div>)}
                        {(searchedPlace && searchedPlace.length) ? (activeListingHere && activeListingHere.length) ? (
                        <>
                            <Marker
                                key={searchedPlace[0].address} 
                                position={{lat: searchedPlace[0].lat, lng: searchedPlace[0].lng}}
                                onClick={() => {
                                    this.setState({ selected: activeListingHere[0] });
                                }}
                                icon={{
                                    url: `https://marker.nanoka.fr/map_cluster-ff4fff-56.svg`,
                                    scaledSize: new window.google.maps.Size(60, 60),
                                    origin: new window.google.maps.Point(0, 0),
                                    anchor: new window.google.maps.Point(30, 20)
                                }}
                                label={activeListingHere && activeListingHere.length ? activeListingHere[0].scores && activeListingHere[0].scores.fourcastScore.toString() : null}
                                style={{ zIndex: 10001 }}
                            />
                        </>) : (
                        <>
                            <Marker
                                key={searchedPlace[0].address} 
                                position={{lat: searchedPlace[0].lat, lng: searchedPlace[0].lng}}
                                onClick={() => {
                                    this.setState({ searchedPlaceSelected: searchedPlace[0] })
                                }}
                                icon={{
                                    scaledSize: new window.google.maps.Size(30, 30),
                                    origin: new window.google.maps.Point(0, 0),
                                    anchor: new window.google.maps.Point(15, 5)
                                }}
                                style={{ zIndex: 10001 }}
                            />
                        </>) : null}
                    
                    {this.state.customSearchMode === false ? 
                    
                    (<MarkerClusterer averageCenter styles={clusterStyles} minimumClusterSize={40}>
                        {(clusterer) => 
                            window.google && filteredListings.map((listing) => (
                                <Marker
                                    key={listing._id} 
                                    position={{lat: listing.latitude, lng: listing.longitude}}
                                    clusterer={clusterer}
                                    onClick={() => {
                                        this.setState({ selected: listing });
                                    }}
                                    icon={{
                                        url: this.state.compareListings.some(compareList => compareList && compareList.value === listing._id) ? `https://marker.nanoka.fr/map_cluster-f700ff-56.svg` : scoreIcon(listing.scores.fourcastScore),
                                        scaledSize: this.state.compareListings.some(compareList => compareList && compareList.value === listing._id) ? new window.google.maps.Size(40, 40) : new window.google.maps.Size(30, 30),
                                        origin: new window.google.maps.Point(0, 0),
                                        anchor: new window.google.maps.Point(15, 5),
                                    }}
                                    label={this.state.compareListings.some(compareList => compareList && compareList.value === listing._id) ? listing.scores.fourcastScore.toString() : null}
                                />
                            ))
                        }
                    </MarkerClusterer>)
                    
                    : 
                    
                    (<MarkerClusterer averageCenter styles={clusterStyles} minimumClusterSize={40}>
                        {(clusterer) =>
                            window.google && customSearchListings.map((listing) => (
                                // this checks to see if the listing is set to be compared in the Fourcast Match Comparison Modal
                                this.state.matchResultsComparisonList && this.state.matchResultsComparisonList.length && this.state.matchResultsComparisonList.some(addedListing => addedListing.value === listing._id) ?
                                (<Marker
                                    key={listing._id} 
                                    position={{lat: listing.latitude, lng: listing.longitude}}
                                    onClick={() => {
                                        this.setState({ selected: listing });
                                    }}
                                    icon={{
                                        url: `https://marker.nanoka.fr/map_cluster-ff4fff-56.svg`,
                                        scaledSize: new window.google.maps.Size(60, 60),
                                        origin: new window.google.maps.Point(0, 0),
                                        anchor: new window.google.maps.Point(30, 20)
                                    }}
                                    label={listing && listing.scores ? ((listing.scores.fourcastScore + findCustomSearchPoints(listing, customSearchObjects) < 50) ? 50 : (listing.scores.fourcastScore + findCustomSearchPoints(listing, customSearchObjects) > 99) ? 99 : (listing.scores.fourcastScore + findCustomSearchPoints(listing, customSearchObjects))).toString() : null}
                                    style={{ zIndex: 999999 }}
                                />)
                                
                                : (<Marker
                                    key={listing._id} 
                                    position={{lat: listing.latitude, lng: listing.longitude}}
                                    clusterer={clusterer}
                                    onClick={() => {
                                        this.setState({ selected: listing });
                                    }}
                                    icon={{
                                        url: customSearchScoreIcon(listing.scores.fourcastScore, findCustomSearchPoints(listing, customSearchObjects)),
                                        scaledSize: new window.google.maps.Size(30, 30),
                                        origin: new window.google.maps.Point(0, 0),
                                        anchor: new window.google.maps.Point(15, 5)
                                    }}
                                />)
                            ))
                        }
                    </MarkerClusterer>)}

                    {this.state.selected ? (
                    <InfoWindow 
                        position={{ lat: this.state.selected.latitude, lng: this.state.selected.longitude }}
                        onCloseClick={() => {
                            this.setState({ selected: null });
                        }}
                    >
                        {this.state.customSearchMode === false ?
                        (<div className='infoWindow'>
                            <h6 style={{ marginBottom: 15, fontFamily: 'sans-serif', width: 250, wordSpacing: 1 }}>{this.state.selected.listingAddress}</h6>
                            <div className='infoWindowScore'><span style={{ fontWeight: 'bold', fontSize: 20, color: scoreColor(this.state.selected.scores.trafficScore), fontFamily: 'sans-serif' }}>{this.state.selected.scores.trafficScore}</span><span style={{ marginLeft: 10, fontWeight: 700 }}>Traffic Score</span></div>
                            <div className='infoWindowScore'><span style={{ fontWeight: 'bold', fontSize: 20, color: scoreColor(this.state.selected.scores.demographicsScore), fontFamily: 'sans-serif' }}>{this.state.selected.scores.demographicsScore}</span><span style={{ marginLeft: 10, fontWeight: 700 }}>Demographics Score</span></div>
                            <div className='infoWindowScore'><span style={{ fontWeight: 'bold', fontSize: 20, color: scoreColor(this.state.selected.scores.competitionScore), fontFamily: 'sans-serif' }}>{this.state.selected.scores.competitionScore}</span><span style={{ marginLeft: 10, fontWeight: 700 }}>Competition Score</span></div>
                            <div className='infoWindowScore' id='bottomInfoScore'><span style={{ fontWeight: 'bold', fontSize: 20, color: scoreColor(this.state.selected.scores.propertyScore), fontFamily: 'sans-serif' }}>{this.state.selected.scores.propertyScore}</span><span style={{ marginLeft: 10, fontWeight: 700 }}>Property Score</span></div>
                            <div><span style={{ fontSize: 14, fontFamily: 'sans-serif' }}>Square Footage:</span><span style={{ fontSize: 16, letterSpacing: 1, fontWeight: 'bold', marginLeft: 7, fontFamily: 'sans-serif' }}>{this.state.selected.squareFeet.length > 1 ? `${this.state.selected.squareFeet[0]} - ${this.state.selected.squareFeet[1]}` : `${this.state.selected.squareFeet}`}</span></div>
                            <div style={{ marginTop: 10, marginBottom: 15 }}><span style={{ fontSize: 14, fontFamily: 'sans-serif' }}>Price per Sqft:</span><span style={{ fontSize: 16, fontWeight: 'bold', marginLeft: 7, fontFamily: 'sans-serif' }}>{this.state.selected.pricePerSquareFoot !== -1 ? `$${this.state.selected.pricePerSquareFoot} / yr` : 'Undisclosed'}</span></div>
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', paddingBottom: 10 }}>
                                <div style={{ display: 'flex', flex: 1, justifyContent: 'left', alignItems: 'center' }}>
                                    <Link to={`/listings/${this.state.selected._id}`} target="_blank" selected={this.state.selected} listings={listings} style={{ textDecoration: 'none' }}>
                                        <Button 
                                            className="seeMore-btn" 
                                            size="medium"
                                            style={{ width: 125, backgroundColor: 'rgb(43, 150, 102)', color: 'white', outline: 'none', boxShadow: '1px 2px 4px 0 rgba(0, 0, 0, 0.2), 1px 3px 10px 0 rgba(0, 0, 0, 0.19)' }}
                                        >
                                            Details
                                        </Button>
                                    </Link>
                                </div>
                                <div style={{ display: 'flex', flex: 1, justifyContent: 'right', alignItems: 'center' }}>
                                    <Button 
                                        style={{ width: 105, backgroundColor: 'rgb(250, 204, 68)', outline: 'none' }}
                                        onClick={(e) => {
                                            const thisList = {label: this.state.selected.listingAddress, value: this.state.selected._id};
                                            const checked = (this.state.compareListings.some(selectListing => selectListing.value === this.state.selected._id));
                                            let listingsToBeCompared = checked ? this.state.compareListings.filter((list) => list.value !== thisList.value) : [...this.state.compareListings, thisList];
                                            this.setState({ compareListings: listingsToBeCompared });
                                        }}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                            <div style={{ backgroundColor: 'rgb(250, 204, 68)', borderRadius: '50%', height: 45, width: 45, position: 'absolute', left: '0%', top: '50%', transform: 'translate(-100%, -50%)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{(compareListings && compareListings.some((list) => list.label === this.state.selected.listingAddress)) ? <ImCheckmark color='white' style={{ width: 20, height: 20 }} /> : <FaChartBar color='white' style={{ height: 25, width: 25 }} />}</div>
                                            <div style={{ width: '100%', textAlign: 'right', color: 'white', textShadow: '1px 1px 1px darkgray' }}>Compare</div>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>)

                        :

                        (
                        <div style={{ height: 225, width: 150, display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
                            <div style={{ width: '100%', height: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 16, fontWeight: 700 }}>{this.state.selected !== null ? this.state.selected.listingAddress.split(",", 1) : null}</div>
                            <div style={{ width: '100%', height: '50%', textAlign: 'center', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                <div style={{ width: '45%' }}>
                                    <div style={{ fontSize: 12 }} className='customSearchInfoBoxDesc'>Regular Score</div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img className='customSearchInfoBoxScore' src={scoreIcon(this.state.selected.scores.fourcastScore)} alt='' />
                                    </div>
                                </div>
                                <div style={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <MdOutlineDoubleArrow style={{ height: 25, width: 25, color: "rgb(52, 181, 122)" }} />
                                </div>
                                <div style={{ width: '45%' }}>
                                    <div style={{ fontSize: 12, color: 'rgb(52, 181, 122)' }} className='customSearchInfoBoxDesc'>Fourcast Match</div>
                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <img className='customSearchInfoBoxScore' src={customSearchScoreIcon(this.state.selected.scores.fourcastScore, findCustomSearchPoints(this.state.selected, customSearchObjects))} alt='' />
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: '100%', height: '25%', display: 'flex', justifyContent: 'right', alignItems: 'center', paddingRight: 10 }}>
                                <Button 
                                    style={{ width: 105, backgroundColor: 'rgb(250, 204, 68)', outline: 'none' }}
                                    onClick={(e) => {
                                        const thisList = {label: this.state.selected.listingAddress, value: this.state.selected._id};
                                        const checked = (this.state.matchResultsComparisonList.some(selectListing => selectListing.value === this.state.selected._id));
                                        let listingsToBeCompared = checked ? this.state.matchResultsComparisonList.filter((list) => list.value !== thisList.value) : [...this.state.matchResultsComparisonList, thisList];
                                        this.setState({ matchResultsComparisonList: listingsToBeCompared });
                                    }}
                                >
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                                        <div style={{ backgroundColor: 'rgb(250, 204, 68)', borderRadius: '50%', height: 45, width: 45, position: 'absolute', left: '0%', top: '50%', transform: 'translate(-100%, -50%)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{(matchResultsComparisonList && matchResultsComparisonList.some((list) => list.label === this.state.selected.listingAddress)) ? <ImCheckmark color='white' style={{ width: 20, height: 20 }} /> : <FaChartBar color='white' style={{ height: 25, width: 25 }} />}</div>
                                        <div style={{ width: '100%', textAlign: 'right', color: 'white', textShadow: '1px 1px 1px darkgray' }}>Compare</div>
                                    </div>
                                </Button>
                            </div>
                        </div>
                        )}
                    </InfoWindow>) : null}

                    {this.state.searchedPlace.length && this.state.searchedPlaceSelected ? (
                    <InfoWindow 
                        position={{ lat: this.state.searchedPlaceSelected.lat, lng: this.state.searchedPlaceSelected.lng }}
                        onCloseClick={() => {
                            this.setState({ searchedPlaceSelected: null });
                        }}
                    >
                        <div className='selectedAddressInfoBox'>
                            <div style={{ height: '20%', width: '100%', paddingTop: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <h5 style={{ fontFamily: 'sans-serif', height: '25%', width: '100%', wordSpacing: 1, textAlign: 'center' }}>{searchedPlace.length && searchedPlace[0].address}</h5>
                            </div>
                            <div style={{ height: '25%', width: '100%', display: 'grid', gridTemplateColumns: '2fr 3fr 1fr' }}>
                                <div style={{ height: '90%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'end', backgroundColor: 'white', paddingBottom: 2.5 }}>
                                    <h6 style={{ textAlign: 'center' }}>Estimated Fourcast Score</h6>
                                </div>
                                <div style={{ height: '90%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'end', backgroundColor: 'white', paddingBottom: 10 }}>
                                    <div> _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ </div>
                                </div>
                                <div style={{ height: '90%', width: '100%', display: 'flex', justifyContent: 'left', alignItems: 'end', backgroundColor: 'white', paddingBottom: 10 }}>
                                    {((this.state.searchedPlaceSelected !== null) && (estimatedFourcastScores && (estimatedFourcastScores.estimatedFourcastScore > 0))) ? <img style={{ height: 45, width: 45 }} src={scoreIcon(estimatedFourcastScores && estimatedFourcastScores.estimatedFourcastScore)} alt='Estimated Fourcast Score' /> : 'Estimate Not Available'}
                                </div>
                            </div>
                            <div style={{ paddingTop: 5, height: '20%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Link to={{
                                        pathname: `/scores/searched-address/${searchedPlace[0].address}`,
                                        state: {
                                            nearbyListings: listingsNearSearchedPlace,
                                            selected: searchedPlace[0],
                                            estimatedFourcastScores: estimatedFourcastScores
                                        }
                                    }}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <Button 
                                        className="seeMore-btn"
                                        variant='outlined'
                                        size="small"
                                        style={{ width: 100, fontSize: 12, color: 'white', backgroundColor: 'rgb(43, 150, 102)', outline: 'none' }}
                                    >
                                        See More
                                    </Button>
                                </Link>
                            </div>
                            <Divider textAlign="left">Nearest Listings</Divider>
                            <div style={{ paddingTop: 5, height: '35%', width: '100%', display: 'flex', justifyContent: 'left', alignItems: 'center', flexWrap: 'wrap' }}>
                                {listingsNearSearchedPlace && listingsNearSearchedPlace.length ? listingsNearSearchedPlace.sort(function(a, b){return a.dist-b.dist}).map(listing => 
                                    (<Link to={`/listings/${listing.listingData._id}`} selected={listing.listingData} listings={listings} style={{ textDecoration: 'none' }}>
                                        <div style={{ border: '1px solid lightgray', borderRadius: 5, backgroundColor: 'whitesmoke', height: 60, width: 115, margin: '5px 5px' }}>
                                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 3fr', height: '50%' }}>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                    {(listing && listing.listingData && listing.listingData.scores && listing.listingData.scores.fourcastScore && listing.listingData.scores.fourcastScore) ? <img style={{ height: 25, width: 25 }} src={scoreIcon(listing.listingData.scores.fourcastScore)} alt='Nearby Listing Fourcast Score' /> : 'N/A'}
                                                </div>
                                                <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', paddingRight: 2 }}>
                                                    <div>
                                                        {listing && listing.dist && `${(listing.dist * 3.2808).toFixed(0)} ft away`}
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ fontWeight: 'bold', color: 'black', display: 'flex', alignItems: 'end', height: '50%', paddingLeft: 2, overflow: 'hidden' }}>
                                                {listing && listing.listingData && listing.listingData.listingAddress && listing.listingData.listingAddress.slice(0, -21)}
                                            </div>
                                        </div>
                                    </Link>)                                
                                ) : null}
                            </div>
                        </div>
                    </InfoWindow>) : null}
                </GoogleMap>

                {window.google && this.state.customSearchMode === true ? 
                    <>
                    {this.state.joyRide === true ? <Joyride run={this.state.joyRide} page={'customMap'} timeout={5000} setJoyRide={this.setJoyRide} /> : null}
                    <div style={{ position: 'absolute', top: '3%', left: '2%', backgroundColor: 'white', height: 700, width: 325, textAlign: 'center', border: '1px solid rgb(52, 181, 122)', borderRadius: '5px' }}>
                        <div className="customMapShowUndisclosedBtn" style={{ marginTop: 25, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ display: 'flex', justifyContent: 'right' }}>
                                <Checkbox 
                                    className='showUndisclosedBtn'
                                    size="large"
                                    color='primary'
                                    checked={this.state.showUndisclosed}
                                    onClick={(e) => this.setState({ showUndisclosed: !this.state.showUndisclosed })}
                                />
                            </div>
                            <div style={{ fontSize: 17, fontWeight: 'bold', textAlign: 'left' }}>
                                Include Undisclosed Rates
                            </div>
                        </div>
                        <div className="customMapShowAllBtn" style={{ marginTop: 25 }}>
                            <Button style={{ fontSize: 14, color: 'white', fontWeight: 'bold', border: '1px solid black', backgroundColor: 'rgb(52, 181, 122)', width: 200, outline: 'none' }} size='large' onClick={(e) => this.setState({ showAll: !this.state.showAll })}>{this.state.showAll === false ? 'Show All Listings' : 'Show Top 10'}</Button>
                        </div>
                        <Divider variant='middle' style={{ marginTop: 35 }}></Divider>
                        <div style={{ width: '100%', marginTop: 35, textAlign: 'center' }}>
                            {(this.state.matchResultsComparisonList && this.state.matchResultsComparisonList.length && (this.state.matchResultsComparisonList.length > 8)) ? (<div style={{ width: '100%', fontSize: 10, color: 'darkred', fontWeight: 700 }}>*Compare 2-8 Listings</div>) : null}
                            <div style={{ width: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>                            
                                <><Badge color="warning" badgeContent={matchResultsComparisonList && matchResultsComparisonList.length} max={10}>
                                    <Button
                                        variant="contained"
                                        className="compareListingsMapButton"
                                        disabled={(this.state.matchResultsComparisonList && this.state.matchResultsComparisonList.length && (this.state.matchResultsComparisonList.length > 1) && (this.state.matchResultsComparisonList.length <= 8)) ? false : true}
                                        id={(this.state.compareListingsChange === true) ? "matchCompareBtnShake" : null}
                                        onAnimationEnd={() => this.setState({ compareListingsChange: false })}
                                        onClick={(e) => {
                                            const { matchResultsComparisonList } = this.state;
                                            this.setFourcastMatchComparisonListings(matchResultsComparisonList)
                                            this.setState({ fourcastMatchResultComparisonModal: true });
                                        }}
                                        style={{ backgroundColor: 'rgb(250, 204, 68)', height: 45, width: 250, textAlign: 'center', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', borderRadius: '5px', outline: 'none' }}
                                    >
                                        <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            Compare Match Results
                                        </div>
                                    </Button>
                                </Badge></>
                                <FourcastMatchResultComparisonModal fourcastMatchResultComparisonModal={this.state.fourcastMatchResultComparisonModal} customSearchFilters={customSearchFilters} listings={this.state.fourcastMatchComparisonListings} customSearchObjects={customSearchObjects} setToggle={this.toggleFourcastMatchComparisonModal} findCustomSearchPoints={findCustomSearchPoints} scoreIcon={scoreIcon} getTopCustomSearchListings={getTopCustomSearchListings} customSearchScoreIcon={customSearchScoreIcon} />
                            </div>
                        </div>
                        <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 25, height: 100, width: '100%' }}>
                            <Select 
                                options={listingOptions && listingOptions.length && listingOptions}
                                styles={{
                                    control: (baseStyles, state) => ({
                                    ...baseStyles,
                                    width: '95%',
                                    margin: 'auto',
                                    minHeight: 75,
                                    maxHeight: 100,
                                    overflowY: 'scroll',
                                    border: (!matchResultsComparisonList.length && (noDataAlert === true)) ? '2px solid darkred' : null
                                    }),
                                }}
                                formatGroupLabel={formatGroupLabel}
                                isMulti={true}
                                placeholder="Select Listings..."
                                onChange={(values) => {
                                    this.setState({ matchResultsComparisonList: values });
                                }}
                                value={matchResultsComparisonList}
                            />
                        </div>
                        <div style={{ marginTop: 10, width: '100%', height: 315, marginLeft: 'auto', marginRight: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', overflowY: 'scroll' }}>
                            <div style={{ overflowY: 'scroll', height: 315 }}>
                                {matchResultsComparisonList.length ? matchResultsComparisonList.map(selectedListing => 
                                (<List>
                                    <ListItem><ListItemIcon>
                                        <MdOutlineCheckCircle style={{ width: 25, height: 25, color: 'darkgreen' }} />
                                    </ListItemIcon>{selectedListing.label}</ListItem>
                                </List>)) 
                                : (<ListItem>Select up to eight (8) listings to compare</ListItem>)}
                            </div>
                        </div>
                    </div>
                    <div className="customMapMatchScoreStrength" style={{ position: 'absolute', top: '3%', right: '1%', backgroundColor: 'white', height: 600, width: 350, textAlign: 'center', border: '1px solid rgb(52, 181, 122)', borderRadius: '5px' }}>
                        <div style={{ height: '15%' }}>
                            <img style={{ marginTop: 10, width: 100 }} alt='listingMapFourcastMatchLogo' src={fourcastMatchLogo} />
                            <h5 style={{ marginTop: 20, fontFamily: 'sans-serif', fontWeight: 700 }}>{this.state.selected !== null ? this.state.selected.listingAddress.split(",", 1) : null}</h5>
                        </div>
                        {this.state.selected !== null ? (
                            <div className='customSearchInfoBox' id="fourcastMatchResultsInfoBox">
                                <div style={{ width: '100%', height: '15%', textAlign: 'center', display: 'flex', justifyContent: 'space-around', alignItems: 'center',  }}>
                                    <div style={{ width: '45%' }}>
                                        <div style={{ fontSize: 12 }} className='customSearchInfoBoxDesc'>Regular Score</div>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img className='customSearchInfoBoxScore' src={scoreIcon(this.state.selected.scores.fourcastScore)} alt='' />
                                        </div>
                                    </div>
                                    <div style={{ width: '10%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <MdOutlineDoubleArrow style={{ height: 25, width: 25, color: "rgb(52, 181, 122)" }} />
                                    </div>
                                    <div style={{ width: '45%' }}>
                                        <div style={{ fontSize: 12 }} className='customSearchInfoBoxDesc'>Fourcast Match</div>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img className='customSearchInfoBoxScore' src={customSearchScoreIcon(this.state.selected.scores.fourcastScore, findCustomSearchPoints(this.state.selected, customSearchObjects))} alt='' />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ width: '100%', height: '15%', backgroundColor: 'whitesmoke' }}>
                                    <div style={{ width: '100%', paddingLeft: 5, paddingTop: 5, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                        <div style={{ fontSize: 14, fontWeight: 'bold' }}>Match Score Strength: </div>
                                        <div style={{ marginLeft: 5, fontSize: 13, fontWeight: 700, color: colorCodeScoreStrength(thisListingsMatchScoreStrength) }}>{thisListingsMatchScoreStrength}</div>
                                    </div>
                                    <div style={{ width: '100%', paddingLeft: 5, paddingTop: 2.5, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                        <div style={{ fontSize: 14, fontWeight: 'bold', color: 'darkgreen' }}>Good Businesses: </div>
                                        {customSearchFilters.goodBiz.map(biz => (
                                        <div style={{ marginLeft: 5, fontSize: 13, fontWeight: 600 }}>{((customSearchFilters.goodBiz.indexOf(biz)) === (customSearchFilters.goodBiz.length - 1)) ? ` ${biz.label}` : ` ${biz.label},`}</div>
                                        ))}
                                    </div>
                                    <div style={{ width: '100%', paddingLeft: 5, paddingTop: 2.5, display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                        <div style={{ fontSize: 14, fontWeight: 'bold', color: 'darkred' }}>Bad Businesses: </div>
                                        {customSearchFilters.badBiz.map(biz => (
                                        <div style={{ marginLeft: 5, fontSize: 13, fontWeight: 600 }}>{((customSearchFilters.badBiz.indexOf(biz)) === (customSearchFilters.badBiz.length - 1)) ? ` ${biz.label}` : ` ${biz.label},`}</div>
                                        ))}
                                    </div>
                                </div>
                                <div style={{ height: '55%', width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'start', paddingTop: 10, overflowY: 'scroll'  }}>
                                    <div style={{ width: '50%' }}>
                                        <div style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}>Good</div>
                                        <div style={{ width: '100%', textAlign: 'center', overflowY: 'scroll' }}>
                                            <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif', marginBottom: 15 }}><span>+ Found <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}><BizListModal bizList={customSelected[0].goodBiz.goodBizList} goodOrBad='good' /></span> Good Businesses Nearby</span></p>
                                            <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}>{(this.state.selected.squareFeet.some(sqFt => sqFt >= customSearchFilters.minSize) && this.state.selected.squareFeet.some(sqFt => sqFt <= customSearchFilters.maxSize)) ? <span>+ <span style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}>{((this.state.selected.squareFeet[0] > customSearchFilters.minSize) && (this.state.selected.squareFeet[0] < customSearchFilters.maxSize)) ? this.state.selected.squareFeet[0] : this.state.selected.squareFeet[1]}</span> SqFt</span> : null}</p>
                                            <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}>{goodPriceCheck(selectedListingPrice, customSearchFilters)}</p>
                                            {customSelected[0].trueTags.length > 0 ? customSelected[0].trueTags.map(tag => (
                                            <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}><span>+ <span style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkgreen' }}> {filterLabels(tag)}</span></span></p>
                                            )) : null}
                                        </div>
                                    </div>
                                    <div style={{ width: '50%' }}>
                                        <div style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkred' }}>Bad</div>
                                        <div style={{ width: '100%', textAlign: 'center', overflowY: 'scroll' }}>
                                            <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif', marginBottom: 15 }}><span>- Found <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkred' }}><BizListModal bizList={customSelected[0].badBiz.badBizList} goodOrBad='bad' /></span> Bad Businesses Nearby</span></p>
                                            <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}>{(((this.state.selected.squareFeet[0] < customSearchFilters.minSize) && (this.state.selected.squareFeet[this.state.selected.squareFeet.length - 1] < customSearchFilters.minSize)) || ((this.state.selected.squareFeet[0] > customSearchFilters.maxSize) && (this.state.selected.squareFeet[this.state.selected.squareFeet.length - 1] > customSearchFilters.maxSize))) ? <span>- <span style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkred' }}>{this.state.selected.squareFeet.length > 1 ? `${this.state.selected.squareFeet[0]} - ${this.state.selected.squareFeet[1]}` : `${this.state.selected.squareFeet}`}</span> SqFt</span> : null}</p>
                                            <p style={selectedListingPrice > 0 ? { fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' } : { fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif', color: '#008CBA' }}>{badPriceCheck(selectedListingPrice, customSearchFilters)}</p>
                                            {customSelected[0].falseTags.length > 0 ? customSelected[0].falseTags.map(tag => (
                                            <p style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif' }}><span>- <span style={{ fontSize: 12, fontWeight: 'bold', fontFamily: 'sans-serif', color: 'darkred' }}> {filterLabels(tag)}</span></span></p>
                                            )) : null}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ height: '15%', width: '100%', display: 'flex', justifyContent: 'space-around', alignItems: 'start' }} id="customSearchActionButtonsArea">
                                    <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                                        <LeavingPageAlert thisListing={this.state.selected} listings={listings} />
                                    </div>
                                    <div style={{ width: '50%', paddingLeft: 25, paddingTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'start' }}>
                                        <ContactModal selected={this.state.selected} fourcastMatchData={customSelected} customSearchFilters={customSearchFilters} />
                                    </div>
                                </div>
                            </div>)
                        : null}
                    </div>
                    </>
                : null}
            </div>

            {this.state.filterBoxOpen === true ? (
            <div style={{ overflowY: 'scroll', maxHeight: 800 }}>
                {((mobile === true) || (this.state.customSearchMode === true)) ? null : (
                <><div style={{ position: 'absolute', right: '40%', top: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 30, height: 40, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', backgroundColor: 'snow' }}>
                    <IconButton 
                        onClick={(e) => {
                            let currentValue = this.state.filterBoxOpen;
                            this.setState({ filterBoxOpen: !currentValue });
                        }}
                        style={{ width: '100%', height: '100%', outline: 'none' }} 
                    >
                        {this.state.filterBoxOpen ? <MdChevronRight /> : <MdChevronLeft />}
                    </IconButton>
                </div>
                <Slide direction="left" in={this.state.filterBoxOpen === true}>
                <div className="filterBox" id='listingsMapFilterBoxDiv'>
                    <h5 id='filterHeader'>Filter Properties</h5>
                    <div id='priceAndSizeFilters'>
                        <div id='squareFootage'>
                            <div className='priceAndSizeAndCategoryTitle'>Square Footage</div>
                            <div id='squareFootageInput'>
                                <Form>
                                    <FormGroup style={{ width: 90, paddingRight: 10 }}>
                                        <Input 
                                            type='number'
                                            category='squareFeetMin'
                                            placeholder='0'
                                            onChange={(e) => {
                                                e.target.value === '' ? this.setMinSF(0) : this.setMinSF(e.target.value);
                                            }}
                                        />
                                        <Label style={{ fontSize: 12, color: 'black' }} for='squareFeet'>Min</Label>
                                    </FormGroup>
                                </Form>
                                <Form>
                                    <FormGroup style={{ width: 90 }}>
                                        <Input 
                                            type='number'
                                            category='squareFeetMax'
                                            placeholder='9999'
                                            onChange={(e) => {
                                                e.target.value === '' ? this.setMaxSF(19999) : this.setMaxSF(e.target.value);
                                            }}
                                        />
                                        <Label style={{ fontSize: 12, color: 'black' }} for='squareFeet'>Max</Label>
                                    </FormGroup>
                                </Form>
                            </div>
                        </div>
                        <div id='pricePerSquareFoot'>
                            <div className='priceAndSizeAndCategoryTitle'>Price per Square Foot</div>
                            <div id='pricePerSquareFootInput'>
                                <Form>
                                    <FormGroup style={{ width: 90, paddingRight: 10 }}>
                                        <Input 
                                            type='number'
                                            category='pricePerSquareFootMin'
                                            placeholder='$0'
                                            onChange={(e) => {
                                                e.target.value === '' ? this.setMinPrice(0) : this.setMinPrice(e.target.value);
                                            }}
                                        />
                                        <Label style={{ fontSize: 12, color: 'black' }} for='pricePerSquareFoot'>Min</Label>
                                    </FormGroup>
                                </Form>
                                <Form>
                                    <FormGroup style={{ width: 90 }}>
                                        <Input 
                                            type='number'
                                            category='pricePerSquareFootMax'
                                            placeholder='$9999'
                                            onChange={(e) => {
                                                e.target.value === '' ? this.setMaxPrice(19999) : this.setMaxPrice(e.target.value);
                                            }}
                                        />
                                        <Label style={{ fontSize: 12, color: 'black' }} for='pricePerSquareFoot'>Max</Label>
                                    </FormGroup>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div id='includeUndisclosed'>
                        <div style={{ width: 250, display: 'grid', gridTemplateColumns: '1fr 5fr', marginLeft: 24 }}>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', height: '100%' }}>
                                <Checkbox
                                    className='showUndisclosedBtn'
                                    size="medium"
                                    color='primary'
                                    checked={this.state.showUndisclosed}
                                    onClick={(e) => this.setState({ showUndisclosed: !this.state.showUndisclosed })}
                                />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left', fontSize: 14, fontWeight: 'bold' }}>
                                Include Undisclosed Rates
                            </div>
                        </div>
                    </div>
                    <div className='categoryScoresArea'>
                        <div className='priceAndSizeAndCategoryTitle'>Category Scores</div>
                        <div className='categoryScoresDescription'><span>Display Only Properties With Category Score Over </span><span id='eighty'>80</span></div>
                        <div className='categoryButtons'>
                            <Button
                                variant="contained"
                                category='highTraffic'
                                checked={this.state.filterTags.includes('highTraffic')}
                                onClick={(e) => {
                                    const value = 'highTraffic';
                                    const checked = this.state.filterTags.includes('highTraffic');
                                    let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                    this.setState({ filterTags: filterTag });
                                }}
                                style={ this.state.filterTags.includes('highTraffic') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 11, marginTop: 7, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 11, marginTop: 7, outline: 'none' }}
                            >
                            Traffic
                            </Button>
                            <Button
                                variant="contained"
                                category='highDemographics'
                                checked={this.state.filterTags.includes('highDemographics')}
                                onClick={(e) => {
                                    const value = 'highDemographics';
                                    const checked = this.state.filterTags.includes('highDemographics');
                                    let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                    this.setState({ filterTags: filterTag });
                                }}
                                style={ this.state.filterTags.includes('highDemographics') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 11, marginLeft: 10, marginTop: 7, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 11, marginLeft: 10, marginTop: 7, outline: 'none' }}
                            >
                            Demographics
                            </Button>
                            <Button
                                variant="contained"
                                category='highCompetition'
                                checked={this.state.filterTags.includes('highCompetition')}
                                onClick={(e) => {
                                    const value = 'highCompetition';
                                    const checked = this.state.filterTags.includes('highCompetition');
                                    let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                    this.setState({ filterTags: filterTag });
                                }}
                                style={ this.state.filterTags.includes('highCompetition') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 11, marginLeft: 10, marginTop: 7, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 11, marginLeft: 10, marginTop: 7, outline: 'none' }}
                            >
                            Competition
                            </Button>
                            <Button
                                variant="contained"
                                category='highProperty'
                                checked={this.state.filterTags.includes('highProperty')}
                                onClick={(e) => {
                                    const value = 'highProperty';
                                    const checked = this.state.filterTags.includes('highProperty');
                                    let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                    this.setState({ filterTags: filterTag });
                                }}
                                style={ this.state.filterTags.includes('highProperty') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 11, marginLeft: 10, marginTop: 7, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 11, marginLeft: 10, marginTop: 7, outline: 'none' }}
                            >
                            Property
                            </Button>
                        </div>
                    </div>
                    <hr />
                    <Accordion style={{ backgroundColor: 'snow', }}>
                        <AccordionSummary
                            expandIcon={<MdExpandMore style={{ width: 30, height: 30 }} />}
                            style={{ width: '95%', height: 80, display: 'flex', justifyContent: 'left', alignItems: 'center' }}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h5 style={{ paddingLeft: 24 }}>Demographics</h5>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='demoFilters'>
                                <div style={{ width: 575, height: 275, marginLeft: 'auto', marginRight: 'auto' }}>
                                    <div style={{ fontSize: 12, fontFamily: 'sans-serif', fontWeight: 'bolder', marginBottom: 10, textAlign: 'left' }}>Type Of People</div>
                                    <Stack direction="row">
                                        <div className='peopleFilter' id='localResidentsFilter' style={{ marginLeft: 5 }}>
                                            <FilterTagTooltip arrow placement="top"
                                                title={
                                                    <>
                                                        <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in largely residential areas.</p>
                                                    </>
                                                }
                                            >
                                                <Fab
                                                    className='filterBtnFab'
                                                    variant="extended"
                                                    size="small"
                                                    category='localResidents'
                                                    checked={this.state.filterTags.includes('localResidents')}
                                                    onClick={(e) => {
                                                        const value = 'localResidents';
                                                        const checked = this.state.filterTags.includes('localResidents');
                                                        let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                        this.setState({ filterTags: filterTag });
                                                    }}
                                                    style={ this.state.filterTags.includes('localResidents') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                >
                                                Mostly Residents
                                                </Fab>
                                            </FilterTagTooltip>
                                        </div>
                                        <div className='peopleFilter' id='officeWorkersFilter'>
                                            <FilterTagTooltip arrow placement="top"
                                                title={
                                                    <>
                                                        <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings where a significant amount of people work in the area.</p>
                                                    </>
                                                }
                                            >
                                                <Fab
                                                    className='filterBtnFab'
                                                    variant="extended"
                                                    size="small"
                                                    category='officeWorkers'
                                                    checked={this.state.filterTags.includes('officeWorkers')}
                                                    onClick={(e) => {
                                                        const value = 'officeWorkers';
                                                        const checked = this.state.filterTags.includes('officeWorkers');
                                                        let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                        this.setState({ filterTags: filterTag });
                                                    }}
                                                    style={ this.state.filterTags.includes('officeWorkers') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                >
                                                Workers
                                                </Fab>
                                            </FilterTagTooltip>
                                        </div>
                                        <div className='peopleFilter' id='touristsFilter'>
                                            <FilterTagTooltip arrow placement="top"
                                                title={
                                                    <>
                                                        <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas with known tourist populations.</p>
                                                    </>
                                                }
                                            >
                                                <Fab
                                                    className='filterBtnFab'
                                                    variant="extended"
                                                    size="small"
                                                    category='nearAttraction'
                                                    checked={this.state.filterTags.includes('nearAttraction')}
                                                    onClick={(e) => {
                                                        const value = 'nearAttraction';
                                                        const checked = this.state.filterTags.includes('nearAttraction');
                                                        let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                        this.setState({ filterTags: filterTag });
                                                    }}
                                                    style={ this.state.filterTags.includes('nearAttraction') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                >
                                                Tourists
                                                </Fab>
                                            </FilterTagTooltip>
                                        </div>
                                        <div className='peopleFilter' id='studentsFilter'>
                                            <FilterTagTooltip arrow placement="top"
                                                title={
                                                    <>
                                                        <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas with the highest concentration of students either living, or attending school in the area.</p>
                                                    </>
                                                }
                                            >
                                                <Fab
                                                    className='filterBtnFab'
                                                    variant="extended"
                                                    size="small"
                                                    category='students'
                                                    checked={this.state.filterTags.includes('students')}
                                                    onClick={(e) => {
                                                        const value = 'students';
                                                        const checked = this.state.filterTags.includes('students');
                                                        let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                        this.setState({ filterTags: filterTag });
                                                    }}
                                                    style={ this.state.filterTags.includes('students') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                >
                                                Students
                                                </Fab>
                                            </FilterTagTooltip>
                                        </div>
                                    </Stack>
                                    <div style={{ fontSize: 12, fontFamily: 'sans-serif', fontWeight: 'bolder', marginBottom: 10, textAlign: 'left', marginTop: 35 }}>Type Of Area</div>
                                    <Stack direction="row">
                                        <div className='areaFilter' id='higherIncome' style={{ marginLeft: 5 }}>
                                            <FilterTagTooltip arrow placement="left"
                                                title={
                                                    <>
                                                        <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas where the average income levels and/or volume of people making higher incomes are higher here than most areas in the city.</p>
                                                    </>
                                                }
                                            >
                                                <Fab
                                                    className='filterBtnFab'
                                                    variant="extended"
                                                    size="small"
                                                    outline="none"
                                                    category='highIncomeArea'
                                                    checked={this.state.filterTags.includes('highIncomeArea')}
                                                    onClick={(e) => {
                                                        const value = 'highIncomeArea';
                                                        const checked = this.state.filterTags.includes('highIncomeArea');
                                                        let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                        this.setState({ filterTags: filterTag });
                                                    }}
                                                    style={ this.state.filterTags.includes('highIncomeArea') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                >
                                                Higher Income
                                                </Fab>
                                            </FilterTagTooltip>
                                        </div>
                                        <div>
                                            <div className='areaFilter' id='cheap'>
                                                <FilterTagTooltip arrow placement="top"
                                                    title={
                                                        <>
                                                            <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas where residential real estate prices are typically more affordable than other neighborhoods in the city.</p>
                                                        </>
                                                    }
                                                >
                                                    <Fab
                                                        className='filterBtnFab'
                                                        variant="extended"
                                                        size="small"
                                                        category='affordableRE'
                                                        checked={this.state.filterTags.includes('affordableRE')}
                                                        onClick={(e) => {
                                                            const value = 'affordableRE';
                                                            const checked = this.state.filterTags.includes('affordableRE');
                                                            let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                            this.setState({ filterTags: filterTag });
                                                        }}
                                                        style={ this.state.filterTags.includes('affordableRE') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                    >
                                                    Affordable Area
                                                    </Fab>
                                                </FilterTagTooltip>
                                            </div>
                                        </div>
                                    </Stack>
                                    <div style={{ fontSize: 12, fontFamily: 'sans-serif', fontWeight: 'bolder', marginBottom: 10, textAlign: 'left', marginTop: 35 }}>Ages</div>
                                    <Stack direction="row" style={{ display: 'flexwrap', flexWrap: 'wrap', height: '40%' }}>
                                        <div id='withChildrenFilter' style={{ textAlign: 'center', fontWeight: 'bold', marginLeft: 5 }}>
                                            <FilterTagTooltip arrow
                                                title={
                                                    <>
                                                        <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings where you can find more families with children living here than many other neighborhoods.</p>
                                                    </>
                                                }
                                            >
                                                <Fab
                                                    className='filterBtnFab'
                                                    variant="extended"
                                                    size="small"
                                                    category='areasWithChildren'
                                                    checked={this.state.filterTags.includes('areasWithChildren')}
                                                    onClick={(e) => {
                                                        const value = 'areasWithChildren';
                                                        const checked = this.state.filterTags.includes('areasWithChildren');
                                                        let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                        this.setState({ filterTags: filterTag });
                                                    }}
                                                    style={ this.state.filterTags.includes('areasWithChildren') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                >
                                                Areas WITH Children
                                                </Fab>
                                            </FilterTagTooltip>
                                        </div>
                                        <div className='ageFilter' style={{ marginLeft: 20 }}>
                                            <FilterTagTooltip arrow
                                                title={
                                                    <>
                                                        <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas with Gen Zs.</p>
                                                    </>
                                                }
                                            >
                                                <Fab
                                                    className='filterBtnFab'
                                                    variant="extended"
                                                    size="small"
                                                    category='genZ'
                                                    checked={this.state.filterTags.includes('genZ')}
                                                    onClick={(e) => {
                                                        const value = 'genZ';
                                                        const checked = this.state.filterTags.includes('genZ');
                                                        let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                        this.setState({ filterTags: filterTag });
                                                    }}
                                                    style={ this.state.filterTags.includes('genZ') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                >
                                                Gen Z
                                                </Fab>
                                            </FilterTagTooltip>
                                        </div>
                                        <div className='ageFilter' style={{ marginLeft: 20 }}>
                                            <Divider fullWidth={true} style={{ fontSize: 10, marginTop: -20 }}>Millennials</Divider>
                                            <div  style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', gap: 10, marginTop: 5 }}>
                                                <div>
                                                    <FilterTagTooltip arrow
                                                        title={
                                                            <>
                                                                <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas with people aged 25-34.</p>
                                                            </>
                                                        }
                                                    >
                                                        <Fab
                                                            className='filterBtnFab'
                                                            variant="extended"
                                                            size="small"
                                                            category='millennialsTo34'
                                                            checked={this.state.filterTags.includes('millennialsTo34')}
                                                            onClick={(e) => {
                                                                const value = 'millennialsTo34';
                                                                const checked = this.state.filterTags.includes('millennialsTo34');
                                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                                this.setState({ filterTags: filterTag });
                                                            }}
                                                            style={ this.state.filterTags.includes('millennialsTo34') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 60, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 60, fontSize: 10, outline: 'none' }}
                                                        >
                                                        25-34
                                                        </Fab>
                                                    </FilterTagTooltip>
                                                </div>
                                                <div>
                                                    <FilterTagTooltip arrow
                                                        title={
                                                            <>
                                                                <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas with people aged 35-44.</p>
                                                            </>
                                                        }
                                                    >
                                                        <Fab
                                                            className='filterBtnFab'
                                                            variant="extended"
                                                            size="small"
                                                            category='millennialsTo44'
                                                            checked={this.state.filterTags.includes('millennialsTo44')}
                                                            onClick={(e) => {
                                                                const value = 'millennialsTo44';
                                                                const checked = this.state.filterTags.includes('millennialsTo44');
                                                                let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                                this.setState({ filterTags: filterTag });
                                                            }}
                                                            style={ this.state.filterTags.includes('millennialsTo44') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 60, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 60, fontSize: 10, outline: 'none' }}
                                                        >
                                                        35-44
                                                        </Fab>
                                                    </FilterTagTooltip>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='ageFilter' id='withChildrenFilter' style={{ marginLeft: 15 }}>
                                            <FilterTagTooltip arrow
                                                title={
                                                    <>
                                                        <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas with Gen Xs.</p>
                                                    </>
                                                }
                                            >
                                                <Fab
                                                    className='filterBtnFab'
                                                    variant="extended"
                                                    size="small"
                                                    category='genX'
                                                    checked={this.state.filterTags.includes('genX')}
                                                    onClick={(e) => {
                                                        const value = 'genX';
                                                        const checked = this.state.filterTags.includes('genX');
                                                        let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                        this.setState({ filterTags: filterTag });
                                                    }}
                                                    style={ this.state.filterTags.includes('genX') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                >
                                                Gen X
                                                </Fab>
                                            </FilterTagTooltip>
                                        </div>
                                        <div id='withChildrenFilter' style={{ marginLeft: 5 }}>
                                            <FilterTagTooltip arrow
                                                title={
                                                    <>
                                                        <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas with Baby Boomers.</p>
                                                    </>
                                                }
                                            >
                                                <Fab
                                                    className='filterBtnFab'
                                                    variant="extended"
                                                    size="small"
                                                    category='boomers'
                                                    checked={this.state.filterTags.includes('boomers')}
                                                    onClick={(e) => {
                                                        const value = 'boomers';
                                                        const checked = this.state.filterTags.includes('boomers');
                                                        let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                        this.setState({ filterTags: filterTag });
                                                    }}
                                                    style={ this.state.filterTags.includes('boomers') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                >
                                                Boomers
                                                </Fab>
                                            </FilterTagTooltip>
                                        </div>
                                    </Stack>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ backgroundColor: 'snow' }}>
                        <AccordionSummary
                            expandIcon={<MdExpandMore style={{ width: 30, height: 30 }} />}
                            style={{ width: '95%', height: 80, display: 'flex', justifyContent: 'left', alignItems: 'center' }}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h5 style={{ paddingLeft: 24 }}>Traffic</h5>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='trafficFilters'>
                                <div style={{ width: 575, height: 275, marginLeft: 'auto', marginRight: 'auto' }}>
                                    <div style={{ fontSize: 12, fontFamily: 'sans-serif', fontWeight: 'bolder', marginBottom: 10, textAlign: 'left' }}>Traffic Drivers</div>
                                    <Stack direction="row">
                                        <div className="trafficDrivers" id='majorAttraction' style={{ marginLeft: 5 }}>
                                            <FilterTagTooltip arrow placement="top"
                                                title={
                                                    <>
                                                        <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Spaces that are within a 250 meter radius (~5 min walk) of a major attraction, which can generate traffic and tourists in the area.</p>
                                                    </>
                                                }
                                            >
                                                <Fab
                                                    className='filterBtnFab'
                                                    variant="extended"
                                                    size="small"
                                                    category='nearAttraction'
                                                    checked={this.state.filterTags.includes('nearAttraction')}
                                                    onClick={(e) => {
                                                        const value = 'nearAttraction';
                                                        const checked = this.state.filterTags.includes('nearAttraction');
                                                        let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                        this.setState({ filterTags: filterTag });
                                                    }}
                                                    style={ this.state.filterTags.includes('nearAttraction') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                >
                                                Near Major Attraction
                                                </Fab>
                                            </FilterTagTooltip>
                                        </div>
                                        <div className="trafficDrivers" id='seasonal'>
                                            <FilterTagTooltip arrow placement="top"
                                                title={
                                                    <>
                                                        <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas where traffic fluctuates depending on the season or time of year.</p>
                                                    </>
                                                }
                                            >
                                                <Fab
                                                    className='filterBtnFab'
                                                    variant="extended"
                                                    size="small"
                                                    category='seasonal'
                                                    checked={this.state.filterTags.includes('seasonal')}
                                                    onClick={(e) => {
                                                        const value = 'seasonal';
                                                        const checked = this.state.filterTags.includes('seasonal');
                                                        let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                        this.setState({ filterTags: filterTag });
                                                    }}
                                                    style={ this.state.filterTags.includes('seasonal') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                >
                                                Seasonal
                                                </Fab>
                                            </FilterTagTooltip>
                                        </div>
                                    </Stack>
                                    <div style={{ fontSize: 12, fontFamily: 'sans-serif', fontWeight: 'bolder', marginBottom: 10, textAlign: 'left', marginTop: 35 }}>Busy Times</div>
                                    <Stack direction="row">
                                        <div className="busyTimeFilters" id='daytime' style={{ marginLeft: 5 }}>
                                            <FilterTagTooltip arrow
                                                title={
                                                    <>
                                                        <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas where traffic is high mostly during the day.</p>
                                                    </>
                                                }
                                            >
                                                <Fab
                                                    className='filterBtnFab'
                                                    variant="extended"
                                                    size="small"
                                                    category='busyDayTime'
                                                    checked={this.state.filterTags.includes('busyDayTime')}
                                                    onClick={(e) => {
                                                        const value = 'busyDayTime';
                                                        const checked = this.state.filterTags.includes('busyDayTime');
                                                        let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                        this.setState({ filterTags: filterTag });
                                                    }}
                                                    style={ this.state.filterTags.includes('busyDayTime') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                >
                                                Busy Daytime
                                                </Fab>
                                            </FilterTagTooltip>
                                        </div>
                                        <div className="busyTimeFilters" id='nightlife'>
                                            <FilterTagTooltip arrow
                                                title={
                                                    <>
                                                        <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas that have clusters of nightlife spots that are open late.</p>
                                                    </>
                                                }
                                            >
                                                <Fab
                                                    className='filterBtnFab'
                                                    variant="extended"
                                                    size="small"
                                                    category='busyNightLife'
                                                    checked={this.state.filterTags.includes('busyNightLife')}
                                                    onClick={(e) => {
                                                        const value = 'busyNightLife';
                                                        const checked = this.state.filterTags.includes('busyNightLife');
                                                        let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                        this.setState({ filterTags: filterTag });
                                                    }}
                                                    style={ this.state.filterTags.includes('busyNightLife') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                >
                                                Busy Nightlife
                                                </Fab>
                                            </FilterTagTooltip>
                                        </div>
                                        <div className="busyTimeFilters" id='monFri'>
                                            <FilterTagTooltip arrow
                                                title={
                                                    <>
                                                        <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas where traffic is high mostly during the weekdays, Monday-Friday.</p>
                                                    </>
                                                }
                                            >
                                                <Fab
                                                    className='filterBtnFab'
                                                    variant="extended"
                                                    size="small"
                                                    category='busyMonThruFri'
                                                    checked={this.state.filterTags.includes('busyMonThruFri')}
                                                    onClick={(e) => {
                                                        const value = 'busyMonThruFri';
                                                        const checked = this.state.filterTags.includes('busyMonThruFri');
                                                        let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                        this.setState({ filterTags: filterTag });
                                                    }}
                                                    style={ this.state.filterTags.includes('busyMonThruFri') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                >
                                                Monday Thru Friday
                                                </Fab>
                                            </FilterTagTooltip>
                                        </div>
                                        <div className="busyTimeFilters" id='weekend'>
                                            <FilterTagTooltip arrow
                                                title={
                                                    <>
                                                        <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas that have weekend traffic, Friday-Sunday.</p>
                                                    </>
                                                }
                                            >
                                                <Fab
                                                    className='filterBtnFab'
                                                    variant="extended"
                                                    size="small"
                                                    category='busyWeekend'
                                                    checked={this.state.filterTags.includes('busyWeekend')}
                                                    onClick={(e) => {
                                                        const value = 'busyWeekend';
                                                        const checked = this.state.filterTags.includes('busyWeekend');
                                                        let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                        this.setState({ filterTags: filterTag });
                                                    }}
                                                    style={ this.state.filterTags.includes('busyWeekend') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                >
                                                Weekends
                                                </Fab>
                                            </FilterTagTooltip>
                                        </div>
                                    </Stack>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ backgroundColor: 'snow' }}>
                        <AccordionSummary
                            expandIcon={<MdExpandMore style={{ width: 30, height: 30 }} />}
                            style={{ width: '95%', height: 80, display: 'flex', justifyContent: 'left', alignItems: 'center' }}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h5 style={{ paddingLeft: 24 }}>Competition</h5>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='competitionFilters'>
                                <div style={{ width: 575, height: 275, marginLeft: 'auto', marginRight: 'auto' }}>
                                    <div style={{ fontSize: 12, fontFamily: 'sans-serif', fontWeight: 'bolder', marginBottom: 10, textAlign: 'left' }}>Product Prices</div>
                                    <Stack direction="row">
                                        <div className="productPriceFilter" id='lowEndBox' style={{ marginLeft: 5 }}>
                                            <FilterTagTooltip arrow placement="top"
                                                title={
                                                    <>
                                                        <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas where there are mostly businesses with affordable product prices nearby.</p>
                                                    </>
                                                }
                                            >
                                                <Fab
                                                    className='filterBtnFab'
                                                    variant="extended"
                                                    size="small"
                                                    category='lowEndBiz'
                                                    checked={this.state.filterTags.includes('lowEndBiz')}
                                                    onClick={(e) => {
                                                        const value = 'lowEndBiz';
                                                        const checked = this.state.filterTags.includes('lowEndBiz');
                                                        let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                        this.setState({ filterTags: filterTag });
                                                    }}
                                                    style={ this.state.filterTags.includes('lowEndBiz') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                >
                                                $
                                                </Fab>
                                            </FilterTagTooltip>
                                        </div>
                                        <div className='productPriceFilter' id='highEndBox'>
                                            <FilterTagTooltip arrow placement="top"
                                                title={
                                                    <>
                                                        <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas where there are numerous high-end businesses nearby.</p>
                                                    </>
                                                }
                                            >
                                                <Fab
                                                    className='filterBtnFab'
                                                    variant="extended"
                                                    size="small"
                                                    category='highEndBiz'
                                                    checked={this.state.filterTags.includes('highEndBiz')}
                                                    onClick={(e) => {
                                                        const value = 'highEndBiz';
                                                        const checked = this.state.filterTags.includes('highEndBiz');
                                                        let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                        this.setState({ filterTags: filterTag });
                                                    }}
                                                    style={ this.state.filterTags.includes('highEndBiz') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                >
                                                $$$$
                                                </Fab>
                                            </FilterTagTooltip>
                                        </div>
                                    </Stack>
                                    <div style={{ fontSize: 12, fontFamily: 'sans-serif', fontWeight: 'bolder', marginBottom: 10, textAlign: 'left', marginTop: 35 }}>Business Density</div>
                                    <Stack direction="row">
                                        <div className='bizDensityFilter' id='highDensityBox' style={{ marginLeft: 5 }}>
                                            <FilterTagTooltip arrow
                                                title={
                                                    <>
                                                        <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas with the highest density of retail businesses.</p>
                                                    </>
                                                }
                                            >
                                                <Fab
                                                    className='filterBtnFab'
                                                    variant="extended"
                                                    size="small"
                                                    category='highBizDensity'
                                                    checked={this.state.filterTags.includes('highBizDensity')}
                                                    onClick={(e) => {
                                                        const value = 'highBizDensity';
                                                        const checked = this.state.filterTags.includes('highBizDensity');
                                                        let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                        this.setState({ filterTags: filterTag });
                                                    }}
                                                    style={ this.state.filterTags.includes('highBizDensity') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                >
                                                High
                                                </Fab>
                                            </FilterTagTooltip>
                                        </div>
                                        <div className='bizDensityFilter' id='lowDensityBox'>
                                            <FilterTagTooltip arrow
                                                title={
                                                    <>
                                                        <p style={{ fontWeight: '500px', fontSize: 13, textAlign: 'center' }}>Listings in areas with the lowest density of retail businesses.</p>
                                                    </>
                                                }
                                            >
                                                <Fab
                                                    className='filterBtnFab'
                                                    variant="extended"
                                                    size="small"
                                                    category='lowBizDensity'
                                                    checked={this.state.filterTags.includes('lowBizDensity')}
                                                    onClick={(e) => {
                                                        const value = 'lowBizDensity';
                                                        const checked = this.state.filterTags.includes('lowBizDensity');
                                                        let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                        this.setState({ filterTags: filterTag });
                                                    }}
                                                    style={ this.state.filterTags.includes('lowBizDensity') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                                >
                                                Low
                                                </Fab>
                                            </FilterTagTooltip>
                                        </div>
                                    </Stack>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ backgroundColor: 'snow' }}>
                        <AccordionSummary
                            expandIcon={<MdExpandMore style={{ width: 30, height: 30 }} />}
                            style={{ width: '95%', height: 80, display: 'flex', justifyContent: 'left', alignItems: 'center' }}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <h5 style={{ paddingLeft: 24 }}>Property</h5>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className='propertyFilters'>
                                <div style={{ width: 575, height: 275, marginLeft: 'auto', marginRight: 'auto' }}>
                                    <div style={{ fontSize: 12, fontFamily: 'sans-serif', fontWeight: 'bolder', marginBottom: 10, textAlign: 'left' }}>Features</div>
                                    <Stack direction="row">
                                        <div className='propertyFilter' id='cornerLocation' style={{ marginLeft: 5 }}>
                                            <Fab
                                                className='filterBtnFab'
                                                variant="extended"
                                                size="small"
                                                category='cornerLocation'
                                                checked={this.state.filterTags.includes('cornerLocation')}
                                                onClick={(e) => {
                                                    const value = 'cornerLocation';
                                                    const checked = this.state.filterTags.includes('cornerLocation');
                                                    let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                    this.setState({ filterTags: filterTag });
                                                }}
                                                style={ this.state.filterTags.includes('cornerLocation') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                            >
                                            Corner Location
                                            </Fab>
                                        </div>
                                        <div className='propertyFilter' id='bigFrontage'>
                                            <Fab
                                                className='filterBtnFab'
                                                variant="extended"
                                                size="small"
                                                category='bigFrontage'
                                                checked={this.state.filterTags.includes('bigFrontage')}
                                                onClick={(e) => {
                                                    const value = 'bigFrontage';
                                                    const checked = this.state.filterTags.includes('bigFrontage');
                                                    let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                    this.setState({ filterTags: filterTag });
                                                }}
                                                style={ this.state.filterTags.includes('bigFrontage') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                            >
                                            Big Frontage
                                            </Fab>
                                        </div>
                                        <div className='propertyFilter' id='divisible'>
                                            <Fab
                                                className='filterBtnFab'
                                                variant="extended"
                                                size="small"
                                                category='divisible'
                                                checked={this.state.filterTags.includes('divisible')}
                                                onClick={(e) => {
                                                    const value = 'divisible';
                                                    const checked = this.state.filterTags.includes('divisible');
                                                    let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                    this.setState({ filterTags: filterTag });
                                                }}
                                                style={ this.state.filterTags.includes('divisible') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                            >
                                            Divisible
                                            </Fab>
                                        </div>
                                    </Stack>
                                    <Stack direction='row' style={{ marginTop: 35 }}>
                                        <div className='propertyFilter' id='multiLevel' style={{ marginLeft: 5 }}>
                                            <Fab
                                                className='filterBtnFab'
                                                variant="extended"
                                                size="small"
                                                category='multiLevel'
                                                checked={this.state.filterTags.includes('multiLevel')}
                                                onClick={(e) => {
                                                    const value = 'multiLevel';
                                                    const checked = this.state.filterTags.includes('multiLevel');
                                                    let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                    this.setState({ filterTags: filterTag });
                                                }}
                                                style={ this.state.filterTags.includes('multiLevel') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                            >
                                            Multi Level
                                            </Fab>
                                        </div>
                                        <div className='propertyFilter' id='newConstruction'>
                                            <Fab
                                                className='filterBtnFab'
                                                variant="extended"
                                                size="small"
                                                category='newConstruction'
                                                checked={this.state.filterTags.includes('newConstruction')}
                                                onClick={(e) => {
                                                    const value = 'newConstruction';
                                                    const checked = this.state.filterTags.includes('newConstruction');
                                                    let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                    this.setState({ filterTags: filterTag });
                                                }}
                                                style={ this.state.filterTags.includes('newConstruction') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                            >
                                            New Construction
                                            </Fab>
                                        </div>
                                        <div className='propertyFilter' id='outdoorSpace'>
                                            <Fab
                                                className='filterBtnFab'
                                                variant="extended"
                                                size="small"
                                                category='outdoorSpace'
                                                checked={this.state.filterTags.includes('outdoorSpace')}
                                                onClick={(e) => {
                                                    const value = 'outdoorSpace';
                                                    const checked = this.state.filterTags.includes('outdoorSpace');
                                                    let filterTag = checked ? this.state.filterTags.filter((tags) => tags !== value) : [...this.state.filterTags, value]
                                                    this.setState({ filterTags: filterTag });
                                                }}
                                                style={ this.state.filterTags.includes('outdoorSpace') ? { color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', width: 125, fontSize: 10, outline: 'none' } : { backgroundColor: 'white', width: 125, fontSize: 10, outline: 'none' }}
                                            >
                                            Outdoor Space
                                            </Fab>
                                        </div>
                                    </Stack>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div></Slide></>)}
            </div>) : <><Slide direction="right" in={this.state.filterBoxOpen === false}><div style={{ position: 'absolute', right: '0%', top: '25%', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 30, height: 40, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', backgroundColor: 'snow' }}>
                    <IconButton 
                        onClick={(e) => {
                            let currentValue = this.state.filterBoxOpen;
                            this.setState({ filterBoxOpen: !currentValue });
                        }}
                        style={{ width: '100%', height: '100%', outline: 'none' }} 
                    >
                        {this.state.filterBoxOpen ? <MdChevronRight /> : <MdChevronLeft />}
                    </IconButton>
                </div></Slide></>}
        </div>
      </LoadScript></>
    )
  }
}

ListingMap.propTypes = {
    getListings: PropTypes.func.isRequired,
    listings: PropTypes.array.isRequired
};

const mapStateToProps = (state) => ({
    listings: state.listing.listings,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});

export default connect(mapStateToProps, { getListings, loadUser, updateUser })(ListingMap);

