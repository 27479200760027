import React, { Component } from 'react';
import { 
    Modal, 
    ModalHeader, 
    ModalBody, 
    Row,
    Alert } 
from 'reactstrap';
import { Button } from '@material-ui/core';
import { connect } from 'react-redux';
import { loadUser } from '../actions/authActions';
import { addLead } from '../actions/leadActions';
import PropTypes from 'prop-types';
import LoginModal from './auth/loginModal';
import RegisterModal from './auth/registerModal';
import LeavingPageDownloadPDFAlert from './CustomSearch/fourcastMatchPDF';

class ContactModal extends Component {
    
    constructor(props) {
        
        super(props);
        this.state = {
            modal: false,
            selected: props.selected,
            customSearchMode: false,
            fourcastMatchData: props.fourcastMatchData,
            successAlert: false,
            newLead: null
        }
    }

    componentDidMount() {
        this.props.loadUser();

        const { fourcastMatchData, customSearchFilters } = this.props;
        
        let thisListing = null;
        let fourcastMatch = null;

        if (fourcastMatchData && customSearchFilters) {
            
            let fourcastMatchScore = (this.state.selected.scores.fourcastScore + fourcastMatchData[0].totalPoints);

            fourcastMatchScore > 100 ? fourcastMatchScore = 100 : fourcastMatchScore < 50 ? fourcastMatchScore = 50 : fourcastMatchScore = fourcastMatchScore;
            
            fourcastMatch = {
                score: fourcastMatchScore,
                filters: fourcastMatchData[0],
                minSize: customSearchFilters.minSize,
                maxSize: customSearchFilters.maxSize,
                budget: customSearchFilters.price
            };

            thisListing = {
                _id: this.state.selected._id,
                listingAddress: this.state.selected.listingAddress,
                squareFeet: this.state.selected.squareFeet,
                pricePerSquareFoot: this.state.selected.pricePerSquareFoot,
            };
        }
        
        const newLead = {
            sender: this.props.user,
            receiver: this.state.selected.createdBy,
            listing: thisListing,
            fourcastMatch: fourcastMatch,
            read: false,
        };
        
        this.setState({ newLead: newLead });
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    }

    onSubmit = e => {
        e.preventDefault();

        const { newLead } = this.state;
        
        if (newLead !== null) {
                    
            this.props.addLead(newLead);
            
            this.setState({ successAlert: true });
        }
    }

    render() {

        const { user, isAuthenticated, selected } = this.props;
        const { newLead } = this.state;
        
        return (
            <div>
                <Button
                    onClick={this.toggle}
                    size="medium"
                    style={{ color: 'white', backgroundColor: 'rgb(228, 114, 103)', marginTop: 10, width: 125, marginRight: 50, outline: 'none', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}
                >
                    Contact
                </Button>
                <Modal style={{ width: 600 }} isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Request Information</ModalHeader>
                    { this.state.successAlert ? (
                                <Alert color='success'>Request Sent!</Alert>
                            ) : null}
                    {isAuthenticated ? (
                    <ModalBody style={{ width: 500, margin: 'auto' }}>
                        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <h4 style={{ marginTop: 30, fontFamily: 'sans-serif', wordSpacing: 1, textAlign: 'center' }}>{selected.listingAddress}</h4>
                        </Row>
                        <Row style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <h5>{selected.squareFeet.length > 1 ? `${selected.squareFeet[0]} - ${selected.squareFeet[1]} SF of Retail Space Available` : `${selected.squareFeet} SF of Retail Space Available`}</h5>
                        </Row>
                        {selected.createdBy.email !== 'nyclistings@fourcastscore.com' ? 
                        (<Row style={{ marginTop: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div>
                                <h5>{selected.createdBy ? `${selected.createdBy.firstName} ${selected.createdBy.lastName}` : 'Listing Broker'}</h5>
                                <div>{selected.createdBy ? selected.createdBy.email : 'Broker Email'}</div>
                                <div>{selected.createdBy && selected.createdBy.phone ? `Phone Number: ${selected.createdBy.phone}` : '(212) 555-5555'}</div>
                            </div>
                        </Row>)
                        :
                        (<Row style={{ marginTop: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ width: '100%', textAlign: 'center', fontFamily: 'sans-serif' }}>Listing Provided By:</div>
                                <img alt='Crexi - Commercial Real Estate Exchange Inc.' src='https://files.crexi.com/web/content/img/CREXi-logo-black.svg?ver=3' style={{ width: 94, height: 19, margin: 'auto', marginTop: 25 }} />
                        </Row>)}
                        {selected.createdBy.email !== 'nyclistings@fourcastscore.com' ?
                        (<><Row style={{ marginTop: 60, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <strong>Send Inquiry As:</strong>
                        </Row>
                        <Row style={{ marginTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span><div style={{ marginRight: '1rem' }}>Name:</div></span><span><strong> {`${user.firstName} ${user.lastName}`}</strong></span>
                        </Row>
                        <Row style={{ marginTop: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <span><div style={{ marginRight: '1rem' }}>Contact:</div></span><span><strong> {user.email}</strong></span>
                        </Row>
                        <Row style={{ marginTop: 60, paddingBottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {!this.state.successAlert ? <Button onClick={this.onSubmit} style={{ marginRight: 'auto', marginLeft: 'auto', backgroundColor: 'rgb(35, 126, 85)', color: 'white', width: 400 }}>Send Request</Button> : null}
                        </Row></>) 
                        : (newLead && newLead.sender !== null) ? 
                        (<Row style={{ marginTop: 25, paddingBottom: 40 }}>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <LeavingPageDownloadPDFAlert user={user} selected={selected} lead={newLead} />
                            </div>
                        </Row>) : 
                        (<Row style={{ marginTop: 60, paddingBottom: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button style={{ backgroundColor: '#007FFF', width: 200 }}>
                                <a style={{ color: 'white', textDecoration: 'none' }} href={selected.link} target="_blank" rel="noopener noreferrer">Leave and go to listing</a>
                            </Button>
                        </Row>)}
                    </ModalBody>) 
                    :
                    (<ModalBody style={{ width: 500, margin: 'auto' }}>
                    <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <h4 style={{ marginTop: 30, fontFamily: 'sans-serif', wordSpacing: 1, textAlign: 'center' }}>{selected.listingAddress}</h4>
                    </Row>
                    <Row style={{ marginTop: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <h5>{selected.squareFeet.length > 1 ? `${selected.squareFeet[0]} - ${selected.squareFeet[1]} SF of Retail Space Available` : `${selected.squareFeet} SF of Retail Space Available`}</h5>
                    </Row>
                    
                    {selected.createdBy.email !== 'nyclistings@fourcastscore.com' ? 
                    (<Row style={{ marginTop: 40, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                            <h5>{selected.createdBy ? `${selected.createdBy.firstName} ${selected.createdBy.lastName}` : 'Listing Broker'}</h5>
                            <div>*******@*****.com</div>
                            <div>(212) ***-****</div>
                        </div>
                    </Row>)
                    :
                    (<Row style={{ marginTop: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ width: '100%', textAlign: 'center', fontFamily: 'sans-serif' }}>Listing Provided By:</div>
                        <img alt='Crexi - Commercial Real Estate Exchange Inc.' src='https://files.crexi.com/web/content/img/CREXi-logo-black.svg?ver=3' style={{ width: 94, height: 19, margin: 'auto', marginTop: 25 }} />
                    </Row>)}
                    <Row style={{ marginTop: 50, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {selected.createdBy.email !== 'nyclistings@fourcastscore.com' ?
                        <strong>Log In or Sign Up For Free</strong>
                    : null}
                    </Row>
                    <Row style={{ marginTop: 25, paddingBottom: 40 }}>
                    {selected.createdBy.email !== 'nyclistings@fourcastscore.com' ?
                        (<><span><Button style={{ marginLeft: 100, backgroundColor: '#007FFF', color: 'white', width: 125 }}>
                                <LoginModal />
                            </Button>
                        </span>
                        <span><Button style={{ marginLeft: 50, backgroundColor: 'rgb(35, 126, 85)', color: 'white', width: 125 }}>
                                <RegisterModal />
                            </Button>
                        </span></>)
                        :
                        (<div style={{ width: '100%', textAlign: 'center' }}>
                            <Button style={{ backgroundColor: '#007FFF', width: 200 }}>
                                <a style={{ color: 'white', textDecoration: 'none' }} href={selected.link} target="_blank" rel="noopener noreferrer">Leave and go to listing</a>
                            </Button>
                        </div>)}
                    </Row>
                </ModalBody>)}
                </Modal>
            </div>
        );
    }
}

ContactModal.propTypes = {
    loadUser: PropTypes.func.isRequired,
    addLead: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});
 
export default connect(mapStateToProps, { loadUser, addLead })(ContactModal);