import React, { Component } from 'react';
import { 
    Modal, 
    ModalHeader, 
    ModalBody, 
    Row }
from 'reactstrap';
import { Button } from '@material-ui/core';
import 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import './traffic.css';

class BusyTimesModal extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            openingTimes: this.props.openingTimes,
            closingTimes: this.props.closingTimes,
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    }

    render() {

        const { openingTimes } = this.props;
        const { closingTimes } = this.props;

        return (
            <div>
                <Button
                    onClick={this.toggle}
                    variant="text"
                    style={{ outline: 'none', fontWeight: 'bold', color: 'dodgerblue' }}
                >
                    Details
                </Button>
                <Modal style={{ minWidth: 850 }} isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Busy Times / Days of the Week</ModalHeader>
                    <ModalBody style={{ width: 850, margin: 'auto' }}>
                    <Row>
                        <div className="timesChart" style={{ paddingLeft: 60 }} id="operatingHoursCharts">
                            <Bar
                            data={{
                                labels: [
                                    '5 AM',
                                    '5:30 AM', 
                                    '6 AM',
                                    '6:30 AM',
                                    '7 AM',
                                    '7:30 AM',
                                    '8 AM',
                                    '8:30 AM', 
                                    '9 AM',
                                    '9:30 AM', 
                                    '10 AM',
                                    '10:30 AM', 
                                    '11 AM',
                                    '11:30 AM',
                                    '12 PM',
                                    '12:30 PM',
                                    '1 PM',
                                    '1:30 PM',
                                    '2 PM',
                                    '2:30 PM',
                                    '3 PM',
                                    '3:30 PM',
                                    '4 PM',
                                    '4:30 PM',
                                    '5 PM',
                                    '5:30 PM',
                                    '6 PM',
                                    '6:30 PM',
                                    '7 PM',
                                    '7:30 PM',
                                    '8 PM',
                                    '8:30 PM',
                                    '9 PM',
                                    '9:30 PM',
                                    '10 PM',
                                    '10:30 PM',
                                    '11 PM',
                                    '11:30 PM',
                                    '12 AM',
                                    '12:30 AM',
                                    '1 AM',
                                    '1:30 AM',
                                    '2 AM',
                                    '2:30 AM',
                                    '3 AM',
                                    '3:30 AM',
                                    '4 AM',
                                ],
                                datasets: [
                                    {
                                        label: 'Opening Times of Businesses Nearby',
                                        data: [
                                            `${openingTimes.openFiveAm}`,
                                            `${openingTimes.openfiveThirtyAm}`,
                                            `${openingTimes.openSixAm}`,
                                            `${openingTimes.openSixThirtyAm}`,
                                            `${openingTimes.openSevenAm}`,
                                            `${openingTimes.openSevenThirtyAm}`,
                                            `${openingTimes.openEightAm}`,
                                            `${openingTimes.openEightThirtyAm}`,
                                            `${openingTimes.openNineAm}`,
                                            `${openingTimes.openNineThirtyAm}`,
                                            `${openingTimes.openTenAm}`,
                                            `${openingTimes.openTenThirtyAm}`,
                                            `${openingTimes.openElevenAm}`,
                                            `${openingTimes.openElevenThirtyAm}`,
                                            `${openingTimes.openNoon}`,
                                            `${openingTimes.openNoonThirtyPm}`,
                                            `${openingTimes.openOnePm}`,
                                            `${openingTimes.openOneThirtyPm}`,
                                            `${openingTimes.openTwoPm}`,
                                            `${openingTimes.openTwoThirtyPm}`,
                                            `${openingTimes.openThreePm}`,
                                            `${openingTimes.openThreeThirtyPm}`,
                                            `${openingTimes.openFourPm}`,
                                            `${openingTimes.openFourThirtyPm}`,
                                            `${openingTimes.openFivePm}`,
                                            `${openingTimes.openFiveThirtyPm}`,
                                            `${openingTimes.openSixPm}`,
                                            `${openingTimes.openSixThirtyPm}`,
                                            `${openingTimes.openSevenPm}`,
                                            `${openingTimes.openSevenThirtyPm}`,
                                            `${openingTimes.openEightPm}`,
                                            `${openingTimes.openEightThirtyPm}`,
                                            `${openingTimes.openNinePm}`,
                                            `${openingTimes.openNineThirtyPm}`,
                                            `${openingTimes.openTenPm}`,
                                            `${openingTimes.openTenThirtyPm}`,
                                            `${openingTimes.openElevenPm}`,
                                            `${openingTimes.openElevenThirtyPm}`,
                                            `${openingTimes.openMidnight}`,
                                            `${openingTimes.openMidnightThirty}`,
                                            `${openingTimes.openOneAm}`,
                                            `${openingTimes.openOneThirtyAm}`,
                                            `${openingTimes.openTwoAm}`,
                                            `${openingTimes.openTwoThirtyAm}`,
                                            `${openingTimes.openThreeAm}`,
                                            `${openingTimes.openThreeThirtyAm}`,
                                            `${openingTimes.openFourAm}`,
                                        ],
                                        backgroundColor: [
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(255, 99, 132, 0.8)',
                                        ]
                                    },
                                    {
                                        label: 'Closing Times of Businesses Nearby',
                                        data: [
                                            `${closingTimes.closeFiveAm}`,
                                            `${closingTimes.closeFiveThirtyAm}`,
                                            `${closingTimes.closeSixAm}`,
                                            `${closingTimes.closeSixThirtyAm}`,
                                            `${closingTimes.closeSevenAm}`,
                                            `${closingTimes.closeSevenThirtyAm}`,
                                            `${closingTimes.closeEightAm}`,
                                            `${closingTimes.closeEightThirtyAm}`,
                                            `${closingTimes.closeNineAm}`,
                                            `${closingTimes.closeNineThirtyAm}`,
                                            `${closingTimes.closeTenAm}`,
                                            `${closingTimes.closeTenThirtyAm}`,
                                            `${closingTimes.closeElevenAm}`,
                                            `${closingTimes.closeElevenThirtyAm}`,
                                            `${closingTimes.closeNoon}`,
                                            `${closingTimes.closeNoonThirtyPm}`,
                                            `${closingTimes.closeOnePm}`,
                                            `${closingTimes.closeOneThirtyPm}`,
                                            `${closingTimes.closeTwoPm}`,
                                            `${closingTimes.closeTwoThirtyPm}`,
                                            `${closingTimes.closeThreePm}`,
                                            `${closingTimes.closeThreeThirtyPm}`,
                                            `${closingTimes.closeFourPm}`,
                                            `${closingTimes.closeFourThirtyPm}`,
                                            `${closingTimes.closeFivePm}`,
                                            `${closingTimes.closeFiveThirtyPm}`,
                                            `${closingTimes.closeSixPm}`,
                                            `${closingTimes.closeSixThirtyPm}`,
                                            `${closingTimes.closeSevenPm}`,
                                            `${closingTimes.closeSevenThirtyPm}`,
                                            `${closingTimes.closeEightPm}`,
                                            `${closingTimes.closeEightThirtyPm}`,
                                            `${closingTimes.closeNinePm}`,
                                            `${closingTimes.closeNineThirtyPm}`,
                                            `${closingTimes.closeTenPm}`,
                                            `${closingTimes.closeTenThirtyPm}`,
                                            `${closingTimes.closeElevenPm}`,
                                            `${closingTimes.closeElevenThirtyPm}`,
                                            `${closingTimes.closeMidnight}`,
                                            `${closingTimes.closeMidnightThirty}`,
                                            `${closingTimes.closeOneAm}`,
                                            `${closingTimes.closeOneThirtyAm}`,
                                            `${closingTimes.closeTwoAm}`,
                                            `${closingTimes.closeTwoThirtyAm}`,
                                            `${closingTimes.closeThreeAm}`,
                                            `${closingTimes.closeThreeThirtyAm}`,
                                            `${closingTimes.closeFourAm}`,
                                        ],
                                        backgroundColor: [
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                            'rgba(55, 175, 250, 0.8)',
                                        ]
                                    }
                                ]
                            }}
                            height={300}
                            width={725}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Open / Closing Times',
                                    fontSize: 18,
                                    color: 'black'
                                },
                                plugins: {
                                    datalabels: {
                                    display: false,
                                    color: 'black'
                                    }
                                },
                                scales: {
                                    xAxes: [{
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                        gridLines: {
                                            display: false
                                        },
                                    }],
                                    yAxes: [{
                                        ticks: {
                                            display: false,
                                            
                                        },
                                        gridLines: {
                                            display: false
                                        },
                                    }],
                                },
                            }} 
                            />
                        </div>
                    </Row>
                    <hr />
                    <Row>
                        <div className="daysChart" style={{ paddingLeft: 175 }}>
                            <Bar
                            data={{
                                labels: [
                                    'Sunday',
                                    'Monday',
                                    'Tuesday', 
                                    'Wednesday',
                                    'Thursday',
                                    'Friday',
                                    'Saturday',
                                ],
                                datasets: [
                                    {
                                        label: 'Volume of Businesses Open Nearby',
                                        data: [
                                            `${openingTimes.sunday}`,
                                            `${openingTimes.monday}`,
                                            `${openingTimes.tuesday}`,
                                            `${openingTimes.wednesday}`,
                                            `${openingTimes.thursday}`,
                                            `${openingTimes.friday}`,
                                            `${openingTimes.saturday}`,
                                        ],
                                        backgroundColor: [
                                            'rgba(0, 132, 0, 0.8)',
                                            'rgba(0, 132, 0, 0.8)',
                                            'rgba(0, 132, 0, 0.8)',
                                            'rgba(0, 132, 0, 0.8)',
                                            'rgba(0, 132, 0, 0.8)',
                                            'rgba(0, 132, 0, 0.8)',
                                            'rgba(0, 132, 0, 0.8)',
                                        ]
                                    },
                                ]
                            }}
                            height={275}
                            width={500}
                            options={{
                                title: {
                                    display: true,
                                    text: 'Weekday / Weekend Traffic',
                                    fontSize: 18,
                                    color: 'black'
                                },
                                plugins: {
                                    datalabels: {
                                    display: false,
                                    color: 'black'
                                    }
                                },
                                scales: {
                                    xAxes: [{
                                        ticks: {
                                            beginAtZero: true,
                                        },
                                        gridLines: {
                                            display: false
                                        },
                                        barPercentage: .95
                                    }],
                                    yAxes: [{
                                        ticks: {
                                            display: false,
                                            beginAtZero: true,
                                        },
                                        gridLines: {
                                            display: false
                                        },
                                    }],
                                },
                            }} 
                            />
                        </div>
                    </Row>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
 
export default BusyTimesModal;