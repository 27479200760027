import React, { Component } from 'react';
import { 
    Table,
    Label,
    Row,
    Col,
    Form,
    FormGroup,
    FormFeedback,
    Input,
    Container } 
from 'reactstrap';
import { Checkbox, FormControlLabel, Button, Card } from '@material-ui/core';
import { Alert } from '@mui/material';
import { connect } from 'react-redux';
import { loadUser } from '../../actions/authActions';
import { updateListing, getListing, setListingsLoading } from '../../actions/listingActions';
import './editListing.css';
import axios from 'axios';

// Pointing Axios baseURL towards AWS API Gateway URL
// Will most likely be changed again at some point because of the /dev
const axiosAWS = axios.create({
    baseURL: 'https://bgeg45v1tk.execute-api.us-east-1.amazonaws.com/dev'
});

class EditListing extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            squareFeet1: null,
            squareFeet2: null,
            pricePerSquareFoot: null,
            frontage: null,
            propertyFeatures: [],
            activeStatus: null,
            successAlert: false,
            addRange: false,
            sfError: false,
            images: []
        }
    }


    async componentDidMount() {
        await this.props.getLocation(this.props.location.pathname);
        await this.props.getListing(this.props.match.params.id);
        await this.props.loadUser();
    }


    onChange = e => {
        this.setState({ [e.target.name]: e.target.value, successAlert: false, sfError: false });
    }

    
    onSubmit = async e => {
        e.preventDefault();

        const { user, auth } = this.props;

        

        const { thisListing } = this.props;
        const checkSfError = (this.state.squareFeet1 === null && (this.state.squareFeet2 !== null && this.state.squareFeet2.length > 0)) ? this.setState({ sfError: true }) : null;
        const checkDivisible = (((this.state.squareFeet1 !== null && this.state.squareFeet1.length > 0) && (this.state.squareFeet2 !== null && this.state.squareFeet2.length > 0)) && (!this.state.propertyFeatures.includes('divisible')) && (!thisListing.propertyFeatures.includes('divisible'))) ? this.state.propertyFeatures.push('divisible') : null;
        let numberedSF1 = (this.state.squareFeet1 !== null && this.state.squareFeet1.length > 0) ? parseFloat(this.state.squareFeet1) : null;
        let numberedSF2 = (this.state.squareFeet2 !== null && this.state.squareFeet2.length > 0) ? parseFloat(this.state.squareFeet2) : null;

        const updatedListing = {
            thisListingID: thisListing._id,
            squareFeet: ((this.state.squareFeet1 === null || this.state.squareFeet1.length === 0) && (this.state.squareFeet2 === null || this.state.squareFeet2.length === 0)) ? thisListing.squareFeet : ((this.state.squareFeet1 !== null) && (this.state.squareFeet2 === null || this.state.squareFeet2.length === 0)) ? [numberedSF1] : [numberedSF1, numberedSF2],
            pricePerSquareFoot: this.state.pricePerSquareFoot === null ? thisListing.pricePerSquareFoot : this.state.pricePerSquareFoot,
            frontage: this.state.frontage === null ? thisListing.frontage : this.state.frontage,
            propertyFeatures: this.state.propertyFeatures.length > 0 ? this.state.propertyFeatures : thisListing.propertyFeatures,
            images: (!this.state.images.length) ? (thisListing.images !== undefined ? thisListing.images : []) : this.state.images,
            activeStatus: this.state.activeStatus === null ? thisListing.active : this.state.activeStatus
        };
        
        updatedListing && updatedListing && !this.state.sfError ? this.props.updateListing(updatedListing) : console.log('listing undefined');
        
        if ((this.state.activeStatus !== null) && (thisListing.active !== this.state.activeStatus)) {
            let currentListings = user && user.engagement.listings;

            const existingNumberOfListings = currentListings && (updatedListing.activeStatus === true) ? currentListings.length : (currentListings.length - 1);

            let tokenConfig = thisToken => {
        
                // Get Token from localStorage
                const token = thisToken;
            
                // Headers 
                const config = {
                    headers: {
                        "Content-type": "application/json"
                    }
                }
            
                // If token, add to headers
                if (token) {
                    config.headers['Authorization'] = token;
                }
            
                return config;
            }

            await axiosAWS.post('/api/subscriptions/update', { user, existingNumberOfListings: existingNumberOfListings }, tokenConfig(auth.token))
                .then(res => {
                    const { data } = res.data.items;
                    const updatedQuantityCharged = data && data[0].quantity;
                    this.setState({ updatedListingsNumber: updatedQuantityCharged });
                })
                .catch(err => {
                    console.log(err);
                });
        }
        
        
        const successMsg = !this.state.sfError ? this.setState({ successAlert: true }) : null;
    }

    render() {
        
        const { thisListing } = this.props;
                
        let propFeatureDesc = (listing) => {
            let propFeats = [];
            
                if (listing.propertyFeatures.includes('newConstruction')) {propFeats.push(' New Construction ')};
                if (listing.propertyFeatures.includes('divisible')) {propFeats.push(' Divisible ')};
                if (listing.propertyFeatures.includes('cornerLocation')) {propFeats.push(' Corner Location ')};
                if (listing.propertyFeatures.includes('multiLevel')) {propFeats.push(' Multi Level ')};
                if (listing.propertyFeatures.includes('outdoorSpace')) {propFeats.push(' Outdoor Space ')};
            
            return propFeats
        }
        
        return(
            <Container style={{ backgroundColor: 'whitesmoke', height: '100vh', paddingTop: 20, paddingBottom: 50 }} fluid>
                <Card className="updateListing" style={{ backgroundColor: 'white', height: '85vh', textAlign: 'center' }}>
                    <div style={{ width: '100%', marginTop: 10 }}>
                        <Table style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}>
                            <thead>
                                <tr>
                                    <th>Listing Address</th>
                                    <th>Fourcast Score</th>
                                    <th>Traffic</th>
                                    <th>Demographics</th>
                                    <th>Competition</th>
                                    <th>Property</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th style={{ fontSize: 14, width: '25%' }}>
                                        {thisListing.listingAddress}
                                    </th>
                                    <td style={{ fontSize: 16 }}>
                                        {thisListing.scores && thisListing.scores ? thisListing.scores.fourcastScore : 'Not Scored'}
                                    </td>
                                    <td style={{ fontSize: 16 }}>
                                        {thisListing.scores && thisListing.scores ? thisListing.scores.trafficScore : 'Not Scored'}
                                    </td>
                                    <td style={{ fontSize: 16 }}>
                                        {thisListing.scores && thisListing.scores ? thisListing.scores.demographicsScore : 'Not Scored'}
                                    </td>
                                    <td style={{ fontSize: 16 }}>
                                        {thisListing.scores && thisListing.scores ? thisListing.scores.competitionScore : 'Not Scored'}
                                    </td>
                                    <td style={{ fontSize: 16 }}>
                                        {thisListing.scores && thisListing.scores ? thisListing.scores.propertyScore : 'Not Scored'}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                    <div style={{ width: '100%', textAlign: 'right', marginTop: 70 }}>
                        <Form>
                            <FormGroup row>
                                <Label for='activeStatus' sm={3}>Active: </Label>
                                    <Col sm={3} row style={{ textAlign: 'left' }}>
                                        <FormControlLabel
                                            value={this.state.activeStatus}
                                            control={<Checkbox 
                                                        value=''
                                                        color='primary'
                                                        checked={this.state.activeStatus === true}
                                                        onChange={(e) => {
                                                            this.setState({ activeStatus: true })
                                                        }}
                                                    />}
                                            label="Yes"
                                            labelPlacement="top"
                                        />
                                        <FormControlLabel
                                            value={this.state.activeStatus}
                                            control={<Checkbox 
                                                        value=''
                                                        color='primary'
                                                        checked={this.state.activeStatus === false}
                                                        onChange={(e) => {
                                                            this.setState({ activeStatus: false })
                                                        }}
                                                    />}
                                            label="No"
                                            labelPlacement="top"
                                        />
                                    </Col>
                                    <Col sm={2}>
                                        <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16, fontWeight: 'bold', color: 'rgb(35, 126, 85)', textDecoration: 'underline', marginBottom: 30, marginTop: -20 }}>Current Value:</div>
                                        <div style={{ fontStyle: 'italic', fontSize: 14, fontWeight: 'bold' }}>{thisListing.active === true ? 'Yes' : 'No'}</div>
                                    </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for='squareFeet' sm={3}>Square Feet: </Label>
                                    <Col sm={2}>
                                        <Row>
                                            <Col sm={6}>
                                                <Input type='number' name="squareFeet1" placeholder={this.state.squareFeet1} onChange={this.onChange} value={this.state.squareFeet1} invalid={this.state.sfError} />
                                                <FormFeedback style={{ textAlign: 'left' }}>Required</FormFeedback>
                                            </Col>
                                            {((thisListing.squareFeet && thisListing.squareFeet.length > 1) || (this.state.addRange)) ? (
                                            <Col sm={6}>
                                                <Input type='number' name="squareFeet2" placeholder={this.state.squareFeet2} onChange={this.onChange} value={this.state.squareFeet2} />
                                            </Col>
                                            ) : (
                                            <Col sm={6}>
                                                <Button variant='text' size='small' onClick={e => this.setState({ addRange: true })} style={{ fontSize: 12, color: 'dodgerblue', marginTop: 5 }}>
                                                    + Add Range
                                                </Button>
                                            </Col>)}
                                        </Row>
                                    </Col>
                                    <Col sm={3} style={{ fontSize: 14, fontStyle: 'italic', fontWeight: 'bold' }}>
                                        <div>{thisListing.squareFeet && thisListing.squareFeet.length > 1 ? `${thisListing.squareFeet[0]} - ${thisListing.squareFeet[1]} sqft.` : `${thisListing.squareFeet} sqft`}</div>
                                    </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for='pricePerSquareFoot' sm={3}>Price per Square Foot: </Label>
                                    <Col sm={2}>
                                        <Input type="number" name="pricePerSquareFoot" placeholder={this.state.pricePerSquareFoot} onChange={this.onChange} value={this.state.pricePerSquareFoot} />
                                    </Col>
                                    <Col sm={3} style={{ fontSize: 14, fontStyle: 'italic', fontWeight: 'bold' }}>
                                        <div>{thisListing.pricePerSquareFoot !== -1 ? `$${thisListing.pricePerSquareFoot}` : 'Undisclosed'}</div>
                                    </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for='squareFeet' sm={3}>Frontage: </Label>
                                    <Col sm={2}>
                                        <Input type="number" name="frontage" placeholder={this.state.frontage} onChange={this.onChange} value={this.state.frontage} />
                                    </Col>
                                    <Col sm={3} style={{ fontSize: 14, fontStyle: 'italic', fontWeight: 'bold' }}>
                                        <div>{`${thisListing.frontage} ft`}</div>
                                    </Col>
                            </FormGroup>
                            <FormGroup row>
                                    <Label for='propertyFeatures' sm={3}>Property Features:</Label>
                                        <Col sm={3} style={{ textAlign: 'left' }}>
                                            <div className='editListingPropFeatureSection'>
                                                <Button
                                                    variant="contained" 
                                                    className='propFeatureBtn'
                                                    style={this.state.propertyFeatures.includes('newConstruction') ? { color: 'white', backgroundColor: 'rgb(52, 181, 122)', outline: 'none', fontSize: 11, margin: '5px 5px 5px 5px' } : { color: 'black', backgroundColor: 'lightgray', outline: 'none', fontSize: 11, margin: '5px 5px 5px 5px' }}
                                                    category='newConstruction'
                                                    checked={this.state.propertyFeatures.includes('newConstruction')}
                                                    onClick={(e) => {
                                                        const value = 'newConstruction';
                                                        const checked = this.state.propertyFeatures.includes('newConstruction');
                                                        let propertyFeature = checked ? this.state.propertyFeatures.filter((tags) => tags !== value) : [...this.state.propertyFeatures, value]
                                                        this.setState({ propertyFeatures: propertyFeature });
                                                    }}
                                                >
                                                New Construction
                                                </Button>
                                                <Button
                                                    variant="contained" 
                                                    className='propFeatureBtn'
                                                    style={this.state.propertyFeatures.includes('divisible') ? { color: 'white', backgroundColor: 'rgb(52, 181, 122)', outline: 'none', fontSize: 11, margin: '5px 5px 5px 5px' } : { color: 'black', backgroundColor: 'lightgray', outline: 'none', fontSize: 11, margin: '5px 5px 5px 5px' }}
                                                    category='divisible'
                                                    checked={this.state.propertyFeatures.includes('divisible')}
                                                    onClick={(e) => {
                                                        const value = 'divisible';
                                                        const checked = this.state.propertyFeatures.includes('divisible');
                                                        let propertyFeature = checked ? this.state.propertyFeatures.filter((tags) => tags !== value) : [...this.state.propertyFeatures, value]
                                                        this.setState({ propertyFeatures: propertyFeature });
                                                    }}
                                                >
                                                Divisible
                                                </Button>
                                                <Button
                                                    variant="contained" 
                                                    className='propFeatureBtn'
                                                    style={this.state.propertyFeatures.includes('cornerLocation') ? { color: 'white', backgroundColor: 'rgb(52, 181, 122)', outline: 'none', fontSize: 11, margin: '5px 5px 5px 5px' } : { color: 'black', backgroundColor: 'lightgray', outline: 'none', fontSize: 11, margin: '5px 5px 5px 5px' }}
                                                    category='cornerLocation'
                                                    checked={this.state.propertyFeatures.includes('cornerLocation')}
                                                    onClick={(e) => {
                                                        const value = 'cornerLocation';
                                                        const checked = this.state.propertyFeatures.includes('cornerLocation');
                                                        let propertyFeature = checked ? this.state.propertyFeatures.filter((tags) => tags !== value) : [...this.state.propertyFeatures, value]
                                                        this.setState({ propertyFeatures: propertyFeature });
                                                    }}
                                                >
                                                Corner Location
                                                </Button>
                                                <Button
                                                    variant="contained" 
                                                    className='propFeatureBtn'
                                                    style={this.state.propertyFeatures.includes('multiLevel') ? { color: 'white', backgroundColor: 'rgb(52, 181, 122)', outline: 'none', fontSize: 11, margin: '5px 5px 5px 5px' } : { color: 'black', backgroundColor: 'lightgray', outline: 'none', fontSize: 11, margin: '5px 5px 5px 5px' }}
                                                    category='multiLevel'
                                                    checked={this.state.propertyFeatures.includes('multiLevel')}
                                                    onClick={(e) => {
                                                        const value = 'multiLevel';
                                                        const checked = this.state.propertyFeatures.includes('multiLevel');
                                                        let propertyFeature = checked ? this.state.propertyFeatures.filter((tags) => tags !== value) : [...this.state.propertyFeatures, value]
                                                        this.setState({ propertyFeatures: propertyFeature });
                                                    }}
                                                >
                                                Multi-Level
                                                </Button>
                                                <Button
                                                    variant="contained" 
                                                    className='propFeatureBtn'
                                                    style={this.state.propertyFeatures.includes('outdoorSpace') ? { color: 'white', backgroundColor: 'rgb(52, 181, 122)', outline: 'none', fontSize: 11, margin: '5px 5px 5px 5px' } : { color: 'black', backgroundColor: 'lightgray', outline: 'none', fontSize: 11, margin: '5px 5px 5px 5px' }}
                                                    category='outdoorSpace'
                                                    checked={this.state.propertyFeatures.includes('outdoorSpace')}
                                                    onClick={(e) => {
                                                        const value = 'outdoorSpace';
                                                        const checked = this.state.propertyFeatures.includes('outdoorSpace');
                                                        let propertyFeature = checked ? this.state.propertyFeatures.filter((tags) => tags !== value) : [...this.state.propertyFeatures, value]
                                                        this.setState({ propertyFeatures: propertyFeature });
                                                    }}
                                                >
                                                Outdoor Space
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col sm={2}>
                                            <div style={{ fontSize: 12, fontStyle: 'italic', fontWeight: 'bold' }}>{thisListing.propertyFeatures && thisListing.propertyFeatures.length > 0 ? `[ ${propFeatureDesc(thisListing)} ]` : 'None'}</div>
                                        </Col>
                                </FormGroup>
                        </Form>
                    </div>
                    <div id='editListingSaveArea'>
                        <Button valid={this.state.successAlert} variant="contained" onClick={this.onSubmit} style={{ backgroundColor: 'rgb(52, 181, 122)', color: 'white', width: 200, textAlign: 'center', marginBottom: 10, outline: 'none' }}>Save Update</Button>
                        { this.state.successAlert && !this.state.sfError ? (
                            <Alert severity='success' variant='outlined'>Listing Updated Successfully!</Alert>
                        ) : null}
                    </div>
                </Card>
            </Container>
        )
    }
}

const mapStateToProps = state => ({
    loading: state.listing.loading,
    thisListing: state.listing.thisListing,
    auth: state.auth,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
});

export default connect(mapStateToProps, { updateListing, loadUser, getListing, setListingsLoading })(EditListing);