import React, { Component } from 'react';
import AboutStatement from './aboutStatement';
import ScoringMethodology from './scoringMethodology';
import Footer from '../welcome/footer';
import ProductFeaturesStreetView from './productFeaturesStreetView';
import ProductFeaturesLaptop from './productFeaturesLaptop';

class AboutIndex extends Component {
    
    async componentDidMount() {
        await this.props.getLocation(this.props.location.pathname);
    }

    render() { 
        return ( 
            <div style={{ minWidth: 'min-content' }}>
                <AboutStatement />
                <ProductFeaturesStreetView />
                <ProductFeaturesLaptop />
                <ScoringMethodology />
                <Footer />
            </div>
        );
    }
}
 
export default AboutIndex;