import React, { Component } from 'react';
import PriceGauge from '../../../Assets/priceGauge.png';
import './demographics.css';

class ResPriceGauge extends Component {
    
    
    render() { 

        
        const { scores: { residentialPricesIndex: resPriceIndex } = {}} = this.props;
        
        let resPriceNeedle; 
        
        if (resPriceIndex >= 95) {
            resPriceNeedle = 85;
        } else if (resPriceIndex >= 90) {
            resPriceNeedle = 75;
        } else if (resPriceIndex >= 85) {
            resPriceNeedle = 65;
        } else if (resPriceIndex >= 80) {
            resPriceNeedle = 45;
        } else if (resPriceIndex >= 75) {
            resPriceNeedle = 30;
        } else if (resPriceIndex >= 70) {
            resPriceNeedle = 20;
        } else if (resPriceIndex >= 65) {
            resPriceNeedle = 10;
        } else if (resPriceIndex >= 60) {
            resPriceNeedle = -10;
        } else if (resPriceIndex >= 55) {
            resPriceNeedle = -20;
        } else if (resPriceIndex >= 50) {
            resPriceNeedle = -30;
        } else if (resPriceIndex >= 45) {
            resPriceNeedle = -45;
        } else if (resPriceIndex >= 40) {
            resPriceNeedle = -65;
        } else if (resPriceIndex >= 35) {
            resPriceNeedle = -75;
        } else if (resPriceIndex >= 0) {
            resPriceNeedle = -85;
        } else {
            resPriceNeedle = 0;
        }

        const setResPricesGauge = (gauge) => {
            setTimeout(() => {
                let resPricesGauge = document.querySelector('.resPriceNeedle');
                let resPricesRotation = gauge;                
                resPricesGauge && resPricesGauge.style.setProperty('--rotation', resPricesRotation);
            }, 200, { once: true });
        }
        
        resPriceIndex && setResPricesGauge(resPriceNeedle);

        return ( 
            <div className='resPriceGauge'>
                {this.props.reportMode ? <img src={PriceGauge} style={{ height: 125, width: 300 }} alt='Res Prices Gauge Report' /> : <img src={PriceGauge} alt='' />}
                <div className='resPriceNeedle'></div>
            </div>
        );
    }
}
 
export default ResPriceGauge;