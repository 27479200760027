import React, { Component } from 'react';
import 'chartjs-plugin-datalabels';
import { Table, Paper } from '@mui/material';
import FourcastLogo from '../../../Assets/fourcast_alt_logo_green.png';
import GreenArrow from '../../../Assets/greenArrow.png';
import RedArrow from '../../../Assets/redArrow.png';
import { Doughnut } from 'react-chartjs-2';
import { FaMoneyBillAlt } from 'react-icons/fa';
import '../../listingDetailsLayouts/demographics/demographics.css';
import './matchComparison.css';

const greenArrow = <img className='greenArrow' src={GreenArrow} alt='' />;
const redArrow = <img className='redArrow' src={RedArrow} alt='' />;

class IncomeMatchComparison extends Component {
    
    
    render() {

        const { listings, inputValueTitle, loading } = this.props;
        
        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.fourcastScore - a.fourcastScore});

        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        };


        let getThisListingsIncome = (thisListing) => {
            
            // INCOME GAUGE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            const thisListingsIncomeGauge = thisListing && thisListing.scores && thisListing.scores.incomeGauge && thisListing.scores.incomeGauge;

            let incomeNeedle = (thisListingsIncomeGauge && (thisListingsIncomeGauge > 0)) ? thisListingsIncomeGauge : 50;


            // INCOME GRAPH DATA !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            // get current geoIdV4 array
            const attomData = thisListing && thisListing.attomData && thisListing.attomData;
            const geoIdV4 = thisListing && thisListing.attomData && thisListing.attomData.geoIdV4 && thisListing.attomData.geoIdV4;
        
            let averageHouseholdIncome = 0;
            let averageIndividualIncome = 0;
            const getTotalNumOfHouseholds = [];

                // number of people in each income bracket
            let householdIncomeFifteenOrLess = 0;
            let householdIncomeFifteenToTwentyFive = 0;
            let householdIncomeTwentyFiveToThirtyFive = 0;
            let householdIncomeThirtyFiveToFifty = 0;
            let householdIncomeFiftyToSeventyFive = 0;
            let householdIncomeSeventyFiveToOneHundred = 0;
            let householdIncomeOneHundredToOneHundredTwentyFive = 0;
            let householdIncomeOneHundredTwentyFiveToOneHundredFifty = 0;
            let householdIncomeOneHundredFiftyToTwoHundred = 0;
            let householdIncomeTwoHundredAndMore = 0;
            
            if (geoIdV4 && geoIdV4.length) {
            
                // avg income numbers
                let primaryIndIncArea = null;
                let secondaryIndIncArea = null;
                let primaryHouseIncArea = null;
                let secondaryHouseIncArea = null;
    
                
                for (let g = 0; g < geoIdV4.length; g++) {
                    
                    // INCOME DATA
                    if (geoIdV4 && (geoIdV4[g].type === 'N4')) {
                        // income data
                        primaryHouseIncArea = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.avg_Household_Income;
                        primaryIndIncArea = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.household_Income_Per_Capita;
                        const getGlobalTotalHouseholdsFamNum = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Non_Family;
                        const getGlobalTotalHouseholdsNonFamNum = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Family;
                        const globalTotalHouseholds = (getGlobalTotalHouseholdsFamNum + getGlobalTotalHouseholdsNonFamNum);
                        getTotalNumOfHouseholds.push(globalTotalHouseholds);
                        householdIncomeFifteenOrLess += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_Less_Than_15000_Pct * .01));
                        householdIncomeFifteenToTwentyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_15000_24999_Pct * .01));
                        householdIncomeTwentyFiveToThirtyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_25000_34999_Pct * .01));
                        householdIncomeThirtyFiveToFifty += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_35000_49999_Pct * .01));
                        householdIncomeFiftyToSeventyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_50000_74999_Pct * .01));
                        householdIncomeSeventyFiveToOneHundred += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_75000_99999_Pct * .01));
                        householdIncomeOneHundredToOneHundredTwentyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_100000_124999_Pct * .01));
                        householdIncomeOneHundredTwentyFiveToOneHundredFifty += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_125000_149999_Pct * .01));
                        householdIncomeOneHundredFiftyToTwoHundred += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_150000_199999_Pct * .01));
                        householdIncomeTwoHundredAndMore += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_200000_And_Over_Pct * .01));
                    } else {
                        // income data
                        secondaryHouseIncArea = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.avg_Household_Income;
                        secondaryIndIncArea = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.household_Income_Per_Capita;
                        const getGlobalTotalHouseholdsFamNum = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Non_Family;
                        const getGlobalTotalHouseholdsNonFamNum = geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Family;
                        const globalTotalHouseholds = (getGlobalTotalHouseholdsFamNum + getGlobalTotalHouseholdsNonFamNum);
                        getTotalNumOfHouseholds.push(globalTotalHouseholds);
                        householdIncomeFifteenOrLess += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_Less_Than_15000_Pct * .01));
                        householdIncomeFifteenToTwentyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_15000_24999_Pct * .01));
                        householdIncomeTwentyFiveToThirtyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_25000_34999_Pct * .01));
                        householdIncomeThirtyFiveToFifty += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_35000_49999_Pct * .01));
                        householdIncomeFiftyToSeventyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_50000_74999_Pct * .01));
                        householdIncomeSeventyFiveToOneHundred += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_75000_99999_Pct * .01));
                        householdIncomeOneHundredToOneHundredTwentyFive += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_100000_124999_Pct * .01));
                        householdIncomeOneHundredTwentyFiveToOneHundredFifty += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_125000_149999_Pct * .01));
                        householdIncomeOneHundredFiftyToTwoHundred += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_150000_199999_Pct * .01));
                        householdIncomeTwoHundredAndMore += (globalTotalHouseholds * (geoIdV4[g].commV4Data && geoIdV4[g].commV4Data.households_Income_200000_And_Over_Pct * .01));
                    }
    
                }
                
                let avgHouseInc = (primaryHouseIncArea === null) ? secondaryHouseIncArea : parseFloat(Math.ceil((primaryHouseIncArea * .6) + (secondaryHouseIncArea * .4)).toFixed(0));
                let avgIndInc = (primaryIndIncArea === null) ? secondaryIndIncArea : parseFloat(Math.ceil((primaryIndIncArea * .6) + (secondaryIndIncArea * .4)).toFixed(0));
                averageIndividualIncome = avgIndInc;
                averageHouseholdIncome = avgHouseInc;
            }
    
            // number of people in each income bracket
            const graphIncomeUnderSeventyFive = [householdIncomeFifteenOrLess, householdIncomeFifteenToTwentyFive, householdIncomeTwentyFiveToThirtyFive, householdIncomeThirtyFiveToFifty, householdIncomeFiftyToSeventyFive];
            const graphIncomeSeventyFiveToOneFifty = [householdIncomeSeventyFiveToOneHundred, householdIncomeOneHundredToOneHundredTwentyFive, householdIncomeOneHundredTwentyFiveToOneHundredFifty];
            
            const underSeventyFiveSum = graphIncomeUnderSeventyFive && graphIncomeUnderSeventyFive.length && graphIncomeUnderSeventyFive.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const seventyFiveToOneFiftySum = graphIncomeSeventyFiveToOneFifty && graphIncomeSeventyFiveToOneFifty.length && graphIncomeSeventyFiveToOneFifty.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const oneFiftyToTwoHundredSum = parseFloat(householdIncomeOneHundredFiftyToTwoHundred).toFixed(0);
            const overTwoHundredSum = parseFloat(householdIncomeTwoHundredAndMore).toFixed(0);
    
            const thisIncomeData = {
                incomeData: {
                    labels: [' Under $75k', '$75k-$150k', '$150k-$200k', 'Over $200k'],
                    datasets: [
                        {
                            backgroundColor: ['rgb(187, 236, 205)', 'rgb(124, 218, 158)', 'rgb(60, 200, 112)', 'rgb(21, 76, 41)'],
                            data: [underSeventyFiveSum, seventyFiveToOneFiftySum, oneFiftyToTwoHundredSum, overTwoHundredSum]
                        }
                    ],
                }
            };
            
            // Income Trends Data
            // Old commData average income for change analyses and historical datapoints
            const totalNumOfHouseholds = getTotalNumOfHouseholds && getTotalNumOfHouseholds.length && getTotalNumOfHouseholds.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });
            const thisOldCommData = thisListing && thisListing.oldCommData && thisListing.oldCommData.length && thisListing.oldCommData.sort(function(a, b){return a.year-b.year});
            
            const hhdNum = thisOldCommData && thisOldCommData.length && thisOldCommData[0] && thisOldCommData[0].oldCommData && thisOldCommData[0].oldCommData.hhd && thisOldCommData[0].oldCommData.hhd;
            const averageHouseholdIncomeIn2021 = thisOldCommData && thisOldCommData.length && thisOldCommData[0] && thisOldCommData[0].oldCommData && thisOldCommData[0].oldCommData.inccyavehh && thisOldCommData[0].oldCommData.inccyavehh;
            
            // insert logic eventually to get the year from the "year" value in the oldCommData object. Right now it is just using 2021, however, the more more "old" neighborhoods being created as the years go on, eventually the year from the created date will have to be extracted.
            //const previousCommDataYear = thisOldCommData && thisOldCommData.length && thisOldCommData[0].year && thisOldCommData[0].year;
            
            const previousCommDataYear = (attomData && attomData.oldCommData) ? attomData.oldCommData.map(oldCommDataObj => oldCommDataObj.year) : '2021';
            const incomeChangeSince2021 = averageHouseholdIncomeIn2021 && parseFloat(((averageHouseholdIncome - averageHouseholdIncomeIn2021) / averageHouseholdIncomeIn2021) * 100).toFixed(0);
            const hhdChangeSince2021 = hhdNum && parseFloat(((totalNumOfHouseholds - hhdNum) / hhdNum) * 100).toFixed(0);
            
            const incomeObj = {address: thisListing.listingAddress, incomeGaugePointer: incomeNeedle, thisIncomeData: thisIncomeData, previousCommDataYear: previousCommDataYear, incomeChangeSince2021: incomeChangeSince2021, hhdChangeSince2021: hhdChangeSince2021, averageIndividualIncome: averageIndividualIncome, averageHouseholdIncome: averageHouseholdIncome};
            return incomeObj;
        };
        
        

        // SORT BY REVIEW COUNT
        const sortedByIncome = sortedByFourcastScore && sortedByFourcastScore.map((listing) => getThisListingsIncome(listing)).sort(function(a, b){return b.incomeGaugePointer - a.incomeGaugePointer});
        
        let listing1 = (sortedByIncome && sortedByIncome.length && (sortedByIncome[0] !== undefined)) ? sortedByIncome[0] : null;
        let listing2 = (sortedByIncome && sortedByIncome.length && (sortedByIncome[1] !== undefined)) ? sortedByIncome[1] : null;
        let listing3 = (sortedByIncome && sortedByIncome.length && (sortedByIncome[2] !== undefined)) ? sortedByIncome[2] : null;
        let listing4 = (sortedByIncome && sortedByIncome.length && (sortedByIncome[3] !== undefined)) ? sortedByIncome[3] : null;
        let listing5 = (sortedByIncome && sortedByIncome.length && (sortedByIncome[4] !== undefined)) ? sortedByIncome[4] : null;
        let listing6 = (sortedByIncome && sortedByIncome.length && (sortedByIncome[5] !== undefined)) ? sortedByIncome[5] : null;
        let listing7 = (sortedByIncome && sortedByIncome.length && (sortedByIncome[6] !== undefined)) ? sortedByIncome[6] : null;
        let listing8 = (sortedByIncome && sortedByIncome.length && (sortedByIncome[7] !== undefined)) ? sortedByIncome[7] : null;

        const commaNumber = (num) => {
            let newNum = Number(num);
            let numString = newNum.toLocaleString("en-US");
            return numString;
        }

        let displayIndIncome = (listing) => {
            return (
                <>
                    {(listing && listing.averageIndividualIncome) ? ( 
                        <div>
                            <div style={{ fontWeight: 800 }}>{`$${listing && commaNumber(listing.averageIndividualIncome)}`}</div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }
        let displayHouseIncome = (listing) => {
            return (
                <>
                    {(listing && listing.averageHouseholdIncome) ? ( 
                        <div>
                            <div style={{ fontWeight: 800 }}>{`$${listing && commaNumber(listing.averageHouseholdIncome)}`}</div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }
        let displayIncomeChange = (listing) => {
            return (
                <>
                    {(listing && listing.incomeChangeSince2021) ? ( 
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                            <div style={{ fontWeight: 800, color: (listing && (listing.incomeChangeSince2021 < 0)) ? 'darkred' : 'darkgreen' }}>{`${listing.incomeChangeSince2021}%`}</div>
                            <div className='incomeArrows'>
                                {(listing && (listing.incomeChangeSince2021 > 0)) ? greenArrow : (listing && (listing.incomeChangeSince2021 < 0)) ? redArrow : '' }
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }
        let displayHouseChange = (listing) => {
            return (
                <>
                    {(listing && listing.hhdChangeSince2021) ? ( 
                        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                            <div style={{ fontWeight: 800, color: (listing && (listing.hhdChangeSince2021 < 0)) ? 'darkred' : 'darkgreen' }}>{`${listing.hhdChangeSince2021}%`}</div>
                            <div className='incomeArrows'>
                                {(listing && (listing.hhdChangeSince2021 > 0)) ? greenArrow : (listing && (listing.hhdChangeSince2021 < 0)) ? redArrow : '' }
                            </div>
                        </div>)
                    : (<div>None</div>)}
                </>
            )
        }

        const previousCommDataYear = sortedByFourcastScore && sortedByFourcastScore.length && sortedByFourcastScore[0] && sortedByFourcastScore[0].previousCommDataYear && sortedByFourcastScore[0].previousCommDataYear;
        
        const setIncomeChart = (listing) => {
            return (
                <div style={{ fontSize: 12, padding: 5, width: '100%', height: 85 }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'start', height: 85, paddingTop: 10 }}>
                        <Table>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }}>Individual Income</th>
                                    <th style={{ textAlign: 'center' }}>Household Income</th>
                                    <th style={{ textAlign: 'center' }}>{`Income Since ${(previousCommDataYear !== undefined ? previousCommDataYear : '2021')}`}</th>
                                    <th style={{ textAlign: 'center' }}>{`Households Since ${(previousCommDataYear !== undefined ? previousCommDataYear : '2021')}`}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ fontSize: 12, textAlign: 'center', paddingTop: 10, color: 'darkgreen' }}>
                                        {listing && displayIndIncome(listing)}
                                    </td>
                                    <td style={{ fontSize: 12, textAlign: 'center', paddingTop: 10, color: 'darkgreen' }}>
                                        {listing && displayHouseIncome(listing)}
                                    </td>
                                    <td style={{ fontSize: 12, textAlign: 'center', paddingTop: 10 }}>
                                        {listing && displayIncomeChange(listing)}
                                    </td>
                                    <td style={{ fontSize: 12, textAlign: 'center', paddingTop: 10 }}>
                                        {listing && displayHouseChange(listing)}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </div>
            )
        }

        const setIncomeGraph = (listing) => {
            return (
                <div style={{ position: 'relative', height: 95, width: '100%' }}>
                    <Doughnut
                        data={listing.thisIncomeData.incomeData}
                        height={95}
                        options={{
                            title: {
                                display: true,
                                text: 'Household Income in the Area',
                                fontSize: 10,
                                color: 'black'
                            },
                            plugins: {
                                datalabels: {
                                display: false,
                                },
                                legend: {
                                    display: true,
                                    position: 'right',
                                    labels: {
                                        boxWidth: 15,
                                        font: {
                                          size: 10
                                        },
                                      },
                                      align: "center",
                                }
                            },
                            responsive: true,
                            maintainAspectRatio: false,
                            scales: {
                                x: {
                                    grid: {
                                        display: false
                                    },
                                    ticks: {
                                        display: false
                                    }
                                },
                            },
                        }}
                    />
                </div>
            )
        }

        const incomeCharts = (
            <div style={{ position: 'relative', width: '98%' }}>
                <div style={{ display: 'flex', justifyContent: 'left', paddingBottom: 10 }}>
                    <div style={{ fontSize: 24, fontWeight: 700, marginLeft: 5, color: 'darkgreen' }}>{inputValueTitle}</div>
                </div>
                <div style={{ position: 'relative', width: '100%' }}>
                    {listing1 !== null ? (<div style={{ position: 'relative' }}>
                        <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                            <div style={{ marginLeft: 5, width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>1</div>
                            <div style={{ marginLeft: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing1.address)}</div>
                            <div style={{ marginLeft: 10, display: 'flex', fontSize: 12, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', color: 'rgb(52, 181, 122)', fontWeight: 700 }}>Best</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ width: '60%' }}>
                                {(listing1 !== null) && setIncomeChart(listing1)}
                            </div>
                            <div style={{ width: '40%' }}>
                                {(listing1 !== null) && setIncomeGraph(listing1)}
                            </div>
                        </div>
                    </div>) : null}
                    {listing2 !== null ? (<div style={{ position: 'relative', marginTop: 15 }}>
                        <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                            <div style={{ marginLeft: 5, width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>2</div>
                            <div style={{ marginLeft: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing2.address)}</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ width: '60%' }}>
                                {(listing2 !== null) && setIncomeChart(listing2)}
                            </div>
                            <div style={{ width: '40%' }}>
                                {(listing2 !== null) && setIncomeGraph(listing2)}
                            </div>
                        </div>
                    </div>) : null}
                    {listing3 !== null ? (<div style={{ position: 'relative', marginTop: 15 }}>
                        <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                            <div style={{ marginLeft: 5, width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>3</div>
                            <div style={{ marginLeft: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing3.address)}</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ width: '60%' }}>
                                {(listing3 !== null) && setIncomeChart(listing3)}
                            </div>
                            <div style={{ width: '40%' }}>
                                {(listing3 !== null) && setIncomeGraph(listing3)}
                            </div>
                        </div>
                    </div>) : null}
                    {listing4 !== null ? (<div style={{ position: 'relative', marginTop: 15 }}>
                        <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                            <div style={{ marginLeft: 5, width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>4</div>
                            <div style={{ marginLeft: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing4.address)}</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ width: '60%' }}>
                                {(listing4 !== null) && setIncomeChart(listing4)}
                            </div>
                            <div style={{ width: '40%' }}>
                                {(listing4 !== null) && setIncomeGraph(listing4)}
                            </div>
                        </div>
                    </div>) : null}
                    {listing5 !== null ? (<div style={{ position: 'relative', marginTop: 15 }}>
                        <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                            <div style={{ marginLeft: 5, width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>5</div>
                            <div style={{ marginLeft: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing5.address)}</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ width: '60%' }}>
                                {(listing5 !== null) && setIncomeChart(listing5)}
                            </div>
                            <div style={{ width: '40%' }}>
                                {(listing5 !== null) && setIncomeGraph(listing5)}
                            </div>
                        </div>
                    </div>) : null}
                    {listing6 !== null ? (<div style={{ position: 'relative', marginTop: 15 }}>
                        <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                            <div style={{ marginLeft: 5, width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>6</div>
                            <div style={{ marginLeft: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing6.address)}</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ width: '60%' }}>
                                {(listing6 !== null) && setIncomeChart(listing6)}
                            </div>
                            <div style={{ width: '40%' }}>
                                {(listing6 !== null) && setIncomeGraph(listing6)}
                            </div>
                        </div>
                    </div>) : null}
                    {listing7 !== null ? (<div style={{ position: 'relative', marginTop: 15 }}>
                        <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                            <div style={{ marginLeft: 5, width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>7</div>
                            <div style={{ marginLeft: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing7.address)}</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ width: '60%' }}>
                                {(listing7 !== null) && setIncomeChart(listing7)}
                            </div>
                            <div style={{ width: '40%' }}>
                                {(listing7 !== null) && setIncomeGraph(listing7)}
                            </div>
                        </div>
                    </div>) : null}
                    {listing8 !== null ? (<div style={{ position: 'relative', marginTop: 15 }}>
                        <div style={{ display: 'flex', justifyContent: 'left', width: '100%' }}>
                            <div style={{ marginLeft: 5, width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>8</div>
                            <div style={{ marginLeft: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing8.address)}</div>
                        </div>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div style={{ width: '60%' }}>
                                {(listing8 !== null) && setIncomeChart(listing8)}
                            </div>
                            <div style={{ width: '40%' }}>
                                {(listing8 !== null) && setIncomeGraph(listing8)}
                            </div>
                        </div>
                    </div>) : null}
                </div>
            </div>
        );


        // PAGE 2 DATA - GLOBAL INCOME GAUGE !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        const setIncomeGaugePointer = async (gauge, el) => {
            setTimeout(async () => {
                const incomeGauge = await document.querySelector(el);
                let incomeRotation = gauge;                
                incomeGauge && await incomeGauge.style.setProperty('--bottomIncome', incomeRotation);
            }, 100, { once: true });
        }

        (listing1 !== null) && listing1.incomeGaugePointer && setIncomeGaugePointer(listing1.incomeGaugePointer, '.matchComparisonIncomeComparisonPointer1');
        (listing2 !== null) && listing2.incomeGaugePointer && setIncomeGaugePointer(listing2.incomeGaugePointer, '.matchComparisonIncomeComparisonPointer2');
        (listing3 !== null) && listing3.incomeGaugePointer && setIncomeGaugePointer(listing3.incomeGaugePointer, '.matchComparisonIncomeComparisonPointer3');
        (listing4 !== null) && listing4.incomeGaugePointer && setIncomeGaugePointer(listing4.incomeGaugePointer, '.matchComparisonIncomeComparisonPointer4');
        (listing5 !== null) && listing5.incomeGaugePointer && setIncomeGaugePointer(listing5.incomeGaugePointer, '.matchComparisonIncomeComparisonPointer5');
        (listing6 !== null) && listing6.incomeGaugePointer && setIncomeGaugePointer(listing6.incomeGaugePointer, '.matchComparisonIncomeComparisonPointer6');
        (listing7 !== null) && listing7.incomeGaugePointer && setIncomeGaugePointer(listing7.incomeGaugePointer, '.matchComparisonIncomeComparisonPointer7');
        (listing8 !== null) && listing8.incomeGaugePointer && setIncomeGaugePointer(listing8.incomeGaugePointer, '.matchComparisonIncomeComparisonPointer8');
        
        if (listing1) {listing1.color = "rgba(254, 39, 18, 0.2)"};
        if (listing2) {listing2.color = "rgba(102, 176, 50, 0.2)"};
        if (listing3) {listing3.color = "rgba(251, 153, 2, 0.2)"};
        if (listing4) {listing4.color = "rgba(52, 124, 152, 0.2)"};
        if (listing5) {listing5.color = "rgb(254, 240, 51, 0.2)"};
        if (listing6) {listing6.color = "rgba(128, 0, 0, 0.2)"};
        if (listing7) {listing7.color = "rgba(2, 71, 254, 0.2)"};
        if (listing8) {listing8.color = "rgba(134, 1, 175, 0.2)"};

        
        const globalIncomeGauge = (
            <div style={{ position: 'relative', width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                    <div style={{ fontSize: 24, fontWeight: 700, color: 'darkgreen' }}>{inputValueTitle}</div>
                    <div style={{ fontSize: 24, fontWeight: 500, marginLeft: 5 }}>cont.</div>
                </div>
                <div className='incomeCompareGaugeArea' style={{ position: 'relative', display: 'flex', marginTop: 100 }}>
                    <div style={{ flex: 1 }}>
                        <div style={{ width: '90%', margin: 'auto auto' }}>
                            <Table style={{ tableLayout: 'fixed' }}>
                                <thead>
                                    <tr style={{ textAlign: 'center' }}>
                                        <th style={{ width: '15%' }}></th>
                                        <th></th>
                                        <th style={{ width: '25%' }}>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><img alt="Fourcast Income Gauge Logo" src={FourcastLogo} style={{ width: 80 }} /></div>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 12, fontWeight: 700, padding: 5, color: 'rgb(52, 181, 122)' }}>Income Gauge</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sortedByIncome && sortedByIncome.map(listing => 
                                        <tr style={{ textAlign: 'center' }}>
                                            <th style={{ width: '20%' }}>
                                                {sortedByIncome && sortedByIncome.length && sortedByIncome.indexOf(listing) === 0 ? <div style={{ display: 'flex', fontSize: 14, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', color: 'rgb(52, 181, 122)', marginLeft: 10, marginTop: 1 }}>Best</div> : <div style={{ display: 'flex', fontSize: 13, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', marginLeft: 10, marginTop: 1 }}>{(sortedByIncome.indexOf(listing) + 1)}</div>}
                                            </th>
                                            <td style={{ border: '1px solid lightgray', fontSize: 14, padding: 10, backgroundColor: listing.color }}>
                                                {listing && addressStyle(listing && listing.address)}
                                            </td>
                                            <td style={{ width: '25%', border: '1px solid lightgray', fontSize: 14, padding: 10, backgroundColor: listing.color }}>
                                                {listing && listing.incomeGaugePointer && `${listing.incomeGaugePointer.toFixed(0)}%`}
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        <div style={{ width: '90%', margin: 'auto auto', marginTop: 150 }}>
                            <Table style={{ tableLayout: 'fixed' }}>
                                <tbody>
                                    <tr style={{ textAlign: 'center' }}>
                                        <th style={{ width: '20%' }}>
                                            <div style={{ display: 'flex', fontSize: 14, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', marginLeft: 10, marginTop: 1 }}>Citywide Gauge</div>
                                        </th>
                                        <td>
                                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                <div style={{ fontWeight: 500, fontSize: 20 }}>Household & Individual Income</div>
                                                <div style={{ marginLeft: 10, marginTop: 1 }}><FaMoneyBillAlt style={{ height: 25, width: 25, color: 'green' }} /></div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className='traffGaugeArea' style={{ flex: 1, width: '100%', height: 100, position: 'relative', marginTop: 25 }}>
                            <div className='matchComparisonDayTrafficComparisonGauge'></div>
                            {((listing1 !== null) && (listing1.incomeGaugePointer)) ? <div className='matchComparisonIncomeComparisonPointer1'></div> : null}
                            {((listing2 !== null) && (listing2.incomeGaugePointer)) ? <div className='matchComparisonIncomeComparisonPointer2'></div> : null}
                            {((listing3 !== null) && (listing3.incomeGaugePointer)) ? <div className='matchComparisonIncomeComparisonPointer3'></div> : null}
                            {((listing4 !== null) && (listing4.incomeGaugePointer)) ? <div className='matchComparisonIncomeComparisonPointer4'></div> : null}
                            {((listing5 !== null) && (listing5.incomeGaugePointer)) ? <div className='matchComparisonIncomeComparisonPointer5'></div> : null}
                            {((listing6 !== null) && (listing6.incomeGaugePointer)) ? <div className='matchComparisonIncomeComparisonPointer6'></div> : null}
                            {((listing7 !== null) && (listing7.incomeGaugePointer)) ? <div className='matchComparisonIncomeComparisonPointer7'></div> : null}
                            {((listing8 !== null) && (listing8.incomeGaugePointer)) ? <div className='matchComparisonIncomeComparisonPointer8'></div> : null}
                            <div className='matchComparisonDayTrafficComparisonGaugeHigh'>High</div>
                            <div className='matchComparisonDayTrafficComparisonGaugeLow'>Low</div>
                        </div>
                    </div>
                </div>
            </div>
        );

        
        return (
            <div style={{ width: '100%' }}>
                <div id='highIncomeAreaMatchComparisonPage1'>
                    <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative' }}>
                        <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ position: 'relative', width: '98%' }}>
                                {(loading === true) ? 'Loading...' : (incomeCharts && incomeCharts)}
                            </div>
                        </div>
                    </Paper>
                </div>
                <div id='highIncomeAreaMatchComparisonPage2'>
                    <Paper className="matchComparisonPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 25, position: 'relative', marginTop: 25 }}>
                        <div style={{ position: 'absolute', top: '2%', left: '75%', color: 'lightgray' }}>www.fourcastscore.com</div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ position: 'relative', width: '98%' }}>
                                {(loading === true) ? 'Loading...' : (globalIncomeGauge && globalIncomeGauge)}
                            </div>
                        </div>
                    </Paper>
                </div>
            </div>
        );
    }
}

export default IncomeMatchComparison;