import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button } from '@material-ui/core';
import { Row } from 'reactstrap';
import FourcastLogo from '../../Assets/fourcast_alt_logo_green.png';
import '../CustomSearch/customSearch.css';
import '../welcome/description.css';


class NotMobileFriendly extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            screenWidth: null,
            mobile: true
        }
    }

    componentDidMount() {
        
        window && window.addEventListener('resize', this.mobileMode.bind(this));
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth });
        } else {
            this.setState({ mobile: false });
        }
    }

    componentWillUnmount() {
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
    }

    mobileMode() {
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth, mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }


    render() { 

        const { screenWidth, mobile } = this.state;
                
        return (
            <div style={{ width: '100%', height: 775, backgroundColor: 'whitesmoke', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Card id='customSearchWelcomePage'>
                    <div>
                        <div style={{ width: `${((screenWidth !== null) && (screenWidth < 1000 )) ? screenWidth : 500}`, marginLeft: 'auto', marginRight: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 20 }}>
                            <img id='welcomePageMatchLogo' style={{ width: 200 }} alt='Try Fourcast' src={FourcastLogo} />
                        </div>
                        <div style={{ marginTop: 25 }}>
                            <div style={{ marginTop: 'auto', marginBottom: 'auto', height: 450, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <div style={{ height: '100%' }}>
                                    <div>
                                        <Row className='fourcastMatchWelcomePageDesc' style={{ marginLeft: 'auto', marginRight: 'auto', height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '85%', textAlign: 'center', fontSize: 24, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif' }}>This page is not mobile-friendly.</div>
                                        </Row>
                                    </div>
                                    <div className='fourcastMatchWelcomePageButtons'>
                                        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', width: '80%', marginLeft: 'auto', marginRight: 'auto', height: 100 }}>
                                            <Link style={{ marginTop: 20, marginRight: 'auto', marginLeft: 'auto', textDecoration: 'none' }} to={`/listings`}>
                                                <Button style={{ color: 'white', backgroundColor: '#007FFF' }} variant="contained" size="large">Browse Listings</Button>
                                            </Link>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}
 

export default NotMobileFriendly;