import React, { Component } from 'react';
import { Table } from '@mui/material';
import YelpStarRating from '../../listingDetailsLayouts/competition/yelpStarRating';
import yelplogo from '../../../Assets/yelplogo.png';
import '../../listingDetailsLayouts/competition/competition.css';
import '../compareListings.css';

class PopularBizTable extends Component {
    

    render() {

        const { listings } = this.props;

        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.scores.fourcastScore - a.scores.fourcastScore});
        
        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        }

        let getThisListingsPopularBusinesses = (thisListing) => {
            
            const yelpBusinesses = thisListing && thisListing.yelpData && thisListing.yelpData.businesses && thisListing.yelpData.businesses;
            
            const topBusinesses = yelpBusinesses && yelpBusinesses.sort((a, b) => {
                return b.review_count - a.review_count
            });
            const topTenBusinesses = topBusinesses && topBusinesses.slice(0, 9);
            
            let filteredBusinesses = topTenBusinesses && topTenBusinesses.map((business) => {
                return (
                    <tr style={{ border: '1px solid #96D4D4' }}>
                        <th style={{ fontSize: 12, padding: 5, height: 60 }}>
                            {business.name}
                        </th>
                        <td style={{ padding: 5, width: 100, height: 60 }}>
                            <YelpStarRating business={business} />
                        </td>
                        <td style={{ fontSize: 12, padding: 5, width: 75, height: 60 }}>
                            {business.review_count}
                        </td>
                        <td style={{ fontSize: 12, width: 50, height: 60 }}>
                            {business.price}
                        </td>
                        <td style={{ fontSize: 12, padding: 5, width: 95, height: 60 }}>
                            {business.categories[0].title}
                        </td>
                    </tr>
                )
            });
            
            
            const popularBizObj = {address: thisListing.listingAddress, filteredBusinesses: filteredBusinesses};
            return popularBizObj;
        }

        // SORT BY REVIEW COUNT
        const filteredListings = sortedByFourcastScore && sortedByFourcastScore.map(listing => getThisListingsPopularBusinesses(listing));

        const { popBizContinued } = this.props;

        return (
            <div style={{ position: 'relative', width: '98%' }}>
                {(popBizContinued !== true) ? (<div style={{ display: 'flex', justifyContent: 'left' }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Popular Businesses Nearby</div>
                    <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}>
                        <img style={{ width: 50, height: 25 }} src={yelplogo} alt='' />
                    </div>
                </div>) : (<div style={{ display: 'flex', justifyContent: 'left' }}>
                    <div style={{ fontSize: 24, fontWeight: 500 }}>Popular Businesses Cont.</div>
                    <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}>
                        <img style={{ width: 50, height: 25 }} src={yelplogo} alt='' />
                    </div>
                </div>)}
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', marginTop: 40, justifyContent: 'space-around' }}>
                    {filteredListings && filteredListings.map(listing => 
                        <div style={{ width: 375 }}>
                            <div style={{ padding: 20, color: 'darkgreen', textAlign: 'center', fontWeight: 800 }}>{addressStyle(listing.address)}</div>
                            <div style={{ flex: 1 }}>
                                <Table style={{ tableLayout: 'fixed', border: '1px solid lightgray' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ fontSize: 12 }}>Name</th>
                                            <th style={{ width: 90, fontSize: 12 }}>Rating</th>
                                            <th style={{ width: 60, fontSize: 12 }}>Reviews</th>
                                            <th style={{ width: 40, fontSize: 12 }}>Price</th>
                                            <th style={{ width: 85, fontSize: 12 }}>Category</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listing && listing.filteredBusinesses}
                                    </tbody>
                                </Table>
                            </div>
                            <hr />
                        </div>)}
                </div>
            </div>
        );
    }
}
 
export default PopularBizTable;