import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import './traffic.css';

class TotalReviewCount extends Component {
    

    render() { 

        const { scores, screenWidth } = this.props;
        const { yelpData: { businesses: theseBusinesses } = {}} = this.props;

        let thisListingsReviewCount = 0;
        let reviewCountGaugePointer = 0;
        
        if (theseBusinesses) {
            for (let i = 0; i < theseBusinesses.length; i++) {
                thisListingsReviewCount += theseBusinesses[i].review_count;
            }
        }

        if (scores) {
            if (scores.totalReviewsGauge < 55) {
                reviewCountGaugePointer = 0;
            } else if (scores.totalReviewsGauge >= 55 && scores.totalReviewsGauge < 60) {
                reviewCountGaugePointer = 15;
            } else if (scores.totalReviewsGauge >= 60 && scores.totalReviewsGauge < 65) {
                reviewCountGaugePointer = 30;
            } else if (scores.totalReviewsGauge >= 65 && scores.totalReviewsGauge < 70) {
                reviewCountGaugePointer = 45;
            } else if (scores.totalReviewsGauge >= 70 && scores.totalReviewsGauge < 75) {
                reviewCountGaugePointer = 60;
            } else if (scores.totalReviewsGauge >= 75 && scores.totalReviewsGauge < 80) {
                reviewCountGaugePointer = 75;
            } else if (scores.totalReviewsGauge >= 80 && scores.totalReviewsGauge < 85) {
                reviewCountGaugePointer = 90;
            } else if (scores.totalReviewsGauge >= 85 && scores.totalReviewsGauge < 90) {
                reviewCountGaugePointer = 105;
            } else if (scores.totalReviewsGauge >= 90 && scores.totalReviewsGauge < 95) {
                reviewCountGaugePointer = 120;
            } else {
                reviewCountGaugePointer = 125;
            }
        }

        const setRevCountGaugePointer = (gauge) => {
            setTimeout(() => {
                let revCountGauge = document.querySelector('.layoutReviewCountPointer');
                let thisBottom = gauge;
                revCountGauge && revCountGauge.style.setProperty('--bottomReview', thisBottom);
            }, 100)
        }

        scores && setRevCountGaugePointer(reviewCountGaugePointer);

        const commaNumber = (num) => {
            let newNum = Number(num);
            let numString = newNum.toLocaleString("en-US");
            return numString;
        }

        
        return ( 
            <div>
                {this.props.reportMode ? null : <p className="engagementTitle">Engagement</p>}
                {((screenWidth > 1000) || (screenWidth === undefined)) ? (<Row style={{ width: 420, height: 140 }}>
                    <Col sm={5}>
                        <div className="totalReviewCount">
                            <div style={{ fontSize: 12 }}>People Talking About This Area</div>
                            <div style={{ marginTop: 5 }}>
                                {commaNumber(thisListingsReviewCount)}
                            </div>
                        </div>
                    </Col>
                    <Col sm={7}>
                        <div className='revCountHigh'>High</div>
                        <div className='reviewCountGauge'></div>
                        <div className='layoutReviewCountPointer'></div>
                        <div className='revCountLow'>Low</div>
                    </Col>
                </Row>) : (<div style={{ width: screenWidth, height: 140, display: 'grid', gridTemplateColumns: '5fr 7fr', position: 'relative' }}>
                    <div style={{ position: 'relative' }}>
                        <div className="totalReviewCount" style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', textAlign: 'center' }}>
                            <div style={{ width: '75%' }}>
                                <div style={{ fontSize: 12 }}>People Talking About This Area</div>
                                <div style={{ marginTop: 5 }}>
                                    {commaNumber(thisListingsReviewCount)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ position: 'relative' }}>
                        <div className='revCountHigh'>High</div>
                        <div className='reviewCountGauge'></div>
                        <div className='layoutReviewCountPointer'></div>
                        <div className='revCountLow'>Low</div>
                    </div>
                </div>)}
            </div>
        );
    }
}
 
export default TotalReviewCount;