import React, { Component } from 'react';
import { Container, Row } from 'reactstrap';
import { FaWalking, FaIndustry } from 'react-icons/fa';
import { BsPeopleFill } from 'react-icons/bs';
import { IoStorefront } from 'react-icons/io5';
import productFeaturesStreet from '../../Assets/productFeatures_street.png';
import '../welcome/description.css';

class ProductFeatures extends Component {
    

    render() { 


        return ( 
            <Container fluid style={{ height: 750 }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 44, fontWeight: 'bold', textAlign: 'center', marginTop: 50 }}>More than just price & size. Much more.</div>
                </div>
                <div style={{ display: 'grid', gridTemplateColumns: '2fr 3fr', paddingTop: 50 }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                        <div style={{ width: '80%' }}>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 42 }}>Getting the big picture</div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 10 }}>
                                <p>Combining interior and exterior characteristics of a property enables you to get an accelerated understanding of each space without having to physically go out and visit each one.</p>
                            </div>
                            <div style={{ paddingTop: 30, textAlign: 'left', fontSize: 16, fontFamily: 'Arial, Helvetica, sans-serif' }}>
                                <Row style={{ height: 50, paddingLeft: 20 }}>
                                    <FaWalking style={{ width: 30, height: 30, color: 'rgb(52, 181, 122)' }} />
                                    <span style={{ fontWeight: 'bold', marginLeft: 10, paddingTop: 5 }}>Traffic</span>
                                </Row>
                                <Row style={{ height: 50, paddingLeft: 20 }}>
                                    <BsPeopleFill style={{ width: 30, height: 30, color: 'rgb(52, 181, 122)' }} />
                                    <span style={{ fontWeight: 'bold', marginLeft: 10, paddingTop: 5 }}>Demographics</span>
                                </Row>
                                <Row style={{ height: 50, paddingLeft: 20 }}>
                                    <FaIndustry style={{ width: 30, height: 30, color: 'rgb(52, 181, 122)' }} />
                                    <span style={{ fontWeight: 'bold', marginLeft: 10, paddingTop: 5 }}>Competition</span>
                                </Row>
                                <Row style={{ height: 50, paddingLeft: 20 }}>
                                    <IoStorefront style={{ width: 30, height: 30, color: 'rgb(52, 181, 122)' }} />
                                    <span style={{ fontWeight: 'bold', marginLeft: 10, paddingTop: 5 }}>Property</span>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={productFeaturesStreet} alt="product features street view" />
                    </div>
                </div>
            </Container>
         );
    }
}
 
export default ProductFeatures;
