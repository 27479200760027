import React, { Component } from 'react';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { getListings, getListing } from '../../actions/listingActions';
import { loadUser, updateUser } from '../../actions/authActions';
import Select from 'react-select';
import PropTypes from 'prop-types';
import './compareListings.css';



class CompareListingsList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            noDataAlert: false,
            selected: [],
        }
    }
    
    async componentDidMount() {
        
        const { isAuthenticated, user } = this.props;
        
        if (user && isAuthenticated) {
            
            try {
                let pageAddresses;
                pageAddresses = { path: "listingAddresses" };
                await this.props.getListings(pageAddresses);
            } catch (err) {
                console.log(err);
            }

        }
    }

    // Select the listings to generate reports for
    setSelected = async (selectedListings) => {
        this.setState({ selected: selectedListings });
    }

    // Get the selected listing's data
    getSelectedListings = async (selected) => {
    
        if (selected.length) {
            for (let i = 0; i < selected.length; i++) {
                try {
                    let listingId;
                    listingId = selected[i].value;
                    await this.props.getListing(listingId);
                } catch (err) {
                    console.log(err);
                }
            }
        }
    }


    async componentDidUpdate(prevState, prevProps) {

        if (this.state.noDataAlert === true) {
            setTimeout(() => {
                this.setState({ noDataAlert: false });
            }, 7500)
        }
        
    }

    async onSubmit() {
        const { selected } = this.state;

        if (!selected.length) {
            this.setState({ noDataAlert: true });
        }

        if (selected.length) {
            await this.props.setSelected(selected);
        }
        
    }

    
    render() {

        const filterLabels = (label) => {    
    
            switch(label) {
                case 'mostVisitedPlacesData':
                    return (
                        "Most Visited Places Nearby"
                    )
                case 'busyTimesOfDay':
                    return (
                        "Daytime Traffic & Nightlife"
                    )
                case 'accessibilityAndEngagement':
                    return (
                        "Accessibility & Engagement"
                    )
                case 'incomeData':
                    return (
                        "Income Data"
                    )
                case 'populationData':
                    return (
                        "Population Data"
                    )
                case 'residentialREData':
                    return (
                        "Residential Real Estate Data"
                    )
                case 'popularBizData':
                    return (
                        "Most Popular Businesses Nearby"
                    )
                case 'businessDensityAndCommonBizTypes':
                    return (
                        "Business Density & Common Business Types"
                    )
                case 'highEndData':
                    return (
                        "High-End Business Data"
                    )
                default:
                    return null
            }
        }

        const { selected, noDataAlert } = this.state;
        const { listings, user: { engagement: { savedListings } = {}} = {}} = this.props;
        const listingAddresses = listings && listings.map(listing => {return {label: listing.listingAddress, value: listing.id}});
        const userSavedListings = listings && listings.filter(listing => savedListings.some(savedId => savedId === listing.id)).map(listing => {return {label: listing.listingAddress, value: listing.id}});

        const listingOptions = [
            { label: 'Saved', options: userSavedListings },
            { label: "Active Listings", options: listingAddresses },
        ];

        const groupStyles = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          };
          const groupBadgeStyles = {
            backgroundColor: '#EBECF0',
            borderRadius: '2em',
            color: 'black',
            display: 'inline-block',
            fontSize: 12,
            fontWeight: 'normal',
            lineHeight: '1',
            minWidth: 1,
            padding: '0.16666666666667em 0.5em',
            textAlign: 'center',
            overflowY: 'scroll'
          };
          
          const formatGroupLabel = data => (
            <div style={groupStyles}>
              <span>{data.label}</span>
              <span style={groupBadgeStyles}>{data.options.length}</span>
            </div>
          );
        

        return (
            <div style={{ width: '100%', height: 200, position: 'relative', backgroundColor: 'rgba(21, 134, 84, .4)', zIndex: 20 }}>
                <div style={{ marginLeft: 'auto', marginRight: 'auto', height: 100, width: '90%', paddingTop: 25 }}>
                    <Select 
                        options={listingOptions}
                        styles={{
                            control: (baseStyles, state) => ({
                            ...baseStyles,
                            width: '95%',
                            margin: 'auto',
                            minHeight: 50,
                            maxHeight: 75,
                            overflowY: 'scroll',
                            border: ((!selected.length && (noDataAlert === true)) || (selected.length > 4)) ? '2px solid darkred' : null
                            }),
                        }}
                        formatGroupLabel={formatGroupLabel}
                        isMulti={true}
                        placeholder="Enter Listing Address or Browse Listings..."
                        onChange={(values) => {                            
                            this.setState({ selected: values });
                        }}
                        defaultValue={selected}
                    />
                </div>
                <div style={{ height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ marginRight: 10, fontSize: 14, fontWeight: 500 }}>Select up to (4) listings</div>
                    <Button id='createReportBtn' variant='contained' style={{ backgroundColor: `${((selected.length <= 1) || (selected.length > 4)) ? 'lightgray' : 'rgb(21, 134, 84)'}` }} onClick={() => this.onSubmit()} disabled={((selected.length > 4) || (selected.length <= 1))}>Compare Listings</Button>
                </div>
            </div>
        )
    }
}

CompareListingsList.propTypes = {
    getListings: PropTypes.func.isRequired,
    listings: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
    listings: state.listing.listings,
    thisListing: state.listing.thisListing,
});

export default connect(mapStateToProps, { getListings, getListing, loadUser, updateUser })(CompareListingsList);