import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { Button } from '@material-ui/core';
import { FaMapMarker, FaQuestion } from 'react-icons/fa';
import { IoStorefrontOutline } from 'react-icons/io5';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateUser, loadUser } from '../../actions/authActions';
import '../welcome/description.css';

class PaymentSuccess extends Component {

    constructor(props) {
        super(props);
        this.state = {
            accountType: ''
        }
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        paymentInfoNeeded: PropTypes.bool,
        error: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        stripeSubData: PropTypes.object.isRequired,
        loadUser: PropTypes.func.isRequired,
        updateUser: PropTypes.func.isRequired
    }

    async componentDidMount() {
        await this.props.loadUser();
    }

    async componentDidUpdate(prevProps) {
        const { user } = this.props;
        const stripeSubData = user && user.stripeSubData;
        const stripeSubStatus = stripeSubData && stripeSubData.status;
        const stripeSubType = stripeSubData && stripeSubData.subType.toLowerCase();

        const userType = user && user.accountType;

        if (((stripeSubStatus === 'active') || (stripeSubStatus === 'trialing')) && (stripeSubType !== userType)) {
            let updatedUser = {
                userId: user._id,
                accountType: stripeSubType,
                engagement: user.engagement
            };
        
            updatedUser && updatedUser ? await this.props.updateUser(updatedUser) : console.log('Could Not Update User');
        }
    }
    

    render() { 

        const { user } = this.props;

        return ( 
            <Container className='descContainer' fluid>
                {(user && user) ? 
                (<><div style={{ width: '100%', backgroundColor: 'whitesmoke', textAlign: 'center', color: 'black', paddingTop: 50, wordSpacing: 3, height: 250, justifyContent: 'center', alignItems: 'center' }}>
                    <IoMdCheckmarkCircleOutline style={{ width: 40, height: 40, color: 'rgb(52, 181, 122)' }} id='paymentSuccessCheckmark' />
                    <h2 style={{ marginTop: 20 }}>Signup Successful!</h2>
                    <h6 style={{ marginTop: 20 }}>Your Account Has Been Created.</h6>
                </div>
                <div className='description'>
                    <div className='scoreTypes' style={{ width: 1150, margin: 'auto', paddingTop: 20, display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 10, textAlign: 'center', position: 'relative' }}>
                        <div style={{ height: 450 }}>
                            <Row style={{ height: '50%', position: 'relative', marginLeft: 'auto', marginRight: 'auto' }}>
                                <div className='descriptionGraphic'>
                                    <FaMapMarker id='descStoreMarker' />
                                    <IoStorefrontOutline id='descStorefront' />
                                </div>
                            </Row>
                            <Row style={{ height: '50%', width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                                <Row style={{ marginLeft: 'auto', marginRight: 'auto', height: 125 }}>
                                    <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '80%', textAlign: 'center', fontSize: 18, fontFamily: 'Arial, Helvetica, sans-serif' }}>Discover and compare available spaces in your city.</div>
                                </Row>
                                <Row style={{ textAlign: 'center', width: '70%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <Link to="/listings" style={{ textDecoration: 'none', marginLeft: 'auto', marginRight: 'auto' }}>
                                        <Button
                                            variant="text"
                                            category='browseListings'
                                            style={{ height: 50, fontSize: 14, color: 'rgb(223, 82, 69)', textDecoration: 'underline', textUnderlineOffset: 8, textDecorationThickness: 2, outline: 'none' }}
                                        >
                                        Browse Listings
                                        </Button>
                                    </Link>
                                </Row>    
                            </Row>
                        </div>
                        <div style={{ height: 450 }}>
                            <Row style={{ height: '50%', position: 'relative' }}>
                                <div className='descriptionGraphic'>
                                    <div className='trapezoid'></div>
                                    <div className='trapezoid1'>
                                        <FaMapMarker id='trap1' />
                                        <FaMapMarker id='trap2' />
                                        <FaMapMarker id='trap3' />
                                    </div>
                                </div>
                            </Row>
                            <Row style={{ height: '50%', width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                                <Row style={{ marginLeft: 'auto', marginRight: 'auto', height: 125 }}>
                                    <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '80%', textAlign: 'center', fontSize: 18, fontFamily: 'Arial, Helvetica, sans-serif' }}>Get instantly matched with spaces and areas that fit exactly what you're looking for.</div>
                                </Row>
                                <Row style={{ textAlign: 'center', width: '70%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <Link to="/customSearch" style={{ textDecoration: 'none', marginLeft: 'auto', marginRight: 'auto' }}>
                                        <Button
                                            variant="text"
                                            category='fourcastMatch'
                                            style={{ height: 50, fontSize: 14, color: 'rgb(223, 82, 69)', textDecoration: 'underline', textUnderlineOffset: 8, textDecorationThickness: 2, outline: 'none' }}
                                        >
                                        Fourcast Match
                                        </Button>
                                    </Link>
                                </Row>
                            </Row>
                        </div>
                        <div style={{ height: 450 }}>
                            <Row style={{ height: '50%' }}>
                                <div className='descriptionGraphic'>
                                    <FaQuestion id='descQuestion' />
                                </div>
                            </Row>
                            <Row style={{ height: '50%', width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                                <Row style={{ marginLeft: 'auto', marginRight: 'auto', height: 125 }}>
                                    <div style={{ marginLeft: 'auto', marginRight: 'auto', width: '80%', textAlign: 'center', fontSize: 18, fontFamily: 'Arial, Helvetica, sans-serif' }}>A platform that helps match businesses with viable spaces, and match landlords with viable tenants.</div>
                                </Row>
                                <Row style={{ textAlign: 'center', width: '70%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <Link to="/about" style={{ textDecoration: 'none', marginLeft: 'auto', marginRight: 'auto' }}>
                                        <Button
                                            variant="text"
                                            category='howItWorks'
                                            style={{ height: 50, marginLeft: 'auto', marginRight: 'auto', fontSize: 14, color: 'rgb(223, 82, 69)', textDecoration: 'underline', textUnderlineOffset: 8, textDecorationThickness: 2, outline: 'none' }}
                                        >
                                        How It Works
                                        </Button>
                                    </Link>
                                </Row>
                            </Row>
                        </div>
                    </div>
                </div></>) : (
                    <Link style={{ fontFamily: 'Arial, Helvetica, sans-serif', color: 'darkblue', fontSize: 18, textDecoration: 'none' }} to='/'>
                        Back to Home
                    </Link>
                )}
            </Container>
         );
    }
}
 
const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    auth: state.auth,
    error: state.error,
});

export default connect(mapStateToProps, { loadUser, updateUser })(PaymentSuccess);