export const GET_LISTINGS = 'GET_LISTINGS';
export const GET_SAVED_LISTINGS = 'GET_SAVED_LISTINGS';
export const ADD_LISTING = 'ADD_LISTING';
export const ADD_LISTING_FAIL = 'ADD_LISTING_FAIL';
export const LISTINGS_LOADING = 'LISTINGS_LOADING';
export const GET_LISTING = 'GET_LISTING';
export const UPDATE_LISTING = 'UPDATE_LISTING';
export const SAVE_LISTING = 'SAVE_LISTING';
export const SAVE_LISTING_FAIL = 'SAVE_LISTING_FAIL';

export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const GET_LEADS = 'GET_LEADS';
export const ADD_LEAD = 'ADD_LEAD';
export const LEADS_LOADING = 'LEADS_LOADING';
export const GET_LEAD = 'GET_LEAD';
export const UPDATE_LEAD = 'UPDATE_LEAD';
export const UPDATE_USER = 'UPDATE_USER';

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET';

export const FORGOT_PASSWORD_SENT = 'FORGOT_PASSWORD_SENT';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const GET_PRICES = 'GET_PRICES';
export const STRIPE_LOADING = 'STRIPE_LOADING';
export const PRICES_ERROR = 'PRICES_ERROR';
