import React from 'react';
import SkeletonElement from './skeletonElement';
import Shimmer from './shimmer';
import { FaMapMarkerAlt } from 'react-icons/fa';
import './skeleton.css';

const SkeletonDetailsPage = () => {
    
    return (
        <div className="skeleton-wrapper" style={{ width: '100vw', height: 625, position: 'absolute', left: '0%', top: '8.75%' }}>
            <div className="skeleton-details-left" style={{ width: '60vw', height: 600 }}>
                <div className="skeleton-banner">
                    <div>
                        <SkeletonElement type="title" />
                        <SkeletonElement type="text" />
                    </div>
                    <div>
                        <SkeletonElement type="title" />
                        <SkeletonElement type="text" />
                    </div>
                    <div>
                        <SkeletonElement type="title" />
                        <SkeletonElement type="text" />
                    </div>
                </div>
                <div className="skeleton-map" style={{ marginTop: 20 }}>
                    <SkeletonElement type="map" />
                    <FaMapMarkerAlt className="skeleton-map-marker-details" />
                </div>
            </div>
            <div className="skeleton-details-right" style={{ width: '35vw', height: 625 }}>
                <div className="skeleton-details-category" style={{ marginTop: 0 }}>
                    <SkeletonElement type="title" />
                    <SkeletonElement type="text" />
                    <SkeletonElement type="text" />
                </div>
                <div className="skeleton-details-category">
                    <SkeletonElement type="title" />
                    <SkeletonElement type="text" />
                    <SkeletonElement type="text" />
                </div>
                <div className="skeleton-details-category">
                    <SkeletonElement type="title" />
                    <SkeletonElement type="text" />
                    <SkeletonElement type="text" />
                </div>
                <div className="skeleton-details-category">
                    <SkeletonElement type="title" />
                    <SkeletonElement type="text" />
                    <SkeletonElement type="text" />
                </div>
            </div>
            <Shimmer />
        </div>
    )
}

export default SkeletonDetailsPage;