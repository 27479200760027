import React, { Component } from 'react';
import { Container } from 'reactstrap';
import roadMap from '../../Assets/mobile_roadmap.png';
import './description.css';

class MobileUserRoadmap extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            mobile: false,
        }
    }

    componentDidMount() {
        window && window.addEventListener('scroll', this.setContainerBackgroundColor.bind(this));
        window && window.addEventListener('scroll', this.fadeInCircles.bind(this));
        window && window.addEventListener('resize', this.mobileMode.bind(this));
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }

    componentWillUnmount() {
        window && window.removeEventListener('scroll', this.setContainerBackgroundColor.bind(this));
        window && window.removeEventListener('scroll', this.fadeInCircles.bind(this));
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
    }

    setContainerBackgroundColor() {
        if (window && (window.scrollY > 0)) {
            this.setState({ demoVidArea: true })
        } else {
            this.setState({ demoVidArea: false })
        }
    }

    fadeInCircles() {
        if (window && (window.scrollY > 200)) {
            
            setTimeout(() => {
                this.setState({ circle1: true })
            }, 250);
            setTimeout(() => {
                this.setState({ circle2: true })
            }, 500);
            setTimeout(() => {
                this.setState({ circle3: true })
            }, 750);
            setTimeout(() => {
                this.setState({ circle4: true })
            }, 1000);
            setTimeout(() => {
                this.setState({ circle5: true })
            }, 1250);
            setTimeout(() => {
                this.setState({ circle6: true })
            }, 1500);
                
        } else {
            this.setState({ demoVidArea: false })
        }
    }

    mobileMode() {
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }

    render() { 

        return ( 
            <Container style={{ backgroundColor: 'rgba(14, 170, 100, 0.8)', paddingTop: 50, paddingBottom: 50 }} fluid>
                <div>
                    <div className='perfectSpace'>
                        <h2 className='descPageAreaTitleBlack'>Find The Perfect Space For Your Business </h2>
                    </div>
                    <div style={{ width: '90%', margin: 'auto', paddingTop: 50, textAlign: 'center', position: 'relative' }}>
                        <img src={roadMap} style={{ width: '100%' }} alt="welcome page roadmap" />
                    </div>
                </div>
            </Container>
         );
    }
}
 
export default MobileUserRoadmap;