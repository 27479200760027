import React, { Component } from 'react';
import Welcome from './welcome';
import Description from './description';
import AMDFHighlight from './amdfHighlight';
import UserValueProp from './userValueProp';
import UXDescription from './uxDescription';
import Footer from './footer';
import UserRoadmap from './userRoadmap';
import DiscoverTools from './discoverTools';
import MobileDiscoverTools from './mobileDiscoverTools';
import MobileUserRoadmap from './mobileUserRoadmap';
import MobileDescription from './mobileDescription';
import MobileUserValueProp from './mobileUserValueProp';
import MobileAMDFHighlight from './mobileAMDFHighlight';
import MobileUXDescription from './mobileUXDescription';
import MobileFooter from './mobileFooter';
import MobileWelcome from './mobileWelcome';

class WelcomeIndex extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            mobile: false,
        }
    }

    componentDidMount() {
        this.props.getLocation(this.props.location.pathname);
        window && window.addEventListener('resize', this.mobileMode.bind(this));
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }

    componentWillUnmount() {
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
    }

    mobileMode() {
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }

    render() { 

        const { mobile } = this.state;

        return ( 
            <div style={{ minWidth: 'min-content' }}>
                {mobile ? <MobileWelcome /> : <Welcome />}
                {mobile ? <MobileUserRoadmap /> : <UserRoadmap />}
                {mobile ? <MobileDiscoverTools /> : <DiscoverTools />}
                {mobile ? <MobileUserValueProp /> : <UserValueProp />}
                {mobile ? <MobileDescription /> : <Description />}
                {mobile ? <MobileAMDFHighlight /> : <AMDFHighlight />}
                {mobile ? <MobileUXDescription /> : <UXDescription />}
                {mobile ? <MobileFooter /> : <Footer />}
            </div>
        );
    }
}
 
export default WelcomeIndex;