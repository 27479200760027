import React, { Component } from 'react';
import { 
    Modal, 
    ModalHeader, 
    ModalBody, 
    Row,
    Col } 
from 'reactstrap';
import { Button } from '@material-ui/core';
import 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import './competition.css';

class PriceGaugeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            thisListingsPriceVolume: props.thisListingsPriceVolume,
            percentage: props.percentage,
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    }

    render() {
        const { thisListingsPriceVolume } = this.props;
        const { percentage } = this.props;

        return (
            <div>
                <Button
                    onClick={this.toggle}
                    variant="text"
                    style={{ outline: 'none', fontWeight: 'bold', color: 'dodgerblue' }}
                >
                    Details
                </Button>
                <Modal style={{ minWidth: 850 }} isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>High-End Gauge</ModalHeader>
                    <ModalBody style={{ width: 850, margin: 'auto' }}>
                    <Row>
                        <Col sm={4}>
                            <p>
                                Chart indicates the volume of businesses that have an average price level of $, $$, $$$, or $$$$:
                            </p>
                        </Col>
                        <Col sm={8}>
                        <div className="priceChart" style={{ paddingRight: 10 }}>
                            <Bar
                            data={{
                                labels: ['$$$$', '$$$', '$$', '$'],
                                datasets: [
                                    {
                                        label: 'Number of Businesses',
                                        data: [
                                            `${thisListingsPriceVolume.priceLevel4}`,
                                            `${thisListingsPriceVolume.priceLevel3}`,
                                            `${thisListingsPriceVolume.priceLevel2}`,
                                            `${thisListingsPriceVolume.priceLevel1}`
                                        ],
                                        backgroundColor: [
                                            'rgba(255, 99, 132, 0.8)',
                                            'rgba(54, 162, 235, 0.8)',
                                            'rgba(255, 206, 86, 0.8)',
                                            'rgba(75, 192, 192, 0.8)',
                                        ]
                                    }
                                ]
                            }}
                            height={150}
                            options={{
                                padding: 20,
                                title: {
                                    display: true,
                                    text: 'Price Level Distribution',
                                    fontSize: 18,
                                    color: 'black'
                                },
                                plugins: {
                                    datalabels: {
                                    display: true,
                                    color: 'black'
                                    }
                                },
                                scales: {
                                    xAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        },
                                        gridLines: {
                                            display: false
                                        }
                                    }],
                                    yAxes: [{
                                        stacked: true,
                                        ticks: {
                                            fontSize: 14,
                                        },
                                        gridLines: {
                                            display: false
                                        },
                                    }],
                                },
                            }} 
                            />
                            </div>
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col sm={4}>
                            <p>
                                High-End Gauge analyzes:
                            </p>
                            <p style={{ fontStyle: 'italic', fontSize: 12 }}>
                                - Price Level Average
                            </p>
                            <p style={{ fontStyle: 'italic', fontSize: 12 }}>
                                - Price Level Volume
                            </p>
                        </Col>
                        <Col sm={8}>
                            <div style={{ textAlign: 'center', fontSize: 14, fontWeight: 'bold', width: "65%", marginLeft: 'auto', marginRight: 'auto' }}>
                                <span>This Listing Has A Higher</span><span style={{ fontStyle: 'italic', color: 'green' }}> Price-Level Average</span><span> or More</span><span style={{ color: 'green' }}> $$$</span><span> &</span><span style={{ color: 'green' }}> $$$$</span><span> Businesses Nearby Than:</span>
                            </div>
                            <div style={{ textAlign: 'center', marginTop: 25, marginBottom: 25, color: 'darkgreen' }}>
                                <h1>{percentage}%</h1>
                            </div>
                            <div style={{ textAlign: 'center', fontSize: 14, fontWeight: 'bold' }}>
                                of Other Listings in This City
                            </div>
                        </Col>
                    </Row>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
 
export default PriceGaugeModal;