import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { Button, Card } from '@mui/material';
import { MdLocationPin, MdPeopleOutline, MdExposureNeg2, MdTravelExplore, MdFileDownload } from 'react-icons/md';
import productFeaturesLaptop from '../../Assets/productFeatures_laptop.png';
import fourcastLogo from '../../Assets/fourcastLogo.png';
import productMapScreen from '../../Assets/productFeatures_mapHighlight.png';
import productMapSquare from '../../Assets/productFeatures_mapHighlightSquare.png';
import productScoreSquare from '../../Assets/productFeatures_score.png';
import productFourcastMatchHome from '../../Assets/productFeatures_matchHome.png';
import productFourcastMatchBannerButton from '../../Assets/productFeatures_matchBannerButton.png';
import productFourcastMatchPriceSize from '../../Assets/productFeatures_priceSize.png';
import productFourcastMatchTraffFilts from '../../Assets/productFeatures_traffFilts.png';
import productReportsResult from '../../Assets/productFeatures_reportsResult.png';
import productReportsSummary from '../../Assets/productFeatures_reportsSummary.png';
import productReportsDemoData from '../../Assets/productFeatures_reportsDemoData.png';
import productReportsCompData from '../../Assets/productFeatures_reportsCompData.png';
import '../welcome/description.css';

class MobileDescription extends Component {


    constructor(props) {
        super(props);
        this.state = {
            screenWidth: window.screen.width,
            mobile: true,
        }
    }

    componentDidMount() {
        window && window.addEventListener('resize', this.mobileMode.bind(this));
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth });
        } else {
            this.setState({ mobile: false });
        }
    }

    componentWillUnmount() {
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
    }

    mobileMode() {
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ screenWidth: screenWidth, mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }
    

    render() {

        const { screenWidth } = this.state;

        return ( 
            <Container style={{ width: screenWidth, position: 'relative', paddingTop: 50, paddingBottom: 50 }} fluid>
                <div>
                    <div>
                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '80%' }}>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 22, paddingLeft: 2, marginTop: 20, color: 'gray' }}>Inside the app</div>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 36, marginTop: 10 }}><span style={{ fontStyle: 'italic', color: 'rgb(21, 134, 84)' }}>Automate</span><span> the </span><span style={{ fontStyle: 'italic' }}>overwhelming</span><span>,</span><span style={{ fontStyle: 'italic' }}> tedious</span><span>,</span><span style={{ fontStyle: 'italic' }}> time-consuming</span><span> tasks</span></div>
                                <div style={{ height: 100, display: 'flex', justifyContent: 'left', alignItems: 'center', paddingTop: 25 }}>
                                    <Link to='/listings' style={{ textDecoration: 'none' }}>
                                        <Button id="explorePropertiesBtn" style={{ width: 215, fontSize: 16 }}>
                                            Browse Listings
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <div style={{ position: 'relative' }}>
                            <img src={productFeaturesLaptop} style={{ zIndex: 999, height: '60%', width: '95%' }} alt="product features laptop" />
                            <img src={productMapScreen} style={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-53%, -46%)', height: '77%', width: '73%' }} alt='product features map' />
                            <img src={fourcastLogo} style={{ position: 'absolute', top: "8%", left: "50%", transform: 'translate(-53%, -8%)', height: '7%', width: '10%', zIndex: 998 }} alt='product features map' />
                            <div style={{ position: 'absolute', top: "7%", left: "50%", transform: 'translate(-53%, -7%)', backgroundColor: 'rgb(21, 134, 84)', width: '73%', height: '8.5%' }}></div>
                            <div id='prodFeaturesBlock1'></div>
                            <div id='prodFeaturesBlock2'></div>
                        </div>
                    </div>
                </div>
                <div style={{ paddingBottom: 100, paddingTop: 100 }}>
                    <div style={{ paddingBottom: 50 }}>
                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '80%' }}>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 42, color: 'rgb(21, 134, 84)', fontWeight: 'bolder' }}>Browse</div>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16, paddingLeft: 2, marginTop: 20 }}>Discover and explore active* retail space listings. Each space gets an overall Fourcast Score which is the average of four sub-category scores.</div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <img src={productScoreSquare} style={{ height: '100%', width: '100%' }} alt='product features score square' />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Fourcast Score</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdPeopleOutline style={{ height: '100%', width: '100%', color: 'dodgerblue' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Advanced filtering</div>
                                </div>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 12, paddingLeft: 2, marginTop: 20 }}>* Listings available in select cities.</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <div style={{ position: 'relative' }}>
                            <img src={productFeaturesLaptop} style={{ zIndex: 999, height: '60%', width: '95%' }} alt="product features laptop" />
                            <img src={productMapScreen} style={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-53%, -46%)', height: '77%', width: '73%' }} alt='product features map' />
                            <img src={fourcastLogo} style={{ position: 'absolute', top: "8%", left: "50%", transform: 'translate(-49.5%, -8%)', height: '7%', width: '10%', zIndex: 999 }} alt='product features map' />
                            <div style={{ position: 'absolute', top: "7%", left: "50%", transform: 'translate(-53%, -7%)', backgroundColor: 'rgb(21, 134, 84)', width: '73%', height: '8.5%' }}></div>
                            <div id='prodFeaturesBlock3'></div>
                            <div id='prodFeaturesBlock4'></div>
                            <div id='mapWhiteoutSquare' style={{ width: '44.5%', height: '70%', left: "20%", transform: 'translate(-20%, -3.5%)' }}></div>
                            <Card id='prodFeaturesMapSquareCard' style={{ left: '30%', top: '25%', height: '75%', width: '50%', zIndex: 1000 }}>
                                <div style={{ position: 'relative' }}>
                                    <img src={productMapSquare} style={{ height: '100%', width: '100%' }} alt='product features map square' />
                                </div>
                            </Card>
                            <Card id='prodFeaturesScoreCard' style={{ left: '15%', top: '0%', height: '40%', width: '20%', zIndex: 1000 }}>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <img src={productScoreSquare} style={{ height: '50%', width: '50%' }} alt='product features score square' />
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
                <div style={{ paddingBottom: 100, paddingTop: 100 }}>
                    <div style={{ paddingBottom: 50 }}>
                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '80%' }}>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 42, color: 'rgb(21, 134, 84)', fontWeight: 'bolder' }}>Match</div>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16, paddingLeft: 2, marginTop: 20 }}>Personalized searching tool. Set specific characteristics of the ideal space and area you are looking for. Get instantly matched.</div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdExposureNeg2 style={{ height: '100%', width: '100%', color: 'rgb(223, 82, 69)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10, width: '80%' }}>Adjusted scores reflect compatibility</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdTravelExplore style={{ height: '100%', width: '100%', color: 'rgb(21, 134, 84)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10, width: '80%' }}>Custom matched spaces</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: 50 }}>
                        <div style={{ position: 'relative' }}>
                            <img src={productFeaturesLaptop} style={{ zIndex: 999, height: '60%', width: '95%' }} alt="product features laptop" />
                            <img src={fourcastLogo} style={{ position: 'absolute', top: "8%", left: "50%", transform: 'translate(-49.5%, -8%)', height: '7%', width: '10%', zIndex: 999 }} alt='product features map' />
                            <div style={{ position: 'absolute', top: "7%", left: "50%", transform: 'translate(-53%, -7%)', backgroundColor: 'rgba(21, 134, 84, .5)', width: '73%', height: '8.5%' }}></div>
                            <img id='prodFeaturesMatchHomepage2' src={productFourcastMatchHome} style={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-53%, -46%)', height: '77%', width: '73%', opacity: .5 }} alt='product features fourcast match home screen' />
                            <Card id='prodFeaturesPriceSizeCard' style={{ top: '65%', left: '25%', height: '60%', width: '40%' }}>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productFourcastMatchPriceSize} style={{ height: '95%', width: '100%' }} alt='product features fourcast match price and size' />
                                </div>
                            </Card>
                            <Card id='prodFeaturesTraffFiltsCard' style={{ left: '50%', top: '70%', height: '40%', width: '25%' }}>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productFourcastMatchTraffFilts} style={{ height: '95%', width: '95%' }} alt='product features fourcast match traffic filters' />
                                </div>
                            </Card>
                            <div id='matchBannerButtonGreenSquare' style={{ width: '9%', height: '4%', top: '16.5%', left: '54%', transform: 'translate(-56%, -16.5%)' }}></div>
                            <Card style={{ position: 'absolute', top: '-10%', left: '40%', height: '15%', width: '30%' }}>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                                    <img src={productFourcastMatchBannerButton} style={{ height: '90%', width: '90%', zIndex: 10001 }} alt='product features fourcast match banner button' />
                                </div>
                            </Card>
                            <div style={{ position: 'absolute', bottom: '6.5%', left: '27.75%', transform: 'translate(-22.25%, -7%)', backgroundColor: 'white', width: '72.5%', height: '53%', zIndex: 1 }}></div>
                        </div>
                    </div>
                </div>
                <div style={{ paddingBottom: 100, paddingTop: 100 }}>
                    <div style={{ paddingBottom: 100 }}>
                        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '80%' }}>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 42, color: 'rgb(21, 134, 84)', fontWeight: 'bolder' }}>Reports</div>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16, paddingLeft: 2, marginTop: 20 }}>Generate customized, printable property reports.</div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdLocationPin style={{ height: '100%', width: '100%', color: 'dodgerblue' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10, width: '80%' }}>Select space(s) & report components</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdFileDownload style={{ height: '100%', width: '100%', color: 'rgb(21, 134, 84)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10, width: '80%' }}>Immediately download and print valuable reports for one or multiple properties.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{ position: 'relative' }}>
                            <img src={productFeaturesLaptop} style={{ zIndex: 999, height: '60%', width: '95%' }} alt="product features laptop" />
                            <img src={fourcastLogo} style={{ position: 'absolute', top: "8%", left: "50%", transform: 'translate(-49.5%, -8%)', height: '7%', width: '10%', zIndex: 999 }} alt='product features map' />
                            <img className='productMapFullScreen' src={productReportsResult} style={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-53%, -46%)', height: '77%', width: '73%' }} alt='product features map' />
                            <div style={{ position: 'absolute', top: "7%", left: "51.5%", transform: 'translate(-51.5%, -7%)', backgroundColor: 'rgb(21, 134, 84)', width: '77%', height: '9.5%' }}></div>
                            <Card id='prodFeaturesPriceSizeCard' style={{ top: '10%', left: '20%', height: '60%', width: '40%' }}>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productReportsSummary} style={{ height: '95%', width: '100%' }} alt='product features fourcast match price and size' />
                                </div>
                            </Card>
                            <Card id='prodFeaturesPriceSizeCard' style={{ left: '70%', top: '0%', zIndex: 1002, height: '60%', width: '40%' }}>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productReportsDemoData} style={{ height: '95%', width: '100%' }} alt='product features reports address input' />
                                </div>
                            </Card>
                            <Card id='prodFeaturesTraffFiltsCard' style={{ left: '45%', top: '95%', zIndex: 1002, height: '55%', width: '30%' }}>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productReportsCompData} style={{ height: '95%', width: '95%' }} alt='product features reports component input' />
                                </div>
                            </Card>
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}
 
export default MobileDescription;