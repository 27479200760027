import React, { Component } from 'react';
import UserBizType from './userBizType';
import PriceAndSize from './priceAndSize';
import BadBizTypes from './badBiz';
import GoodBizTypes from './goodBiz';
import TrafficFilters from './trafficFilters';
import DemoFilters from './demoFilters';
import CompFilters from './compFilters';
import PropertyFilters from './propertyFilters';
import ConfirmFilters from './confirmFilters';
import FourcastMatchWelcomePage from './welcomePage';

class CustomSearch extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            step: 0,
            minSize: null,
            maxSize: null,
            price: null,
            budgetType: 1,
            userBizType1: null,
            userBizType2: null,
            badBiz: [],
            goodBiz: [],
            trafficFilters: [],
            demoFilters: [],
            compFilters: [],
            propertyFilters: [],
            saveThisSearch: true
        }
    }

    componentDidMount() {
        this.props.getLocation(this.props.location.pathname);
    }


    // Proceed to next step
    nextStep = () => {
        const { step } = this.state;
        this.setState({ step: step + 1 });
    }

    // Go back to previous step
    prevStep = () => {
        const { step } = this.state;
        this.setState({ step: step - 1 });
    }

    goToPage = (stepNum) => {
        this.setState({ step: stepNum });
    }

    handleChange = state => value => {
        this.setState({ [state]: value.value });
    }

    addToState = state => value => {
        this.setState({ [state]: value });
    }

    setFilters = (state, filters) => {
        this.setState({ [state]: filters });
    }

    setMinSize = (value) => {
        this.setState({ minSize: value });
    }

    setMaxSize = (value) => {
        this.setState({ maxSize: value });
    }

    setMaxPrice = (value) => {
        this.setState({ price: value });
    }

    setBudgetType = (value) => {
        this.setState({ budgetType: value.value });
    }

    setSaveThisSearch = (value) => {
        this.setState({ saveThisSearch: value.target.checked });
    }


    render() {
        
        
        const { step } = this.state;
        
        const { userBizType1, userBizType2, minSize, maxSize, price, badBiz, goodBiz, trafficFilters, demoFilters, compFilters, propertyFilters, budgetType, saveThisSearch } = this.state;
        const values = { userBizType1, userBizType2, minSize, maxSize, price, badBiz, goodBiz, trafficFilters, demoFilters, compFilters, propertyFilters, budgetType, saveThisSearch };
        
        // BUDGET TYPES
        const budgetTypes = [{label: 'total / year', value: 1},
        {label: "per sqft / year", value: 2}];
        
        // BIZ TYPES
        const bizTypes = [{label: 'Restaurants/Food/Beverage', value: 'foodBev'}, 
        {label: "Shopping", value: 'shopping'}, 
        {label: "Beauty/Spas", value: 'beautySpas'}, 
        {label: 'Fitness/Instructions', value: 'fitnessInstructions'}, 
        {label: 'Arts/Entertainment', value: 'artsEntertainment'}, 
        {label: 'Health/Medical', value: 'healthMedical'},        
        {label: 'Local Services', value: 'localServices'}, 
        {label: 'Education', value: 'education'}];
        
        // SUB BIZ TYPES
        const foodBevTypes = [{label: 'Coffee & Tea', value: 'coffee'}, 
        {label: 'Sandwiches', value: 'sandwiches'}, 
        {label: 'Tapas Bars', value: 'tapasBars'}, 
        {label: 'Tapas/Small Plates', value: 'tapasSmallPlates'}, 
        {label: 'Spanish', value: 'spanish'}, 
        {label: 'Sushi Bars', value: 'sushibars'}, 
        {label: 'Barbeque', value: 'bbq'}, 
        {label: 'Dive Bars', value: 'diveBars'}, 
        {label: 'Breweries', value: 'breweries'}, 
        {label: 'Beer Bar', value: 'beerBar'}, 
        {label: 'Desserts', value: 'desserts'}, 
        {label: 'Ice Cream & Frozen Yogurt', value: 'iceCream'}, 
        {label: 'Indian', value: 'indian'}, 
        {label: 'Teppanyaki', value: 'teppanyaki'}, 
        {label: 'Ramen', value: 'ramen'}, 
        {label: 'Pizza', value: 'pizza'}, 
        {label: 'Italian', value: 'italian'}, 
        {label: 'Japanese', value: 'japanese'}, 
        {label: 'Asian Fusion', value: 'asianFusion'}, 
        {label: 'Comfort Food', value: 'comfortFood'}, 
        {label: 'Mexican', value: 'mexican'}, 
        {label: 'Vegan', value: 'vegan'}, 
        {label: 'Cocktail Bars', value: 'cocktailBars'}, 
        {label: 'Middle Eastern', value: 'middleEastern'}, 
        {label: 'Falafel', value: 'falafel'}, 
        {label: 'Szechuan', value: 'szechuan'},
        {label: 'Bars', value: 'bars'}, 
        {label: 'Gastropubs', value: 'gastropubs'}, 
        {label: 'American (Traditional)', value: 'americanTraditional'}, 
        {label: 'Diners', value: 'diners'}, 
        {label: 'Ukrainian', value: 'ukrainian'}, 
        {label: 'Gluten-Free', value: 'glutenFree'}, 
        {label: 'Breakfast & Brunch', value: 'breakfastBrunch'}, 
        {label: 'Mediterranean', value: 'mediterranean'}, 
        {label: 'Vegetarian', value: 'vegetarian'}, 
        {label: 'Delis', value: 'delis'}, 
        {label: 'American (New)', value: 'americanNew'}, 
        {label: 'Modern European', value: 'modernEuropean'}, 
        {label: 'Burgers', value: 'burgers'}, 
        {label: 'Hot Pot', value: 'hotPot'}, 
        {label: 'Chinese', value: 'chinese'}, 
        {label: 'African', value: 'african'}, 
        {label: 'Moroccan', value: 'moroccan'}, 
        {label: 'Korean', value: 'korean'}, 
        {label: 'Coffee Roasteries', value: 'coffeeRoastaries'}, 
        {label: 'Cafes', value: 'cafes'}, 
        {label: 'Seafood', value: 'seafood'}, 
        {label: 'Jazz & Blues', value: 'jazzBlues'},
        {label: 'Comedy Clubs', value: 'comedyClubs'}, 
        {label: 'Izakaya', value: 'izakaya'}, 
        {label: 'Dim Sum', value: 'dimSum'}, 
        {label: 'Cantonese', value: 'cantonese'}, 
        {label: 'Juice Bars & Smoothies', value: 'juiceBarsSmoothies'}, 
        {label: 'Pubs', value: 'pubs'}, 
        {label: 'Lounges', value: 'lounges'}, 
        {label: 'Noodles', value: 'noodles'}, 
        {label: 'Wine Bars', value: 'wineBars'}, 
        {label: 'Pasta Shops', value: 'pastaShops'}, 
        {label: 'Bubble Tea', value: 'bubbleTea'}, 
        {label: 'Taiwanese', value: 'taiwanese'}, 
        {label: 'Vietnamese', value: 'vietnamese'}, 
        {label: 'Sports Bars', value: 'sportsBars'}, 
        {label: 'Candy Stores', value: 'candyStores'}, 
        {label: 'Creperies', value: 'creperies'}, 
        {label: 'Salad', value: 'salad'}, 
        {label: 'Thai', value: 'thai'}, 
        {label: 'Soup', value: 'soup'}, 
        {label: 'Japanese Curry', value: 'japaneseCurry'}, 
        {label: 'Chicken Wings', value: 'chickenWings'}, 
        {label: 'Chicken Shop', value: 'chickenShop'}, 
        {label: 'Russian', value: 'russian'}, 
        {label: 'Gay Bars', value: 'gayBars'}, 
        {label: 'French', value: 'french'}, 
        {label: 'Bakeries', value: 'bakeries'}, 
        {label: 'Whiskey Bars', value: 'whiskeyBars'}, 
        {label: 'Hookah Bars', value: 'hookahBars'}, 
        {label: 'Empanadas', value: 'empanadas'}, 
        {label: 'Gelato', value: 'gelato'}, 
        {label: 'Brasseries', value: 'brasseries'}, 
        {label: 'Tacos', value: 'tacos'}, 
        {label: 'Cuban', value: 'cuban'}, 
        {label: 'Acai Bowls', value: 'acaiBowls'}, 
        {label: 'Speakeasies', value: 'speakeasies'}, 
        {label: 'Dance Clubs', value: 'danceClubs'}, 
        {label: 'Grocery', value: 'grocery'}, 
        {label: 'Tea Rooms', value: 'teaRooms'}, 
        {label: 'Karaoke', value: 'karaoke'}, 
        {label: 'Donuts', value: 'donuts'}, 
        {label: 'Latin American', value: 'latinAmerican'}, 
        {label: 'Food Stands', value: 'foodStands'}, 
        {label: 'Persian/Iranian', value: 'persianIranian'}, 
        {label: 'Food Delivery Services', value: 'foodDelivery'}, 
        {label: 'Cupcakes', value: 'cupcakes'}, 
        {label: 'Custom Cakes', value: 'customCakes'}, 
        {label: 'Meat Shops', value: 'meatShops'}, 
        {label: 'Steakhouses', value: 'steakhouses'}, 
        {label: 'Soul Food', value: 'soulFood'}, 
        {label: 'Belgian', value: 'belgian'}, 
        {label: 'Specialty Food', value: 'specialtyFood'}, 
        {label: 'Poutineries', value: 'poutineries'}, 
        {label: 'Halal', value: 'halal'}, 
        {label: 'Herbs & Spices', value: 'herbsSpices'}, 
        {label: 'Lebanese', value: 'lebanese'}, 
        {label: 'Waffles', value: 'waffles'}, 
        {label: 'Bagels', value: 'bagels'}, 
        {label: 'Australian', value: 'australian'}, 
        {label: 'Poke', value: 'poke'}, 
        {label: 'Kosher', value: 'kosher'}, 
        {label: 'Wraps', value: 'wraps'}, 
        {label: 'Beer, Wine & Spirits', value: 'beerWineSpirits'}, 
        {label: 'Fast Food', value: 'fastFood'}, 
        {label: 'Greek', value: 'greek'}, 
        {label: 'Hungarian', value: 'hungarian'}, 
        {label: 'German', value: 'german'}, 
        {label: 'Georgian', value: 'georgian'}, 
        {label: 'Peruvian', value: 'peruvian'}, 
        {label: 'Food Trucks', value: 'foodTrucks'}, 
        {label: 'Brazilian', value: 'brazilian'}, 
        {label: 'Tex-Mex', value: 'texMex'}, 
        {label: 'Hot Dogs', value: 'hotDogs'}, 
        {label: 'Ethiopian', value: 'ethiopian'}, 
        {label: 'Cooking Classes', value: 'cookingClasses'}, 
        {label: 'Turkish', value: 'turkish'}, 
        {label: 'Beer Gardens', value: 'beerGardens'}, 
        {label: 'Brewpubs', value: 'brewpubs'}, 
        {label: 'Tuscan', value: 'tuscan'}, 
        {label: 'Kebab', value: 'kebab'}, 
        {label: 'British', value: 'british'}, 
        {label: 'Shaved Ice', value: 'shavedIce'},
        {label: 'Cajun/Creole', value: 'cajunCreole'}, 
        {label: 'Convenience Stores', value: 'convenienceStores'}, 
        {label: 'Caribbean', value: 'caribbean'}, 
        {label: 'Pancakes', value: 'pancakes'}, 
        {label: 'Cheese Shops', value: 'cheeseShops'}, 
        {label: 'Pop-Up Restaurants', value: 'popupRestaurant'}, 
        {label: 'Local Flavor', value: 'localFlavor'}];
        const beautySpaTypes = [{label: 'Hair Salons', value: 'hair'},
        {label: 'Barbers', value: 'barbers'},
        {label: 'Skin Care', value: 'skinCare'},
        {label: 'Eyelash Service', value: 'eyelashService'},
        {label: 'Waxing', value: 'waxing'},
        {label: 'Hair Stylists', value: 'hairStylists'},
        {label: 'Hair Extensions', value: 'hairExtensions'},
        {label: 'Piercing', value: 'piercing'},
        {label: 'Spray Tanning', value: 'sprayTanning'},
        {label: 'Threading Services', value: 'threadingServices'},
        {label: 'Massage', value: 'massage'},
        {label: 'Nail Salons', value: 'nailsalons'},
        {label: 'Nail Technicians', value: 'nailTechnicians'},
        {label: 'Tattoo', value: 'tattoo'},
        {label: 'Makeup Artists', value: 'makeupArtists'},
        {label: 'Permanent Makeup', value: 'permanentMakeup'},
        {label: 'Teeth Whitening', value: 'teethWhitening'},
        {label: 'Day Spas', value: 'daySpas'},
        {label: 'Blow Dry/Out Services', value: 'blowDryOutServices'},
        {label: 'Laser Hair Removal', value: 'laserHairRemoval'}];
        const medicalHealthTypes = [{label: 'Optometrists', value: 'optometrists'},
        {label: 'Reflexology', value: 'reflexology'},
        {label: 'Massage Therapy', value: 'massageTherapy'},
        {label: 'Cosmetic Surgeons', value: 'cosmeticSurgeons'},
        {label: 'Medical Spas', value: 'medicalSpas'},
        {label: 'Plastic Surgeons', value: 'plasticSurgeons'},
        {label: 'Alternative Medicine', value: 'alternativeMedicine'},
        {label: 'Tui Na', value: 'tuiNa'}];
        const shoppingTypes = [{label: 'Cosmetics & Beauty Supply', value: 'cosmeticsBeauty'},
        {label: 'Florists', value: 'florists'},
        {label: 'Toy Stores', value: 'toyStores'},
        {label: 'Sunglasses', value: 'sunglasses'},
        {label: 'Jewelry', value: 'jewelry'},
        {label: 'Cards & Stationery', value: 'cardsStationery'},
        {label: 'Tobacco Shops', value: 'tobaccoShops'},
        {label: 'Vape Shops', value: 'vapeShops'},
        {label: 'Baby Gear & Furniture', value: 'babyGearFurniture'},
        {label: "Men's Clothing", value: 'mensClothing'},
        {label: 'Accessories', value: 'accessories'},
        {label: "Women's Clothing", value: 'womensClothing'},
        {label: 'Vinyl Records', value: 'vinylRecords'},
        {label: 'Video Game Stores', value: 'videoGameStores'},
        {label: 'Bookstores', value: 'bookstores'},
        {label: 'Arts & Crafts', value: 'artsCrafts'},
        {label: 'Home Decor', value: 'homeDecor'},
        {label: 'Gift Shops', value: 'giftShops'},
        {label: 'Hardware Stores', value: 'hardwareStores'},
        {label: 'Drugstores', value: 'drugstores'},
        {label: 'Lighting Stores', value: 'lightingStores'},
        {label: 'Lighting Fixtures & Equipment', value: 'lightingFixtureEquipment'},
        {label: 'Lingerie', value: 'lingerie'},
        {label: 'Swimwear', value: 'swimwear'},
        {label: 'Antiques', value: 'antiques'},
        {label: 'Used, Vintage & Consignment', value: 'usedVintageConsignment'},
        {label: 'Department Stores', value: 'departmentStores'},
        {label: 'Guitar Stores', value: 'guitarStores'},
        {label: 'Head Shops', value: 'headShops'},
        {label: 'Rugs', value: 'rugs'},
        {label: 'Mobile Phones', value: 'mobilePhones'},
        {label: 'Mobile Phone Accessories', value: 'mobilePhoneAccessories'},
        {label: 'Cooking Classes', value: 'cookingClasses'},
        {label: 'Packing Supplies', value: 'packingSupplies'},
        {label: 'Leather Goods', value: 'leatherGoods'},
        {label: 'Comic Books', value: 'comicBooks'},
        {label: 'Watches', value: 'watches'},
        {label: 'Framing', value: 'framing'},
        {label: 'Music & DVDs', value: 'musicDVD'},
        {label: 'Shoe Stores', value: 'shoeStores'},
        {label: 'Hats', value: 'hats'},
        {label: 'Perfume', value: 'perfume'},
        {label: 'Shopping', value: 'shopping'},
        {label: 'Eyewear & Opticians', value: 'eyewearOpticians'},
        {label: 'Bikes', value: 'bikes'},
        {label: 'Videos & Video Game Rental', value: 'videoGamesAndRentals'},
        {label: 'Vitamins & Supplements', value: 'vitaminsSupplements'},
        {label: 'Fashion', value: 'fashion'},
        {label: 'Pop-up Shops', value: 'popupShops'},
        {label: 'Surf Shop', value: 'surfShop'},
        {label: 'Sports Wear', value: 'sportsWear'},
        {label: 'Discount Store', value: 'discountStore'},
        {label: 'Photography Stores & Services', value: 'photographyStores'},
        {label: 'Souvenir Shops', value: 'souvenirShops'},
        {label: 'Flowers & Gifts', value: 'flowersGifts'},
        {label: 'Costumes', value: 'costumes'},
        {label: 'Wholesale Stores', value: 'wholesaleStores'}];
        const localServicesTypes = [{label: 'Mobile Phone Repair', value: 'mobilePhoneRepair'},
        {label: 'Electronics Repair', value: 'electronicsRepair'},
        {label: 'Movers', value: 'movers'},
        {label: 'Self Storage', value: 'selfStorage'},
        {label: 'Bike Repair/Maintenance', value: 'bikeRepair'}];
        const artsEntertainmentTypes = [{label: 'Musical Instruments & Teachers', value: 'musicalInstrumentsTeachers'},
        {label: 'Performing Arts', value: 'performingArts'},
        {label: 'Art Galleries', value: 'artGalleries'},
        {label: 'Cinema', value: 'cinema'}, 
        {label: 'Arcades', value: 'arcades'},
        {label: 'Cultural Center', 
        value: 'culturalCenter'}];
        const fitnessInstructionsTypes = [{label: 'Pilates', value: 'pilates'},
        {label: 'Gyms', value: 'gyms'},
        {label: 'Bike Rentals', value: 'bikeRentals'},
        {label: 'Health Coach', value: 'healthCoach'},
        {label: 'Boot Camps', value: 'bootCamps'},
        {label: 'Yoga', value: 'yoga'},
        {label: 'Dance Studios', value: 'danceStudios'},
        {label: 'Meditation Centers', value: 'meditationCenters'},
        {label: 'Trainers', value: 'trainers'},
        {label: 'Interval Training Gyms', value: 'intervalTrainingGyms'},
        {label: 'Martial Arts', value: 'martialArts'},
        {label: 'Self-defense Classes', value: 'selfDefenseClasses'},
        {label: 'Karate', value: 'karate'},
        {label: 'Tai Chi', value: 'taiChi'}];
        const educationTypes = [{label: 'Cooking Schools', value: 'cookingSchools'},
        {label: 'Art Schools', value: 'artSchools'},
        {label: 'Dance Schools', value: 'danceSchools'}];


        
        let sortedBizTypes = bizTypes.sort((a, b) => {
            if (a.value < b.value) {
                return -1
            } 
            if (a.value > b.value) {
                return 1
            }
            return 0
        });
        let sortedFoodBevTypes = foodBevTypes.sort((a, b) => {
            if (a.value < b.value) {
                return -1
            } 
            if (a.value > b.value) {
                return 1
            }
            return 0
        });
        let sortedBeautySpaTypes = beautySpaTypes.sort((a, b) => {
            if (a.value < b.value) {
                return -1
            } 
            if (a.value > b.value) {
                return 1
            }
            return 0
        });
        let sortedMedicalHealthTypes = medicalHealthTypes.sort((a, b) => {
            if (a.value < b.value) {
                return -1
            } 
            if (a.value > b.value) {
                return 1
            }
            return 0
        });
        let sortedShoppingTypes = shoppingTypes.sort((a, b) => {
            if (a.value < b.value) {
                return -1
            } 
            if (a.value > b.value) {
                return 1
            }
            return 0
        });
        let sortedLocalServicesTypes = localServicesTypes.sort((a, b) => {
            if (a.value < b.value) {
                return -1
            } 
            if (a.value > b.value) {
                return 1
            }
            return 0
        });
        let sortedArtsEntertainmentTypes = artsEntertainmentTypes.sort((a, b) => {
            if (a.value < b.value) {
                return -1
            } 
            if (a.value > b.value) {
                return 1
            }
            return 0
        });
        let sortedFitnessInstructionsTypes = fitnessInstructionsTypes.sort((a, b) => {
            if (a.value < b.value) {
                return -1
            } 
            if (a.value > b.value) {
                return 1
            }
            return 0
        });
        let sortedEducationTypes = educationTypes.sort((a, b) => {
            if (a.value < b.value) {
                return -1
            } 
            if (a.value > b.value) {
                return 1
            }
            return 0
        });
        


        switch(step) {
            case 0:
                return (
                    <FourcastMatchWelcomePage 
                        nextStep={this.nextStep}
                    />
                )
            case 1:
                return (
                    <UserBizType 
                        nextStep={this.nextStep}
                        onChange={this.handleChange}
                        values={values}
                        bizTypes={sortedBizTypes}
                        foodBevTypes={sortedFoodBevTypes}
                        beautySpaTypes={sortedBeautySpaTypes}
                        medicalHealthTypes={sortedMedicalHealthTypes}
                        shoppingTypes={sortedShoppingTypes}
                        localServicesTypes={sortedLocalServicesTypes}
                        artsEntertainmentTypes={sortedArtsEntertainmentTypes}
                        fitnessInstructionsTypes={sortedFitnessInstructionsTypes}
                        educationTypes={sortedEducationTypes}
                    />
                )
            case 2:
                return (
                    <PriceAndSize 
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        setMinSize={this.setMinSize}
                        setMaxSize={this.setMaxSize}
                        setMaxPrice={this.setMaxPrice}
                        setBudgetType={this.setBudgetType}
                        values={values}
                        budgetTypes={budgetTypes}
                    />
                )
            case 3:
                return (
                    <BadBizTypes 
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        addToState={this.addToState}
                        values={values}
                        foodBevTypes={sortedFoodBevTypes}
                        beautySpaTypes={sortedBeautySpaTypes}
                        medicalHealthTypes={sortedMedicalHealthTypes}
                        shoppingTypes={sortedShoppingTypes}
                        localServicesTypes={sortedLocalServicesTypes}
                        artsEntertainmentTypes={sortedArtsEntertainmentTypes}
                        fitnessInstructionsTypes={sortedFitnessInstructionsTypes}
                        educationTypes={sortedEducationTypes}
                        isMulti={true}
                    />
                )
            case 4:
                return (
                    <GoodBizTypes 
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        addToState={this.addToState}
                        values={values}
                        foodBevTypes={sortedFoodBevTypes}
                        beautySpaTypes={sortedBeautySpaTypes}
                        medicalHealthTypes={sortedMedicalHealthTypes}
                        shoppingTypes={sortedShoppingTypes}
                        localServicesTypes={sortedLocalServicesTypes}
                        artsEntertainmentTypes={sortedArtsEntertainmentTypes}
                        fitnessInstructionsTypes={sortedFitnessInstructionsTypes}
                        educationTypes={sortedEducationTypes}
                        isMulti={true}
                    />
                )
            case 5:
                return (
                    <CompFilters 
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        setFilters={this.setFilters}
                        values={values}
                    />
                )
            case 6:
                return (
                    <TrafficFilters 
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        setFilters={this.setFilters}
                        values={values}
                    />
                )
            case 7:
                return (
                    <DemoFilters 
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        setFilters={this.setFilters}
                        values={values}
                    />
                )
            case 8:
                return (
                    <PropertyFilters 
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        setFilters={this.setFilters}
                        values={values}
                    />
                )
            case 9:
                return (
                    <ConfirmFilters 
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        values={values}
                        setFilters={this.setFilters}
                        goToPage={this.goToPage}
                        setSaveThisSearch={this.setSaveThisSearch}
                    />
                )
            default:
                return (
                    <FourcastMatchWelcomePage 
                        nextStep={this.nextStep}
                    />
                )
        }
    }
}
 
export default CustomSearch;

