import React, { Component } from 'react';
import { Button, Card, CardContent, CircularProgress } from '@mui/material';
import { ImCheckmark, ImCross } from 'react-icons/im';
import { Row } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateUser, loadUser } from '../../actions/authActions';
import { getPrices } from '../../actions/stripeActions';
import axios from 'axios';
import SkeletonPaymentPage from '../skeletons/skeletonPaymentInfo';


// Pointing Axios baseURL towards AWS API Gateway URL
// Will most likely be changed again at some point because of the /dev
const axiosAWS = axios.create({
    baseURL: 'https://bgeg45v1tk.execute-api.us-east-1.amazonaws.com/dev'
});



class PaymentInfo extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            thesePrices: [],
            accountType: '',
            loading: false,
            error: null,
            doubleChecked: false
        }
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        paymentInfoNeeded: PropTypes.bool,
        error: PropTypes.object.isRequired,
        getPrices: PropTypes.func.isRequired,
        user: PropTypes.object.isRequired,
        auth: PropTypes.object.isRequired,
        loadUser: PropTypes.func.isRequired,
        updateUser: PropTypes.func.isRequired
    }


    async componentDidMount() {
        
        const { isAuthenticated, user, auth } = this.props;
        
        await this.props.loadUser();

        if (auth && isAuthenticated && user) {
            await this.props.getPrices();
        }
        
        const accountType = user && user.accountType;
        
        this.setState({ accountType: accountType });
        
    }

    async componentDidUpdate(prevProps, prevState) {

        const { prices, isAuthenticated, auth } = this.props;
        const { thesePrices, loading, doubleChecked, error } = this.state;

        if (auth && isAuthenticated && (!thesePrices.length)) {
            if (prevProps.prices !== prices) {
                this.setState({ thesePrices: prices.data, loading: false });
            }
            if (loading && (doubleChecked === false)) {
                
                try {
                    await this.props.getPrices();
                    await this.checkedPricesAgain();
                } catch (err) {
                    console.log(err);
                }
            }
        }

        if (error !== prevState.error) {
            if (this.state.error !== null) {
                this.setState({ loading: false })
            }
        }

    }

    checkedPricesAgain = async () => {
        this.setState({ doubleChecked: true });
    }


    createSession = async e => {

        const { auth } = this.props;

        this.setState({ loading: true });

        const { thesePrices, accountType } = this.state;
        const { user } = this.props;

        const proPriceData = thesePrices && thesePrices.filter(price => {return price.nickname === "Pro"});
        const brokerPriceData = thesePrices && thesePrices.filter(price => {return price.nickname === "Broker"});

        const proPriceId = proPriceData.length > 0 && proPriceData[0].id;
        const brokerPriceId = brokerPriceData.length > 0 && brokerPriceData[0].id;

        const token = auth && auth.token ? auth.token : this.props.history.location.state.token;

        let tokenConfig = thisToken => {
    
            // Get Token from localStorage
            const token = thisToken;
        
            // Headers 
            const config = {
                headers: {
                    "Content-type": "application/json"
                }
            }
        
            // If token, add to headers
            if (token) {
                config.headers['Authorization'] = token;
            }
        
            return config;
        }

        if (accountType === 'pro') {
            
            let sessionDetails = {
                priceId: proPriceId,
                user: user
            };
            await axiosAWS.post('/api/subscriptions/session', sessionDetails, tokenConfig(token))
                .then(res => {
                    const { url } = res.data;
                    window.location.href = url;
                })
                .catch(err => {
                    this.setState({ error: 'pro' });
                });
        }

        if (accountType === 'broker') {

            let sessionDetails = {
                priceId: brokerPriceId,
                user: user
            };
            await axiosAWS.post('/api/subscriptions/session', sessionDetails, tokenConfig(token))
                .then(res => {
                    const { url } = res.data;
                    window.location.href = url;
                })
                .catch(err => {
                    this.setState({ error: 'broker' });
                });
        }

        if (accountType === 'basic') {
            let updatedUser = {
                userId: user._id,
                accountType: 'basic',
                engagement: user.engagement
            };
            
            updatedUser && updatedUser ? await this.props.updateUser(updatedUser) : console.log('Error: Please go to "MY ACCOUNT" to update.');
        }
    }



    render() { 
        
        const { thesePrices } = this.state;
        const { isAuthenticated } = this.props;


        const proPriceData = thesePrices && thesePrices.filter(price => {return price.nickname === "Pro"});
        const brokerPriceData = thesePrices && thesePrices.filter(price => {return price.nickname === "Broker"});

        let proPrice = proPriceData.length > 0 && proPriceData[0].unit_amount;
        proPrice *= .01;
        proPrice = proPrice.toFixed(2);

        let brokerPrice = brokerPriceData.length > 0 && brokerPriceData[0].unit_amount;
        brokerPrice *= .01;

        return ( 
            <>
            {(isAuthenticated && (!thesePrices || (!this.state.accountType.length))) ? (<SkeletonPaymentPage loading />) : (
            <div style={{ backgroundColor: 'whitesmoke', paddingTop: 50, height: '100vh', width: '100vw' }}>
                <Card style={{ width: '90%', marginTop: 'auto', marginBottom: 'auto', marginLeft: 'auto', marginRight: 'auto', height: 700 }}>
                    <CardContent>
                        <div style={{ width: '100%', textAlign: 'center', marginTop: 25 }}>
                            <h3>Please Select Account Type</h3>
                        </div>
                        <div style={{ height: 225, marginTop: 50, display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 10, textAlign: 'center' }}>
                            <div>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    style={this.state.accountType === 'basic' ? { outline: 'none', color: 'black', backgroundColor: 'white', border: '5px solid rgb(52, 181, 122)', fontSize: 12, width: 300, height: 150 } : { backgroundColor: 'whitesmoke', color: 'black', fontSize: 12, width: 300, height: 150 }}
                                    category='basic'
                                    checked={this.state.accountType === 'basic'}
                                    onClick={(e) => {
                                        this.setState({ accountType: 'basic', successAlert: false, changeMade: true });
                                    }}
                                >
                                    <h5>Basic</h5>
                                </Button>
                                <p style={{ marginTop: 10, fontSize: 12, fontWeight: 'bold' }}>Free</p>
                            </div>
                            <div>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    style={this.state.accountType === 'pro' ? { outline: 'none', color: 'black', backgroundColor: 'white', border: '5px solid rgb(52, 181, 122)', fontSize: 12, width: 300, height: 150 } : { backgroundColor: 'whitesmoke', color: 'black', fontSize: 12, width: 300, height: 150 }}
                                    category='pro'
                                    checked={this.state.accountType === 'pro'}
                                    onClick={(e) => {
                                        this.setState({ accountType: 'pro', successAlert: false, changeMade: true });
                                    }}
                                >
                                    <h5>Pro</h5>
                                </Button>
                                <p style={{ marginTop: 10, fontSize: 12, fontWeight: 'bold' }}>{`$${proPrice} / month`}</p>
                            </div>
                            <div>
                                <Button 
                                    variant="contained"
                                    size="large"
                                    style={this.state.accountType === 'broker' ? { outline: 'none', color: 'black', backgroundColor: 'white', border: '5px solid rgb(52, 181, 122)', fontSize: 12, width: 300, height: 150 } : { backgroundColor: 'whitesmoke', color: 'black', fontSize: 12, width: 300, height: 150 }}
                                    category='broker'
                                    checked={this.state.accountType === 'broker'}
                                    onClick={(e) => {
                                        this.setState({ accountType: 'broker', successAlert: false, changeMade: true });
                                    }}
                                >
                                    <h5>Broker</h5>
                                </Button>
                                <p style={{ marginTop: 10, fontSize: 12, fontWeight: 'bold' }}>{`$${brokerPrice} per listing / month`}</p>
                                <p style={{ marginTop: -10, fontSize: 11, fontWeight: 'bold' }}>{`*Must have at least 1 listing submitted`}</p>
                            </div>
                        </div>
                        <div style={{ height: 200, marginTop: 10, marginLeft: 100, display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 10, textAlign: 'center' }}>
                            <div>
                                <div className="accountTypeDesc">
                                    <div><ImCheckmark style={{ color: 'darkgreen' }} /></div>
                                    <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Browse Listings</p>
                                </div>
                                <div className="accountTypeDesc">
                                    <div><ImCross style={{ color: 'darkred' }} /></div>
                                    <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Fourcast Match</p>
                                </div>
                                <div className="accountTypeDesc">
                                    <div><ImCross style={{ color: 'darkred' }} /></div>
                                    <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Submit & Advertise Listings</p>
                                </div>
                            </div>
                            <div style={{ marginLeft: 25 }}>
                                <div className="accountTypeDesc">
                                    <div><ImCheckmark style={{ color: 'darkgreen' }} /></div>
                                    <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Browse Listings</p>
                                </div>
                                <div className="accountTypeDesc">
                                    <div><ImCheckmark style={{ color: 'darkgreen' }} /></div>
                                    <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Fourcast Match</p>
                                </div>
                                <div className="accountTypeDesc">
                                    <div><ImCross style={{ color: 'darkred' }} /></div>
                                    <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Submit & Advertise Listings</p>
                                </div>
                            </div>
                            <div style={{ marginLeft: 50 }}>
                                <div className="accountTypeDesc">
                                    <div><ImCheckmark style={{ color: 'darkgreen' }} /></div>
                                    <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Browse Listings</p>
                                </div>
                                <div className="accountTypeDesc">
                                    <div><ImCheckmark style={{ color: 'darkgreen' }} /></div>
                                    <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Fourcast Match</p>
                                </div>
                                <div className="accountTypeDesc">
                                    <div><ImCheckmark style={{ color: 'darkgreen' }} /></div>
                                    <p style={{ width: '100%', marginTop: 5, fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>Submit & Advertise Listings</p>
                                </div>
                            </div>
                        </div>
                        <Row style={{ height: 100 }}>
                            <Button onClick={() => this.createSession()} variant="contained" endIcon={(this.state.loading && !this.state.error) ? <CircularProgress style={{ color: 'white' }} size={20} thickness={3} /> : null} style={{ outline: 'none', marginLeft: 'auto', marginRight: 'auto', backgroundColor: 'rgb(35, 126, 85)', width: '25%', height: 50, color: 'white' }}>
                                <div style={{ marginLeft: 10, paddingRight: 10 }}>Continue</div>
                            </Button>
                        </Row>
                    </CardContent>
                </Card>
            </div>)}
            </>
        );
    }
}



const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    prices: state.stripe.prices,
    user: state.auth.user,
    auth: state.auth,
    paymentInfoNeeded: state.auth.paymentInfoNeeded,
    error: state.error,
});

export default connect(mapStateToProps, { loadUser, updateUser, getPrices })(PaymentInfo);