import React, { Component } from 'react';
import 'chart.js/auto';
import 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';

class MostCommonBusiness extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commonChartData: props.commonChartData
        }
    }

    static defaultProps = {
        displayTitle: true,
    }
    
    render() {

        return (
            <div className="chart">
            <Bar
            data={this.state.commonChartData}
            height={190}
            options={{
                padding: 20,
                title: {
                    display: this.props.displayTitle,
                    text: 'Common Business Types Nearby',
                    fontSize: 25,
                    color: 'black'
                },
                plugins: {
                    datalabels: {
                       display: true,
                       color: 'black'
                    }
                },
                scales: {
                    x: {
                        type: 'linear',
                        ticks: {
                            beginAtZero: true
                        },
                        grid: {
                            display: false
                        }
                    },
                    y: {
                        type: 'category',
                        stacked: true,
                        ticks: {
                            fontSize: 14,
                        },
                        grid: {
                            display: false
                        },
                    },
                },
            }} 
            />
            </div>
        );
    }
}

export default MostCommonBusiness;