import React, { Component } from 'react';
import { Card } from '@material-ui/core';
import { Container } from 'reactstrap';
import bizOwner from '../../Assets/userProp_bizOwner.png';
import tenantRep from '../../Assets/userProp_tenantRep.png';
import landlord from '../../Assets/userProp_landlord.png';
import { MdCheckCircleOutline, MdStorefront, MdOutlineVisibility, MdDirectionsWalk, MdOutlineManageAccounts, MdOutlineCalendarToday, MdOutlineGroupAdd, MdOutlineQueryStats, MdOutlineVerifiedUser, MdOutlinePersonSearch } from 'react-icons/md';
import MobileUserValueProp from './mobileUserValueProp';
import './description.css';

class UserValueProp extends Component {
    

    render() { 
        return ( 
            <Container style={{ height: 1000, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', paddingBottom: 50 }} fluid>
                <div style={{ width: '100%', height: 900, margin: 'auto' }}>
                    <div className='perfectSpace' style={{ display: 'flex', justifyContent: 'center', height: '15%' }}>
                        <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                            <h2>Improving the process of finding viable spaces and viable tenants, so all can thrive</h2>
                        </div>
                    </div>
                    <div style={{ height: '80%', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', gap: 10 }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ height: '100%' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70%' }}>
                                    <Card style={{ height: 350, width: 450, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'whitesmoke' }}>
                                        <img src={bizOwner} style={{ height: 190, width: 256 }} alt="user proposition business owner" />
                                    </Card>
                                </div>
                                <div style={{ height: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h3 style={{ width: '80%' }}>Retailers</h3>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20, width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <div style={{ height: 50, width: 40 }}>
                                        <div style={{ height: '50%', width: '100%' }}>
                                            <MdCheckCircleOutline style={{ height: '100%', width: '100%', color: 'rgb(21, 134, 84)' }} />
                                        </div>
                                        <div style={{ height: '50%', width: '100%' }}>
                                            <MdStorefront style={{ height: '100%', width: '100%', color: 'rgb(21, 134, 84)' }} />
                                        </div>
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Location compatibility</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20, width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <div style={{ height: 40, width: 40 }}>
                                        <MdOutlineManageAccounts style={{ height: '100%', width: '100%', color: 'dodgerblue' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Empowering search capabilities</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20, width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <div style={{ height: 40, width: 40 }}>
                                        <MdOutlineCalendarToday style={{ height: '80%', width: '100%', color: 'rgb(223, 82, 69)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Less time spent searching</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ height: '100%' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70%' }}>
                                    <Card style={{ height: 350, width: 450, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'whitesmoke' }}>
                                        <img src={tenantRep} style={{ height: 325, width: 444 }} alt="user proposition tenant rep" />
                                    </Card>
                                </div>
                                <div style={{ height: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h3 style={{ width: '80%' }}>Tenant Reps</h3>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20, width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <div style={{ height: 50, width: 40 }}>
                                        <MdOutlineQueryStats style={{ height: '100%', width: '100%', color: 'rgb(250, 204, 68)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Provide highest quality service</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20, width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <div style={{ height: 40, width: 40 }}>
                                        <MdOutlineVerifiedUser style={{ height: '100%', width: '100%', color: 'dodgerblue' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Elevate reliability</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20, width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <div style={{ height: 40, width: 40 }}>
                                        <MdDirectionsWalk style={{ height: '100%', width: '100%', color: 'rgb(223, 82, 69)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Eliminate pointless showings</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20, width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <div style={{ height: 40, width: 40 }}>
                                        <MdOutlineGroupAdd style={{ height: '100%', width: '100%', color: 'rgb(21, 134, 84)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>More time, handle more clients</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ height: '100%' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70%' }}>
                                    <Card style={{ height: 350, width: 450, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'whitesmoke' }}>
                                        <img src={landlord} style={{ height: 329, width: 219 }} alt="user proposition landlord" />
                                    </Card>
                                </div>
                                <div style={{ height: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <h3 style={{ width: '80%' }}>Listing Brokers / Landlords</h3>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20, width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <div style={{ height: 50, width: 40 }}>
                                        <MdOutlineVisibility style={{ height: '100%', width: '100%', color: 'dodgerblue' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Maximize listing exposure</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20, width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <div style={{ height: 40, width: 40 }}>
                                        <MdOutlinePersonSearch style={{ height: '100%', width: '100%', color: 'rgb(21, 134, 84)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Pre-screened, matched applicants</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20, width: '80%', marginLeft: 'auto', marginRight: 'auto' }}>
                                    <div style={{ height: 40, width: 40 }}>
                                        <MdStorefront style={{ height: '100%', width: '100%', color: 'rgb(223, 82, 69)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Prevent and reduce vacancies</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
         );
    }
}
 
export default UserValueProp;