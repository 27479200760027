import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import CreateListing from './createListing';
import ListingsList from './listingsList';
import LeadsList from './leadsList';
import Footer from '../welcome/footer';
import AccountSettings from './accountSettings';
import UpgradeAccount from './upgradeAccount';
import LoginModal from '../auth/loginModal';
import SkeletonAccountSettings from '../skeletons/skeletonAccountSettings';
import { connect } from 'react-redux';
import { loadUser } from '../../actions/authActions';
import { getListings } from '../../actions/listingActions';
import { getPrices } from '../../actions/stripeActions';
import { getLeads } from '../../actions/leadActions';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { Fab } from '@material-ui/core';
import moment from 'moment';
import './userProfile.css';



class UserProfile extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            pageview: 'accountSettings',
            thesePrices: [],
            userSubData: null,
            doubleChecked: false,
            isLoading: true
        }
    }


    checkedPricesAgain = async () => {
        this.setState({ doubleChecked: true });
    }
    

    async componentDidMount() {
        await this.props.getLocation(this.props.location.pathname);
        await this.props.loadUser();
        const { isAuthenticated, user, auth } = this.props;
        const { doubleChecked, thesePrices } = this.state;
        
        
        if (auth && isAuthenticated) {
            
            try {
                let page;
                page = { path: "listingList", filters: { userId: user._id } };
                await this.props.getListings(page);
                await this.props.getLeads();
                if ((thesePrices && !thesePrices.length) && (doubleChecked === false)) {
                    await this.props.getPrices();
                    await this.checkedPricesAgain();
                }
            } catch (err) {
                console.log(err);
            }
        }
    }

    async componentDidUpdate(prevProps) {
        const { prices, isAuthenticated, auth } = this.props;
        const { thesePrices, isLoading, doubleChecked } = this.state;
        if (auth && isAuthenticated && (!thesePrices.length)) {
            if (prevProps.prices !== prices) {
                this.setState({ thesePrices: prices.data, isLoading: false });
            }
            if (isLoading && (doubleChecked === false)) {
                
                try {
                    await this.props.getPrices();
                    await this.checkedPricesAgain();
                } catch (err) {
                    console.log(err);
                }
            }
        }
    }


    

    seeListings = e => {
        this.setState({ pageview: 'listings' });
    }

    seeLeads = e => {
        this.setState({ pageview: 'leads' });
    }

    seeAccountSettings = e => {
        this.setState({ pageview: 'accountSettings' });
    }

    render() {
        
        const { listings, user, leads, loading, isAuthenticated, auth, prices } = this.props;
        const { thesePrices, isLoading } = this.state;

        const userStripeSubData = user && user.stripeSubData;

        let thisUsersListings = listings && listings.filter((listing) => {
            if (listing.createdBy && listing.createdBy._id === user._id) {
                return listing;
            }
        });

        let thisUsersLeads = leads && leads.filter((lead) => {
            if (lead.sender._id === user._id || lead.receiver._id === user._id) {
                return lead;
            }
        });

        const newLeadsHere = [];

        let newOrNot = (createdTime) => {
            let rightNow = moment();
            let tOC = createdTime;
            let difference = rightNow.diff(tOC, 'days');
            if (difference <= 1) {
                newLeadsHere.push('NEW');
            }
        };

        for (let i = 0; i < thisUsersLeads.length; i++) {
            newOrNot(thisUsersLeads[i].createdAt);
        }
        
        return (
            <>
            {user && isAuthenticated ? (this.props.match.params.id === (user.id || user._id)) ? (!thesePrices.length) ? (<SkeletonAccountSettings loading />) : (
            <Container fluid>
                <div style={{ margin: 'auto', height: 100, width: 1300, display: 'grid', gridTemplateColumns: '2fr 6fr' }}>
                    <div style={{ margin: 'auto', paddingTop: 10 }}>
                        <div className="nameGreeting"><span style={{ fontSize: 22, fontFamily: 'Arial, Helvetica, sans-serif' }}>Hello,</span><span style={{ marginLeft: 5, fontSize: 26, fontWeight: 'bold', fontFamily: 'Arial, Helvetica, sans-serif', color: 'rgb(52, 181, 122)' }}>{user && user.firstName}!</span></div>
                    </div>
                    <div style={{ paddingTop: 10, margin: 'auto', display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', width: '90%' }}>
                        <div>
                            <Fab
                                variant="extended"
                                size="md"
                                category='accountSettings'
                                onClick={this.seeAccountSettings}
                                style={{ color: 'white', backgroundColor: 'rgb(52, 129, 181)', width: 175, marginTop: 'auto', marginBottom: 'auto', textAlign: 'right', marginLeft: 15, fontSize: 12, outline: 'none' }}
                            >
                            Account Settings
                            </Fab>
                        </div>
                        <div>
                            <Fab
                                variant="extended"
                                size="md"
                                category='myListings'
                                onClick={this.seeListings}
                                style={{ color: 'white', backgroundColor: 'rgb(52, 129, 181)', width: 175, marginTop: 'auto', marginBottom: 'auto', textAlign: 'right', marginLeft: 15, fontSize: 12, outline: 'none' }}
                            >
                            My Listings
                            </Fab>
                        </div>
                        <div>
                            <Fab
                                variant="extended"
                                size="md"
                                category='myLeads'
                                onClick={this.seeLeads}
                                style={{ color: 'white', backgroundColor: 'rgb(207, 83, 72)', width: 175, marginTop: 'auto', marginBottom: 'auto', textAlign: 'right', marginLeft: 15, fontSize: 12, outline: 'none' }}
                            >
                            {newLeadsHere.length > 0 ? `My Leads (${newLeadsHere.length})` : 'My Leads'}
                            </Fab>
                        </div>
                        <div>
                            {(((user && user.accountType === 'broker') && ((userStripeSubData && userStripeSubData.status === 'active') || (userStripeSubData && userStripeSubData.status === 'trialing')) && (userStripeSubData && userStripeSubData.subType === 'Broker')) || (user.accountType === 'admin')) ? <CreateListing user={user} auth={auth} thisUsersListings={thisUsersListings} /> : <UpgradeAccount prices={thesePrices} user={user} />}
                        </div>
                    </div>
                </div>
                <hr />
                <div style={{ height: 625, overflow: 'hidden', overflowY: 'scroll' }}>
                    {!isLoading && user && isAuthenticated ? this.state.pageview === 'leads' ? <LeadsList thisUsersLeads={thisUsersLeads} user={user} listings={thisUsersListings} /> : (this.state.pageview === 'accountSettings' && isAuthenticated) ? <AccountSettings user={user} prices={thesePrices} auth={auth} getPrices={this.props.getPrices} /> : <ListingsList thisUsersListings={thisUsersListings} user={user} loading={loading} /> : null}
                </div>
            </Container>) : <Redirect to="/" /> : <LoginModal modal />}
            {isAuthenticated ? 
            <div style={{ backgroundColor: 'whitesmoke', marginTop: 25 }}>
                <Footer />
            </div> : <div style={{ width: '100vw', textAlign: 'center', paddingTop: 100 }}><Link style={{ textAlign: 'center', verticalAlign: 'middle', fontSize: 24 }} to="/">Return To Home Page</Link></div>}
            </>
        )
    }
}

UserProfile.propTypes = {
    getListings: PropTypes.func.isRequired,
    getPrices: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    listings: PropTypes.array.isRequired,
    getLeads: PropTypes.func.isRequired,
    leads: PropTypes.array.isRequired,
    auth: PropTypes.object.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
    listings: state.listing.listings,
    prices: state.stripe.prices,
    auth: state.auth,
    loading: state.listing.loading,
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    leads: state.lead.leads,
});

export default connect(mapStateToProps, { getListings, loadUser, getLeads, getPrices })(UserProfile);