import React from 'react';
import SkeletonElement from './skeletonElement';
import Shimmer from './shimmer';
import { FaMapMarkerAlt } from 'react-icons/fa';
import './skeleton.css';

const SkeletonListingMap = () => {
    
    return (
        <div className="skeleton-wrapper-map" style={{ width: '100vw', height: 600, position: 'absolute', left: '0%', top: '8.75%' }}>
            <div className="skeleton-map">
                <SkeletonElement type="map" />
                <FaMapMarkerAlt className="skeleton-map-marker" />
            </div>
            <div className="skeleton-filterBox">
                <SkeletonElement type="title" />
                <SkeletonElement type="text" />
                <SkeletonElement type="text" />
                <div className="catPills">
                    <div>
                        <SkeletonElement type="pill" />
                        <SkeletonElement type="pill" />
                    </div>
                    <div>
                        <SkeletonElement type="pill" />
                        <SkeletonElement type="pill" />
                    </div>
                </div>
                <SkeletonElement type="title" />
                <div className="filterPills">
                    <div>
                        <SkeletonElement type="pill" />
                        <SkeletonElement type="pill" />
                        <SkeletonElement type="pill" />
                    </div>
                    <div>
                        <SkeletonElement type="pill" />
                        <SkeletonElement type="pill" />
                    </div>
                    <div>
                        <SkeletonElement type="pill" />
                        <SkeletonElement type="pill" />
                        <SkeletonElement type="pill" />
                    </div>
                </div>
            </div>
            <Shimmer />
        </div>
    )
}

export default SkeletonListingMap;