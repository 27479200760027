import React, { Component } from 'react';
import 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
import '../../listingDetailsLayouts/demographics/demographics.css';
import './matchComparison.css';

class AgesMatchComparison extends Component {
    
    
    render() {

        const { listings, inputValueTitle, populationReport } = this.props;
        
        const sortedByFourcastScore = listings && listings.length && listings.sort(function(a, b){return b.fourcastScore - a.fourcastScore});

        const addressStyle = (address) => {
            let thisAddressArray = [];
            thisAddressArray.push(address);
            let addressString = thisAddressArray.toString();
            let thisListingAddress = addressString.split(',');
            return thisListingAddress[0];
        };


        let getThisListingsAges = (thisListing) => {
            
            const { inputValueTitle } = this.props;
            
            const geoIdV4 = thisListing && thisListing.attomData && thisListing.attomData.geoIdV4 && thisListing.attomData.geoIdV4;

            // POPULATION DATA
            const getPopulation = [];
            const getDayPop = [];
        
                // ages 
            const elevenAndUnderArr = [];
            const twelveToSeventeenArr = [];
            const eighteenTo24Arr = [];
            const twentyfiveTo34Arr = [];
            const thirtyfiveTo44Arr = [];
            const fortyfiveTo54Arr = [];
            const fiftyfiveTo64Arr = [];
            const sixtyfiveToSeventyFourArr = [];
            const seventyFiveAndUpArr = [];

                // pop trends
            const popChanges = [];
            const popForecasts = [];

            
            if (geoIdV4 && geoIdV4.length) {

                
                for (let g = 0; g < geoIdV4.length; g++) {
                    
                    // POPULATION DATA
                    const totalPopulation = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population);
                    const thisDayPop = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Daytime);
                    
                    const zeroToFivePct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_0_5_Pct);
                    const sixTo11Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_6_11_Pct);
                    const twelveTo17Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_12_17_Pct);
                    const e18To24Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_18_24_Pct);
                    const t25To34Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_25_34_Pct);
                    const t35To44Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_35_44_Pct);
                    const f45To54Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_45_54_Pct);
                    const f55To64Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_55_64_Pct);
                    const s65To74Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_65_74_Pct);
                    const s75To84Pct = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Aged_75_84_Pct);
                    
                    getPopulation.push(totalPopulation);
                    getDayPop.push(thisDayPop);
                    elevenAndUnderArr.push((totalPopulation * (zeroToFivePct * .01)) + (totalPopulation * (sixTo11Pct * .01)));
                    twelveToSeventeenArr.push(totalPopulation * (twelveTo17Pct * .01));
                    eighteenTo24Arr.push(totalPopulation * (e18To24Pct * .01));
                    twentyfiveTo34Arr.push(totalPopulation * (t25To34Pct * .01));
                    thirtyfiveTo44Arr.push(totalPopulation * (t35To44Pct * .01));
                    fortyfiveTo54Arr.push(totalPopulation * (f45To54Pct * .01));
                    fiftyfiveTo64Arr.push(totalPopulation * (f55To64Pct * .01));
                    sixtyfiveToSeventyFourArr.push(totalPopulation * (s65To74Pct * .01));
                    seventyFiveAndUpArr.push(totalPopulation * (s75To84Pct * .01));


                    const popChangeSince2010 = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_Chg_Pct_2010);
                    let popChangeWeightedValue = (geoIdV4 && (geoIdV4.length > 1)) ? (geoIdV4 && (geoIdV4[g].type !== 'N4')) ? (popChangeSince2010 * .4) : (popChangeSince2010 * .6) : popChangeSince2010;
                    let popForecast5YearNum = geoIdV4 && geoIdV4[g].commV4Data && parseFloat(geoIdV4[g].commV4Data.population_5_Yr_Projection);

                    popChanges.push(popChangeWeightedValue);
                    popForecasts.push(popForecast5YearNum);

                }
                
            }

            // POPULATION DATA
            const currentPop = getPopulation && getPopulation.length && getPopulation.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            });
        
            // AGE GRAPH DATA

            const elevenAndUnder = elevenAndUnderArr && elevenAndUnderArr.length && elevenAndUnderArr.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const twelveToSeventeen = twelveToSeventeenArr && twelveToSeventeenArr.length && twelveToSeventeenArr.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const eighteenTo24 = eighteenTo24Arr && eighteenTo24Arr.length && eighteenTo24Arr.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const twentyfiveTo34 = twentyfiveTo34Arr && twentyfiveTo34Arr.length && twentyfiveTo34Arr.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const thirtyfiveTo44 = thirtyfiveTo44Arr && thirtyfiveTo44Arr.length && thirtyfiveTo44Arr.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const fortyfiveTo54 = fortyfiveTo54Arr && fortyfiveTo54Arr.length && fortyfiveTo54Arr.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const fiftyfiveTo64 = fiftyfiveTo64Arr && fiftyfiveTo64Arr.length && fiftyfiveTo64Arr.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const sixtyfiveToSeventyFour = sixtyfiveToSeventyFourArr && sixtyfiveToSeventyFourArr.length && sixtyfiveToSeventyFourArr.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);
            const seventyFiveAndUp = seventyFiveAndUpArr && seventyFiveAndUpArr.length && seventyFiveAndUpArr.reduce(function (accumulator, current) {
                return (parseFloat(accumulator) + parseFloat(current));
            }).toFixed(0);


            const thisAgeData = {
                ageData: {
                    labels: [['0-11'], ['12-17'], ['18-24'], ['25-34'], ['35-44'], ['45-54'], ['55-64'], ['65-74'], ['75+']],
                    datasets: [
                        {
                            label: 'Percentage of Population',
                            backgroundColor: [
                                'rgba(42, 207, 219, .1)',
                                (inputValueTitle === 'Gen Z') ? 'rgb(255, 105, 180)' : 'rgba(255, 105, 180, .1)',
                                (inputValueTitle === 'Gen Z') ? 'rgb(255, 105, 180)' : 'rgba(255, 105, 180, .1)',
                                (inputValueTitle === 'Young Millennials') ? 'rgb(52, 181, 122)' : 'rgba(52, 181, 122, .1)',
                                (inputValueTitle === 'Older Millennials') ? 'rgb(52, 181, 122)' : 'rgba(52, 181, 122, .1)',
                                (inputValueTitle === 'Gen X') ? 'rgb(219, 136, 42)' : 'rgba(219, 136, 42, .1)',
                                (inputValueTitle === 'Boomers') ? 'rgb(0, 0, 128)' : 'rgba(0, 0, 128, .1)',
                                (inputValueTitle === 'Boomers') ? 'rgb(0, 0, 128)' : 'rgba(0, 0, 128, .1)',
                                'rgba(42, 207, 219, .1)',
                            ],
                            data: [elevenAndUnder, twelveToSeventeen, eighteenTo24, twentyfiveTo34, thirtyfiveTo44, fortyfiveTo54, fiftyfiveTo64, sixtyfiveToSeventyFour, seventyFiveAndUp]
                        },
                    ]
                }
            };

            
            const thisCurrentPop = parseFloat(currentPop);

            const findDesiredAgeGroupNum = (inputValueTitle) => {
                if (inputValueTitle === 'Gen Z') {
                    const val1 = Math.floor((twelveToSeventeen / thisCurrentPop) * 100);
                    const val2 = Math.floor((eighteenTo24 / thisCurrentPop) * 100);
                    return Math.floor(val1 + val2);
                };
                if (inputValueTitle === 'Young Millennials') {
                    return Math.floor((twentyfiveTo34 / thisCurrentPop) * 100);
                };
                if (inputValueTitle === 'Older Millennials') {
                    return Math.floor((thirtyfiveTo44 / thisCurrentPop) * 100);
                };
                if (inputValueTitle === 'Gen X') {
                    return Math.floor((fortyfiveTo54 / thisCurrentPop) * 100);
                };
                if (inputValueTitle === 'Boomers') {
                    let val1 = Math.floor((fiftyfiveTo64 / thisCurrentPop) * 100);
                    let val2 = Math.floor((sixtyfiveToSeventyFour / thisCurrentPop) * 100);
                    return Math.floor(val1 + val2);
                };
            }
            
            const desiredAgeGroupNum = findDesiredAgeGroupNum(inputValueTitle);

            
            const populationObj = {address: thisListing.listingAddress, thisAgeData: thisAgeData, totalPopulation: thisCurrentPop, desiredAgeGroupNum: desiredAgeGroupNum};
            return populationObj;
        };
        

        // SORT BY RES PRICES
        const getFilteredListingsAges = sortedByFourcastScore && sortedByFourcastScore.map(listing => getThisListingsAges(listing));
        const filteredListingsAges = getFilteredListingsAges && getFilteredListingsAges.length && getFilteredListingsAges.sort(function(a, b){return b.desiredAgeGroupNum - a.desiredAgeGroupNum});
        
        const setAgesChart = (popData) => {
            return (
                <div>
                    <Bar
                        data={popData.thisAgeData.ageData}
                        height={200}
                        width={275}
                        options={{
                            title: {
                                display: true,
                                text: 'Population By Age',
                                fontSize: 10,
                                color: 'black'
                            },
                            layout: {
                                padding: {
                                    top: 20
                                }
                            },
                            plugins: {
                                datalabels: {
                                    display: true,
                                    color: "black",
                                    anchor: "end",
                                    offset: -20,
                                    align: "start",
                                    font: {
                                        weight: 'bold',
                                        size: 12,
                                    },
                                    formatter: function(value, context) {
                                        return Math.floor((value / popData.totalPopulation) * 100) + '%';
                                    },
                                },
                                legend: {
                                    display: true,
                                    position: "bottom"
                                }
                            },
                            indexAxis: `${populationReport ? 'y' : 'x'}`,
                            scales: {
                                x: populationReport ? {
                                    ticks: {
                                        display: false,
                                        beginAtZero: true,
                                    },
                                    grid: {
                                        display: false
                                    },
                                } : {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                    grid: {
                                        display: false
                                    },
                                    barPercentage: .95
                                },
                                y: populationReport ? {
                                    ticks: {
                                        beginAtZero: true,
                                    },
                                    grid: {
                                        display: false
                                    },
                                    barPercentage: .95
                                } : {
                                    ticks: {
                                        display: false,
                                        beginAtZero: true,
                                    },
                                    grid: {
                                        display: false
                                    },
                                },
                            }
                        }} 
                    />
                </div>
            )
        }
        
        
        return (
            <div style={{ position: 'relative', width: '100%' }}>
                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                    <div style={{ fontSize: 24, fontWeight: 700, color: 'darkgreen' }}>{inputValueTitle}</div>
                </div>
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', justifyContent: 'space-around' }}>
                    {filteredListingsAges && filteredListingsAges.length && filteredListingsAges.map((listing) => 
                        <div style={{ position: 'relative', marginTop: 25, width: 325 }}>
                            <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                                <div style={{ width: 20, fontSize: 12, fontWeight: 600, color: 'white', backgroundColor: 'rgb(52, 181, 122)', border: '1px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>{filteredListingsAges && filteredListingsAges.length && (filteredListingsAges.indexOf(listing) + 1)}</div>
                                <div style={{ marginLeft: 10, fontSize: 13, color: 'darkgreen', textAlign: 'center', fontWeight: 700 }}>{addressStyle(listing.address)}</div>
                                {filteredListingsAges && filteredListingsAges.length && filteredListingsAges.indexOf(listing) === 0 ? <div style={{ display: 'flex', fontSize: 12, fontWeight: 700, fontStyle: 'italic', justifyContent: 'center', alignItems: 'center', color: 'rgb(52, 181, 122)', marginLeft: 10, marginTop: 1 }}>Best</div> : null}
                            </div>
                            <div style={{ padding: 5, width: 275, height: 200, marginLeft: '50%', transform: 'translate(-50%, 0)' }}>
                                {(listing !== null) && setAgesChart(listing)}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default AgesMatchComparison;