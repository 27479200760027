import React, { Component } from 'react';
import { Button, CircularProgress } from '@mui/material';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Alert, NavLink, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { register } from '../../actions/authActions';
import { clearErrors } from '../../actions/errorActions';

const navLinkStyle = { color: 'white', fontSize: 13 };

class RegisterModal extends Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            modal: false,
            mobile: false,
            firstName: null,
            lastName: null,
            email: null,
            password: null,
            confirmPassword: null,
            company: null,
            accountType: null,
            msg: null, 
            step: 1,
            loading: false
        }
    }

    static propTypes = {
        isAuthenticated: PropTypes.bool,
        paymentInfoNeeded: PropTypes.bool,
        error: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        register: PropTypes.func.isRequired,
        clearErrors: PropTypes.func.isRequired
    }

    componentDidMount() {
        window && window.addEventListener('resize', this.mobileMode.bind(this));
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }

    componentWillUnmount() {
        window && window.removeEventListener('resize', this.mobileMode.bind(this));
    }

    mobileMode() {
        let screenWidth = window.screen.width;
        if (window && (screenWidth < 1000)) {
            this.setState({ mobile: true });
        } else {
            this.setState({ mobile: false });
        }
    }

    componentDidUpdate(prevProps) {
        const { error, isAuthenticated, user } = this.props;

        if (error !== prevProps.error) {
            
            //Check for Register Error
            if (error.id === 'REGISTER_FAIL') {
                this.setState({ msg: error.msg.msg })
            } else {
                this.setState({ msg: null });
            }
        }

        // If Authenticated Close Modal
        if (this.state.modal) {
            if (isAuthenticated && (user.accountType === 'basic')) {
                this.toggle();
            }
        }
    }

    toggle = () => {
        
        // Clear Errors
        this.props.clearErrors();

        // Clear Fields
        this.clearFields();

        this.setState({ 
            modal: !this.state.modal,
            loading: false
        })
    };

    clearFields = e => {
        this.setState({ firstName: '', lastName: '', email: '', password: '', confirmPassword: '', company: '', accountType: '' });
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value, msg: null, loading: false });
    };

    onSubmit = async e => {
        e.preventDefault();

        this.setState({ loading: true })

        const { firstName, lastName, email, password, confirmPassword, company, accountType } = this.state;

        let engagement = {
            listings: [],
            searches: 0,
            savedListings: [],
            savedSearches: []
        };

        const newUser = {
            firstName,
            lastName,
            email, 
            password,
            confirmPassword,
            company,
            accountType,
            engagement
        };

        // Attempt to Register
        await this.props.register(newUser);

    }

    render() { 
        
        const { style } = this.props;
        const { mobile } = this.state;
        
        return ( 
            <div>
                <NavLink style={style || navLinkStyle} href="#" onClick={this.toggle}>
                    SIGN UP
                </NavLink>
                <Modal style={{ minWidth: '50%', position: 'relative' }} isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Sign Up</ModalHeader>
                    <ModalBody style={{ minWidth: '100%' }}>
                        { this.state.msg ? (
                        <Alert color='danger'>{this.state.msg}</Alert>
                        ) : null}
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup>
                                <Row>
                                    <Col sm={6}>
                                        <Label style={{ display: 'flex' }} for='firstName'><div style={{ fontSize: 14, fontWeight: 'bold' }}>First Name</div><div style={{ fontSize: 14, fontWeight: 'bold', color: 'red' }}>*</div></Label>
                                        <Input 
                                            type='text'
                                            name='firstName'
                                            id='firstName'
                                            className='mb-3'
                                            onChange={this.onChange}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Label style={{ display: 'flex' }} for='lastName'><div style={{ fontSize: 14, fontWeight: 'bold' }}>Last Name</div><div style={{ fontSize: 14, fontWeight: 'bold', color: 'red' }}>*</div></Label>
                                        <Input 
                                            type='text'
                                            name='lastName'
                                            id='lastName'
                                            className='mb-3'
                                            onChange={this.onChange}
                                        />
                                    </Col>
                                </Row>
                                <Label style={{ display: 'flex' }} for='email'><div style={{ fontSize: 14, fontWeight: 'bold' }}>Email</div><div style={{ fontSize: 14, fontWeight: 'bold', color: 'red' }}>*</div></Label>
                                <Input 
                                    type='email'
                                    name='email'
                                    id='email'
                                    className='mb-3'
                                    onChange={this.onChange}
                                />
                                <Row>
                                    <Col sm={6}>
                                        <Label style={{ display: 'flex' }} for='password'><div style={{ fontSize: 14, fontWeight: 'bold' }}>Password</div><div style={{ fontSize: 14, fontWeight: 'bold', color: 'red' }}>*</div></Label>
                                        <Input 
                                            type='password'
                                            name='password'
                                            id='password'
                                            className='mb-3'
                                            onChange={this.onChange}
                                        />
                                    </Col>
                                    <Col sm={6}>
                                        <Label style={{ display: 'flex' }} for='password'><div style={{ fontSize: 14, fontWeight: 'bold' }}>Confirm Password</div><div style={{ fontSize: 14, fontWeight: 'bold', color: 'red' }}>*</div></Label>
                                        <Input 
                                            type='password'
                                            name='confirmPassword'
                                            id='confirmPassword'
                                            className='mb-3'
                                            onChange={this.onChange}
                                        />
                                    </Col>
                                </Row>
                                <Label style={{ display: 'flex' }} for='company'><div style={{ fontSize: 14, fontWeight: 'bold' }}>Company Name or Type</div><div style={{ fontSize: 14, fontWeight: 'bold', color: 'red' }}>*</div></Label>
                                <Input 
                                    type='text'
                                    name='company'
                                    id='company'
                                    className='mb-3'
                                    onChange={this.onChange}
                                />
                            </FormGroup>
                            <p style={{ marginTop: 20, marginBottom: 10, display: 'flex' }}><div style={{ fontSize: 14, fontWeight: 'bold' }}>Account Type:</div><div style={{ fontSize: 14, fontWeight: 'bold', color: 'red' }}>*</div></p>
                            <div style={{ width: '100%', display: `${mobile ? 'flexwrap' : 'flex'}`, justifyContent: 'center', alignItems: 'center' }}>
                                <div>
                                <Button
                                    variant="contained" 
                                    style={this.state.accountType === 'basic' ? { marginLeft: 10, color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', outline: 'none', fontSize: 11, width: 150, height: 55 } : { marginLeft: 10, color: 'black', backgroundColor: 'whitesmoke', fontSize: 11, width: 150, height: 55  }}
                                    category='basic'
                                    checked={this.state.accountType === 'basic'}
                                    onClick={(e) => {
                                        this.setState({ accountType: 'basic' });
                                    }}
                                >
                                Browse Properties
                                </Button>
                                <p style={{ marginLeft: 15, marginTop: 5, fontSize: 13, textAlign: 'left', fontWeight: 'bold' }}>Basic</p>
                                </div>
                                <div>
                                <Button
                                    variant="contained"
                                    style={this.state.accountType === 'pro' ? { marginLeft: `${mobile ? '10px' : '25px'}`, color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', outline: 'none', fontSize: 11, width: 150, height: 55  } : { marginLeft: `${mobile ? '10px' : '25px'}`, color: 'black', backgroundColor: 'whitesmoke', fontSize: 11, width: 150, height: 55  }}
                                    category='pro'
                                    checked={this.state.accountType === 'pro'}
                                    onClick={(e) => {
                                        this.setState({ accountType: 'pro' });
                                    }}
                                >
                                Fourcast Match
                                </Button>
                                <div style={{ marginLeft: `${mobile ? '15px' : '40px'}`, marginTop: 5, fontSize: 13, textAlign: 'left', fontWeight: 'bold' }}>Pro</div>
                                <div style={{ marginLeft: `${mobile ? '15px' : '40px'}`, marginTop: 0, fontSize: 10, textAlign: 'left', fontWeight: 'bold', color: 'darkgreen' }}>Free Trial</div>
                                </div>
                                <div>
                                <Button
                                    variant="contained" 
                                    style={this.state.accountType === 'broker' ? { marginLeft: `${mobile ? '10px' : '25px'}`, color: 'white', backgroundColor: 'rgba(14, 150, 45, 0.9)', outline: 'none', fontSize: 11, width: 150, height: 55, marginTop: `${mobile ? '10px' : '0px'}` } : { marginLeft: `${mobile ? '10px' : '25px'}`, color: 'black', backgroundColor: 'whitesmoke', fontSize: 11, width: 150, height: 55, marginTop: `${mobile ? '10px' : '0px'}` }}
                                    category='broker'
                                    checked={this.state.accountType === 'broker'}
                                    onClick={(e) => {
                                        this.setState({ accountType: 'broker' });
                                    }}
                                >
                                Fourcast Match + Advertise
                                </Button>
                                <div style={{ marginLeft: `${mobile ? '15px' : '40px'}`, marginTop: `${mobile ? '10px' : '5px'}`, fontSize: 13, textAlign: 'left', fontWeight: 'bold' }}>Broker</div>
                                <div style={{ marginLeft: `${mobile ? '15px' : '40px'}`, marginTop: 0, fontSize: 10, textAlign: 'left', fontWeight: 'bold', color: 'darkgreen' }}>Free Trial</div>
                                </div>
                            </div>
                            <Row style={{ height: 75, marginTop: 20 }}>
                                <Button onClick={this.onSubmit} variant="contained" endIcon={this.state.loading ? <CircularProgress style={{ color: 'white' }} size={20} thickness={3} /> : null} style={{ outline: 'none', margin: 'auto', backgroundColor: 'rgb(35, 126, 85)', width: '90%', height: 50, color: 'white' }}>
                                    <div style={{ marginLeft: 10, paddingRight: 10 }}>Continue</div>
                                </Button>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    user: state.auth.user,
    paymentInfoNeeded: state.auth.paymentInfoNeeded,
    error: state.error,
});

export default connect(mapStateToProps, { register, clearErrors })(RegisterModal);