import React, { Component } from 'react';
import { Paper } from '@mui/material';
import yelplogo from '../../Assets/yelplogo.png';
import WalkScoreData from '../listingDetailsLayouts/traffic/walkscoreData';
import BusyRoadTraffic from '../listingDetailsLayouts/traffic/busyRoadTraffic';
import TotalReviewCount from '../listingDetailsLayouts/traffic/totalReviewCount';
import fourcastLogo from '../../Assets/fourcast_alt_logo_green.png';
import '../listingDetailsLayouts/traffic/traffic.css';
import '../listingDetailsLayouts/summary/summary.css';



class AccessibilityAndEngagementReport extends Component {
    
    
    render() { 

        const { walkScoreData, yelpData, thisListing, scores, thisListing: { listingAddress: getListingAddress } = {}} = this.props;

        

        let thisAddressArray = [];
        thisAddressArray.push(getListingAddress);
        let addressString = thisAddressArray.toString();
        let thisListingAddress = addressString.split(',');
        

        return (
            <Paper id="trafficReportPage" elevation={5} style={{ margin: 'auto', width: '210mm', height: '297mm', paddingTop: 15, position: 'relative' }}>
                <div style={{ width: '100%', height: '100%' }}>
                    <div style={{ display: 'grid', gridTemplateColumns: '6fr 1fr' }}>
                        <div className='reportAddress'>
                            <h2>{thisListingAddress[0]}</h2>
                        </div>
                        <div className='reportFourcastLogoArea'>
                            <img className='reportFourcastLogo' src={fourcastLogo} alt='Fourcast Reports Logo' />
                        </div>
                    </div>
                    <hr />
                    <div style={{ marginTop: 15, height: '30%', display: 'grid', gridTemplateColumns: '4fr 5fr' }}>
                        <div style={{ backgroundColor: 'rgb(21, 134, 84)', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                            <h5 style={{ margin: '15px 0px 10px 20px', paddingBottom: 10, color: 'white' }}>Accessibility:</h5>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                            <div className="walkscoreData" id="accessibilityData">
                                <img id='walkScoreLogoApi' className='walkscoreLogoTraffic' src={walkScoreData.logo_url} alt='Walk Score logo in report.' />
                                <WalkScoreData walkScoreData={walkScoreData} reportMode={true} />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div style={{ marginTop: 15, height: '26%', display: 'grid', gridTemplateColumns: '4fr 5fr' }}>
                        <div style={{ backgroundColor: 'rgb(21, 134, 84)', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                            <h5 style={{ margin: '15px 0px 10px 20px', paddingBottom: 10, color: 'white' }}>Road Traffic:</h5>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
                            <div className='busyRoadTraffic'>
                                <div id='reportBusyRoadSection' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <BusyRoadTraffic thisListing={thisListing} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div style={{ marginTop: 15, height: '30%', display: 'grid', gridTemplateColumns: '4fr 5fr' }}>
                        <div style={{ backgroundColor: 'rgb(21, 134, 84)', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                            <h5 style={{ margin: '15px 0px 10px 20px', paddingBottom: 10, color: 'white' }}>Peer Engagement:</h5>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }}>
                            <div className='engagement' id='engagementData'>
                                <img className='yelpLogoTraffic' src={yelplogo} alt='Yelp logo in report.' />
                                <TotalReviewCount thisListing={thisListing} yelpData={yelpData} scores={scores} reportMode={true} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ fontSize: 10, position: 'absolute', bottom: '1%', right: '1%', fontWeight: 800, color: 'darkgreen' }}>fourcastscore.com</div>
            </Paper>
        );
    }
}

export default AccessibilityAndEngagementReport;