import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { Card } from '@material-ui/core';
import { MdLocationPin, MdDraw, MdSearch, MdPeopleOutline, MdOutlineWatchLater, MdAttachMoney, MdListAlt, MdFilterList, MdOutlinePriceChange, MdOutlineGppGood, MdDirectionsWalk, MdOutlinePlusOne, MdExposureNeg2, MdTravelExplore, MdOutlineChecklistRtl, MdOutlinePlaylistAdd, MdOutlineMultipleStop, MdFileDownload, MdOutlineMoreVert } from 'react-icons/md';
import productFeaturesLaptop from '../../Assets/productFeatures_laptop.png';
import fourcastLogo from '../../Assets/fourcast_alt_logo_green.png';
import productMapScreen from '../../Assets/productFeatures_mapHighlight.png';
import productMapFullScreen from '../../Assets/productFeatures_mapFullScreen.png';
import productMapSquare from '../../Assets/productFeatures_mapHighlightSquare.png';
import productScoreSquare from '../../Assets/productFeatures_score.png';
import product90 from '../../Assets/productFeatures_90.png';
import product81 from '../../Assets/productFeatures_81.png';
import product71 from '../../Assets/productFeatures_71.png';
import product67 from '../../Assets/productFeatures_67.png';
import productFilterboxSquare from '../../Assets/productFeatures_filterboxHighlightSquare.png';
import productFourcastMatchHome from '../../Assets/productFeatures_matchHome.png';
import productFourcastMatchBannerButton from '../../Assets/productFeatures_matchBannerButton.png';
import productFourcastMatchPriceSize from '../../Assets/productFeatures_priceSize.png';
import productFourcastMatchTraffFilts from '../../Assets/productFeatures_traffFilts.png';
import productFourcastMatchScoring1 from '../../Assets/productFeatures_matchScoring1.png';
import productFourcastMatchScoring2 from '../../Assets/productFeatures_matchScoring2.png';
import productFourcastMatchScoring3 from '../../Assets/productFeatures_matchScoring3.png';
import productReportsLanding from '../../Assets/productFeatures_reportsLanding.png';
import productReportsResult from '../../Assets/productFeatures_reportsResult.png';
import productReportsAddressInput from '../../Assets/productFeatures_reportsAddressInput.png';
import productReportsCompInput from '../../Assets/productFeatures_reportsCompInput.png';
import productReportsSummary from '../../Assets/productFeatures_reportsSummary.png';
import productReportsDemoData from '../../Assets/productFeatures_reportsDemoData.png';
import productReportsCompData from '../../Assets/productFeatures_reportsCompData.png';
import '../welcome/description.css';

class ProductFeaturesLaptop extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            logo: false,
            comp1: false,
            comp2: false,
            comp3: false,
            comp4: false,
            comp5: false,
            comp6: false,
            comp7: false,
            comp8: false,
            comp9: false
        }
    }

    componentDidMount() {
        window && window.addEventListener('scroll', this.fadeInCircles.bind(this));
    }

    componentWillUnmount() {
        window && window.removeEventListener('scroll', this.fadeInCircles.bind(this));
    }

    fadeInCircles() {
        if (window && (window.scrollY > 10) && (window.scrollY < 1300)) {
            this.setState({ comp1: true, comp2: false, comp3: false, comp4: false, comp5: false, comp6: false, comp7: false, comp8: false, comp9: false })
        } else if (window && (window.scrollY > 1300) && (window.scrollY < 1900)) {
            this.setState({ comp1: false, comp2: true, comp3: false, comp4: false, comp5: false, comp6: false, comp7: false, comp8: false, comp9: false })
        } else if (window && (window.scrollY > 1900) && (window.scrollY < 2500)) {
            this.setState({ comp1: false, comp2: false, comp3: true, comp4: false, comp5: false, comp6: false, comp7: false, comp8: false, comp9: false })
        } else if (window && (window.scrollY > 2500) && (window.scrollY < 3100)) {
            this.setState({ comp1: false, comp2: false, comp3: false, comp4: true, comp5: false, comp6: false, comp7: false, comp8: false, comp9: false })
        } else if (window && (window.scrollY > 3100) && (window.scrollY < 3700)) {
            this.setState({ comp1: false, comp2: false, comp3: false, comp4: false, comp5: true, comp6: false, comp7: false, comp8: false, comp9: false })
        } else if (window && (window.scrollY > 3700) && (window.scrollY < 4300)) {
            this.setState({ comp1: false, comp2: false, comp3: false, comp4: false, comp5: false, comp6: true, comp7: false, comp8: false, comp9: false })
        } else if (window && (window.scrollY > 4300) && (window.scrollY < 4900)) {
            this.setState({ comp1: false, comp2: false, comp3: false, comp4: false, comp5: false, comp6: false, comp7: true, comp8: false, comp9: false })
        } else if (window && (window.scrollY > 4900) && (window.scrollY < 5500)) {
            this.setState({ comp1: false, comp2: false, comp3: false, comp4: false, comp5: false, comp6: false, comp7: false, comp8: true, comp9: false })
        } else if (window && (window.scrollY > 5500) && (window.scrollY < 6000)) {
            this.setState({ comp1: false, comp2: false, comp3: false, comp4: false, comp5: false, comp6: false, comp7: false, comp8: false, comp9: true })
        } else {
            this.setState({ logo: false })
        }
    }

    render() { 

        const { logo, comp1, comp2, comp3, comp4, comp5, comp6, comp7, comp8, comp9 } = this.state;

        return ( 
            <Container fluid>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 2fr', paddingBottom: 200 }}>
                    <div style={{ height: 5400 }}>
                        <div style={{ height: 650, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '80%' }}>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 42 }}>Browse Listings</div>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16, paddingLeft: 2, marginTop: 20 }}>Discover and explore active* listings.</div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdLocationPin style={{ height: '100%', width: '100%', color: 'rgb(21, 134, 84)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Up-to-date listings</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdDraw style={{ height: '100%', width: '100%', color: 'dodgerblue' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Polygon drawing tool</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdSearch style={{ height: '100%', width: '100%', color: 'rgb(223, 82, 69)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Search by address</div>
                                </div>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 12, paddingLeft: 2, marginTop: 20 }}>* Listings available in select cities.</div>
                            </div>
                        </div>
                        <div style={{ height: 650, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '80%' }}>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 42 }}>Fourcast Score</div>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16, paddingLeft: 2, marginTop: 20, width: '75%' }}>Each space gets an overall Fourcast Score which is the average of four sub-category scores.</div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <img src={product81} style={{ height: '100%', width: '100%' }} alt='product features score square' />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Fourcast Score</div>
                                </div>
                                <div className='prodFeaturesScoreChild' style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20, marginLeft: 15 }}>
                                    <div style={{ width: 20, height: 30 }}>__</div>
                                    <div style={{ height: 30, width: 30 }}>
                                        <img src={productScoreSquare} style={{ height: '100%', width: '100%' }} alt='product features score square' />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Traffic Score</div>
                                </div>
                                <div className='prodFeaturesScoreChild' style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20, marginLeft: 15 }}>
                                    <div style={{ width: 20, height: 30 }}>__</div>
                                    <div style={{ height: 30, width: 30 }}>
                                        <img src={product90} style={{ height: '100%', width: '100%' }} alt='product features score square' />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Demographics Score</div>
                                </div>
                                <div className='prodFeaturesScoreChild' style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20, marginLeft: 15 }}>
                                    <div style={{ width: 20, height: 30 }}>__</div>
                                    <div style={{ height: 30, width: 30 }}>
                                        <img src={product67} style={{ height: '100%', width: '100%' }} alt='product features score square' />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Competition Score</div>
                                </div>
                                <div className='prodFeaturesScoreChild' style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20, marginLeft: 15 }}>
                                    <div style={{ width: 20, height: 30 }}>__</div>
                                    <div style={{ height: 30, width: 30 }}>
                                        <img src={product71} style={{ height: '100%', width: '100%' }} alt='product features score square' />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Property Score</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 650, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '80%' }}>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 42 }}>Advanced Filtering</div>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16, paddingLeft: 2, marginTop: 20 }}>Use highly specific, relevant filters.</div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdPeopleOutline style={{ height: '100%', width: '100%', color: 'dodgerblue' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Demographic characteristics</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdOutlineWatchLater style={{ height: '100%', width: '100%', color: 'rgb(223, 82, 69)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Peak traffic times</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdAttachMoney style={{ height: '100%', width: '100%', color: 'rgb(21, 134, 84)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Product prices</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdOutlineMoreVert style={{ height: '100%', width: '100%', color: 'dodgerblue' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>and more</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 650, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '80%' }}>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 42, color: 'rgb(21, 134, 84)', fontWeight: 'bolder' }}>Fourcast Match</div>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16, paddingLeft: 2, marginTop: 20 }}>Personalized searching tool. Set specific characteristics of the ideal space and area you are looking for. Get instantly matched.</div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdOutlinePriceChange style={{ height: '100%', width: '100%', color: 'dodgerblue' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Price & Size</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdOutlineGppGood style={{ height: '100%', width: '100%', color: 'rgb(223, 82, 69)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Good vs Bad Neighbors</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdDirectionsWalk style={{ height: '100%', width: '100%', color: 'rgb(21, 134, 84)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10, width: '80%' }}>People types, traffic patterns, & more</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 650, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '80%' }}>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 42 }}>Adjusted Scores</div>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16, paddingLeft: 2, marginTop: 20, width: '80%' }}>Each space is either awarded or penalized points for every selected filter.</div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdOutlinePlusOne style={{ height: '100%', width: '100%', color: 'rgb(21, 134, 84)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10, width: '80%' }}>Points are added if the filter is present at the location</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdExposureNeg2 style={{ height: '100%', width: '100%', color: 'rgb(223, 82, 69)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10, width: '80%' }}>Points are deducted if the filter is not present</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 650, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '80%' }}>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 42 }}>Custom Matched</div>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16, paddingLeft: 2, marginTop: 20 }}>The new Fourcast Match Score reflects how closely each listing matches your criteria.</div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdTravelExplore style={{ height: '100%', width: '100%', color: 'dodgerblue' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Discover compatible spaces</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdOutlineWatchLater style={{ height: '100%', width: '100%', color: 'rgb(223, 82, 69)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Save time, money, and effort</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdOutlineChecklistRtl style={{ height: '100%', width: '100%', color: 'rgb(21, 134, 84)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Full transparency</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 650, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '80%' }}>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 42, color: 'rgb(21, 134, 84)', fontWeight: 'bolder' }}>Fourcast Reports</div>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16, paddingLeft: 2, marginTop: 20 }}>Generate customized, printable reports.</div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdLocationPin style={{ height: '100%', width: '100%', color: 'rgb(21, 134, 84)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10, width: '80%' }}>Select space(s)</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdOutlinePlaylistAdd style={{ height: '100%', width: '100%', color: 'dodgerblue' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10 }}>Pick datapoints to include</div>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 650, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <div style={{ width: '80%' }}>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 42 }}>Download & Print</div>
                                <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 16, paddingLeft: 2, marginTop: 20, width: '80%' }}>Immediately download and print valuable reports for one or multiple properties.</div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdOutlineMultipleStop style={{ height: '100%', width: '100%', color: 'dodgerblue' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10, width: '80%' }}>Instantly switch between multiple property reports</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', marginTop: 20 }}>
                                    <div style={{ height: 30, width: 30 }}>
                                        <MdFileDownload style={{ height: '100%', width: '100%', color: 'rgb(21, 134, 84)' }} />
                                    </div>
                                    <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', fontSize: 20, paddingLeft: 10, width: '80%' }}>Export to PDF</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'left', alignItems: 'center', position: 'sticky', top: 100, alignSelf: 'start', paddingTop: 100 }}>
                        {comp1 ? <div style={{ position: 'relative' }}>
                            <img src={productFeaturesLaptop} alt="product features laptop" />
                            <img src={productMapScreen} style={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-49.5%, -46%)', maxHeight: '77%', maxWidth: '77%' }} alt='product features map' />
                            <img src={fourcastLogo} style={{ position: 'absolute', top: "8%", left: "50%", transform: 'translate(-49.5%, -8%)', height: 40, width: 128, zIndex: 999 }} alt='product features map' />
                            <div style={{ position: 'absolute', top: "7%", left: "51.5%", transform: 'translate(-51.5%, -7%)', backgroundColor: 'rgb(21, 134, 84)', width: '77%', height: '10%' }}></div>
                        </div> : null}
                        {comp2 ? <div style={{ position: 'relative' }}>
                            <img src={productFeaturesLaptop} style={{ zIndex: 999 }} alt="product features laptop" />
                            <img src={productMapScreen} style={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-49.5%, -46%)', maxHeight: '77%', maxWidth: '77%' }} alt='product features map' />
                            <img src={fourcastLogo} style={{ position: 'absolute', top: "8%", left: "50%", transform: 'translate(-49.5%, -8%)', height: 40, width: 128, zIndex: 998 }} alt='product features map' />
                            <div style={{ position: 'absolute', top: "7%", left: "51.5%", transform: 'translate(-51.5%, -7%)', backgroundColor: 'rgb(21, 134, 84)', width: '77%', height: '10%' }}></div>
                            <div id='prodFeaturesBlock1'></div>
                            <div id='prodFeaturesBlock2'></div>
                        </div> : null}
                        {comp3 ? <div style={{ position: 'relative' }}>
                            <img src={productFeaturesLaptop} alt="product features laptop" />
                            <img src={productMapScreen} style={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-49.5%, -46%)', maxHeight: '77%', maxWidth: '77%' }} alt='product features map' />
                            <img src={fourcastLogo} style={{ position: 'absolute', top: "8%", left: "50%", transform: 'translate(-49.5%, -8%)', height: 40, width: 128, zIndex: 999 }} alt='product features map' />
                            <div style={{ position: 'absolute', top: "7%", left: "51.5%", transform: 'translate(-51.5%, -7%)', backgroundColor: 'rgb(21, 134, 84)', width: '77%', height: '10%' }}></div>
                            <div id='prodFeaturesBlock3'></div>
                            <div id='prodFeaturesBlock4'></div>
                            <div id='mapWhiteoutSquare'></div>
                            <Card id='prodFeaturesMapSquareCard' style={{ left: '25%', top: '25%', zIndex: 1000 }}>
                                <div style={{ position: 'relative' }}>
                                    <img src={productMapSquare} style={{ height: '100%', width: '100%' }} alt='product features map square' />
                                </div>
                            </Card>
                            <Card id='prodFeaturesScoreCard'>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    <img src={product81} style={{ height: '50%', width: '50%' }} alt='product features score square' />
                                </div>
                            </Card>
                        </div> : null}
                        {comp4 ? <div style={{ position: 'relative' }}>
                            <img src={productFeaturesLaptop} alt="product features laptop" />
                            <img src={productMapScreen} style={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-49.5%, -46%)', maxHeight: '77%', maxWidth: '77%' }} alt='product features map' />
                            <img src={fourcastLogo} style={{ position: 'absolute', top: "8%", left: "50%", transform: 'translate(-49.5%, -8%)', height: 40, width: 128, zIndex: 999 }} alt='product features map' />
                            <div style={{ position: 'absolute', top: "7%", left: "51.5%", transform: 'translate(-51.5%, -7%)', backgroundColor: 'rgb(21, 134, 84)', width: '77%', height: '10%' }}></div>
                            <div id='filterboxWhiteoutSquare'></div>
                            <div id='mapWhiteoutSquare'></div>
                            <Card id='prodFeaturesMapSquareCard2'>
                                <div style={{ position: 'relative' }}>
                                    <img src={productMapSquare} style={{ height: '100%', width: '100%' }} alt='product features map square' />
                                </div>
                            </Card>
                            <Card id='prodFeaturesFilterboxSquareCard'>
                                <div style={{ position: 'relative' }}>
                                    <img src={productFilterboxSquare} style={{ height: '100%', width: '100%' }} alt='product features filter box square' />
                                </div>
                            </Card>
                        </div> : null}
                        {comp5 ? <div style={{ position: 'relative' }}>
                            <img src={productFeaturesLaptop} alt="product features laptop" />
                            <img src={fourcastLogo} style={{ position: 'absolute', top: "8%", left: "50%", transform: 'translate(-49.5%, -8%)', height: 40, width: 128, zIndex: 999, opacity: .5 }} alt='product features map' />
                            <div style={{ position: 'absolute', top: "7%", left: "51.5%", transform: 'translate(-51.5%, -7%)', backgroundColor: 'rgba(21, 134, 84, .5)', width: '77%', height: '9.25%' }}></div>
                            <img id='prodFeaturesMatchHomepage2' src={productFourcastMatchHome} alt='product features fourcast match home screen' />
                            <Card id='prodFeaturesPriceSizeCard' style={{ top: '37%' }}>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productFourcastMatchPriceSize} style={{ height: '95%', width: '100%' }} alt='product features fourcast match price and size' />
                                </div>
                            </Card>
                            <Card id='prodFeaturesTraffFiltsCard'>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productFourcastMatchTraffFilts} style={{ height: '95%', width: '95%' }} alt='product features fourcast match traffic filters' />
                                </div>
                            </Card>
                            <div id='matchBannerButtonGreenSquare'></div>
                            <Card id='prodFeaturesMatchBannerButton'>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productFourcastMatchBannerButton} style={{ height: '75%', width: '75%', zIndex: 10001 }} alt='product features fourcast match banner button' />
                                </div>
                            </Card>
                            <div id='prodFeaturesBlock7' style={{ left: '35%' }}></div>
                            <div id='matchHomeWhiteoutSquare'></div>
                            <div id='prodFeaturesBlock5' style={{ top: '23%' }}></div>
                            <div id='prodFeaturesBlock6'></div>
                        </div> : null}
                        {comp6 ? <div style={{ position: 'relative' }}>
                            <img src={productFeaturesLaptop} alt="product features laptop" />
                            <img src={fourcastLogo} style={{ position: 'absolute', top: "8%", left: "50%", transform: 'translate(-49.5%, -8%)', height: 40, width: 128, zIndex: 999 }} alt='product features map' />
                            <img className='productMapFullScreen' src={productMapFullScreen} style={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-49.5%, -46%)', maxHeight: '77%', maxWidth: '77%' }} alt='product features map' />
                            <div style={{ position: 'absolute', top: "7%", left: "51.5%", transform: 'translate(-51.5%, -7%)', backgroundColor: 'rgb(21, 134, 84)', width: '77%', height: '9.5%' }}></div>
                            <Card id='prodFeaturesPriceSizeCard'>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productFourcastMatchScoring1} style={{ height: '95%', width: '100%' }} alt='product features fourcast match price and size' />
                                </div>
                            </Card>
                            <Card id='prodFeaturesTraffFiltsCard'>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productFourcastMatchScoring2} style={{ height: '95%', width: '95%' }} alt='product features fourcast match traffic filters' />
                                </div>
                            </Card>
                            <div id='prodFeaturesBlock5'></div>
                            <div id='prodFeaturesBlock6'></div>
                        </div> : null}
                        {comp7 ? <div style={{ position: 'relative' }}>
                            <img src={productFeaturesLaptop} alt="product features laptop" />
                            <img src={fourcastLogo} style={{ position: 'absolute', top: "8%", left: "50%", transform: 'translate(-49.5%, -8%)', height: 40, width: 128, zIndex: 999 }} alt='product features map' />
                            <img src={productMapFullScreen} style={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-49.5%, -46%)', maxHeight: '77%', maxWidth: '77%' }} alt='product features map' />
                            <div style={{ position: 'absolute', top: "7%", left: "51.5%", transform: 'translate(-51.5%, -7%)', backgroundColor: 'rgb(21, 134, 84)', width: '77%', height: '9.5%' }}></div>
                            <Card id='prodFeaturesTraffFiltsCard' style={{ top: '25%', left: '57%', height: 250 }}>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productFourcastMatchScoring3} style={{ height: '95%', width: '100%' }} alt='product features fourcast match price and size' />
                                </div>
                            </Card>
                            <div id='prodFeaturesBlock6' style={{ backgroundColor: 'salmon', animationDelay: '0000ms', bottom: '10%' }}></div>
                        </div> : null}
                        {comp8 ? <div style={{ position: 'relative' }}>
                            <img src={productFeaturesLaptop} alt="product features laptop" />
                            <img src={fourcastLogo} style={{ position: 'absolute', top: "8%", left: "50%", transform: 'translate(-49.5%, -8%)', height: 40, width: 128, zIndex: 999 }} alt='product features map' />
                            <img className='productMapFullScreen' src={productReportsLanding} style={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-49.5%, -46%)', maxHeight: '77%', maxWidth: '77%' }} alt='product features reports landing page' />
                            <div style={{ position: 'absolute', top: "7%", left: "51.5%", transform: 'translate(-51.5%, -7%)', backgroundColor: 'rgb(21, 134, 84)', width: '77%', height: '9.5%' }}></div>
                            <Card id='prodFeaturesPriceSizeCard' style={{ left: '68%', top: '0%', zIndex: 1002 }}>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productReportsAddressInput} style={{ height: '95%', width: '100%' }} alt='product features reports address input' />
                                </div>
                            </Card>
                            <Card id='prodFeaturesTraffFiltsCard' style={{ left: '55%', top: '85%', zIndex: 1002 }}>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productReportsCompInput} style={{ height: '95%', width: '95%' }} alt='product features reports component input' />
                                </div>
                            </Card>
                            <div id='prodFeaturesBlock6'></div>
                            <div id='prodFeaturesBlock7'></div>
                        </div> : null}
                        {comp9 ? <div style={{ position: 'relative' }}>
                            <img src={productFeaturesLaptop} alt="product features laptop" />
                            <img src={fourcastLogo} style={{ position: 'absolute', top: "8%", left: "50%", transform: 'translate(-49.5%, -8%)', height: 40, width: 128, zIndex: 999 }} alt='product features map' />
                            <img className='productMapFullScreen' src={productReportsResult} style={{ position: 'absolute', top: "50%", left: "50%", transform: 'translate(-49.5%, -46%)', maxHeight: '77%', maxWidth: '77%' }} alt='product features map' />
                            <div style={{ position: 'absolute', top: "7%", left: "51.5%", transform: 'translate(-51.5%, -7%)', backgroundColor: 'rgb(21, 134, 84)', width: '77%', height: '9.5%' }}></div>
                            <Card id='prodFeaturesPriceSizeCard'>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productReportsSummary} style={{ height: '95%', width: '100%' }} alt='product features fourcast match price and size' />
                                </div>
                            </Card>
                            <Card id='prodFeaturesPriceSizeCard' style={{ left: '80%', top: '0%', zIndex: 1002 }}>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productReportsDemoData} style={{ height: '95%', width: '100%' }} alt='product features reports address input' />
                                </div>
                            </Card>
                            <Card id='prodFeaturesTraffFiltsCard' style={{ left: '50%', top: '95%', zIndex: 1002 }}>
                                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', marginTop: 10 }}>
                                    <img src={productReportsCompData} style={{ height: '95%', width: '95%' }} alt='product features reports component input' />
                                </div>
                            </Card>
                            <div id='prodFeaturesBlock5'></div>
                            <div id='prodFeaturesBlock6' style={{ backgroundColor: 'dodgerblue' }}></div>
                            <div id='prodFeaturesBlock7' style={{ left: '45%', backgroundColor: 'salmon' }}></div>
                        </div> : null}
                    </div>
                </div>
                <div style={{ height: 850, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div>
                        <div style={{ width: '100%', textAlign: 'center', marginBottom: 40 }}>
                            <h2>Explore Property Searching Capabilities</h2>
                        </div>
                        <div>
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/ulptKpuLvxQ?si=i7AepaM3iY80b6PS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                        </div>
                    </div>
                </div>
            </Container>
         );
    }
}
 
export default ProductFeaturesLaptop;
