import axios from 'axios';
import { GET_LISTINGS, ADD_LISTING, ADD_LISTING_FAIL, LISTINGS_LOADING, GET_LISTING, UPDATE_LISTING, GET_SAVED_LISTINGS } from './types';
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';

// Pointing Axios baseURL towards AWS API Gateway URL
// Will most likely be changed again at some point because of the /dev
const axiosAWS = axios.create({
    baseURL: 'https://bgeg45v1tk.execute-api.us-east-1.amazonaws.com/dev'
});

export const getListings = page => (dispatch, getState) => {
    dispatch(setListingsLoading());
    if (page.path === 'listingMap') {
        axiosAWS
            .get('/api/listings/map')
            .then(res => 
                dispatch({
                    type: GET_LISTINGS,
                    payload: res.data
                })
            ).catch(err => console.log(err));
    } else if (page.path === 'customMap') {
        axiosAWS
            .post('/api/listings/custom-map', page)
            .then(res => 
                dispatch({
                    type: GET_LISTINGS,
                    payload: res.data
                })
            ).catch(err => console.log(err));
    } else if (page.path === 'listingList') {
        axiosAWS
            .post('/api/listings/listing-list', page, tokenConfig(getState))
            .then(res => 
                dispatch({
                    type: GET_LISTINGS,
                    payload: res.data
                })
            ).catch(err => console.log(err));
    } else if (page.path === 'savedListings') {
        axiosAWS
            .post('/api/listings/saved-list', page, tokenConfig(getState))
            .then(res => 
                dispatch({
                    type: GET_SAVED_LISTINGS,
                    payload: res.data
                })
            ).catch(err => console.log(err));
    } else if (page.path === 'listingAddresses') {
        axiosAWS
            .post('/api/listings/listing-addresses', page, tokenConfig(getState))
            .then(res => 
                dispatch({
                    type: GET_LISTINGS,
                    payload: res.data
                })
            ).catch(err => console.log(err));
    } else if (page.path === 'searchedAddress') {
        axiosAWS
            .post('/api/scores/searched-address', page)
            .then(res => 
                dispatch({
                    type: GET_LISTINGS,
                    payload: res.data
                })
            ).catch(err => console.log(err));
    }
};

export const addListing = listing => (dispatch, getState) => {
    axiosAWS
        .post('/api/listings', listing, tokenConfig(getState))
        .then(res => 
            dispatch({
                type: ADD_LISTING,
                payload: res.data
            })
        ).catch(err => {
            dispatch(returnErrors(err.response.data, err.response.status, 'ADD_LISTING_FAIL'));
            dispatch({
                type: ADD_LISTING_FAIL
            })
        })
};

export const getListing = id => dispatch => {
    dispatch(setListingsLoading());
    axiosAWS
        .get(`/api/listings/${id}`)
        .then(res => 
            dispatch({
                type: GET_LISTING,
                payload: res.data
            })
        ).catch(err => console.log(err));
};

export const updateListing = updatedListing => (dispatch, getState) => {
    axiosAWS
        .patch(`/api/listings/${updatedListing.thisListingID}`, { 
            active: updatedListing.activeStatus,
            squareFeet: updatedListing.squareFeet,
            pricePerSquareFoot: updatedListing.pricePerSquareFoot,
            frontage: updatedListing.frontage,
            propertyFeatures: updatedListing.propertyFeatures,
            images: updatedListing.images
        }, tokenConfig(getState))
        .then(res => 
            dispatch({
                type: UPDATE_LISTING,
                payload: res.data
            })
        ).catch(err => 
            dispatch(returnErrors(err.response.data, err.response.status))
        );
};

export const setListingsLoading = () => {
    return {
        type: LISTINGS_LOADING
    }
};

