import React, { Component } from 'react';
import { 
    Modal, 
    ModalHeader, 
    ModalBody, 
    NavLink } 
from 'reactstrap';
import { Link } from 'react-router-dom';
import { Card, CardContent, Stack } from '@mui/material';
import { MdFavorite } from 'react-icons/md';
import { connect } from 'react-redux';
import { getListings } from '../../actions/listingActions';
import { loadUser } from '../../actions/authActions';
import PropTypes from 'prop-types';
import './savedModal.css';

class SavedModal extends Component {
    
    constructor(props) {
        
        super(props);
        this.state = {
            modal: false,
            savedListings: props.savedListings,
        }
    }

    async componentDidMount() {
        
        await this.props.loadUser();
        const { isAuthenticated, user } = this.props;
        
        if (user && isAuthenticated) {
            
            try {
                let page;
                page = { path: "savedListings", filters: { savedIds: user.engagement.savedListings } };
                await this.props.getListings(page);
            } catch (err) {
                console.log(err);
            }
        }
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal,
        });
    }


    render() {

        const { savedListings } = this.props;

        const getStreetAddress = listingAddress => listingAddress.split(", ");
        
        return (
            <div>
                <NavLink style={{ fontFamily: 'Arial, Helvetica, sans-serif', color: 'white', padding: 0, fontSize: 13, textDecoration: 'none' }} onClick={this.toggle} href='#'>
                    SAVED
                </NavLink>
                <Modal style={{ width: 600 }} isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}>Saved Listings</ModalHeader>
                    <ModalBody style={{ width: 500, margin: 'auto', height: 500, overflowY: 'scroll' }}>
                        <Stack className="savedListingsContainer" direction={'column'} spacing={1}>
                            {savedListings && savedListings.map(listing => (
                                <Link style={{ textDecoration: 'none' }} to={`/listings/${listing._id}`}>
                                    <Card className='savedListingCard' style={{ margin: '15px auto', width: 350, borderRadius: 5, boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)', position: 'relative' }}>
                                        <div style={{ position: 'absolute', left: 5, top: 0 }}><MdFavorite style={{ height: 20, width: 20 }} color={'rgb(228, 114, 103)'} /></div>
                                        <CardContent>
                                            <p style={{ fontSize: 15, width: '100%', textAlign: 'center', fontWeight: 'bolder' }}>{getStreetAddress(listing.listingAddress)[0]}</p>
                                            <hr />
                                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
                                                <div>
                                                    <div>
                                                        <p style={{ fontSize: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', textDecoration: 'underline', textUnderlineOffset: 6, textDecorationThickness: 1, }}>Fourcast Score</p>
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                            <div style={{ fontSize: 18, fontWeight: 'bold', width: 50, height: 50, border: '3px solid darkgreen', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{listing.scores.fourcastScore}</div>
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: 15 }}>
                                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><span style={{ fontSize: 13, fontWeight: 'bold' }}>{listing.squareFeet.length > 1 ? `${listing.squareFeet[0]} - ${listing.squareFeet[1]} sqft` : `${listing.squareFeet}`}</span><span style={{ fontSize: 12, marginLeft: 5 }}>Sqft</span></div>
                                                        <div style={{ fontSize: 13, fontWeight: 'bold', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{listing.pricePerSquareFoot !== -1 ? `$${listing.pricePerSquareFoot} / yr` : 'Undisclosed Price'}</div>
                                                    </div>
                                                </div>
                                                <div style={{ marginTop: 10 }}>
                                                    <Stack className="savedListingsScores" style={{ display: 'flex', justifyContent: 'right' }} direction={'row'} spacing={2}>
                                                        <p style={{ fontSize: 12 }}>Traffic:</p>
                                                        <p style={{ fontSize: 14, fontWeight: 'bold' }}>{listing.scores.trafficScore}</p>
                                                    </Stack>
                                                    <Stack className="savedListingsScores" style={{ display: 'flex', justifyContent: 'right' }} direction={'row'} spacing={2}>
                                                        <p style={{ fontSize: 12 }}>Demographics:</p>
                                                        <p style={{ fontSize: 14, fontWeight: 'bold' }}>{listing.scores.demographicsScore}</p>
                                                    </Stack>
                                                    <Stack className="savedListingsScores" style={{ display: 'flex', justifyContent: 'right' }} direction={'row'} spacing={2}>
                                                        <p style={{ fontSize: 12 }}>Competition:</p>
                                                        <p style={{ fontSize: 14, fontWeight: 'bold' }}>{listing.scores.competitionScore}</p>
                                                    </Stack>
                                                    <Stack className="savedListingsScores" style={{ display: 'flex', justifyContent: 'right' }} direction={'row'} spacing={2}>
                                                        <p style={{ fontSize: 12 }}>Property:</p>
                                                        <p style={{ fontSize: 14, fontWeight: 'bold' }}>{listing.scores.propertyScore}</p>
                                                    </Stack>
                                                </div>
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Link>
                            ))}
                        </Stack>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

SavedModal.propTypes = {
    loadUser: PropTypes.func.isRequired,
    savedListings: PropTypes.object.isRequired,
    getListings: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    isAuthenticated: state.auth.isAuthenticated,
    savedListings: state.listing.savedListings,
    user: state.auth.user,
});
 
export default connect(mapStateToProps, { getListings, loadUser })(SavedModal);